import React, { useState, useContext, useEffect } from "react";

import { AuthContext } from "Auth.js";

// reactstrap components
import {
  Alert,
  ButtonGroup,
  Button,
  Collapse,
  Card,
  Container,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  CardImg,
  CardBody,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Table,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Toast,
  ToastBody,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import GooglePlaceAutocomplete from "react-google-autocomplete";

import { Form, Field } from "react-final-form";
import Select from "react-select";

import {
  notify,
  genImageName,
  nDaysAfterTZ,
  handleReactDatetimeChange,
  getClassNameReactDatetimeDays,
  hashCode,
  validEthAddress,
  truncateAddressLong,
  truncateText,
} from "utils/Utils.js";

import {
  creatorAddPass,
  creatorUpdatePass,
  creatorDeletePass,
  getNftMetadata,
} from "utils/Api.js";

import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.compat.css";

import Compressor from "compressorjs";

import QRCode from "react-qr-code";

import Avatar from "boring-avatars";

import Loader from "components/Loaders/Loader.js";
import LoaderDots from "components/Loaders/LoaderDots.js";
import LoaderSection from "components/Loaders/LoaderSection.js";
import LoaderModal from "components/Loaders/LoaderModal.js";

import ScaleLoader from "react-spinners/ScaleLoader";
import BarLoader from "react-spinners/BarLoader";

import ModalSuccess from "components/Modals/ModalSuccess.js";

import MultiPassesEditable from "components/Lists/MultiPassesEditable";
import { set } from "lodash";

const {
  defaultBoxGrayImg,
  defaultFormImg,
  ethImg,
  polygonImg,
  ethWhiteImg,
  polygonWhiteImg,
  defaultFormBanner,
  DefaultLocation,
  ChainOptions,
  EventCategoryOptions,
  EventCategorySelectorOptions,
  CreatorRoleTypes,
  DisableMaxUsageOption,
  InviteMaxUsageOptions,
  InviteExpireOptions,
  ChainSelectorOptions,
  USDateOption,
  UserRoleTypes,
  UserRoleStatus,
  VerifiedUserTypes,
  VerifiedUserStatus,
  QuillEditorModules,
  QuillEditorFormats,
  DefaultPassSize,
} = require("utils/Variables.js");

const { REACT_APP_HOST } = process.env;

export default function EventPass(props) {
  let {
    event,
    eventId,
    updateEvent,
    updateEventName,
    updateIsPublished,
    isEventActiveCreator,
  } = props;

  const {
    currentUser,
    currentUserId,
    currentAuthToken,
    currentUserInfo,
    setCurrentUserInfo,
  } = useContext(AuthContext);

  const hasAdminRole = currentUserInfo?.hasAdminRole;
  const hasCreatorRole = currentUserInfo?.hasCreatorRole;
  const isVerifiedUser = currentUserInfo?.isVerifiedUser;

  const [passSaving, setPassSaving] = useState(false);
  const [passContractSearching, setPassContractSearching] = useState(false);
  const [passLoading, setPassLoading] = useState(false);

  const [name, setName] = useState(event?.name || "");
  const [description, setDescription] = useState(event?.description || "");

  const [requireUniquePassId, setRequireUniquePassId] = useState(false);
  const [selectedPass, setSelectedPass] = useState();

  const [passNameInput, setPassNameInput] = useState("");
  const [passPriceInput, setPassPriceInput] = useState(0);
  const [passFreeInput, setPassFreeInput] = useState(false);
  const [passMaxUsersInput, setPassMaxUsersInput] = useState("∞");
  const [passUnlimitedUsersInput, setPassUnlimitedUsersInput] = useState(true);
  const [enablePassTokengateInput, setEnablePassTokengateInput] =
    useState(true);
  const [passBlockchainOption, setPassBlockchainOption] = useState(
    ChainSelectorOptions[0]
  );

  const [enablePassPricingInput, setEnablePassPricingInput] = useState(false);
  const [passContractInput, setPassContractInput] = useState("");
  const [passContractError, setPassContractError] = useState();

  const [passUpdateSuccess, setPassUpdateSuccess] = useState(false);
  const [passUpdateModalOpen, setPassUpdateModalOpen] = useState(false);
  const [passUpdateAction, setPassUpdateAction] = useState();
  const [passUpdateIndex, setPassUpdateIndex] = useState();

  const [newPassSuccess, setNewPassSuccess] = useState(false);

  const [passDeleteConfirmation, setPassDeleteConfirmation] = useState("");
  const [passDeleteModalOpen, setPassDeleteModalOpen] = useState(false);
  const [passDeleting, setPassDeleting] = useState(false);

  const resetPassForm = () => {
    setPassContractSearching(false);
    setSelectedPass();
    setPassUpdateIndex();
    setPassNameInput("");
    setPassPriceInput(0);
    setPassFreeInput(false);
    setPassMaxUsersInput("∞");
    setPassUnlimitedUsersInput(true);
    setEnablePassTokengateInput(true);
    setRequireUniquePassId(false);
    setPassBlockchainOption(ChainSelectorOptions[0]);
    setEnablePassPricingInput(false);
    setPassContractInput("");
    setPassContractError();
  };

  const addEventPass = async () => {
    if (event?.passes?.length >= DefaultPassSize) {
      notify("You've reached the maximum number of passes.", "error");
      return;
    }

    try {
      setPassSaving(true);

      const passData = {
        isTokengated: enablePassTokengateInput,
        passName: passNameInput,
        passPrice: passPriceInput,
        passQty: passUnlimitedUsersInput ? -1 : Number(passMaxUsersInput),
      };

      if (enablePassTokengateInput) {
        passData.address = selectedPass?.address;
        passData.chain = passBlockchainOption?.value;
        passData.tokenIdUnique = requireUniquePassId;
      }

      let res = await creatorAddPass(currentAuthToken, eventId, passData);

      if (res?.passes?.length > 0) {
        updateEvent({
          ...event,
          passes: res.passes,
        });

        setNewPassSuccess(true);
        setPassUpdateModalOpen(false);
        resetPassForm();
      } else {
        notify("Unable to update event passes.", "error");
      }
    } catch (err) {
      console.error(err.message);
      notify("Unable to update event passes.", "error");
    } finally {
      setPassSaving(false);
    }
  };

  const searchContractAddress = async () => {
    if (!passContractInput || !validEthAddress(passContractInput)) {
      setPassContractError("Pass contract is not a valid.");
      return;
    }

    try {
      setSelectedPass(null);

      setPassContractSearching(true);

      let res = await getNftMetadata(
        currentAuthToken,
        passContractInput,
        passBlockchainOption?.value
      );

      if (res) {
        // setPassContractInput(res?.data?.name);
        setSelectedPass(res);
        setPassContractInput("");
        setPassContractError();
      } else {
        setPassContractError("Contract not found.");
      }
    } catch (err) {
      console.error(err.message);
      setPassContractError("Contract not found.");
    } finally {
      setPassContractSearching(false);
    }
  };

  const showPassDetail = selectedPass?.address && (
    <Toast className="bg-white">
      <Row className="toast-header bg-white">
        <Col>
          <FontAwesomeIcon icon="fa-ticket" className="mr-2 text-primary" />
          <strong className="text-primary">{selectedPass?.symbol} Pass</strong>
        </Col>
        <Col className="justify-content-right text-right col-1">
          <button
            type="button"
            className="ml-2 close text-primary"
            data-dismiss="toast"
            aria-label="Close"
            color="primary"
            onClick={() => {
              setSelectedPass();
            }}
          >
            <span aria-hidden="true" className="text-primary">
              <FontAwesomeIcon icon="fa-xmark" className="text-primary" />
            </span>
          </button>
        </Col>
      </Row>
      <ToastBody className="bg-white text-primary">
        <Alert className="mt-1 bg-secondary text-primary">
          <Row className="my-1">
            <Col className="col-4">
              <div className="text-left">
                <img
                  alt="..."
                  style={{ width: "100%" }}
                  className="mt-2 mb-2 rounded"
                  src={selectedPass?.imageUrl || defaultBoxGrayImg}
                />
              </div>
            </Col>
            <Col className="col-8">
              <Row className="mt-2">
                <Col className="col-12">
                  <strong className="text-left">
                    {truncateText(selectedPass?.collectionName, 20)}
                  </strong>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col className="col-12">
                  <div className="text-left">
                    {passBlockchainOption.value === "eth" && (
                      <img src={ethImg} className="icon-eth mr-2" />
                    )}
                    {passBlockchainOption.value === "matic" && (
                      <img src={polygonImg} className="icon-polygon mr-2" />
                    )}
                    <>
                      <span>{selectedPass?.tokenType} </span>

                      <a
                        href={`https://opensea.io/assets/${
                          passBlockchainOption?.value === "matic"
                            ? "matic"
                            : "ethereum"
                        }/${selectedPass?.address}`}
                        rel="external nofollow noopener"
                        target="_blank"
                      >
                        <span className="text-info">
                          {truncateAddressLong(selectedPass?.address)}
                        </span>{" "}
                        <FontAwesomeIcon
                          icon="arrow-up-right-from-square"
                          className="ml-1 text-gray"
                        />
                      </a>
                    </>
                  </div>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col>
                  <div className="ml-4 mt-2">
                    <Input
                      className="custom-control-input"
                      id="requireUniqueIdCheck"
                      type="checkbox"
                      checked={requireUniquePassId}
                      onChange={() => {
                        setRequireUniquePassId(!requireUniquePassId);
                      }}
                    />
                    <Label
                      className="custom-control-label"
                      htmlFor="requireUniqueIdCheck"
                    >
                      <div>
                        <strong>Unique Token ID</strong>
                      </div>
                      <small>Token ID can be used once</small>
                    </Label>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Alert>
      </ToastBody>
    </Toast>
  );

  const updateEventPass = async () => {
    try {
      setPassSaving(true);

      const passData = {
        isTokengated: enablePassTokengateInput,
      };

      if (enablePassTokengateInput) {
        passData.address = selectedPass?.address;
        passData.chain = passBlockchainOption?.value;
        passData.tokenIdUnique = requireUniquePassId;
      }

      if (enablePassPricingInput) {
        passData.passName = passNameInput;
        passData.passPrice = passPriceInput;
        passData.passQty = passUnlimitedUsersInput
          ? -1
          : Number(passMaxUsersInput);
      }

      let res = await creatorUpdatePass(
        currentAuthToken,
        eventId,
        passData,
        passUpdateIndex
      );

      if (res?.passes?.length > 0) {
        updateEvent({
          ...event,
          passes: res.passes,
        });
        setNewPassSuccess(true);
        setPassUpdateModalOpen(false);
        resetPassForm();
      } else {
        notify("Unable to update event passes.", "error");
      }
    } catch (err) {
      console.error(err.message);
      notify("Unable to update event passes.", "error");
    } finally {
      setPassSaving(false);
    }
  };

  const deletePass = async () => {
    try {
      setPassDeleting(true);

      let res = await creatorDeletePass(
        currentAuthToken,
        eventId,
        passUpdateIndex
      );

      if (res?.passes?.length > 0) {
        updateEvent({
          ...event,
          passes: res.passes,
        });
        notify("Pass has been deleted.", "success");

        setPassUpdateAction();
      } else {
        notify("Unable to delete pass.", "error");
      }
    } catch (err) {
      console.error(err.message);
      notify("Unable to delete pass.", "error");
    } finally {
      setPassDeleting(false);
      setPassDeleteModalOpen(false);
      resetPassForm();
      setPassDeleteConfirmation("");
    }
  };

  useEffect(() => {
    if (passUpdateModalOpen && passUpdateIndex >= 0) {
      const pass = event?.passes[passUpdateIndex];

      setEnablePassTokengateInput(pass?.isTokengated);

      if (pass?.isTokengated) {
        setSelectedPass(pass);
        setRequireUniquePassId(pass?.tokenIdUnique);
      }
      setPassNameInput(pass?.passName);
      setPassPriceInput(pass?.passPrice);
      setPassFreeInput(pass?.passPrice <= 0);
      setPassMaxUsersInput(pass?.passQty < 0 ? "∞" : pass?.passQty);
      setPassUnlimitedUsersInput(pass?.passQty < 0);

      const chainOption = ChainSelectorOptions.find(
        (option) => option.value === pass?.chain
      );

      setPassBlockchainOption(chainOption || ChainSelectorOptions[0]);
      setEnablePassPricingInput(true);

      setPassContractInput("");
    }
  }, [passUpdateModalOpen]);

  return (
    <>
      {(hasAdminRole || isEventActiveCreator) && (
        <>
          <Row>
            <Col>
              <h5 className="text-default">
                <div>
                  <strong>Passes</strong>
                </div>
              </h5>
              <small>You may add up to {DefaultPassSize} passes</small>
            </Col>
            <Col>
              <Button
                size="sm"
                color="success"
                className="float-right"
                onClick={() => {
                  resetPassForm();
                  setPassUpdateAction("Create");
                  setPassUpdateModalOpen(true);
                }}
                disabled={event?.passes?.length >= DefaultPassSize}
              >
                <FontAwesomeIcon icon="fa-plus" className="mr-2" />
                Create New Pass
              </Button>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mt-3 mb-4">
                <MultiPassesEditable
                  passes={event?.passes}
                  updatePass={setPassUpdateModalOpen}
                  deletePass={setPassDeleteModalOpen}
                  action={setPassUpdateAction}
                  index={setPassUpdateIndex}
                />
              </div>
            </Col>
          </Row>

          {newPassSuccess ? (
            <ModalSuccess
              success={newPassSuccess}
              message="You've successfully updated the event pass."
              resetSuccess={setNewPassSuccess}
            />
          ) : (
            <Modal isOpen={passUpdateModalOpen}>
              <BarLoader
                loading={passSaving}
                cssOverride={{
                  marginLeft: "1.5%",
                  marginRight: "1.5%",
                  width: "97%",
                  backgroundColor: "#fff",
                  opacity: "1",
                }}
              />
              <div className="modal-header">
                <span className="modal-title" id="regenKeyModalLabel">
                  <strong>{passUpdateAction} Pass</strong>
                </span>

                <button
                  aria-label="Close"
                  className=" close"
                  type="button"
                  onClick={() => {
                    setPassUpdateModalOpen(false);
                    resetPassForm();
                  }}
                  disabled={passSaving}
                >
                  <span aria-hidden={true}>
                    <FontAwesomeIcon icon="fa-xmark" />
                  </span>
                </button>
              </div>
              <Form
                onSubmit={() => {}}
                validate={(values) => {
                  const errors = {};

                  if (passNameInput?.length > 40) {
                    errors.name = "Pass name must not exceed 40 characters.";
                  }

                  if (
                    !passFreeInput &&
                    (isNaN(Number(passPriceInput)) ||
                      Number(passPriceInput) < 0)
                  ) {
                    errors.maxUsers =
                      "Price must be greater than or equal to 0.";
                  }

                  if (
                    !passUnlimitedUsersInput &&
                    (isNaN(Number(passMaxUsersInput)) ||
                      Number(passMaxUsersInput) < 0)
                  ) {
                    errors.maxUsers =
                      "Max members must be greater than or equal to 0.";
                  }

                  if (!passBlockchainOption.value) {
                    errors.blockchain = "Blockchain is required.";
                  }

                  return errors;
                }}
                render={({
                  handleSubmit,
                  values,
                  submitting,
                  validating,
                  valid,
                }) => (
                  <>
                    <form onSubmit={handleSubmit}>
                      <ModalBody className="text-default">
                        <div className="mb-2">
                          <Row>
                            <Col className="col-12">
                              <span className="h5">⛩</span>
                              <span className="ml-2">
                                <strong>Tokengate</strong>
                              </span>

                              <Label className="custom-toggle float-right">
                                <Input
                                  checked={enablePassTokengateInput}
                                  type="checkbox"
                                  onChange={() => {
                                    setEnablePassTokengateInput(
                                      !enablePassTokengateInput
                                    );
                                  }}
                                />
                                <span className="custom-toggle-slider rounded-circle"></span>
                              </Label>
                            </Col>
                          </Row>

                          {enablePassTokengateInput ? (
                            <>
                              <FormGroup>
                                <Field name="blockchain">
                                  {({ input, meta }) => (
                                    <>
                                      <Row className="mt-2 mb-1">
                                        <Col>
                                          <div
                                            htmlFor="blockchainInput"
                                            className="mb-1"
                                          >
                                            <small>
                                              <strong>Blockchain</strong>
                                            </small>
                                          </div>
                                          <InputGroup id="blockchainInput">
                                            <Select
                                              {...input}
                                              id="blockchain"
                                              className="realpass-selector"
                                              onChange={(e) => {
                                                setPassBlockchainOption(e);
                                              }}
                                              options={ChainSelectorOptions}
                                              value={passBlockchainOption}
                                              components={{
                                                IndicatorSeparator: () => null,
                                              }}
                                            />
                                          </InputGroup>

                                          {meta.error && meta.touched && (
                                            <small className="text-danger">
                                              <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                              {meta.error}
                                            </small>
                                          )}
                                        </Col>
                                      </Row>
                                    </>
                                  )}
                                </Field>
                              </FormGroup>

                              <div className="my-3">
                                <div
                                  htmlFor="passContractSearch"
                                  className="mb-1"
                                >
                                  <small>
                                    <strong>
                                      Search for NFT Smart Contract
                                    </strong>
                                  </small>
                                </div>
                                <InputGroup id="passContractSearch">
                                  <Input
                                    value={passContractInput}
                                    bsSize="md"
                                    onChange={(e) => {
                                      setPassContractInput(
                                        e.target.value?.toLowerCase()
                                      );
                                    }}
                                    placeholder="Find 0xabc123..."
                                    type="text"
                                    className="input-group-left"
                                  />
                                  <Button
                                    color="primary"
                                    size="md"
                                    type="button"
                                    className="input-group-right"
                                    onClick={searchContractAddress}
                                    disabled={
                                      !passContractInput ||
                                      passContractSearching
                                    }
                                  >
                                    {passContractSearching ? (
                                      <ScaleLoader
                                        color="#eee"
                                        loading={passContractSearching}
                                        height="10px"
                                        cssOverride={{
                                          display: "inline",
                                        }}
                                      />
                                    ) : (
                                      "Find"
                                    )}
                                  </Button>
                                </InputGroup>

                                <div>
                                  {passContractError && (
                                    <small className="text-danger">
                                      <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                      {passContractError}
                                    </small>
                                  )}
                                </div>
                              </div>
                              <div className="my-2">{showPassDetail}</div>
                            </>
                          ) : (
                            <Alert color="secondary" className="text-default">
                              <FontAwesomeIcon
                                icon="fa-circle-info"
                                className="mr-2"
                              />
                              <span>This event is not tokengated.</span>
                            </Alert>
                          )}
                        </div>
                        <hr />
                        <div className="mt-1 mb-2">
                          <Row>
                            <Col className="col-12">
                              <div>
                                <FontAwesomeIcon icon="fa-money-check-dollar" />
                                <span className="ml-2">
                                  <strong>Pricing</strong>
                                </span>

                                <Label className="custom-toggle float-right">
                                  <Input
                                    checked={enablePassPricingInput}
                                    type="checkbox"
                                    onChange={() => {
                                      setEnablePassPricingInput(
                                        !enablePassPricingInput
                                      );
                                    }}
                                  />
                                  <span className="custom-toggle-slider rounded-circle"></span>
                                </Label>
                              </div>
                            </Col>
                          </Row>

                          {enablePassPricingInput ? (
                            <>
                              <FormGroup>
                                <Field name="name">
                                  {({ input, meta }) => (
                                    <>
                                      <Row className="mt-2 mb-1">
                                        <Col>
                                          <div
                                            htmlFor="passNameInput"
                                            className="mb-1"
                                          >
                                            <small>
                                              <strong>Name</strong>
                                            </small>
                                          </div>
                                          <InputGroup id="passNameInput">
                                            <InputGroupText className="input-group-left text-muted">
                                              <FontAwesomeIcon icon="fa-check-to-slot" />
                                            </InputGroupText>
                                            <Input
                                              {...input}
                                              value={passNameInput}
                                              className="input-group-right"
                                              placeholder={
                                                passNameInput ||
                                                "What's the pass called?"
                                              }
                                              onChange={(e) => {
                                                setPassNameInput(
                                                  e.target.value
                                                );
                                              }}
                                            />
                                          </InputGroup>

                                          {meta.error && meta.touched && (
                                            <small className="text-danger">
                                              <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                              {meta.error}
                                            </small>
                                          )}
                                        </Col>
                                      </Row>
                                    </>
                                  )}
                                </Field>
                              </FormGroup>

                              <FormGroup>
                                <Field name="price">
                                  {({ input, meta }) => (
                                    <>
                                      <Row className="mt-2 mb-1">
                                        <Col>
                                          <div htmlFor="passPriceInput">
                                            <small>
                                              <strong>Price</strong>
                                            </small>
                                            <span className="float-right">
                                              <small>Free Pass</small>
                                              <Button
                                                size="sm"
                                                color="link"
                                                className="btn-icon-only ml-2"
                                                onClick={() => {
                                                  const flag = !passFreeInput;
                                                  setPassFreeInput(flag);

                                                  if (flag) {
                                                    setPassPriceInput(0);
                                                  }
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={
                                                    passFreeInput
                                                      ? "fa-toggle-on"
                                                      : "fa-toggle-off"
                                                  }
                                                  className={`fa-2xl ${
                                                    passFreeInput
                                                      ? "text-info"
                                                      : "text-muted"
                                                  }`}
                                                />
                                              </Button>
                                            </span>
                                          </div>
                                          <InputGroup id="passPriceInput">
                                            <InputGroupText className="input-group-left text-muted">
                                              <FontAwesomeIcon icon="fa-hand-holding-dollar" />
                                            </InputGroupText>

                                            <Input
                                              {...input}
                                              id="price"
                                              className="pl-2"
                                              value={
                                                passFreeInput
                                                  ? "Free"
                                                  : passPriceInput
                                              }
                                              onChange={(e) => {
                                                setPassPriceInput(
                                                  e.target.value
                                                );
                                              }}
                                              placeholder={
                                                passFreeInput
                                                  ? "Free"
                                                  : "Amount"
                                              }
                                              type="number"
                                              required
                                              disabled={passFreeInput}
                                            />
                                          </InputGroup>

                                          {meta.error && meta.touched && (
                                            <small className="text-danger">
                                              <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                              {meta.error}
                                            </small>
                                          )}
                                        </Col>
                                      </Row>
                                    </>
                                  )}
                                </Field>
                              </FormGroup>

                              <FormGroup>
                                <Field name="maxUsers">
                                  {({ input, meta }) => (
                                    <>
                                      <Row className="mt-2 mb-1">
                                        <Col>
                                          <div htmlFor="passMaxUsersInput">
                                            <small>
                                              <strong>Qty</strong>
                                            </small>
                                            <span className="float-right">
                                              <small>No Limit</small>
                                              <Button
                                                size="sm"
                                                color="link"
                                                className="btn-icon-only ml-2"
                                                onClick={() => {
                                                  const flag =
                                                    !passUnlimitedUsersInput;

                                                  setPassUnlimitedUsersInput(
                                                    flag
                                                  );

                                                  if (!flag) {
                                                    setPassMaxUsersInput(0);
                                                  }
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={
                                                    passUnlimitedUsersInput
                                                      ? "fa-toggle-on"
                                                      : "fa-toggle-off"
                                                  }
                                                  className={`fa-2xl ${
                                                    passUnlimitedUsersInput
                                                      ? "text-info"
                                                      : "text-muted"
                                                  }`}
                                                />
                                              </Button>
                                            </span>
                                          </div>
                                          <InputGroup id="passMaxUsersInput">
                                            <InputGroupText className="input-group-left pr-2">
                                              <FontAwesomeIcon icon="fa-users" />
                                            </InputGroupText>
                                            <Input
                                              {...input}
                                              id="maxUsers"
                                              value={
                                                passUnlimitedUsersInput
                                                  ? "∞"
                                                  : passMaxUsersInput
                                              }
                                              className="pl-2"
                                              onChange={(e) => {
                                                setPassMaxUsersInput(
                                                  e.target.value
                                                );
                                              }}
                                              placeholder={
                                                passUnlimitedUsersInput
                                                  ? "∞"
                                                  : "Max"
                                              }
                                              disabled={passUnlimitedUsersInput}
                                              type="number"
                                              required
                                            />
                                          </InputGroup>

                                          {meta.error && meta.touched && (
                                            <small className="text-danger">
                                              <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                              {meta.error}
                                            </small>
                                          )}
                                        </Col>
                                      </Row>
                                    </>
                                  )}
                                </Field>
                              </FormGroup>
                            </>
                          ) : (
                            <Alert color="secondary" className="text-default">
                              <FontAwesomeIcon
                                icon="fa-circle-info"
                                className="mr-2"
                              />
                              <span>
                                This event has no pricing set up.{" "}
                                {enablePassTokengateInput &&
                                  " Free pass for all eligible token holders."}
                              </span>
                            </Alert>
                          )}
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          size="md"
                          color="primary"
                          onClick={() => {
                            if (passUpdateAction === "Create") {
                              addEventPass();
                            }

                            if (passUpdateAction === "Update") {
                              updateEventPass();
                            }
                          }}
                          disabled={passSaving || !valid}
                        >
                          {passSaving ? (
                            <ScaleLoader
                              color="#eee"
                              loading={passSaving}
                              height="10px"
                              cssOverride={{
                                display: "inline",
                              }}
                            />
                          ) : (
                            passUpdateAction
                          )}
                        </Button>
                        <Button
                          size="md"
                          color="link"
                          type="button"
                          onClick={() => {
                            setPassUpdateModalOpen(false);
                            resetPassForm();
                          }}
                          disabled={passSaving}
                        >
                          Close
                        </Button>
                      </ModalFooter>
                    </form>
                  </>
                )}
              />
            </Modal>
          )}

          <Modal isOpen={passDeleteModalOpen}>
            <div className="modal-header">
              <strong className="text-danger modal-title" id="deleteModalLabel">
                Delete Pass
              </strong>

              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => {
                  setPassDeleteModalOpen(false);
                  setPassDeleteConfirmation("");
                  resetPassForm();
                }}
                disabled={passDeleting}
              >
                <span aria-hidden={true}>
                  <FontAwesomeIcon icon="fa-xmark" />
                </span>
              </button>
            </div>

            <ModalBody>
              <div className="my-1 text-default">
                <Label for="confirmation" className="required">
                  Please type <strong>delete</strong> to confirm the pass
                  deletion.
                </Label>
                <Input
                  id="confirmation"
                  value={passDeleteConfirmation}
                  onChange={(e) => {
                    setPassDeleteConfirmation(e.target.value);
                  }}
                  placeholder="delete"
                  type="text"
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                size="md"
                color="danger"
                type="button"
                onClick={deletePass}
                disabled={passDeleteConfirmation != "delete" || passDeleting}
              >
                {passDeleting ? (
                  <>
                    <span className="mr-2">Deleting</span>
                    <ScaleLoader
                      color="#eee"
                      loading={passDeleting}
                      height="10px"
                      cssOverride={{
                        display: "inline",
                      }}
                    />
                  </>
                ) : (
                  "Confirm"
                )}
              </Button>
              <Button
                size="md"
                color="link"
                type="button"
                onClick={() => {
                  setPassDeleteModalOpen(false);
                  setPassDeleteConfirmation("");
                  resetPassForm();
                }}
                disabled={passDeleting}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </>
      )}
    </>
  );
}
