import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { AuthContext } from "Auth.js";

import QRCode from "react-qr-code";
// import { RandomHash } from "random-hash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// reactstrap components
import {
  Button,
  Badge,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Label,
  Input,
  Toast,
  ToastHeader,
  ToastBody,
  Spinner,
  UncontrolledTooltip,
  Alert,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";

// core components
import TopNavbar from "components/Navbars/TopNavbar.js";

import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.compat.css";

import {
  hashCode,
  truncateAddressShort,
  truncateText,
  notify,
  formatPrettyNumber,
} from "utils/Utils.js";

import { getEvent } from "utils/Api.js";

import Loader from "components/Loaders/Loader.js";
import LoaderModal from "components/Loaders/LoaderModal.js";

import ScaleLoader from "react-spinners/ScaleLoader";
import BarLoader from "react-spinners/BarLoader";

import ManageEventButton from "components/Buttons/ManageEventButton.js";

import moment from "moment-timezone";

const {
  bgAbstractLine01,
  bgAbstractLine02,
  bgAbstractLine03,
  bgAbstractLine04,
  bgAbstractLine05,
  logoWhiteImg,
  passChipImg,
  defaultBoxGrayImg,
  verifiedImg,
  nsfwImg,
  ethImg,
  ethWhiteImg,
  polygonImg,
  polygonWhiteImg,
  mmImg,
  ethLogoColor,
  polygonLogoColor,
  calImg,
  gCalImg,
  outlookImg,
  ChainLogos,
  ChainOptions,
  EventCategoryOptions,
  USDateOption,
  USDateTimeOption,
  USDateTimeShortOption,
  UserRoleTypes,
  CreatorRoleTypes,
  UserRoleStatus,
  VerifiedUserTypes,
  VerifiedUserStatus,
  SocialShareOptions,
  SocialShareMessage,
  SocialShareTitle,
  SocialShareSubject,
  SocialShareUsername,
  DisclaimerShort,
} = require("utils/Variables.js");

const { REACT_APP_HOST } = process.env;

function Access() {
  const navigate = useNavigate();

  const { currentUser, currentAuthToken, currentUserInfo, setCurrentUserInfo } =
    useContext(AuthContext);

  const hasAdminRole = currentUserInfo?.hasAdminRole;
  const hasCreatorRole = currentUserInfo?.hasCreatorRole;

  const { eventId } = useParams();

  const [creatorWallet, setCreatorWallet] = useState(
    currentUserInfo?.ethAddress
  );

  const [isEventActiveCreator, setIsEventActiveCreator] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [isCohost, setIsCohost] = useState(false);
  const [isPanelist, setIsPanelist] = useState(false);

  const [pageLoading, setPageLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const [eventObjectId, setEventObjectId] = useState(eventId);
  const [eventImage, setEventImage] = useState();
  const [bannerImage, setBannerImage] = useState();
  const [eventName, setEventName] = useState();
  const [eventDescription, setEventDescription] = useState();
  const [accessEnabled, setAccessEnabled] = useState();

  const [blockchain, setBlockchain] = useState();
  const [passName, setPassName] = useState();
  const [passType, setPassType] = useState();
  const [passImage, setPassImage] = useState();
  const [passTitle, setPassTitle] = useState();
  const [freePass, setFreePass] = useState(false);
  const [passContract, setPassContract] = useState();

  const [eventKey, setEventKey] = useState();
  const [eventQRCode, setEventQRCode] = useState("");
  const [eventHashCode, setEventHashCode] = useState();

  const [totalReg, setTotalReg] = useState();
  const [totalCheckin, setTotalCheckin] = useState();
  const [maxParticipants, setMaxParticipants] = useState();
  const [spotsFull, setSpotsFull] = useState();

  const [eventStartDate, setEventStartDate] = useState();
  const [eventEndDate, setEventEndDate] = useState();
  const [eventEnded, setEventEnded] = useState();

  const [isVirtual, setIsVirtual] = useState(true);
  const [eventLocation, setEventLocation] = useState();

  const [tokenUrl, setTokenUrl] = useState();

  const [creatorEthAddress, setCreatorEthAddress] = useState();
  const [creatorName, setCreatorName] = useState();
  const [ownerEthAddress, setOwnerEthAddress] = useState();
  const [isVerifiedUser, setIsVerifiedUser] = useState(false);

  const [isPrivate, setIsPrivate] = useState();
  const [isNSFW, setIsNSFW] = useState();
  const [isCancelled, setIsCancelled] = useState(false);
  const [isPublished, setIsPublished] = useState(true);
  const [isInstantAccessEnabled, setIsInstantAccessEnabled] = useState();

  const [passError, setPassError] = useState();

  const [qrActive, setQRActive] = useState(false);
  const [regenKeyModalOpen, setRegenKeyModalOpen] = useState(false);

  const [showMoreText, setShowMoreText] = useState(false);
  const [hasMoreText, setHasMoreText] = useState(false);
  const [showPassQR, setShowPassQR] = useState(false);
  const [passModalOpen, setPassModalOpen] = useState(false);

  const [eventError, setEventError] = useState();

  const fetchEvent = async () => {
    try {
      setPageLoading(true);
      const eventRes = await getEvent(currentAuthToken, eventId);

      if (
        !hasAdminRole &&
        !(
          hasCreatorRole &&
          (eventRes?.isOwner || eventRes?.isCohost || eventRes?.isPanelist)
        )
      ) {
        navigate(`/event/${eventId}`);
      }

      let contract, uniqueId, chain;

      const owner = eventRes?.owner;
      setOwnerEthAddress(owner?.ethAddress);
      setIsOwner(eventRes?.isOwner);
      setIsCohost(eventRes?.isCohost);
      setIsPanelist(eventRes?.isPanelist);
      setIsEventActiveCreator(
        hasCreatorRole &&
          (eventRes?.isOwner || eventRes?.isCohost || eventRes?.isPanelist)
      );

      setIsCancelled(eventRes?.isCancelled);
      setIsPublished(eventRes?.isPublished);

      setEventName(eventRes?.name);

      setEventImage(eventRes?.imageUrl);
      setBannerImage(eventRes?.bannerUrl);

      const start = new Date(eventRes?.startDateTime);
      const end = new Date(eventRes?.endDateTime);
      setEventEnded(end?.getTime() < new Date().getTime());

      const dateTZOption = JSON.parse(JSON.stringify(USDateOption));

      dateTZOption.timeZone = eventRes?.timezone;

      const startTimeShort = `${start?.toLocaleDateString(
        "en-US",
        dateTZOption
      )} (${moment().tz(eventRes?.timezone)?.zoneAbbr()})`;

      const endTimeShort = `${end?.toLocaleDateString(
        "en-US",
        dateTZOption
      )} (${moment().tz(eventRes?.timezone)?.zoneAbbr()})`;

      setEventStartDate(startTimeShort);
      setEventEndDate(endTimeShort);

      setIsPrivate(eventRes?.isPrivate);
      setIsNSFW(eventRes?.isNSFW);
      setIsInstantAccessEnabled(eventRes?.isInstantAccessEnabled);

      setIsVirtual(eventRes?.isVirtual);
      setEventLocation(eventRes?.location);

      setMaxParticipants(eventRes?.maxParticipants);
      setTotalReg(eventRes?.totalRegistered);
      setTotalCheckin(eventRes?.totalCheckin);
      setSpotsFull(
        eventRes?.maxParticipants >= 0 &&
          eventRes?.totalRegistered >= eventRes?.maxParticipants
      );

      setEventError();

      //   eventQuery.equalTo("objectId", eventId);
      //   nfevent = await eventQuery.first();

      //   if (!nfevent) {
      //     navigate(`/account`);
      //   }

      //   if (nfevent.get("creatorWallet") !== creatorWallet) {
      //     navigate(`/event/${eventId}`);
      //   }

      //   const name = nfevent.get("name");
      //   const description = nfevent.get("description");
      //   const accessEnabled = nfevent.get("accessEnabled");
      //   const image = nfevent.get("eventImage");
      //   const banner = nfevent.get("bannerImage");
      //   const contract = nfevent.get("passContract");
      //   const startTime = nfevent.get("startDateTime");
      //   const endTime = nfevent.get("endDateTime");
      //   const virtual = nfevent.get("isVirtual");
      //   const location = nfevent.get("location");
      //   const maxParts = nfevent.get("maxParticipants");
      //   const publicFlag = nfevent.get("isPublic");
      //   const nsfw = nfevent.get("isNSFW");

      //   // TODO: Remove rinkeby
      //   const chain = nfevent.get("blockchain") === "eth" ? "rinkeby" : "mumbai";
      //   // const chain = nfevent.get("blockchain");

      //   setEventName(name);
      //   setEventDescription(description);
      //   setHasMoreText(description.length > 300);

      //   setAccessEnabled(accessEnabled);
      //   setEventImage(image?.url());
      //   setBannerImage(banner?.url());

      //   const startTimeShort = startTime?.toLocaleDateString(
      //     "en-US",
      //     US_DATE_OPTION
      //   );
      //   const endTimeShort = endTime?.toLocaleDateString("en-US", US_DATE_OPTION);

      //   setEventStartDate(startTimeShort);
      //   setEventEndDate(endTimeShort);

      //   if (endTime?.getTime() <= new Date().getTime()) {
      //     setEventEnded(true);
      //   }
      //   setIsVirtual(virtual);
      //   setEventLocation(location);
      //   setMaxParticipants(maxParts);
      //   setIsPublic(publicFlag);
      //   setIsNSFW(nsfw);

      //   await fetchProfile();

      //   ekeyQuery.equalTo("eventId", eventId);
      //   nfekey = await ekeyQuery.first();
      //   const key = nfekey?.get("eventKey");

      //   setEventKey(key);

      //   if (accessEnabled && key) {
      //     genQR(key);
      //   }

      //   setBlockchain(chain);

      //   if (contract) {
      //     setPassContract(contract);
      //     setFreePass(false);
      //     setPassTitle("Holders Only");

      //     // TODO: check if pass contract is RealPass
      //     // TODO: remove rinkeby and mumbai
      //     if (chain === "eth") {
      //       setTokenUrl(`https://etherscan.io/address/${contract}`);
      //     }

      //     if (chain === "matic") {
      //       setTokenUrl(`https://polygonscan.com/address/${contract}`);
      //     }

      //     const options = {
      //       chain: chain,
      //       address: contract,
      //       limit: 1,
      //     };

      //     try {
      //       const res = await Moralis.Web3API.token.getAllTokenIds(options);
      //       if (res && res.result && res.result.length > 0) {
      //         const nft = res.result[0];
      //         setPassName(nft?.name);
      //         setPassType(nft?.contract_type);
      //         setPassImage(
      //           JSON.parse(nft?.metadata)?.image?.replace(
      //             "ipfs://",
      //             "https://gateway.moralisipfs.com/ipfs/"
      //           )
      //         );
      //       } else {
      //         setPassError("Cannot find the smart contract.");
      //       }
      //     } catch (err) {
      //       console.error(err.message);
      //       setPassError("Unable to get the smart contract data.");
      //     } finally {
      //       setPageLoading(false);
      //     }
      //   } else {
      //     setPassTitle("Free Pass");
      //     setFreePass(true);
      //   }

      //   const totalParts = await fetchTotalParticipants();

      //   if (!isNaN(totalParts) && !isNaN(maxParts) && maxParts > 0) {
      //     setSpotsFull(totalParts >= maxParts);
      //   }
    } catch (err) {
      console.error(err.message);
      if (err.response?.status === 404) {
        navigate("/404");
      } else {
        notify("Unable to fetch event data.", "error");
        setEventError(
          "This is event currently is not available. You can come back later."
        );
      }
    } finally {
      setPageLoading(false);
    }
  };

  //   const fetchProfile = async () => {
  //     try {
  //       profileQuery.equalTo("user", currentUser);
  //       nfprofile = await profileQuery.first();

  //       if (!nfprofile) {
  //         //No permission to event access should redirect
  //         navigate(`/event/${eventId}`);
  //       }

  //       setCreatorEthAddress(nfprofile.get("ethAddress"));
  //       setCreatorName(nfprofile.get("name"));

  //       verifiedQuery.equalTo("profile", nfprofile);
  //       nfverified = await verifiedQuery.first();
  //       setIsNFVerified(nfverified?.get("verified"));
  //     } catch (err) {
  //       console.error(err.message);
  //       notify("Unable to fetch profile.", "error");
  //     }
  //   };

  const fetchTotalParticipants = async () => {
    try {
      //   const res = await Moralis.Cloud.run("totalParticipants", {
      //     eventId: eventId,
      //     validOnly: true,
      //   });
      //   if (!isNaN(res)) {
      //     setTotalParticipants(res);
      //     return res;
      //   } else {
      //     setTotalParticipants(0);
      //     return 0;
      //   }
    } catch (err) {
      console.error(err.message);
    }
  };

  const regenKey = async () => {
    if (accessEnabled) {
      try {
        setModalLoading(true);

        // const generateHash = new RandomHash();
        // const newkey = generateHash();

        // nfekey.set("eventId", eventId);
        // nfekey.set("eventKey", newkey);

        // await nfekey.save();

        // genQR(newkey);
      } catch (err) {
        console.error(err.message);
      } finally {
        setModalLoading(false);
      }
    }
  };

  const genQR = (key) => {
    if (key) {
      const salt = Math.round(new Date().getTime() / 500);
      const seed = salt + key;

      const hash = hashCode(seed);

      let url = window.location.href;

      if (url.indexOf("?") > 0) {
        url = url.substring(0, url.indexOf("?"));
      }

      const qrCode = `${REACT_APP_HOST}/myaccess/verify?ehash=${hash}`;

      setEventQRCode(qrCode);
      setEventHashCode(hash);
    }
  };

  useEffect(() => {
    if (!hasAdminRole && !hasCreatorRole) {
      navigate(`/event/${eventId}`);
    }

    fetchEvent();
  }, []);

  useEffect(() => {
    if (accessEnabled && eventKey && qrActive) {
      const qrTimer = setTimeout(() => {
        genQR(eventKey);
      }, 500);

      return () => {
        clearTimeout(qrTimer);
      };
    }
  }, [eventHashCode, qrActive]);

  return (
    <>
      <BarLoader
        loading={pageLoading}
        cssOverride={{
          width: "100%",
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          zIndex: "1031",
          backgroundColor: "#fff",
          opacity: "1",
        }}
      />
      <TopNavbar />
      <ReactNotifications />
      <div className="wrapper event-page">
        {!pageLoading &&
          (isCancelled ? (
            <Alert
              color="dark"
              className="mb-0 mt-2 text-center rounded-0 bg-dark"
              style={{ zIndex: "1000" }}
            >
              <span className="h5 text-white">
                ☔️ This event has been cancelled by the{" "}
                <a
                  href={`/${ownerEthAddress}`}
                  target="_blank"
                  className="text-info"
                >
                  <u>creator</u>
                </a>
                . You no longer have the access.
              </span>
            </Alert>
          ) : eventError ? (
            <Alert
              color="dark"
              className="mb-0 mt-2 text-center rounded-0 bg-dark"
              style={{ zIndex: "1000" }}
            >
              <span className="h5 text-white">
                <FontAwesomeIcon
                  icon="fa-triangle-exclamation"
                  className="mr-2"
                />
                {eventError}
              </span>
            </Alert>
          ) : !isPublished ? (
            <Alert
              color="dark"
              className="mb-0 mt-2 text-center rounded-0 bg-dark"
              style={{ zIndex: "1000" }}
            >
              <span className="h5 text-white">
                <FontAwesomeIcon icon="fa-bell" className="mr-2" />
                The event is not published. It's only visible to admin.
              </span>
            </Alert>
          ) : eventEnded ? (
            <Alert
              color="dark"
              className="mb-0 mt-2 text-center rounded-0 bg-dark"
              style={{ zIndex: "1000" }}
            >
              <span className="h5 text-white">
                <FontAwesomeIcon icon="fa-bell" className="mr-2" />
                This event has already ended.
              </span>
            </Alert>
          ) : (
            // ) : !isInstantAccessEnabled ? (
            //   <Alert
            //     color="dark"
            //     className="mb-0 mt-2 text-center rounded-0 bg-dark"
            //     style={{ zIndex: "1000" }}
            //   >
            //     <span className="h5 text-white">
            //       <FontAwesomeIcon icon="fa-bell" className="mr-2" />
            //       Instant access is disabled.
            //     </span>
            //   </Alert>
            isNSFW && (
              <Alert
                color="dark"
                className="mb-0 mt-2 text-center rounded-0 bg-dark"
                style={{ zIndex: "1000" }}
              >
                <span className="h5 text-white">
                  <FontAwesomeIcon icon="fa-bell" className="mr-2" />
                  This event is NSFW and has age restriction.
                </span>
              </Alert>
            )
          ))}
        <section className="section-event-cover-half section-shaped my-0">
          <img
            alt="..."
            src={bannerImage || bgAbstractLine01}
            className="bg-image w-100"
          />
        </section>
        {(hasAdminRole || (hasCreatorRole && (isOwner || isCohost))) && (
          <section className="section section-main">
            <Container>
              <div className="card-event">
                <div className="px-3 pb-2">
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="7">
                      <div className="card-event-image-md">
                        <img
                          alt="..."
                          className="event-image-lg"
                          src={eventImage || defaultBoxGrayImg}
                        ></img>
                      </div>
                    </Col>
                    <Col
                      className="order-lg-3 text-lg-right align-self-lg-center"
                      lg="2"
                    >
                      <div className="card-event-actions justify-content-center py-lg-4 mt-lg-0">
                        <ManageEventButton eventId={eventId} />
                      </div>
                    </Col>
                    <Col className="order-lg-1" lg="2">
                      <div className="card-event-stats d-flex justify-content-center">
                        <div>
                          <small className="heading">
                            {formatPrettyNumber(totalCheckin)}
                          </small>
                          <small className="description">Checkin</small>
                        </div>
                        <div>
                          <small className="heading">
                            {formatPrettyNumber(totalReg)}
                          </small>
                          <small className="description">Joined</small>
                        </div>
                        <div>
                          <small className="heading">
                            {!isNaN(maxParticipants) ? (
                              maxParticipants >= 0 ? (
                                formatPrettyNumber(maxParticipants)
                              ) : (
                                <>
                                  <FontAwesomeIcon
                                    icon="fa-infinity"
                                    id="unlimitedSpots"
                                  />

                                  <UncontrolledTooltip
                                    placement="top"
                                    target="unlimitedSpots"
                                    trigger="hover"
                                  >
                                    Unlimited Spots{" "}
                                  </UncontrolledTooltip>
                                </>
                              )
                            ) : (
                              <>TBA</>
                            )}
                          </small>
                          <span className="description">
                            Spots{" "}
                            {spotsFull && (
                              <sup>
                                <small className="text-warning">FULL</small>
                              </sup>
                            )}
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center mt-lg-4">
                    <span className="display-4 text-dark">
                      {eventName || "Unnamed"}
                    </span>
                    {isPrivate && (
                      <span
                        id="privateEventTag"
                        className="text-white ml-2"
                        style={{ cursor: "pointer" }}
                      >
                        <sup>
                          <Badge color="secondary" className="text-default">
                            Private
                          </Badge>
                        </sup>
                      </span>
                    )}
                    {isPrivate && (
                      <UncontrolledTooltip
                        placement="top"
                        target="privateEventTag"
                        trigger="hover"
                      >
                        This is a private event.
                      </UncontrolledTooltip>
                    )}
                    <div className="ml-lg-9 mr-lg-9 mt-4 mb-4">
                      <span className="mx-2">
                        <FontAwesomeIcon
                          icon="fa-calendar-days"
                          className="mr-2"
                        />

                        <strong className="text-info">
                          {eventStartDate ? eventStartDate : "TBA"}
                        </strong>
                        {eventEnded && (
                          <small className="text-warning"> ENDED</small>
                        )}
                      </span>
                      <span className="mx-3">
                        {isVirtual ? (
                          <i className="ni ni-planet mr-2 align-middle" />
                        ) : (
                          <FontAwesomeIcon
                            icon="fa-location-dot"
                            className="mr-2"
                          />
                        )}
                        <strong className="text-info">
                          {eventLocation?.name ? (
                            isVirtual ? (
                              <>
                                <a
                                  href={
                                    eventLocation?.name.includes("https://") ||
                                    eventLocation?.name.includes("http://")
                                      ? eventLocation?.name
                                      : `https://${eventLocation?.name}`
                                  }
                                  target="_blank"
                                  rel="external nofollow noopener"
                                >
                                  Virtual
                                </a>
                              </>
                            ) : (
                              <>{eventLocation?.name}</>
                            )
                          ) : (
                            <>
                              {isVirtual ? "Virtual" : "IRL"}{" "}
                              <small className="text-primary">(TBA)</small>
                            </>
                          )}
                        </strong>
                      </span>
                    </div>
                  </div>
                  <hr />
                  <div className="ml-lg-8 mr-lg-8  mt-2">
                    <Row>
                      <Col>
                        <div className="text-center my-3">
                          <Button
                            outline
                            size="lg"
                            color="primary"
                            onClick={() => {
                              if (
                                accessEnabled &&
                                eventQRCode &&
                                !eventEnded &&
                                !spotsFull
                              ) {
                                setQRActive(!qrActive);
                              }
                            }}
                            disabled={
                              !accessEnabled ||
                              !eventQRCode ||
                              eventEnded ||
                              spotsFull
                            }
                          >
                            <div className="display-4 mt-2">
                              {accessEnabled &&
                              eventQRCode &&
                              !eventEnded &&
                              !spotsFull ? (
                                <>
                                  Scan QR Code
                                  {qrActive ? (
                                    <FontAwesomeIcon
                                      icon="fa-eye-slash"
                                      className="ml-2"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon="fa-eye"
                                      className="ml-2"
                                    />
                                  )}
                                </>
                              ) : eventEnded ? (
                                <>Event ended</>
                              ) : spotsFull ? (
                                <>No more spots</>
                              ) : !accessEnabled || !eventQRCode ? (
                                <>Access disabled</>
                              ) : (
                                <>Invalid QR Code</>
                              )}
                            </div>
                            <Row className="mt-3 mx-2">
                              <Col className="text-center">
                                {qrActive ? (
                                  <QRCode
                                    className={
                                      !accessEnabled ||
                                      !eventQRCode ||
                                      eventEnded ||
                                      spotsFull
                                        ? "qr-overlay"
                                        : ""
                                    }
                                    size={
                                      window.innerWidth * 0.18 > 230
                                        ? window.innerWidth * 0.18
                                        : 210
                                    }
                                    value={eventQRCode}
                                  />
                                ) : (
                                  <QRCode
                                    className="qr-overlay"
                                    size={
                                      window.innerWidth * 0.18 > 230
                                        ? window.innerWidth * 0.18
                                        : 210
                                    }
                                    value=""
                                  />
                                )}
                                <div className="mt-2 mb-0">
                                  <div className="mx-3 mt-2">
                                    <div className="display-6">
                                      Supported wallet{" "}
                                      <img
                                        src={mmImg}
                                        className="mx-2 my-2"
                                        style={{ width: "25px" }}
                                      />
                                      {/* <img
                                      src={wcImg}
                                      className='mx-2 my-2'
                                      style={{ width: "30px" }}
                                    />{" "} */}
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Button>
                        </div>

                        <div className="text-center my-3">
                          {freePass ? (
                            <Button
                              outline
                              color="primary"
                              onClick={(e) => e.preventDefault()}
                            >
                              <div className="display-5 text-center">
                                <FontAwesomeIcon
                                  icon="fa-ticket"
                                  className="mr-2"
                                />{" "}
                                FREE PASS
                              </div>
                            </Button>
                          ) : (
                            passContract && (
                              <Button
                                outline
                                color="primary"
                                onClick={() => setPassModalOpen(true)}
                              >
                                <strong className="display-5">
                                  <FontAwesomeIcon
                                    icon="fa-ticket"
                                    className="mr-2"
                                  />{" "}
                                  Event Pass
                                </strong>
                                <div className="display-4">
                                  {blockchain === "eth" && (
                                    <img
                                      src={ethLogoColor}
                                      className="chain-icon-md mr-2"
                                    />
                                  )}
                                  {blockchain === "matic" && (
                                    <img
                                      src={polygonLogoColor}
                                      className="chain-icon-md mr-2"
                                    />
                                  )}
                                  {truncateText(passName, 18)}
                                </div>
                              </Button>
                            )
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              {accessEnabled && eventQRCode && (
                <Modal
                  toggle={() => setRegenKeyModalOpen(!regenKeyModalOpen)}
                  isOpen={regenKeyModalOpen}
                >
                  <LoaderModal loading={modalLoading} />
                  <div className=" modal-header">
                    <h5 className=" modal-title" id="regenKeyModalLabel">
                      Update Event Key
                    </h5>
                    <button
                      aria-label="Close"
                      className=" close"
                      type="button"
                      onClick={() => setRegenKeyModalOpen(!regenKeyModalOpen)}
                    >
                      <span aria-hidden={true}>
                        <FontAwesomeIcon icon="fa-xmark" />
                      </span>
                    </button>
                  </div>
                  <ModalBody>
                    <span className="text-default">
                      <strong>Event QR Code</strong> is a dynamic hash code
                      computed from your <strong>Secret Event Key</strong>.
                      Would you like to regenerate your Event Key?
                    </span>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="primary"
                      type="button"
                      onClick={() => {
                        regenKey();
                        setRegenKeyModalOpen(!regenKeyModalOpen);
                      }}
                    >
                      Yes, update key
                    </Button>
                    <Button
                      color="secondary"
                      type="button"
                      onClick={() => setRegenKeyModalOpen(!regenKeyModalOpen)}
                    >
                      No
                    </Button>
                  </ModalFooter>
                </Modal>
              )}

              {passContract && (
                <Modal
                  toggle={() => {
                    setPassModalOpen(!passModalOpen);
                    setShowPassQR(false);
                  }}
                  isOpen={passModalOpen}
                >
                  <LoaderModal loading={modalLoading} />
                  <div className=" modal-header">
                    <h5 className=" modal-title" id="passModalLabel">
                      Pass Detail
                    </h5>
                    <button
                      aria-label="Close"
                      className=" close"
                      type="button"
                      onClick={() => {
                        setPassModalOpen(!passModalOpen);
                        setShowPassQR(false);
                      }}
                    >
                      <span aria-hidden={true}>
                        <FontAwesomeIcon icon="fa-xmark" />
                      </span>
                    </button>
                  </div>
                  <ModalBody className="justify-content-center">
                    <div className="m-3">
                      <Row>
                        <Col className="justify-content-center">
                          <Card
                            className="bg-primary justify-content-center"
                            style={{
                              border: "8px solid #2a2a2a",
                            }}
                          >
                            <CardBody className="text-white">
                              <div className="m-1">
                                {freePass ? (
                                  <h3 className="display-3 text-center text-white">
                                    FREE
                                  </h3>
                                ) : (
                                  passContract && (
                                    <>
                                      {showPassQR ? (
                                        <div
                                          className="text-center p-2 mt-2 mb-2 rounded"
                                          style={{
                                            width: "100%",
                                            backgroundColor: "white",
                                          }}
                                        >
                                          <QRCode size={230} value={tokenUrl} />
                                        </div>
                                      ) : (
                                        <img
                                          alt="..."
                                          style={{ width: "100%" }}
                                          className="mt-2 mb-2 rounded"
                                          src={passImage || defaultBoxGrayImg}
                                        />
                                      )}
                                      <h4 className="text-white">
                                        {blockchain === "eth" && (
                                          <img
                                            src={ethWhiteImg}
                                            className="icon-eth mr-2"
                                          />
                                        )}
                                        {blockchain === "matic" && (
                                          <img
                                            src={polygonWhiteImg}
                                            className="icon-polygon mr-2"
                                          />
                                        )}
                                        <small>
                                          {passType && <>{passType} </>}
                                        </small>
                                        <strong>
                                          {truncateText(passName, 20)}
                                        </strong>
                                      </h4>
                                      <h5>
                                        <span className="text-white">
                                          <a
                                            href={tokenUrl}
                                            rel="external nofollow noopener"
                                            target="_blank"
                                          >
                                            <span className="text-info">
                                              {passContract}
                                            </span>{" "}
                                            <small>
                                              <FontAwesomeIcon
                                                icon="arrow-up-right-from-square"
                                                className="text-white ml-2"
                                              />
                                            </small>
                                          </a>
                                          <Button
                                            size="lg"
                                            color="white"
                                            className="btn-icon-only rounded-circle ml-3 float-right mt-3"
                                            onClick={() =>
                                              setShowPassQR(!showPassQR)
                                            }
                                          >
                                            <FontAwesomeIcon
                                              icon="fa-qrcode"
                                              className="fa-lg"
                                            />
                                          </Button>
                                        </span>
                                      </h5>
                                    </>
                                  )
                                )}

                                {passError && (
                                  <div>
                                    <span className="text-danger">
                                      <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                      {passError}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  </ModalBody>
                </Modal>
              )}
            </Container>
          </section>
        )}
      </div>
    </>
  );
}

export default Access;
