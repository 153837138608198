import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { AuthContext } from "Auth.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// reactstrap components
import {
  Alert,
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  InputGroupText,
  InputGroup,
  Label,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import TopNavbar from "components/Navbars/TopNavbar.js";
import Loader from "components/Loaders/Loader.js";

import ScaleLoader from "react-spinners/ScaleLoader";
import BarLoader from "react-spinners/BarLoader";

import {
  truncateAddressShort,
  truncateAddressLong,
  notify,
} from "utils/Utils.js";

import { requestAccountDeletion } from "utils/Api.js";

import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.compat.css";

const defaultProfileImg = require("assets/img/default/profile.svg");
const ethImg = require("assets/img/chains/eth-gray.svg");

const { REACT_APP_API_HOST, REACT_APP_GOODLE_RECAPTCHA_CHECKBOX_KEY } =
  process.env;

function DeleteAccount() {
  const {
    currentUser,
    currentAuthToken,
    currentUserInfo,
    setCurrentUserInfo,
    currentUserStatus,
    setCurrentUserStatus,
  } = useContext(AuthContext);

  const [confirmation, setConfirmation] = useState("");

  const [pageLoading, setPageLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const deleteAccount = async () => {
    if (
      currentUserStatus !== "user_request_deletion" &&
      currentUserStatus !== "suspended"
    ) {
      try {
        setDeleteLoading(true);

        const status = await requestAccountDeletion(currentAuthToken);

        if (status === "user_request_deletion") {
          notify(
            "Sorry to see you leave. We've received your account deletion request.",
            "",
            3000
          );

          setCurrentUserStatus(status);
        } else {
          notify("Account deletion failed.", "error");
        }
      } catch (err) {
        console.error(err.message);
        notify("Account deletion failed.", "error");
      } finally {
        setDeleteLoading(false);
        setDeleteModalOpen(false);
      }
    }
  };

  return (
    <>
      <BarLoader
        loading={pageLoading}
        cssOverride={{
          width: "100%",
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          zIndex: "1031",
          backgroundColor: "#fff",
          opacity: "1",
        }}
      />
      <TopNavbar />
      <ReactNotifications />
      <section className='section section-shaped section-main'>
        <Container>
          <Row className='justify-content-center'>
            <Col xl='9' lg='10' md='11' sm='12' xs='12'>
              <h3 className='text-danger mb-3'>
                <strong>Delete Account</strong>
              </h3>
              <Card className='card-main shadow'>
                <div className='m-2'>
                  <Row className='mt-3'>
                    <Col>
                      <div>
                        <strong className='text-danger mr-2'>
                          Danger Zone
                        </strong>
                      </div>
                      <Alert className='my-3 bg-secondary px-3'>
                        <Row>
                          <Col xl='9' lg='9' md='12' sm='12' xs='12'>
                            <div className='text-dark'>
                              <p className='mr-2'>
                                Account Deletion for{" "}
                                <strong>
                                  {currentUserInfo?.ethAddress ||
                                    currentUser?.email}
                                </strong>
                              </p>
                              <p>
                                <small>
                                  This is irreversible. All your offchain data
                                  we've stored will be deleted. However we won't
                                  be able to delete any data store in
                                  blockchain.
                                </small>
                              </p>
                            </div>
                          </Col>
                          <Col xl='3' lg='3' md='12' sm='12' xs='12'>
                            <Button
                              color='danger'
                              size='md'
                              onClick={() => setDeleteModalOpen(true)}
                              className='btn-icon float-right'
                            >
                              {currentUserStatus === "user_request_deletion"
                                ? "Account Deleted"
                                : currentUserStatus === "suspended"
                                ? "Suspended"
                                : "Delete Account"}
                            </Button>
                          </Col>
                        </Row>
                      </Alert>

                      <div className='mt-4 mb-3'>
                        <Button
                          className='btn btn-dark mt-4 btn-block'
                          color='dark'
                          href='/settings'
                          type='button'
                        >
                          Back
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={deleteModalOpen}>
            <div className='modal-header'>
              <strong className='text-danger modal-title' id='deleteModalLabel'>
                Confirm Account Deletion
              </strong>

              <button
                aria-label='Close'
                className=' close'
                type='button'
                onClick={() => setDeleteModalOpen(false)}
                disabled={deleteLoading}
              >
                <span aria-hidden={true}>
                  <FontAwesomeIcon icon='fa-xmark' />
                </span>
              </button>
            </div>

            <ModalBody>
              <div className='my-1'>
                <Label for='confirmation' className='required'>
                  Please type <strong>delete my account</strong> to confirm
                  deletion.
                </Label>
                <Input
                  id='confirmation'
                  value={confirmation}
                  onChange={(e) => {
                    setConfirmation(e.target.value);
                  }}
                  placeholder='delete my account'
                  type='text'
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                size='md'
                color='danger'
                type='button'
                onClick={deleteAccount}
                disabled={confirmation != "delete my account" || deleteLoading}
              >
                {deleteLoading ? (
                  <>
                    <span className='mr-2'>Deleting</span>
                    <ScaleLoader
                      color='#eee'
                      loading={deleteLoading}
                      height='10px'
                      cssOverride={{
                        display: "inline",
                      }}
                    />
                  </>
                ) : (
                  "Confirm Deletion"
                )}
              </Button>
              <Button
                size='md'
                color='link'
                type='button'
                onClick={() => {
                  if (!deleteLoading) {
                    setDeleteModalOpen(!deleteModalOpen);
                    setConfirmation("");
                  }
                }}
                disabled={deleteLoading}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </section>
    </>
  );
}

export default DeleteAccount;
