import React, { useState, useEffect, useContext } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

// import { ThirdwebProvider, ChainId } from "@thirdweb-dev/react";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/realpass-react.scss?v1.1.0";

import { ReactSession } from "react-client-session";

import Protected from "Protected.js";
import { AuthProvider } from "Auth.js";

import Index from "views/Pages/Index.js";
import Explore from "views/Pages/Explore.js";
import Event from "views/Pages/Event.js";
import PublicProfile from "views/Pages/PublicProfile.js";
import Settings from "views/User/Settings.js";
import DeleteAccount from "views/User/DeleteAccount.js";
import Signin from "views/Auth/Signin.js";
import Account from "views/User/Account.js";
import MyAccess from "views/User/MyAccess.js";
import MyAccessVerify from "views/User/MyAccessVerify.js";
import Create from "views/Creator/Create.js";
import AccessCreate from "views/Creator/AccessCreate.js";
import EventAccess from "views/Creator/EventAccess.js";
import EventCheckin from "views/Creator/EventCheckin.js";
import AccessUpdate from "views/Creator/AccessUpdate.js";
import PassCreate from "views/Creator/PassCreate.js";
import DashboardEvent from "views/Creator/DashboardEvent.js";
import EventCreate from "views/Creator/EventCreate.js";
import EventCreate2 from "views/Creator/EventCreate2.js";
import EventUpdate from "views/Creator/EventUpdate.js";
import RaffleCreate from "views/Creator/RaffleCreate.js";
import NotFound from "views/Static/NotFound.js";
import Terms from "views/Static/Terms.js";
import Privacy from "views/Static/Privacy.js";

import {
  WagmiConfig,
  createClient,
  defaultChains,
  configureChains,
  chain,
} from "wagmi";

import { alchemyProvider } from "wagmi/providers/alchemy";
import { infuraProvider } from "wagmi/providers/infura";
import { publicProvider } from "wagmi/providers/public";

import { InjectedConnector } from "wagmi/connectors/injected";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faDiscord,
  faInstagram,
  faYoutube,
  faMedium,
  faLinkedin,
  faBehance,
  faTelegram,
  faTiktok,
  faSpotify,
  faPatreon,
  faPinterest,
  faTwitch,
  faDribbble,
  faSoundcloud,
  faFacebook,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  fas,
  faTwitter,
  faDiscord,
  faInstagram,
  faYoutube,
  faMedium,
  faLinkedin,
  faBehance,
  faTelegram,
  faTiktok,
  faSpotify,
  faPatreon,
  faPinterest,
  faTwitch,
  faDribbble,
  faSoundcloud,
  faFacebook,
  faGithub
);

ReactSession.setStoreType("localStorage");

const root = ReactDOM.createRoot(document.getElementById("root"));

const { REACT_APP_ALCHEMY_API_KEY, REACT_APP_INFURA_API_KEY } = process.env;

// Configure chains & providers with the Alchemy provider.
// Two popular providers are Alchemy (alchemy.com) and Infura (infura.io)
const { chains, provider, webSocketProvider } = configureChains(
  [chain.mainnet, chain.polygon],
  [
    // alchemyProvider({ apiKey: REACT_APP_ALCHEMY_API_KEY, priority: 0 }),
    infuraProvider({ apeKey: REACT_APP_INFURA_API_KEY, priority: 1 }),
    publicProvider({
      priority: 2,
    }),
  ]
);

// Set up client
const wagmiClient = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    // new InjectedConnector({
    //   chains,
    //   options: {
    //     name: "MetaMask",
    //     shimDisconnect: true,
    //   },
    // }),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true,
      },
    }),
  ],
  provider,
  webSocketProvider,
});

root.render(
  <WagmiConfig client={wagmiClient}>
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<Index />} />

          <Route
            path="/account"
            exact
            element={
              <Protected>
                <Account />
              </Protected>
            }
          />

          <Route
            path="/create"
            exact
            element={
              <Protected>
                <Create />
              </Protected>
            }
          />

          <Route
            path="/create/event"
            exact
            element={
              <Protected>
                <EventCreate />
              </Protected>
            }
          />

          <Route
            path="/create/event2"
            exact
            element={
              <Protected>
                <EventCreate2 />
              </Protected>
            }
          />

          {/* <Route
            path="/event/update/:eventId"
            exact
            element={
              <Protected>
                <EventUpdate />
              </Protected>
            }
          /> */}

          {/* <Route
            path="/event/instant/:eventId"
            exact
            element={
              <Protected>
                <EventAccess />
              </Protected>
            }
          /> */}

          <Route
            path="/event/checkin/:eventId"
            exact
            element={
              <Protected>
                <EventCheckin />
              </Protected>
            }
          />

          <Route
            path="/create/access"
            exact
            element={
              <Protected>
                <AccessCreate />
              </Protected>
            }
          />

          {/* <Route
            path="/access/update/:eventId"
            exact
            element={
              <Protected>
                <AccessUpdate />
              </Protected>
            }
          /> */}

          <Route
            path="/dashboard/event/:eventId"
            exact
            element={
              <Protected>
                <DashboardEvent />
              </Protected>
            }
          />

          <Route
            path="/myaccess"
            exact
            element={
              <Protected>
                <MyAccess />
              </Protected>
            }
          />

          {/* <Route
            path="/myaccess/verify/:eventId"
            exact
            element={<MyAccessVerify />}
          /> */}

          <Route
            path="/create/pass"
            exact
            element={
              <Protected>
                <PassCreate />
              </Protected>
            }
          />

          <Route
            path="/create/raffle"
            exact
            element={
              <Protected>
                <RaffleCreate />
              </Protected>
            }
          />

          <Route
            path="/settings"
            exact
            element={
              <Protected>
                <Settings />
              </Protected>
            }
          />

          <Route
            path="/settings/delete"
            exact
            element={
              <Protected>
                <DeleteAccount />
              </Protected>
            }
          />

          {/* <Route path="/explore" exact element={<Explore />} /> */}

          <Route path="/event/:eventId" exact element={<Event />} />
          <Route path="/:web3id" exact element={<PublicProfile />} />
          <Route path="/signin" exact element={<Signin />} />
          <Route path="/terms" exact element={<Terms />} />
          <Route path="/privacy" exact element={<Privacy />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  </WagmiConfig>
);
