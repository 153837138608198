import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Alert,
  ButtonGroup,
  Button,
  Collapse,
  Card,
  Container,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  CardImg,
  CardBody,
  CardTitle,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Table,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  notify,
  genImageName,
  nDaysAfterTZ,
  handleReactDatetimeChange,
  getClassNameReactDatetimeDays,
} from "utils/Utils.js";

import ReactDatetime from "react-datetime";
import Moment from "react-moment";
import moment from "moment-timezone";

const { USDateTimeOption } = require("utils/Variables.js");

export default function MultiSessionsCollapse(props) {
  let { sessions, timezone } = props;

  const [showSessions, setShowSessions] = useState(true);

  const dateTZOption = JSON.parse(JSON.stringify(USDateTimeOption));
  dateTZOption.timeZone = timezone;

  const listSessions = () => {
    return sessions?.map((session, index) => {
      const start = new Date(session.startDateTime)?.toLocaleString(
        "en-US",
        dateTZOption
      );

      const end = new Date(session.endDateTime)?.toLocaleString(
        "en-US",
        dateTZOption
      );
      return (
        <div xs={12} sm={6} md={4} key={index} className="my-2  py-2">
          <div className="text-default">
            <FontAwesomeIcon icon="fa-clock" className="mr-2" />
            <strong>{session.name}</strong>
          </div>
          <div className="text-info ml-2">
            {start
              ? end
                ? start + " - " + end
                : start + " - " + "TBA"
              : "TBA"}
          </div>
        </div>
      );
    });
  };

  return (
    sessions?.length > 0 && (
      <Alert
        color="white"
        className="mt-2"
        style={{ border: "1px solid #ccc" }}
      >
        <Button
          className="text-primary"
          color="link"
          size="sm"
          block
          onClick={() => {
            setShowSessions(!showSessions);
          }}
        >
          All Sessions
          <FontAwesomeIcon icon="fa-list-ul" className="ml-2" />
        </Button>
        <Collapse isOpen={showSessions}>
          <div className="border-top mt-2">{listSessions()}</div>
        </Collapse>
      </Alert>
    )
  );
}
