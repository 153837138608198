import React from "react";

// reactstrap components
import {
  Alert,
  ButtonGroup,
  Button,
  Collapse,
  Card,
  Container,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  CardImg,
  CardBody,
  CardTitle,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Table,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function UrlButton(props) {
  let { url, size, style, message } = props;

  return (
    <ButtonGroup className={`my-2 btn-${style}`}>
      <Button
        id='urlButton'
        size={size}
        color='info'
        outline
        type='button'
        className='px-4'
        style={{ textTransform: "none" }}
        onClick={() => {
          if (url) {
            navigator.clipboard.writeText(url);
          }
        }}
      >
        {url ? (
          <>
            <FontAwesomeIcon icon='fa-link' className='mr-2' />
            {url}
          </>
        ) : (
          <>{message}</>
        )}
      </Button>

      {url && (
        <>
          <Button
            id='urlButton'
            size={size}
            color='info'
            type='button'
            onClick={() => navigator.clipboard.writeText(url)}
            style={{ maxWidth: "80px" }}
          >
            Copy
          </Button>
          <UncontrolledTooltip
            deplay={30}
            placement='top'
            target='urlButton'
            trigger='focus'
          >
            URL Copied
          </UncontrolledTooltip>
        </>
      )}
    </ButtonGroup>
  );
}
