import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from "react";

import { AuthContext } from "Auth.js";

// reactstrap components
import {
  Alert,
  ButtonGroup,
  Button,
  Collapse,
  Card,
  Container,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  CardImg,
  CardBody,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Table,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Form, Field } from "react-final-form";
import Select from "react-select";

import pluralize from "pluralize";

import {
  truncateText,
  truncateAddressShort,
  truncateAddressLong,
  hasDuplicates,
  validEthAddress,
  nativeCurrency,
  notify,
  genImageName,
  capitalize,
  formatPrettyNumber,
  validEmail,
  removeProtocolStr,
  getTimeBetween,
  getFormattedTimeBetween,
  NoDataText,
} from "utils/Utils.js";

import {
  creatorGetEventUsers,
  creatorBulkAddEventUsers,
  creatorUpdateEventUsers,
  creatorUpdateInviteSettings,
  creatorUpdateUserInviteStatus,
  creatorGetUsersInvites,
  creatorUpdateCreatorInviteStatus,
  creatorGetCreatorInvites,
  creatorUpdateCreatorInviteSettings,
  creatorAddCreatorInvites,
} from "utils/Api.js";

import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.compat.css";

import Avatar from "boring-avatars";

import Loader from "components/Loaders/Loader.js";
import LoaderDots from "components/Loaders/LoaderDots.js";
import LoaderSection from "components/Loaders/LoaderSection.js";
import LoaderModal from "components/Loaders/LoaderModal.js";

import ScaleLoader from "react-spinners/ScaleLoader";
import BarLoader from "react-spinners/BarLoader";

import UrlButton from "components/Buttons/UrlButton.js";

import ReactDataGrid from "@inovua/reactdatagrid-community";
import GridButton from "@inovua/reactdatagrid-community/packages/Button";
import "@inovua/reactdatagrid-community/index.css";

import ReactDatetime from "react-datetime";
import Moment from "react-moment";
import moment from "moment-timezone";

import Web3 from "web3";
import { getAddressesBalances } from "eth-balance-checker/lib/web3";

const {
  defaultFormImg,
  defaultFormBanner,
  defaultProfileImg,
  bgGradient,
  defaultBoxGrayImg,
  verifiedImg,
  ethImg,
  nsfwImg,
  mmImg,
  wcImg,
  pwImg,
  ethWhiteImg,
  ethLogoColor,
  polygonImg,
  polygonWhiteImg,
  polygonLogoColor,
  bscLogoColor,
  ftmLogoColor,
  avaxLogoColor,
  cronosLogoColor,
  solLogoColor,
  catTokenImg,
  catArtImg,
  catMusicImg,
  catGameImg,
  catPartyImg,
  catSportsImg,
  catDaoImg,
  catMeetingImg,
  catStudyImg,
  catLeisureImg,
  catFundingImg,
  catMiscImg,
  ChainOptions,
  EventCategoryOptions,
  EventCategorySelectorOptions,
  CreatorRoleTypes,
  DisableMaxUsageOption,
  InviteMaxUsageOptions,
  InviteExpireOptions,
  ChainSelectorOptions,
  USDateOption,
  USDateTimeOption,
  UserRoleTypes,
  UserRoleStatus,
  VerifiedUserTypes,
  VerifiedUserStatus,
  QuillEditorModules,
  QuillEditorFormats,
} = require("utils/Variables.js");

export default function EventMembers(props) {
  let { event, eventId, eventName, updateEvent, isEventActiveCreator } = props;

  const {
    currentUser,
    currentUserId,
    currentAuthToken,
    currentUserInfo,
    setCurrentUserInfo,
  } = useContext(AuthContext);

  const hasAdminRole = currentUserInfo?.hasAdminRole;
  const hasCreatorRole = currentUserInfo?.hasCreatorRole;
  const isVerifiedUser = currentUserInfo?.isVerifiedUser;

  const [modalLoading, setModalLoading] = useState(false);

  const [teamInviteOptionSaving, setTeamInviteOptionSaving] = useState(false);
  const [creatorInviteCodeAdding, setCreatorInviteCodeAdding] = useState(false);
  const [creatorInviteStatusUpdating, setCreatorInviteStatusUpdating] =
    useState(false);
  const [creatorInviteGridLoading, setCreatorInviteGridLoading] =
    useState(false);

  const [memberInviteGridLoading, setMemberInviteGridLoading] = useState(false);
  const [memberInviteStatusUpdating, setMemberInviteStatusUpdating] =
    useState(false);
  const [memberInviteOptionSaving, setMemberInviteOptionSaving] =
    useState(false);
  const [memberInviteWalletFinding, setMemberInviteWalletFinding] =
    useState(false);
  const [eventUsersGridLoading, setEventUsersGridLoading] = useState(false);

  const [eventUsers, setEventUsers] = useState([]);
  const [eventUserIds, setEventUserIds] = useState([]);
  const [totalEventUsers, setTotalEventUsers] = useState(0);

  // filter "all", "active", "inactive", "bots"
  const [membersTableFilter, setMembersTableFilter] = useState("active");
  const [botsIpCount, setBotsIpCount] = useState(5);
  const [botsRegCount, setBotsRegCount] = useState(5);

  const [topParticipants, setTopParticipants] = useState([]);
  const [topUsersFilter, setTopUsersFilter] = useState("Invites");

  const [membersSelected, setMembersSelected] = useState({});
  const [membersUnselected, setMembersUnselected] = useState({});
  const [filteredEventMembers, setFilteredEventMembers] = useState([]);
  // const [filteredEventMembersCount, setFilteredEventMembersCount] = useState(0);

  const [allParticipants, setAllParticipants] = useState([]);

  const [memberDetailInput, setMemberDetailInput] = useState("");
  // const [memberDetailToFind, setMemberDetailToFind] = useState();

  // const [membersFilterCollapseOpen, setMembersFilterCollapseOpen] =
  //   useState(false);

  // const [allMembersFilter, setAllMembersFilter] = useState(true);
  // const [activeMembersFilter, setActiveMembersFilter] = useState(false);
  // const [inactiveMembersFilter, setInactiveMembersFilter] = useState(false);
  // const [botMembersFilter, setBotMembersFilter] = useState(false);

  const [bulkMemberAddresses, setBulkMemberAddresses] = useState("");
  const [bulkMemberAddressList, setBulkMemberAddressList] = useState([]);

  const [allMembersTableRefresh, setAllMembersTableRefresh] = useState(false);
  const [bulkAddMembersError, setBulkAddMembersError] = useState();
  const [confirmAddMembers, setConfirmAddMembers] = useState(false);

  const CreatorMaxOptions =
    event.isOwner || event.maxCreatorInvites < 0
      ? InviteMaxUsageOptions
      : event.maxCreatorInvites === 0
      ? DisableMaxUsageOption
      : InviteMaxUsageOptions.filter((item) => {
          return item.value > 0 && item.value <= event.maxCreatorInvites;
        });

  const [teamInviteCodesCount, setTeamInviteCodesCount] = useState(0);
  const [creatorInviteCodesCount, setCreatorInviteCodesCount] = useState(0);
  const [creatorInviteMaxUsageOption, setCreatorInviteMaxUsageOption] =
    useState(CreatorMaxOptions[0]);
  const [creatorInviteExpireOption, setCreatorInviteExpireOption] = useState(
    InviteExpireOptions[0]
  );

  const teamInviteEnabled =
    event?.maxCreatorInvites > 0 || event?.maxCreatorInvites < 0;
  const [enableTeamInvite, setEnableTeamInvite] = useState(teamInviteEnabled);
  const maxTeamInvites = InviteMaxUsageOptions.find(
    (option) => option?.value === event?.maxCreatorInvites
  );
  const [teamInviteMaxUsageOption, setTeamInviteMaxUsageOption] = useState(
    teamInviteEnabled ? maxTeamInvites : DisableMaxUsageOption
  );
  const [enableTeamInviteInput, setEnableTeamInviteInput] =
    useState(teamInviteEnabled);
  const [teamInviteMaxUsageOptionInput, setTeamInviteMaxUsageOptionInput] =
    useState(teamInviteEnabled ? maxTeamInvites : DisableMaxUsageOption);
  const [teamInviteOptionChanged, setTeamInviteOptionChanged] = useState(false);

  const usersInviteEnabled =
    event?.maxUserInvites > 0 || event?.maxUserInvites < 0;
  const [enableMemberInvite, setEnableMemberInvite] =
    useState(usersInviteEnabled);
  const maxUserInvites = InviteMaxUsageOptions.find(
    (option) => option?.value === event?.maxUserInvites
  );
  const [memberInviteMaxUsageOption, setMemberInviteMaxUsageOption] = useState(
    usersInviteEnabled ? maxUserInvites : DisableMaxUsageOption
  );
  const [enableMemberInviteInput, setEnableMemberInviteInput] =
    useState(usersInviteEnabled);
  const [memberInviteMaxUsageOptionInput, setMemberInviteMaxUsageOptionInput] =
    useState(usersInviteEnabled ? maxUserInvites : DisableMaxUsageOption);
  const [memberInviteOptionChanged, setMemberInviteOptionChanged] =
    useState(false);

  const [memberInviteCodesCount, setMemberInviteCodesCount] = useState(0);
  const [totalEventUserInvites, setTotalEventUserInvites] = useState(0);
  const [eventUserInviteIds, setEventUserInviteIds] = useState([]);
  const [memberInviteWalletInput, setMemberInviteWalletInput] = useState("");
  const [memberInviteWalletToFind, setMemberInviteWalletToFind] = useState();
  const [memberInviteWalletError, setMemberInviteWalletError] = useState();

  const [memberInviteCodesRefresh, setMemberInviteCodesRefresh] =
    useState(false);
  const [memberInviteAction, setMemberInviteAction] = useState();
  const [memberInviteActionCode, setMemberInviteActionCode] = useState();
  const [memberInviteActionUserId, setMemberInviteActionUserId] = useState();
  const [memberInviteActionMemberName, setMemberInviteActionMemberName] =
    useState();
  const [memberInviteActionMemberWallet, setMemberInviteActionMemberWallet] =
    useState();

  const [creatorInviteCodesRefresh, setCreatorInviteCodesRefresh] =
    useState(false);

  const [creatorInviteAction, setCreatorInviteAction] = useState();
  const [creatorInviteActionUserId, setCreatorInviteActionUserId] = useState();
  const [creatorInviteActionCode, setCreatorInviteActionCode] = useState();
  const [creatorInviteLink, setCreatorInviteLink] = useState();

  const [creatorInviteSelected, setCreatorInviteSelected] = useState(null); // Important

  const [inviteCodeModalOpen, setInviteCodeModalOpen] = useState(false);
  const [memberInviteModalOpen, setMemberInviteModalOpen] = useState(false);
  const [bulkAddMembersModalOpen, setBulkAddMembersModalOpen] = useState(false);

  const [memberInviteActionCollapseOpen, setMemberInviteActionCollapseOpen] =
    useState(false);

  const [creatorInviteActionCollapseOpen, setCreatorInviteActionCollapseOpen] =
    useState(false);

  const [selectedMemberIds, setSelectedMemberIds] = useState([]);
  const [membersAction, setMembersAction] = useState();
  const [membersActionError, setMembersActionError] = useState();
  const [membersActionModalOpen, setMembersActionModalOpen] = useState(false);

  const renderColumnContextMenu = useCallback((menuProps, { cellProps }) => {
    menuProps.items = null;
  }, []);

  const memberInviteGridStyle = { minHeight: 250, minWidth: 450 };

  const memberInviteCols = [
    {
      id: "member",
      defaultFlex: 6,
      header: "Member",
      render: ({ data }) => (
        <a href={`/${data?.ethAddress}`} target="_blank">
          {data?.imageUrl ? (
            <img
              alt="..."
              className="profile-image-xs rounded-circle"
              src={data?.imageUrl}
            />
          ) : (
            <span className="profile-image-xs rounded-circle">
              <Avatar
                size={28}
                name={data?.ethAddress}
                square="false"
                variant="ring"
                colors={["#000000", "#2F2F2F", "#505050", "#797979", "#CECECE"]}
                className="rounded-circle profile-image"
              />
            </span>
          )}
          <span className="ml-2">
            <strong>{truncateText(data.name, 13) || "Unnamed"}</strong>
            {data.isVerifiedUser && (
              <img
                className="realpass-verified-name-suffix-sm ml-2 mb-1"
                src={verifiedImg}
              />
            )}
          </span>
        </a>
      ),
    },
    {
      id: "inviteCode",
      defaultFlex: 4,
      header: "Code",
      render: ({ data }) => (
        <>
          {data.inviteStatus === "active" ? (
            <span
              style={{ cursor: "pointer" }}
              id={`memberInvite-${data.inviteCode}`}
            >
              <strong>{data.inviteCode}</strong>
            </span>
          ) : (
            <>
              <span
                style={{ cursor: "pointer" }}
                id={`memberInvite-${data.inviteCode}`}
              >
                <s>
                  <strong>{data.inviteCode}</strong>
                </s>
              </span>

              <UncontrolledTooltip
                placement="top"
                target={`memberInvite-${data.inviteCode}`}
                trigger="hover"
              >
                This invite Code is not active.
              </UncontrolledTooltip>
            </>
          )}

          <Badge
            pill
            style={{ cursor: "pointer", textTransform: "none" }}
            id={`memberInviteCount-${data.inviteCode}`}
            color={data.totalInvites > 99 ? "primary" : "secondary"}
            className={`ml-2 ${
              data.totalInvites > 49
                ? "text-yellow"
                : data.totalInvites > 0
                ? "text-success"
                : "text-primary"
            }`}
          >
            {formatPrettyNumber(data.totalInvites)} invited
          </Badge>

          <UncontrolledTooltip
            placement="top"
            target={`memberInviteCount-${data.inviteCode}`}
            trigger="hover"
          >
            {data.totalInvites}{" "}
            {data.totalInvites > 1 ? "members have" : "member has"} joined using
            this code.
          </UncontrolledTooltip>
        </>
      ),
    },
    {
      id: "action",
      defaultFlex: 2,
      header: "Action",
      render: ({ data }) => (
        <>
          {data.inviteStatus === "active" ? (
            <>
              <span
                id={`deactivateMemberInviteButton-${data.inviteCode}`}
                style={{ cursor: "pointer !important" }}
              >
                <Button
                  color="link"
                  className="btn-icon-only m-0"
                  onClick={() =>
                    confirmMemberInviteAction(
                      data.userId,
                      data.name,
                      data.wallet,
                      data.inviteCode,
                      "deactivate",
                      true
                    )
                  }
                  disabled={!enableMemberInvite}
                >
                  <FontAwesomeIcon
                    icon={enableMemberInvite ? "fa-toggle-on" : "fa-toggle-off"}
                    className={`fa-xl ${
                      enableMemberInvite ? "text-info" : "text-muted"
                    }`}
                  />
                </Button>
              </span>
              {!enableMemberInvite && (
                <UncontrolledTooltip
                  placement="top"
                  target={`deactivateMemberInviteButton-${data.inviteCode}`}
                  trigger="hover"
                >
                  Member invite is not available
                </UncontrolledTooltip>
              )}
            </>
          ) : (
            <>
              <span
                id={`activateMemberInviteButton-${data.inviteCode}`}
                style={{ cursor: "pointer !important" }}
              >
                <Button
                  color="link"
                  className="btn-icon-only m-0"
                  onClick={() =>
                    confirmMemberInviteAction(
                      data.userId,
                      data.name,
                      data.wallet,
                      data.inviteCode,
                      "activate",
                      true
                    )
                  }
                  disabled={!enableMemberInvite}
                >
                  <FontAwesomeIcon
                    icon="fa-toggle-off"
                    className={`fa-xl ${
                      enableMemberInvite ? "text-gray" : "text-muted"
                    }`}
                  />
                </Button>
              </span>

              {!enableMemberInvite && (
                <UncontrolledTooltip
                  placement="top"
                  target={`activateMemberInviteButton-${data.inviteCode}`}
                  trigger="hover"
                >
                  Member invite is disabled
                </UncontrolledTooltip>
              )}
            </>
          )}
        </>
      ),
    },
  ];

  const findMemberInviteWallet = async () => {
    setMemberInviteWalletFinding(true);

    const address = memberInviteWalletInput.toLowerCase();
    const isAddress = validEthAddress(address);

    if (!isAddress) {
      setMemberInviteWalletError("Invalid wallet address.");
    } else {
      setMemberInviteWalletError();
      setMemberInviteWalletToFind(address);
    }

    setMemberInviteWalletFinding(false);
  };

  const fetchMemberInvites = async ({ skip, limit, sortInfo }) => {
    try {
      setEventUsersGridLoading(true);

      let wallet;

      if (memberInviteWalletToFind) {
        wallet = memberInviteWalletToFind;
      }

      const res = await creatorGetUsersInvites(
        currentAuthToken,
        eventId,
        limit,
        skip,
        wallet
      );

      const data = res?.eventUsers || [];
      const count = res?.totalEventUsers || 0;

      setMemberInviteCodesCount(count);

      const ids = data?.map((item) => item?.userId);
      setEventUserInviteIds(ids);

      return { data, count };
    } catch (err) {
      console.error(err);
      notify("Unable to fetch members invites.", "error");
    } finally {
      setEventUsersGridLoading(false);
    }
  };

  const toggleEnableMemberInvite = (memberInviteFlag) => {
    setEnableMemberInviteInput(memberInviteFlag);

    if (memberInviteFlag) {
      setMemberInviteMaxUsageOptionInput(InviteMaxUsageOptions[0]);
    } else {
      setMemberInviteMaxUsageOptionInput(DisableMaxUsageOption);
    }
  };

  const handleMemberInviteSettings = async () => {
    try {
      setMemberInviteOptionSaving(true);

      let maxInvites = 0;

      if (enableMemberInviteInput) {
        maxInvites = memberInviteMaxUsageOptionInput?.value;
      }

      const res = await creatorUpdateInviteSettings(
        currentAuthToken,
        eventId,
        maxInvites,
        "user"
      );

      if (res) {
        const newMaxInvites = res?.maxUserInvites;

        const newMaxInvitesEnabled = newMaxInvites > 0 || newMaxInvites < 0;

        const newMaxInvitesOption = InviteMaxUsageOptions.find(
          (option) => option?.value === newMaxInvites
        );

        setEnableMemberInvite(newMaxInvitesEnabled);
        setMemberInviteMaxUsageOption(
          newMaxInvitesEnabled ? newMaxInvitesOption : DisableMaxUsageOption
        );
        setMemberInviteOptionChanged(false);

        updateEvent({ ...event, ...res });

        notify(
          `Successfully ${
            !maxInvites ? "disabled" : "enabled"
          } member invites.`,
          "success"
        );
      } else {
        throw { message: "Unable to update member invites settings." };
      }
    } catch (err) {
      console.error(err.message);
      notify(`Unable to update member invite settings.`, "error");
    } finally {
      setMemberInviteOptionSaving(false);
    }
  };

  const handleMemberInviteAction = async (userId, code, action) => {
    try {
      setMemberInviteStatusUpdating(true);

      if (action !== "activate" && action !== "deactivate") {
        return;
      }

      const res = await creatorUpdateUserInviteStatus(
        currentAuthToken,
        eventId,
        userId,
        code,
        action
      );

      if (res) {
        confirmMemberInviteAction();
        setMemberInviteCodesRefresh(!memberInviteCodesRefresh);
        notify(`Successfully ${action} the invite code.`, "success");
      } else {
        notify(`Unable to ${action} the invite code.`, "error");
      }
    } catch (err) {
      console.error(err.message);
      notify(`Unable to ${action} the invite code.`, "error");
    } finally {
      setMemberInviteStatusUpdating(false);
    }
  };

  const confirmMemberInviteAction = (
    userId,
    name,
    wallet,
    code,
    action,
    isOpen
  ) => {
    setMemberInviteAction(action);
    setMemberInviteActionCode(code);
    setMemberInviteActionUserId(userId);
    setMemberInviteActionMemberName(name);
    setMemberInviteActionMemberWallet(wallet);
    setMemberInviteActionCollapseOpen(isOpen);
  };

  const memberInviteDataSource = useCallback(fetchMemberInvites, [
    memberInviteCodesRefresh,
    memberInviteWalletToFind,
  ]);

  const creatorInviteGridStyle = { minHeight: 250, minWidth: 600 };

  const creatorInviteCols = [
    {
      id: "team",
      defaultFlex: 6,
      header: "Team",
      render: ({ data }) => (
        <>
          <a href={`/${data?.ethAddress}`} target="_blank">
            {data?.imageUrl ? (
              <img
                alt="..."
                className="profile-image-xs rounded-circle"
                src={data?.imageUrl}
              />
            ) : (
              <span className="profile-image-xs rounded-circle">
                <Avatar
                  size={28}
                  name={data?.ethAddress}
                  square="false"
                  variant="ring"
                  colors={[
                    "#000000",
                    "#2F2F2F",
                    "#505050",
                    "#797979",
                    "#CECECE",
                  ]}
                  className="rounded-circle profile-image"
                />
              </span>
            )}
            <span
              className={`ml-2 ${
                data?.registerStatus === "inactive"
                  ? "text-muted"
                  : data?.registerStatus === "banned" && "text-danger"
              }`}
            >
              <strong>{truncateText(data?.name, 13) || "Unnamed"}</strong>
              {data.isVerifiedUser && (
                <img
                  className="realpass-verified-name-suffix-sm ml-2 mb-1"
                  src={verifiedImg}
                />
              )}
            </span>{" "}
            <small className="text-muted">({capitalize(data.eventRole)})</small>
          </a>
        </>
      ),
    },
    {
      id: "inviteCode",
      defaultFlex: 3.7,
      header: "Code",
      render: ({ data }) => (
        <>
          {data.inviteCode ? (
            <>
              {" "}
              {data.inviteStatus === "active" ? (
                <span
                  style={{ cursor: "pointer" }}
                  id={`creatorInvite-${data.inviteCode}`}
                >
                  <strong>{data.inviteCode}</strong>
                </span>
              ) : (
                <>
                  <span
                    style={{ cursor: "pointer" }}
                    id={`creatorInvite-${data.inviteCode}`}
                  >
                    <s>
                      <strong>{data.inviteCode}</strong>
                    </s>
                  </span>

                  <UncontrolledTooltip
                    placement="top"
                    target={`creatorInvite-${data.inviteCode}`}
                    trigger="hover"
                  >
                    This invite Code is not active.
                  </UncontrolledTooltip>
                </>
              )}
              <Badge
                pill
                style={{ cursor: "pointer", textTransform: "none" }}
                id={`creatorInviteCount-${data.inviteCode}`}
                color={data.totalInvites > 99 ? "primary" : "secondary"}
                className={`ml-2 ${
                  data.totalInvites > 49
                    ? "text-yellow"
                    : data.totalInvites > 0
                    ? "text-success"
                    : "text-primary"
                }`}
              >
                {formatPrettyNumber(data.totalInvites)} invited
              </Badge>
              <UncontrolledTooltip
                placement="top"
                target={`creatorInviteCount-${data.inviteCode}`}
                trigger="hover"
              >
                {data.totalInvites}{" "}
                {data.totalInvites > 1 ? "members have" : "member has"} joined
                using this code.
              </UncontrolledTooltip>
            </>
          ) : (
            <small className="text-muted">Not Created</small>
          )}
        </>
      ),
    },
    {
      name: "limit",
      defaultFlex: 4.1,
      header: "Limit",
      render: ({ data }) => (
        <>
          {data.inviteCode ? (
            <>
              <Badge
                id={`creatorInviteLimit-${data.inviteCode}`}
                style={{ cursor: "pointer", textTransform: "none" }}
                className="text-primary mr-1"
              >
                {!enableTeamInvite && data.eventRole !== "owner" ? (
                  pluralize("use", 0, true)
                ) : data.eventRole === "owner" ||
                  event.maxCreatorInvites < 0 ? (
                  data.maxInvites >= 0 ? (
                    pluralize("use", data.maxInvites || 0, true)
                  ) : (
                    <>
                      <FontAwesomeIcon icon="fa-infinity" /> uses
                    </>
                  )
                ) : event.maxCreatorInvites <= data.maxInvites ? (
                  pluralize("use", event.maxCreatorInvites || 0, true)
                ) : data.maxInvites >= 0 ? (
                  pluralize("use", data.maxInvites || 0, true)
                ) : (
                  <>
                    <FontAwesomeIcon icon="fa-infinity" /> uses
                  </>
                )}
              </Badge>
              <UncontrolledTooltip
                placement="top"
                target={`creatorInviteLimit-${data.inviteCode}`}
                trigger="hover"
              >
                {data.maxInvites >= 0 ? (
                  <>
                    Invite code can be used up to{" "}
                    {pluralize("time", data.maxInvites || 0, true)}
                  </>
                ) : (
                  <>There is no invite limit.</>
                )}
              </UncontrolledTooltip>
              <Badge
                id={`creatorInviteEnd-${data.inviteCode}`}
                style={{ cursor: "pointer", textTransform: "none" }}
                className="text-primary mr-1"
              >
                {data.inviteStatus !== "active" ? (
                  "Inactive"
                ) : !data.inviteEndDateTime ? (
                  "Never Ends"
                ) : new Date(data.inviteEndDateTime)?.getTime() <=
                  new Date().getTime() ? (
                  "Ended"
                ) : (
                  <>
                    End in{" "}
                    {getFormattedTimeBetween(
                      new Date().getTime(),
                      new Date(data.inviteEndDateTime)?.getTime()
                    )}
                  </>
                )}
              </Badge>
              <UncontrolledTooltip
                placement="top"
                target={`creatorInviteEnd-${data.inviteCode}`}
                trigger="hover"
              >
                {data.inviteStatus !== "active" ? (
                  "Invite is not active."
                ) : !data.inviteEndDateTime ? (
                  "Invite code never expires."
                ) : new Date(data?.inviteEndDateTime)?.getTime() <=
                  new Date().getTime() ? (
                  "Invite code has expired."
                ) : (
                  <>
                    Invite code expire on{" "}
                    {new Date(data?.inviteEndDateTime)?.toLocaleString(
                      "en-US",
                      USDateTimeOption
                    )}
                  </>
                )}
              </UncontrolledTooltip>
            </>
          ) : (
            <small className="text-muted">N/A</small>
          )}
        </>
      ),
    },
    {
      id: "action",
      defaultFlex: 1.9,
      header: "Action",
      render: ({ data }) => (
        <>
          {data.inviteStatus === "active" ? (
            <>
              <span
                id={`deactivateCreatorInviteButton-${data.inviteCode}`}
                style={{ cursor: "pointer !important" }}
              >
                <Button
                  color="link"
                  className="btn-icon-only m-0"
                  onClick={() =>
                    confirmCreatorInviteAction(
                      data.userId,
                      data.inviteCode,
                      "deactivate",
                      true
                    )
                  }
                  disabled={
                    (!enableTeamInvite && data.eventRole !== "owner") ||
                    (data.inviteEndDateTime &&
                      new Date(data.inviteEndDateTime)?.getTime() <=
                        new Date().getTime()) ||
                    (!event.isOwner && currentUserId !== data.userId)
                  }
                >
                  <FontAwesomeIcon
                    icon={
                      (!enableTeamInvite && data.eventRole !== "owner") ||
                      (data.inviteEndDateTime &&
                        new Date(data.inviteEndDateTime)?.getTime() <=
                          new Date().getTime())
                        ? "fa-toggle-off"
                        : "fa-toggle-on"
                    }
                    className={`fa-xl
                    ${
                      (!enableTeamInvite && data.eventRole !== "owner") ||
                      (data.inviteEndDateTime &&
                        new Date(data.inviteEndDateTime)?.getTime() <=
                          new Date().getTime())
                        ? "text-muted"
                        : "text-info"
                    }`}
                  />
                </Button>
              </span>

              {((!enableTeamInvite && data.eventRole !== "owner") ||
                (data.inviteEndDateTime &&
                  new Date(data.inviteEndDateTime)?.getTime() <=
                    new Date().getTime()) ||
                (!event.isOwner && currentUserId !== data.userId)) && (
                <UncontrolledTooltip
                  placement="top"
                  target={`deactivateCreatorInviteButton-${data.inviteCode}`}
                  trigger="hover"
                >
                  Invite update is not available
                </UncontrolledTooltip>
              )}
            </>
          ) : (
            <>
              <span
                id={`activateCreatorInviteButton-${data.inviteCode}`}
                style={{ cursor: "pointer !important" }}
              >
                <Button
                  color="link"
                  className="btn-icon-only m-0"
                  onClick={() =>
                    confirmCreatorInviteAction(
                      data.userId,
                      data.inviteCode,
                      "activate",
                      true
                    )
                  }
                  disabled={
                    (!enableTeamInvite && data.eventRole !== "owner") ||
                    (data.inviteEndDateTime &&
                      new Date(data.inviteEndDateTime)?.getTime() <=
                        new Date().getTime()) ||
                    (!event.isOwner && currentUserId !== data.userId)
                  }
                >
                  <FontAwesomeIcon
                    icon="fa-toggle-off"
                    className={`fa-xl 
                    ${
                      (!enableTeamInvite && data.eventRole !== "owner") ||
                      (data.inviteEndDateTime &&
                        new Date(data.inviteEndDateTime)?.getTime() <=
                          new Date().getTime())
                        ? "text-muted"
                        : "text-gray"
                    }`}
                  />
                </Button>
              </span>

              {((!enableTeamInvite && data.eventRole !== "owner") ||
                (data.inviteEndDateTime &&
                  new Date(data.inviteEndDateTime)?.getTime() <=
                    new Date().getTime()) ||
                (!event.isOwner && currentUserId !== data.userId)) && (
                <UncontrolledTooltip
                  placement="top"
                  target={`activateCreatorInviteButton-${data.inviteCode}`}
                  trigger="hover"
                >
                  Invite update is not available
                </UncontrolledTooltip>
              )}
            </>
          )}
        </>
      ),
    },
  ];

  const fetchCreatorInvites = async () => {
    try {
      setCreatorInviteGridLoading(true);

      let data = [];
      let count;

      const res = await creatorGetCreatorInvites(currentAuthToken, eventId);

      data = res?.creatorInvites;
      count = data?.length || 0;

      const creatorOwnInvite = data?.filter(
        (item) => item.userId === currentUserId
      );

      setCreatorInviteCodesCount(creatorOwnInvite?.length);
      setTeamInviteCodesCount(data?.length);

      return { data, count };
    } catch (err) {
      console.error(err.message);
      notify("Unable to fetch creator invites.", "error");
    } finally {
      setCreatorInviteGridLoading(false);
    }
  };

  const creatorInviteDataSource = useCallback(fetchCreatorInvites, [
    creatorInviteCodesRefresh,
  ]);

  const toggleEnableTeamInvite = (teamInviteFlag) => {
    setEnableTeamInviteInput(teamInviteFlag);

    if (teamInviteFlag) {
      setTeamInviteMaxUsageOptionInput(InviteMaxUsageOptions[0]);
    } else {
      setTeamInviteMaxUsageOptionInput(DisableMaxUsageOption);
    }
  };

  const handleTeamInviteSettings = async () => {
    try {
      setTeamInviteOptionSaving(true);

      let maxInvites = 0;

      if (enableTeamInviteInput) {
        maxInvites = teamInviteMaxUsageOptionInput?.value;
      }

      const res = await creatorUpdateInviteSettings(
        currentAuthToken,
        eventId,
        maxInvites,
        "creator"
      );

      if (res) {
        const newMaxInvites = res?.maxCreatorInvites;

        const newMaxInvitesEnabled = newMaxInvites > 0 || newMaxInvites < 0;

        const newMaxInvitesOption = InviteMaxUsageOptions.find(
          (option) => option?.value === newMaxInvites
        );

        setEnableTeamInvite(newMaxInvitesEnabled);
        setTeamInviteMaxUsageOption(
          newMaxInvitesEnabled ? newMaxInvitesOption : DisableMaxUsageOption
        );
        setTeamInviteOptionChanged(false);

        updateEvent({ ...event, ...res });

        notify(
          `Successfully ${
            !maxInvites ? "disabled" : "enabled"
          } creator invites.`,
          "success"
        );
      } else {
        throw { message: "Unable to update creator invites settings." };
      }
    } catch (err) {
      console.error(err.message);
      notify(`Unable to update creator invite settings.`, "error");
    } finally {
      setTeamInviteOptionSaving(false);
    }
  };

  const handleCreatorInviteAction = async (userId, code, action) => {
    try {
      setCreatorInviteStatusUpdating(true);

      if (action !== "activate" && action !== "deactivate") {
        return;
      }

      const res = await creatorUpdateCreatorInviteStatus(
        currentAuthToken,
        eventId,
        userId,
        code,
        action
      );

      if (res) {
        confirmCreatorInviteAction();
        setCreatorInviteCodesRefresh(!creatorInviteCodesRefresh);
        notify(`Successfully ${action} the invite code.`, "success");
      } else {
        notify(`Unable to ${action} the invite code.`, "error");
      }
    } catch (err) {
      console.error(err.message);
      notify(`Unable to ${action} the invite code.`, "error");
    } finally {
      setCreatorInviteStatusUpdating(false);
    }

    // try {
    //   // setModalLoading(true);
    //   // if (
    //   //   action !== "activate" &&
    //   //   action !== "deactivate" &&
    //   //   action !== "delete"
    //   // ) {
    //   //   return;
    //   // }
    //   // let params = {
    //   //   eventId: eventId,
    //   //   inviteCode: code,
    //   //   action: action,
    //   // };
    //   // const actionRes = await Moralis.Cloud.run(
    //   //   "creatorInviteCodeAction",
    //   //   params
    //   // );
    //   // setModalLoading(false);
    //   // setCreatorInviteLink();
    //   // notify(`Successfully ${action} the invite code.`, "success", 2000);
    //   // setCreatorInviteActionCollapseOpen(false);
    //   // setCreatorInviteCodesRefresh(!creatorInviteCodesRefresh);
    // } catch (err) {
    //   console.error(err.message);
    //   setModalLoading(false);

    //   notify(`Unable to ${action} the invite code.`, "error");
    // }
  };

  const confirmCreatorInviteAction = (userId, code, action, isOpen) => {
    setCreatorInviteActionUserId(userId);
    setCreatorInviteAction(action);
    setCreatorInviteActionCode(code);
    setCreatorInviteActionCollapseOpen(isOpen);
  };

  const handleCreatorInviteSelected = (code) => {
    if (code) {
      setCreatorInviteLink(`realpass.xyz/${eventId}?i=${code}`);
    } else {
      setCreatorInviteLink();
    }
  };

  // // If selected changes, update the link
  const onCreatorInviteSelectionChange = useCallback(({ selected }) => {
    setCreatorInviteSelected(selected);
    handleCreatorInviteSelected(selected);
  }, []);

  const handleNewCreatorInvite = async () => {
    try {
      if (creatorInviteCodesCount >= 5) {
        return;
      }
      setCreatorInviteCodeAdding(true);

      let maxInvites;
      if (event.isOwner || enableTeamInvite) {
        maxInvites = creatorInviteMaxUsageOption?.value || 0;
      }

      let expireAfter;
      if (event.isOwner || enableTeamInvite) {
        expireAfter = creatorInviteExpireOption?.value || 0;
      }

      const res = await creatorAddCreatorInvites(
        currentAuthToken,
        eventId,
        maxInvites,
        expireAfter
      );

      if (res) {
        setCreatorInviteCodesRefresh(!creatorInviteCodesRefresh);
        setCreatorInviteMaxUsageOption(CreatorMaxOptions[0]);
        setCreatorInviteExpireOption(InviteExpireOptions[0]);

        notify("Successfully created a new invite.", "success");
      }

      // if (creatorInviteCodesCount < 10) {
      //   const creatorInvite = new NFInviteCode();

      //   const generateHash = new RandomHash();
      //   let exists = true;
      //   let newkey;

      //   while (exists) {
      //     newkey = generateHash();

      //     exists = await Moralis.Cloud.run("inviteCodeExists", {
      //       eventId: eventId,
      //       inviteCode: newkey,
      //     });
      //   }

      //   const createRes = await Moralis.Cloud.run("creatorInviteCodeAction", {
      //     eventId: eventId,
      //     inviteCode: newkey,
      //     maxUsage: creatorInviteMaxUsageOption.value,
      //     endDateTime: new Date(
      //       new Date().getTime() + creatorInviteExpireOption.value
      //     ),
      //     action: "create",
      //   });
      //   setModalLoading(false);

      //   if (createRes) {
      //     handleCreatorInviteSelected(newkey);

      //     notify("Successfully created a new invite.", "success", 2000);

      //     setCreatorInviteCodesRefresh(!creatorInviteCodesRefresh);
      //   } else {
      //     throw { message: "Unable create the new invite." };
      //   }
      // }
    } catch (err) {
      console.error(err.message);
      notify("Unable create the new invite.", "error");
    } finally {
      setCreatorInviteCodeAdding(false);
    }
  };

  const downloadMembersBlob = (
    blob,
    fileName = "realpass-members-data.csv"
  ) => {
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.position = "absolute";
    link.style.visibility = "hidden";

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const SEPARATOR = ",";

  const membersCols = [
    {
      id: "member",
      defaultFlex: 6,
      header: "Member",
      render: ({ data }) => (
        <>
          <a href={`/${data?.ethAddress}`} target="_blank">
            {data?.imageUrl ? (
              <img
                alt="..."
                className="profile-image-xs rounded-circle"
                src={data?.imageUrl}
              />
            ) : (
              <span className="profile-image-xs rounded-circle">
                <Avatar
                  size={28}
                  name={data?.ethAddress}
                  square="false"
                  variant="ring"
                  colors={[
                    "#000000",
                    "#2F2F2F",
                    "#505050",
                    "#797979",
                    "#CECECE",
                  ]}
                  className="rounded-circle profile-image"
                />
              </span>
            )}
            <span
              className={`ml-2 ${
                data?.registerStatus === "inactive"
                  ? "text-muted"
                  : data?.registerStatus === "banned" && "text-danger"
              }`}
            >
              <strong>{truncateText(data?.name, 13) || "Unnamed"}</strong>
              {data.isVerifiedUser && (
                <img
                  className="realpass-verified-name-suffix-sm ml-2 mb-1"
                  src={verifiedImg}
                />
              )}
            </span>

            {data.eventRoleGroup === "creator" && (
              <>
                <Badge
                  pill
                  id={`creator-${data?.ethAddress}`}
                  className="ml-1 text-success"
                  style={{ textTransform: "none" }}
                >
                  <FontAwesomeIcon icon="fa-user-shield" />
                </Badge>

                <UncontrolledTooltip
                  placement="top"
                  target={`creator-${data?.ethAddress}`}
                  trigger="hover"
                >
                  Creator not updatable
                </UncontrolledTooltip>
              </>
            )}
            <span
              className={`ml-2 ${
                data?.registerStatus === "active" ? "text-info" : "text-muted"
              }`}
            >
              {truncateAddressShort(data?.ethAddress)}
            </span>
            <small className="text-muted">
              <FontAwesomeIcon
                icon="arrow-up-right-from-square"
                className="ml-2 fa-sm"
              />
            </small>
          </a>
        </>
      ),
    },
    {
      name: "totalInvites",
      defaultFlex: 2.5,
      maxWidth: 150,
      header: "Invites",
      render: ({ value }) => {
        return <>{formatPrettyNumber(value)} invited</>;
      },
    },
    {
      name: "balanceEth",
      defaultFlex: 2.5,
      maxWidth: 150,
      header: "Balance",
      render: ({ value }) => {
        return (
          <>
            {formatPrettyNumber(
              value?.toLocaleString("en-US", {
                maximumFractionDigits: 5,
                minimumFractionDigits: 0,
              })
            )}{" "}
            {nativeCurrency("eth")}
          </>
        );
      },
    },
    {
      id: "flag",
      defaultFlex: 3,
      maxWidth: 180,
      header: "Status",
      render: ({ data }) => (
        <>
          <>
            <span
              className="mr-1"
              style={{ cursor: "pointer" }}
              id={`memberStatus-${data?.ethAddress}`}
            >
              <Badge>
                {data?.registerStatus === "active" ? (
                  <FontAwesomeIcon
                    icon="fa-user-check"
                    className="fa-lg text-success"
                  />
                ) : data?.registerStatus === "inactive" ? (
                  <FontAwesomeIcon
                    icon="fa-user-xmark"
                    className="fa-lg text-primary"
                  />
                ) : (
                  data?.registerStatus === "banned" && (
                    <FontAwesomeIcon
                      icon="fa-user-slash"
                      className="fa-lg text-danger"
                    />
                  )
                )}
              </Badge>
            </span>

            <UncontrolledTooltip
              placement="top"
              target={`memberStatus-${data?.ethAddress}`}
              trigger="hover"
            >
              Event access is {data?.registerStatus}.
            </UncontrolledTooltip>
          </>
          <>
            <span
              className="mr-1"
              style={{ cursor: "pointer" }}
              id={`memberUpdateCount-${data?.ethAddress}`}
            >
              <Badge
                className={
                  data?.regCount >= botsRegCount ? "text-danger" : "text-muted"
                }
              >
                <FontAwesomeIcon
                  icon="fa-clock-rotate-left"
                  className="fa-lg mr-1"
                />
                {data?.regCount || 0}
              </Badge>
            </span>

            <UncontrolledTooltip
              placement="top"
              target={`memberUpdateCount-${data?.ethAddress}`}
              trigger="hover"
            >
              Member has registered{" "}
              {pluralize("time", data?.regCount || 0, true)}
            </UncontrolledTooltip>
          </>
          <>
            <span
              className="mr-1"
              style={{ cursor: "pointer" }}
              id={`memberIPCount-${data?.ethAddress}`}
            >
              <Badge
                className={
                  data?.ipCount >= botsIpCount ? "text-danger" : "text-muted"
                }
              >
                <FontAwesomeIcon icon="fa-map-pin" className="fa-lg mr-1" />
                {data?.ipCount || 0}
              </Badge>
            </span>

            <UncontrolledTooltip
              placement="top"
              target={`memberIPCount-${data?.ethAddress}`}
              trigger="hover"
            >
              IP address has used by{" "}
              {pluralize("member", data?.ipCount || 0, true)}
            </UncontrolledTooltip>
          </>
        </>
      ),
    },
  ];

  const membersGridStyle = {
    minHeight: 550,
    minWidth: 680,
  };

  const exportMembersCSV = async () => {
    if (totalEventUsers > 0) {
      const res = await fetchFilteredEventMembers({
        skip: 0,
        limit: -1,
        sortInfo: "",
      });

      let columns = [];
      let header = "";
      let rows = [];

      if (res?.data?.length > 0) {
        columns = Object.keys(res.data[0]);

        header = columns.join(SEPARATOR);

        rows = res?.data?.map((data) => Object.values(data).join(SEPARATOR));
      }

      const contents = [header].concat(rows).join("\n");
      const blob = new Blob([contents], { type: "text/csv;charset=utf-8;" });

      downloadMembersBlob(blob);
    }
  };

  const findMemberDetail = (memberAddress) => {
    // setMemberDetailToFind(memberAddress);
    // const web3 = new Web3(Moralis.provider);
    // const lowerAddress = memberAddress.toLowerCase();
    // const isAddress = web3.utils.isAddress(lowerAddress);
    // if (isAddress) {
    //   setMemberDetailToFind(
    //     web3.utils.toChecksumAddress(lowerAddress).toLowerCase()
    //   );
    // } else if (!memberAddress) {
    //   setMemberDetailToFind();
    // }
  };

  const fetchFilteredEventMembers = async ({ skip, limit, sortInfo }) => {
    try {
      if (memberDetailInput) {
        if (!validEthAddress(memberDetailInput)) {
          return { data: [], count: 0 };
        }

        setMembersTableFilter("all");
        setBotsRegCount(5);
        setBotsIpCount(5);
      }

      setEventUsersGridLoading(true);

      const filter = memberDetailInput ? "all" : membersTableFilter;

      const regCount =
        !memberDetailInput && membersTableFilter === "regCount"
          ? botsRegCount
          : 0;

      const ipCount =
        !memberDetailInput && membersTableFilter === "ipCount"
          ? botsIpCount
          : 0;

      const res = await creatorGetEventUsers(
        currentAuthToken,
        eventId,
        filter,
        limit,
        skip,
        regCount,
        ipCount,
        memberDetailInput
      );

      const data = res?.eventUsers || [];
      const count = res?.totalEventUsers || 0;

      setTotalEventUsers(count);

      const ids = data?.map((item) => item?.userId);
      setEventUserIds(ids);
      setEventUsers(data);

      return { data, count };
    } catch (err) {
      console.error(err.message);
      notify("Unable to fetch event members.", "error");
    } finally {
      setEventUsersGridLoading(false);
    }
  };

  // it will automatically fetch data if condition change
  const membersDataSource = useCallback(fetchFilteredEventMembers, [
    allMembersTableRefresh,
  ]);

  const onMembersSelectionChange = useCallback(({ selected, unselected }) => {
    // selected: 1. true - select all, 2. objects - with keys:id of selected rows
    setMembersSelected(selected);
    setMembersUnselected(unselected);
  }, []);

  const checkMembersAction = (action) => {
    setMembersActionModalOpen(true);
    setMembersActionError();
    setMembersAction();

    if (["grant", "revoke", "ban", "unban"].includes(action)) {
      setMembersAction(action);
      let ids = [];
      let users = [];

      // Set selected member ids
      if (membersSelected === true && membersUnselected === null) {
        ids = eventUserIds;
        users = eventUsers;
      } else if (membersSelected === true && membersUnselected !== null) {
        const unselectedKeys = Object.keys(membersUnselected);

        ids = eventUserIds.filter((e) => !unselectedKeys.includes(e));
        users = eventUsers.filter((e) => !unselectedKeys.includes(e?.userId));
      } else if (membersSelected === false) {
        ids = [];
        users = [];
      } else {
        ids = Object.keys(membersSelected);
        users = Object.values(membersSelected);
      }

      if (ids?.length <= 0) {
        setMembersActionError("You must select at least 1 member.");
      }

      if (ids?.length > 10) {
        setMembersActionError(
          `You can only ${action} up to 10 members at a time.`
        );
      }

      if (users?.some((u) => u?.eventRoleGroup === "creator")) {
        setMembersActionError(`Event creators are not updatable.`);
      }

      setSelectedMemberIds(ids);
    }
  };

  /**
   * Perform action on selected members: grant, revoke, ban, unban
   */
  const performMembersAction = async (action) => {
    try {
      setEventUsersGridLoading(true);

      if (["grant", "revoke", "ban", "unban"].includes(action)) {
        if (selectedMemberIds?.length > 0 && selectedMemberIds?.length <= 10) {
          const res = await creatorUpdateEventUsers(
            currentAuthToken,
            eventId,
            action,
            selectedMemberIds
          );

          if (res?.success) {
            notify(
              `Successfully ${
                ["ban", "unban"].includes(action)
                  ? action + "ned"
                  : action + "ed"
              } selected members${
                ["grant", "revoke"].includes(action) && " access"
              }.`,
              "success"
            );
            setAllMembersTableRefresh(!allMembersTableRefresh);
          } else {
            notify(
              `Unable to ${action} selected members${
                ["grant", "revoke"].includes(action) && " access"
              }.`,
              "error"
            );
          }
        }
      }
    } catch (err) {
      console.error(err.message);
      notify(
        `Unable to ${action} selected members${
          ["grant", "revoke"].includes(action) && " access"
        }.`,
        "error"
      );
    } finally {
      setEventUsersGridLoading(false);
    }
  };

  const fetchTotalParticipants = async (maxParts) => {
    try {
      // const totalParts = await Moralis.Cloud.run("totalParticipants", {
      //   eventId: eventId,
      //   validOnly: true,
      // });
      // if (!isNaN(totalParts)) {
      //   setTotalParticipants(totalParts);
      //   if (!isNaN(totalParts) && !isNaN(maxParts) && maxParts >= 0) {
      //     setSpotsFull(totalParts >= maxParts);
      //   }
      //   // return res;
      // } else {
      //   setTotalParticipants(0);
      //   // return 0;
      // }
    } catch (err) {
      console.error(err.message);
    }
  };

  const fetchMembersByCondition = async () => {
    try {
    } catch (err) {
      console.error(err.message);
    }
  };

  /*
   * Validate wallet addresses before bulk adding members
   */
  const validateBulkMemberAddresses = () => {
    try {
      if (!bulkMemberAddresses) {
        return false;
      }

      const addressList = bulkMemberAddresses?.trim()?.split("\n");

      if (addressList?.length > 0) {
        if (addressList.length > 10) {
          throw { message: "You can only add up to 10 members at a time." };
        }

        if (hasDuplicates(addressList)) {
          throw { message: "Duplicate addresses are not allowed." };
        }

        for (const i in addressList) {
          const isAddress = validEthAddress(addressList[i]);

          if (!isAddress) {
            throw {
              message: `${addressList[i]} is not a valid address.`,
            };
          }
        }

        setBulkMemberAddressList(addressList);
        setBulkAddMembersError();
      }
    } catch (err) {
      console.error(err.message);
      setBulkAddMembersError(err.message);
    }
  };

  const handleBulkMemberAddresses = async (addressList) => {
    try {
      setModalLoading(true);

      validateBulkMemberAddresses();

      const res = await creatorBulkAddEventUsers(
        currentAuthToken,
        eventId,
        addressList,
        null
      );

      setBulkMemberAddresses("");
      setAllMembersTableRefresh(!allMembersTableRefresh);

      notify("Successfully added new members.", "success");
    } catch (err) {
      console.error(err.response?.data?.message);
      setBulkAddMembersError(err.response?.data?.message);

      notify("Unable to bulk add members.", "error");
    } finally {
      setModalLoading(false);
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      {(hasAdminRole || isEventActiveCreator) && (
        <>
          <Row className="my-lg-3">
            <Col className="text-left col-4">
              <h5 className="text-default">
                <strong>Members</strong>
              </h5>
            </Col>
            <Col className="text-right col-8">
              {(hasAdminRole || event?.isOwner || event?.isCohost) && (
                <Button
                  onClick={exportMembersCSV}
                  size="sm"
                  outline
                  color="success"
                  className="text-right"
                  disabled={totalEventUsers <= 0}
                >
                  <FontAwesomeIcon icon="fa-download" />
                  <span className="d-none d-md-inline-block">Export</span>
                </Button>
              )}

              <UncontrolledDropdown className="text-right">
                <DropdownToggle
                  id="inviteButton"
                  caret
                  size="sm"
                  outline
                  color="primary"
                  type="button"
                >
                  <FontAwesomeIcon icon="fa-user-plus" />
                  <span className="d-none d-md-inline-block">Invites</span>
                </DropdownToggle>
                <DropdownMenu
                  className="full-dropdown-menu-right-sm"
                  style={{ margin: 0 }}
                >
                  <DropdownItem onClick={() => setInviteCodeModalOpen(true)}>
                    <FontAwesomeIcon icon="fa-tags" className="mr-1" />
                    <span className="ml-1">Creator Invites</span>
                  </DropdownItem>

                  {(hasAdminRole || event?.isOwner || event?.isCohost) && (
                    <DropdownItem
                      onClick={() => setMemberInviteModalOpen(true)}
                    >
                      <FontAwesomeIcon icon="fa-user-group" className="mr-1" />
                      <span className="ml-1">Members Invites</span>
                    </DropdownItem>
                  )}

                  {(hasAdminRole || event?.isOwner || event?.isCohost) && (
                    <DropdownItem
                      onClick={() => setBulkAddMembersModalOpen(true)}
                    >
                      <FontAwesomeIcon
                        icon="fa-file-arrow-up"
                        className="mr-1"
                      />
                      <span className="ml-1">Bulk Add Members</span>
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>

              {(hasAdminRole || event?.isOwner || event?.isCohost) && (
                <Button
                  size="sm"
                  outline
                  color="primary"
                  className="text-right"
                  // onClick={}
                  // disabled={}
                >
                  <strong>🏅</strong>
                  <span className="btn-reward">Reward</span>
                </Button>
              )}
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="text-left" xl="6" lg="6" md="3" sm="3" xs="3">
              {(hasAdminRole || event?.isOwner || event?.isCohost) && (
                <div className="mt-2 mb-2">
                  <UncontrolledDropdown className="text-right">
                    <DropdownToggle
                      id="memberOptionsButton"
                      caret
                      outline
                      color="primary"
                      size="md"
                      type="button"
                    >
                      <span className="">
                        <FontAwesomeIcon icon="fa-bars-progress" />
                        <span className="d-none d-md-inline-block">Action</span>
                      </span>
                    </DropdownToggle>
                    <DropdownMenu
                      className="full-dropdown-menu-md"
                      style={{ margin: 0 }}
                    >
                      {/* <DropdownItem onClick={() => {}}>
                          <FontAwesomeIcon
                            icon='fa-list-check'
                            className='mr-1'
                          />
                          <span className='ml-1'>Validate Members</span>
                        </DropdownItem> */}
                      <DropdownItem
                        onClick={() => {
                          checkMembersAction("grant");
                        }}
                      >
                        <FontAwesomeIcon
                          icon="fa-user-check"
                          className="mr-1"
                        />
                        <span className="ml-1">Grant Access</span>
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          checkMembersAction("revoke");
                        }}
                      >
                        <FontAwesomeIcon
                          icon="fa-user-xmark"
                          className="mr-1"
                        />
                        <span className="ml-1">Revoke Access</span>
                      </DropdownItem>
                      <DropdownItem divider></DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          checkMembersAction("unban");
                        }}
                      >
                        <FontAwesomeIcon
                          icon="fa-user-clock"
                          className="mr-1"
                        />
                        <span className="ml-1">Unban Members</span>
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          checkMembersAction("ban");
                        }}
                      >
                        <FontAwesomeIcon
                          icon="fa-user-slash"
                          className="mr-1 text-danger"
                        />
                        <span className="mr-1 text-danger">Ban Members</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              )}
            </Col>
            <Col xl="6" lg="6" md="9" sm="9" xs="9">
              <div className="mt-2 mb-2">
                <InputGroup className="input-search-wrapper">
                  <InputGroupText className="input-search-left">
                    <FontAwesomeIcon icon="fa-search" />
                  </InputGroupText>
                  <Input
                    id="membersTableSearch"
                    className="input-search-mid"
                    value={memberDetailInput}
                    onChange={(e) => {
                      setMemberDetailInput(e.target.value?.toLowerCase());
                      setAllMembersTableRefresh(!allMembersTableRefresh);
                    }}
                    placeholder="Find wallet 0xabc123..."
                    type="text"
                  />
                  <UncontrolledDropdown>
                    <DropdownToggle
                      outline
                      type="button"
                      className="input-search-right"
                    >
                      <FontAwesomeIcon icon="fa-filter" className="mr-1" />
                      <span className="d-none d-md-inline-block ml-1">
                        Filter
                      </span>
                    </DropdownToggle>
                    <DropdownMenu
                      className="full-dropdown-menu-right-md filter-button-menu"
                      style={{ margin: 0 }}
                    >
                      <DropdownItem
                        onClick={() => {
                          setMembersTableFilter("all");
                          setAllMembersTableRefresh(!allMembersTableRefresh);
                        }}
                      >
                        <span className="">All Members</span>

                        {membersTableFilter === "all" && (
                          <FontAwesomeIcon
                            icon="fa-check"
                            className="float-right"
                          />
                        )}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setMembersTableFilter("active");
                          setAllMembersTableRefresh(!allMembersTableRefresh);
                        }}
                        disabled={memberDetailInput?.length > 0}
                      >
                        <span className="">Active Only</span>

                        {membersTableFilter === "active" && (
                          <FontAwesomeIcon
                            icon="fa-check"
                            className="float-right"
                          />
                        )}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setMembersTableFilter("inactive");
                          setAllMembersTableRefresh(!allMembersTableRefresh);
                        }}
                        disabled={memberDetailInput?.length > 0}
                      >
                        <span className="">Inactive Members</span>

                        {membersTableFilter === "inactive" && (
                          <FontAwesomeIcon
                            icon="fa-check"
                            className="float-right"
                          />
                        )}
                      </DropdownItem>

                      {(hasAdminRole || event?.isOwner || event?.isCohost) && (
                        <DropdownItem
                          onClick={() => {
                            setMembersTableFilter("regCount");
                            setBotsRegCount(5);
                            setAllMembersTableRefresh(!allMembersTableRefresh);
                          }}
                          disabled={memberDetailInput?.length > 0}
                        >
                          <span className="">Repeating Entries</span>

                          {membersTableFilter === "modifyCount" && (
                            <FontAwesomeIcon
                              icon="fa-check"
                              className="float-right"
                            />
                          )}
                        </DropdownItem>
                      )}

                      {(hasAdminRole || event?.isOwner || event?.isCohost) && (
                        <DropdownItem
                          onClick={() => {
                            setMembersTableFilter("ipCount");
                            setBotsIpCount(5);
                            setAllMembersTableRefresh(!allMembersTableRefresh);
                          }}
                          disabled={memberDetailInput?.length > 0}
                        >
                          <span className="">Repeating IPs</span>

                          {membersTableFilter === "ipCount" && (
                            <FontAwesomeIcon
                              icon="fa-check"
                              className="float-right"
                            />
                          )}
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </InputGroup>
              </div>
            </Col>
          </Row>

          <Row className="my-2">
            <Col>
              <div className="float-right">
                <small className="text-muted">
                  Showing{" "}
                  {["active", "inactive"].includes(membersTableFilter) &&
                    membersTableFilter + " "}
                  {pluralize("member", totalEventUsers || 0, true)}
                </small>
              </div>
              {(hasAdminRole || event?.isOwner || event?.isCohost) && (
                <div className="float-right mx-2">
                  <Collapse
                    isOpen={membersTableFilter === "regCount"}
                    className="filter-collapse"
                  >
                    <UncontrolledDropdown>
                      <DropdownToggle
                        outline
                        size="sm"
                        type="button"
                        id="botModificationFilterButton"
                        className="filter-button"
                      >
                        <FontAwesomeIcon icon="fa-clock-rotate-left" />
                        <span className="d-none d-md-inline-block">
                          Registered
                        </span>
                      </DropdownToggle>

                      <UncontrolledTooltip
                        placement="top"
                        target="botModificationFilterButton"
                        trigger="hover"
                      >
                        Repeated pass swapping or opt-in behaviors
                      </UncontrolledTooltip>
                      <DropdownMenu
                        className="full-dropdown-menu-sm filter-button-menu"
                        style={{ margin: 0 }}
                      >
                        <DropdownItem
                          onClick={() => {
                            setBotsRegCount(2);
                            setAllMembersTableRefresh(!allMembersTableRefresh);
                          }}
                        >
                          <span>2+ Repeats</span>

                          {botsRegCount === 2 && (
                            <FontAwesomeIcon
                              icon="fa-check"
                              className="float-right"
                            />
                          )}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setBotsRegCount(5);
                            setAllMembersTableRefresh(!allMembersTableRefresh);
                          }}
                        >
                          <span>5+ Repeats</span>

                          {botsRegCount === 5 && (
                            <FontAwesomeIcon
                              icon="fa-check"
                              className="float-right"
                            />
                          )}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setBotsRegCount(10);
                            setAllMembersTableRefresh(!allMembersTableRefresh);
                          }}
                        >
                          <span>10+ Repeats</span>

                          {botsRegCount === 10 && (
                            <FontAwesomeIcon
                              icon="fa-check"
                              className="float-right"
                            />
                          )}
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Collapse>
                  <Collapse
                    isOpen={membersTableFilter === "ipCount"}
                    className="filter-collapse"
                  >
                    <UncontrolledDropdown>
                      <DropdownToggle
                        outline
                        size="sm"
                        type="button"
                        id="botIPFilterButton"
                        className="filter-button"
                      >
                        <FontAwesomeIcon icon="fa-map-pin" />
                        <span className="d-none d-md-inline-block">
                          IP Address
                        </span>
                      </DropdownToggle>

                      <UncontrolledTooltip
                        placement="top"
                        target="botIPFilterButton"
                        trigger="hover"
                      >
                        Repeating IP address for multiple accesses
                      </UncontrolledTooltip>
                      <DropdownMenu
                        className="full-dropdown-menu-sm filter-button-menu"
                        style={{ margin: 0 }}
                      >
                        <DropdownItem
                          onClick={() => {
                            setBotsIpCount(2);
                            setAllMembersTableRefresh(!allMembersTableRefresh);
                          }}
                        >
                          <span>2+ Repeats</span>

                          {botsIpCount === 2 && (
                            <FontAwesomeIcon
                              icon="fa-check"
                              className="float-right"
                            />
                          )}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setBotsIpCount(5);
                            setAllMembersTableRefresh(!allMembersTableRefresh);
                          }}
                        >
                          <span>5+ Repeats</span>

                          {botsIpCount === 5 && (
                            <FontAwesomeIcon
                              icon="fa-check"
                              className="float-right"
                            />
                          )}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setBotsIpCount(10);
                            setAllMembersTableRefresh(!allMembersTableRefresh);
                          }}
                        >
                          <span>10+ Repeats</span>

                          {botsIpCount === 10 && (
                            <FontAwesomeIcon
                              icon="fa-check"
                              className="float-right"
                            />
                          )}
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Collapse>
                </div>
              )}
            </Col>
          </Row>

          <Row className="mb-2">
            <Col>
              <div className="mt-2" style={{ overflow: "auto" }}>
                <ReactDataGrid
                  idProperty="userId"
                  columns={membersCols}
                  style={membersGridStyle}
                  dataSource={membersDataSource}
                  pagination
                  loading={eventUsersGridLoading}
                  loadingText={" "}
                  onLoadingChange={setEventUsersGridLoading}
                  sortable={false}
                  defaultLimit={25}
                  emptyText={() => NoDataText("lg", true)}
                  renderRowContextMenu={null}
                  checkboxColumn
                  selected={membersSelected}
                  onSelectionChange={onMembersSelectionChange}
                  showZebraRows={false}
                  defaultShowCellBorders={"horizontal"}
                  columnUserSelect={true}
                  renderColumnContextMenu={renderColumnContextMenu}
                />
              </div>
            </Col>
          </Row>

          <Modal
            toggle={() => setMembersActionModalOpen(!membersActionModalOpen)}
            isOpen={membersActionModalOpen}
          >
            <div
              className={
                !!membersActionError
                  ? "bg-primary modal-body rounded"
                  : "modal-header"
              }
            >
              <span className="modal-title" id="membersActionModalLabel">
                {membersActionError ? (
                  <span>
                    <FontAwesomeIcon
                      icon="fa-circle-xmark"
                      className="mr-2  text-danger"
                    />
                    <span className="text-white">{membersActionError}</span>
                  </span>
                ) : (
                  <strong>
                    {capitalize(membersAction)}
                    {membersAction === "grant"
                      ? " access to"
                      : membersAction === "revoke" && " access from"}{" "}
                    selected members?
                  </strong>
                )}
              </span>

              <button
                aria-label="Close"
                className={`close`}
                type="button"
                onClick={() => setMembersActionModalOpen(false)}
              >
                <span aria-hidden={true}>
                  <FontAwesomeIcon icon="fa-xmark" />
                </span>
              </button>
            </div>

            {!membersActionError && (
              <ModalBody>
                <span className="text-default">
                  {membersAction === "ban"
                    ? "Selected members will banned and not be able to access or register the event."
                    : membersAction === "unban"
                    ? "Selected members will be unbanned and able to register the event."
                    : membersAction === "grant"
                    ? "Selected members will be granted access to the event."
                    : membersAction === "revoke" &&
                      "Selected members will be revoked access to the event."}
                </span>
              </ModalBody>
            )}
            {!membersActionError && (
              <ModalFooter>
                <Button
                  size="md"
                  color={membersAction === "ban" ? "danger" : "primary"}
                  type="button"
                  onClick={() => {
                    performMembersAction(membersAction);
                    setMembersActionModalOpen(false);
                  }}
                  disabled={!!membersActionError}
                >
                  {capitalize(membersAction)}
                </Button>
                <Button
                  size="md"
                  color="link"
                  type="button"
                  disabled={modalLoading}
                  onClick={() => {
                    setMembersActionModalOpen(false);
                  }}
                >
                  Close
                </Button>
              </ModalFooter>
            )}
          </Modal>

          <Modal isOpen={inviteCodeModalOpen}>
            <BarLoader
              loading={modalLoading}
              cssOverride={{
                marginLeft: "1.5%",
                marginRight: "1.5%",
                width: "97%",
                backgroundColor: "#fff",
                opacity: "1",
              }}
            />
            <div className="modal-header">
              <span className="modal-title" id="inviteCodeModalLabel">
                <strong>Invite Code</strong>
              </span>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => {
                  setCreatorInviteActionCollapseOpen(false);
                  setInviteCodeModalOpen(false);
                }}
                disabled={
                  modalLoading ||
                  creatorInviteStatusUpdating ||
                  creatorInviteCodeAdding ||
                  teamInviteOptionSaving
                }
              >
                <span aria-hidden={true}>
                  <FontAwesomeIcon icon="fa-xmark" />
                </span>
              </button>
            </div>

            <ModalBody>
              <Alert color="secondary" className="text-default">
                <div>
                  {!event?.isOwner && event?.maxCreatorInvites === 0 ? (
                    <>
                      Creator invite is disabled. Please contact the{" "}
                      <a
                        className="text-info"
                        href={`/${event?.ownerEthAddress}`}
                        target="_blank"
                        style={{ cursor: "pointer" }}
                      >
                        event owner
                        <small className="text-muted">
                          <FontAwesomeIcon
                            icon="arrow-up-right-from-square"
                            className="ml-1 fa-sm"
                          />
                        </small>
                      </a>
                      .
                    </>
                  ) : !event?.isPrivate ? (
                    <>
                      This is a public event. People can join without invite. (
                      <a
                        className="text-info"
                        href={`/dashboard/event/${eventId}?view=pass`}
                        target="_blank"
                        style={{ cursor: "pointer" }}
                      >
                        Change Settings
                        <small className="text-muted">
                          <FontAwesomeIcon
                            icon="arrow-up-right-from-square"
                            className="ml-1 fa-sm"
                          />
                        </small>
                      </a>
                      )
                    </>
                  ) : (
                    <>
                      This is a private event. People need invite to join. (
                      <a
                        className="text-info"
                        href={`/dashboard/event/${eventId}?view=pass`}
                        target="_blank"
                        style={{ cursor: "pointer" }}
                      >
                        Change Settings
                        <small className="text-muted">
                          <FontAwesomeIcon
                            icon="arrow-up-right-from-square"
                            className="ml-1 fa-sm"
                          />
                        </small>
                      </a>
                      )
                    </>
                  )}
                </div>
              </Alert>
              <div className="mb-2">
                <div className="mt-2 mb-3">
                  <FontAwesomeIcon
                    icon="fa-circle-check"
                    className="text-success fa-lg"
                  />
                  <span className="ml-2">
                    <strong>Create Invite Code</strong>
                  </span>

                  <div className="ml-2">
                    <small className="text-muted">
                      You can create up to 5 invite codes
                    </small>

                    <Button
                      size="sm"
                      color="primary"
                      className="float-right"
                      onClick={handleNewCreatorInvite}
                      disabled={
                        creatorInviteCodesCount >= 5 ||
                        creatorInviteCodeAdding ||
                        (!event?.isOwner && event?.maxCreatorInvites === 0)
                      }
                    >
                      {creatorInviteCodeAdding ? (
                        <ScaleLoader
                          color="#eee"
                          loading={creatorInviteCodeAdding}
                          height="10px"
                          cssOverride={{
                            display: "inline",
                          }}
                        />
                      ) : (
                        "Create New"
                      )}
                    </Button>
                  </div>
                  <FormGroup>
                    <Row className="my-2">
                      <Col>
                        <div htmlFor="creatorInviteMaxUsageSelector">
                          <small
                            id="creatorInviteMaxUsageTooltip"
                            style={{ cursor: "pointer" }}
                          >
                            <strong>Max Invite Usage</strong>

                            <FontAwesomeIcon
                              icon="fa-circle-info"
                              className="text-muted ml-1"
                            />
                          </small>

                          <UncontrolledTooltip
                            placement="top"
                            target="creatorInviteMaxUsageTooltip"
                            trigger="hover"
                          >
                            {creatorInviteMaxUsageOption?.value < 0 ? (
                              <>There is no invite limit on each invite code.</>
                            ) : (
                              <>
                                Each invite code can invite up to{" "}
                                {creatorInviteMaxUsageOption?.value}{" "}
                                {creatorInviteMaxUsageOption?.value > 1
                                  ? "people"
                                  : "person"}
                              </>
                            )}
                          </UncontrolledTooltip>
                        </div>
                        <Select
                          id="creatorInviteMaxUsageSelector"
                          className="realpass-selector-sm"
                          onChange={(e) => {
                            setCreatorInviteMaxUsageOption(e);
                          }}
                          options={CreatorMaxOptions}
                          value={creatorInviteMaxUsageOption}
                          isDisabled={
                            creatorInviteCodesCount >= 5 ||
                            creatorInviteCodeAdding ||
                            (!event?.isOwner && event?.maxCreatorInvites === 0)
                          }
                          styles={{
                            control: (base) => ({
                              ...base,
                              height: "38px",
                              padding: "1.5px 2px !important",
                            }),
                          }}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                      </Col>
                      <Col>
                        <div htmlFor="creatorInviteExpireSelector">
                          <small>
                            <strong>Expire After</strong>
                          </small>
                        </div>
                        <Select
                          id="creatorInviteExpireSelector"
                          className="realpass-selector-sm"
                          onChange={(e) => {
                            setCreatorInviteExpireOption(e);
                          }}
                          options={InviteExpireOptions}
                          value={creatorInviteExpireOption}
                          isDisabled={
                            creatorInviteCodesCount >= 5 ||
                            creatorInviteCodeAdding ||
                            (!event?.isOwner && event?.maxCreatorInvites === 0)
                          }
                          styles={{
                            control: (base) => ({
                              ...base,
                              height: "38px",
                              padding: "1.5px 2px !important",
                            }),
                          }}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </div>
                {event.isOwner && (
                  <div className="mt-4 mb-3">
                    <Row>
                      <Col className="col-12">
                        <FontAwesomeIcon
                          icon="fa-circle-check"
                          className={`fa-lg ${
                            enableTeamInviteInput && "text-success"
                          }`}
                        />
                        <span className="ml-2">
                          <strong>Team Invite Settings</strong>
                        </span>
                        <Label className="custom-toggle float-right">
                          <Input
                            checked={enableTeamInviteInput}
                            type="checkbox"
                            onChange={() => {
                              toggleEnableTeamInvite(!enableTeamInviteInput);
                              setTeamInviteOptionChanged(true);
                            }}
                          />
                          <span className="custom-toggle-slider rounded-circle" />
                        </Label>

                        <div className="ml-2 text-muted">
                          {enableTeamInviteInput ? (
                            <small>
                              Team members can create up to 5 invites
                            </small>
                          ) : (
                            <small>Only event owner can create invite</small>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <FormGroup>
                      <Row className="mt-2 mb-3">
                        <Col className="col-9">
                          <div htmlFor="teamInviteMaxUsageSelector">
                            <small
                              id="teamInviteMaxUsageTooltip"
                              style={{ cursor: "pointer" }}
                            >
                              <strong>Max Usage per Code</strong>

                              <FontAwesomeIcon
                                icon="fa-circle-info"
                                className="text-muted ml-1"
                              />
                            </small>

                            <UncontrolledTooltip
                              placement="top"
                              target="teamInviteMaxUsageTooltip"
                              trigger="hover"
                            >
                              {teamInviteMaxUsageOptionInput?.value < 0 ? (
                                <>
                                  There is no invite limit on each invite code.
                                </>
                              ) : (
                                <>
                                  Each invite code can invite up to{" "}
                                  {teamInviteMaxUsageOptionInput?.value}{" "}
                                  {teamInviteMaxUsageOptionInput?.value > 1
                                    ? "people"
                                    : "person"}
                                </>
                              )}
                            </UncontrolledTooltip>
                          </div>
                          <div>
                            <Select
                              id="teamInviteMaxUsageSelector"
                              className="realpass-selector-sm"
                              onChange={(e) => {
                                setTeamInviteMaxUsageOptionInput(e);
                                setTeamInviteOptionChanged(true);
                              }}
                              options={InviteMaxUsageOptions}
                              value={teamInviteMaxUsageOptionInput}
                              styles={{
                                control: (base) => ({
                                  ...base,
                                  height: "38px",
                                  padding: "1.5px 2px !important",
                                }),
                              }}
                              isDisabled={!enableTeamInviteInput}
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </div>
                        </Col>
                        <Col>
                          <Button
                            size="md"
                            color="primary"
                            className="float-right"
                            style={{ position: "absolute", bottom: 0 }}
                            onClick={handleTeamInviteSettings}
                            disabled={
                              teamInviteOptionSaving || !teamInviteOptionChanged
                            }
                          >
                            {teamInviteOptionSaving ? (
                              <ScaleLoader
                                color="#eee"
                                loading={teamInviteOptionSaving}
                                height="10px"
                                cssOverride={{
                                  display: "inline",
                                }}
                              />
                            ) : (
                              "Save"
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </FormGroup>
                  </div>
                )}
                <hr />
                {(event?.isOwner ||
                  event?.maxCreatorInvites < 0 ||
                  event?.maxCreatorInvites > 0) && (
                  <>
                    <div className="my-2">
                      <FontAwesomeIcon
                        icon="fa-circle-check"
                        className="text-success fa-lg"
                      />
                      <span className="ml-2">
                        <strong>Share Invite Link</strong>
                      </span>
                    </div>
                    <div className="my-2 col-12">
                      <UrlButton
                        url={creatorInviteLink}
                        size="md"
                        style="block"
                        message="Click on code and make a short url"
                      />
                    </div>

                    <div className="mt-3 mb-2">
                      <FontAwesomeIcon
                        icon="fa-ranking-star"
                        className="fa-lg"
                        style={{ color: "gold" }}
                      />
                      <span className="ml-2">
                        <strong>Invite Tracking</strong>
                      </span>
                      <p className="ml-2">
                        <small className="text-muted">
                          Found {teamInviteCodesCount} creator invite codes.{" "}
                          <a
                            onClick={(e) => e.preventDefault()}
                            id="inviteTrackingTip"
                          >
                            (
                            <u
                              className="text-info"
                              style={{ cursor: "pointer" }}
                            >
                              Do you know?
                            </u>
                            )
                          </a>
                        </small>
                      </p>
                      <UncontrolledTooltip
                        placement="top"
                        target="inviteTrackingTip"
                        trigger="hover"
                      >
                        Invite is a great way to generate leads and convert into
                        sales.
                      </UncontrolledTooltip>
                    </div>

                    <Collapse
                      isOpen={creatorInviteActionCollapseOpen}
                      className="my-2"
                    >
                      <Alert color="secondary" className="text-default">
                        <Row>
                          <Col className="text-center">
                            <div>
                              Would you like to {creatorInviteAction} invite
                              code <strong>{creatorInviteActionCode}</strong>?
                            </div>
                            <div className=" mt-2">
                              <Button
                                size="sm"
                                color="primary"
                                onClick={() =>
                                  handleCreatorInviteAction(
                                    creatorInviteActionUserId,
                                    creatorInviteActionCode,
                                    creatorInviteAction
                                  )
                                }
                                disabled={creatorInviteStatusUpdating}
                              >
                                Yes, {creatorInviteAction}
                                {creatorInviteStatusUpdating && (
                                  <ScaleLoader
                                    color="#eee"
                                    loading={creatorInviteStatusUpdating}
                                    height="10px"
                                    cssOverride={{
                                      display: "inline",
                                    }}
                                  />
                                )}
                              </Button>
                              <Button
                                size="sm"
                                color="secondary"
                                onClick={() =>
                                  setCreatorInviteActionCollapseOpen(
                                    !creatorInviteActionCollapseOpen
                                  )
                                }
                                disabled={creatorInviteStatusUpdating}
                              >
                                No
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Alert>
                    </Collapse>
                    <div className="mt-2" style={{ overflow: "auto" }}>
                      <ReactDataGrid
                        idProperty="inviteCode"
                        columns={creatorInviteCols}
                        style={creatorInviteGridStyle}
                        dataSource={creatorInviteDataSource}
                        loading={creatorInviteGridLoading}
                        loadingText={" "}
                        onLoadingChange={setCreatorInviteGridLoading}
                        emptyText={() => NoDataText("sm", true)}
                        sortable={false}
                        onSelectionChange={onCreatorInviteSelectionChange}
                        renderRowContextMenu={null}
                        showZebraRows={false}
                        defaultShowCellBorders={"horizontal"}
                        enableSelection={true}
                        columnUserSelect={true}
                        renderColumnContextMenu={renderColumnContextMenu}
                      />
                    </div>
                  </>
                )}
              </div>
            </ModalBody>

            <ModalFooter>
              <Button
                size="md"
                color="link"
                type="button"
                onClick={() => {
                  setCreatorInviteActionCollapseOpen(false);
                  setInviteCodeModalOpen(false);
                }}
                disabled={
                  modalLoading ||
                  creatorInviteStatusUpdating ||
                  creatorInviteCodeAdding ||
                  teamInviteOptionSaving
                }
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={memberInviteModalOpen}>
            <BarLoader
              loading={modalLoading}
              cssOverride={{
                marginLeft: "1.5%",
                marginRight: "1.5%",
                width: "97%",
                backgroundColor: "#fff",
                opacity: "1",
              }}
            />
            <div className="modal-header">
              <span className=" modal-title" id="memberInviteModalLabel">
                <strong>Member Invites</strong>
              </span>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => {
                  setMemberInviteModalOpen(false);
                  setMemberInviteOptionChanged(false);
                  setEnableMemberInviteInput(enableMemberInvite);
                  setMemberInviteMaxUsageOptionInput(
                    memberInviteMaxUsageOption
                  );
                  setMemberInviteWalletInput("");
                  setMemberInviteWalletError();
                }}
                disabled={
                  memberInviteOptionSaving ||
                  memberInviteWalletFinding ||
                  memberInviteStatusUpdating
                }
              >
                <span aria-hidden={true}>
                  <FontAwesomeIcon icon="fa-xmark" />
                </span>
              </button>
            </div>

            <ModalBody>
              <Alert color="secondary" className="text-default">
                <div>
                  {!event?.isPrivate ? (
                    <>This is a public event. Anyone can create invite.</>
                  ) : (
                    <>
                      This is a private event. Only active members can create
                      invite.
                    </>
                  )}{" "}
                  (
                  <a
                    className="text-info"
                    href={`/dashboard/event/${eventId}?view=pass`}
                    target="_blank"
                    style={{ cursor: "pointer" }}
                  >
                    Change settings
                    <small className="text-muted">
                      <FontAwesomeIcon
                        icon="arrow-up-right-from-square"
                        className="ml-1 fa-sm"
                      />
                    </small>
                  </a>
                  )
                </div>
              </Alert>
              <div className="mt-2 mb-3">
                <Row>
                  <Col className="col-12">
                    <FontAwesomeIcon
                      icon="fa-circle-check"
                      className={`fa-lg ${
                        enableMemberInviteInput && "text-success"
                      }`}
                    />
                    <span className="ml-2">
                      <strong>Member Invite</strong>
                    </span>
                    <Label className="custom-toggle float-right">
                      <Input
                        checked={enableMemberInviteInput}
                        type="checkbox"
                        onChange={() => {
                          toggleEnableMemberInvite(!enableMemberInviteInput);
                          setMemberInviteOptionChanged(true);
                        }}
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </Label>
                    <div className="ml-2 text-muted">
                      {enableMemberInviteInput ? (
                        <small>Allow each member to create one invite</small>
                      ) : (
                        <small>Disable new and existing member invites</small>
                      )}
                    </div>
                  </Col>
                </Row>

                <FormGroup>
                  <Row className="mt-2 mb-3">
                    <Col className="col-9">
                      <div htmlFor="memberInviteMaxUsageSelector">
                        <small
                          id="memberInviteMaxUsageTooltip"
                          style={{ cursor: "pointer" }}
                        >
                          <strong>Max Invite Usage</strong>

                          <FontAwesomeIcon
                            icon="fa-circle-info"
                            className="text-muted ml-1"
                          />
                        </small>

                        <UncontrolledTooltip
                          placement="top"
                          target="memberInviteMaxUsageTooltip"
                          trigger="hover"
                        >
                          {memberInviteMaxUsageOptionInput?.value < 0 ? (
                            <>There is no invite limit on each invite code.</>
                          ) : (
                            <>
                              Each invite code can invite up to{" "}
                              {memberInviteMaxUsageOptionInput?.value}{" "}
                              {memberInviteMaxUsageOptionInput?.value > 1
                                ? "people"
                                : "person"}
                            </>
                          )}
                        </UncontrolledTooltip>
                      </div>
                      <div>
                        <Select
                          id="memberInviteMaxUsageSelector"
                          className="realpass-selector-sm"
                          onChange={(e) => {
                            setMemberInviteMaxUsageOptionInput(e);
                            setMemberInviteOptionChanged(true);
                          }}
                          options={InviteMaxUsageOptions}
                          value={memberInviteMaxUsageOptionInput}
                          styles={{
                            control: (base) => ({
                              ...base,
                              height: "38px",
                              padding: "1.5px 2px !important",
                            }),
                          }}
                          isDisabled={!enableMemberInviteInput}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                      </div>
                    </Col>
                    <Col>
                      <Button
                        size="md"
                        color="primary"
                        className="float-right"
                        style={{ position: "absolute", bottom: 0 }}
                        onClick={handleMemberInviteSettings}
                        disabled={
                          memberInviteOptionSaving || !memberInviteOptionChanged
                        }
                      >
                        {memberInviteOptionSaving ? (
                          <ScaleLoader
                            color="#eee"
                            loading={memberInviteOptionSaving}
                            height="10px"
                            cssOverride={{
                              display: "inline",
                            }}
                          />
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
              </div>
              <hr />
              <div className="mb-2">
                <div className="mt-3 mb-2">
                  <FontAwesomeIcon
                    icon="fa-ranking-star"
                    className="fa-lg"
                    style={{ color: "gold" }}
                  />
                  <span className="ml-2">
                    <strong>Members Invites Tracking</strong>
                  </span>
                  <p className="ml-2">
                    <small className="text-muted">
                      Found{" "}
                      {pluralize("record", memberInviteCodesCount || 0, true)}.{" "}
                      <a
                        onClick={(e) => e.preventDefault()}
                        id="inviteTrackingTip"
                      >
                        (
                        <u className="text-info" style={{ cursor: "pointer" }}>
                          Do you know?
                        </u>
                        )
                      </a>
                    </small>
                  </p>
                  <UncontrolledTooltip
                    placement="top"
                    target="inviteTrackingTip"
                    trigger="hover"
                  >
                    Invite is a great way to generate leads and convert into
                    sales.
                  </UncontrolledTooltip>
                </div>

                <Collapse
                  isOpen={memberInviteActionCollapseOpen && enableMemberInvite}
                  className="my-2"
                >
                  <Alert color="secondary" className="text-default">
                    <Row>
                      <Col className="text-center">
                        <div>
                          <>
                            Would you like to {memberInviteAction} invite code{" "}
                            <strong>{memberInviteActionCode}</strong>?
                          </>
                        </div>
                        <div className=" mt-2">
                          <Button
                            size="sm"
                            color="primary"
                            onClick={() =>
                              handleMemberInviteAction(
                                memberInviteActionUserId,
                                memberInviteActionCode,
                                memberInviteAction
                              )
                            }
                            disabled={memberInviteStatusUpdating}
                          >
                            Yes, {memberInviteAction}{" "}
                            {memberInviteStatusUpdating && (
                              <ScaleLoader
                                color="#eee"
                                loading={memberInviteStatusUpdating}
                                height="10px"
                                cssOverride={{
                                  display: "inline",
                                }}
                              />
                            )}
                          </Button>
                          <Button
                            size="sm"
                            color="secondary"
                            onClick={() =>
                              setMemberInviteActionCollapseOpen(
                                !memberInviteActionCollapseOpen
                              )
                            }
                            disabled={memberInviteStatusUpdating}
                          >
                            No
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Alert>
                </Collapse>
                <div className="my-3">
                  <InputGroup>
                    <Input
                      id="inviteWalletSearch"
                      value={memberInviteWalletInput}
                      bsSize="sm"
                      onChange={(e) => {
                        if (!e.target.value && memberInviteWalletToFind) {
                          setMemberInviteWalletToFind();
                        }
                        setMemberInviteWalletInput(
                          e.target.value?.toLowerCase()
                        );
                      }}
                      placeholder="Find wallet 0xabc123..."
                      type="text"
                      className="input-group-left"
                    />
                    <Button
                      color="primary"
                      size="sm"
                      type="button"
                      className="input-group-right"
                      onClick={findMemberInviteWallet}
                      disabled={
                        !memberInviteWalletInput || memberInviteWalletFinding
                      }
                    >
                      {memberInviteWalletFinding ? (
                        <ScaleLoader
                          color="#eee"
                          loading={memberInviteWalletFinding}
                          height="10px"
                          cssOverride={{
                            display: "inline",
                          }}
                        />
                      ) : (
                        "Find"
                      )}
                    </Button>
                  </InputGroup>

                  <div>
                    {memberInviteWalletError && (
                      <small className="text-danger">
                        <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                        {memberInviteWalletError}
                      </small>
                    )}
                  </div>
                </div>
                <div className="mt-2" style={{ overflow: "auto" }}>
                  <ReactDataGrid
                    idProperty="userId"
                    columns={memberInviteCols}
                    style={memberInviteGridStyle}
                    dataSource={memberInviteDataSource}
                    loading={memberInviteGridLoading}
                    loadingText={" "}
                    onLoadingChange={setMemberInviteGridLoading}
                    emptyText={() => NoDataText("sm", true)}
                    pagination
                    sortable={false}
                    defaultLimit={25}
                    renderRowContextMenu={null}
                    showZebraRows={false}
                    defaultShowCellBorders={"horizontal"}
                    renderColumnContextMenu={renderColumnContextMenu}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                size="md"
                color="link"
                type="button"
                onClick={() => {
                  setMemberInviteModalOpen(false);
                  setMemberInviteOptionChanged(false);
                  setEnableMemberInviteInput(enableMemberInvite);
                  setMemberInviteMaxUsageOptionInput(
                    memberInviteMaxUsageOption
                  );
                  setMemberInviteWalletInput("");
                  setMemberInviteWalletError();
                }}
                disabled={
                  memberInviteOptionSaving ||
                  memberInviteWalletFinding ||
                  memberInviteStatusUpdating
                }
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={bulkAddMembersModalOpen}>
            <BarLoader
              loading={modalLoading}
              cssOverride={{
                marginLeft: "1.5%",
                marginRight: "1.5%",
                width: "97%",
                backgroundColor: "#fff",
                opacity: "1",
              }}
            />
            <div className="modal-header">
              <span className=" modal-title" id="bulkAddMembersModalLabel">
                <strong>Bulk Add Members</strong>
              </span>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => {
                  setBulkAddMembersModalOpen(false);
                  setBulkMemberAddresses("");
                  setBulkAddMembersError();
                }}
                disabled={modalLoading}
              >
                <span aria-hidden={true}>
                  <FontAwesomeIcon icon="fa-xmark" />
                </span>
              </button>
            </div>

            <ModalBody>
              <div className="mb-2">
                <div className="my-2">
                  {bulkMemberAddressList?.length > 0 ? (
                    <FontAwesomeIcon
                      icon="fa-circle-check"
                      className="text-success fa-lg"
                    />
                  ) : (
                    <FontAwesomeIcon icon="fa-circle-check" className="fa-lg" />
                  )}
                  <span
                    id="addMembersTooltip"
                    className="ml-2"
                    style={{ cursor: "pointer" }}
                  >
                    <strong>Drop Wallet Addresses</strong>

                    <small className="ml-1">
                      <FontAwesomeIcon
                        icon="fa-circle-info"
                        className="text-muted ml-1"
                      />
                    </small>
                  </span>

                  <UncontrolledTooltip
                    placement="top"
                    target="addMembersTooltip"
                    trigger="hover"
                  >
                    Adding members is gasless and free.
                  </UncontrolledTooltip>
                </div>
                <div className="mt-3">
                  <FormGroup>
                    <>
                      <Label for="bulkAddresses">
                        <small>
                          <strong className="text-muted">
                            You may add up to 10 members at a time.
                          </strong>
                        </small>
                      </Label>
                      <Input
                        id="bulkMemberAddresses"
                        value={bulkMemberAddresses}
                        onChange={(e) => {
                          setBulkMemberAddresses(e.target.value?.toLowerCase());
                        }}
                        onBlur={validateBulkMemberAddresses}
                        placeholder={"Drop one wallet address per line."}
                        rows="9"
                        type="textarea"
                      />

                      {bulkAddMembersError && (
                        <small className="text-danger">
                          <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                          {bulkAddMembersError}
                        </small>
                      )}
                    </>
                  </FormGroup>
                </div>
                <div className="mx-4 mt-3 mb-1">
                  <div>
                    <Label>
                      <Input
                        checked={confirmAddMembers}
                        className="custom-control-input"
                        type="checkbox"
                        onChange={() => {
                          setConfirmAddMembers(!confirmAddMembers);
                        }}
                      />
                      <span className="custom-control-label">
                        <strong>Confirm Adding the Above Addresses</strong>
                      </span>
                    </Label>
                  </div>
                  <small className="text-muted mt-2">
                    Wallet owners will be automatically admitted regardless
                    their passes. Please use with caution. Make sure it's not
                    used for spams or advertising.
                  </small>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                size="md"
                color="primary"
                type="button"
                disabled={
                  bulkAddMembersError || !confirmAddMembers || modalLoading
                }
                onClick={() => {
                  handleBulkMemberAddresses(bulkMemberAddressList);
                }}
              >
                Add Members
                {modalLoading && (
                  <span className="ml-2">
                    <ScaleLoader
                      color="#eee"
                      loading={modalLoading}
                      height="10px"
                      cssOverride={{
                        display: "inline",
                      }}
                    />
                  </span>
                )}
              </Button>
              <Button
                size="md"
                color="link"
                type="button"
                onClick={() => {
                  setBulkAddMembersModalOpen(!bulkAddMembersModalOpen);
                  setBulkMemberAddresses("");
                  setBulkAddMembersError();
                }}
                disabled={modalLoading}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </>
      )}
    </>
  );
}
