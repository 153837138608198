import React, { useState, useContext, useEffect } from "react";

import { AuthContext } from "Auth.js";

// reactstrap components
import {
  Alert,
  ButtonGroup,
  Button,
  Collapse,
  Card,
  Container,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  CardImg,
  CardBody,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Table,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";

import { Form, Field } from "react-final-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  truncateAddressShort,
  nativeCurrency,
  notify,
  genImageName,
  validEmail,
  removeProtocolStr,
} from "utils/Utils.js";

import {
  eventExists,
  validateInviteCode,
  countEventUsers,
  getMyParticipation,
  userRegisterEvent,
  creatorUpdateEventSocial,
} from "utils/Api.js";

import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.compat.css";

import Avatar from "boring-avatars";

import Loader from "components/Loaders/Loader.js";
import LoaderDots from "components/Loaders/LoaderDots.js";
import LoaderSection from "components/Loaders/LoaderSection.js";
import LoaderModal from "components/Loaders/LoaderModal.js";

import ScaleLoader from "react-spinners/ScaleLoader";
import BarLoader from "react-spinners/BarLoader";

import SocialLinksEditable from "components/Lists/SocialLinksEditable.js";

const {
  defaultFormImg,
  defaultFormBanner,
  defaultProfileImg,
  bgGradient,
  defaultBoxGrayImg,
  verifiedImg,
  ethImg,
  nsfwImg,
  mmImg,
  wcImg,
  pwImg,
  ethWhiteImg,
  ethLogoColor,
  polygonImg,
  polygonWhiteImg,
  polygonLogoColor,
  bscLogoColor,
  ftmLogoColor,
  avaxLogoColor,
  cronosLogoColor,
  solLogoColor,
  catTokenImg,
  catArtImg,
  catMusicImg,
  catGameImg,
  catPartyImg,
  catSportsImg,
  catDaoImg,
  catMeetingImg,
  catStudyImg,
  catLeisureImg,
  catFundingImg,
  catMiscImg,
} = require("utils/Variables.js");

export default function EventSocial(props) {
  let { event, updateEvent, isEventActiveCreator } = props;

  const { currentAuthToken, currentUser, currentUserInfo, setCurrentUserInfo } =
    useContext(AuthContext);

  const hasAdminRole = currentUserInfo?.hasAdminRole;
  const hasCreatorRole = currentUserInfo?.hasCreatorRole;
  const isVerifiedUser = currentUserInfo?.isVerifiedUser;

  const [dataSaving, setDataSaving] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  const [links, setLinks] = useState([]);

  const [videoUrl, setVideoUrl] = useState(event?.videoUrl || "");

  const [useAccountEmail, setUseAccountEmail] = useState(
    currentUser?.email === event?.supportEmail
  );
  const [supportEmail, setSupportEmail] = useState(event?.supportEmail || "");
  const [emailError, setEmailError] = useState();

  const toggleUseAccountEmail = (flag) => {
    if (useAccountEmail !== flag) {
      setUseAccountEmail(!useAccountEmail);

      if (flag) {
        setSupportEmail(currentUser?.email);
      } else {
        setSupportEmail(event?.supportEmail);
      }
      setFormChanged(true);
    }
  };

  const saveEventSocial = async () => {
    try {
      setDataSaving(true);

      const eventData = {
        links,
        videoUrl,
        supportEmail,
      };

      const res = await creatorUpdateEventSocial(
        currentAuthToken,
        event?.eventId,
        eventData
      );

      if (res?.success) {
        notify("Successfully updated event.", "success");
      } else {
        notify("Unable to update event.", "error");
      }
    } catch (err) {
      console.error(err.message);
      notify("Unable to update event.", "error");
    } finally {
      setDataSaving(false);
      setFormChanged(false);
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      {(hasAdminRole || isEventActiveCreator) && (
        <>
          <h5 className="text-default">
            <strong>Social</strong>
          </h5>
          <Form
            onSubmit={() => {}}
            validate={async (values) => {
              const errors = {};

              if (videoUrl?.length > 100) {
                errors.video = "Video URL must not exceed 100 characters.";
              }

              if (supportEmail && !validEmail(supportEmail)) {
                errors.email = "Email must have valid format.";
              } else if (supportEmail.length > 100) {
                errors.email = "Email must not exceed 100 characters.";
              }

              return errors;
            }}
            render={({
              handleSubmit,
              values,
              submitting,
              validating,
              valid,
            }) => (
              <div className="m-2">
                <form onSubmit={handleSubmit}>
                  <Row className="mt-4 mb-4">
                    <Col xl="9" lg="10" md="11" sm="12" xs="12">
                      <div>
                        <FormGroup>
                          <Field name="links">
                            {({ input, meta }) => (
                              <>
                                <Label for="links">
                                  <div>
                                    <strong>Links</strong>
                                  </div>
                                  <small className="text-muted">
                                    You may add up to 5 links
                                  </small>
                                </Label>

                                <SocialLinksEditable
                                  links={event?.links}
                                  updateLinks={setLinks}
                                  updateFormChanged={setFormChanged}
                                />
                              </>
                            )}
                          </Field>
                        </FormGroup>
                      </div>
                      <div>
                        <FormGroup>
                          <Field name="video">
                            {({ input, meta }) => (
                              <>
                                <Label for="video">
                                  <div>
                                    <strong>Video</strong>
                                  </div>
                                  <small className="text-muted">
                                    Promo video to be embedded on the page{" "}
                                  </small>
                                </Label>
                                <InputGroup>
                                  <InputGroupText className="input-group-left pr-2 text-muted">
                                    <FontAwesomeIcon icon="fa-brands fa-youtube" />
                                  </InputGroupText>
                                  <Input
                                    {...input}
                                    id="videoUrl"
                                    value={videoUrl}
                                    className="input-group-right pl-2"
                                    onChange={(e) => {
                                      setVideoUrl(
                                        removeProtocolStr(e.target.value)
                                      );
                                      setFormChanged(true);
                                    }}
                                    placeholder={
                                      videoUrl ||
                                      "youtube.com/watch?v=your_video"
                                    }
                                    type="text"
                                  />
                                </InputGroup>

                                {meta.error && meta.touched && (
                                  <small className="text-danger">
                                    <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                    {meta.error}
                                  </small>
                                )}
                              </>
                            )}
                          </Field>
                        </FormGroup>
                      </div>
                      <hr />
                      <div>
                        <FormGroup>
                          <Field name="email">
                            {({ input, meta }) => (
                              <>
                                <Row className="my-1">
                                  <Col className="col-5">
                                    <Label for="email">
                                      <div>
                                        <strong>Support Email</strong>
                                      </div>
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label className="custom-toggle float-right">
                                      <Input
                                        checked={useAccountEmail}
                                        type="checkbox"
                                        onChange={() => {
                                          toggleUseAccountEmail(
                                            !useAccountEmail
                                          );
                                        }}
                                      />
                                      <span className="custom-toggle-slider rounded-circle"></span>
                                    </Label>
                                    <small className="text-default float-right mr-2 mt-1">
                                      Use Account Email
                                    </small>
                                  </Col>
                                </Row>
                                <InputGroup>
                                  <InputGroupText className="input-group-left text-muted">
                                    <FontAwesomeIcon icon="fa-envelope" />
                                  </InputGroupText>
                                  <Input
                                    {...input}
                                    id="email"
                                    value={supportEmail}
                                    onChange={(e) => {
                                      setSupportEmail(e.target.value);
                                      setEmailError();
                                    }}
                                    placeholder={
                                      supportEmail || "youremail@example.com"
                                    }
                                    type="text"
                                    disabled={useAccountEmail}
                                  />
                                </InputGroup>

                                {((meta.error && meta.touched) || emailError) &&
                                  !useAccountEmail &&
                                  supportEmail && (
                                    <small className="text-danger">
                                      <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                      {meta.error || emailError}
                                    </small>
                                  )}
                              </>
                            )}
                          </Field>
                        </FormGroup>
                      </div>

                      <div className="mt-5 mb-3">
                        <Button
                          className="btn btn-dark mt-4 btn-block"
                          color="dark"
                          type="button"
                          onClick={saveEventSocial}
                          disabled={!valid || dataSaving || !formChanged}
                        >
                          Update Event
                          {dataSaving && (
                            <span className="ml-2">
                              <ScaleLoader
                                color="#eee"
                                loading={dataSaving}
                                height="10px"
                                cssOverride={{
                                  display: "inline",
                                }}
                              />
                            </span>
                          )}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </form>
              </div>
            )}
          />
        </>
      )}
    </>
  );
}
