import React, { useState, useEffect, createContext } from "react";
import axios from "axios";

import FirebaseInit from "utils/FirebaseInit.js";

import { getCurrentUserInfo } from "utils/Api.js";

import { Alert, Button, Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import LoaderPage from "components/Loaders/LoaderPage.js";
import BarLoader from "react-spinners/BarLoader";

import { ethers } from "ethers";

const { REACT_APP_API_HOST, REACT_APP_GOODLE_RECAPTCHA_CHECKBOX_KEY } =
  process.env;

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { firebaseDb, doc, getDoc, firebaseAuth, onAuthStateChanged } =
    FirebaseInit();

  const [currentUser, setCurrentUser] = useState();
  const [currentUserId, setCurrentUserId] = useState();
  const [currentUserEthAddress, setCurrentUserEthAddress] = useState();
  const [currentAuthToken, setCurrentAuthToken] = useState();
  const [currentUserInfo, setCurrentUserInfo] = useState();
  const [currentUserStatus, setCurrentUserStatus] = useState();
  const [pending, setPending] = useState(true);

  useEffect(() => {
    onAuthStateChanged(firebaseAuth, async (user) => {
      try {
        setPending(true);

        if (user) {
          const idToken = await user?.getIdToken();
          setCurrentUser(user);
          setCurrentUserId(user.uid);
          setCurrentAuthToken(idToken);

          const userInfo = await getCurrentUserInfo(idToken);
          setCurrentUserEthAddress(userInfo?.ethAddress);
          setCurrentUserInfo(userInfo);
          setCurrentUserStatus(userInfo?.userStatus);
        } else {
          setCurrentUser();
          setCurrentUserInfo();
          setCurrentAuthToken();
          setCurrentUserStatus();
        }
      } catch (err) {
        console.error(err.message);

        const userStatus = err.response?.data?.userStatus;

        if (userStatus) {
          setCurrentUserStatus(userStatus);
        }
      } finally {
        setPending(false);
      }
    });
  }, []);

  if (pending) {
    return <LoaderPage />;
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        currentUserId,
        currentUserEthAddress,
        currentAuthToken,
        currentUserInfo,
        setCurrentUserInfo,
        currentUserStatus,
        setCurrentUserStatus,
      }}
    >
      {!pending && (
        <>
          {children}
          <Container>
            {currentUser &&
              (currentUserStatus === "user_request_deletion" ? (
                <Alert
                  color='gray'
                  className='text-white shadow'
                  style={{
                    position: "fixed",
                    bottom: "1%",
                    left: "15%",
                    width: "70%",
                    backgroundImage:
                      "linear-gradient(90deg, #cb105d 0%, #cb1e10 100%",
                    opacity: "0.99",
                    zIndex: "9999",
                  }}
                >
                  <Row>
                    <Col xl='9' lg='g' mg='9' sm='12' xs='12'>
                      <p>
                        You have requested for account deletion. If you think
                        this is a mistake, please contact us at{" "}
                        <a
                          href='mailto:gm@passlabs.xyz'
                          target='_blank'
                          className='text-white'
                        >
                          <strong>gm@passlabs.xyz</strong>
                        </a>
                      </p>
                    </Col>
                    <Col xl='3' lg='3' mg='3' sm='12' xs='12'>
                      <span className='float-right'>
                        <Button
                          size='md'
                          color='white'
                          href='mailto:gm@passlabs.xyz'
                          target='_blank'
                          className='my-2'
                        >
                          Contact Us
                        </Button>
                      </span>
                    </Col>
                  </Row>
                </Alert>
              ) : currentUserStatus === "suspended" ? (
                <Alert
                  color='gray'
                  className='text-white shadow'
                  style={{
                    position: "fixed",
                    bottom: "1%",
                    left: "15%",
                    width: "70%",
                    backgroundImage:
                      "linear-gradient(90deg, #cb105d 0%, #cb1e10 100%",
                    opacity: "0.99",
                    zIndex: "9999",
                  }}
                >
                  <Row>
                    <Col xl='9' lg='g' mg='9' sm='12' xs='12'>
                      <p>
                        Your account has been suspended. Please review the
                        community guideline in{" "}
                        <a href='/terms' target='_blank' className='text-white'>
                          <strong>Terms of Service</strong>.
                        </a>
                      </p>
                    </Col>
                    <Col xl='3' lg='3' mg='3' sm='12' xs='12'>
                      <span className='float-right'>
                        <Button
                          size='md'
                          color='white'
                          href='/terms'
                          target='_blank'
                          className='my-2'
                        >
                          Review
                        </Button>
                      </span>
                    </Col>
                  </Row>
                </Alert>
              ) : (
                (!currentUserInfo?.ethAddress ||
                  !currentUserInfo?.name ||
                  !currentUser.email) &&
                window.location.pathname !== "/settings" && (
                  <Alert
                    color='gray'
                    className='text-white shadow'
                    style={{
                      position: "fixed",
                      bottom: "1%",
                      left: "15%",
                      width: "70%",
                      backgroundImage:
                        "linear-gradient(90deg, #087EE1 0%, #05D6B4 100%",
                      opacity: "0.99",
                      zIndex: "9999",
                    }}
                  >
                    <Row>
                      <Col xl='9' lg='g' mg='9' sm='12' xs='12'>
                        <p>
                          Looks like you haven't set up profile yet. Complete
                          profile and unlock all the perks.
                        </p>
                      </Col>
                      <Col xl='3' lg='3' mg='3' sm='12' xs='12'>
                        <span className='float-right'>
                          <Button
                            size='md'
                            color='info'
                            href='/settings'
                            className='my-2'
                          >
                            Go to Settings
                          </Button>
                        </span>
                      </Col>
                    </Row>
                  </Alert>
                )
              ))}
          </Container>
        </>
      )}
    </AuthContext.Provider>
  );
};
