import React from "react";

export default function LoaderDots(props) {
  let { loading } = props;

  return loading ? (
    <div className='loading-zone-bottom'>
      <div className='dot-pulse'></div>
    </div>
  ) : (
    ""
  );
}
