import React, { useState, useEffect, useContext } from "react";
import {
  Link,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { AuthContext } from "Auth.js";

import Moment from "react-moment";
import moment from "moment-timezone";

// reactstrap components
import {
  Nav,
  NavbarBrand,
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Button,
  Badge,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Toast,
  ToastHeader,
  ToastBody,
  UncontrolledTooltip,
  UncontrolledDropdown,
  ButtonGroup,
  Dropdown,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  Label,
  InputGroup,
  InputGroupText,
  Input,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Core Components
import TopNavbar from "components/Navbars/TopNavbar.js";

import Loader from "components/Loaders/Loader.js";
import LoaderModal from "components/Loaders/LoaderModal.js";
import ModalSuccess from "components/Modals/ModalSuccess.js";
import ScaleLoader from "react-spinners/ScaleLoader";
import BarLoader from "react-spinners/BarLoader";

import ManageEventButton from "components/Buttons/ManageEventButton.js";
import UrlButton from "components/Buttons/UrlButton.js";
import SocialLinksList from "components/Lists/SocialLinksList.js";
import SimpleCreatorsList from "components/Lists/SimpleCreatorsList.js";
import MultiSessionsCollapse from "components/Lists/MultiSessionsCollapse.js";

import Avatar from "boring-avatars";

import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.compat.css";

import pluralize from "pluralize";

import Select from "react-select";
import makeAnimated from "react-select/animated";

import {
  truncateAddressShort,
  truncateText,
  notify,
  formatPrettyNumber,
  DateWithTZ,
} from "utils/Utils.js";

import {
  createSiweMessage,
  eventExists,
  firebaseUpload,
  createEvent,
  getEvent,
  likeEvent,
  unlikeEvent,
  countEventLikes,
  getUserInvites,
  validateInviteCode,
  countEventUsers,
  getMyParticipation,
  userRegisterEvent,
  creatorUpdateOwnTeamRole,
} from "utils/Api.js";

import Countdown, { zeroPad } from "react-countdown";

import Parser from "html-react-parser";
import DOMPurify from "dompurify";

import ReactPlayer from "react-player";

import { Map, Marker, ZoomControl } from "pigeon-maps";

import { InlineShareButtons } from "sharethis-reactjs";
import { google, outlook, ics } from "calendar-link";

const { REACT_APP_GOODLE_RECAPTCHA_CHECKBOX_KEY } = process.env;

const {
  bgAbstractLine01,
  bgAbstractLine02,
  bgAbstractLine03,
  bgAbstractLine04,
  bgAbstractLine05,
  defaultBoxGrayImg,
  verifiedImg,
  nsfwImg,
  ethImg,
  ethWhiteImg,
  polygonImg,
  polygonWhiteImg,
  calImg,
  gCalImg,
  outlookImg,
  ChainLogos,
  ChainOptions,
  EventCategoryOptions,
  USDateOptionTZ,
  USDateOption,
  USDateShortOption,
  USDateTimeOption,
  USDateTimeShortOption,
  UserRoleTypes,
  CreatorRoleTypes,
  UserRoleStatus,
  VerifiedUserTypes,
  VerifiedUserStatus,
  SocialShareOptions,
  SocialShareMessage,
  SocialShareTitle,
  SocialShareSubject,
  SocialShareUsername,
  DisclaimerShort,
} = require("utils/Variables.js");

const { REACT_APP_API_HOST, REACT_APP_FBASE_API_KEY } = process.env;

export default function Event() {
  const navigate = useNavigate();

  const { currentAuthToken, currentUser, currentUserInfo, setCurrentUserInfo } =
    useContext(AuthContext);

  const hasAdminRole = currentUserInfo?.hasAdminRole;
  const hasCreatorRole = currentUserInfo?.hasCreatorRole;
  const isVerifiedUser = currentUserInfo?.isVerifiedUser;

  const currentUrl = window.location.href;
  const currentUrlClean =
    currentUrl.indexOf("?") > 0
      ? currentUrl.substring(0, currentUrl.indexOf("?"))
      : currentUrl;

  const { eventId } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const invite = searchParams.get("i");

  const [pageLoading, setPageLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [inviteCodeChecking, setInviteCodeChecking] = useState(false);
  const [isInviteCodeValid, setIsInviteCodeValid] = useState();

  const [isOwner, setIsOwner] = useState(false);
  const [isCohost, setIsCohost] = useState(false);
  const [isPanelist, setIsPanelist] = useState(false);
  const [isPendingCohost, setIsPendingCohost] = useState(false);
  const [isPendingPanelist, setIsPendingPanelist] = useState(false);
  const [isEventActiveCreator, setIsEventActiveCreator] = useState(false);
  const [isEventCreator, setIsEventCreator] = useState(false);

  const [eventImage, setEventImage] = useState();
  const [bannerImage, setBannerImage] = useState();
  const [eventName, setEventName] = useState();
  const [eventDescription, setEventDescription] = useState();

  const [isFree, setIsFree] = useState();
  const [isRegEnabled, setIsRegEnabled] = useState();
  const [isInstantAccessEnabled, setIsINstantAccessEnabled] = useState();

  const [isSecretEnabled, setIsSecretEnabled] = useState();
  const [secretName, setSecretName] = useState();
  const [secretCode, setSecretCode] = useState();

  const [eventAlert, setEventAlert] = useState(true);
  const [passAlert, setPassAlert] = useState(true);

  const [timezone, setTimezone] = useState();
  const [timezoneLabel, setTimezoneLabel] = useState();
  const [eventStartDate, setEventStartDate] = useState();
  const [eventStartDateTime, setEventStartDateTime] = useState();
  const [eventEndDate, setEventEndDate] = useState();
  const [eventEndDateTime, setEventEndDateTime] = useState();
  const [eventEnded, setEventEnded] = useState();
  const [sessions, setSessions] = useState();
  const [showSessions, setShowSessions] = useState(false);

  const [eventCategory, setEventCategory] = useState();
  const [eventVenue, setEventVenue] = useState();
  const [isVirtual, setIsVirtual] = useState(true);
  const [eventLocation, setEventLocation] = useState();
  const [eventLocationLat, setEventLocationLat] = useState();
  const [eventLocationLng, setEventLocationLng] = useState();
  const [isLocationRestricted, setIsLocationRestricted] = useState(true);

  const [maxParticipants, setMaxParticipants] = useState();
  const [spotsFull, setSpotsFull] = useState();

  const [blockchain, setBlockchain] = useState();
  const [tokenUrl, setTokenUrl] = useState();

  const [registered, setRegistered] = useState(false);
  const [registeredAt, setRegisteredAt] = useState();
  const [registeredStatus, setRegisteredStatus] = useState();
  const [registeredAtShort, setRegisteredAtShort] = useState();
  const [activeParticipant, setActiveParticipant] = useState();

  const [passName, setPassName] = useState();
  const [passSymbol, setPassSymbol] = useState();
  const [passImage, setPassImage] = useState();
  const [passType, setPassType] = useState();
  const [passTitle, setPassTitle] = useState();
  const [passContract, setPassContract] = useState();
  const [requireUniquePassId, setRequireUniquePassId] = useState();

  const [eventLinks, setEventLinks] = useState();
  const [eventWebsite, setEventWebsite] = useState();
  const [eventVideoUrl, setEventVideoUrl] = useState();
  const [eventTwitter, setEventTwitter] = useState();
  const [eventDiscord, setEventDiscord] = useState();
  const [eventInstagram, setEventInstagram] = useState();
  const [isPrivate, setIsPrivate] = useState();
  const [isNSFW, setIsNSFW] = useState();

  const [isCancelled, setIsCancelled] = useState(false);
  const [isPublished, setIsPublished] = useState(true);

  const [maxUserInvites, setMaxUserInvites] = useState();

  const [inviteCodeInputChanged, setInviteCodeInputChanged] = useState(false);
  const [inviteCode, setInviteCode] = useState(invite || "");
  const [inviteError, setInviteError] = useState();

  const [myInviteStatus, setMyInviteStatus] = useState();
  const [myInviteLink, setMyInviteLink] = useState(`realpass.xyz/${eventId}`);
  const [myTotalInvites, setMyTotalInvites] = useState();

  const [eventCalendar, setEventCalendar] = useState({});

  const [ownerEthAddress, setOwnerEthAddress] = useState();
  const [ownerImage, setOwnerImage] = useState();
  const [ownerName, setOwnerName] = useState();
  const [isOwnerVerifiedUser, setIsOwnerVerifiedUser] = useState(false);
  const [eventTeam, setEventTeam] = useState();

  const [supportEmail, setSupportEmail] = useState();

  const [liked, setLiked] = useState(false);
  const [totalLikes, setTotalLikes] = useState(0);
  const [followed, setFollowed] = useState(false);
  const [totalReg, setTotalReg] = useState(0);

  const animatedComponents = makeAnimated();
  const [userTotalPassHolding, setUserTotalPassHolding] = useState(0);
  const [userTokenOptions, setUserTokenOptions] = useState([]);
  const [userTokenOption, setUserTokenOption] = useState();
  const [eligibleToJoin, setEligibleToJoin] = useState(false);

  const [passDetailCollapseOpen, setPassDetailCollapseOpen] = useState(true);
  const [secretCollapseOpen, setSecretCollapseOpen] = useState(false);
  const [hostsCollapseOpen, setHostsCollapseOpen] = useState(true);
  const [descriptionCollapseOpen, setDescriptionCollapseOpen] = useState(true);
  const [howToJoinCollapseOpen, setHowToJoinCollapseOpen] = useState(true);

  const [inviteInputOpen, setInviteInputOpen] = useState(false);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [registerModalOpen, setRegisterModalOpen] = useState(false);
  const [unlikeModalOpen, setUnlikeModalOpen] = useState(false);
  const [unfollowModalOpen, setUnfollowModalOpen] = useState(false);
  const [issueModalOpen, setIssueModalOpen] = useState(false);

  const [confirmAge, setConfirmAge] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);

  const [teamUpdating, setTeamUpdating] = useState(false);

  const [passError, setPassError] = useState();
  const [eventError, setEventError] = useState();

  const fetchEvent = async () => {
    try {
      setPageLoading(true);

      const event = await getEvent(currentAuthToken, eventId);

      if (event) {
        setIsCancelled(event.isCancelled);
        setIsPublished(event.isPublished);

        setEventName(event.name);
        setEventDescription(event.description);
        setEventImage(event.imageUrl);
        setBannerImage(event.bannerUrl);
        setEventCategory(event.category);

        const start = new Date(event.startDateTime);
        const end = new Date(event.endDateTime);

        const dateTZOption = JSON.parse(JSON.stringify(USDateOption));
        dateTZOption.timeZone = event.timezone;

        const dateTimeTZOption = JSON.parse(JSON.stringify(USDateTimeOption));
        dateTimeTZOption.timeZone = event.timezone;

        const startTimeShort = `${start?.toLocaleDateString(
          "en-US",
          dateTZOption
        )}`;

        const endTimeShort = `${end?.toLocaleDateString(
          "en-US",
          dateTZOption
        )}`;

        const startTimeLong = start?.toLocaleString("en-US", dateTimeTZOption);

        const endTimeLong = end?.toLocaleString("en-US", dateTimeTZOption);

        setTimezone(event.timezone);
        setTimezoneLabel(
          `${event.timezone} (${moment().tz(event.timezone)?.zoneAbbr()})`
        );
        setEventStartDate(startTimeShort);
        setEventEndDate(endTimeShort);

        setEventStartDateTime(startTimeLong);
        setEventEndDateTime(endTimeLong);

        setSessions(event.sessions);

        setIsVirtual(event.isVirtual);
        setIsLocationRestricted(event.isLocationRestricted);

        if (!event.isLocationRestricted) {
          setEventVenue(event.venue);
          setEventLocation(event.location);
        }

        // Always show add to calendar, even time is TBA
        const eventCal = {
          title: event.name,
          start: start,
          end: end,
          location: event.location?.name,
          url: `${currentUrlClean}?ref=addToCalendar`,
        };

        setEventCalendar(eventCal);
        setIsFree(event.isFree);
        setIsRegEnabled(event.isRegisterEnabled);
        setIsPrivate(event.isPrivate);
        setIsNSFW(event.isNSFW);

        setIsSecretEnabled(event.isSecretEnabled);
        setSecretName(event.secretName);
        setSecretCode(event.secretCode);

        setEventLinks(event.links);
        setEventVideoUrl(event.videoUrl);
        setSupportEmail(event.supportEmail);

        const owner = event.owner;
        const team = event.team;

        setOwnerName(owner?.name);
        setOwnerImage(owner?.imageUrl);
        setOwnerEthAddress(owner?.ethAddress);
        setIsOwnerVerifiedUser(owner?.isVerifiedUser);

        setEventTeam(
          team?.sort((x, y) => {
            return x?.eventRole?.length - y?.eventRole?.length;
          }) || []
        );

        setIsOwner(event.isOwner);
        setIsCohost(event.isCohost);
        setIsPanelist(event.isPanelist);
        setIsPendingCohost(event.isPendingCohost);
        setIsPendingPanelist(event.isPendingPanelist);

        const isActiveCreator =
          hasCreatorRole &&
          (event.isOwner || event.isCohost || event.isPanelist);
        const isCreator =
          hasCreatorRole &&
          (event.isOwner ||
            event.isCohost ||
            event.isPanelist ||
            event.isPendingCohost ||
            event.isPendingPanelist);
        setIsEventActiveCreator(isActiveCreator);
        setIsEventCreator(isCreator);

        setTotalLikes(event.totalLikes);
        setLiked(event.isLikedByUser);

        const totalReg = event.totalRegistered;
        setTotalReg(totalReg || 0);
        setMaxParticipants(event.maxParticipants);
        setRegistered(event?.registered);
        setRegisteredStatus(event?.registerStatus);

        const regTime = Date.parse(event?.registeredAt);

        if (!isNaN(regTime)) {
          setRegisteredAt(
            new Date(regTime).toLocaleDateString("en-US", USDateOption)
          );
          setRegisteredAtShort(
            new Date(regTime).toLocaleDateString("en-US", USDateShortOption)
          );
        }

        setActiveParticipant(event?.registerStatus === "active");

        setEventEnded(end?.getTime() < new Date().getTime());
        setSpotsFull(
          event.maxParticipants >= 0 && totalReg >= event.maxParticipants
        );

        setMaxUserInvites(event.maxUserInvites);

        await fetchMyInvites(
          event.maxUserInvites,
          event.isPrivate,
          event?.registerStatus === "active",
          isCreator
        );

        if (!event.registeredAt) {
          // If user already registered, don't check invite
          await checkInviteCode(event.isPrivate);
        }

        setEventError();
      } else {
        navigate("/404");
      }
      //   eventQuery.equalTo("objectId", eventId);
      //   nfevent = await eventQuery.first();

      //   if (nfevent) {
      //     const name = nfevent.get("name");
      //     const description = nfevent.get("description");
      //     const regEnabled = nfevent.get("registrationEnabled");
      //     const accEnabled = nfevent.get("accessEnabled");
      //     const image = nfevent.get("eventImage");
      //     const banner = nfevent.get("bannerImage");
      //     const startTime = nfevent.get("startDateTime");
      //     const endTime = nfevent.get("endDateTime");
      //     const category = nfevent.get("category");
      //     const virtual = nfevent.get("isVirtual");
      //     const location = nfevent.get("location");
      //     const maxParts = nfevent.get("maxParticipants");
      //     const creator = nfevent.get("creator");
      //     const contract = nfevent.get("passContract");
      //     const uniqueId = nfevent.get("requireUniquePassId");

      //     // TODO: remove rinkeby
      //     const chain =
      //       nfevent.get("blockchain") === "eth" ? "rinkeby" : "mumbai";

      //     // const chain = nfevent.get("blockchain");

      //     const website = nfevent.get("website");
      //     const videoUrl = nfevent.get("videoUrl");
      //     const twitter = nfevent.get("twitter");
      //     const discord = nfevent.get("discord");
      //     const instagram = nfevent.get("instagram");
      //     const publicFlag = nfevent.get("isPublic");
      //     const nsfw = nfevent.get("isNSFW");
      //     const inviteLimit = nfevent.get("userInviteMaxUsage");

      //     setEventName(name);
      //     setEventDescription(description);
      //     setIsRegistrationEnabled(regEnabled);
      //     setAccessEnabled(accEnabled);
      //     setEventImage(image?.url());
      //     setBannerImage(banner?.url());

      //     await fetchMyAccess();

      //     const startTimeShort = startTime?.toLocaleDateString(
      //       "en-US",
      //       USDateOption
      //     );

      //     const endTimeShort = endTime?.toLocaleDateString("en-US", USDateOption);

      //     const startTimeLong = startTime?.toLocaleString(
      //       "en-US",
      //       USDateTimeOption
      //     );

      //     const endTimeLong = endTime?.toLocaleString("en-US", USDateTimeOption);

      //     setEventStartTimestamp(startTime?.getTime());
      //     setEventEndTimestamp(endTime?.getTime());

      //     setEventStartDate(startTimeShort);
      //     setEventEndDate(endTimeShort);

      //     setEventStartDateTime(startTimeLong);
      //     setEventEndDateTime(endTimeLong);

      //     if (endTime?.getTime() <= new Date().getTime()) {
      //       setEventEnded(true);
      //     }

      //     setEventCategory(category);
      //     setIsVirtual(virtual);
      //     setEventLocation(location);
      //     setMaxParticipants(maxParts);
      //     setBlockchain(chain);
      //     setEventWebsite(website);
      //     setEventVideoUrl(videoUrl);
      //     setEventTwitter(twitter);
      //     setEventDiscord(discord);
      //     setEventInstagram(instagram);
      //     setIsPublic(publicFlag);
      //     setIsNSFW(nsfw);
      //     setMaxUserInvites(inviteLimit);
      //     setRequireUniquePassId(uniqueId);

      //     if (publicFlag && inviteCode === "") {
      //       setInviteError("");
      //     }

      // if (creator) {
      //   creatorProfileQuery.equalTo("objectId", creator.id);
      //   nfcreatorProfile = await creatorProfileQuery.first();

      //   if (nfcreatorProfile) {
      //     setIsEventCreator(
      //       currentUser &&
      //         currentUser.id &&
      //         currentUser.id === nfcreatorProfile.get("user")?.id
      //     );

      //     setCreatorEthAddress(nfcreatorProfile.get("ethAddress"));
      //     setCreatorImage(nfcreatorProfile.get("image")?.url());
      //     setCreatorName(nfcreatorProfile.get("name"));
      //     setCreatorBio(nfcreatorProfile.get("bio"));
      //     setCreatorWebsite(nfcreatorProfile.get("website"));
      //     setCreatorTwitter(nfcreatorProfile.get("twitter"));
      //     setCreatorInstagram(nfcreatorProfile.get("instagram"));
      //     setCreatorEmail(nfcreatorProfile.get("contactEmail"));

      //     verifiedQuery.equalTo("profile", nfcreatorProfile);
      //     nfverified = await verifiedQuery.first();
      //     setIsNFVerified(nfverified?.get("verified"));
      //   }
      // }

      //   const totalParts = await fetchTotalParticipants();

      //   if (!isNaN(totalParts) && !isNaN(maxParts) && maxParts >= 0) {
      //     setSpotsFull(totalParts >= maxParts);
      //   }

      //   if (inviteLimit) {
      //     await fetchMyInvite();
      //   }

      //   // Check if invite code of the url param is valid after initially page loaded
      //   if (inviteCode !== "") {
      //     await checkInviteCode();
      //   }

      // if (contract) {
      //   setPassContract(contract);
      //   setFreePass(false);
      //   setPassTitle("Holders Only");

      // TODO: check if pass contract is RealPass
      // TODO: remove rinkeby and mumbai
      //   if (chain === "eth") {
      //     setTokenUrl(`https://etherscan.io/address/${contract}`);
      //   }

      //   if (chain === "matic") {
      //     setTokenUrl(`https://polygonscan.com/address/${contract}`);
      //   }

      //   const options = {
      //     chain: chain,
      //     address: contract,
      //     limit: 1,
      //   };

      //   try {
      // const res = await Moralis.Web3API.token.getAllTokenIds(options);
      // if (res && res.result && res.result.length > 0) {
      //   const nft = res.result[0];
      //   setPassName(nft?.name);
      //   setPassSymbol(nft?.symbol);
      //   setPassType(nft?.contract_type);
      //   setPassImage(
      //     JSON.parse(nft?.metadata)?.image?.replace(
      //       "ipfs://",
      //       "https://gateway.moralisipfs.com/ipfs/"
      //     )
      //   );
      // } else {
      //   setPassError("Cannot find the smart contract.");
      // }
      //   } catch (err) {
      //     console.error(err.message);
      //     setPassError("Unable to get the smart contract data.");
      //   } finally {
      //     setPageLoading(false);
      //   }
      //     } else {
      //       setPassTitle("Free Pass");
      //       setFreePass(true);
      //     }
      //   } else {
      //     navigate("/explore");
      //   }
    } catch (err) {
      console.error(err.message);
      if (err.response?.status === 404) {
        navigate("/404");
      } else {
        notify("Unable to fetch event data.", "error");
        setEventError(
          "This is event currently is not available. You can come back later."
        );
      }
    } finally {
      setPageLoading(false);
    }
  };

  const listSessions = () => {
    return sessions?.map((session, index) => {
      const start = new Date(session.startDateTime)?.toLocaleString(
        "en-US",
        USDateTimeOption
      );

      const end = new Date(session.endDateTime)?.toLocaleString(
        "en-US",
        USDateTimeOption
      );
      return (
        <div xs={12} sm={6} md={4} key={index} className="my-2  py-2">
          <div className="text-default">
            <FontAwesomeIcon icon="fa-clock" className="mr-2" />
            <strong>{session.name}</strong>
          </div>
          <div className="text-info ml-2">
            {start
              ? end
                ? start + " - " + end
                : start + " - " + "TBA"
              : "TBA"}
          </div>
        </div>
      );
    });
  };

  const toggleLike = async (likeFlag) => {
    if (isCancelled || !isPublished) {
      return;
    }

    if (currentUserInfo) {
      try {
        if (likeFlag === liked) {
          return;
        }
        let isLiking;

        setLiked(likeFlag);

        if (likeFlag) {
          isLiking = await likeEvent(currentAuthToken, eventId);
        } else {
          isLiking = await unlikeEvent(currentAuthToken, eventId);
        }

        const count = await countEventLikes(eventId);
        setTotalLikes(count);
      } catch (err) {
        console.error(err.message);
        setLiked(!likeFlag);
      }
    }
  };

  const fetchTotalParticipants = async () => {
    if (isCancelled || !isPublished) {
      return;
    }
    try {
      let count = await countEventUsers(eventId);
      let totalReg = count?.totalRegistered;

      if (isNaN(totalReg)) {
        totalReg = 0;
      }

      setTotalReg(totalReg);

      setSpotsFull(maxParticipants >= 0 && totalReg >= maxParticipants);
    } catch (err) {
      console.error(err.message);
    }
  };

  const fetchMyParticipation = async (maxInvites, privateEvent, isCreator) => {
    if (currentUserInfo) {
      try {
        const participant = await getMyParticipation(currentAuthToken, eventId);

        if (participant) {
          setRegistered(participant?.registered);

          const regTime = Date.parse(participant?.registeredAt);

          if (!isNaN(regTime)) {
            setRegisteredAt(
              new Date(regTime).toLocaleDateString("en-US", USDateOption)
            );
            setRegisteredAtShort(
              new Date(regTime).toLocaleDateString("en-US", USDateShortOption)
            );
          }

          setActiveParticipant(participant?.registerStatus === "active");

          await fetchMyInvites(
            maxInvites,
            privateEvent,
            participant?.registerStatus === "active",
            isCreator
          );

          if (!participant.registered || !participant.registeredAt) {
            // If user already registered, don't check invite
            await checkInviteCode(privateEvent);
          }
        }
      } catch (err) {
        console.error(err.message);
      }
    }
  };

  const checkInviteCode = async (privateEvent) => {
    if (isCancelled || !isPublished) {
      return;
    }
    if (!privateEvent) {
      setIsInviteCodeValid();
      setInviteError();
    }

    if (
      (privateEvent && !inviteCode) ||
      (inviteCode && inviteCode?.length !== 7)
    ) {
      setIsInviteCodeValid(false);
      setInviteError("Invite code is not valid.");
      return;
    }

    if (currentUserInfo && !!inviteCode) {
      try {
        setInviteCodeChecking(true);

        const isValid = await validateInviteCode(
          currentAuthToken,
          eventId,
          inviteCode
        );

        setInviteError(isValid ? "" : "Invite code is not valid.");
        setIsInviteCodeValid(isValid);
      } catch (err) {
        console.error(err.message);
        setInviteError(
          err.response?.data?.message === "Self invite error."
            ? "Self invite is not allowed."
            : "Invite code is not valid."
        );
        setIsInviteCodeValid(false);
      } finally {
        setInviteCodeChecking(false);
      }
    }
  };

  // Check if this is a private event and user is a participant
  const fetchMyInvites = async (
    inviteLimit,
    privateEvent,
    activeReg,
    isCreator
  ) => {
    if (isCancelled || !isPublished || eventEnded) {
      return;
    }
    if (
      currentUserInfo &&
      !!inviteLimit &&
      (!privateEvent || activeReg) &&
      !isCreator
    ) {
      try {
        const invites = await getUserInvites(currentAuthToken, eventId);
        const code = invites?.inviteCode;
        const totalInvites = invites.totalInvites || 0;

        setMyInviteStatus(invites?.inviteStatus);
        setMyTotalInvites(totalInvites);

        if (code) {
          setMyInviteLink(`realpass.xyz/${eventId}?i=${code}`);
        }
      } catch (err) {
        console.error(err.message);
      }
    }
  };

  const checkEligibility = async () => {
    if (isCancelled || !isPublished) {
      setEligibleToJoin(false);
      return;
    }
    try {
      setRegisterModalOpen(true);
      setModalLoading(true);

      //   if (passContract) {
      //     const options = {
      //       chain: blockchain,
      //       address: currentUser?.attributes?.ethAddress,
      //       token_address: passContract,
      //     };

      //     const res = await Moralis.Web3API.account.getNFTsForContract(options);
      //     if (res && res.result && res.result.length > 0) {
      //       setUserTotalPassHolding(res.total);

      //       if (requireUniquePassId) {
      //         const tokenOptions = res.result.map((token, index) => {
      //           return {
      //             value: token.token_id,
      //             label: (
      //               <div>
      //                 <FontAwesomeIcon icon='fa-ticket' className='ml-2 mr-2' />
      //                 <span>
      //                   {truncateText(token.symbol, 20)} #{token.token_id}
      //                 </span>
      //               </div>
      //             ),
      //           };
      //         });
      //         setUserTokenOptions(tokenOptions);

      //         if (res.total === 1) {
      //           setUserTokenOption(tokenOptions[0]);
      //           setEligibleToJoin(true);
      //         }
      //       } else {
      //         setEligibleToJoin(true);
      //       }
      //     }
      //   }

      if (isFree) {
        setEligibleToJoin(true);
      }
    } catch (err) {
      console.error(err.message);
    } finally {
      setModalLoading(false);
    }
  };

  // 1. Register free event
  // 2. Register pass without unique Id
  // 3. Register pass with unique Id
  const register = async () => {
    if (
      !isCancelled &&
      isPublished &&
      isRegEnabled &&
      !eventEnded &&
      !spotsFull &&
      (!registered || !registeredAt || !activeParticipant)
    ) {
      try {
        setModalLoading(true);

        const participant = await userRegisterEvent(
          currentAuthToken,
          eventId,
          inviteCode
        );

        if (participant && participant?.registerStatus === "active") {
          setRegistrationSuccess(true);
          setRegisterModalOpen(false);

          const regTime = Date.parse(participant?.registeredAt);

          if (!isNaN(regTime)) {
            setRegisteredAt(
              new Date(regTime).toLocaleDateString("en-US", USDateOption)
            );
            setRegisteredAtShort(
              new Date(regTime).toLocaleDateString("en-US", USDateShortOption)
            );
          }

          setActiveParticipant(true);
        }

        await fetchTotalParticipants();

        if (myInviteLink === `realpass.xyz/${eventId}`) {
          await fetchMyInvites(
            maxUserInvites,
            isPrivate,
            participant?.registerStatus === "active",
            isEventCreator
          );
        }

        // let params = {
        //   eventId: eventId,
        //   recaptcha: recaptchaToken,
        // };
        // if (inviteCode) {
        //   params.inviteCodeUsed = inviteCode;
        // }

        // if (!freePass && requireUniquePassId) {
        //   params.tokenId = userTokenOption?.value;
        // }

        // const accessRes = await Moralis.Cloud.run(
        //   "userRegisterEventAccess",
        //   params
        // );

        // if (!accessRes) {
        //   throw { message: "Unable to register for the event." };
        // }

        // const createdAt = accessRes.get("createdAt");

        // setRegisteredAt(
        //   createdAt?.toLocaleDateString("en-US", USDateOption)
        // );

        // nfmyAccessKey = new NFAccessKey();

        // const generateHash = new RandomHash();
        // const key = generateHash().toLowerCase();

        // nfmyAccessKey.set("access", accessRes);
        // nfmyAccessKey.set("accessKey", key);

        // const accessKeyRes = await nfmyAccessKey.save();

        // setModalLoading(false);

        // if (!accessKeyRes) {
        //   throw { message: "Unable to create the event access key." };
        // }

        // notify("You've successfully joined the event.", "success", 2000);
        // setRegisterModalOpen(false);

        // await fetchTotalParticipants();
      } catch (err) {
        console.error(err.message);
        // setModalLoading(false);
        notify(err.message || "Unable to register for the event.", "error");
      } finally {
        setModalLoading(false);
      }
    }
  };
  const performTeamAction = async (action) => {
    if (!action) {
      return;
    }

    if (!isPendingCohost && !isPendingPanelist) {
      return;
    }

    try {
      setTeamUpdating(true);

      const res = await creatorUpdateOwnTeamRole(
        currentAuthToken,
        eventId,
        action
      );

      if (res) {
        setEventTeam(
          res?.team?.sort((x, y) => {
            return x?.eventRole?.length - y?.eventRole?.length;
          }) || []
        );

        setIsOwner(res.isOwner);
        setIsCohost(res.isCohost);
        setIsPanelist(res.isPanelist);
        setIsPendingCohost(res.isPendingCohost);
        setIsPendingPanelist(res.isPendingPanelist);

        const isActiveCreator =
          hasCreatorRole && (res.isOwner || res.isCohost || res.isPanelist);
        const isCreator =
          hasCreatorRole &&
          (res.isOwner ||
            res.isCohost ||
            res.isPanelist ||
            res.isPendingCohost ||
            res.isPendingPanelist);
        setIsEventActiveCreator(isActiveCreator);
        setIsEventCreator(isCreator);

        notify(
          `You've ${
            action === "accept" ? "accepted" : "declined"
          } the team invitation.`,
          action === "accept" ? "success" : ""
        );
      }
    } catch (err) {
      console.error(err.message);
      notify(
        `Unable to ${
          action === "accept" ? "accept" : "decline"
        } team invitation.`,
        "error"
      );
    } finally {
      setTeamUpdating(false);
    }
  };

  useEffect(() => {
    fetchEvent();
  }, []);

  return (
    <>
      <BarLoader
        loading={pageLoading || teamUpdating}
        cssOverride={{
          width: "100%",
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          zIndex: "1031",
          backgroundColor: "#fff",
          opacity: "1",
        }}
      />
      <TopNavbar />
      <ReactNotifications />
      <div className="wrapper event-page">
        {!pageLoading &&
          (isCancelled ? (
            <Alert
              color="dark"
              className="mb-0 mt-2 text-center rounded-0 bg-dark"
              style={{ zIndex: "1000" }}
            >
              <span className="h5 text-white">
                ☔️ This event has been cancelled by the{" "}
                <a
                  href={`/${ownerEthAddress}`}
                  target="_blank"
                  className="text-info"
                >
                  <u>creator</u>
                </a>
                . You no longer have the access.
              </span>
            </Alert>
          ) : eventError ? (
            <Alert
              color="dark"
              className="mb-0 mt-2 text-center rounded-0 bg-dark"
              style={{ zIndex: "1000" }}
            >
              <span className="h5 text-white">
                <FontAwesomeIcon
                  icon="fa-triangle-exclamation"
                  className="mr-2"
                />
                {eventError}
              </span>
            </Alert>
          ) : !isPublished ? (
            <Alert
              color="dark"
              className="mb-0 mt-2 text-center rounded-0 bg-dark"
              style={{ zIndex: "1000" }}
            >
              <span className="h5 text-white">
                <FontAwesomeIcon icon="fa-bell" className="mr-2" />
                The event is not published. It's only visible to admin.
              </span>
            </Alert>
          ) : eventEnded ? (
            <Alert
              color="dark"
              className="mb-0 mt-2 text-center rounded-0 bg-dark"
              style={{ zIndex: "1000" }}
            >
              <span className="h5 text-white">
                <FontAwesomeIcon icon="fa-bell" className="mr-2" />
                This event has already ended.
                {/* Find more events{" "}
                <a href="/explore" className="text-info">
                  <u>here</u>
                </a>
                . */}
              </span>
            </Alert>
          ) : spotsFull ? (
            <Alert
              color="dark"
              className="mb-0 mt-2 text-center rounded-0 bg-dark"
              style={{ zIndex: "1000" }}
            >
              <span className="h5 text-white">
                <FontAwesomeIcon icon="fa-bell" className="mr-2" />
                This event has no more spots.
                {/* Find more events{" "}
                <a href="/explore" className="text-info">
                  <u>here</u>
                </a>
                . */}
              </span>
            </Alert>
          ) : (
            registered &&
            registeredAt &&
            !activeParticipant && (
              <Alert
                color="dark"
                className="mb-0 mt-2 text-center rounded-0 bg-dark"
                style={{ zIndex: "1000" }}
              >
                <span className="h5 text-white">
                  <FontAwesomeIcon icon="fa-bell" className="mr-2" />
                  It appears you don't have a valid pass. You may need to
                  reverify.
                </span>
              </Alert>
            )
          ))}
        <section className="section-event-cover section-shaped my-0">
          <img
            alt="..."
            className="bg-image w-100"
            src={bannerImage || bgAbstractLine01}
          />
        </section>
        <section className="section section-main">
          <Container>
            <div className="card-event">
              <div className="pb-2">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="7">
                    <div className="card-event-image-md">
                      <img
                        alt="..."
                        className="event-image-lg"
                        src={eventImage || defaultBoxGrayImg}
                      ></img>
                    </div>
                  </Col>
                  <Col
                    className="order-lg-3 text-lg-right align-self-lg-center"
                    lg="2"
                  >
                    <div className="card-event-actions justify-content-center py-lg-4 mt-lg-0 mt-md-2 mt-sm-1">
                      {(hasAdminRole ||
                        (hasCreatorRole && isEventActiveCreator)) && (
                        <ManageEventButton
                          eventId={eventId}
                          currentPath="event"
                        />
                      )}
                    </div>
                  </Col>
                  <Col className="order-lg-1" lg="2">
                    <div className="card-event-stats d-flex justify-content-center">
                      <div>
                        <small className="heading">
                          {formatPrettyNumber(totalLikes)}
                        </small>
                        <small className="description">Likes</small>
                      </div>
                      <div>
                        <small className="heading">
                          {formatPrettyNumber(totalReg)}
                        </small>
                        <small className="description">Joined</small>
                      </div>
                      <div>
                        <small className="heading">
                          {!isNaN(maxParticipants) ? (
                            maxParticipants >= 0 ? (
                              formatPrettyNumber(maxParticipants)
                            ) : (
                              <>
                                <FontAwesomeIcon
                                  icon="fa-infinity"
                                  id="unlimitedSpots"
                                  style={{ cursor: "pointer" }}
                                />

                                <UncontrolledTooltip
                                  placement="top"
                                  target="unlimitedSpots"
                                  trigger="hover"
                                >
                                  Unlimited Spots{" "}
                                </UncontrolledTooltip>
                              </>
                            )
                          ) : (
                            <>TBA</>
                          )}
                        </small>
                        <span className="description">
                          Spots{" "}
                          {spotsFull && (
                            <sup>
                              <small className="text-warning">FULL</small>
                            </sup>
                          )}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="text-center mt-lg-3">
                  <span className="display-4 text-dark">
                    {!pageLoading && (eventName || "Unnamed")}
                  </span>
                  {isPrivate && (
                    <span
                      id="privateEventTag"
                      className="text-white ml-2"
                      style={{ cursor: "pointer" }}
                    >
                      <sup>
                        <Badge color="secondary" className="text-default">
                          Private
                        </Badge>
                      </sup>
                    </span>
                  )}
                  {isPrivate && (
                    <UncontrolledTooltip
                      placement="top"
                      target="privateEventTag"
                      trigger="hover"
                    >
                      This is a private event.
                    </UncontrolledTooltip>
                  )}

                  {ownerEthAddress && (
                    <div className="my-3">
                      by{" "}
                      <a href={"/" + ownerEthAddress}>
                        <span className="h5" style={{ fontWieght: "bolder" }}>
                          {ownerImage ? (
                            <img
                              alt="..."
                              className="rounded-circle profile-image-xs mx-2"
                              src={ownerImage}
                            />
                          ) : (
                            <span className="rounded-circle mx-2">
                              <Avatar
                                size={30}
                                name={currentUserInfo?.ethAddress}
                                square="false"
                                variant="ring"
                                colors={[
                                  "#000000",
                                  "#2F2F2F",
                                  "#505050",
                                  "#797979",
                                  "#CECECE",
                                ]}
                                className="rounded-circle profile-image-xs"
                              />
                            </span>
                          )}
                          {ownerName || "Unnamed"}
                        </span>
                        {isOwnerVerifiedUser && (
                          <img
                            className="realpass-verified-name-suffix ml-2 mb-1"
                            src={verifiedImg}
                          />
                        )}
                      </a>
                    </div>
                  )}
                  <div className="ml-lg-9 mr-lg-9 mt-3 mb-4">
                    <span className="mx-2">
                      <FontAwesomeIcon
                        icon="fa-calendar-days"
                        className="mr-2"
                      />

                      <strong className="text-info">
                        {eventStartDate ? eventStartDate : "TBA"}
                        {eventEnded && (
                          <small className="text-warning"> ENDED</small>
                        )}
                      </strong>
                    </span>
                    <span className="mx-3">
                      {isVirtual ? (
                        <i className="ni ni-planet mr-2 align-middle" />
                      ) : (
                        <FontAwesomeIcon
                          icon="fa-location-dot"
                          className="mr-2"
                        />
                      )}
                      <strong className="text-info">
                        {!isLocationRestricted || activeParticipant ? (
                          eventLocation?.name ? (
                            isVirtual ? (
                              <>
                                <a
                                  href={
                                    eventLocation?.name.includes("https://") ||
                                    eventLocation?.name.includes("http://")
                                      ? eventLocation?.name
                                      : `https://${eventLocation?.name}`
                                  }
                                  target="_blank"
                                  rel="external nofollow noopener"
                                >
                                  Virtual
                                </a>
                              </>
                            ) : (
                              <>{eventVenue || eventLocation?.name}</>
                            )
                          ) : (
                            <>
                              {isVirtual ? "Virtual" : "IRL"}{" "}
                              <small className="text-primary">(TBA)</small>
                            </>
                          )
                        ) : (
                          <>
                            {isVirtual ? "Virtual" : "IRL"}{" "}
                            <small className="text-primary">(Hidden)</small>
                          </>
                        )}
                      </strong>
                    </span>
                  </div>
                </div>

                <Navbar
                  className="navbar-light rounded bg-white shadow my-2"
                  expand="lg"
                >
                  <Container>
                    <Row>
                      <Col
                        xl="4"
                        lg="4"
                        md="4"
                        sm="12"
                        xs="12"
                        className="justify-content-start text-left m-0 p-0"
                      >
                        <div className="float-left my-2">
                          <Button
                            id="likeButton"
                            className="btn-icon-only m-0"
                            color="link"
                            type="button"
                            onClick={() => {
                              if (liked) {
                                setUnlikeModalOpen(!unlikeModalOpen);
                              } else {
                                toggleLike(true);
                              }
                            }}
                            size="md"
                          >
                            <FontAwesomeIcon
                              className="fa-lg"
                              icon="fa-heart"
                              color={liked ? "red" : "gray"}
                            />
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target="likeButton"
                            trigger="hover"
                          >
                            {currentUser && currentUserInfo ? (
                              liked ? (
                                <>Unlike the event</>
                              ) : (
                                <>Like the event</>
                              )
                            ) : !currentUser ? (
                              "Must sign in"
                            ) : (
                              !currentUserInfo && "Must complete profile"
                            )}
                          </UncontrolledTooltip>

                          <Button
                            className="btn-icon-only m-0 mr-2"
                            color="link"
                            type="button"
                            id="addCalendar"
                            size="md"
                          >
                            <FontAwesomeIcon
                              className=" fa-lg"
                              icon="fa-calendar-plus"
                              color="gray"
                            />
                          </Button>

                          <UncontrolledPopover
                            trigger="legacy"
                            placement="bottom"
                            target="addCalendar"
                          >
                            <PopoverHeader className="text-default">
                              <small>
                                <strong>Add to Calendar</strong>
                              </small>
                            </PopoverHeader>
                            <PopoverBody className="p-3 d-flex">
                              <Row>
                                <Col className="ml-2">
                                  <a href={ics(eventCalendar)}>
                                    <span className="heading">
                                      <img src={calImg} />
                                    </span>
                                  </a>
                                </Col>
                                <Col>
                                  <a
                                    href={google(eventCalendar)}
                                    target="_blank"
                                    rel="external nofollow noopener"
                                  >
                                    <img src={gCalImg} />
                                  </a>
                                </Col>
                                <Col className="mr-2">
                                  <a
                                    href={outlook(eventCalendar)}
                                    target="_blank"
                                    rel="external nofollow noopener"
                                  >
                                    <img src={outlookImg} />
                                  </a>
                                </Col>
                              </Row>
                            </PopoverBody>
                          </UncontrolledPopover>

                          {eventLinks?.length > 0 && (
                            <span className="border-left mt-1">
                              <SocialLinksList links={eventLinks} size="lg" />
                            </span>
                          )}
                        </div>
                      </Col>
                      <Col xl="8" lg="8" md="8" sm="12" xs="12">
                        <div className="float-right text-right my-2">
                          <UncontrolledDropdown className="m-0">
                            <DropdownToggle
                              id="registerButton"
                              caret
                              size="md"
                              className={`m-0 ${
                                currentUserInfo &&
                                registered &&
                                registeredAt &&
                                activeParticipant
                                  ? "btn-success"
                                  : registered &&
                                    registeredAt &&
                                    !activeParticipant
                                  ? "btn-warning"
                                  : eventEnded || spotsFull
                                  ? "btn-warning"
                                  : "btn-info"
                              }`}
                              type="button"
                            >
                              {currentUserInfo &&
                              registered &&
                              registeredAt &&
                              activeParticipant ? (
                                <>Joined on {registeredAtShort} </>
                              ) : registered &&
                                registeredAt &&
                                !activeParticipant ? (
                                "Joined (Inactive)"
                              ) : eventEnded || spotsFull ? (
                                "Not Available"
                              ) : isFree ? (
                                "Join Free"
                              ) : (
                                "Join Now"
                              )}
                            </DropdownToggle>
                            <DropdownMenu
                              className="full-dropdown-menu-right-md"
                              style={{ margin: 0 }}
                            >
                              {currentUserInfo && registered && registeredAt ? (
                                <>
                                  <DropdownItem
                                    disabled={!currentUserInfo}
                                    href={`/myaccess?event=${eventId}`}
                                  >
                                    <FontAwesomeIcon icon="fa-qrcode" />
                                    <span className="ml-1">My Access</span>
                                  </DropdownItem>
                                </>
                              ) : (
                                <>
                                  <DropdownItem
                                    disabled={
                                      !currentUserInfo ||
                                      isCancelled ||
                                      !isPublished ||
                                      !isRegEnabled ||
                                      eventEnded ||
                                      spotsFull
                                    }
                                    onClick={checkEligibility}
                                  >
                                    <FontAwesomeIcon icon="fa-check-to-slot" />
                                    <span className="ml-1">
                                      Register{" "}
                                      {isCancelled ? (
                                        <small> (cancelled)</small>
                                      ) : !isPublished ? (
                                        <small> (not published)</small>
                                      ) : eventEnded ? (
                                        <small> (ended)</small>
                                      ) : spotsFull ? (
                                        <small> (full)</small>
                                      ) : !isRegEnabled ? (
                                        <small> (soon)</small>
                                      ) : !currentUser ? (
                                        <small> (must sign in)</small>
                                      ) : (
                                        !currentUserInfo && (
                                          <small> (need profile)</small>
                                        )
                                      )}
                                    </span>
                                  </DropdownItem>
                                </>
                              )}
                              <DropdownItem
                                onClick={() => setInviteModalOpen(true)}
                              >
                                <FontAwesomeIcon icon="fa-ranking-star" />
                                <span className="ml-1">Invite Friends</span>
                              </DropdownItem>{" "}
                              <DropdownItem divider></DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  setIssueModalOpen(!issueModalOpen)
                                }
                              >
                                <FontAwesomeIcon icon="fa-flag" />
                                <span className="ml-1">Report Issue</span>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>

                          {!currentUser && (
                            <UncontrolledTooltip
                              placement="top"
                              target="registerButton"
                              trigger="hover"
                            >
                              {!currentUser && "You must sign in first"}
                            </UncontrolledTooltip>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Navbar>

                <div>
                  <Row>
                    <Col xl="9" lg="8" md="8" sm="12" xs="12" className="my-2">
                      <div className="rounded-card accordion accordion-events ">
                        <Card className="shadow">
                          <CardHeader className="bg-white">
                            <a
                              aria-expanded={descriptionCollapseOpen}
                              onClick={() =>
                                setDescriptionCollapseOpen(
                                  !descriptionCollapseOpen
                                )
                              }
                              className="w-100 text-primary text-left"
                              style={{ cursor: "pointer" }}
                            >
                              <span className="h5 mb-0">
                                <strong>Details</strong>
                              </span>
                              <FontAwesomeIcon
                                icon={
                                  passDetailCollapseOpen
                                    ? "fa-chevron-up"
                                    : "fa-chevron-down"
                                }
                                className="float-right pt-1"
                              />
                            </a>
                          </CardHeader>
                          <Collapse isOpen={descriptionCollapseOpen}>
                            <CardBody>
                              {eventVideoUrl && (
                                <div className="my-4">
                                  <ReactPlayer
                                    url={
                                      eventVideoUrl?.includes("https://") ||
                                      eventVideoUrl?.includes("http://")
                                        ? eventVideoUrl
                                        : `https://${eventVideoUrl}`
                                    }
                                    width="100%"
                                    controls={true}
                                  />
                                </div>
                              )}

                              <div
                                className="mt-4 mb-5 description"
                                style={{ fontWeight: "300" }}
                              >
                                {eventDescription && (
                                  <div className="text-primary">
                                    {Parser(
                                      DOMPurify.sanitize(eventDescription)
                                    )}
                                  </div>
                                )}
                              </div>
                              <div className="my-4 text-right">
                                {blockchain && (
                                  <>
                                    <img
                                      id="blockchainBadge"
                                      src={ChainLogos[blockchain]}
                                      className="mr-2 desc-badge"
                                      style={{ cursor: "pointer" }}
                                    />
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="blockchainBadge"
                                      trigger="hover"
                                    >
                                      <span className="text-capitalize">
                                        {blockchain} Network
                                      </span>
                                    </UncontrolledTooltip>
                                  </>
                                )}
                                {eventCategory && (
                                  <>
                                    <img
                                      id="categoryBadge"
                                      src={
                                        EventCategoryOptions[eventCategory]
                                          ?.image
                                      }
                                      className="mr-2 desc-badge"
                                      style={{ cursor: "pointer" }}
                                    />
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="categoryBadge"
                                      trigger="hover"
                                    >
                                      <span className="text-capitalize">
                                        {
                                          EventCategoryOptions[eventCategory]
                                            ?.name
                                        }
                                      </span>
                                    </UncontrolledTooltip>
                                  </>
                                )}
                                {isNSFW && (
                                  <>
                                    <img
                                      id="nsfwBadge"
                                      src={nsfwImg}
                                      className="mr-2 desc-badge"
                                      style={{ cursor: "pointer" }}
                                    />
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="nsfwBadge"
                                      trigger="hover"
                                    >
                                      <span className="text-capitalize">
                                        Not Suitable For Work
                                      </span>
                                    </UncontrolledTooltip>
                                  </>
                                )}
                                <span className="mb-3">
                                  <Button
                                    color="dark"
                                    size="md"
                                    onClick={() => setInviteModalOpen(true)}
                                    className="btn-icon"
                                  >
                                    <FontAwesomeIcon
                                      icon="fa-arrow-up-from-bracket"
                                      className="mr-2"
                                    />
                                    <span className="text-capitalized">
                                      Invite friends
                                    </span>
                                  </Button>
                                </span>
                              </div>
                              <hr />
                              <div className="my-4">
                                <h5 className="mb-1">
                                  <strong>How to join?</strong>
                                </h5>
                                <Alert
                                  color="secondary"
                                  className="text-default"
                                >
                                  <FontAwesomeIcon
                                    icon="fa-circle-info"
                                    className="mr-2"
                                  />
                                  {isPrivate ? (
                                    <span>
                                      This is a <strong>private event</strong>.
                                      You need Invite Code to join.
                                    </span>
                                  ) : (
                                    <span>
                                      This is a <strong>public event</strong>.
                                      You can register directly.
                                    </span>
                                  )}
                                </Alert>

                                <Row className="my-2">
                                  <Col className="m-2">
                                    <h5>
                                      🗓 Date &amp; Time{" "}
                                      <small>
                                        <FontAwesomeIcon
                                          icon="fa-circle-info"
                                          className="text-muted ml-1 fa-sm"
                                          id="timezoneTooltip"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </small>
                                      <UncontrolledTooltip
                                        placement="top"
                                        target="timezoneTooltip"
                                        trigger="hover"
                                      >
                                        Your local timezone -{" "}
                                        {moment.tz.guess()} (
                                        {moment
                                          .tz(moment.tz.guess())
                                          .format("z")}{" "}
                                        )
                                      </UncontrolledTooltip>
                                    </h5>

                                    <div className="ml-3 mr-3">
                                      <FontAwesomeIcon
                                        icon="fa-calendar-days"
                                        className="mr-2"
                                      />
                                      <strong className="text-info">
                                        {eventStartDateTime
                                          ? eventEndDateTime
                                            ? eventStartDateTime +
                                              " - " +
                                              eventEndDateTime
                                            : eventStartDateTime + " - " + "TBA"
                                          : "TBA"}
                                      </strong>

                                      {eventEnded && (
                                        <span className="text-warning ml-2">
                                          ENDED
                                        </span>
                                      )}
                                    </div>

                                    {timezoneLabel && (
                                      <div
                                        className="ml-3 mr-3 text-muted"
                                        style={{ textTransform: "none" }}
                                      >
                                        <small>
                                          <strong>Timezone</strong>{" "}
                                          <span className="text-info">
                                            {timezoneLabel}
                                          </span>
                                        </small>
                                      </div>
                                    )}
                                    <div className="mx-3">
                                      <MultiSessionsCollapse
                                        sessions={sessions}
                                        timezone={timezone}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="my-2">
                                  <Col className="m-2">
                                    <h5>🌎 Location</h5>
                                    {eventVenue && (
                                      <div>
                                        <span className="ml-3 mr-3">
                                          <FontAwesomeIcon
                                            icon="fa-building"
                                            className="mr-2"
                                          />
                                          <strong>{eventVenue}</strong>
                                        </span>
                                      </div>
                                    )}
                                    <div className="mb-3">
                                      <span className="ml-3 mr-3">
                                        {isVirtual ? (
                                          <i className="ni ni-planet mr-2 align-middle" />
                                        ) : (
                                          <FontAwesomeIcon
                                            icon="fa-location-dot"
                                            className="mr-2"
                                          />
                                        )}
                                        <strong className="text-info">
                                          {!isLocationRestricted ||
                                          activeParticipant ? (
                                            eventLocation?.name ? (
                                              isVirtual ? (
                                                <a
                                                  href={
                                                    eventLocation?.name.includes(
                                                      "https://"
                                                    ) ||
                                                    eventLocation?.name.includes(
                                                      "http://"
                                                    )
                                                      ? eventLocation?.name
                                                      : `https://${eventLocation?.name}`
                                                  }
                                                  target="_blank"
                                                  rel="external nofollow noopener"
                                                >
                                                  <span className="text-info">
                                                    Virtual
                                                  </span>
                                                </a>
                                              ) : (
                                                <>
                                                  <span>
                                                    {eventLocation?.name}
                                                  </span>
                                                </>
                                              )
                                            ) : (
                                              `${
                                                isVirtual ? "Virtual" : "IRL"
                                              } (TBA)`
                                            )
                                          ) : (
                                            <>
                                              {isVirtual ? "Virtual" : "IRL"}{" "}
                                              <span className="text-muted">
                                                (Only Visible to Members)
                                              </span>
                                            </>
                                          )}
                                        </strong>
                                      </span>
                                    </div>
                                  </Col>
                                </Row>

                                {eventLocation?.name && !isVirtual && (
                                  <Row>
                                    <Col>
                                      <Map
                                        height={450}
                                        defaultCenter={[
                                          eventLocation?.lat,
                                          eventLocation?.lng,
                                        ]}
                                        defaultZoom={13}
                                        metaWheelZoom={true}
                                        metaWheelZoomWarning="Use META + scroll to zoom"
                                      >
                                        <Marker
                                          width={50}
                                          anchor={[
                                            eventLocation?.lat,
                                            eventLocation?.lng,
                                          ]}
                                          color="red"
                                        />
                                        <ZoomControl />
                                      </Map>
                                    </Col>
                                  </Row>
                                )}
                              </div>
                            </CardBody>
                          </Collapse>
                        </Card>
                      </div>
                    </Col>
                    <Col xl="3" lg="4" md="4" sm="12" xs="12" className="my-2">
                      <div className="accordion accordion-events mb-2">
                        <Card className="shadow">
                          <CardHeader className="bg-white">
                            <a
                              aria-expanded={passDetailCollapseOpen}
                              onClick={() =>
                                setPassDetailCollapseOpen(
                                  !passDetailCollapseOpen
                                )
                              }
                              className="w-100 text-primary text-left"
                              style={{ cursor: "pointer" }}
                            >
                              <span className="h5 mb-0">
                                <strong>Passes</strong>
                              </span>
                              <FontAwesomeIcon
                                icon={
                                  passDetailCollapseOpen
                                    ? "fa-chevron-up"
                                    : "fa-chevron-down"
                                }
                                className="float-right pt-1"
                              />
                            </a>
                          </CardHeader>
                          <Collapse isOpen={passDetailCollapseOpen}>
                            <CardBody>
                              <Toast className="bg-primary">
                                <ToastHeader className="text-white bg-primary">
                                  <div>
                                    <FontAwesomeIcon
                                      icon="fa-ticket"
                                      className="mr-2"
                                    />
                                    <strong className="text-uppercase">
                                      {passTitle}
                                    </strong>
                                  </div>
                                </ToastHeader>
                                <ToastBody className="text-white bg-primary">
                                  {isFree ? (
                                    <h1 className="display-1 text-center text-pass">
                                      FREE
                                    </h1>
                                  ) : passContract ? (
                                    <>
                                      <img
                                        alt="..."
                                        style={{ width: "100%" }}
                                        className="mt-2 mb-2 rounded"
                                        src={passImage || defaultBoxGrayImg}
                                      ></img>
                                      <div className="text-pass">
                                        <strong>
                                          {truncateText(passName, 20)}
                                        </strong>
                                      </div>
                                      <div>
                                        <a
                                          href={tokenUrl}
                                          rel="external nofollow noopener"
                                          target="_blank"
                                        >
                                          {blockchain === "eth" && (
                                            <img
                                              src={ethWhiteImg}
                                              className="icon-eth mr-2"
                                            />
                                          )}
                                          {blockchain === "matic" && (
                                            <img
                                              src={polygonWhiteImg}
                                              className="icon-polygon mr-2"
                                            />
                                          )}
                                          <span className="text-white">
                                            <small>
                                              {passType && <>{passType}: </>}
                                            </small>
                                            <span className="text-info">
                                              {truncateAddressShort(
                                                passContract
                                              )}
                                            </span>
                                            <FontAwesomeIcon
                                              icon="arrow-up-right-from-square"
                                              className="ml-1 text-white"
                                            />
                                          </span>
                                        </a>

                                        {passContract && (
                                          <div>
                                            <small className="text-warning text-capitalize">
                                              * Must hold during the event
                                            </small>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <h1 className="display-1 text-center text-pass">
                                      TBA
                                    </h1>
                                  )}

                                  {passError && (
                                    <div>
                                      <span className="text-danger">
                                        <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                        {passError}
                                      </span>
                                    </div>
                                  )}
                                </ToastBody>
                              </Toast>
                            </CardBody>
                          </Collapse>
                        </Card>

                        {isSecretEnabled &&
                          registered &&
                          registeredAt &&
                          activeParticipant && (
                            <Card className="shadow">
                              <CardHeader className="bg-white">
                                <a
                                  aria-expanded={secretCollapseOpen}
                                  onClick={() =>
                                    setSecretCollapseOpen(!secretCollapseOpen)
                                  }
                                  className="w-100 text-primary text-left"
                                  style={{ cursor: "pointer" }}
                                >
                                  <span className="h5 mb-0">
                                    <strong>Secret Code</strong>
                                  </span>
                                  <FontAwesomeIcon
                                    icon={
                                      secretCollapseOpen
                                        ? "fa-chevron-up"
                                        : "fa-chevron-down"
                                    }
                                    className="float-right pt-1"
                                  />
                                </a>
                              </CardHeader>
                              <Collapse isOpen={secretCollapseOpen}>
                                <CardBody>
                                  <Alert
                                    color="secondary"
                                    className="text-default"
                                  >
                                    <FontAwesomeIcon
                                      icon="fa-circle-info"
                                      className="mr-2"
                                    />
                                    <span>
                                      You have unlocked the secret code.
                                    </span>
                                    <div>
                                      <ButtonGroup className={`my-2 btn-block`}>
                                        <Button
                                          id="secretButton"
                                          size="md"
                                          color="success"
                                          type="button"
                                          className="px-4"
                                          style={{
                                            textTransform: "none",
                                            maxWidth: "120px",
                                          }}
                                          outline
                                          onClick={() => {
                                            if (secretName) {
                                              navigator.clipboard.writeText(
                                                secretName
                                              );
                                            }
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon="fa-shield"
                                            className="mr-1"
                                          />
                                          {secretName || "Passcode"}:
                                        </Button>

                                        {secretCode && (
                                          <>
                                            <Button
                                              id="secretButton"
                                              size="md"
                                              color="success"
                                              outline
                                              type="button"
                                              onClick={() =>
                                                navigator.clipboard.writeText(
                                                  secretCode
                                                )
                                              }
                                            >
                                              {secretCode}
                                            </Button>
                                            <UncontrolledTooltip
                                              deplay={30}
                                              placement="top"
                                              target="secretButton"
                                              trigger="focus"
                                            >
                                              Secret Copied
                                            </UncontrolledTooltip>
                                          </>
                                        )}
                                      </ButtonGroup>
                                    </div>
                                  </Alert>
                                </CardBody>
                              </Collapse>
                            </Card>
                          )}

                        <Card className="shadow">
                          <CardHeader className="bg-white">
                            <a
                              aria-expanded={hostsCollapseOpen}
                              onClick={() =>
                                setHostsCollapseOpen(!hostsCollapseOpen)
                              }
                              className="w-100 text-primary text-left"
                              style={{ cursor: "pointer" }}
                            >
                              <span className="h5 mb-0">
                                <strong>Hosts</strong>
                              </span>
                              <FontAwesomeIcon
                                icon={
                                  hostsCollapseOpen
                                    ? "fa-chevron-up"
                                    : "fa-chevron-down"
                                }
                                className="float-right pt-1"
                              />
                            </a>
                          </CardHeader>

                          <Collapse isOpen={hostsCollapseOpen}>
                            <CardBody>
                              {eventTeam?.length > 0 && (
                                <SimpleCreatorsList creators={eventTeam} />
                              )}
                              {supportEmail && (
                                <Button
                                  size="md"
                                  block
                                  outline
                                  color="info"
                                  href={`mailto:${supportEmail}`}
                                  target="_blank"
                                >
                                  <FontAwesomeIcon
                                    icon="fa-message"
                                    className="mr-2"
                                  />
                                  Contact Team
                                </Button>
                              )}
                            </CardBody>
                          </Collapse>
                        </Card>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>

            <Modal
              toggle={() => setInviteModalOpen(!inviteModalOpen)}
              isOpen={inviteModalOpen}
            >
              <BarLoader
                loading={modalLoading}
                cssOverride={{
                  marginLeft: "1.5%",
                  marginRight: "1.5%",
                  width: "97%",
                  backgroundColor: "#fff",
                  opacity: "1",
                }}
              />
              <div className="modal-header">
                <span className="modal-title" id="inviteModalLabel">
                  <strong>Invite Your Friends</strong>{" "}
                </span>

                <button
                  aria-label="Close"
                  className=" close"
                  type="button"
                  onClick={() => setInviteModalOpen(false)}
                  disabled={modalLoading}
                >
                  <span aria-hidden={true}>
                    <FontAwesomeIcon icon="fa-xmark" />
                  </span>
                </button>
              </div>

              <ModalBody>
                <div className="mb-4">
                  {isEventCreator ? (
                    <Alert color="secondary" className="text-default">
                      <FontAwesomeIcon
                        icon="fa-circle-info"
                        className="mr-2 text-muted"
                      />
                      User Invite is not available for <strong>creator</strong>.
                      Head to the{" "}
                      <Button
                        size="sm"
                        color="link"
                        className="text-info p-0"
                        href={`/dashboard/event/${eventId}?view=members`}
                      >
                        Dashboard
                      </Button>{" "}
                      and create an Creator Invite.
                    </Alert>
                  ) : currentUser && currentUserInfo ? (
                    !maxUserInvites ? (
                      <Alert color="secondary" className="text-default">
                        <FontAwesomeIcon
                          icon="fa-circle-info"
                          className="mr-2 text-muted"
                        />
                        User invite code is disabled. You can still share this
                        event.
                      </Alert>
                    ) : myInviteStatus !== "active" ? (
                      <Alert color="secondary" className="text-default">
                        <FontAwesomeIcon
                          icon="fa-circle-info"
                          className="mr-2 text-muted"
                        />
                        Your invite code is not active. You can still share this
                        event.
                      </Alert>
                    ) : (
                      isPrivate && (
                        <Alert color="secondary" className="text-default">
                          <FontAwesomeIcon
                            icon="fa-circle-info"
                            className="mr-2 text-muted"
                          />
                          This is a <strong>private event</strong>. Only active
                          members can create invite.
                        </Alert>
                      )
                    )
                  ) : (
                    <Alert color="secondary" className="text-default">
                      <FontAwesomeIcon
                        icon="fa-circle-info"
                        className="mr-2 text-muted"
                      />
                      You must{" "}
                      {!currentUser
                        ? "sign in"
                        : !currentUserInfo && "complete profile"}{" "}
                      first before creating an invite.
                    </Alert>
                  )}
                  <div className="my-2">
                    <FontAwesomeIcon
                      icon="fa-circle-check"
                      className="text-success fa-lg"
                    />
                    <span className="ml-2">
                      <strong>
                        Share{" "}
                        {maxUserInvites && myInviteStatus === "active"
                          ? "Invite Link"
                          : "This Event"}
                      </strong>
                    </span>
                  </div>
                  <div className="my-2 col-12">
                    <UrlButton url={myInviteLink} size="md" style="block" />
                  </div>
                  <div className="my-2 col-12">
                    <InlineShareButtons
                      config={{
                        alignment: "left",
                        color: "gray",
                        enabled: true,
                        font_size: 16,
                        labels: "null",
                        language: "en",
                        networks: SocialShareOptions,
                        padding: 7,
                        radius: 5,
                        show_total: false,
                        size: 35,
                        description: SocialShareMessage, // (defaults to og:description or twitter:description)
                        title: SocialShareTitle, // (defaults to og:title or twitter:title)
                        message: SocialShareMessage, // (only for email sharing)
                        subject: SocialShareSubject, // (only for email sharing)
                        username: SocialShareUsername,
                        url: myInviteLink,
                      }}
                    />
                  </div>

                  <div className="mt-4">
                    <FontAwesomeIcon
                      icon="fa-ranking-star"
                      className="fa-lg"
                      style={{ color: "gold" }}
                    />
                    <span className="ml-2">
                      <strong>Invite Achievement</strong>
                    </span>
                  </div>

                  <div className="my-2 col-12">
                    <Alert color="primary">
                      <span style={{ color: "gold" }}>
                        {maxUserInvites ? (
                          <>
                            {myTotalInvites > 0 && "Congrats, "}
                            You have{" "}
                            <strong>
                              {pluralize("friend", myTotalInvites || 0, true)}
                            </strong>{" "}
                            joined!
                          </>
                        ) : (
                          <>Invite ranking is not available</>
                        )}
                      </span>
                    </Alert>
                  </div>
                </div>
              </ModalBody>
            </Modal>

            {registrationSuccess ? (
              <ModalSuccess
                success={registrationSuccess}
                message="You've successfully joined the event."
              />
            ) : (
              currentUserInfo &&
              !isCancelled &&
              isPublished &&
              isRegEnabled &&
              !eventEnded &&
              !spotsFull && (
                <Modal isOpen={registerModalOpen}>
                  <BarLoader
                    loading={modalLoading}
                    cssOverride={{
                      marginLeft: "1.5%",
                      marginRight: "1.5%",
                      width: "97%",
                      backgroundColor: "#fff",
                      opacity: "1",
                    }}
                  />
                  <div className="modal-header">
                    <span className=" modal-title" id="registerModalLabel">
                      <strong>Event Registration</strong>{" "}
                    </span>

                    <button
                      aria-label="Close"
                      className=" close"
                      type="button"
                      onClick={() => {
                        setRegisterModalOpen(false);
                      }}
                      disabled={modalLoading}
                    >
                      <span aria-hidden={true}>
                        <FontAwesomeIcon icon="fa-xmark" />
                      </span>
                    </button>
                  </div>

                  <ModalBody>
                    <div className="mb-4">
                      <div>
                        <h5 className="mb-2">Check Eligibility</h5>
                        {isFree ? (
                          <div className="mt-2">
                            <FontAwesomeIcon
                              icon="fa-circle-check"
                              className="text-success fa-lg"
                            />
                            <span className="ml-2">
                              This event requires no pass to join.
                            </span>
                          </div>
                        ) : !isFree && !passContract ? (
                          <div className="mt-2">
                            <FontAwesomeIcon
                              icon="fa-circle-xmark"
                              className="text-danger fa-lg"
                            />
                            <span className="ml-2">
                              The event pass detail is not announced yet.
                            </span>
                          </div>
                        ) : (
                          passContract && (
                            <>
                              <div className="mt-2">
                                <FontAwesomeIcon
                                  icon="fa-circle-check"
                                  className="text-success fa-lg"
                                />
                                <span className="ml-2">
                                  This event requires the following pass.
                                </span>
                              </div>
                              <div className="mt-3 mb-4">
                                <Button
                                  className="btn-sm"
                                  type="button"
                                  href={tokenUrl}
                                  rel="external nofollow noopener"
                                  target="_blank"
                                >
                                  {blockchain === "eth" && (
                                    <img
                                      src={ethImg}
                                      className="icon-eth mr-2"
                                    />
                                  )}
                                  {blockchain === "matic" && (
                                    <img
                                      src={polygonImg}
                                      className="icon-polygon mr-2"
                                    />
                                  )}
                                  <>
                                    {truncateText(passName, 20)} {"( "}
                                    <small>{passType} </small>
                                    <span className="text-info">
                                      {truncateAddressShort(passContract)}
                                    </span>{" "}
                                    {")"}
                                    <FontAwesomeIcon
                                      icon="arrow-up-right-from-square"
                                      className="ml-1"
                                    />
                                  </>
                                </Button>
                              </div>

                              <div className="my-3">
                                {userTotalPassHolding > 0 ? (
                                  <FontAwesomeIcon
                                    icon="fa-circle-check"
                                    className="text-success fa-lg"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon="fa-circle-xmark"
                                    className="text-danger fa-lg"
                                  />
                                )}
                                <span className="ml-2">
                                  You are holding{" "}
                                  {pluralize(
                                    "pass",
                                    userTotalPassHolding || 0,
                                    true
                                  )}
                                  .
                                </span>
                              </div>
                              {userTotalPassHolding > 0 &&
                                (requireUniquePassId ? (
                                  <div className="my-3">
                                    {userTokenOption?.value ? (
                                      <FontAwesomeIcon
                                        icon="fa-circle-check"
                                        className="text-success fa-lg"
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        icon="fa-circle-check"
                                        className="fa-lg"
                                      />
                                    )}
                                    <span className="ml-2 required">
                                      Choose your pass to register
                                    </span>
                                    <div className="">
                                      <small className="text-muted">
                                        Require unique wallet and Token ID to
                                        join.
                                      </small>
                                    </div>
                                    <div className="my-2 col-11">
                                      <Select
                                        className="realpass-selector"
                                        onChange={(e) => {
                                          setUserTokenOption(e);
                                          if (e) {
                                            setEligibleToJoin(true);
                                          } else {
                                            setEligibleToJoin(false);
                                          }
                                        }}
                                        isClearable={true}
                                        isSearchable={true}
                                        options={userTokenOptions}
                                        value={userTokenOption}
                                        components={{
                                          animatedComponents,
                                          IndicatorSeparator: () => null,
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <FontAwesomeIcon
                                      icon="fa-circle-check"
                                      className="text-success fa-lg"
                                    />
                                    <span className="ml-2">
                                      Each wallet and pass may have one entry.
                                    </span>
                                  </>
                                ))}
                            </>
                          )
                        )}
                      </div>
                    </div>

                    <div className="my-3">
                      {inviteCodeChecking ? (
                        <FontAwesomeIcon
                          icon="fa-circle-check"
                          className="text-muted fa-lg"
                        />
                      ) : !inviteError ? (
                        <FontAwesomeIcon
                          icon="fa-circle-check"
                          className="text-success fa-lg"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon="fa-circle-xmark"
                          className="text-danger fa-lg"
                        />
                      )}
                      <span>
                        {isPrivate ? (
                          <span className="ml-2 required">
                            You need invite code for private event.
                          </span>
                        ) : (
                          <span className="ml-2">
                            You may optionally use
                            <Button
                              size="sm"
                              color="link"
                              onClick={() =>
                                setInviteInputOpen(!inviteInputOpen)
                              }
                            >
                              <span className="text-info">Invite Code</span>
                            </Button>
                          </span>
                        )}
                      </span>
                      {(isPrivate || inviteInputOpen || inviteError) && (
                        <div className="ml-3 my-2 col-11">
                          <InputGroup>
                            <InputGroupText className="input-group-left pr-2 text-muted">
                              <FontAwesomeIcon
                                icon="fa-handshake-angle"
                                className="mr-2"
                              />
                            </InputGroupText>

                            <Input
                              id="inviteCode"
                              className="input-group-mid-no-border pl-2"
                              value={inviteCode}
                              onChange={(e) => {
                                setInviteCode(e.target.value);
                              }}
                              onBlur={() => checkInviteCode(isPrivate)}
                              placeholder="Enter 6-digit invite code"
                              type="text"
                            />

                            <InputGroupText
                              className="input-group-right pr-2 text-muted"
                              style={{
                                borderLeft: "0px",
                              }}
                            >
                              {inviteCodeChecking ? (
                                <ScaleLoader
                                  color="#eee"
                                  loading={inviteCodeChecking}
                                  height="10px"
                                  cssOverride={{
                                    display: "inline",
                                  }}
                                />
                              ) : (
                                isInviteCodeValid && (
                                  <FontAwesomeIcon
                                    icon="fa-check"
                                    className="text-success"
                                  />
                                )
                              )}
                            </InputGroupText>
                          </InputGroup>

                          {inviteError && (
                            <small className="text-danger">
                              <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                              {inviteError}
                            </small>
                          )}
                        </div>
                      )}
                    </div>

                    {isNSFW && (
                      <div className="mx-4 my-3">
                        <div>
                          <Label>
                            <Input
                              checked={confirmAge}
                              className="custom-control-input"
                              type="checkbox"
                              onChange={() => {
                                setConfirmAge(!confirmAge);
                              }}
                            />
                            <span className="custom-control-label">
                              <strong>Confirm Legal Age</strong>
                            </span>
                          </Label>
                        </div>
                        <small className="text-muted mt-2">
                          This event is NSFW. You need to confirm you're 18+ to
                          continue.
                        </small>
                      </div>
                    )}
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      size="md"
                      color="primary"
                      type="button"
                      disabled={
                        (isNSFW && !confirmAge) ||
                        !!inviteError ||
                        !eligibleToJoin ||
                        modalLoading
                      }
                      onClick={register}
                    >
                      Register
                      {modalLoading && (
                        <span className="ml-2">
                          <ScaleLoader
                            color="#eee"
                            loading={modalLoading}
                            height="10px"
                            cssOverride={{
                              display: "inline",
                            }}
                          />
                        </span>
                      )}
                    </Button>
                    <Button
                      size="md"
                      color="link"
                      type="button"
                      disabled={modalLoading}
                      onClick={() => {
                        setRegisterModalOpen(!registerModalOpen);
                        setUserTokenOption();
                        setConfirmAge(false);
                        setAcceptTerms(false);
                        setEligibleToJoin(false);
                      }}
                    >
                      Close
                    </Button>
                  </ModalFooter>
                </Modal>
              )
            )}

            {currentUserInfo && (
              <Modal
                toggle={() => setUnlikeModalOpen(!unlikeModalOpen)}
                isOpen={unlikeModalOpen}
              >
                <div className="modal-header">
                  <span className="modal-title" id="unlikeModalLabel">
                    <strong>Unlike the event?</strong>
                  </span>

                  <button
                    aria-label="Close"
                    className=" close"
                    type="button"
                    onClick={() => setUnlikeModalOpen(false)}
                  >
                    <span aria-hidden={true}>
                      <FontAwesomeIcon icon="fa-xmark" />
                    </span>
                  </button>
                </div>
                <ModalFooter>
                  <Button
                    size="md"
                    color="primary"
                    type="button"
                    onClick={() => {
                      toggleLike(false);
                      setUnlikeModalOpen(!unlikeModalOpen);
                    }}
                  >
                    Unlike
                  </Button>
                  <Button
                    size="md"
                    color="link"
                    type="button"
                    disabled={modalLoading}
                    onClick={() => {
                      setUnlikeModalOpen(!unlikeModalOpen);
                    }}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            )}

            {currentUserInfo && (
              <Modal
                toggle={() => setIssueModalOpen(!issueModalOpen)}
                isOpen={issueModalOpen}
              >
                <div className="modal-header">
                  <span className="modal-title" id="issueModalLabel">
                    <strong>Report an issue?</strong>
                  </span>

                  <button
                    aria-label="Close"
                    className=" close"
                    type="button"
                    onClick={() => setIssueModalOpen(false)}
                  >
                    <span aria-hidden={true}>
                      <FontAwesomeIcon icon="fa-xmark" />
                    </span>
                  </button>
                </div>
                <ModalBody>
                  <span className="text-default">
                    You can submit an issue if you think this content may be
                    scam, explicit, sensitive, violent, spam, copyright
                    infringed or other resons inappropriate for RealPass.
                  </span>
                </ModalBody>
                <ModalFooter>
                  <Button
                    size="md"
                    color="primary"
                    href="https://forms.gle/kYF5Sijquhjifwiq5"
                    rel="external nofollow noopener"
                    target="_blank"
                    onClick={() => {
                      setIssueModalOpen(!issueModalOpen);
                    }}
                  >
                    Continue
                  </Button>
                  <Button
                    size="md"
                    color="link"
                    type="button"
                    disabled={modalLoading}
                    onClick={() => {
                      setIssueModalOpen(!issueModalOpen);
                    }}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            )}
          </Container>
        </section>
      </div>
      {isCancelled && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            color: "white",
            zIndex: "999",
          }}
        ></div>
      )}
      {!isCancelled && (isPendingCohost || isPendingPanelist) && (
        <Alert
          color="gray"
          className="text-white shadow"
          style={{
            position: "fixed",
            bottom: "1%",
            left: "15%",
            width: "70%",
            backgroundImage: "linear-gradient(90deg, #087EE1 0%, #05D6B4 100%",
            opacity: "0.99",
            zIndex: "9999",
          }}
        >
          <Row>
            <Col xl="9" lg="g" mg="9" sm="12" xs="12">
              <p>
                Bravo, you have been invited as a{" "}
                {isPendingCohost ? "cohost" : isPendingPanelist && "panelist"}.
                Start building by accepting the inivitation.
              </p>
            </Col>
            <Col xl="3" lg="3" mg="3" sm="12" xs="12">
              <span className="float-right">
                <Button
                  size="md"
                  color="info"
                  className="my-2"
                  onClick={() => performTeamAction("accept")}
                >
                  Accept
                </Button>
                <Button
                  size="md"
                  color="link"
                  className="text-white my-2"
                  onClick={() => performTeamAction("leave")}
                >
                  <u>Decline</u>
                </Button>
              </span>
            </Col>
          </Row>
        </Alert>
      )}
    </>
  );
}
