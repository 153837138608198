import React, { useState, useEffect, useContext } from "react";
import {
  Link,
  useParams,
  useNavigate,
  Navigate,
  useSearchParams,
} from "react-router-dom";

import { AuthContext } from "Auth.js";

// reactstrap components
import {
  ButtonGroup,
  Button,
  Card,
  CardImg,
  CardHeader,
  CardBody,
  Container,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Badge,
  InputGroup,
  InputGroupText,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  ModalFooter,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
} from "reactstrap";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

// core components
import TopNavbar from "components/Navbars/TopNavbar.js";

import Loader from "components/Loaders/Loader.js";
import LoaderDots from "components/Loaders/LoaderDots.js";

import ScaleLoader from "react-spinners/ScaleLoader";
import BarLoader from "react-spinners/BarLoader";

import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.compat.css";

import Web3 from "web3";

import { truncateAddressShort, notify } from "utils/Utils.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import { ReactSearchAutocomplete } from "react-search-autocomplete";

import Autosuggest from "react-autosuggest";

import InfiniteScroll from "react-infinite-scroll-component";

import { set } from "lodash";

const {
  defaultProfileImg,
  bgGradient,
  defaultBoxGrayImg,
  verifiedImg,
  ethImg,
  nsfwImg,
  mmImg,
  wcImg,
  pwImg,
  ethWhiteImg,
  ethLogoColor,
  polygonImg,
  polygonWhiteImg,
  polygonLogoColor,
  bscLogoColor,
  ftmLogoColor,
  avaxLogoColor,
  cronosLogoColor,
  solLogoColor,
  catTokenImg,
  catArtImg,
  catMusicImg,
  catGameImg,
  catPartyImg,
  catSportsImg,
  catDaoImg,
  catMeetingImg,
  catStudyImg,
  catLeisureImg,
  catFundingImg,
  catMiscImg,
  ChainOptions,
  EventCategoryOptions,
  USDateOption,
  UserRoleTypes,
  UserRoleStatus,
  VerifiedUserTypes,
  VerifiedUserStatus,
} = require("utils/Variables.js");

const { REACT_APP_FBASE_API_KEY } = process.env;

export default function Explore() {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const view = searchParams.get("view");
  const tabIndex = {
    events: 0,
    creators: 1,
  };

  const eventsPageSize = 8;
  const creatorsPageSize = 40;

  // const NFPass = Moralis.Object.extend("NFPass");
  // const passQuery = new Moralis.Query(NFPass);

  // const NFProfile = Moralis.Object.extend("NFProfile");
  // const profileQuery = new Moralis.Query(NFProfile);
  // const eventCreatorQuery = new Moralis.Query(NFProfile);

  // const NFVerified = Moralis.Object.extend("NFVerified");
  // const verifiedQuery = new Moralis.Query(NFVerified);

  // const NFEvent = Moralis.Object.extend("NFEvent");

  // const NFRaffle = Moralis.Object.extend("NFRaffle");
  // const raffleQuery = new Moralis.Query(NFRaffle);

  // const NFFollow = Moralis.Object.extend("NFFollow");
  // const myfollowQuery = new Moralis.Query(NFFollow);
  // const followersQuery = new Moralis.Query(NFFollow);

  const [pageLoading, setPageLoading] = useState(false);
  const [itemsLoading, setItemsLoading] = useState(false);

  const [events, setEvents] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [moreEvents, setMoreEvents] = useState(true);
  const [eventsPageIndex, setEventsPageIndex] = useState(0);

  const [verifiedCreators, setVerifiedCreators] = useState([]);
  const [selectedCreators, setSelectedCreators] = useState([]);
  const [moreCreators, setMoreCreators] = useState(true);
  const [creatorsPageIndex, setCreatorsPageIndex] = useState(0);

  const [filterCollapseOpen, setFilterCollapseOpen] = useState(false);

  const [statusOngoingChecked, setStatusOngoingChecked] = useState(true);
  const [statusFinishedChecked, setStatusFinishedChecked] = useState(false);
  const [statusIncludePrivateChecked, setStatusIncludePrivateChecked] =
    useState(false);

  const [chainEthChecked, setChainEthChecked] = useState(true);
  const [chainPolygonChecked, setChainPolygonChecked] = useState(true);

  const [categoryTokenChecked, setCategoryTokenChecked] = useState(true);
  const [categoryArtChecked, setCategoryArtChecked] = useState(true);
  const [categoryMusicChecked, setCategoryMusicChecked] = useState(true);
  const [categoryGamingChecked, setCategoryGamingChecked] = useState(true);
  const [categoryPartyChecked, setCategoryPartyChecked] = useState(true);
  const [categorySportsChecked, setCategorySportsChecked] = useState(true);
  const [categoryDAOChecked, setCategoryDAOChecked] = useState(true);
  const [categoryMeetingChecked, setCategoryMeetingChecked] = useState(true);
  const [categoryStudyChecked, setCategoryStudyChecked] = useState(true);
  const [categoryLeisureChecked, setCategoryLeisureChecked] = useState(true);
  const [categoryFundraisingChecked, setCategoryFundraisingChecked] =
    useState(true);
  const [categoryMiscChecked, setCategoryMiscChecked] = useState(true);

  const [passFreeChecked, setPassFreeChecked] = useState(true);
  const [passHoldersChecked, setPassHoldersChecked] = useState(true);

  const [refreshEvents, setRefreshEvents] = useState(false);

  const [eventSearchLoading, setEventSearchLoading] = useState("");
  const [eventSearchValue, setEventSearchValue] = useState("");
  const [eventSearchSuggestions, setEventSearchSuggestions] = useState([]);
  const [eventSearchResults, setEventSearchResults] = useState([
    {
      name: "Metaverse Fest",
      creator: "BigL",
    },
    {
      name: "Bored Ape Party",
      creator: "Will",
    },
  ]);

  const [creatorSearchLoading, setCreatorSearchLoading] = useState("");
  const [creatorSearchValue, setCreatorSearchValue] = useState("");
  const [creatorSearchSuggestions, setCreatorSearchSuggestions] = useState([]);
  const [creatorSearchResults, setCreatorSearchResults] = useState([
    {
      name: "BigL",
    },
    {
      name: "Will",
    },
  ]);

  const fetchEvents = async () => {
    try {
      setItemsLoading(true);

      const toSkip = eventsPageIndex * eventsPageSize;

      const eventsQuery = eventsQueryBuilder();

      // eventsQuery.matchesKeyInQuery("creator", "profile", verifiedQuery);

      eventsQuery.descending("createdAt");

      eventsQuery.skip(toSkip);
      eventsQuery.limit(eventsPageSize);

      const res = await eventsQuery.find();

      const newList = events;

      for (const i of res) {
        // eventCreatorQuery.equalTo("objectId", i?.get("creator")?.id);
        // const creatorRes = await eventCreatorQuery.first();

        const start = i
          ?.get("startDateTime")
          ?.toLocaleDateString("en-US", USDateOption);
        const ended = i?.get("endDateTime")?.getTime() <= new Date().getTime();

        newList.push({
          id: i?.id,
          name: i?.get("name"),
          image: i?.get("eventImage")?.url(),
          // creator: creatorRes.get("name"),
          verified: true,
          category: EventCategoryOptions[i?.get("category") || "misc"],
          blockchain: ChainOptions[i?.get("blockchain")],
          pass: i?.get("passContract"),
          start: start,
          ended: ended,
          virtual: i?.get("isVirtual"),
          location: i?.get("location")?.name,
          public: i?.get("isPublic"),
          nsfw: i?.get("isNSFW"),
        });
      }

      setEvents([...newList]);
      setSelectedEvents([...newList]);

      if (res && res.length > 0) {
        setMoreEvents(true);
      } else {
        setMoreEvents(false);
      }
    } catch (err) {
      console.error(err.message);
      notify("Unable to fetch events.", "error");
    } finally {
      setPageLoading(false);
      setItemsLoading(false);
    }
  };

  const resetFilterEvents = () => {
    setStatusOngoingChecked(true);
    setStatusFinishedChecked(false);
    setStatusIncludePrivateChecked(false);

    setChainEthChecked(true);
    setChainPolygonChecked(true);

    setCategoryTokenChecked(true);
    setCategoryArtChecked(true);
    setCategoryMusicChecked(true);
    setCategoryGamingChecked(true);
    setCategoryPartyChecked(true);
    setCategorySportsChecked(true);
    setCategoryDAOChecked(true);
    setCategoryMeetingChecked(true);
    setCategoryStudyChecked(true);
    setCategoryLeisureChecked(true);
    setCategoryFundraisingChecked(true);
    setCategoryMiscChecked(true);

    setPassFreeChecked(true);
    setPassHoldersChecked(true);

    applyFilterEvents();
  };

  const applyFilterEvents = () => {
    try {
      setPageLoading(true);

      setEvents([]);
      setSelectedEvents([]);
      setMoreEvents(true);
      setEventsPageIndex(0);
      setRefreshEvents(!refreshEvents);
    } catch (err) {
      console.error(err.message);
      notify("Unable to filter events.", "error");
    } finally {
      setPageLoading(false);
    }
  };

  const eventsQueryBuilder = () => {
    // const tbaEndEvents = new Moralis.Query(NFEvent);
    // tbaEndEvents.doesNotExist("endDateTime");
    // const notEndedEvents = new Moralis.Query(NFEvent);
    // notEndedEvents.greaterThan("endDateTime", new Date());
    // const endedEvents = new Moralis.Query(NFEvent);
    // endedEvents.lessThanOrEqualTo("endDateTime", new Date());
    // let statusQuery;
    // if (statusOngoingChecked && statusFinishedChecked) {
    //   statusQuery = Moralis.Query.or(tbaEndEvents, notEndedEvents, endedEvents);
    // } else if (statusOngoingChecked) {
    //   statusQuery = Moralis.Query.or(tbaEndEvents, notEndedEvents);
    // } else if (statusFinishedChecked) {
    //   statusQuery = endedEvents.lessThanOrEqualTo("endDateTime", new Date());
    // } else {
    //   statusQuery = Moralis.Query.and(
    //     tbaEndEvents,
    //     notEndedEvents,
    //     endedEvents
    //   );
    // }
    // const publicEvents = new Moralis.Query(NFEvent);
    // publicEvents.equalTo("isPublic", true);
    // const privateEvents = new Moralis.Query(NFEvent);
    // privateEvents.equalTo("isPublic", false);
    // const notPublicEvents = new Moralis.Query(NFEvent);
    // notPublicEvents.doesNotExist("isPublic");
    // let privateEventQuery;
    // if (!statusIncludePrivateChecked) {
    //   privateEventQuery = publicEvents;
    // } else {
    //   privateEventQuery = Moralis.Query.or(
    //     publicEvents,
    //     privateEvents,
    //     notPublicEvents
    //   );
    // }
    // const chainQuery = new Moralis.Query(NFEvent);
    // chainQuery.containedIn("blockchain", [
    //   chainEthChecked && "eth",
    //   chainPolygonChecked && "matic",
    // ]);
    // const categoryQuery = new Moralis.Query(NFEvent);
    // categoryQuery.containedIn("category", [
    //   categoryTokenChecked && "token",
    //   categoryArtChecked && "art",
    //   categoryMusicChecked && "music",
    //   categoryGamingChecked && "gaming",
    //   categoryPartyChecked && "party",
    //   categoryLeisureChecked && "leisure",
    //   categorySportsChecked && "sports",
    //   categoryDAOChecked && "dao",
    //   categoryMeetingChecked && "meeting",
    //   categoryStudyChecked && "study",
    //   categoryFundraisingChecked && "fundraising",
    //   categoryMiscChecked && "misc",
    // ]);
    // let passQuery;
    // const freeEvents = new Moralis.Query(NFEvent);
    // freeEvents.doesNotExist("passContract");
    // const passEvents = new Moralis.Query(NFEvent);
    // passEvents.exists("passContract");
    // if (passFreeChecked && passHoldersChecked) {
    //   passQuery = Moralis.Query.or(freeEvents, passEvents);
    // } else if (passFreeChecked) {
    //   passQuery = freeEvents;
    // } else if (passHoldersChecked) {
    //   passQuery = passEvents;
    // } else {
    //   passQuery = Moralis.Query.and(freeEvents, passEvents);
    // }
    // const eventsQuery = Moralis.Query.and(
    //   privateEventQuery,
    //   statusQuery,
    //   chainQuery,
    //   categoryQuery,
    //   passQuery
    // );
    // return eventsQuery;
  };

  const fetchFollowers = async (profile) => {
    // try {
    //   followersQuery.equalTo("profile", profile);
    //   followersQuery.equalTo("followed", true);
    //   const followers = await followersQuery.count();
    //   return followers;
    // } catch (err) {
    //   console.error(err.message);
    //   return 0;
    // }
  };

  const fetchCreators = async () => {
    // try {
    //   setItemsLoading(true);
    //   const toSkip = creatorsPageIndex * creatorsPageSize;
    //   profileQuery.matchesKeyInQuery(
    //     "objectId",
    //     "profile.objectId",
    //     verifiedQuery
    //   );
    //   profileQuery.ascending("name");
    //   profileQuery.skip(toSkip);
    //   profileQuery.limit(creatorsPageSize);
    //   const res = await profileQuery.find();
    //   const newList = verifiedCreators;
    //   for (const i of res) {
    //     const followers = await fetchFollowers(i);
    //     newList.push({
    //       ethAddress: i?.get("ethAddress"),
    //       name: i?.get("name"),
    //       image: i?.get("image")?.url(),
    //       verified: true,
    //       followers: followers,
    //     });
    //   }
    //   setVerifiedCreators([...newList]);
    //   setSelectedCreators([...newList]);
    //   if (res && res.length > 0) {
    //     setMoreCreators(true);
    //   } else {
    //     setMoreCreators(false);
    //   }
    // } catch (err) {
    //   console.error(err.message);
    //   notify("Unable to fetch creators data.", "error");
    // } finally {
    setPageLoading(false);
    //   setItemsLoading(false);
    // }
  };

  const formatEventResult = (item) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>
          {item?.name} - {item?.creator}{" "}
          {item?.verified && (
            <img
              className='realpass-verified-name-suffix-sm ml-1 mb-1'
              src={verifiedImg}
            />
          )}
        </span>
      </>
    );
  };

  const listEvents = () => {
    return selectedEvents.map((item, index) => {
      return (
        <Col
          className='mt-1 mb-1 p-0'
          xl='3'
          lg='3'
          md='4'
          sm='6'
          xs='6'
          key={index}
        >
          <Card className='shadow card-thumbnail m-2 events-cards' lg='4'>
            {item?.nsfw && (
              <>
                <img
                  id={`nsfwBadge-${index}`}
                  src={nsfwImg}
                  className='nsfw-thumbnail-mark'
                  style={{ cursor: "pointer" }}
                />

                <UncontrolledTooltip
                  placement='top'
                  target={`nsfwBadge-${index}`}
                  trigger='hover'
                >
                  <span className='text-capitalize'>Not Suitable For Work</span>
                </UncontrolledTooltip>
              </>
            )}
            <a href={"/event/" + item?.id}>
              <div className='card-img-thumbnail'>
                <CardImg src={item?.image || defaultBoxGrayImg} top />{" "}
              </div>
            </a>
            <CardBody className='text-left events-cards-body'>
              <a href={"/event/" + item?.id}>
                <div className='title-thumbnail'>
                  <strong>{item?.name}</strong>
                </div>
                <div className='mt-1 mb-1'>
                  <small>
                    by <strong>{item?.creator}</strong>
                    {item?.verified && (
                      <img
                        className='realpass-verified-name-suffix-sm ml-1 mb-1'
                        src={verifiedImg}
                      />
                    )}
                  </small>
                </div>
              </a>
              <img
                id={`blockchainBadge-${index}`}
                className='chain-icon-sm mt-1 mb-1'
                src={item?.blockchain?.image}
                style={{ cursor: "pointer" }}
              />
              <UncontrolledTooltip
                placement='top'
                target={`blockchainBadge-${index}`}
                trigger='hover'
              >
                <span className='text-capitalize'>
                  {item?.blockchain?.name}
                </span>
              </UncontrolledTooltip>
              <img
                id={`categoryBadge-${index}`}
                className='category-icon-sm mt-1 mb-1 ml-1'
                src={item?.category?.image}
                style={{ cursor: "pointer" }}
              />
              <UncontrolledTooltip
                placement='top'
                target={`categoryBadge-${index}`}
                trigger='hover'
              >
                <span className='text-capitalize'>{item?.category?.name}</span>
              </UncontrolledTooltip>
              <Badge
                id={`eventBadge-${index}`}
                className='text-white mt-1 mb-1 ml-1'
                pill
                color='primary'
                style={{ cursor: "pointer" }}
              >
                <small>Event</small>
              </Badge>
              {!item?.public && (
                <>
                  <Badge
                    id={`privateBadge-${index}`}
                    color='secondary'
                    pill
                    className='text-default mt-1 mb-1 ml-1'
                    style={{ cursor: "pointer" }}
                  >
                    <small>PVT</small>
                  </Badge>
                  <UncontrolledTooltip
                    placement='top'
                    target={`privateBadge-${index}`}
                    trigger='hover'
                  >
                    <span className='text-capitalize'>Private Event</span>
                  </UncontrolledTooltip>
                </>
              )}
              <Row className='mt-2 px-1'>
                <Col className='mt-1 mb-1 px-2 col-xl-5 col-lg-5'>
                  <small className='text-muted time-thumbnail'>
                    <FontAwesomeIcon icon='fa-clock' className='mr-2' />
                    {item?.ended ? "ENDED" : item?.start || "TBA"}
                  </small>
                </Col>
                <Col className='mt-1 mb-1 px-2 col-xl-7 col-lg-7'>
                  <small className='text-muted address-thumbnail'>
                    {item?.virtual ? (
                      <i className='ni ni-planet mr-2 align-middle' />
                    ) : (
                      <FontAwesomeIcon
                        icon='fa-location-dot'
                        className='mr-2'
                      />
                    )}
                    {item?.location ? (
                      item?.virtual ? (
                        <>Virtual</>
                      ) : (
                        <>{item?.location}</>
                      )
                    ) : (
                      "TBA"
                    )}
                  </small>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      );
    });
  };

  const listVerifiedCreators = () => {
    return selectedCreators.map((item, index) => {
      return (
        <Col
          className='mt-1 mb-1 p-0'
          xl='3'
          lg='3'
          md='4'
          sm='6'
          xs='6'
          key={index}
        >
          <a href={"/" + item?.ethAddress}>
            <Card className='creators-cards shadow m-2' data-background='cover'>
              <CardBody>
                <div className='rounded-circle mt-3'>
                  <CardImg
                    className='profile-image-md rounded-circle'
                    src={item?.image || defaultProfileImg}
                    top
                  />
                </div>
                <div className='title-thumbnail'>
                  <h5 className='h5 mt-3'>
                    <strong>{item?.name}</strong>
                    {item?.verified && (
                      <img
                        className='realpass-verified-name-suffix ml-1 mb-1'
                        src={verifiedImg}
                      />
                    )}
                  </h5>
                </div>
                <div>
                  <small className='text-muted'>
                    <strong className='mr-1'>{item?.followers || 0}</strong>
                    <strong>Followers</strong>
                  </small>
                </div>
              </CardBody>
            </Card>
          </a>
        </Col>
      );
    });
  };

  const renderEventSearchInput = (inputProps) => (
    <InputGroup className='input-search-wrapper input-group-plain input-group-merge'>
      <InputGroupText className='input-search-left'>
        <FontAwesomeIcon icon='fa-magnifying-glass' />
      </InputGroupText>
      <Input
        {...inputProps}
        className='input-search-mid'
        placeholder='Search events'
      />

      <InputGroupText className='input-search-right'>
        {eventSearchLoading && (
          <ScaleLoader
            color='#eee'
            loading={eventSearchLoading}
            height='10px'
            cssOverride={{
              display: "inline",
            }}
          />
        )}
        {eventSearchValue && !eventSearchLoading && (
          <button
            aria-label='Close'
            className='close'
            type='button'
            onClick={() => {
              setEventSearchValue("");
              setEventSearchSuggestions([]);
            }}
          >
            <span aria-hidden={true}>&times;</span>
          </button>
        )}
      </InputGroupText>
    </InputGroup>
  );

  // Teach Autosuggest how to calculate suggestions for any given input value.
  const getEventSuggestions = (value) => {
    const inputValue = value?.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : eventSearchResults.filter(
          (result) =>
            result?.name?.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion.
  const getEventSuggestionValue = (suggestion) => suggestion?.name;

  // TODO: Search suggestion scollable and max result
  const renderEventSuggestion = (suggestion) => (
    <div className='text-left'>{suggestion?.name}</div>
  );

  const onEventSearchChange = (event, { newValue }) => {
    // setEventSearchLoading(true);
    setEventSearchValue(newValue);
  };

  // Autosuggest will pass through all these props to the input.
  const eventSearchProps = {
    placeholder: "Search events",
    value: eventSearchValue,
    onChange: onEventSearchChange,
  };

  const onEventSuggestionsFetchRequested = ({ value }) => {
    setEventSearchSuggestions(getEventSuggestions(value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onEventSuggestionsClearRequested = () => {
    setEventSearchSuggestions([]);
  };

  const renderCreatorSearchInput = (inputProps) => (
    <InputGroup className='input-search-wrapper input-group-plain input-group-merge'>
      <InputGroupText className='input-search-left'>
        <FontAwesomeIcon icon='fa-magnifying-glass' />
      </InputGroupText>
      <Input
        {...inputProps}
        className='input-search-mid'
        placeholder='Search creators'
      />

      <InputGroupText className='input-search-right'>
        {creatorSearchLoading && (
          <ScaleLoader
            color='#eee'
            loading={creatorSearchLoading}
            height='10px'
            cssOverride={{
              display: "inline",
            }}
          />
        )}
        {creatorSearchValue && !creatorSearchLoading && (
          <button
            aria-label='Close'
            className='close'
            type='button'
            onClick={() => {
              setCreatorSearchValue("");
              setCreatorSearchSuggestions([]);
            }}
          >
            <span aria-hidden={true}>&times;</span>
          </button>
        )}
      </InputGroupText>
    </InputGroup>
  );

  // Teach Autosuggest how to calculate suggestions for any given input value.
  const getCreatorSuggestions = (value) => {
    const inputValue = value?.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : creatorSearchResults.filter(
          (result) =>
            result?.name?.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion.
  const getCreatorSuggestionValue = (suggestion) => suggestion?.name;

  // TODO: Search suggestion scollable and max result
  const renderCreatorSuggestion = (suggestion) => (
    <div className='text-left'>{suggestion?.name}</div>
  );

  const onCreatorSearchChange = (event, { newValue }) => {
    // setCreatorSearchLoading(true);
    setCreatorSearchValue(newValue);
  };

  // Autosuggest will pass through all these props to the input.
  const creatorSearchProps = {
    placeholder: "Search creators",
    value: creatorSearchValue,
    onChange: onCreatorSearchChange,
  };

  const onCreatorSuggestionsFetchRequested = ({ value }) => {
    setCreatorSearchSuggestions(getCreatorSuggestions(value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onCreatorSuggestionsClearRequested = () => {
    setCreatorSearchSuggestions([]);
  };

  /************** Old Search Box *************/
  const handleEventsSearch = async (string, results) => {
    if (string) {
      setSelectedEvents([...results]);
    } else {
      setSelectedEvents([...events]);
    }
  };

  const handleEventSelect = async (item) => {
    setSelectedEvents([item]);
  };

  const handleCreatorsSearch = async (string, results) => {
    if (string) {
      setSelectedCreators([...results]);
    } else {
      setSelectedCreators([...verifiedCreators]);
    }
  };

  const handleCreatorSelect = async (item) => {
    setSelectedCreators([item]);
  };

  const handleSelectTab = async (e) => {
    try {
      if (e.currentTarget.attributes["aria-selected"].value === "false") {
        if (e.currentTarget.id.slice(-1) === "0") {
          // setSelectedEvents([...events]);
          setSearchParams({ ["view"]: "events" });

          setVerifiedCreators([]);
          setSelectedCreators([]);
          setMoreCreators(true);
          setCreatorsPageIndex(0);

          setEvents([]);
          setSelectedEvents([]);
          setMoreEvents(true);
          setEventsPageIndex(0);
        }

        if (e.currentTarget.id.slice(-1) === "1") {
          // setSelectedCreators([...verifiedCreators]);
          setSearchParams({ ["view"]: "creators" });

          setVerifiedCreators([]);
          setSelectedCreators([]);
          setMoreCreators(true);
          setCreatorsPageIndex(0);

          setEvents([]);
          setSelectedEvents([]);
          setMoreEvents(true);
          setEventsPageIndex(0);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Dynamic url param for active tabs
  // Pagination for filter and infinite scroll
  useEffect(() => {
    if (view === "creators") {
      if (!creatorsPageIndex) {
        setPageLoading(true);

        // fetchCreators();
      }
      fetchCreators();
    } else {
      if (!eventsPageIndex) {
        setPageLoading(true);
        // fetchEvents();
      }
      fetchEvents();
    }
  }, [view, creatorsPageIndex, eventsPageIndex, refreshEvents]);

  return (
    <>
      <BarLoader
        loading={pageLoading}
        cssOverride={{
          width: "100%",
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          zIndex: "1031",
          backgroundColor: "#fff",
          opacity: "1",
        }}
      />
      <TopNavbar />
      <ReactNotifications />
      <div className='wrapper explore-page'>
        {/* <section className='section-collection-cover section-shaped my-0'>
          <img
            alt='...'
            className='bg-image'
            src={bgGradient}
            style={{ width: "100%" }}
          ></img>
        </section> */}
        <section className='collection-section section-main'>
          <Container>
            {/* <Loader loading={pageLoading} /> */}
            <div>
              <div className='text-center'>
                <h3 className='display-3 mt-4'>Explore</h3>
              </div>

              <Tabs className='py-5 text-center' defaultIndex={tabIndex[view]}>
                <TabList>
                  <Tab onClick={handleSelectTab}>
                    <div id='eventsTab'>
                      <FontAwesomeIcon icon='fa-fire' className='mr-1' />
                      <span className='d-none d-md-inline-block ml-1'>
                        Hot Events
                      </span>
                    </div>

                    <UncontrolledTooltip
                      placement='top'
                      target='eventsTab'
                      trigger='hover'
                    >
                      Find the latest events, drops, free mints and deals{" "}
                    </UncontrolledTooltip>
                  </Tab>
                  <Tab onClick={handleSelectTab}>
                    <div id='creatorsTab'>
                      <FontAwesomeIcon icon='fa-store' className='mr-1' />
                      <span className='d-none d-md-inline-block ml-1'>
                        Top Creators
                      </span>
                    </div>

                    <UncontrolledTooltip
                      placement='top'
                      target='creatorsTab'
                      trigger='hover'
                    >
                      Connect with Top creators, artists, and brands{" "}
                    </UncontrolledTooltip>
                  </Tab>
                </TabList>

                <TabPanel>
                  <Row>
                    <Col className='mt-2 mb-2 text-left'>
                      <Button
                        outline
                        type='button'
                        className='filter-button'
                        onClick={() => {
                          setFilterCollapseOpen(!filterCollapseOpen);
                        }}
                      >
                        <FontAwesomeIcon icon='fa-filter' />
                        <span className='d-none d-md-inline-block'>Filter</span>
                      </Button>
                    </Col>
                    <Col
                      className='search-box-wrapper mt-2 mb-2'
                      xl='6'
                      lg='6'
                      md='8'
                      sm='8'
                      xs='8'
                    >
                      {/* <ReactSearchAutocomplete
                        items={events}
                        fuseOptions={{
                          minMatchCharLength: 1,
                          keys: ["name", "creator", "pass"],
                          isCaseSensitive: false,
                          findAllMatches: true,
                          includeMatches: true,
                        }}
                        onSearch={handleEventsSearch}
                        onSelect={handleEventSelect}
                        formatResult={formatEventResult}
                        maxResults={100}
                        placeholder='Search name or pass'
                      /> */}

                      <Autosuggest
                        suggestions={eventSearchSuggestions}
                        onSuggestionsFetchRequested={
                          onEventSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          onEventSuggestionsClearRequested
                        }
                        getSuggestionValue={getEventSuggestionValue}
                        renderSuggestion={renderEventSuggestion}
                        renderInputComponent={(inputProps) =>
                          renderEventSearchInput(inputProps)
                        }
                        inputProps={eventSearchProps}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className='mt-2 mb-2'>
                      <Collapse
                        isOpen={filterCollapseOpen}
                        className='float-left filter-collapse'
                      >
                        <UncontrolledDropdown>
                          <DropdownToggle
                            outline
                            size='sm'
                            type='button'
                            className='filter-button'
                          >
                            <FontAwesomeIcon icon='fa-signal' />
                            <span className='d-none d-md-inline-block'>
                              Status
                            </span>
                          </DropdownToggle>
                          <DropdownMenu
                            className='full-dropdown-menu-sm filter-button-menu'
                            style={{ margin: 0 }}
                          >
                            <div className='filter-button-menu-items'>
                              <div className='mt-2 mb-2'>
                                <Button
                                  color='link'
                                  outline
                                  size='sm'
                                  type='button'
                                  block
                                  onClick={() => {
                                    setStatusOngoingChecked(true);
                                    setStatusFinishedChecked(true);
                                  }}
                                >
                                  <span>Select All</span>
                                </Button>
                              </div>
                            </div>

                            <div className='filter-button-menu-items'>
                              <div className='mt-2 mb-2'>
                                <Input
                                  className='custom-control-input filter-button-menu-input'
                                  id='statusOngoingCheck'
                                  type='checkbox'
                                  checked={statusOngoingChecked}
                                  onChange={() => {
                                    setStatusOngoingChecked(
                                      !statusOngoingChecked
                                    );
                                  }}
                                />
                                <Label
                                  className='custom-control-label'
                                  htmlFor='statusOngoingCheck'
                                >
                                  <span>Ongoing</span>
                                </Label>
                              </div>
                            </div>
                            <div className='filter-button-menu-items'>
                              <div className='mt-2 mb-2'>
                                <Input
                                  className='custom-control-input filter-button-menu-input'
                                  id='statusFinishedCheck'
                                  type='checkbox'
                                  checked={statusFinishedChecked}
                                  onChange={() => {
                                    setStatusFinishedChecked(
                                      !statusFinishedChecked
                                    );
                                  }}
                                />
                                <Label
                                  className='custom-control-label'
                                  htmlFor='statusFinishedCheck'
                                >
                                  <span>Finished</span>
                                </Label>
                              </div>
                            </div>
                            <div className='filter-button-menu-items'>
                              <div className='mt-2 mb-2'>
                                <Input
                                  className='custom-control-input filter-button-menu-input'
                                  id='statusIncludePrivateCheck'
                                  type='checkbox'
                                  checked={statusIncludePrivateChecked}
                                  onChange={() => {
                                    setStatusIncludePrivateChecked(
                                      !statusIncludePrivateChecked
                                    );
                                  }}
                                />
                                <Label
                                  className='custom-control-label'
                                  htmlFor='statusIncludePrivateCheck'
                                >
                                  <span>Include Private</span>
                                </Label>
                              </div>
                            </div>
                            <div className='filter-button-menu-items'>
                              <div className='mt-2 mb-2'>
                                <Button
                                  color='link'
                                  outline
                                  size='sm'
                                  type='button'
                                  block
                                  onClick={() => {
                                    setStatusOngoingChecked(false);
                                    setStatusFinishedChecked(false);
                                  }}
                                >
                                  <span>Clear All</span>
                                </Button>
                              </div>
                            </div>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            outline
                            size='sm'
                            type='button'
                            className='filter-button'
                          >
                            <FontAwesomeIcon icon='fa-link' />
                            <span className='d-none d-md-inline-block'>
                              Chain
                            </span>
                          </DropdownToggle>
                          <DropdownMenu
                            className='full-dropdown-menu-sm filter-button-menu'
                            style={{ margin: 0 }}
                          >
                            <div className='filter-button-menu-items'>
                              <div className='mt-2 mb-2'>
                                <Button
                                  color='link'
                                  outline
                                  size='sm'
                                  type='button'
                                  block
                                  onClick={() => {
                                    setChainEthChecked(true);
                                    setChainPolygonChecked(true);
                                  }}
                                >
                                  <span>Select All</span>
                                </Button>
                              </div>
                            </div>
                            <div className='filter-button-menu-items'>
                              <div className='mt-2 mb-2'>
                                <Input
                                  className='custom-control-input filter-button-menu-input'
                                  id='chainEthCheck'
                                  type='checkbox'
                                  checked={chainEthChecked}
                                  onChange={() => {
                                    setChainEthChecked(!chainEthChecked);
                                  }}
                                />
                                <Label
                                  className='custom-control-label'
                                  htmlFor='chainEthCheck'
                                >
                                  <span>Ethereum</span>
                                </Label>
                              </div>
                            </div>
                            <div className='filter-button-menu-items'>
                              <div className='mt-2 mb-2'>
                                <Input
                                  className='custom-control-input filter-button-menu-input'
                                  id='chainPolygonCheck'
                                  type='checkbox'
                                  checked={chainPolygonChecked}
                                  onChange={() => {
                                    setChainPolygonChecked(
                                      !chainPolygonChecked
                                    );
                                  }}
                                />
                                <Label
                                  className='custom-control-label'
                                  htmlFor='chainPolygonCheck'
                                >
                                  <span>Polygon</span>
                                </Label>
                              </div>
                            </div>
                            <div className='filter-button-menu-items'>
                              <div className='mt-2 mb-2'>
                                <Button
                                  color='link'
                                  outline
                                  size='sm'
                                  type='button'
                                  block
                                  onClick={() => {
                                    setChainEthChecked(false);
                                    setChainPolygonChecked(false);
                                  }}
                                >
                                  <span>Clear All</span>
                                </Button>
                              </div>
                            </div>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            outline
                            size='sm'
                            type='button'
                            className='filter-button'
                          >
                            <FontAwesomeIcon icon='fa-list' />
                            <span className='d-none d-md-inline-block'>
                              Category
                            </span>
                          </DropdownToggle>
                          <DropdownMenu
                            className='full-dropdown-menu-sm filter-button-menu'
                            style={{ margin: 0 }}
                          >
                            <div className='filter-button-menu-items'>
                              <div className='mt-2 mb-2'>
                                <Button
                                  color='link'
                                  outline
                                  size='sm'
                                  type='button'
                                  block
                                  onClick={() => {
                                    setCategoryTokenChecked(true);
                                    setCategoryArtChecked(true);
                                    setCategoryMusicChecked(true);
                                    setCategoryGamingChecked(true);
                                    setCategoryPartyChecked(true);
                                    setCategorySportsChecked(true);
                                    setCategoryDAOChecked(true);
                                    setCategoryMeetingChecked(true);
                                    setCategoryStudyChecked(true);
                                    setCategoryLeisureChecked(true);
                                    setCategoryFundraisingChecked(true);
                                    setCategoryMiscChecked(true);
                                  }}
                                >
                                  <span>Select All</span>
                                </Button>
                              </div>
                            </div>
                            <div className='filter-button-menu-items'>
                              <div className='mt-2 mb-2'>
                                <Input
                                  className='custom-control-input filter-button-menu-input'
                                  id='categoryTokenCheck'
                                  type='checkbox'
                                  checked={categoryTokenChecked}
                                  onChange={() => {
                                    setCategoryTokenChecked(
                                      !categoryTokenChecked
                                    );
                                  }}
                                />
                                <Label
                                  className='custom-control-label'
                                  htmlFor='categoryTokenCheck'
                                >
                                  <span>NFT &amp; Token</span>
                                </Label>
                              </div>
                            </div>
                            <div className='filter-button-menu-items'>
                              <div className='mt-2 mb-2'>
                                <Input
                                  className='custom-control-input filter-button-menu-input'
                                  id='categoryArtCheck'
                                  type='checkbox'
                                  checked={categoryArtChecked}
                                  onChange={() => {
                                    setCategoryArtChecked(!categoryArtChecked);
                                  }}
                                />
                                <Label
                                  className='custom-control-label'
                                  htmlFor='categoryArtCheck'
                                >
                                  <span>Art &amp; Exhbition</span>
                                </Label>
                              </div>
                            </div>
                            <div className='filter-button-menu-items'>
                              <div className='mt-2 mb-2'>
                                <Input
                                  className='custom-control-input filter-button-menu-input'
                                  id='categoryMusicCheck'
                                  type='checkbox'
                                  checked={categoryMusicChecked}
                                  onChange={() => {
                                    setCategoryMusicChecked(
                                      !categoryMusicChecked
                                    );
                                  }}
                                />
                                <Label
                                  className='custom-control-label'
                                  htmlFor='categoryMusicCheck'
                                >
                                  <span>Music &amp; Concert</span>
                                </Label>
                              </div>
                            </div>
                            <div className='filter-button-menu-items'>
                              <div className='mt-2 mb-2'>
                                <Input
                                  className='custom-control-input filter-button-menu-input'
                                  id='categoryGamingCheck'
                                  type='checkbox'
                                  checked={categoryGamingChecked}
                                  onChange={() => {
                                    setCategoryGamingChecked(
                                      !categoryGamingChecked
                                    );
                                  }}
                                />
                                <Label
                                  className='custom-control-label'
                                  htmlFor='categoryGamingCheck'
                                >
                                  <span>Gaming &amp; Metaverse</span>
                                </Label>
                              </div>
                            </div>
                            <div className='filter-button-menu-items'>
                              <div className='mt-2 mb-2'>
                                <Input
                                  className='custom-control-input filter-button-menu-input'
                                  id='categoryPartyCheck'
                                  type='checkbox'
                                  checked={categoryPartyChecked}
                                  onChange={() => {
                                    setCategoryPartyChecked(
                                      !categoryPartyChecked
                                    );
                                  }}
                                />
                                <Label
                                  className='custom-control-label'
                                  htmlFor='categoryPartyCheck'
                                >
                                  <span>Party &amp; Festival</span>
                                </Label>
                              </div>
                            </div>
                            <div className='filter-button-menu-items'>
                              <div className='mt-2 mb-2'>
                                <Input
                                  className='custom-control-input filter-button-menu-input'
                                  id='categorySportsCheck'
                                  type='checkbox'
                                  checked={categorySportsChecked}
                                  onChange={() => {
                                    setCategorySportsChecked(
                                      !categorySportsChecked
                                    );
                                  }}
                                />
                                <Label
                                  className='custom-control-label'
                                  htmlFor='categorySportsCheck'
                                >
                                  <span>Sports &amp; Fitness</span>
                                </Label>
                              </div>
                            </div>
                            <div className='filter-button-menu-items'>
                              <div className='mt-2 mb-2'>
                                <Input
                                  className='custom-control-input filter-button-menu-input'
                                  id='categoryDAOCheck'
                                  type='checkbox'
                                  checked={categoryDAOChecked}
                                  onChange={() => {
                                    setCategoryDAOChecked(!categoryDAOChecked);
                                  }}
                                />
                                <Label
                                  className='custom-control-label'
                                  htmlFor='categoryDAOCheck'
                                >
                                  <span>DAO Governance</span>
                                </Label>
                              </div>
                            </div>
                            <div className='filter-button-menu-items'>
                              <div className='mt-2 mb-2'>
                                <Input
                                  className='custom-control-input filter-button-menu-input'
                                  id='categoryMeetingCheck'
                                  type='checkbox'
                                  checked={categoryMeetingChecked}
                                  onChange={() => {
                                    setCategoryMeetingChecked(
                                      !categoryMeetingChecked
                                    );
                                  }}
                                />
                                <Label
                                  className='custom-control-label'
                                  htmlFor='categoryMeetingCheck'
                                >
                                  <span>Meeting &amp; Conference</span>
                                </Label>
                              </div>
                            </div>
                            <div className='filter-button-menu-items'>
                              <div className='mt-2 mb-2'>
                                <Input
                                  className='custom-control-input filter-button-menu-input'
                                  id='categoryStudyCheck'
                                  type='checkbox'
                                  checked={categoryStudyChecked}
                                  onChange={() => {
                                    setCategoryStudyChecked(
                                      !categoryStudyChecked
                                    );
                                  }}
                                />
                                <Label
                                  className='custom-control-label'
                                  htmlFor='categoryStudyCheck'
                                >
                                  <span>Study &amp; Workshop</span>
                                </Label>
                              </div>
                            </div>
                            <div className='filter-button-menu-items'>
                              <div className='mt-2 mb-2'>
                                <Input
                                  className='custom-control-input filter-button-menu-input'
                                  id='categoryLeisureCheck'
                                  type='checkbox'
                                  checked={categoryLeisureChecked}
                                  onChange={() => {
                                    setCategoryLeisureChecked(
                                      !categoryLeisureChecked
                                    );
                                  }}
                                />
                                <Label
                                  className='custom-control-label'
                                  htmlFor='categoryLeisureCheck'
                                >
                                  <span>Leisure &amp; Tour</span>
                                </Label>
                              </div>
                            </div>
                            <div className='filter-button-menu-items'>
                              <div className='mt-2 mb-2'>
                                <Input
                                  className='custom-control-input filter-button-menu-input'
                                  id='categoryFundraisingCheck'
                                  type='checkbox'
                                  checked={categoryFundraisingChecked}
                                  onChange={() => {
                                    setCategoryFundraisingChecked(
                                      !categoryFundraisingChecked
                                    );
                                  }}
                                />
                                <Label
                                  className='custom-control-label'
                                  htmlFor='categoryFundraisingCheck'
                                >
                                  <span>Charity &amp; Fundraising</span>
                                </Label>
                              </div>
                            </div>
                            <div className='filter-button-menu-items'>
                              <div className='mt-2 mb-2'>
                                <Input
                                  className='custom-control-input filter-button-menu-input'
                                  id='categoryMiscCheck'
                                  type='checkbox'
                                  checked={categoryMiscChecked}
                                  onChange={() => {
                                    setCategoryMiscChecked(
                                      !categoryMiscChecked
                                    );
                                  }}
                                />
                                <Label
                                  className='custom-control-label'
                                  htmlFor='categoryMiscCheck'
                                >
                                  <span>Miscellaneous</span>
                                </Label>
                              </div>
                            </div>
                            <div className='filter-button-menu-items'>
                              <div className='mt-2 mb-2'>
                                <Button
                                  color='link'
                                  outline
                                  size='sm'
                                  type='button'
                                  block
                                  onClick={() => {
                                    setCategoryTokenChecked(false);
                                    setCategoryArtChecked(false);
                                    setCategoryMusicChecked(false);
                                    setCategoryGamingChecked(false);
                                    setCategoryPartyChecked(false);
                                    setCategorySportsChecked(false);
                                    setCategoryDAOChecked(false);
                                    setCategoryMeetingChecked(false);
                                    setCategoryStudyChecked(false);
                                    setCategoryLeisureChecked(false);
                                    setCategoryFundraisingChecked(false);
                                    setCategoryMiscChecked(false);
                                  }}
                                >
                                  <span>Clear All</span>
                                </Button>
                              </div>
                            </div>
                          </DropdownMenu>
                        </UncontrolledDropdown>

                        <UncontrolledDropdown>
                          <DropdownToggle
                            outline
                            size='sm'
                            type='button'
                            className='filter-button'
                          >
                            <FontAwesomeIcon icon='fa-dollar-sign' />
                            <span className='d-none d-md-inline-block'>
                              Pass
                            </span>
                          </DropdownToggle>
                          <DropdownMenu
                            className='full-dropdown-menu-sm filter-button-menu'
                            style={{ margin: 0 }}
                          >
                            <div className='filter-button-menu-items'>
                              <div className='mt-2 mb-2'>
                                <Button
                                  color='link'
                                  outline
                                  size='sm'
                                  type='button'
                                  block
                                  onClick={() => {
                                    setPassFreeChecked(true);
                                    setPassHoldersChecked(true);
                                  }}
                                >
                                  <span>Select All</span>
                                </Button>
                              </div>
                            </div>
                            <div className='filter-button-menu-items'>
                              <div className='mt-2 mb-2'>
                                <Input
                                  className='custom-control-input filter-button-menu-input'
                                  id='passFreeCheck'
                                  type='checkbox'
                                  checked={passFreeChecked}
                                  onChange={() => {
                                    setPassFreeChecked(!passFreeChecked);
                                  }}
                                />
                                <Label
                                  className='custom-control-label'
                                  htmlFor='passFreeCheck'
                                >
                                  <span>Free to Join</span>
                                </Label>
                              </div>
                            </div>
                            <div className='filter-button-menu-items'>
                              <div className='mt-2 mb-2'>
                                <Input
                                  className='custom-control-input filter-button-menu-input'
                                  id='passHoldersCheck'
                                  type='checkbox'
                                  checked={passHoldersChecked}
                                  onChange={() => {
                                    setPassHoldersChecked(!passHoldersChecked);
                                  }}
                                />
                                <Label
                                  className='custom-control-label'
                                  htmlFor='passHoldersCheck'
                                >
                                  <span>Pass Holders</span>
                                </Label>
                              </div>
                            </div>
                            <div className='filter-button-menu-items'>
                              <div className='mt-2 mb-2'>
                                <Button
                                  color='link'
                                  outline
                                  size='sm'
                                  type='button'
                                  block
                                  onClick={() => {
                                    setPassFreeChecked(false);
                                    setPassHoldersChecked(false);
                                  }}
                                >
                                  <span>Clear All</span>
                                </Button>
                              </div>
                            </div>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                        <Button
                          outline
                          color='link'
                          size='sm'
                          type='button'
                          id='resetFilter'
                          onClick={resetFilterEvents}
                        >
                          <FontAwesomeIcon icon='fa-rotate-left' />
                        </Button>

                        <UncontrolledTooltip
                          placement='top'
                          target='resetFilter'
                          trigger='hover'
                        >
                          <span className='text-capitalize'>Reset Filter</span>
                        </UncontrolledTooltip>

                        <Button
                          outline
                          color='primary'
                          size='sm'
                          type='button'
                          id='applyFilter'
                          onClick={applyFilterEvents}
                        >
                          Go
                        </Button>

                        <UncontrolledTooltip
                          placement='top'
                          target='applyFilter'
                          trigger='hover'
                        >
                          <span className='text-capitalize'>Apply Filter</span>
                        </UncontrolledTooltip>
                      </Collapse>
                    </Col>
                  </Row>
                  <div className='pt-2 pb-4 px-1'>
                    {(!selectedEvents || selectedEvents.length <= 0) &&
                    !itemsLoading ? (
                      <h4 className='mt-5 mb-5 text-muted'>No events found.</h4>
                    ) : (
                      selectedEvents &&
                      selectedEvents.length > 0 && (
                        <InfiniteScroll
                          dataLength={selectedEvents.length}
                          next={() => setEventsPageIndex((page) => page + 1)}
                          hasMore={moreEvents}
                        >
                          <Row className='mt-1 mb-5'>{listEvents()}</Row>
                          <LoaderDots loading={itemsLoading} />
                        </InfiniteScroll>
                      )
                    )}
                  </div>
                </TabPanel>
                <TabPanel>
                  <Row>
                    <Col className='text-left mt-2 mb-2'>
                      <Button
                        outline
                        type='button'
                        className='filter-button'
                        onClick={(e) => e.preventDefault()}
                      >
                        <img
                          src={verifiedImg}
                          className='realpass-verified-name-suffix-sm'
                        />
                        <span className='d-none d-md-inline-block ml-1'>
                          Verified
                        </span>
                      </Button>
                    </Col>
                    <Col
                      className='search-box-wrapper mt-2 mb-2'
                      xl='6'
                      lg='6'
                      md='8'
                      sm='8'
                      xs='8'
                    >
                      {/* <ReactSearchAutocomplete
                        items={verifiedCreators}
                        fuseOptions={{
                          minMatchCharLength: 1,
                          keys: ["name"],
                          isCaseSensitive: false,
                          findAllMatches: true,
                          includeMatches: true,
                        }}
                        onSearch={handleCreatorsSearch}
                        onSelect={handleCreatorSelect}
                        maxResults={100}
                        placeholder='Search for creators'
                      /> */}

                      <Autosuggest
                        suggestions={creatorSearchSuggestions}
                        onSuggestionsFetchRequested={
                          onCreatorSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          onCreatorSuggestionsClearRequested
                        }
                        getSuggestionValue={getCreatorSuggestionValue}
                        renderSuggestion={renderCreatorSuggestion}
                        renderInputComponent={(inputProps) =>
                          renderCreatorSearchInput(inputProps)
                        }
                        inputProps={creatorSearchProps}
                      />
                    </Col>
                  </Row>
                  <div className='pt-2 pb-4 px-1'>
                    {(!selectedCreators || selectedCreators.length <= 0) &&
                    !itemsLoading ? (
                      <h4 className='mt-5 mb-5 text-muted'>
                        No creators found.
                      </h4>
                    ) : (
                      selectedCreators &&
                      selectedCreators.length > 0 && (
                        <InfiniteScroll
                          dataLength={selectedCreators.length}
                          next={() => setCreatorsPageIndex((page) => page + 1)}
                          hasMore={moreCreators}
                        >
                          <Row className='mt-1 mb-5'>
                            {listVerifiedCreators()}
                          </Row>
                          <LoaderDots loading={itemsLoading} />
                        </InfiniteScroll>
                      )
                    )}
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </Container>
        </section>
      </div>
    </>
  );
}
