import React from "react";
// reactstrap components
import { Button, NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function DarkFooter() {
  return (
    <>
      <footer className="bg-dark footer">
        <Container>
          <Row className="align-items-center justify-content-md-between">
            <Col md="5" sm="5" xs="5">
              <Nav className="nav-footer">
                <NavItem>
                  <NavLink
                    className="text-white"
                    href="https://passlabs.xyz"
                    target="_blank"
                  >
                    <small className="text-white copyright">
                      © {new Date().getFullYear()} PASS LABS
                    </small>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/terms">
                    <small className="text-white">Terms</small>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/privacy">
                    <small className="text-white">Privacy</small>
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="7" sm="7" xs="7" className="float-right">
              <Nav className="nav-footer justify-content-end">
                <NavItem className="ml-1 mr-2">
                  <Button
                    className="btn-icon-only rounded-circle btn-sm"
                    color="white"
                    href="https://twitter.com/realpassxyz"
                    rel="external nofollow noopener"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon="fa-brands fa-twitter" />
                  </Button>
                </NavItem>
                <NavItem className="ml-1 mr-2">
                  <Button
                    className="btn-icon-only rounded-circle btn-sm"
                    color="white"
                    href="mailto:gm@passlabs.xyz"
                    rel="external nofollow noopener"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon="fa-envelope" />
                  </Button>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}
