import React, { useState, useEffect, useContext } from "react";

import { AuthContext } from "Auth.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  CardImg,
  Badge,
  Button,
  Container,
  UncontrolledCarousel,
  Row,
  Col,
} from "reactstrap";

const verifiedImg = require("assets/img/icons/verified-blue.svg");
const gelBannerImg = require("assets/img/banners/gel-banner.svg");

const { REACT_APP_GOODLE_API_KEY } = process.env;

export default function TopEvents() {
  //   const NFFeatured = Moralis.Object.extend("NFFeatured");
  //   const featuredQuery = new Moralis.Query(NFFeatured);

  //   const NFEvent = Moralis.Object.extend("NFEvent");
  //   const eventQuery = new Moralis.Query(NFEvent);

  //   const NFVerified = Moralis.Object.extend("NFVerified");
  //   const verifiedQuery = new Moralis.Query(NFVerified);

  //   const NFProfile = Moralis.Object.extend("NFProfile");
  //   const creatorProfileQuery = new Moralis.Query(NFProfile);

  const [featuredItems, setFeaturedItems] = useState([]);

  const fetchFeaturedItems = async () => {
    // try {
    //   featuredQuery.equalTo("featured", true);
    //   eventQuery.matchesKeyInQuery("objectId", "event.objectId", featuredQuery);
    //   eventQuery.ascending("name");
    //   eventQuery.limit(3);
    //   const eventRes = await eventQuery.find();
    //   if (eventRes) {
    //     const eventList = [];
    //     for (let i of eventRes) {
    //       creatorProfileQuery.matchesKeyInQuery(
    //         "objectId",
    //         "profile.objectId",
    //         verifiedQuery
    //       );
    //       creatorProfileQuery.equalTo("objectId", i?.get("creator").id);
    //       const creatorRes = await creatorProfileQuery.first();
    //       eventList.push({
    //         id: i?.id,
    //         name: i?.get("name"),
    //         image: i?.get("eventImage")?.url(),
    //         creator: creatorRes?.get("name"),
    //         verified: true,
    //       });
    //     }
    //     setFeaturedItems(eventList);
    //   }
    // } catch (err) {
    //   console.error(err.message);
    // }
  };

  const listFeaturedItems = () => {
    return featuredItems.map((item, index) => {
      return (
        <Col lg='4' md='5' sm='6' key={index}>
          <Card className='card-background' data-animation='zooming'>
            <div
              className='full-background'
              style={{
                backgroundImage: `url(${item?.image})`,
              }}
            ></div>
            <a href={`/event/${item?.id}`}>
              <CardBody>
                <div className='content-bottom'>
                  <h5 className='card-category text-white'>
                    {item?.creator}
                    {item?.verified && (
                      <img
                        className='realpass-verified-name-suffix-sm ml-1 mb-1'
                        src={verifiedImg}
                      />
                    )}
                  </h5>
                  <CardTitle tag='h5' className='title-thumbnail'>
                    <strong>{item?.name}</strong>
                  </CardTitle>
                </div>
              </CardBody>
            </a>
          </Card>
        </Col>
      );
    });
  };

  useEffect(() => {
    fetchFeaturedItems();
  }, []);

  return (
    <>
      <section className='section section-sm mt-5 mb-5'>
        <Container>
          <Row className='justify-content-center text-center mb-4'>
            <Col lg='8'>
              <h2 className='display-3'>Top Events</h2>
              <p>
                Join on-chain Events - Metaverse Concerts, NFT Sales, Game
                Launches, Token Offerings
              </p>
            </Col>
          </Row>
          <Row className='justify-content-center'>
            <Col lg='12'>
              <Row className='row-grid'>{listFeaturedItems()}</Row>
            </Col>
          </Row>
          <Row className='justify-content-center text-center mt-5'>
            <Col lg='8'>
              <Button outline color='dark' href='/explore'>
                View All Events
              </Button>
            </Col>
          </Row>
          <Card className='card-background card mt-6'>
            <div
              className='full-background'
              style={{
                backgroundImage: `url(${gelBannerImg})`,
              }}
            ></div>
            <div className='p-5' style={{ zIndex: "2" }}>
              <Row className='align-items-center'>
                <Col lg='6'>
                  <h3 className='display-4 text-white'>
                    NFT Passes for Notable Events
                  </h3>
                </Col>
                <Col className='ml-lg-auto' lg='3'>
                  <Button
                    block
                    className='btn-white'
                    color='default'
                    href='https://forms.gle/xLrNXeQHnZZyWwT5A'
                    target='_blank'
                    rel='external nofollow noopener'
                  >
                    <FontAwesomeIcon icon='fa-star' className='mr-2' />
                    Get Creator Access
                  </Button>
                </Col>
              </Row>
            </div>
          </Card>
        </Container>
      </section>
    </>
  );
}
