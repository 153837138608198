import React, { useState, useEffect, useContext } from "react";
import {
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  FormGroup,
  InputGroup,
  InputGroupText,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";

// core components
import TopNavbar from "components/Navbars/TopNavbar.js";
import LoaderSection from "components/Loaders/LoaderSection.js";
import LightFooter from "components/Footers/LightFooter.js";

import ScaleLoader from "react-spinners/ScaleLoader";
import BarLoader from "react-spinners/BarLoader";

import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.compat.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { notify, truncateAddressShort, validEmail } from "utils/Utils.js";

import {
  authHttpConfig,
  ethWalletExists,
  emailExists,
  createSiweMessage,
  eventUrlExists,
  firebaseUpload,
  ethsigAuth,
} from "utils/Api.js";

import FirebaseInit from "utils/FirebaseInit.js";

import { AuthContext } from "Auth.js";

import {
  useAccount,
  useConnect,
  useDisconnect,
  useEnsAvatar,
  useEnsName,
  useNetwork,
  useSignMessage,
} from "wagmi";

import { ReactSession } from "react-client-session";

import { Form, Field } from "react-final-form";

import Avatar from "boring-avatars";

const mmImg = require("assets/img/wallets/mm.svg");
const wcImg = require("assets/img/wallets/wc.svg");
const pwImg = require("assets/img/wallets/pw.svg");
const bgImg = require("assets/img/bg/abstract-line-01.svg");
const pidImg = require("assets/img/logos/logo-icon-gradient.svg");

const {
  REACT_APP_HOST,
  REACT_APP_API_HOST,
  REACT_APP_GOODLE_RECAPTCHA_CHECKBOX_KEY,
} = process.env;

export default function Signin() {
  const {
    firebaseAuth,
    signInWithCustomToken,
    isSignInWithEmailLink,
    sendSignInLinkToEmail,
    signInWithEmailLink,
  } = FirebaseInit();

  const {
    currentUser,
    currentUserInfo,
    setCurrentUserInfo,
    currentAuthToken,
    setCurrentUserStatus,
  } = useContext(AuthContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const isSignMode = searchParams.get("mode") === "signIn" || false;

  const [email, setEmail] = useState(ReactSession.get("signinEmail") || "");
  const [signinEmail, setSigninEmail] = useState(
    ReactSession.get("signinEmail")
  );

  const {
    address: wagmiAddress,
    connector: wagmiConnector,
    isConnected: isWagmiConnected,
  } = useAccount();

  const { chain } = useNetwork();
  const { signMessageAsync } = useSignMessage();
  const { data: ensAvatar } = useEnsAvatar({ wagmiAddress });
  const { data: ensName } = useEnsName({ wagmiAddress });
  const {
    connect: wagmiConnect,
    connectors: wagmiConnectors,
    error: wagmiError,
    isLoading: isWagmiLoading,
    pendingConnector: pendingWagmiConnector,
  } = useConnect();

  const { disconnect: wagmiDisconnect } = useDisconnect({
    onError(error) {
      console.error("Error ", error);
    },
  });

  const location = useLocation();
  const navigate = useNavigate();

  const domain = "RealPass";
  const origin = REACT_APP_HOST;
  const href = window.location.href;

  const [signinTitle01, setSigninTitle01] = useState(
    "Get started with web3 wallet"
  );
  const [signinTitle02, setSigninTitle02] = useState("Get started with email");
  const [isWalletSigning, setIsWalletSigning] = useState(false);
  const [isEmailSigning, setIsEmailSigning] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const [acceptTerms, setAcceptTerms] = useState(false);

  const signingMsg = `Safe sign the message to verify your wallet.`;

  useEffect(() => {
    if (currentUser) {
      if (
        location?.state?.prevPath &&
        location?.state?.prevPath !== "/signin"
      ) {
        navigate(location?.state?.prevPath);
      } else {
        navigate("/account");
      }
    }
  }, [currentUser]);

  // const createSiweMessage = async (chainId, address, statement) => {
  //   const res = await fetch(`${REACT_APP_API_HOST}/auth/nonce`, {
  //     method: "GET",
  //     headers: {
  //       "Accept": "application/json",
  //       "Content-Type": "application/json",
  //     },
  //   });

  //   const data = await res.json();

  //   const message = new SiweMessage({
  //     domain,
  //     address,
  //     statement,
  //     uri: origin,
  //     version: "1",
  //     chainId,
  //     nonce: data?.nonce,
  //   });

  //   return { message: message.prepareMessage(), challenge: data?.challenge };
  // };

  const signInEthAddress = async () => {
    try {
      setIsWalletSigning(true);

      const chainId = chain?.id;
      if (!wagmiAddress || !chainId) return;

      const { message, challenge } = await createSiweMessage(
        chainId,
        wagmiAddress,
        signingMsg
      );

      const signature = await signMessageAsync({
        message: message,
      });

      const data = await ethsigAuth(message, signature, challenge);

      if (data?.error) {
        notify(data?.error, "error");
      }

      if (data?.authenticated) {
        const userCredential = await signInWithCustomToken(
          firebaseAuth,
          data?.token
        );

        notify(`GM, good to see you back.`, "success");
      } else {
        notify("Wallet authentication failed", "error");
      }
    } catch (err) {
      console.error(err.message);
      notify(
        err.message?.includes("user rejected signing")
          ? "User rejected signing with wallet."
          : "Unable to sign in with wallet address.",
        "error"
      );
    } finally {
      setIsWalletSigning(false);
    }
  };

  const removeSigninEmail = () => {
    setSigninEmail();
    ReactSession.remove("signinEmail");
  };

  const signinWithEmail = async () => {
    try {
      setIsEmailSigning(true);
      if (isSignInWithEmailLink(firebaseAuth, href) && signinEmail) {
        const res = await signInWithEmailLink(
          firebaseAuth,
          ReactSession.get("signinEmail"),
          href
        );
        setSigninEmail();
        ReactSession.remove("signinEmail");

        notify(`GM, good to see you back.`, "success", 2000);
      } else if (isSignMode) {
        notify("Sign in link has expired.", "error");
      }
    } catch (err) {
      console.error(err.message);
      notify("Unable to sign in with email address.", "error");
    } finally {
      setIsEmailSigning(false);
    }
  };

  const getSigninLink = async () => {
    try {
      setIsEmailSigning(true);
      const actionCodeSettings = {
        url: `${window.location.origin}/signin`,
        handleCodeInApp: true,
      };

      await sendSignInLinkToEmail(firebaseAuth, email, actionCodeSettings);

      ReactSession.set("signinEmail", email);
      setSigninEmail(email);

      notify(
        `We have sent you a secure sign in link to ${email}`,
        "success",
        2000
      );
    } catch (err) {
      console.error(err.message);
      notify("Unable to get sign in link.", "error");
    } finally {
      setIsEmailSigning(false);
    }
  };

  useEffect(() => {
    setPageLoading(true);

    signinWithEmail();

    setPageLoading(false);
  }, []);

  return (
    <>
      <BarLoader
        loading={isWalletSigning || isEmailSigning || pageLoading}
        cssOverride={{
          width: "100%",
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          zIndex: "1031",
          backgroundColor: "#fff",
          opacity: "1",
        }}
      />
      <TopNavbar />
      <ReactNotifications />
      <div className="bg-transparent">
        <img alt="..." className="bg-image" src={bgImg} />
      </div>
      <section className="section section-shaped section-main">
        <Container
          className={isWagmiConnected && wagmiConnector ? "mb-3" : "mb-5"}
        >
          <Row className="justify-content-center text-center mb-4">
            <Col lg="6" md="7" sm="8">
              <h2 className="display-3">Welcome to RealPass</h2>
              <p>
                Get started by signing in with your email address or web3
                wallet.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg="6" md="7" sm="8">
              <Card className="shadow border-0 pt-3 pb-3">
                <CardHeader className="px-lg-5 bg-white">
                  <div className="text-center mb-3 text-dark">
                    <span>
                      <strong>{signinTitle02}</strong>
                    </span>
                  </div>
                  <Form
                    onSubmit={getSigninLink}
                    validate={async (values) => {
                      const errors = {};

                      if (!email) {
                        errors.email = "Email is required.";
                      } else if (!validEmail(email)) {
                        errors.email = "Email must have valid format.";
                      } else if (email.length > 100) {
                        errors.email = "Email must not exceed 100 characters.";
                      }

                      return errors;
                    }}
                    render={({
                      handleSubmit,
                      values,
                      submitting,
                      validating,
                      valid,
                    }) => (
                      <div>
                        <form onSubmit={handleSubmit}>
                          <Row>
                            <Col>
                              <div>
                                <FormGroup>
                                  <Field name="email">
                                    {({ input, meta }) => (
                                      <>
                                        <FormGroup>
                                          <InputGroup className="input-group-merge input-group-alternative">
                                            <InputGroupText>
                                              <FontAwesomeIcon icon="fa-envelope" />
                                            </InputGroupText>
                                            <Input
                                              {...input}
                                              id="email"
                                              value={email}
                                              onChange={(e) => {
                                                setEmail(e.target.value);
                                              }}
                                              placeholder={email || "Email"}
                                              type="text"
                                            />
                                          </InputGroup>
                                        </FormGroup>

                                        {meta.error && meta.touched && (
                                          <small className="text-warning">
                                            {meta.error}
                                          </small>
                                        )}
                                      </>
                                    )}
                                  </Field>
                                </FormGroup>
                              </div>
                            </Col>
                          </Row>

                          <div className="text-center">
                            <Button
                              className="mt-2 mb-3"
                              block
                              color="dark"
                              type="submit"
                              disabled={
                                !valid ||
                                !acceptTerms ||
                                isWalletSigning ||
                                isEmailSigning
                              }
                            >
                              <FontAwesomeIcon icon="fa-envelope" />
                              {isSignMode ? "Sign in now" : "Get Sign in link"}
                              <span className="ml-2">
                                <ScaleLoader
                                  color="#eee"
                                  loading={isEmailSigning}
                                  height="10px"
                                  cssOverride={{ display: "inline" }}
                                />
                              </span>
                            </Button>
                          </div>
                        </form>
                      </div>
                    )}
                  />
                </CardHeader>
                <CardBody className="px-lg-5 bg-white">
                  <div className="text-center mb-3 text-dark">
                    <span>
                      <strong>{signinTitle01}</strong>
                    </span>
                  </div>
                  {isWagmiConnected && wagmiConnector && (
                    <Alert className="mb-3 bg-secondary px-3">
                      <Row>
                        <Col className="col-1">
                          <span className="rounded-circle">
                            {/* {ensAvatar ? (
                            <img
                              src={ensAvatar}
                              alt='ENS Avatar'
                              className='float-left'
                            />
                          ) : ( */}
                            <Avatar
                              size={40}
                              name={wagmiAddress}
                              square="false"
                              variant="ring"
                              colors={[
                                "#000000",
                                "#2F2F2F",
                                "#505050",
                                "#797979",
                                "#CECECE",
                              ]}
                              className="float-left"
                            />
                            {/* )} */}
                          </span>
                        </Col>
                        <Col className="col-7">
                          <span className="text-dark ml-3 mb-0">
                            {ensName
                              ? `${ensName} (${truncateAddressShort(
                                  wagmiAddress
                                )})`
                              : truncateAddressShort(wagmiAddress)}
                          </span>
                          <div className="ml-3 mt-0">
                            <small className="text-muted">
                              Connected to {wagmiConnector?.name}
                            </small>
                          </div>
                        </Col>
                        <Col className="col-3">
                          <Button
                            outline
                            color="warning"
                            size="sm"
                            onClick={wagmiDisconnect}
                            className="btn-icon float-right"
                          >
                            <small>Disconnect</small>
                          </Button>
                        </Col>
                      </Row>
                    </Alert>
                  )}
                  <div className="btn-wrapper text-center mb-3">
                    {isWagmiConnected && wagmiConnector ? (
                      <>
                        <Button
                          className="btn-icon mt-2 mb-2"
                          type="button"
                          color="dark"
                          block
                          onClick={signInEthAddress}
                          disabled={
                            !acceptTerms || isWalletSigning || isEmailSigning
                          }
                        >
                          <span className="btn-inner--icon mr-1">
                            <img alt="..." src={pidImg} />
                          </span>
                          <span className="btn-inner--text">Safe Sign in</span>

                          <span className="ml-1">
                            <ScaleLoader
                              color="#eee"
                              loading={isWalletSigning}
                              height="10px"
                              cssOverride={{ display: "inline" }}
                            />
                          </span>
                        </Button>
                      </>
                    ) : (
                      wagmiConnectors?.map((connector) => {
                        return !connector.ready &&
                          connector.name === "MetaMask" ? (
                          <Button
                            block
                            className="btn-dark btn-icon mt-2 mb-2"
                            key={connector.id}
                            disabled={
                              !acceptTerms || isWagmiLoading || isEmailSigning
                            }
                            href="https://metamask.io/"
                            target="_blank"
                          >
                            <span className="btn-inner--icon mr-2">
                              <img alt="..." src={mmImg} />
                            </span>
                            Install MetaMask
                          </Button>
                        ) : (
                          <Button
                            block
                            className="btn-dark btn-icon mt-2 mb-2"
                            type="button"
                            disabled={
                              !acceptTerms || isWagmiLoading || isEmailSigning
                            }
                            key={connector.id}
                            onClick={() => wagmiConnect({ connector })}
                          >
                            <span className="btn-inner--icon mr-2">
                              <img
                                alt="..."
                                src={
                                  connector.name === "MetaMask"
                                    ? mmImg
                                    : connector.name === "WalletConnect"
                                    ? wcImg
                                    : pidImg
                                }
                              />
                            </span>
                            {connector.name}
                            {!connector.ready &&
                              connector.name !== "MetaMask" &&
                              " (unsupported)"}
                            <span className="ml-2">
                              <ScaleLoader
                                color="#eee"
                                loading={
                                  isWagmiLoading &&
                                  connector.id === pendingWagmiConnector?.id
                                }
                                height="10px"
                                cssOverride={{ display: "inline" }}
                              />
                            </span>
                          </Button>
                        );
                      })
                    )}

                    {wagmiError && (
                      <small className="text-warning mt-1">
                        {wagmiError.message}
                      </small>
                    )}
                  </div>

                  <div className="mt-4 mx-2 px-4">
                    <Label>
                      <Input
                        id="customCheckLogin"
                        checked={acceptTerms}
                        className="custom-control-input"
                        type="checkbox"
                        onChange={() => {
                          setAcceptTerms(!acceptTerms);
                        }}
                      />
                      <span className="custom-control-label">
                        I accept RealPass{" "}
                        <a
                          href="/terms"
                          rel="external nofollow noopener"
                          target="_blank"
                          className="text-info"
                        >
                          Terms of Service
                        </a>{" "}
                        and{" "}
                        <a
                          href="/privacy"
                          rel="external nofollow noopener"
                          target="_blank"
                          className="text-info"
                        >
                          Privacy Policy
                        </a>
                        .
                      </span>
                    </Label>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <LightFooter />
    </>
  );
}
