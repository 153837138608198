import React, { useState } from "react";

import { Button, Modal } from "reactstrap";

export default function ModalSuccess(props) {
  let { success, title, message, handler, resetSuccess } = props;
  const [modalOpen, setModalOpen] = useState(success);

  return success ? (
    <Modal isOpen={modalOpen}>
      <div className="mt-2 mb-3">
        <div className="success-checkmark">
          <div className="check-icon">
            <span className="icon-line line-tip"></span>
            <span className="icon-line line-long"></span>
            <div className="icon-circle"></div>
            <div className="icon-fix"></div>
          </div>
        </div>
        <div className="success-checkmark-title display-4">
          {title || "Success"}
        </div>
        <div className="success-checkmark-message lead px-2">
          {message || "Congrats, it's a success."}
        </div>
        <div className="success-checkmark-btn">
          <Button
            size="md"
            color="success"
            onClick={() => {
              if (handler) {
                handler();
              }
              if (resetSuccess) {
                resetSuccess(false);
              }
              setModalOpen(false);
            }}
          >
            Ok
          </Button>
        </div>
      </div>
    </Modal>
  ) : (
    ""
  );
}
