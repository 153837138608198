import React from "react";

export default function Loader(props) {
  let { loading } = props;

  return loading ? (
    <div className='loading-zone'>
      <div className='loading-zone-inner'>
        <div className='loading-zone-content'>
          <span className='loading'></span>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}
