import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { AuthContext } from "Auth.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";

// reactstrap components
import {
  Alert,
  Button,
  Card,
  Container,
  Row,
  Col,
  Input,
  InputGroupText,
  InputGroup,
  Label,
  FormGroup,
  UncontrolledTooltip,
  CardBody,
} from "reactstrap";

// core components
import TopNavbar from "components/Navbars/TopNavbar.js";
import Loader from "components/Loaders/Loader.js";

import ScaleLoader from "react-spinners/ScaleLoader";
import BarLoader from "react-spinners/BarLoader";

import SocialLinksEditable from "components/Lists/SocialLinksEditable.js";

import {
  truncateAddressShort,
  truncateAddressLong,
  notify,
  validEmail,
  removeProtocolStr,
  genImageName,
} from "utils/Utils.js";

import {
  authHttpConfig,
  ethWalletExists,
  emailExists,
  createSiweMessage,
  eventUrlExists,
  firebaseUpload,
  ethsigLinkAccount,
} from "utils/Api.js";

import {
  useAccount,
  useConnect,
  useDisconnect,
  useEnsAvatar,
  useEnsName,
  useNetwork,
  useSignMessage,
} from "wagmi";

import { ethers } from "ethers";
import { SiweMessage, ErrorTypes } from "siwe";

import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.compat.css";

import { Form, Field } from "react-final-form";

import { ReactSession } from "react-client-session";
import FirebaseInit from "utils/FirebaseInit.js";

import ReCAPTCHA from "react-google-recaptcha";

import Avatar from "boring-avatars";
import Compressor from "compressorjs";

import { SocialLinks } from "social-links";

const {
  mmImg,
  wcImg,
  pwImg,
  bgImg,
  pidImg,
  bgAbstractLine03,
  logoBlackIcon,
  defaultBoxGrayImg,
  defaultFormImg,
  defaultFormBanner,
  ethWhiteImg,
  polygonWhiteImg,
  DefaultLocation,
} = require("utils/Variables.js");

const {
  REACT_APP_HOST,
  REACT_APP_API_HOST,
  REACT_APP_GOODLE_RECAPTCHA_CHECKBOX_KEY,
} = process.env;

function Settings() {
  const {
    firebaseAuth,
    isSignInWithEmailLink,
    sendSignInLinkToEmail,
    linkWithCredential,
    EmailAuthProvider,
    unlink,
  } = FirebaseInit();

  const { currentUser, currentAuthToken, currentUserInfo, setCurrentUserInfo } =
    useContext(AuthContext);

  const hasCreatorRole = currentUserInfo?.hasCreatorRole;
  const isVerifiedUser = currentUserInfo?.isVerifiedUser;

  const [isNewUser, setIsNewUser] = useState(
    !currentUserInfo?.ethAddress || !currentUser?.email
  );

  const {
    address: wagmiAddress,
    connector: wagmiConnector,
    isConnected: isWagmiConnected,
  } = useAccount();

  const { chain } = useNetwork();
  const { signMessageAsync } = useSignMessage();
  const { data: ensAvatar } = useEnsAvatar({ wagmiAddress });
  const { data: ensName } = useEnsName({ wagmiAddress });
  const {
    connect: wagmiConnect,
    connectors: wagmiConnectors,
    error: wagmiError,
    isLoading: isWagmiLoading,
    pendingConnector: pendingWagmiConnector,
  } = useConnect();

  const { disconnect: wagmiDisconnect } = useDisconnect({
    onError(error) {
      console.error("Error ", error);
    },
  });

  const socialLinks = new SocialLinks();

  const domain = "RealPass";
  const origin = REACT_APP_HOST;
  const href = window.location.href;

  const [isWalletSigning, setIsWalletSigning] = useState(false);
  const [isEmailUpdating, setIsEmailUpdating] = useState(false);
  const [newEmail, setNewEmail] = useState();
  const [walletError, setWalletError] = useState();
  const [emailError, setEmailError] = useState();
  const [isImageUploading, setIsImageUploading] = useState(false);

  const [pageLoading, setPageLoading] = useState(false);
  const [dataSaving, setDataSaving] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  const [imageUrl, setImageUrl] = useState(currentUserInfo?.imageUrl || "");
  const [imageData, setImageData] = useState();
  const [imageName, setImageName] = useState();

  const [bannerImageUrl, setBannerImageUrl] = useState(
    currentUserInfo?.bannerUrl || ""
  );
  const [bannerImageName, setBannerImageName] = useState();
  const [bannerImageData, setBannerImageData] = useState();

  const [name, setName] = useState(currentUserInfo?.name || "");
  const [bio, setBio] = useState(currentUserInfo?.bio || "");
  const [email, setEmail] = useState(currentUser?.email || "");

  const [links, setLinks] = useState([]);

  const signingMsg = `Safe sign the message to verify your wallet.`;

  const selectImage = (e) => {
    const data = e.target.files[0];

    // Compress Images
    new Compressor(data, {
      quality: 0.8,
      maxWidth: 360,
      maxHeight: 360,
      mimeType: "image/webp",

      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      success(result) {
        setImageUrl(URL.createObjectURL(result));
        setImageData(result);
        setImageName(`image.${result.name.split(".").at(-1)}`);
      },
      error(err) {
        console.error(err.message);
      },
    });
  };

  const selectBannerImage = (e) => {
    const data = e.target.files[0];

    //Compress Images
    new Compressor(data, {
      quality: 0.8,
      maxWidth: 1400,
      maxHeight: 400,
      mimeType: "image/webp",

      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      success(result) {
        setBannerImageUrl(URL.createObjectURL(result));
        setBannerImageData(result);
        setBannerImageName(`banner.${result.name.split(".").at(-1)}`);
      },
      error(err) {
        console.error(err.message);
      },
    });
  };

  const saveProfile = async () => {
    try {
      setDataSaving(true);

      let url = imageUrl;
      let bannerUrl = bannerImageUrl;

      if (imageData) {
        url = await firebaseUpload(
          imageData,
          genImageName(currentUser?.uid, "profile", imageName)
        );
        setImageUrl(url);
      }

      if (bannerImageData) {
        bannerUrl = await firebaseUpload(
          bannerImageData,
          genImageName(currentUser?.uid, "profile", bannerImageName)
        );

        setBannerImageUrl(bannerUrl);
      }

      const body = {
        imageUrl: url,
        bannerUrl: bannerUrl,
        name: name,
        bio: bio,
        links,
      };

      const res = await axios.post(
        `${REACT_APP_API_HOST}/user/settings`,
        body,
        authHttpConfig(currentAuthToken)
      );

      const data = await res.data;

      if (data) {
        setCurrentUserInfo(data);
        notify("Profile has been updated.", "success");
      } else {
        notify("Profile update failed.", "error");
      }
    } catch (err) {
      console.error(err.message);
      notify("Unable to save user profile.", "error");
    } finally {
      setDataSaving(false);
      setFormChanged(false);
    }
  };

  const checkWalletExists = async () => {
    let exists = await ethWalletExists(currentAuthToken, wagmiAddress);

    if (exists) {
      setWalletError("Wallet is linked with another account.");
    } else {
      setWalletError();
    }

    return exists;
  };

  const linkEthAddress = async () => {
    try {
      setIsWalletSigning(true);

      const chainId = chain?.id;
      if (!wagmiAddress || !chainId) return;

      const { message, challenge } = await createSiweMessage(
        chainId,
        wagmiAddress,
        signingMsg
      );

      const signature = await signMessageAsync({
        message: message,
      });

      const data = await ethsigLinkAccount(
        currentAuthToken,
        message,
        signature,
        challenge
      );

      if (data?.sigLinked) {
        setCurrentUserInfo({
          ...currentUserInfo,
          ethAddress: data?.ethAddress,
        });

        notify(`You have successfully linked your wallet.`, "success", 2000);
      } else {
        notify("Wallet verification failed", "error");
      }
    } catch (err) {
      console.error(err.message);
      notify("Unable to link wallet address.", "error");
    } finally {
      setIsWalletSigning(false);
    }
  };

  const verifySigninLink = async () => {
    try {
      if (
        isSignInWithEmailLink(firebaseAuth, href) &&
        ReactSession.get("newEmail")
      ) {
        // Unlink current email first
        if (
          currentUser?.email &&
          currentUser?.email !== ReactSession.get("newEmail")
        ) {
          await unlink(currentUser, EmailAuthProvider.PROVIDER_ID);
        }

        const credential = EmailAuthProvider.credentialWithLink(
          ReactSession.get("newEmail"),
          href
        );

        await linkWithCredential(currentUser, credential);
        setEmail(currentUser?.email);

        ReactSession.remove("newEmail");
        setNewEmail();

        notify("We've successfully verified your email.", "success", 2000);
      }
    } catch (err) {
      console.error(err.message);

      notify(
        err.code === "auth/email-already-in-use"
          ? "Email address is linked with another account."
          : err.code === "auth/provider-already-linked"
          ? "You have already linked your email."
          : "Unable to sign in with email.",
        "error"
      );

      if (err.code === "auth/provider-already-linked") {
        ReactSession.remove("newEmail");
        setNewEmail();
      }
    }
  };

  const linkEmailAccount = async () => {
    if (currentUser?.email !== email) {
      try {
        setIsEmailUpdating(true);

        const exists = await emailExists(currentAuthToken, email);

        if (exists) {
          setEmailError("Email is linked with another account.");
          return;
        } else {
          setEmailError();
        }

        const actionCodeSettings = {
          url: `${window.location.origin}/settings`,
          handleCodeInApp: true,
        };

        const res = await sendSignInLinkToEmail(
          firebaseAuth,
          email,
          actionCodeSettings
        );

        ReactSession.set("newEmail", email);
        setNewEmail(email);

        notify(
          `We've sent you a secure sign in link to ${email}`,
          "success",
          2000
        );
      } catch (err) {
        console.error(err);
        notify("Unable to link email address.", "error");
      } finally {
        setIsEmailUpdating(false);
      }
    }
  };

  // Check if connected wallet is linked to another account
  useEffect(() => {
    if (
      isWagmiConnected &&
      !currentUserInfo?.ethAddress &&
      wagmiAddress !== currentUserInfo?.ethAddress
    ) {
      checkWalletExists();
    }
  }, [isWagmiConnected, wagmiAddress]);

  useEffect(() => {
    setPageLoading(true);

    verifySigninLink();

    setPageLoading(false);
  }, []);

  return (
    <>
      <BarLoader
        loading={isEmailUpdating || pageLoading || dataSaving}
        cssOverride={{
          width: "100%",
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          zIndex: "1031",
          backgroundColor: "#fff",
          opacity: "1",
        }}
      />
      <TopNavbar />
      <ReactNotifications />
      <div
        className="bg-transparent"
        style={{
          height: "100%",
          width: "100%",
          marginBottom: "0px",
          opacity: "0.3",
        }}
      >
        <img alt="..." className="bg-image" src={bgAbstractLine03} />
      </div>
      <section className="section section-shaped section-main">
        <Container>
          <Row className="justify-content-center">
            <Col xl="9" lg="10" md="11" sm="12" xs="12">
              <h3 className="mb-3">
                <strong>{isNewUser ? "Create Profile" : "Settings"}</strong>
              </h3>
              <Form
                onSubmit={() => {}}
                validate={async (values) => {
                  const errors = {};

                  if (!name) {
                    errors.name = "Name is required.";
                  }

                  if (name.length > 40) {
                    errors.name = "Name must not exceed 40 characters.";
                  }

                  if (bio.length > 240) {
                    errors.bio = "Bio must not exceed 240 characters.";
                  }

                  if (!email) {
                    errors.email = "Email is required.";
                  } else if (!validEmail(email)) {
                    errors.email = "Email must have valid format.";
                  } else if (email.length > 100) {
                    errors.email = "Email must not exceed 100 characters.";
                  }

                  return errors;
                }}
                render={({
                  handleSubmit,
                  values,
                  submitting,
                  validating,
                  valid,
                }) => (
                  <div className="m-2">
                    <form onSubmit={handleSubmit}>
                      <Row className="mt-4 mb-4">
                        <Col>
                          <div className="my-4">
                            <FormGroup>
                              <Field name="image">
                                {({ input, meta }) => (
                                  <>
                                    <Label>
                                      <div>
                                        <strong>Profile Image</strong>
                                        <small>
                                          <FontAwesomeIcon
                                            icon="fa-circle-info"
                                            className="text-muted ml-1"
                                            id="profileImageTooltip"
                                            style={{ cursor: "pointer" }}
                                          />
                                        </small>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="profileImageTooltip"
                                          trigger="hover"
                                        >
                                          Select image to upload
                                        </UncontrolledTooltip>
                                      </div>
                                      <small className="text-muted">
                                        Recommend 360x360 1MB
                                      </small>
                                    </Label>
                                    <Label
                                      className="ml-3 image-upload-label"
                                      for="profileUpload"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <span className="rounded-circle">
                                        {imageUrl ? (
                                          <img
                                            alt="..."
                                            className="rounded-circle profile-image-edit"
                                            src={imageUrl}
                                          />
                                        ) : ensAvatar ? (
                                          <img
                                            src={ensAvatar}
                                            alt="ENS Avatar"
                                            className="rounded-circle profile-image-edit"
                                          />
                                        ) : (
                                          <Avatar
                                            size={100}
                                            name={currentUserInfo?.ethAddress}
                                            square="false"
                                            variant="ring"
                                            colors={[
                                              "#000000",
                                              "#2F2F2F",
                                              "#505050",
                                              "#797979",
                                              "#CECECE",
                                            ]}
                                            className="profile-image-edit"
                                          />
                                        )}
                                      </span>

                                      <div className="image-overlay">
                                        <FontAwesomeIcon
                                          icon="fa-pencil"
                                          className="text-muted hidden-icon"
                                        />
                                      </div>
                                    </Label>
                                    <Input
                                      {...input}
                                      id="profileUpload"
                                      name="file"
                                      type="file"
                                      accept="image/*"
                                      style={{ display: "none" }}
                                      onChange={(e) => {
                                        selectImage(e);
                                        setFormChanged(true);
                                      }}
                                    />
                                  </>
                                )}
                              </Field>
                            </FormGroup>
                          </div>

                          <div className="my-4">
                            <FormGroup>
                              <Field name="bannerImage">
                                {({ input, meta }) => (
                                  <>
                                    <Label>
                                      <div>
                                        <strong>Banner Image</strong>
                                        <small>
                                          <FontAwesomeIcon
                                            icon="fa-circle-info"
                                            className="text-muted ml-1"
                                            id="bannerImageTooltip"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          />
                                        </small>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="bannerImageTooltip"
                                          trigger="hover"
                                        >
                                          Show on top of your profile page
                                        </UncontrolledTooltip>
                                      </div>
                                      <small className="text-muted">
                                        Recommend 1400x400 1MB
                                      </small>
                                    </Label>
                                    <Label
                                      className="ml-3 image-upload-label"
                                      for="bannerUpload"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img
                                        alt="..."
                                        className="banner-image-edit"
                                        src={
                                          bannerImageUrl || defaultFormBanner
                                        }
                                      />
                                      <div className="image-overlay">
                                        <FontAwesomeIcon
                                          icon="fa-pencil"
                                          className="text-muted hidden-icon"
                                        />
                                      </div>
                                    </Label>
                                    <Input
                                      {...input}
                                      id="bannerUpload"
                                      name="file"
                                      type="file"
                                      accept="image/*"
                                      style={{ display: "none" }}
                                      onChange={(e) => {
                                        selectBannerImage(e);
                                        setFormChanged(true);
                                      }}
                                    />

                                    {meta.error && meta.touched && (
                                      <small className="text-danger">
                                        <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                        {meta.error}
                                      </small>
                                    )}
                                  </>
                                )}
                              </Field>
                            </FormGroup>
                          </div>

                          <div className="my-4">
                            <FormGroup>
                              <Field name="name">
                                {({ input, meta }) => (
                                  <>
                                    <Label for="name" className="required">
                                      <strong>Name</strong>
                                    </Label>
                                    <Input
                                      {...input}
                                      id="name"
                                      value={name}
                                      onChange={(e) => {
                                        setName(e.target.value);
                                        setFormChanged(true);
                                      }}
                                      placeholder={name || "Name"}
                                      type="text"
                                    />

                                    {meta.error && meta.touched && (
                                      <small className="text-danger">
                                        <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                        {meta.error}
                                      </small>
                                    )}
                                  </>
                                )}
                              </Field>
                            </FormGroup>
                          </div>
                          <div className="my-4">
                            <FormGroup>
                              <Field name="bio">
                                {({ input, meta }) => (
                                  <>
                                    <Label for="bio">
                                      <strong>Bio</strong>
                                    </Label>
                                    <Input
                                      {...input}
                                      id="bio"
                                      value={bio}
                                      onChange={(e) => {
                                        setBio(e.target.value);
                                        setFormChanged(true);
                                      }}
                                      placeholder={
                                        bio || "Share your story ..."
                                      }
                                      rows="4"
                                      maxLength="140"
                                      type="textarea"
                                    />

                                    {meta.error && meta.touched && (
                                      <small className="text-danger">
                                        <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                        {meta.error}
                                      </small>
                                    )}
                                  </>
                                )}
                              </Field>
                            </FormGroup>
                          </div>
                          <div className="my-4">
                            <FormGroup>
                              <Field name="links">
                                {({ input, meta }) => (
                                  <>
                                    <Label for="links">
                                      <div>
                                        <strong>Links</strong>
                                      </div>
                                      <small className="text-muted">
                                        You may add up to 5 links
                                      </small>
                                    </Label>

                                    <SocialLinksEditable
                                      links={currentUserInfo?.links}
                                      updateLinks={setLinks}
                                      updateFormChanged={setFormChanged}
                                    />
                                  </>
                                )}
                              </Field>
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                      <hr />
                      <Row className="mt-3">
                        <Col>
                          <div className="mt-3">
                            <strong className="mr-2">Linked Accounts</strong>
                          </div>
                          <Alert className="my-3 bg-secondary px-3">
                            {!currentUserInfo?.ethAddress && (
                              <Alert className="bg-gray">
                                <span className="text-white">
                                  You must connect and sign in with web3 wallet{" "}
                                </span>
                              </Alert>
                            )}
                            <Row>
                              <Col>
                                <div>
                                  <strong className="text-dark required mr-2">
                                    ETH Wallet
                                  </strong>
                                </div>
                                <small className="text-muted">
                                  Link your ETH wallet
                                </small>
                              </Col>
                              <Col lg="7" md="8" sm="9">
                                {currentUserInfo?.ethAddress ? (
                                  <>
                                    <Button
                                      id="walletAddressButton"
                                      className="float-right my-2"
                                      color="dark"
                                      type="button"
                                      style={{ textTransform: "none" }}
                                      onClick={() =>
                                        navigator.clipboard.writeText(
                                          currentUserInfo?.ethAddress
                                        )
                                      }
                                    >
                                      <img
                                        className="icon-eth text-gradient mr-2"
                                        alt="..."
                                        src={ethWhiteImg}
                                      />
                                      <strong className="wallet-field text-gradient">
                                        {truncateAddressShort(
                                          currentUserInfo?.ethAddress
                                        )}
                                        <FontAwesomeIcon
                                          icon="fa-clone"
                                          className="ml-1 text-gradient"
                                        />
                                      </strong>
                                    </Button>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="walletAddressButton"
                                      trigger="focus"
                                    >
                                      Address Copied
                                    </UncontrolledTooltip>
                                  </>
                                ) : (
                                  <>
                                    {isWagmiConnected && (
                                      <div className="mt-2 mb-3 bg-secondary">
                                        <Row>
                                          <Col className="col-1">
                                            <span className="rounded-circle">
                                              <Avatar
                                                size={40}
                                                name={wagmiAddress}
                                                square="false"
                                                variant="ring"
                                                colors={[
                                                  "#000000",
                                                  "#2F2F2F",
                                                  "#505050",
                                                  "#797979",
                                                  "#CECECE",
                                                ]}
                                                className="float-left"
                                              />
                                            </span>
                                          </Col>
                                          <Col className="col-7">
                                            <span className="text-dark ml-3 mb-0">
                                              {ensName
                                                ? `${ensName} (${truncateAddressShort(
                                                    wagmiAddress
                                                  )})`
                                                : truncateAddressShort(
                                                    wagmiAddress
                                                  )}
                                            </span>
                                            <div className="ml-3 mt-0">
                                              <small className="text-muted">
                                                Connected to{" "}
                                                {wagmiConnector?.name}
                                              </small>
                                            </div>
                                          </Col>
                                          <Col className="col-3">
                                            <Button
                                              outline
                                              color="warning"
                                              size="sm"
                                              onClick={wagmiDisconnect}
                                              className="btn-icon float-right"
                                            >
                                              <small>Disconnect</small>
                                            </Button>
                                          </Col>
                                        </Row>
                                      </div>
                                    )}
                                    <div className="btn-wrapper text-center mb-3">
                                      {isWagmiConnected ? (
                                        <>
                                          <Button
                                            className="btn-icon mt-2 mb-2"
                                            type="button"
                                            color="dark"
                                            block
                                            onClick={linkEthAddress}
                                            disabled={
                                              isWalletSigning || !!walletError
                                            }
                                          >
                                            <span className="btn-inner--icon mr-1">
                                              <img alt="..." src={pidImg} />
                                            </span>
                                            <span className="btn-inner--text">
                                              Safe Sign in
                                            </span>

                                            <span className="ml-1">
                                              <ScaleLoader
                                                color="#eee"
                                                loading={isWalletSigning}
                                                height="10px"
                                                cssOverride={{
                                                  display: "inline",
                                                }}
                                              />
                                            </span>
                                          </Button>
                                        </>
                                      ) : (
                                        wagmiConnectors?.map((connector) => {
                                          return !connector.ready &&
                                            connector.name === "MetaMask" ? (
                                            <Button
                                              block
                                              className="btn-dark btn-icon mt-2 mb-2"
                                              key={connector.id}
                                              disabled={isWagmiLoading}
                                              href="https://metamask.io/"
                                              target="_blank"
                                            >
                                              <span className="btn-inner--icon mr-2">
                                                <img alt="..." src={mmImg} />
                                              </span>
                                              Install MetaMask
                                            </Button>
                                          ) : (
                                            <Button
                                              block
                                              className="btn-dark btn-icon mt-2 mb-2"
                                              type="button"
                                              disabled={isWagmiLoading}
                                              key={connector.id}
                                              onClick={() =>
                                                wagmiConnect({
                                                  connector,
                                                })
                                              }
                                            >
                                              <span className="btn-inner--icon mr-2">
                                                <img
                                                  alt="..."
                                                  src={
                                                    connector.name ===
                                                    "MetaMask"
                                                      ? mmImg
                                                      : connector.name ===
                                                        "WalletConnect"
                                                      ? wcImg
                                                      : pidImg
                                                  }
                                                />
                                              </span>
                                              {connector.name}
                                              {!connector.ready &&
                                                connector.name !== "MetaMask" &&
                                                " (unsupported)"}
                                              <span className="ml-1">
                                                <ScaleLoader
                                                  color="#eee"
                                                  loading={
                                                    isWagmiLoading &&
                                                    connector.id ===
                                                      pendingWagmiConnector?.id
                                                  }
                                                  height="10px"
                                                  cssOverride={{
                                                    display: "inline",
                                                  }}
                                                />
                                              </span>
                                            </Button>
                                          );
                                        })
                                      )}

                                      {(wagmiError ||
                                        (walletError && isWagmiConnected)) && (
                                        <small className="text-danger mt-1">
                                          <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                          {wagmiError?.message || walletError}
                                        </small>
                                      )}
                                    </div>
                                  </>
                                )}
                              </Col>
                            </Row>
                          </Alert>

                          <Alert className="my-3 bg-secondary px-3">
                            {ReactSession.get("newEmail") ? (
                              <Alert className="bg-success">
                                <span className="text-white">
                                  We've sent you a link to verify{" "}
                                  <u>{ReactSession.get("newEmail")}</u>
                                </span>

                                <Button
                                  color="white"
                                  size="sm"
                                  onClick={() => {
                                    setNewEmail();
                                    setEmail(currentUser?.email || "");
                                    ReactSession.remove("newEmail");
                                    setEmailError();
                                  }}
                                  className="float-right my-1"
                                >
                                  Cancel
                                </Button>
                              </Alert>
                            ) : (
                              newEmail && (
                                <Alert className="bg-gray">
                                  <span className="text-white">
                                    Email must be unique and only linked to one
                                    account.
                                  </span>
                                  <Button
                                    color="white"
                                    size="sm"
                                    onClick={() => {
                                      setNewEmail();
                                      setEmail(currentUser?.email || "");
                                      setEmailError();
                                    }}
                                    className="float-right my-1"
                                  >
                                    Cancel
                                  </Button>
                                </Alert>
                              )
                            )}
                            <Row>
                              <Col>
                                <div>
                                  <strong className="text-dark required">
                                    Email
                                  </strong>
                                </div>

                                <small className="text-muted">
                                  Link your email address
                                </small>
                              </Col>
                              <Col lg="7" md="8" sm="9">
                                <FormGroup className="mt-2">
                                  <Field name="email">
                                    {({ input, meta }) => (
                                      <>
                                        <InputGroup>
                                          <Input
                                            {...input}
                                            id="email"
                                            value={email}
                                            onChange={(e) => {
                                              setEmail(e.target.value);
                                              setEmailError();
                                            }}
                                            placeholder={
                                              email || "youremail@example.com"
                                            }
                                            type="text"
                                            disabled={
                                              !newEmail && !!currentUser?.email
                                            }
                                          />
                                          {!!newEmail || !currentUser?.email ? (
                                            <>
                                              <Button
                                                color="dark"
                                                onClick={linkEmailAccount}
                                                className="float-right"
                                                disabled={
                                                  !email ||
                                                  currentUser?.email ===
                                                    email ||
                                                  !!meta.error
                                                }
                                              >
                                                {!isEmailUpdating ? (
                                                  "Verify"
                                                ) : (
                                                  <ScaleLoader
                                                    color="#eee"
                                                    loading={isEmailUpdating}
                                                    height="10px"
                                                    cssOverride={{
                                                      display: "inline",
                                                    }}
                                                  />
                                                )}
                                              </Button>
                                            </>
                                          ) : (
                                            <Button
                                              color="dark"
                                              onClick={() => {
                                                setNewEmail(email);
                                              }}
                                              className="float-right"
                                            >
                                              Change
                                            </Button>
                                          )}
                                        </InputGroup>

                                        {((meta.error && meta.touched) ||
                                          emailError) &&
                                          (newEmail ||
                                            currentUser?.email !== email) && (
                                            <small className="text-danger">
                                              <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                              {meta.error || emailError}
                                            </small>
                                          )}
                                      </>
                                    )}
                                  </Field>
                                </FormGroup>
                              </Col>
                            </Row>
                          </Alert>
                        </Col>
                      </Row>

                      <div className="mt-4 mb-3">
                        <Button
                          className="btn btn-dark mt-4 btn-block"
                          color="dark"
                          type="button"
                          onClick={saveProfile}
                          disabled={!valid || dataSaving || !formChanged}
                        >
                          Save Settings{" "}
                          {dataSaving && (
                            <ScaleLoader
                              color="#eee"
                              loading={dataSaving}
                              height="10px"
                              cssOverride={{
                                display: "inline",
                              }}
                            />
                          )}
                        </Button>
                      </div>
                    </form>
                  </div>
                )}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Settings;
