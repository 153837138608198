import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { AuthContext } from "Auth.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from "react-moment";
import moment from "moment-timezone";
import Select from "react-select";

import Autosuggest from "react-autosuggest";

// import { RandomHash } from "random-hash";
import _ from "lodash";

import GooglePlaceAutocomplete from "react-google-autocomplete";

// reactstrap components
import {
  Alert,
  Button,
  Card,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  InputGroupText,
  InputGroup,
  Label,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
  Toast,
  ToastHeader,
  ToastBody,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import ReactDatetime from "react-datetime";

// core components
import TopNavbar from "components/Navbars/TopNavbar.js";
import Loader from "components/Loaders/Loader.js";

import ScaleLoader from "react-spinners/ScaleLoader";
import BarLoader from "react-spinners/BarLoader";

import MarkdownIt from "markdown-it";
// import MdEditor from "react-markdown-editor-lite";
// import "react-markdown-editor-lite/lib/index.css";

import { Form, Field } from "react-final-form";

import {
  truncateAddressShort,
  truncateText,
  notify,
  validUrlSlug,
} from "utils/Utils.js";

import { createSiweMessage, eventExists } from "utils/Api.js";

import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.compat.css";

import Web3 from "web3";

import ReCAPTCHA from "react-google-recaptcha";

const { REACT_APP_FBASE_API_KEY, REACT_APP_GOODLE_RECAPTCHA_CHECKBOX_KEY } =
  process.env;

const bgImg = require("assets/img/bg/abstract-line-03.svg");
const defaultImg = require("assets/img/forms/image.svg");
const defaultBanner = require("assets/img/forms/banner.svg");
const defaultPassImg = require("assets/img/default/rpbox.svg");

const {
  logoBlackIcon,
  ethImg,
  ethWhiteImg,
  polygonImg,
  polygonWhiteImg,
  ChainOptions,
  ChainSelectorOptions,
  EventCategoryOptions,
  EventCategorySelectorOptions,
  USDateOption,
  USDateTimeOption,
  UserRoleTypes,
  CreatorRoleTypes,
  UserRoleStatus,
  VerifiedUserTypes,
  VerifiedUserStatus,
  DisclaimerShort,
} = require("utils/Variables.js");

export default function EventUpdate() {
  const navigate = useNavigate();

  const { currentUser, currentAuthToken, currentUserInfo, setCurrentUserInfo } =
    useContext(AuthContext);

  const hasCreatorRole = currentUserInfo?.hasCreatorRole;
  const isVerifiedUser = currentUserInfo?.isVerifiedUser;

  const { eventId } = useParams();

  const [pageLoading, setPageLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  const [eventImage, setEventImage] = useState("");
  const [eventImageData, setEventImageData] = useState();
  const [eventBannerImage, setEventBannerImage] = useState("");
  const [eventBannerImageData, setEventBannerImageData] = useState();

  const [eventName, setEventName] = useState("");
  const [eventDescription, setEventDescription] = useState("");

  const [currentEventUrl, setCurrentEventUrl] = useState("");
  const [eventUrl, setEventUrl] = useState("");

  const [blockchain, setBlockchain] = useState("");
  const [blockchainOption, setBlockchainOption] = useState(
    ChainSelectorOptions[0]
  );

  const [isVirtual, setIsVirtual] = useState(false);
  const [eventLocation, setEventLocation] = useState({ name: "" });

  const [eventCategory, setEventCategory] = useState("");
  const [eventCategoryOption, setEventCategoryOption] = useState(
    EventCategorySelectorOptions[0]
  );

  const [startDateMoment, setStartDateMoment] = useState();
  const [endDateMoment, setEndDateMoment] = useState();
  const [eventTba, setEventTba] = useState(false);

  const [eventMaxParticipants, setEventMaxParticipants] = useState("∞");
  const [unlimitedParticipants, setUnlimitedParticipants] = useState(true);
  const [totalReg, setTotalReg] = useState(0);

  // const [isRaffle, setIsRaffle] = useState(false);
  // const [winners, setWinners] = useState(1);

  const [requirePass, setRequirePass] = useState(true);
  const [registerEnabled, setRegisterEnabled] = useState(false);

  const [selectedPass, setSelectedPass] = useState();
  const [requireUniquePassId, setRequireUniquePassId] = useState(true);

  const [passInput, setPassInput] = useState("");
  const [passInputChanged, setPassInputChanged] = useState(false);
  const [passSuggestions, setPassSuggestions] = useState([]);

  const [eventWebsite, setEventWebsite] = useState("");
  const [eventVideoUrl, setEventVideoUrl] = useState("");
  const [eventTwitter, setEventTwitter] = useState("");
  const [eventDiscord, setEventDiscord] = useState("");
  const [eventInstagram, setEventInstagram] = useState("");

  const [isPublic, setIsPublic] = useState(true);
  const [isNSFW, setIsNSFW] = useState(false);

  const [acceptTerms, setAcceptTerms] = useState(false);

  const [recaptcha, setRecaptcha] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const [locationError, setLocationError] = useState();
  const [passError, setPassError] = useState();

  const [openedCollapse, setOpenedCollapse] = useState("collapse-1");

  const mdParser = new MarkdownIt();

  /************** Pass Selector Below **************/
  // Disable Input when Max Contracts are selected
  const renderPassInput = (input, inputProps) => (
    <input
      {...inputProps}
      disabled={selectedPass || totalReg > 0}
      placeholder={
        selectedPass
          ? "You can only set one Pass Contract."
          : "Smart contract 0x12345..."
      }
    />
  );

  // Pass thumbnail to render
  const showPass = selectedPass && (
    <Col className="m-3">
      <Toast className="bg-secondary">
        <Row className="toast-header">
          <Col>
            <FontAwesomeIcon icon="fa-ticket" className="mr-2 text-primary" />
            <strong className="text-primary">
              Require {selectedPass?.contract_type} Pass
            </strong>
          </Col>
          <Col className="justify-content-right text-right col-1">
            {totalReg <= 0 && (
              <button
                type="button"
                className="ml-2 close text-white"
                data-dismiss="toast"
                aria-label="Close"
                color="white"
                onClick={() => {
                  setSelectedPass();
                  setFormChanged(true);
                }}
              >
                <span aria-hidden="true" className="">
                  <FontAwesomeIcon icon="fa-xmark" />
                </span>
              </button>
            )}
          </Col>
        </Row>
        <ToastBody className="bg-secondary">
          <div className="ml-5 mt-2">
            <div>
              <Input
                className="custom-control-input"
                id="requireUniqueIdCheck"
                type="checkbox"
                checked={requireUniquePassId}
                onChange={() => {
                  setRequireUniquePassId(!requireUniquePassId);
                  setFormChanged(true);
                }}
                disabled={totalReg > 0}
              />
              <Label
                className="custom-control-label"
                htmlFor="requireUniqueIdCheck"
              >
                <strong>Require Unique Token ID</strong>
              </Label>
            </div>
          </div>

          <div className="mx-4 mt-4 mb-3">
            <Button
              color="primary"
              href={
                (blockchainOption.value === "eth" &&
                  `https://etherscan.io/address/${selectedPass?.token_address}`) ||
                (blockchainOption.value === "matic" &&
                  `https://polygonscan.com/address/${selectedPass?.token_address}`)
              }
              rel="external nofollow noopener"
              target="_blank"
            >
              <div className="text-left">
                {truncateText(selectedPass?.name, 20)}
              </div>
              <div className="text-left">
                <img
                  alt="..."
                  style={{ width: "100%" }}
                  className="mt-2 mb-2 rounded"
                  src={
                    JSON.parse(selectedPass?.metadata)?.image?.replace(
                      "ipfs://",
                      "https://gateway.moralisipfs.com/ipfs/"
                    ) || defaultPassImg
                  }
                />
              </div>
              <div className="text-left">
                {blockchainOption.value === "eth" && (
                  <img src={ethWhiteImg} className="icon-eth mr-2" />
                )}
                {blockchainOption.value === "matic" && (
                  <img src={polygonWhiteImg} className="icon-polygon mr-2" />
                )}
                <>
                  <small>{selectedPass?.contract_type} </small>
                  <span className="text-info">
                    {truncateAddressShort(selectedPass?.token_address)}
                  </span>{" "}
                  <FontAwesomeIcon
                    icon="arrow-up-right-from-square"
                    className="ml-1"
                  />
                </>
              </div>
              <div className="text-left">
                <small className="text-warning text-capitalize">
                  *{" "}
                  {requireUniquePassId
                    ? "Require unique Token ID to join"
                    : "Token ID can be reused"}
                </small>
              </div>
            </Button>
          </div>
        </ToastBody>
      </Toast>
    </Col>
  );

  // Fetch Metadata by Contract Address
  const getPassSuggestions = async (input) => {
    // const isAddress = web3.utils.isAddress(input.toLowerCase());
    // if (isAddress) {
    //   const address = web3.utils
    //     .toChecksumAddress(input.toLowerCase())
    //     .toLowerCase();
    //   // TODO: remove rinkeby
    //   const options = {
    //     address: address,
    //     // chain: blockchainOption.value,
    //     chain: blockchainOption.value === "eth" ? "rinkeby" : "mumbai",
    //     limit: 1,
    //   };
    //   let nfts = [];
    // try {
    //   setSearchLoading(true);
    // const res = await Moralis.Web3API.token.getAllTokenIds(options);
    // if (res && res.result && res.result.length > 0) {
    //   if (res.result[0].contract_type === "ERC721") {
    //     nfts = res.result;
    //     setPassError();
    //   } else {
    //     setPassError("Smart contract is not ERC721.");
    //   }
    // } else {
    //   setPassError("Cannot find the smart contract.");
    // }
    // } catch (err) {
    //   console.error(err.message);
    //   setPassError("Unable to get the smart contract data.");
    // } finally {
    //   setSearchLoading(false);
    // }
    // return nfts;
    // } else {
    //   setPassError("Pass Contract must be a valid address.");
    //   return [];
    // }
  };

  // Get suggest value and set on the input
  const getPassSuggestionValue = (suggestion) => suggestion.name;

  // Render pass suggestion result dropdown
  const renderPassSuggestion = (suggestion) => (
    <div>
      <span style={{ display: "block", textAlign: "left" }}>
        {suggestion?.name}
      </span>
      <small style={{ display: "block", textAlign: "left" }}>
        {suggestion?.token_address}
      </small>
    </div>
  );

  // When Contract Input Change
  const onPassInputChange = (event, { newValue }) => {
    setPassInput(newValue);
    setPassInputChanged(true);
  };

  // Set the Pass Suggestion after getting the metadata
  const onPassSuggestionsFetch = async ({ value }) => {
    const data = await getPassSuggestions(value);
    if (data) {
      setPassSuggestions(data);
    } else {
      setPassSuggestions([]);
    }
  };

  // Set the pass thumbnail after selecting the suggestion
  const onPassSelected = (event, { suggestion }) => {
    setSelectedPass(suggestion);
    setRequireUniquePassId(true);
    setFormChanged(true);
  };

  // Reset the input
  const onPassSuggestionsClear = () => {
    setPassInput("");
    setPassSuggestions([]);
  };

  // Input Props
  const passInputProps = {
    placeholder: "Smart contract 0x12345...",
    value: passInput,
    onChange: onPassInputChange,
  };
  /************** Pass Selector Above **************/

  // Upload to IPFS during event updating
  const uploadEventImage = async (e) => {
    const data = e.target.files[0];
    setEventImage(URL.createObjectURL(data));
    setEventImageData(data);
  };

  // Upload to IPFS during event updating
  const uploadBannerImage = async (e) => {
    const data = e.target.files[0];
    setEventBannerImage(URL.createObjectURL(data));
    setEventBannerImageData(data);
  };

  const saveEvent = async () => {
    try {
      setPageLoading(true);

      //   if (eventImageData) {
      //     const eventImageFile = new Moralis.File(
      //       eventName.replace(/[^a-zA-Z0-9 ]/g, ""),
      //       eventImageData
      //     );
      //     await eventImageFile.saveIPFS();
      //     nfevent.set("eventImage", eventImageFile);
      //   }

      //   if (eventBannerImageData) {
      //     const bannerImageFile = new Moralis.File(
      //       eventName.replace(/[^a-zA-Z0-9 ]/g, ""),
      //       eventBannerImageData
      //     );
      //     await bannerImageFile.saveIPFS();
      //     nfevent.set("bannerImage", bannerImageFile);
      //   }

      //   nfevent.set("name", eventName);
      //   nfevent.set("description", eventDescription);
      //   nfevent.set("blockchain", blockchainOption.value);

      //   if (!startDateMoment) {
      //     nfevent.unset("startDateTime");
      //   } else {
      //     nfevent.set("startDateTime", startDateMoment?.toDate());
      //   }

      //   if (!endDateMoment) {
      //     nfevent.unset("endDateTime");
      //   } else {
      //     nfevent.set("endDateTime", endDateMoment?.toDate());
      //   }

      //   nfevent.set("isVirtual", isVirtual);
      //   nfevent.set("location", eventLocation);
      //   nfevent.set(
      //     "maxParticipants",
      //     eventMaxParticipants === "∞" ? -1 : Number(eventMaxParticipants)
      //   );

      //   if (selectedPass?.token_address) {
      //     nfevent.set("passContract", selectedPass?.token_address);
      //     nfevent.set("requireUniquePassId", requireUniquePassId);
      //   } else {
      //     nfevent.unset("passContract");
      //     nfevent.unset("requireUniquePassId");
      //   }

      //   nfevent.set("category", eventCategoryOption.value);
      //   nfevent.set("onChain", false);
      //   // nfevent.set("eventContract", eventContract); //TODO: may be able to create on chain events
      //   nfevent.set("registerEnabled", registerEnabled);
      //   nfevent.set("isPublic", isPublic);
      //   nfevent.set("isNSFW", isNSFW);
      //   nfevent.set("website", eventWebsite);
      //   nfevent.set("videoUrl", eventVideoUrl);
      //   nfevent.set("twitter", eventTwitter);
      //   nfevent.set("discord", eventDiscord);
      //   nfevent.set("instagram", eventInstagram);

      //   // Serverside recaptcha validation via context
      //   const context = { recaptcha: recaptchaToken };
      //   const res = await nfevent.save(null, { context: context });

      //   setPageLoading(false);
      //   setFormChanged(false);

      //   notify("Event has been updated.", "success");
      //   setTimeout(() => navigate(`/event/${eventId}`), 3000);
    } catch (err) {
      console.error(err.message);
      setPageLoading(false);
      setFormChanged(false);
      notify("Event update failed.", "error");
    }
  };

  const fetchEvent = async () => {
    try {
      setPageLoading(true);

      //   eventQuery.equalTo("objectId", eventId);
      //   eventQuery.equalTo("creatorWallet", creatorWallet);
      //   nfevent = await eventQuery.first();

      //   if (!nfevent) {
      //     navigate(`/event/${eventId}`);
      //   }

      //   const name = nfevent.get("name");
      //   const description = nfevent.get("description");

      //   let chain = nfevent.get("blockchain");

      //   const category = nfevent.get("category");

      //   const contract = nfevent.get("passContract");
      //   const uniqueId = nfevent.get("requireUniquePassId");

      //   const maxParticipants = nfevent.get("maxParticipants");
      //   const image = nfevent.get("eventImage");
      //   const bannerImage = nfevent.get("bannerImage");
      //   const start = nfevent.get("startDateTime");
      //   const end = nfevent.get("endDateTime");

      //   const virtual = nfevent.get("isVirtual");
      //   const location = nfevent.get("location");

      //   const regEnabled = nfevent.get("registerEnabled");

      //   const website = nfevent.get("website");
      //   const videoUrl = nfevent.get("videoUrl");
      //   const twitter = nfevent.get("twitter");
      //   const discord = nfevent.get("discord");
      //   const instagram = nfevent.get("instagram");

      //   const publicFlag = nfevent.get("isPublic");
      //   const nsfw = nfevent.get("isNSFW");

      //   setEventName(name);
      //   setEventDescription(description || "");

      //   setBlockchain(chain);
      //   setBlockchainOption(
      //     ChainSelectorOptions.find((option) => option.value === chain)
      //   );

      //   setEventCategory(category);
      //   setEventCategoryOption(
      //     EventCategorySelectorOptions.find((option) => option.value === category)
      //   );

      //   setEventImage(image?.url());
      //   setEventBannerImage(bannerImage?.url());

      //   setEventMaxParticipants(
      //     maxParticipants < 0 ? "∞" : !maxParticipants ? 0 : maxParticipants
      //   );
      //   setUnlimitedParticipants(maxParticipants < 0);

      //   setEventTba(!start);
      //   setStartDateMoment(start && moment(start));
      //   setEndDateMoment(end && moment(end));

      //   setIsVirtual(!!virtual);
      //   setEventLocation(location ? location : { name: "" });

      //   setRegisterEnabled(!!regEnabled);

      //   setEventWebsite(website || "");
      //   setEventVideoUrl(videoUrl || "");
      //   setEventTwitter(twitter || "");
      //   setEventDiscord(discord || "");
      //   setEventInstagram(instagram || "");

      //   setIsPublic(!!publicFlag);
      //   setIsNSFW(!!nsfw);

      //   await fetchTotalParticipants();

      //   if (contract) {
      //     setRequirePass(true);

      //     // TODO: Remove Rinkeby  on prod
      //     chain = chain === "eth" ? "rinkeby" : "mumbai";

      //     const options = {
      //       chain: chain,
      //       address: contract,
      //       limit: 1,
      //     };

      //     try {
      //       setSearchLoading(true);
      //       const res = await Moralis.Web3API.token.getAllTokenIds(options);

      //       setSearchLoading(false);

      //       if (res && res.result && res.result.length > 0) {
      //         if (res.result[0].contract_type === "ERC721") {
      //           const nft = res.result[0];
      //           setSelectedPass(nft);
      //           setRequireUniquePassId(uniqueId);
      //           setPassError();
      //         } else {
      //           setPassError("Smart contract is not ERC721.");
      //         }
      //       } else {
      //         setPassError("Cannot find the smart contract.");
      //       }
      //     } catch (err) {
      //       console.error(err.message);
      //       setSearchLoading(false);
      //       setPassError("Unable to get the smart contract data.");
      //     }
      //   } else {
      //     setRequirePass(false);
      //   }
    } catch (err) {
      setPageLoading(false);
      console.error(err.message);
      notify("Unable to fetch event data.", "error");
    } finally {
      setPageLoading(false);
    }
  };

  const fetchTotalParticipants = async () => {
    try {
      //   const res = await Moralis.Cloud.run("totalParticipants", {
      //     eventId: eventId,
      //     validOnly: true,
      //   });
      //   if (!isNaN(res)) {
      //     setTotalReg(res);
      //     return res;
      //   } else {
      //     setTotalReg(0);
      //     return 0;
      //   }
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleReactDatetimeChange = (which, date) => {
    if (
      startDateMoment &&
      which === "endDate" &&
      new Date(startDateMoment?._d + "") > new Date(date?._d + "")
    ) {
      setStartDateMoment(date);
      setEndDateMoment(date);
    } else if (
      endDateMoment &&
      which === "startDate" &&
      new Date(endDateMoment?._d + "") < new Date(date?._d + "")
    ) {
      setStartDateMoment(date);
      setEndDateMoment(date);
    } else {
      if (which === "startDate") setStartDateMoment(date);
      if (which === "endDate") setEndDateMoment(date);
    }
  };

  const getClassNameReactDatetimeDays = (date) => {
    if (startDateMoment && endDateMoment) {
    }
    if (
      startDateMoment &&
      endDateMoment &&
      startDateMoment?._d + "" !== endDateMoment?._d + ""
    ) {
      if (
        new Date(endDateMoment?._d + "") > new Date(date?._d + "") &&
        new Date(startDateMoment?._d + "") < new Date(date?._d + "")
      ) {
        return " middle-date";
      }
      if (endDateMoment?._d + "" === date?._d + "") {
        return " end-date";
      }
      if (startDateMoment?._d + "" === date?._d + "") {
        return " start-date";
      }
    }
    return "";
  };

  const toggleTba = () => {
    setEventTba(!eventTba);
    handleReactDatetimeChange("startDate");
    handleReactDatetimeChange("endDate");
  };

  const toggleUnlimitedParticipants = () => {
    const unlimited = !unlimitedParticipants;
    setUnlimitedParticipants(unlimited);
    unlimited ? setEventMaxParticipants("∞") : setEventMaxParticipants(1);
  };

  const toggleLocation = () => {
    const virtualFlag = !isVirtual;
    setIsVirtual(virtualFlag);
    setEventLocation({ name: "" });
    setLocationError();
  };

  const toggleRequirePass = () => {
    const requirePassFlag = !requirePass;
    setRequirePass(requirePassFlag);
    setSelectedPass();
    setPassError();
  };

  const recaptchaChangeHandler = (value) => {
    setRecaptcha(true);
    setRecaptchaToken(value);
  };

  useEffect(() => {
    if (!hasCreatorRole) {
      navigate("/create");
    }

    fetchEvent();
  }, []);

  return (
    <>
      <BarLoader
        loading={pageLoading}
        cssOverride={{
          width: "100%",
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          zIndex: "1031",
          backgroundColor: "#fff",
          opacity: "1",
        }}
      />
      <TopNavbar />
      <ReactNotifications />
      {hasCreatorRole && (
        <>
          <div className="bg-transparent">
            <img alt="..." className="bg-image" src={bgImg} />
          </div>
          <section className="section section-shaped section-main">
            {/* <Loader loading={pageLoading} /> */}
            <Container>
              <Row className="justify-content-center">
                <Col xl="9" lg="10" md="11" sm="12" xs="12">
                  <Row>
                    <Col className="col-7">
                      <h3 className="mb-3">
                        <strong>Update Event</strong>
                      </h3>
                    </Col>
                    <Col className="col-5 text-right">
                      <UncontrolledDropdown className="mt-1">
                        <DropdownToggle
                          id="manageEventButton"
                          caret
                          size="sm"
                          outline
                          color="primary"
                          type="button"
                          className="pr-2"
                        >
                          <span className="ml-2 mr-1">
                            <FontAwesomeIcon
                              icon="fa-sliders"
                              className="mr-1"
                            />
                            <span className="d-none d-md-inline-block ml-1">
                              Manage
                            </span>
                          </span>
                        </DropdownToggle>
                        <DropdownMenu
                          className="full-dropdown-menu-right-sm"
                          style={{ margin: 0 }}
                        >
                          <DropdownItem href={`/event/${eventId}`}>
                            <FontAwesomeIcon
                              icon="fa-compass"
                              className="mr-1"
                            />
                            <span className="ml-1">Go to Event</span>
                          </DropdownItem>

                          <DropdownItem href={`/dashboard/event/${eventId}`}>
                            <FontAwesomeIcon
                              icon="fa-gauge-high"
                              className="mr-1"
                            />
                            <span className="ml-1">Event Dashboard</span>
                          </DropdownItem>

                          <DropdownItem href={`/event/checkin/${eventId}`}>
                            <FontAwesomeIcon
                              icon="fa-camera"
                              className="mr-1"
                            />
                            <span className="ml-1">Event Checkin</span>
                          </DropdownItem>

                          <DropdownItem href={`/access/${eventId}`}>
                            <FontAwesomeIcon
                              icon="fa-qrcode"
                              className="mr-1"
                            />
                            <span className="ml-1">Instant Access</span>
                          </DropdownItem>

                          <DropdownItem href={`/event/update/${eventId}`}>
                            <FontAwesomeIcon
                              icon="fa-wrench"
                              className="mr-1"
                            />
                            <span className="ml-1">Update Event</span>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Col>
                  </Row>
                  <Card className="card-main shadow">
                    <Form
                      onSubmit={saveEvent}
                      validate={async (values) => {
                        const errors = {};
                        const validateAddress = () => {
                          try {
                            if (!selectedPass) {
                              return false;
                            }
                            // const lowerAddress =
                            //   selectedPass.token_address.toLowerCase();
                            // const isAddress = web3.utils.isAddress(lowerAddress);

                            // return isAddress;
                          } catch (err) {
                            console.error("Invalid address", err.message);
                            return false;
                          }
                        };

                        if (!eventImage) {
                          errors.eventImage = "Event image is required.";
                        }

                        if (!eventName) {
                          errors.name = "Name is required.";
                        }

                        if (eventName.length > 40) {
                          errors.name = "Name must not exceed 40 characters.";
                        }

                        if (eventDescription.length > 3000) {
                          errors.description =
                            "Description must not exceed 3000 characters.";
                        }

                        if (!eventUrl || eventUrl?.length < 10) {
                          errors.eventUrl =
                            "Event URL must have at least 10 characters.";
                        }

                        if (eventUrl?.length > 70) {
                          errors.eventUrl =
                            "Event URL must not exceed 70 characters.";
                        }

                        if (eventUrl && !validUrlSlug(eventUrl)) {
                          errors.eventUrl =
                            "Event URL can only have nummbers, lowercase letters, or -.";
                        }

                        if (
                          currentEventUrl !== eventUrl &&
                          (await eventExists())
                        ) {
                          errors.eventUrl = "Event URL already exists.";
                        }

                        if (!blockchainOption.value) {
                          errors.blockchain = "Blockchain is required.";
                        }

                        if (!eventCategoryOption.value) {
                          errors.category = "Category is required.";
                        }

                        if (!eventTba && !startDateMoment) {
                          errors.eventDuration = "Event duration is required.";
                        }

                        if (!eventMaxParticipants) {
                          errors.participants =
                            "Participants number is required.";
                        }

                        if (
                          !unlimitedParticipants &&
                          (isNaN(Number(eventMaxParticipants)) ||
                            Number(eventMaxParticipants) < 0)
                        ) {
                          errors.participants =
                            "Participants number must be greater than or equal to zero.";
                        }

                        if (
                          !unlimitedParticipants &&
                          Number(eventMaxParticipants) < totalReg
                        ) {
                          errors.participants = `Participants number must not be less than ${totalReg} (total participants).`;
                        }

                        if (eventLocation?.name?.length > 120) {
                          errors.location =
                            "Location must not exceed 120 characters.";
                        }

                        if (locationError) {
                          errors.location = locationError;
                        }

                        if (requirePass && !validateAddress()) {
                          errors.passes =
                            "Pass Contract must be a valid address.";
                        }

                        if (eventWebsite?.length > 100) {
                          errors.links =
                            "Website must not exceed 100 characters.";
                        }

                        if (eventVideoUrl?.length > 100) {
                          errors.links =
                            "Video URL must not exceed 100 characters.";
                        }

                        if (eventTwitter?.length > 45) {
                          errors.links =
                            "Twitter handle must not exceed 45 characters.";
                        }

                        if (eventDiscord?.length > 45) {
                          errors.links =
                            "Discord handle must not exceed 45 characters.";
                        }

                        if (eventInstagram?.length > 45) {
                          errors.links =
                            "Instagram handle must not exceed 45 characters.";
                        }

                        if (passError) {
                          errors.passes = passError;
                        }

                        if (!acceptTerms) {
                          errors.acceptTerms =
                            "You must accept Terms and Privacy Policy to save events.";
                        }

                        return errors;
                      }}
                      render={({
                        handleSubmit,
                        values,
                        submitting,
                        validating,
                        valid,
                      }) => (
                        <div className="m-2">
                          <form onSubmit={handleSubmit}>
                            <Row className="mt-2 mb-4">
                              <Col>
                                <div className="mt-4 mb-4">
                                  <FormGroup>
                                    <Field name="eventImage">
                                      {({ input, meta }) => (
                                        <>
                                          <Label>
                                            <div className="required">
                                              <strong>Event Image</strong>
                                            </div>
                                            <small className="text-muted">
                                              Used for event navigation
                                              <br />
                                              Recommended 360 x 360 1MB
                                            </small>
                                          </Label>
                                          <Label
                                            className="ml-3 image-upload-label"
                                            for="eventUpload"
                                            style={{ cursor: "pointer" }}
                                          >
                                            <img
                                              alt="..."
                                              className="event-image-edit"
                                              src={eventImage || defaultImg}
                                            />
                                            <div className="image-overlay">
                                              <FontAwesomeIcon
                                                icon="fa-pencil"
                                                className="text-muted hidden-icon"
                                              />
                                            </div>
                                          </Label>
                                          <small>
                                            <FontAwesomeIcon
                                              icon="fa-circle-info"
                                              className="text-muted ml-1"
                                              id="eventImageTooltip"
                                              style={{ cursor: "pointer" }}
                                            />
                                          </small>
                                          <UncontrolledTooltip
                                            placement="top"
                                            target="eventImageTooltip"
                                            trigger="hover"
                                          >
                                            Event image to be stored in IPFS
                                          </UncontrolledTooltip>
                                          <Input
                                            {...input}
                                            id="eventUpload"
                                            name="file"
                                            type="file"
                                            accept="image/*"
                                            style={{ display: "none" }}
                                            onChange={(e) => {
                                              uploadEventImage(e);
                                              setFormChanged(true);
                                            }}
                                          />

                                          {meta.error && meta.touched && (
                                            <small className="text-danger">
                                              <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                              {meta.error}
                                            </small>
                                          )}
                                        </>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </div>

                                <div className="mt-4 mb-4">
                                  <FormGroup>
                                    <Field name="eventImage">
                                      {({ input, meta }) => (
                                        <>
                                          <Label>
                                            <div>
                                              <strong>Banner Image</strong>
                                            </div>
                                            <small className="text-muted">
                                              Show on top of your event page
                                              <br />
                                              Recommended 1400 x 400 1MB
                                            </small>
                                          </Label>
                                          <Label
                                            className="ml-3 image-upload-label"
                                            for="bannerUpload"
                                            style={{ cursor: "pointer" }}
                                          >
                                            <img
                                              alt="..."
                                              className="banner-image-edit"
                                              src={
                                                eventBannerImage ||
                                                defaultBanner
                                              }
                                            />
                                            <div className="image-overlay">
                                              <FontAwesomeIcon
                                                icon="fa-pencil"
                                                className="text-muted hidden-icon"
                                              />
                                            </div>
                                          </Label>
                                          <small>
                                            <FontAwesomeIcon
                                              icon="fa-circle-info"
                                              className="text-muted ml-1"
                                              id="bannerImageTooltip"
                                              style={{ cursor: "pointer" }}
                                            />
                                          </small>
                                          <UncontrolledTooltip
                                            placement="top"
                                            target="bannerImageTooltip"
                                            trigger="hover"
                                          >
                                            Banner image to be stored in IPFS
                                          </UncontrolledTooltip>
                                          <Input
                                            {...input}
                                            id="bannerUpload"
                                            name="file"
                                            type="file"
                                            accept="image/*"
                                            style={{ display: "none" }}
                                            onChange={(e) => {
                                              uploadBannerImage(e);
                                              setFormChanged(true);
                                            }}
                                          />

                                          {meta.error && meta.touched && (
                                            <small className="text-danger">
                                              <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                              {meta.error}
                                            </small>
                                          )}
                                        </>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </div>

                                <div className="mt-4 mb-4">
                                  <FormGroup>
                                    <Field name="name">
                                      {({ input, meta }) => (
                                        <>
                                          <Label
                                            for="name"
                                            className="required"
                                          >
                                            <strong>Name</strong>
                                          </Label>
                                          <Input
                                            {...input}
                                            id="name"
                                            value={eventName}
                                            onChange={(e) => {
                                              setEventName(e.target.value);
                                              setFormChanged(true);
                                            }}
                                            placeholder={
                                              eventName ||
                                              "Event name, eg. Non-fungible Party"
                                            }
                                            type="text"
                                          />

                                          {meta.error && meta.touched && (
                                            <small className="text-danger">
                                              <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                              {meta.error}
                                            </small>
                                          )}
                                        </>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </div>

                                <div className="mt-4 mb-4">
                                  <FormGroup>
                                    <Field name="description">
                                      {({ input, meta }) => (
                                        <>
                                          <Label for="description">
                                            <div>
                                              <strong>Description</strong>
                                            </div>
                                            <small className="text-muted ml-2">
                                              Supports basic markdown syntax{" "}
                                              <a
                                                href="https://www.markdownguide.org/basic-syntax/"
                                                target="_blank"
                                                rel="external nofollow noopener"
                                                className="text-muted"
                                              >
                                                ( Learn more{" "}
                                                <FontAwesomeIcon icon="arrow-up-right-from-square" />{" "}
                                                )
                                              </a>
                                            </small>
                                          </Label>
                                          {/* <MdEditor
                                            {...input}
                                            id='description'
                                            style={{ height: "250px" }}
                                            plugins={[
                                              "font-bold",
                                              "font-italic",
                                              "list-unordered",
                                              "list-ordered",
                                              "link",
                                              "mode-toggle",
                                              "logger",
                                            ]}
                                            view={{
                                              menu: true,
                                              md: true,
                                              html: false,
                                              fullScreen: false,
                                            }}
                                            canView={{
                                              menu: true,
                                              md: true,
                                              html: true,
                                              fullScreen: false,
                                              hideMenu: true,
                                            }}
                                            renderHTML={(text) =>
                                              mdParser.render(text)
                                            }
                                            onChange={(data) => {
                                              setEventDescription(data.text);
                                              setFormChanged(true);
                                            }}
                                            value={eventDescription}
                                          /> */}

                                          {meta.error && meta.touched && (
                                            <small className="text-danger">
                                              <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                              {meta.error}
                                            </small>
                                          )}
                                        </>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </div>

                                <div className="mt-4 mb-4">
                                  <FormGroup>
                                    <Field name="blockchain">
                                      {({ input, meta }) => (
                                        <>
                                          <Label
                                            for="blockchain"
                                            className="required"
                                          >
                                            <strong>Blockchain</strong>
                                          </Label>
                                          <Select
                                            {...input}
                                            id="blockchain"
                                            className="realpass-selector"
                                            onChange={(e) => {
                                              setBlockchainOption(e);
                                              setFormChanged(true);
                                            }}
                                            options={ChainSelectorOptions}
                                            value={blockchainOption}
                                            components={{
                                              IndicatorSeparator: () => null,
                                            }}
                                          />

                                          {meta.error && meta.touched && (
                                            <small className="text-danger">
                                              <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                              {meta.error}
                                            </small>
                                          )}
                                        </>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </div>

                                <div className="mt-4 mb-4">
                                  <FormGroup>
                                    <Field name="eventDuration">
                                      {({ input, meta }) => (
                                        <>
                                          <Label>
                                            <div>
                                              <span className="required">
                                                <strong>Event Duration</strong>
                                              </span>
                                              <span className="ml-5">
                                                <Input
                                                  {...input}
                                                  className="custom-control-input"
                                                  id="tbaCheck"
                                                  type="checkbox"
                                                  checked={eventTba}
                                                  onChange={() => {
                                                    toggleTba();
                                                    setFormChanged(true);
                                                  }}
                                                />
                                                <Label
                                                  className="custom-control-label"
                                                  htmlFor="tbaCheck"
                                                >
                                                  <span>TBA</span>
                                                </Label>
                                              </span>
                                            </div>
                                            <small className="text-muted">
                                              {eventTba ? (
                                                <>Event date to be announced</>
                                              ) : (
                                                <>
                                                  Set event start and end date ({" "}
                                                  {moment
                                                    .tz(moment.tz.guess())
                                                    .format("z")}{" "}
                                                  )
                                                  <FontAwesomeIcon
                                                    icon="fa-circle-info"
                                                    className="text-muted ml-1"
                                                    id="timezoneTooltip"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                  <UncontrolledTooltip
                                                    placement="top"
                                                    target="timezoneTooltip"
                                                    trigger="hover"
                                                  >
                                                    Your local timezone -{" "}
                                                    {moment.tz.guess()} (
                                                    {moment
                                                      .tz(moment.tz.guess())
                                                      .format("z")}{" "}
                                                    )
                                                  </UncontrolledTooltip>
                                                </>
                                              )}
                                            </small>
                                          </Label>
                                          <InputGroup className="date-range-picker">
                                            <InputGroupText className="date-picker-left text-muted">
                                              <FontAwesomeIcon icon="fa-calendar-days" />
                                            </InputGroupText>

                                            <ReactDatetime
                                              {...input}
                                              className="date-picker-middle"
                                              value={startDateMoment}
                                              inputProps={{
                                                placeholder: "Event Starts",
                                                disabled: eventTba,
                                              }}
                                              onChange={(e) => {
                                                handleReactDatetimeChange(
                                                  "startDate",
                                                  e
                                                );
                                                setFormChanged(true);
                                              }}
                                              renderDay={(
                                                props,
                                                currentDate,
                                                selectedDate
                                              ) => {
                                                let classes = props.className;
                                                classes +=
                                                  getClassNameReactDatetimeDays(
                                                    currentDate
                                                  );
                                                return (
                                                  <td
                                                    {...props}
                                                    className={classes}
                                                  >
                                                    {currentDate.date()}
                                                  </td>
                                                );
                                              }}
                                            />
                                            <ReactDatetime
                                              {...input}
                                              className="date-picker-right rdtPickerOnRight"
                                              value={endDateMoment}
                                              inputProps={{
                                                placeholder: "Event Ends",
                                                disabled: eventTba,
                                              }}
                                              onChange={(e) => {
                                                handleReactDatetimeChange(
                                                  "endDate",
                                                  e
                                                );
                                                setFormChanged(true);
                                              }}
                                              renderDay={(
                                                props,
                                                currentDate,
                                                selectedDate
                                              ) => {
                                                let classes = props.className;
                                                classes +=
                                                  getClassNameReactDatetimeDays(
                                                    currentDate
                                                  );
                                                return (
                                                  <td
                                                    {...props}
                                                    className={classes}
                                                  >
                                                    {currentDate.date()}
                                                  </td>
                                                );
                                              }}
                                            />
                                          </InputGroup>

                                          {meta.error && meta.touched && (
                                            <small className="text-danger">
                                              <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                              {meta.error}
                                            </small>
                                          )}
                                        </>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </div>

                                <div className="mt-4 mb-4">
                                  <FormGroup>
                                    <Field name="location">
                                      {({ input, meta }) => (
                                        <>
                                          <Label for="location">
                                            <div>
                                              <strong>Location</strong>

                                              <span className="ml-5">
                                                <Input
                                                  {...input}
                                                  className="custom-control-input"
                                                  id="virtualCheck"
                                                  type="checkbox"
                                                  checked={isVirtual}
                                                  onChange={() => {
                                                    toggleLocation();
                                                    setFormChanged(true);
                                                  }}
                                                />
                                                <Label
                                                  className="custom-control-label"
                                                  htmlFor="virtualCheck"
                                                >
                                                  <span>
                                                    This is a Virtual Event
                                                  </span>
                                                </Label>
                                              </span>
                                            </div>
                                            <small className="text-muted">
                                              {isVirtual
                                                ? "Enter the URL of virtual place"
                                                : "Enter the location in real world"}
                                            </small>
                                          </Label>

                                          <InputGroup>
                                            {isVirtual ? (
                                              <>
                                                <InputGroupText className="input-group-left pr-2">
                                                  <i className="ni ni-planet" />
                                                </InputGroupText>
                                                <Input
                                                  {...input}
                                                  id="location"
                                                  className="pl-2"
                                                  value={eventLocation?.name}
                                                  onChange={(e) => {
                                                    setEventLocation({
                                                      name: e.target.value,
                                                    });
                                                    setFormChanged(true);
                                                  }}
                                                  placeholder={
                                                    "In virtual place"
                                                  }
                                                  type="text"
                                                />{" "}
                                              </>
                                            ) : (
                                              <>
                                                <InputGroupText className="input-group-left pr-2">
                                                  <FontAwesomeIcon icon="fa-location-dot" />
                                                </InputGroupText>
                                                <GooglePlaceAutocomplete
                                                  className="form-control pl-2"
                                                  apiKey={`${REACT_APP_FBASE_API_KEY}&callback=Function.prototype`}
                                                  defaultValue={
                                                    eventLocation?.name
                                                  }
                                                  options={{
                                                    types: ["geocode"],
                                                    fields: [
                                                      "name",
                                                      "geometry",
                                                      "formatted_address",
                                                    ],
                                                  }}
                                                  onPlaceSelected={(place) => {
                                                    if (
                                                      place.formatted_address
                                                        .length > 120
                                                    ) {
                                                      setLocationError(
                                                        "Location must not exceed 120 characters."
                                                      );
                                                    } else {
                                                      setLocationError();
                                                      setEventLocation({
                                                        name: place.formatted_address,
                                                        lat: place.geometry.location.lat(),
                                                        lng: place.geometry.location.lng(),
                                                      });
                                                    }

                                                    setFormChanged(true);
                                                  }}
                                                />{" "}
                                              </>
                                            )}
                                          </InputGroup>

                                          {meta.error &&
                                            (meta.touched || locationError) && (
                                              <small className="text-danger">
                                                <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                                {meta.error}
                                              </small>
                                            )}
                                        </>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </div>

                                <div className="mt-4 mb-4">
                                  <FormGroup>
                                    <Field name="participants">
                                      {({ input, meta }) => (
                                        <>
                                          <Label for="participants">
                                            <div>
                                              <span className="required">
                                                <strong>Participants</strong>
                                              </span>
                                              <span className="ml-5">
                                                <Input
                                                  {...input}
                                                  className="custom-control-input"
                                                  id="unlimitedParticipantsCheck"
                                                  type="checkbox"
                                                  checked={
                                                    unlimitedParticipants
                                                  }
                                                  onChange={() => {
                                                    toggleUnlimitedParticipants();
                                                    setFormChanged(true);
                                                  }}
                                                />
                                                <Label
                                                  className="custom-control-label"
                                                  htmlFor="unlimitedParticipantsCheck"
                                                >
                                                  <span>
                                                    Unlimited Participants
                                                  </span>
                                                </Label>
                                              </span>
                                            </div>
                                            <small className="text-muted">
                                              Number of participants can join
                                              the event
                                            </small>
                                          </Label>
                                          <InputGroup>
                                            <InputGroupText className="input-group-left pr-2">
                                              <FontAwesomeIcon
                                                icon="fa-users"
                                                className="mr-2"
                                              />
                                              Max
                                            </InputGroupText>
                                            <Input
                                              {...input}
                                              id="maxParticipants"
                                              value={eventMaxParticipants}
                                              className="pl-2"
                                              onChange={(e) => {
                                                setEventMaxParticipants(
                                                  e.target.value
                                                );
                                                setFormChanged(true);
                                              }}
                                              placeholder={
                                                !unlimitedParticipants
                                                  ? eventMaxParticipants
                                                  : "∞"
                                              }
                                              disabled={unlimitedParticipants}
                                            />
                                          </InputGroup>

                                          {meta.error && meta.touched && (
                                            <small className="text-danger">
                                              <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                              {meta.error}
                                            </small>
                                          )}
                                        </>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </div>

                                <div className="mt-4 mb-4">
                                  <FormGroup>
                                    <Field name="category">
                                      {({ input, meta }) => (
                                        <>
                                          <Label
                                            for="category"
                                            className="required"
                                          >
                                            <strong>Category</strong>
                                          </Label>

                                          <InputGroup>
                                            <Select
                                              {...input}
                                              className="realpass-selector"
                                              onChange={(e) => {
                                                setEventCategoryOption(e);
                                                setFormChanged(true);
                                              }}
                                              options={
                                                EventCategorySelectorOptions
                                              }
                                              value={eventCategoryOption}
                                              components={{
                                                IndicatorSeparator: () => null,
                                              }}
                                            />
                                          </InputGroup>

                                          {meta.error && meta.touched && (
                                            <small className="text-danger">
                                              <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                              {meta.error}
                                            </small>
                                          )}
                                        </>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </div>

                                <div className="mt-4 mb-4">
                                  <FormGroup>
                                    <Field name="passes">
                                      {({ input, meta }) => (
                                        <>
                                          <Label for="passes">
                                            <div>
                                              <span className="required">
                                                <strong>Who can join?</strong>
                                              </span>
                                              <span className="ml-5">
                                                <Input
                                                  {...input}
                                                  className="custom-control-input"
                                                  id="requirePassCheck"
                                                  type="checkbox"
                                                  checked={requirePass}
                                                  onChange={() => {
                                                    toggleRequirePass();
                                                    setFormChanged(true);
                                                  }}
                                                  disabled={totalReg > 0}
                                                />
                                                <Label
                                                  className="custom-control-label"
                                                  htmlFor="requirePassCheck"
                                                >
                                                  <span>Pass Holders Only</span>
                                                </Label>
                                                <a
                                                  href="/create/pass"
                                                  target="_blank"
                                                  rel="external nofollow noopener"
                                                  className="ml-2"
                                                >
                                                  <small>
                                                    (Create Pass{" "}
                                                    <FontAwesomeIcon icon="arrow-up-right-from-square" />
                                                    )
                                                  </small>
                                                </a>
                                              </span>
                                            </div>

                                            <div>
                                              <small className="text-muted ml-2">
                                                Update is not allowed after
                                                registration starts
                                              </small>
                                            </div>
                                          </Label>

                                          {requirePass ? (
                                            <>
                                              <Alert
                                                color="secondary"
                                                className="text-default"
                                              >
                                                <div className="mb-2">
                                                  Find the smart contract
                                                  address of{" "}
                                                  <a
                                                    id="whyERC721"
                                                    className="text-info"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={(e) =>
                                                      e.preventDefault()
                                                    }
                                                  >
                                                    <u>ERC721</u>
                                                  </a>{" "}
                                                </div>
                                                <InputGroup>
                                                  <InputGroupText className="input-group-left pr-2 pass-search-prefix">
                                                    {searchLoading ? (
                                                      <span className="loading-sm"></span>
                                                    ) : (
                                                      <FontAwesomeIcon
                                                        icon="fa-ticket"
                                                        className="mr-2"
                                                      />
                                                    )}
                                                  </InputGroupText>
                                                  <Autosuggest
                                                    suggestions={
                                                      passSuggestions
                                                    }
                                                    onSuggestionsFetchRequested={
                                                      onPassSuggestionsFetch
                                                    }
                                                    onSuggestionsClearRequested={
                                                      onPassSuggestionsClear
                                                    }
                                                    getSuggestionValue={
                                                      getPassSuggestionValue
                                                    }
                                                    renderSuggestion={
                                                      renderPassSuggestion
                                                    }
                                                    onSuggestionSelected={
                                                      onPassSelected
                                                    }
                                                    renderInputComponent={(
                                                      inputProps
                                                    ) =>
                                                      renderPassInput(
                                                        input,
                                                        inputProps
                                                      )
                                                    }
                                                    inputProps={passInputProps}
                                                  />
                                                </InputGroup>

                                                {meta.error &&
                                                  passInputChanged && (
                                                    <small className="text-danger">
                                                      <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                                      {meta.error}
                                                    </small>
                                                  )}

                                                <div className="mt-3">
                                                  <Row>{showPass}</Row>
                                                </div>
                                              </Alert>
                                              <UncontrolledPopover
                                                trigger="hover"
                                                placement="bottom"
                                                target="whyERC721"
                                              >
                                                <PopoverHeader className="text-default">
                                                  <small>
                                                    <strong>Why ERC721?</strong>
                                                  </small>
                                                </PopoverHeader>
                                                <PopoverBody className="d-flex p-3">
                                                  <Row>
                                                    <Col>
                                                      <small>
                                                        ERC721 is the
                                                        non-fungible token
                                                        standard that supports
                                                        unique Token IDs where
                                                        each ID belongs to one
                                                        owner. ERC1155 is
                                                        semi-fungible where each
                                                        ID may have many owners.
                                                        ERC721 is much better to
                                                        track ownerships and
                                                        provenance than ERC1155.
                                                        Most NFT marketplaces
                                                        support ERC721.
                                                      </small>
                                                    </Col>
                                                  </Row>
                                                </PopoverBody>
                                              </UncontrolledPopover>
                                            </>
                                          ) : (
                                            <>
                                              <Alert
                                                color="secondary"
                                                className="text-default"
                                              >
                                                <div className="mb-2">
                                                  Anyone can join the event. Are
                                                  you sure?{" "}
                                                </div>
                                                <Toast className="bg-secondary">
                                                  <ToastHeader>
                                                    <div>
                                                      <FontAwesomeIcon
                                                        icon="fa-ticket"
                                                        className="mr-2 text-primary"
                                                      />
                                                      <strong className="text-primary">
                                                        No Pass Required
                                                      </strong>
                                                    </div>
                                                  </ToastHeader>
                                                  <ToastBody className="bg-secondary">
                                                    <div className="my-2 mx-5">
                                                      <Button
                                                        block
                                                        color="primary"
                                                        type="button"
                                                        onClick={(e) =>
                                                          e.preventDefault()
                                                        }
                                                      >
                                                        <h3 className="display-3 text-center text-white">
                                                          FREE
                                                        </h3>
                                                      </Button>
                                                    </div>
                                                  </ToastBody>
                                                </Toast>
                                              </Alert>
                                            </>
                                          )}
                                        </>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </div>

                                <div className="mt-4 mb-4">
                                  <Row>
                                    <Col
                                      className="mr--3"
                                      xl="3"
                                      lg="3"
                                      md="3"
                                      sm="4"
                                      xs="5"
                                    >
                                      <Label>
                                        <strong>Enable Registration</strong>
                                      </Label>
                                    </Col>
                                    <Col>
                                      <Label className="custom-toggle">
                                        <Input
                                          checked={registerEnabled}
                                          type="checkbox"
                                          onChange={() => {
                                            setRegisterEnabled(
                                              !registerEnabled
                                            );
                                            setFormChanged(true);
                                          }}
                                        />
                                        <span className="custom-toggle-slider rounded-circle"></span>
                                      </Label>
                                    </Col>
                                  </Row>

                                  <Alert
                                    color="secondary"
                                    className="text-default"
                                  >
                                    <span>
                                      {registerEnabled
                                        ? "People can register to join on the event page now."
                                        : "You must turn this on to enable people to join. You can change it later."}
                                    </span>
                                  </Alert>
                                </div>

                                <div className="mt-4 mb-4">
                                  <div>
                                    <Label for="privateCheck">
                                      <strong>Is it private?</strong>
                                    </Label>
                                    <span className="ml-5">
                                      <Input
                                        checked={!isPublic}
                                        id="privateCheck"
                                        value={!isPublic}
                                        className="custom-control-input"
                                        type="checkbox"
                                        onChange={() => setIsPublic(!isPublic)}
                                      />
                                      <Label
                                        className="custom-control-label"
                                        htmlFor="privateCheck"
                                      >
                                        <span>Invite or QR Access Only</span>
                                      </Label>
                                    </span>
                                  </div>
                                  <Alert
                                    color="secondary"
                                    className="text-default"
                                  >
                                    <span>
                                      {isPublic ? (
                                        <>
                                          This is a public event. People can
                                          join by registering on the event page.
                                        </>
                                      ) : (
                                        <>
                                          This is a private event. People an{" "}
                                          <strong>Invite Code</strong> to
                                          register or{" "}
                                          <strong>Scan QR Code</strong> at the
                                          event.
                                        </>
                                      )}
                                    </span>
                                  </Alert>
                                </div>

                                {/* <div className='mt-4 mb-4'>
                              <Label for='walletAddressButton'>
                                <div>
                                  <strong>Creator Wallet</strong>
                                  <small className='text-muted ml-2'>
                                    Wallet address to collect the payment
                                  </small>
                                </div>
                              </Label>

                              <InputGroup>
                                <Button
                                  id='walletAddressButton'
                                  outline
                                  type='button'
                                  style={{ textTransform: "none" }}
                                  onClick={() =>
                                    navigator.clipboard.writeText(
                                      currentUserInfo?.ethAddress
                                    )
                                  }
                                >
                                  <img
                                    className='icon-eth mr-2'
                                    alt='...'
                                    src={ethImg}
                                  />
                                  <span className='wallet-field-md'>
                                    {currentUserInfo?.ethAddress}
                                    <FontAwesomeIcon
                                      icon='fa-clone'
                                      className='ml-1 text-muted'
                                    />
                                  </span>
                                </Button>
                                <UncontrolledTooltip
                                  placement='top'
                                  target='walletAddressButton'
                                  trigger='click'
                                >
                                  Address Copied
                                </UncontrolledTooltip>
                              </InputGroup>
                            </div> */}

                                <div className="mt-4 mb-4">
                                  <FormGroup>
                                    <Field name="links">
                                      {({ input, meta }) => (
                                        <>
                                          <Label for="website">
                                            <strong>Links</strong>
                                          </Label>
                                          <InputGroup>
                                            <InputGroupText className="input-group-top-left pr-2 text-muted">
                                              <FontAwesomeIcon icon="fa-globe" />
                                            </InputGroupText>
                                            <Input
                                              {...input}
                                              id="website"
                                              value={eventWebsite}
                                              className="input-group-top-right pl-2"
                                              onChange={(e) => {
                                                setEventWebsite(e.target.value);
                                                setFormChanged(true);
                                              }}
                                              placeholder={
                                                eventWebsite || "yoursite.xyz"
                                              }
                                              type="text"
                                            />
                                          </InputGroup>
                                          <InputGroup>
                                            <InputGroupText className="input-group-mid-left pr-2 text-muted">
                                              <FontAwesomeIcon
                                                icon="fa-brands fa-youtube"
                                                className="mr-2"
                                              />
                                            </InputGroupText>
                                            <Input
                                              {...input}
                                              id="videoUrl"
                                              value={eventVideoUrl}
                                              className="input-group-mid-right pl-2"
                                              onChange={(e) => {
                                                setEventVideoUrl(
                                                  e.target.value
                                                );
                                                setFormChanged(true);
                                              }}
                                              placeholder={
                                                eventVideoUrl ||
                                                "youtube.com/watch?v=your_video"
                                              }
                                              type="text"
                                            />
                                          </InputGroup>
                                          <InputGroup>
                                            <InputGroupText className="input-group-mid-left pr-2 text-muted">
                                              <FontAwesomeIcon icon="fa-brands fa-twitter" />
                                            </InputGroupText>

                                            <Input
                                              {...input}
                                              id="twitter"
                                              value={eventTwitter}
                                              className="input-group-mid-right pl-2"
                                              onChange={(e) => {
                                                setEventTwitter(e.target.value);
                                                setFormChanged(true);
                                              }}
                                              placeholder={
                                                eventTwitter ||
                                                "twitter.com/your_handle"
                                              }
                                              type="text"
                                            />
                                          </InputGroup>
                                          <InputGroup>
                                            <InputGroupText className="input-group-mid-left pr-2 text-muted">
                                              <FontAwesomeIcon icon="fa-brands fa-discord" />
                                            </InputGroupText>

                                            <Input
                                              {...input}
                                              id="discord"
                                              value={eventDiscord}
                                              className="input-group-mid-right pl-2"
                                              onChange={(e) => {
                                                setEventDiscord(e.target.value);
                                                setFormChanged(true);
                                              }}
                                              placeholder={
                                                eventDiscord ||
                                                "discord.gg/your_server"
                                              }
                                              type="text"
                                            />
                                          </InputGroup>
                                          <InputGroup>
                                            <InputGroupText className="input-group-bottom-left pr-2 text-muted">
                                              <FontAwesomeIcon icon="fa-brands fa-instagram" />
                                            </InputGroupText>

                                            <Input
                                              {...input}
                                              id="instagram"
                                              value={eventInstagram}
                                              className="input-group-bottom-right pl-2"
                                              onChange={(e) => {
                                                setEventInstagram(
                                                  e.target.value
                                                );
                                                setFormChanged(true);
                                              }}
                                              placeholder={
                                                eventInstagram ||
                                                "instagram.com/your_handle"
                                              }
                                              type="text"
                                            />
                                          </InputGroup>

                                          {meta.error && meta.touched && (
                                            <small className="text-danger">
                                              <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                              {meta.error}
                                            </small>
                                          )}
                                        </>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </div>

                                <div className="mt-4 mb-4">
                                  <div>
                                    <Label for="nsfwCheck">
                                      <strong>Is this NSFW?</strong>
                                    </Label>
                                    <span className="ml-5">
                                      <Input
                                        checked={isNSFW}
                                        id="nsfwCheck"
                                        className="custom-control-input"
                                        type="checkbox"
                                        onChange={() => {
                                          {
                                            setIsNSFW(!isNSFW);
                                            setFormChanged(true);
                                          }
                                        }}
                                      />

                                      <Label
                                        className="custom-control-label"
                                        htmlFor="nsfwCheck"
                                      >
                                        <span>Mark as NSFW</span>
                                      </Label>
                                    </span>
                                  </div>

                                  <small className="text-muted mt-2 ml-3">
                                    RealPass allows NSFW content, but the
                                    content is required to be marked NSFW and
                                    handled differently from normal content.{" "}
                                  </small>
                                </div>

                                <div className="mt-5">
                                  <FormGroup>
                                    <Field name="acceptTerms">
                                      {({ input, meta }) => (
                                        <div className="ml-4">
                                          <div>
                                            <Label>
                                              <Input
                                                {...input}
                                                checked={acceptTerms}
                                                className="custom-control-input"
                                                type="checkbox"
                                                onChange={() => {
                                                  setAcceptTerms(!acceptTerms);
                                                }}
                                              />
                                              <span className="custom-control-label">
                                                <strong>
                                                  Accept Terms and Privacy
                                                  Policy
                                                </strong>
                                              </span>
                                            </Label>
                                          </div>
                                          <small className="text-muted mt-2">
                                            {DisclaimerShort}{" "}
                                            <a
                                              href="/terms"
                                              rel="external nofollow noopener"
                                              target="_blank"
                                              className="text-info"
                                            >
                                              Terms of Service
                                            </a>{" "}
                                            and{" "}
                                            <a
                                              href="/privacy"
                                              rel="external nofollow noopener"
                                              target="_blank"
                                              className="text-info"
                                            >
                                              Privacy Policy
                                            </a>
                                            .
                                          </small>
                                        </div>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </div>

                                <div className="mt-4">
                                  <ReCAPTCHA
                                    className="m-3"
                                    sitekey={
                                      REACT_APP_GOODLE_RECAPTCHA_CHECKBOX_KEY
                                    }
                                    onChange={recaptchaChangeHandler}
                                    onExpired={() => setRecaptcha(false)}
                                    onError={() => setRecaptcha(false)}
                                  />

                                  <Button
                                    className="btn btn-dark mt-4 btn-block"
                                    color="dark"
                                    type="submit"
                                    disabled={
                                      !valid || !recaptcha || !formChanged
                                    }
                                  >
                                    Save Event
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      )}
                    />
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </>
      )}
    </>
  );
}
