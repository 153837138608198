import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Alert,
  ButtonGroup,
  Button,
  Collapse,
  Card,
  Container,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  CardImg,
  CardBody,
  CardTitle,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Table,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  notify,
  genImageName,
  nDaysAfterTZ,
  handleReactDatetimeChange,
  getClassNameReactDatetimeDays,
} from "utils/Utils.js";

import { DefaultEventSessionSize } from "utils/Variables.js";

import ReactDatetime from "react-datetime";
import Moment from "react-moment";
import moment from "moment-timezone";

export default function MultiSessionsEditable(props) {
  let { sessions, timezone, updateSessions, updateFormChanged } = props;

  const [sessionsInput, setSessionsInput] = useState([
    {
      name: "My Session",
      start: nDaysAfterTZ(1),
      end: nDaysAfterTZ(2),
    },
  ]);

  const addSession = () => {
    const len = sessionsInput?.length;

    const newSession = {
      name: `My Session ${len + 1}`,
      start: nDaysAfterTZ(1),
      end: nDaysAfterTZ(2),
    };

    setSessionsInput([...sessionsInput, newSession]);
  };

  const removeSession = (index) => {
    const newSessions = [...sessionsInput];
    newSessions.splice(index, 1);
    setSessionsInput(newSessions);
  };

  const updateSession = (index, name, start, end) => {
    const newSessions = [...sessionsInput];

    newSessions[index] = {
      name: name,
      start: start,
      end: end,
    };

    if (name?.length > 40) {
      newSessions[index].error = "Session name must not exceed 40 characters.";
    }

    if (new Date(start) >= new Date(end)) {
      newSessions[index].error = "Session duration is invalid.";
    }

    if (Date.now() > new Date(start) || Date.now() > new Date(end)) {
      newSessions[index].error = "Session time cannot be in the past.";
    }

    setSessionsInput(newSessions);
  };

  useEffect(() => {
    const sessionList = sessionsInput.map((session, index) => {
      return {
        name: session.name,
        startDateTime: new Date(session.start),
        endDateTime: new Date(session.end),
      };
    });

    updateSessions(sessionList);
  }, [sessionsInput]);

  useEffect(() => {
    const sessionList = sessions?.map((item, index) => {
      const session = {
        name: item?.name,
        start: moment(item?.startDateTime),
        end: moment(item?.endDateTime),
      };

      return session;
    });

    setSessionsInput(
      sessionList?.length > 0
        ? sessionList
        : [
            {
              name: "My Session",
              start: nDaysAfterTZ(1),
              end: nDaysAfterTZ(2),
            },
          ]
    );
  }, []);

  return sessionsInput?.map((item, index) => {
    return (
      <div key={index}>
        {index > 0 && <hr className="my-3" />}
        <div className="my-2">
          <Row className="my-1">
            <Col className="col-9">
              <InputGroup>
                <InputGroupText className="input-group-left text-muted">
                  <FontAwesomeIcon icon="fa-check-to-slot" />
                </InputGroupText>
                <Input
                  value={item.name}
                  className="input-group-right"
                  placeholder={item.name || "What's the session called?"}
                  onChange={(e) => {
                    updateSession(index, e.target.value, item.start, item.end);

                    updateFormChanged(true);
                  }}
                />
              </InputGroup>
            </Col>
            <Col>
              {index > 0 ? (
                <Button
                  color="link"
                  size="sm"
                  className="text-gray btn-icon-only mt-2"
                  onClick={() => {
                    removeSession(index);
                    updateFormChanged(true);
                  }}
                >
                  <FontAwesomeIcon icon="fa-trash-can" className="fa-lg" />
                </Button>
              ) : (
                <Button
                  className=" text-info mt-2"
                  color="link"
                  size="sm"
                  disabled={sessionsInput?.length >= DefaultEventSessionSize}
                  onClick={() => {
                    addSession();
                    updateFormChanged(true);
                  }}
                >
                  <FontAwesomeIcon icon="fa-square-plus" className="fa-lg" />
                </Button>
              )}
            </Col>
          </Row>

          <Row className="my-1">
            <Col>
              <InputGroup className="date-range-picker">
                <InputGroupText className="date-picker-left text-muted">
                  <FontAwesomeIcon icon="fa-calendar-days" />
                </InputGroupText>
                <ReactDatetime
                  className="date-picker-middle"
                  value={item.start}
                  inputProps={{
                    placeholder: "Event Starts",
                  }}
                  isValidDate={(now) => {
                    return now.isAfter(moment().subtract(1, "day"));
                  }}
                  onChange={(e) => {
                    const duration = handleReactDatetimeChange(
                      "startDate",
                      e,
                      item.start,
                      item.end
                    );
                    item.start = duration.start;
                    duration.end && (item.end = duration.end);
                    updateSession(index, item.name, item.start, item.end);

                    updateFormChanged(true);
                  }}
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    classes += getClassNameReactDatetimeDays(
                      currentDate,
                      item.start,
                      item.end
                    );
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  displayTimeZone={timezone}
                />
                <ReactDatetime
                  className="date-picker-right rdtPickerOnRight"
                  value={item.end}
                  inputProps={{
                    placeholder: "Event Ends",
                  }}
                  isValidDate={(now) => {
                    return now.isAfter(moment().subtract(1, "day"));
                  }}
                  onChange={(e) => {
                    const duration = handleReactDatetimeChange(
                      "endDate",
                      e,
                      item.start,
                      item.end
                    );

                    duration.start && (item.start = duration.start);
                    item.end = duration.end;

                    updateSession(index, item.name, item.start, item.end);

                    updateFormChanged(true);
                  }}
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    classes += getClassNameReactDatetimeDays(
                      currentDate,
                      item.start,
                      item.end
                    );
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  displayTimeZone={timezone}
                />
              </InputGroup>
            </Col>
          </Row>

          {item.error && (
            <small className="text-danger">
              <FontAwesomeIcon icon="fa-triangle-exclamation" /> {item.error}
            </small>
          )}
        </div>
      </div>
    );
  });
}
