import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import { AuthContext } from "Auth.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Select from "react-select";

import Autosuggest from "react-autosuggest";

// import { RandomHash } from "random-hash";
import _, { now } from "lodash";

import GooglePlaceAutocomplete from "react-google-autocomplete";

// reactstrap components
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  Container,
  Collapse,
  Row,
  Col,
  FormGroup,
  Input,
  InputGroupText,
  InputGroup,
  Label,
  UncontrolledTooltip,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Toast,
  ToastHeader,
  ToastBody,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";

import ReactDatetime from "react-datetime";

import Moment from "react-moment";
import moment from "moment-timezone";

import TimezoneSelect, { allTimezones } from "react-timezone-select";

// core components
import TopNavbar from "components/Navbars/TopNavbar.js";
import Loader from "components/Loaders/Loader.js";

import ScaleLoader from "react-spinners/ScaleLoader";
import BarLoader from "react-spinners/BarLoader";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { Form, Field } from "react-final-form";

import {
  truncateAddressShort,
  truncateText,
  notify,
  validEthAddress,
  validUrlSlug,
  validEmail,
  removeProtocolStr,
  genImageName,
  DayMS,
  nDaysAfterTZ,
  handleReactDatetimeChange,
  getClassNameReactDatetimeDays,
} from "utils/Utils.js";

import {
  createSiweMessage,
  eventExists,
  firebaseUpload,
  createEvent,
} from "utils/Api.js";

import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.compat.css";

import Compressor from "compressorjs";

import Web3 from "web3";

import ReCAPTCHA from "react-google-recaptcha";

import MultiSessionsEditable from "components/Lists/MultiSessionsEditable.js";

const {
  REACT_APP_API_HOST,
  REACT_APP_FBASE_API_KEY,
  REACT_APP_GOODLE_RECAPTCHA_CHECKBOX_KEY,
} = process.env;

const {
  bgAbstractLine03,
  logoBlackIcon,
  defaultBoxGrayImg,
  defaultFormImg,
  defaultFormBanner,
  ethWhiteImg,
  polygonWhiteImg,
  DefaultLocation,
  ChainOptions,
  ChainSelectorOptions,
  EventCategoryOptions,
  EventCategorySelectorOptions,
  USDateOption,
  USDateTimeOption,
  UserRoleTypes,
  CreatorRoleTypes,
  UserRoleStatus,
  VerifiedUserTypes,
  VerifiedUserStatus,
  DisclaimerShort,
  QuillEditorModules,
  QuillEditorFormats,
} = require("utils/Variables.js");

export default function EventCreate() {
  const navigate = useNavigate();

  const { currentUser, currentAuthToken, currentUserInfo, setCurrentUserInfo } =
    useContext(AuthContext);

  const hasCreatorRole = currentUserInfo?.hasCreatorRole;
  const isVerifiedUser = currentUserInfo?.isVerifiedUser;

  const [pageLoading, setPageLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [dataSaving, setDataSaving] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  const [eventImageUrl, setEventImageUrl] = useState("");
  const [eventImageName, setEventImageName] = useState("");
  const [eventImageData, setEventImageData] = useState();

  const [bannerImageUrl, setBannerImageUrl] = useState("");
  const [bannerImageName, setBannerImageName] = useState("");
  const [bannerImageData, setBannerImageData] = useState();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [email, setEmail] = useState(currentUser?.email || "");

  const [blockchainOption, setBlockchainOption] = useState(
    ChainSelectorOptions[0]
  );

  const [venue, setVenue] = useState();
  const [isVirtual, setIsVirtual] = useState(false);
  const [virtualLocation, setVirtualLocation] = useState(DefaultLocation);
  const [irlLocation, setIrlLocation] = useState(DefaultLocation);

  const [isLocationRestricted, setIsLocationRestricted] = useState(false);
  const [secretEnabled, setSecretEnabled] = useState(false);
  const [secretName, setSecretName] = useState("");
  const [secretCode, setSecretCode] = useState("");

  const [categoryOption, setCategoryOption] = useState(
    EventCategorySelectorOptions[0]
  );
  // const [eventCategory, setEventCategory] = useState("");
  // const [eventCategoryOption, setEventCategoryOption] = useState(
  //   EventCategorySelectorOptions[0]
  // );

  const [selectedTimezoneValue, setSelectedTimezoneValue] = useState(
    moment.tz.guess()
  );
  const [selectedTimezoneLabel, setSelectedTimezoneLabel] = useState(
    `${moment.tz.guess()} (${moment
      .tz(moment.tz.guess())
      .format("z")})`?.replace("_", " ")
  );
  const [selectedTimezone, setSelectedTimezone] = useState(moment.tz.guess());

  const [startDateMoment, setStartDateMoment] = useState(
    nDaysAfterTZ(1, moment.tz.guess())
  );
  const [endDateMoment, setEndDateMoment] = useState(
    nDaysAfterTZ(2, moment.tz.guess())
  );

  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  const [isMultiSession, setIsMultiSession] = useState();
  const [sessions, setSessions] = useState([]);
  const [durationError, setDurationError] = useState();

  const [maxUsers, setMaxUsers] = useState("∞");
  const [unlimitedUsers, setUnlimitedUsers] = useState(true);

  // const [isRaffle, setIsRaffle] = useState(false);
  // const [winners, setWinners] = useState(1);

  const [requirePass, setRequirePass] = useState(true);
  const [registerEnabled, setRegisterEnabled] = useState(false);

  const [selectedPass, setSelectedPass] = useState();
  const [requireUniquePassId, setRequireUniquePassId] = useState(true);

  const [passInput, setPassInput] = useState("");
  const [passInputChanged, setPassInputChanged] = useState(false);
  const [passSuggestions, setPassSuggestions] = useState([]);

  const [website, setWebsite] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [twitter, setTwitter] = useState("");
  const [discord, setDiscord] = useState("");
  const [instagram, setInstagram] = useState("");

  // const [isPublic, setIsPublic] = useState(true);
  const [isNSFW, setIsNSFW] = useState(false);

  const [isPublished, setIsPublished] = useState(false);

  const [openedCollapse, setOpenedCollapse] = useState("collapse-1");

  const [locationError, setLocationError] = useState();
  const [passError, setPassError] = useState();

  // const checkEventUrl = async () => {
  //   setEventUrlError();

  //   // On blur validate event url
  //   if (!eventUrl || eventUrl?.length === 10) {
  //     setEventUrlError("Event URL must have at least 10 characters.");
  //     return;
  //   }

  //   // if (eventUrl?.length > 100) {
  //   //   setEventUrlError("Event URL must not exceed 100 characters.");
  //   //   return;
  //   // }

  //   if (eventUrl && !validUrlSlug(eventUrl)) {
  //     setEventUrlError(
  //       "Event URL can only have nummbers, lowercase letters, or -."
  //     );
  //     return;
  //   }

  //   try {
  //     setEventUrlLoading(true);

  //     const exists = await eventExists(currentAuthToken, eventUrl);

  //     if (exists) {
  //       setNotDuplicateEvent(false);
  //       setEventUrlError("Event URL already exists");
  //     } else {
  //       setNotDuplicateEvent(true);
  //       setEventUrlError();
  //     }
  //   } catch (err) {
  //     console.error(err.message);
  //     setEventUrlError("Unable to check Event URL.");
  //   } finally {
  //     setEventUrlLoading(false);
  //   }
  // };

  /************** Pass Selector Below **************/
  // Disable Input when Max Contracts are selected
  const renderPassInput = (input, inputProps) => (
    <input
      {...inputProps}
      disabled={selectedPass}
      placeholder={
        selectedPass
          ? "You may only set one Pass Contract."
          : "Smart contract 0x12345..."
      }
    />
  );

  // // Pass thumbnail to render
  // const showPass = selectedPass && (
  //   <Col className="m-3">
  //     <Toast className="bg-secondary">
  //       <Row className="toast-header">
  //         <Col>
  //           <FontAwesomeIcon icon="fa-ticket" className="mr-2 text-primary" />
  //           <strong className="text-primary">
  //             Require {selectedPass?.contract_type} Pass
  //           </strong>
  //         </Col>
  //         <Col className="justify-content-right text-right col-1">
  //           <button
  //             type="button"
  //             className="ml-2 close text-white"
  //             data-dismiss="toast"
  //             aria-label="Close"
  //             color="white"
  //             onClick={() => {
  //               setSelectedPass();
  //               setFormChanged(true);
  //             }}
  //           >
  //             <span aria-hidden="true" className="">
  //               <FontAwesomeIcon icon="fa-xmark" />
  //             </span>
  //           </button>
  //         </Col>
  //       </Row>
  //       <ToastBody className="bg-secondary">
  //         <div className="ml-5 mt-2">
  //           <div>
  //             <Input
  //               className="custom-control-input"
  //               id="requireUniqueIdCheck"
  //               type="checkbox"
  //               checked={requireUniquePassId}
  //               onChange={() => {
  //                 setRequireUniquePassId(!requireUniquePassId);
  //                 setFormChanged(true);
  //               }}
  //             />
  //             <Label
  //               className="custom-control-label"
  //               htmlFor="requireUniqueIdCheck"
  //             >
  //               <strong>Require Unique Token ID</strong>
  //             </Label>
  //           </div>
  //         </div>

  //         <div className="mx-4 mt-4 mb-3">
  //           <Button
  //             color="primary"
  //             href={
  //               (blockchainOption.value === "eth" &&
  //                 `https://etherscan.io/address/${selectedPass?.token_address}`) ||
  //               (blockchainOption.value === "matic" &&
  //                 `https://polygonscan.com/address/${selectedPass?.token_address}`)
  //             }
  //             rel="external nofollow noopener"
  //             target="_blank"
  //           >
  //             <div className="text-left">
  //               {truncateText(selectedPass?.name, 20)}
  //             </div>
  //             <div className="text-left">
  //               <img
  //                 alt="..."
  //                 style={{ width: "100%" }}
  //                 className="mt-2 mb-2 rounded"
  //                 src={
  //                   JSON.parse(selectedPass?.metadata)?.image?.replace(
  //                     "ipfs://",
  //                     "https://gateway.moralisipfs.com/ipfs/"
  //                   ) || defaultBoxGrayImg
  //                 }
  //               />
  //             </div>
  //             <div className="text-left">
  //               {blockchainOption.value === "eth" && (
  //                 <img src={ethWhiteImg} className="icon-eth mr-2" />
  //               )}
  //               {blockchainOption.value === "matic" && (
  //                 <img src={polygonWhiteImg} className="icon-polygon mr-2" />
  //               )}
  //               <>
  //                 <small>{selectedPass?.contract_type} </small>
  //                 <span className="text-info">
  //                   {truncateAddressShort(selectedPass?.token_address)}
  //                 </span>{" "}
  //                 <FontAwesomeIcon
  //                   icon="arrow-up-right-from-square"
  //                   className="ml-1"
  //                 />
  //               </>
  //             </div>
  //             <div className="text-left">
  //               <small className="text-warning text-capitalize">
  //                 *{" "}
  //                 {requireUniquePassId
  //                   ? "Require unique Token ID to join"
  //                   : "Each Token ID can be reused"}
  //               </small>
  //             </div>
  //           </Button>
  //         </div>
  //       </ToastBody>
  //     </Toast>
  //   </Col>
  // );

  // Fetch Metadata by Contract Address
  const getPassSuggestions = async (input) => {
    // const isAddress = web3.utils.isAddress(input.toLowerCase());
    // if (isAddress) {
    //   const address = web3.utils
    //     .toChecksumAddress(input.toLowerCase())
    //     .toLowerCase();
    //   // TODO: remove rinkeby
    //   const options = {
    //     address: address,
    //     // chain: blockchainOption.value,
    //     chain: blockchainOption.value === "eth" ? "rinkeby" : "mumbai",
    //     limit: 1,
    //   };
    //   let nfts = [];
    //   try {
    //     setSearchLoading(true);
    // const res = await Moralis.Web3API.token.getAllTokenIds(options);
    // if (res && res.result && res.result.length > 0) {
    //   if (res.result[0].contract_type === "ERC721") {
    //     nfts = res.result;
    //     setPassError();
    //   } else {
    //     setPassError("Smart contract is not ERC721.");
    //   }
    // } else {
    //   setPassError("Cannot find the smart contract.");
    // }
    //   } catch (err) {
    //     console.error(err.message);
    //     setPassError("Unable to get the smart contract data.");
    //   } finally {
    //     setSearchLoading(false);
    //   }
    //   return nfts;
    // } else {
    //   setPassError("Pass Contract must be a valid address.");
    //   return [];
    // }
  };

  // Get suggest value and set on the input
  const getPassSuggestionValue = (suggestion) => suggestion.name;

  // Render pass suggestion result dropdown
  const renderPassSuggestion = (suggestion) => (
    <div>
      <span style={{ display: "block", textAlign: "left" }}>
        {suggestion?.name}
      </span>
      <small style={{ display: "block", textAlign: "left" }}>
        {suggestion?.token_address}
      </small>
    </div>
  );

  // When Contract Input Change
  const onPassInputChange = (event, { newValue }) => {
    setPassInput(newValue);
    setPassInputChanged(true);
  };

  // Set the Pass Suggestion after getting the metadata
  const onPassSuggestionsFetch = async ({ value }) => {
    const data = await getPassSuggestions(value);
    if (data) {
      setPassSuggestions(data);
    } else {
      setPassSuggestions([]);
    }
  };

  // Set the pass thumbnail after selecting the suggestion
  const onPassSelected = (event, { suggestion }) => {
    setSelectedPass(suggestion);
    setRequireUniquePassId(true);
    setFormChanged(true);
  };

  // Reset the input
  const onPassSuggestionsClear = () => {
    setPassInput("");
    setPassSuggestions([]);
  };

  // Input Props
  const passInputProps = {
    placeholder: "Smart contract 0x12345...",
    value: passInput,
    onChange: onPassInputChange,
  };
  /************** Pass Selector Above **************/

  const selectEventImage = (e) => {
    const data = e.target.files[0];

    //Compress Images
    new Compressor(data, {
      quality: 0.8,
      maxWidth: 600,
      maxHeight: 600,
      mimeType: "image/webp",

      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      success(result) {
        setEventImageUrl(URL.createObjectURL(result));
        setEventImageData(result);
        setEventImageName(`image.${result.name.split(".").at(-1)}`);
      },
      error(err) {
        console.error(err.message);
      },
    });
  };

  const selectBannerImage = (e) => {
    const data = e.target.files[0];

    //Compress Images
    new Compressor(data, {
      quality: 0.8,
      maxWidth: 1400,
      maxHeight: 400,
      mimeType: "image/webp",

      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      success(result) {
        setBannerImageUrl(URL.createObjectURL(result));
        setBannerImageData(result);
        setBannerImageName(`banner.${result.name.split(".").at(-1)}`);
      },
      error(err) {
        console.error(err.message);
      },
    });
  };

  const saveEvent = async () => {
    try {
      setDataSaving(true);

      let imageUrl = eventImageUrl;
      let bannerUrl = bannerImageUrl;

      if (eventImageData) {
        imageUrl = await firebaseUpload(
          eventImageData,
          genImageName(currentUser?.uid, "event", eventImageName, true)
        );

        setEventImageUrl(imageUrl);
      }

      if (bannerImageData) {
        bannerUrl = await firebaseUpload(
          bannerImageData,
          genImageName(currentUser?.uid, "event", bannerImageName, true)
        );

        setBannerImageUrl(bannerUrl);
      }

      const event = {
        imageUrl,
        bannerUrl,
        name,
        description,
        category: categoryOption.value,
        isMultiSession,
        isVirtual,
        isLocationRestricted,
        isNSFW,
        isPublished,
        timezone: selectedTimezoneValue,
        venue,
      };

      event.startDateTime = new Date(startDateMoment);
      event.endDateTime = new Date(endDateMoment);

      if (isMultiSession) {
        event.sessions = sessions;
      } else {
        event.sessions = null;
      }

      if (isVirtual) {
        event.location = {
          name: virtualLocation.name,
        };
      } else {
        event.location = {
          name: irlLocation.name,
          lat: irlLocation.lat,
          lng: irlLocation.lng,
        };
      }

      const eventUrl = await createEvent(currentAuthToken, event);

      setDataSaving(false);
      setFormChanged(false);

      if (eventUrl) {
        notify("Event has been created.", "success");
        setTimeout(() => navigate(`/dashboard/event/${eventUrl}`), 2000);
      } else {
        notify("Event creation failed.", "error");
      }

      //   const NFEvent = Moralis.Object.extend("NFEvent");
      //   const nfevent = new NFEvent();

      //   const eventImageFile = new Moralis.File(
      //     name.replace(/[^a-zA-Z0-9 ]/g, ""),
      //     eventImageData
      //   );
      //   await eventImageFile.saveIPFS();
      //   nfevent.set("eventImage", eventImageFile);

      //   if (bannerImageData) {
      //     const bannerImageFile = new Moralis.File(
      //       name.replace(/[^a-zA-Z0-9 ]/g, ""),
      //       bannerImageData
      //     );
      //     await bannerImageFile.saveIPFS();
      //     nfevent.set("bannerImage", bannerImageFile);
      //   }

      //   nfevent.set("name", name);
      //   nfevent.set("description", description);
      //   nfevent.set("blockchain", blockchainOption.value);
      //   nfevent.set("startDateTime", startDateMoment?.toDate());
      //   nfevent.set("endDateTime", endDateMoment?.toDate());
      //   nfevent.set("isVirtual", isVirtual);
      //   nfevent.set("location", location);
      //   nfevent.set(
      //     "maxUsers",
      //     maxUsers === "∞" ? -1 : Number(maxUsers)
      //   );

      //   nfevent.set("passContract", selectedPass?.token_address);
      //   nfevent.set("requireUniquePassId", requireUniquePassId);

      //   nfevent.set("category", eventCategoryOption.value);
      //   nfevent.set("onChain", false);
      //   // nfevent.set("eventContract", eventContract);
      //   nfevent.set("registerEnabled", registerEnabled);
      //   nfevent.set("isPublic", isPublic);
      //   nfevent.set("isNSFW", isNSFW);
      //   nfevent.set("website", website);
      //   nfevent.set("videoUrl", videoUrl);
      //   nfevent.set("twitter", twitter);
      //   nfevent.set("discord", discord);
      //   nfevent.set("instagram", instagram);

      //   // Serverside recaptcha validation via context
      //   const context = { recaptcha: recaptchaToken };
      //   const res = await nfevent.save(null, { context: context });

      //   const eventId = res.id;

      //   setPageLoading(false);
      //   setFormChanged(false);

      //   if (eventId) {
      //     notify("Event has been created.", "success");
      //     setTimeout(() => navigate(`/event/${eventId}`), 3000);
      //   } else {
      //     notify("Event creation failed.", "error");
      //   }
    } catch (err) {
      console.error(err.message);
      setDataSaving(false);
      setFormChanged(false);
      notify("Event creation failed.", "error");
    }
  };

  const handleTimezoneChange = (tz) => {
    setSelectedTimezoneValue(tz.value);
    setSelectedTimezoneLabel(`${tz.value} (${tz.abbrev})`?.replace("_", " "));
    setSelectedTimezone(tz);
    setFormChanged(true);
  };

  // const toggleUnlimitedUsers = () => {
  //   const unlimited = !unlimitedUsers;
  //   setUnlimitedUsers(unlimited);
  //   unlimited ? setMaxUsers("∞") : setMaxUsers(0);
  // };

  const toggleMultiSession = (multiFlag) => {
    if (multiFlag !== isMultiSession) {
      setIsMultiSession(multiFlag);
    }
  };

  const toggleVirtual = (virtualFlag) => {
    if (virtualFlag !== isVirtual) {
      setIsVirtual(virtualFlag);
      setLocationError();
    }
  };

  const toggleSecret = () => {
    const secretFlag = !secretEnabled;

    setSecretEnabled(secretFlag);
    setSecretName("");
    setSecretCode("");
  };

  const toggleRequirePass = () => {
    const requirePassFlag = !requirePass;
    setRequirePass(requirePassFlag);
    setSelectedPass();
    setPassError();
  };

  useEffect(() => {
    if (!hasCreatorRole) {
      navigate("/create");
    }
  }, []);

  useEffect(() => {
    let hasError = false;

    if (
      Date.now() > new Date(startDateMoment) ||
      Date.now() > new Date(endDateMoment)
    ) {
      setDurationError("Event time cannot be in the past.");
      hasError = true;
    }

    if (new Date(startDateMoment) >= new Date(endDateMoment)) {
      setDurationError("Event duration is invalid.");
      hasError = true;
    }

    !hasError && setDurationError();
  }, [startDateMoment, endDateMoment]);

  return (
    <>
      <BarLoader
        loading={pageLoading || dataSaving}
        cssOverride={{
          width: "100%",
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          zIndex: "1031",
          backgroundColor: "#fff",
          opacity: "1",
        }}
      />
      <TopNavbar />
      <ReactNotifications />
      <div
        className="bg-transparent"
        style={{
          height: "100%",
          width: "100%",
          marginBottom: "0px",
          opacity: "0.3",
        }}
      >
        <img alt="..." className="bg-image" src={bgAbstractLine03} />
      </div>
      {hasCreatorRole && (
        <>
          <section className="section section-shaped section-main">
            <Container>
              <Row className="justify-content-center">
                <Col xl="9" lg="10" md="11" sm="12" xs="12">
                  <h3 className="mb-3">
                    <strong>Create Event</strong>
                  </h3>
                  {/* <Card className='shadow'>
                    <CardBody> */}
                  <Form
                    onSubmit={() => {}}
                    validate={(values) => {
                      const errors = {};

                      if (!eventImageUrl) {
                        errors.eventImage = "Event image is required.";
                      }

                      if (!name) {
                        errors.name = "Name is required.";
                      }

                      if (name?.length > 40) {
                        errors.name = "Name must not exceed 40 characters.";
                      }

                      if (description?.length > 3000) {
                        errors.description =
                          "Description must not exceed 3000 characters.";
                      }

                      if (venue?.length > 40) {
                        errors.location =
                          "Venue must not exceed 40 characters.";
                      }

                      if (
                        (isVirtual && virtualLocation?.name?.length > 120) ||
                        (!isVirtual && irlLocation?.name?.length > 120)
                      ) {
                        errors.location =
                          "Location must not exceed 120 characters.";
                      }

                      if (locationError) {
                        errors.location = locationError;
                      }

                      // if (!email) {
                      //   errors.email = "Email is required.";
                      // } else if (!validEmail(email)) {
                      //   errors.email = "Email must have valid format.";
                      // } else if (email.length > 100) {
                      //   errors.email = "Email must not exceed 100 characters.";
                      // }

                      // if (!eventUrl || eventUrl?.length < 10) {
                      //   errors.eventUrl =
                      //     "Event URL must have at least 10 characters.";
                      // }

                      // if (eventUrl?.length > 70) {
                      //   errors.eventUrl =
                      //     "Event URL must not exceed 70 characters.";
                      // }

                      // if (eventUrl && !validUrlSlug(eventUrl)) {
                      //   errors.eventUrl =
                      //     "Event URL can only have nummbers, lowercase letters, or -.";
                      // }

                      // if (!blockchainOption.value) {
                      //   errors.blockchain = "Blockchain is required.";
                      // }

                      // if (!eventCategoryOption.value) {
                      //   errors.category = "Category is required.";
                      // }

                      // if (!startDateMoment) {
                      //   errors.eventSession = "Event session is required.";
                      // }
                      // if (
                      //   startDateMoment?.valueOf() > endDateMoment?.valueOf() ||
                      //   Date.now() > startDateMoment?.valueOf() ||
                      //   Date.now() > endDateMoment?.valueOf()
                      // ) {
                      //   errors.eventSession = "Invalid event duration.";
                      // }

                      // if (!maxUsers) {
                      //   errors.maxUsers = "Members number is required.";
                      // }

                      // if (
                      //   !unlimitedUsers &&
                      //   (isNaN(Number(maxUsers)) || Number(maxUsers) < 0)
                      // ) {
                      //   errors.maxUsers =
                      //     "Members number must be greater than or equal to zero.";
                      // }

                      // if (
                      //   requirePass &&
                      //   (!selectedPass ||
                      //     !validEthAddress(selectedPass?.token_address))
                      // ) {
                      //   errors.passes =
                      //     "Pass Contract must be a valid address.";
                      // }

                      // if (website?.length > 100) {
                      //   errors.links =
                      //     "Website must not exceed 100 characters.";
                      // }

                      // if (videoUrl?.length > 100) {
                      //   errors.links =
                      //     "Video URL must not exceed 100 characters.";
                      // }

                      // if (twitter?.length > 45) {
                      //   errors.links =
                      //     "Twitter handle must not exceed 45 characters.";
                      // }

                      // if (discord?.length > 45) {
                      //   errors.links =
                      //     "Discord handle must not exceed 45 characters.";
                      // }

                      // if (instagram?.length > 45) {
                      //   errors.links =
                      //     "Instagram handle must not exceed 45 characters.";
                      // }

                      // if (passError) {
                      //   errors.passes = passError;
                      // }

                      return errors;
                    }}
                    render={({
                      handleSubmit,
                      values,
                      submitting,
                      validating,
                      valid,
                    }) => (
                      <div className="m-2">
                        <form onSubmit={handleSubmit}>
                          <Row>
                            <Col>
                              <div className="mt-4 mb-4">
                                <FormGroup>
                                  <Field name="eventImage">
                                    {({ input, meta }) => (
                                      <>
                                        <Label>
                                          <div className="required">
                                            <strong>Event Image</strong>
                                            <small>
                                              <FontAwesomeIcon
                                                icon="fa-circle-info"
                                                className="text-muted ml-1"
                                                id="eventImageTooltip"
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              />
                                            </small>
                                            <UncontrolledTooltip
                                              placement="top"
                                              target="eventImageTooltip"
                                              trigger="hover"
                                            >
                                              Used for event navigation
                                            </UncontrolledTooltip>
                                          </div>
                                          <small className="text-muted">
                                            Recommended 600 x 600 1MB
                                          </small>
                                        </Label>
                                        <Label
                                          className="ml-3 image-upload-label"
                                          for="eventUpload"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <img
                                            alt="..."
                                            className="event-image-edit"
                                            src={
                                              eventImageUrl || defaultFormImg
                                            }
                                          />
                                          <div className="image-overlay">
                                            <FontAwesomeIcon
                                              icon="fa-pencil"
                                              className="text-muted hidden-icon"
                                            />
                                          </div>
                                        </Label>
                                        <Input
                                          {...input}
                                          id="eventUpload"
                                          name="file"
                                          type="file"
                                          accept="image/*"
                                          style={{ display: "none" }}
                                          onChange={(e) => {
                                            selectEventImage(e);
                                            setFormChanged(true);
                                          }}
                                        />

                                        {meta.error && meta.touched && (
                                          <small className="text-danger">
                                            <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                            {meta.error}
                                          </small>
                                        )}
                                      </>
                                    )}
                                  </Field>
                                </FormGroup>
                              </div>

                              <div className="mt-4 mb-4">
                                <FormGroup>
                                  <Field name="bannerImage">
                                    {({ input, meta }) => (
                                      <>
                                        <Label>
                                          <div>
                                            <strong>Banner Image</strong>
                                            <small>
                                              <FontAwesomeIcon
                                                icon="fa-circle-info"
                                                className="text-muted ml-1"
                                                id="bannerImageTooltip"
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              />
                                            </small>
                                            <UncontrolledTooltip
                                              placement="top"
                                              target="bannerImageTooltip"
                                              trigger="hover"
                                            >
                                              Show on top of your event page
                                            </UncontrolledTooltip>
                                          </div>
                                          <small className="text-muted">
                                            Recommended 1400x400 1MB
                                          </small>
                                        </Label>
                                        <Label
                                          className="ml-3 image-upload-label"
                                          for="bannerUpload"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <img
                                            alt="..."
                                            className="banner-image-edit"
                                            src={
                                              bannerImageUrl ||
                                              defaultFormBanner
                                            }
                                          />
                                          <div className="image-overlay">
                                            <FontAwesomeIcon
                                              icon="fa-pencil"
                                              className="text-muted hidden-icon"
                                            />
                                          </div>
                                        </Label>
                                        <Input
                                          {...input}
                                          id="bannerUpload"
                                          name="file"
                                          type="file"
                                          accept="image/*"
                                          style={{ display: "none" }}
                                          onChange={(e) => {
                                            selectBannerImage(e);
                                            setFormChanged(true);
                                          }}
                                        />

                                        {meta.error && meta.touched && (
                                          <small className="text-danger">
                                            <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                            {meta.error}
                                          </small>
                                        )}
                                      </>
                                    )}
                                  </Field>
                                </FormGroup>
                              </div>

                              <div className="mt-4 mb-4">
                                <FormGroup>
                                  <Field name="name">
                                    {({ input, meta }) => (
                                      <>
                                        <Label for="name" className="required">
                                          <strong>Name</strong>
                                        </Label>
                                        <Input
                                          {...input}
                                          id="name"
                                          value={name}
                                          onChange={(e) => {
                                            setName(e.target.value);
                                            setFormChanged(true);
                                          }}
                                          placeholder={
                                            name ||
                                            "Event name, eg. Non-fungible Party"
                                          }
                                          type="text"
                                        />

                                        {meta.error && meta.touched && (
                                          <small className="text-danger">
                                            <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                            {meta.error}
                                          </small>
                                        )}
                                      </>
                                    )}
                                  </Field>
                                </FormGroup>
                              </div>

                              <div className="mt-4 mb-4">
                                <FormGroup>
                                  <Field name="category">
                                    {({ input, meta }) => (
                                      <>
                                        <Label for="category">
                                          <div>
                                            <span>
                                              <strong>Category</strong>
                                            </span>
                                          </div>
                                        </Label>
                                        <InputGroup>
                                          <Select
                                            {...input}
                                            className="realpass-selector"
                                            onChange={(e) => {
                                              setCategoryOption(e);
                                              setFormChanged(true);
                                            }}
                                            options={
                                              EventCategorySelectorOptions
                                            }
                                            value={categoryOption}
                                            components={{
                                              IndicatorSeparator: () => null,
                                            }}
                                          />
                                        </InputGroup>

                                        {meta.error && meta.touched && (
                                          <small className="text-danger">
                                            <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                            {meta.error}
                                          </small>
                                        )}

                                        {/* {categoryOption.value === "token" && (
                                          <Alert
                                            color='secondary'
                                            className='text-default mt-2'
                                          >
                                            Is this an NFT Drop? You can create{" "}
                                            <a
                                              href='/create/nft-launch'
                                              target='_blank'
                                              rel='external nofollow noopener'
                                              className='text-default'
                                            >
                                              <u>
                                                <strong>Launch Campaign</strong>
                                              </u>{" "}
                                              <FontAwesomeIcon icon='arrow-up-right-from-square' />
                                            </a>{" "}
                                            instead.
                                          </Alert>
                                        )} */}
                                      </>
                                    )}
                                  </Field>
                                </FormGroup>
                              </div>

                              <div className="mt-4 mb-4">
                                <FormGroup>
                                  <Field name="description">
                                    {({ input, meta }) => (
                                      <>
                                        <Label for="description">
                                          <div>
                                            <strong>Description</strong>
                                          </div>
                                          {/* <small className='text-muted ml-2'>
                                                    Supports basic markdown
                                                    syntax{" "}
                                                    <a
                                                      href='https://www.markdownguide.org/basic-syntax/'
                                                      target='_blank'
                                                      rel='external nofollow noopener'
                                                      className='text-muted'
                                                    >
                                                      ( Learn more{" "}
                                                      <FontAwesomeIcon icon='arrow-up-right-from-square' />{" "}
                                                      )
                                                    </a>
                                                  </small> */}
                                        </Label>
                                        <ReactQuill
                                          theme="snow"
                                          value={description}
                                          modules={QuillEditorModules}
                                          formats={QuillEditorFormats}
                                          onChange={(e) => {
                                            setDescription(e);
                                            setFormChanged(true);
                                          }}
                                          className="mb-2"
                                        />

                                        {meta.error && meta.touched && (
                                          <small className="text-danger">
                                            <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                            {meta.error}
                                          </small>
                                        )}
                                      </>
                                    )}
                                  </Field>
                                </FormGroup>
                              </div>
                              <hr />
                              <div className="my-4">
                                <FormGroup>
                                  <Field name="eventSessions">
                                    {({ input, meta }) => (
                                      <>
                                        <Row>
                                          <Col>
                                            <Label>
                                              <div>
                                                <strong>Event Duration</strong>
                                              </div>

                                              <small className="text-muted">
                                                {isMultiSession
                                                  ? "A series of events"
                                                  : "A single event"}
                                              </small>
                                            </Label>
                                          </Col>
                                          <Col>
                                            <ButtonGroup
                                              role="tablist"
                                              className="float-right"
                                            >
                                              <Button
                                                aria-selected={!isMultiSession}
                                                className={`btn-tab mb-sm-3 mb-md-0
                                                  ${
                                                    !isMultiSession && "active"
                                                  }`}
                                                outline
                                                color="dark"
                                                size="sm"
                                                onClick={(e) => {
                                                  toggleMultiSession(false);
                                                  setFormChanged(true);
                                                }}
                                                type="button"
                                                role="tab"
                                              >
                                                Single
                                              </Button>
                                              <Button
                                                aria-selected={isMultiSession}
                                                className={`btn-tab mb-sm-3 mb-md-0 ${
                                                  isMultiSession && "active"
                                                }`}
                                                outline
                                                color="dark"
                                                size="sm"
                                                onClick={(e) => {
                                                  toggleMultiSession(true);
                                                  setFormChanged(true);
                                                }}
                                                type="button"
                                                role="tab"
                                              >
                                                Multi
                                              </Button>
                                            </ButtonGroup>
                                          </Col>
                                        </Row>
                                        <div className="mt-1">
                                          <InputGroup className="date-range-picker">
                                            <InputGroupText className="date-picker-left text-muted">
                                              <FontAwesomeIcon icon="fa-calendar-days" />
                                            </InputGroupText>

                                            <ReactDatetime
                                              {...input}
                                              className="date-picker-middle"
                                              value={startDateMoment}
                                              inputProps={{
                                                placeholder: "Event Starts",
                                              }}
                                              isValidDate={(now) => {
                                                return now.isAfter(
                                                  moment().subtract(1, "day")
                                                );
                                              }}
                                              onChange={(e) => {
                                                const duration =
                                                  handleReactDatetimeChange(
                                                    "startDate",
                                                    e,
                                                    startDateMoment,
                                                    endDateMoment
                                                  );
                                                setStartDateMoment(
                                                  duration.start
                                                );

                                                if (duration.end) {
                                                  setEndDateMoment(
                                                    duration.end
                                                  );
                                                }

                                                setFormChanged(true);
                                              }}
                                              renderDay={(
                                                props,
                                                currentDate,
                                                selectedDate
                                              ) => {
                                                let classes = props.className;
                                                classes +=
                                                  getClassNameReactDatetimeDays(
                                                    currentDate,
                                                    startDateMoment,
                                                    endDateMoment
                                                  );
                                                return (
                                                  <td
                                                    {...props}
                                                    className={classes}
                                                  >
                                                    {currentDate.date()}
                                                  </td>
                                                );
                                              }}
                                              displayTimeZone={
                                                selectedTimezoneValue
                                              }
                                            />
                                            <ReactDatetime
                                              {...input}
                                              className="date-picker-right rdtPickerOnRight"
                                              value={endDateMoment}
                                              inputProps={{
                                                placeholder: "Event Ends",
                                              }}
                                              isValidDate={(now) => {
                                                return now.isAfter(
                                                  moment().subtract(1, "day")
                                                );
                                              }}
                                              onChange={(e) => {
                                                const duration =
                                                  handleReactDatetimeChange(
                                                    "endDate",
                                                    e,
                                                    startDateMoment,
                                                    endDateMoment
                                                  );
                                                if (duration.start) {
                                                  setStartDateMoment(
                                                    duration.start
                                                  );
                                                }
                                                setEndDateMoment(duration.end);

                                                setFormChanged(true);
                                              }}
                                              renderDay={(
                                                props,
                                                currentDate,
                                                selectedDate
                                              ) => {
                                                let classes = props.className;
                                                classes +=
                                                  getClassNameReactDatetimeDays(
                                                    currentDate,
                                                    startDateMoment,
                                                    endDateMoment
                                                  );
                                                return (
                                                  <td
                                                    {...props}
                                                    className={classes}
                                                  >
                                                    {currentDate.date()}
                                                  </td>
                                                );
                                              }}
                                              displayTimeZone={
                                                selectedTimezoneValue
                                              }
                                            />
                                          </InputGroup>
                                        </div>
                                        {durationError && (
                                          <small className="text-danger">
                                            <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                            {durationError}
                                          </small>
                                        )}
                                        {isMultiSession && (
                                          <>
                                            <div className="mt-3">
                                              <Label>
                                                <div>
                                                  <strong>Sessions</strong>
                                                </div>

                                                <small className="text-muted">
                                                  Add up to 10 sessions
                                                </small>
                                              </Label>
                                              <MultiSessionsEditable
                                                updateSessions={setSessions}
                                                updateFormChanged={
                                                  setFormChanged
                                                }
                                                timezone={selectedTimezoneValue}
                                              />
                                            </div>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </Field>
                                </FormGroup>
                              </div>

                              <div className="my-4">
                                <FormGroup>
                                  <Field name="timezone">
                                    {({ input, meta }) => (
                                      <>
                                        <Row>
                                          <Col>
                                            <Label for="timezone">
                                              <div>
                                                <strong>Timezone</strong>
                                                <small>
                                                  <FontAwesomeIcon
                                                    icon="fa-circle-info"
                                                    className="text-muted ml-1"
                                                    id="timezoneTooltip"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                </small>
                                                <UncontrolledTooltip
                                                  placement="top"
                                                  target="timezoneTooltip"
                                                  trigger="hover"
                                                >
                                                  Your local timezone -{" "}
                                                  {moment.tz.guess()} (
                                                  {moment
                                                    .tz(moment.tz.guess())
                                                    .format("z")}{" "}
                                                  )
                                                </UncontrolledTooltip>
                                              </div>
                                              <span>
                                                <small className="text-muted">
                                                  Selected timezone -{" "}
                                                </small>
                                                <small className="text-info">
                                                  {selectedTimezoneLabel}
                                                </small>
                                              </span>
                                            </Label>
                                          </Col>
                                        </Row>
                                        <div className="select-wrapper">
                                          <TimezoneSelect
                                            className="realpass-selector"
                                            value={selectedTimezone}
                                            onChange={handleTimezoneChange}
                                            timezones={{
                                              ...allTimezones,
                                            }}
                                            components={{
                                              IndicatorSeparator: () => null,
                                            }}
                                          />
                                        </div>
                                      </>
                                    )}
                                  </Field>
                                </FormGroup>
                              </div>

                              <hr />
                              <div className="my-4">
                                <FormGroup>
                                  <Field name="location">
                                    {({ input, meta }) => (
                                      <>
                                        <Row>
                                          <Col className="col-6">
                                            <Label for="location">
                                              <div>
                                                <strong>Location</strong>
                                              </div>
                                              <small className="text-muted">
                                                {isVirtual
                                                  ? "URL for virtual space"
                                                  : "In real fife address"}
                                              </small>
                                            </Label>
                                          </Col>
                                          {/* <UncontrolledPopover
                                                  trigger='hover'
                                                  placement='bottom'
                                                  target='whatsTokengate'
                                                >
                                                  <PopoverHeader className='text-default'>
                                                    <small>
                                                      <strong>
                                                        What is Tokengated
                                                        Access?
                                                      </strong>
                                                    </small>
                                                  </PopoverHeader>
                                                  <PopoverBody className='d-flex p-3'>
                                                    <Row>
                                                      <Col>
                                                        <small>
                                                          Tokengated Access is
                                                          securely controlled
                                                          through dynamic
                                                          on-chain verification
                                                          of the NFT ownership.
                                                          This means that we
                                                          will check if members
                                                          have the required NFT
                                                          Passes to access your
                                                          protected digital
                                                          assets or entering the
                                                          physical venue of the
                                                          event.
                                                        </small>
                                                      </Col>
                                                    </Row>
                                                  </PopoverBody>
                                                </UncontrolledPopover> */}
                                          <Col>
                                            <ButtonGroup
                                              role="tablist"
                                              className="float-right"
                                            >
                                              <Button
                                                aria-selected={!isVirtual}
                                                className={`btn-tab mb-sm-3 mb-md-0
                                                  ${!isVirtual && "active"}`}
                                                outline
                                                color="dark"
                                                size="sm"
                                                onClick={(e) => {
                                                  toggleVirtual(false);
                                                  setFormChanged(true);
                                                }}
                                                type="button"
                                                role="tab"
                                              >
                                                IRL
                                              </Button>
                                              <Button
                                                aria-selected={isVirtual}
                                                className={`btn-tab mb-sm-3 mb-md-0
                                                  ${isVirtual && "active"}`}
                                                outline
                                                color="dark"
                                                size="sm"
                                                onClick={(e) => {
                                                  toggleVirtual(true);
                                                  setFormChanged(true);
                                                }}
                                                type="button"
                                                role="tab"
                                              >
                                                Virtual
                                              </Button>
                                            </ButtonGroup>
                                          </Col>
                                        </Row>
                                        <InputGroup>
                                          {isVirtual ? (
                                            <>
                                              <InputGroupText className="input-group-left pr-2 text-muted">
                                                <i className="ni ni-planet" />
                                              </InputGroupText>
                                              <Input
                                                {...input}
                                                id="location"
                                                className="pl-2"
                                                value={virtualLocation?.name}
                                                onChange={(e) => {
                                                  if (
                                                    e.target.value.length > 120
                                                  ) {
                                                    setLocationError(
                                                      "Location must not exceed 120 characters."
                                                    );
                                                  } else {
                                                    setLocationError();
                                                  }

                                                  setVirtualLocation({
                                                    name: e.target.value,
                                                  });
                                                  setFormChanged(true);
                                                }}
                                                placeholder={"In virtual place"}
                                                type="text"
                                              />{" "}
                                            </>
                                          ) : (
                                            <>
                                              <InputGroupText className="input-group-left pr-2 text-muted">
                                                <i className="ni ni-square-pin" />
                                              </InputGroupText>
                                              <GooglePlaceAutocomplete
                                                className="form-control pl-2"
                                                apiKey={`${REACT_APP_FBASE_API_KEY}&callback=Function.prototype`}
                                                defaultValue={irlLocation?.name}
                                                placeholder="Find Address"
                                                options={{
                                                  types: ["geocode"],
                                                  fields: [
                                                    "name",
                                                    "geometry",
                                                    "formatted_address",
                                                  ],
                                                }}
                                                onChange={(e) => {
                                                  if (
                                                    e.target.value.length > 120
                                                  ) {
                                                    setLocationError(
                                                      "Location must not exceed 120 characters."
                                                    );
                                                  } else {
                                                    setLocationError();
                                                  }
                                                  setFormChanged(true);
                                                }}
                                                onPlaceSelected={(place) => {
                                                  if (
                                                    place.formatted_address
                                                      .length > 120
                                                  ) {
                                                    setLocationError(
                                                      "Location must not exceed 120 characters."
                                                    );
                                                  } else {
                                                    setLocationError();
                                                    setIrlLocation({
                                                      name: place.formatted_address,
                                                      lat: place.geometry.location.lat(),
                                                      lng: place.geometry.location.lng(),
                                                    });
                                                  }
                                                  setFormChanged(true);
                                                }}
                                              />{" "}
                                            </>
                                          )}
                                        </InputGroup>

                                        {((meta.error && meta.touched) ||
                                          locationError) && (
                                          <small className="text-danger">
                                            <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                            {meta.error || locationError}
                                          </small>
                                        )}
                                      </>
                                    )}
                                  </Field>
                                </FormGroup>

                                <FormGroup>
                                  <Field name="venue">
                                    {({ input, meta }) => (
                                      <>
                                        <Label for="venue">
                                          <strong>Venue</strong>
                                        </Label>
                                        <Input
                                          {...input}
                                          id="irlVenue"
                                          value={venue}
                                          onChange={(e) => {
                                            setVenue(e.target.value);
                                            setFormChanged(true);
                                          }}
                                          placeholder={venue || "Venue Name"}
                                          type="text"
                                        />

                                        {meta.error && meta.touched && (
                                          <small className="text-danger">
                                            <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                            {meta.error}
                                          </small>
                                        )}
                                      </>
                                    )}
                                  </Field>
                                </FormGroup>

                                <Row className="mt-4">
                                  <Col className="col-9">
                                    <Label>
                                      <div>
                                        <strong>Restrict Location</strong>
                                      </div>

                                      <small className="text-muted">
                                        Location only visible after registration
                                      </small>
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label className="custom-toggle float-right">
                                      <Input
                                        checked={isLocationRestricted}
                                        type="checkbox"
                                        onChange={() => {
                                          setIsLocationRestricted(
                                            !isLocationRestricted
                                          );
                                          setFormChanged(true);
                                        }}
                                      />
                                      <span className="custom-toggle-slider rounded-circle"></span>
                                    </Label>
                                  </Col>
                                </Row>
                              </div>

                              {/* <div className='mt-4 mb-4'>
                                        <FormGroup>
                                          <Field name='eventUrl'>
                                            {({ input, meta }) => (
                                              <>
                                                <Label for='eventUrl'>
                                                  <div className='required'>
                                                    <strong>Event URL</strong>
                                                  </div>
                                                  <small className='text-muted'>
                                                    Your event URL must be
                                                    unique{" "}
                                                  </small>
                                                </Label>
                                                <InputGroup>
                                                  <InputGroupText className='input-group-left pr-2 text-muted'>
                                                    <FontAwesomeIcon
                                                      icon='fa-dice-d6'
                                                      className='mr-2'
                                                    />{" "}
                                                    realpass.xyz/event/
                                                  </InputGroupText>

                                                  <Input
                                                    {...input}
                                                    id='eventUrl'
                                                    value={eventUrl}
                                                    className='input-group-mid-no-border pl-2'
                                                    onChange={(e) => {
                                                      setNotDuplicateEvent();
                                                      setEventUrl(
                                                        e.target.value.toLowerCase()
                                                      );
                                                    }}
                                                    onBlur={checkEventUrl}
                                                    placeholder={
                                                      eventUrl ||
                                                      " amazing-event-url"
                                                    }
                                                  />

                                                  <InputGroupText
                                                    className='input-group-right pr-2 text-muted'
                                                    style={{
                                                      borderLeft: "0px",
                                                    }}
                                                  >
                                                    {eventUrlLoading ? (
                                                      <ScaleLoader
                                                        color='#eee'
                                                        loading={
                                                          eventUrlLoading
                                                        }
                                                        height='10px'
                                                        cssOverride={{
                                                          display: "inline",
                                                        }}
                                                      />
                                                    ) : (
                                                      notDuplicateEvent && (
                                                        <FontAwesomeIcon
                                                          icon='fa-check'
                                                          className='text-success'
                                                        />
                                                      )
                                                    )}
                                                  </InputGroupText>
                                                </InputGroup>

                                                {eventUrlSuggestion && (
                                                  <div className='mx-1 mb-1'>
                                                    <small className='text-muted'>
                                                      ( Try: realpass.xyz/event/
                                                      <u className='text-info'>
                                                        {eventUrlSuggestion}
                                                      </u>{" "}
                                                      )
                                                    </small>
                                                  </div>
                                                )}

                                                {((meta.error &&
                                                  meta.touched) ||
                                                  eventUrlError) && (
                                                  <small className='text-danger'>
                                                    <FontAwesomeIcon icon='fa-triangle-exclamation' />{" "}
                                                    {meta.error ||
                                                      eventUrlError}
                                                  </small>
                                                )}
                                              </>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </div> */}

                              {/* <div className='mt-4 mb-4'>
                                      <FormGroup>
                                        <Field name='video'>
                                          {({ input, meta }) => (
                                            <>
                                              <Label for='video'>
                                                <div>
                                                  <strong>Video</strong>
                                                </div>
                                                <small className='text-muted'>
                                                  Promo video to be embedded on
                                                  the page{" "}
                                                </small>
                                              </Label>
                                              <InputGroup>
                                                <InputGroupText className='input-group-left pr-2 text-muted'>
                                                  <FontAwesomeIcon icon='fa-brands fa-youtube' />
                                                </InputGroupText>
                                                <Input
                                                  {...input}
                                                  id='videoUrl'
                                                  value={videoUrl}
                                                  className='input-group-right pl-2'
                                                  onChange={(e) => {
                                                    setVideoUrl(
                                                      removeProtocolStr(
                                                        e.target.value
                                                      )
                                                    );
                                                    setFormChanged(true);
                                                  }}
                                                  placeholder={
                                                    videoUrl ||
                                                    "youtube.com/watch?v=your_video"
                                                  }
                                                  type='text'
                                                />
                                              </InputGroup>

                                              {meta.error && meta.touched && (
                                                <small className='text-danger'>
                                                  <FontAwesomeIcon icon='fa-triangle-exclamation' />{" "}
                                                  {meta.error}
                                                </small>
                                              )}
                                            </>
                                          )}
                                        </Field>
                                      </FormGroup>
                                    </div>

                                    <div className='mt-4 mb-4'>
                                      <FormGroup>
                                        <Field name='links'>
                                          {({ input, meta }) => (
                                            <>
                                              <Label for='website'>
                                                <strong>Links</strong>
                                              </Label>
                                              <InputGroup>
                                                <InputGroupText className='input-group-top-left pr-2 text-muted'>
                                                  <FontAwesomeIcon icon='fa-globe' />
                                                </InputGroupText>
                                                <Input
                                                  {...input}
                                                  id='website'
                                                  value={website}
                                                  className='input-group-top-right pl-2'
                                                  onChange={(e) => {
                                                    setWebsite(
                                                      removeProtocolStr(
                                                        e.target.value
                                                      )
                                                    );
                                                    setFormChanged(true);
                                                  }}
                                                  placeholder={
                                                    website || "yoursite.xyz"
                                                  }
                                                  type='text'
                                                />
                                              </InputGroup>
                                              <InputGroup>
                                                <InputGroupText className='input-group-mid-left pr-2 text-muted'>
                                                  <FontAwesomeIcon icon='fa-brands fa-youtube' />
                                                </InputGroupText>
                                                <Input
                                                  {...input}
                                                  id='videoUrl'
                                                  value={videoUrl}
                                                  className='input-group-mid-right pl-2'
                                                  onChange={(e) => {
                                                    setVideoUrl(
                                                      removeProtocolStr(
                                                        e.target.value
                                                      )
                                                    );
                                                    setFormChanged(true);
                                                  }}
                                                  placeholder={
                                                    videoUrl ||
                                                    "youtube.com/watch?v=your_video"
                                                  }
                                                  type='text'
                                                />
                                              </InputGroup>
                                              <InputGroup>
                                                <InputGroupText className='input-group-mid-left pr-2 text-muted'>
                                                  <FontAwesomeIcon icon='fa-brands fa-twitter' />
                                                </InputGroupText>

                                                <Input
                                                  {...input}
                                                  id='twitter'
                                                  value={twitter}
                                                  className='input-group-mid-right pl-2'
                                                  onChange={(e) => {
                                                    setTwitter(
                                                      removeProtocolStr(
                                                        e.target.value
                                                      )
                                                    );
                                                    setFormChanged(true);
                                                  }}
                                                  placeholder={
                                                    twitter ||
                                                    "twitter.com/your_handle"
                                                  }
                                                  type='text'
                                                />
                                              </InputGroup>
                                              <InputGroup>
                                                <InputGroupText className='input-group-mid-left pr-2 text-muted'>
                                                  <FontAwesomeIcon icon='fa-brands fa-discord' />
                                                </InputGroupText>

                                                <Input
                                                  {...input}
                                                  id='discord'
                                                  value={discord}
                                                  className='input-group-mid-right pl-2'
                                                  onChange={(e) => {
                                                    setDiscord(
                                                      removeProtocolStr(
                                                        e.target.value
                                                      )
                                                    );
                                                    setFormChanged(true);
                                                  }}
                                                  placeholder={
                                                    discord ||
                                                    "discord.gg/your_server"
                                                  }
                                                  type='text'
                                                />
                                              </InputGroup>
                                              <InputGroup>
                                                <InputGroupText className='input-group-bottom-left pr-2 text-muted'>
                                                  <FontAwesomeIcon icon='fa-brands fa-instagram' />{" "}
                                                </InputGroupText>

                                                <Input
                                                  {...input}
                                                  id='instagram'
                                                  value={instagram}
                                                  className='input-group-bottom-right pl-2'
                                                  onChange={(e) => {
                                                    setInstagram(
                                                      removeProtocolStr(
                                                        e.target.value
                                                      )
                                                    );
                                                    setFormChanged(true);
                                                  }}
                                                  placeholder={
                                                    instagram ||
                                                    "instagram.com/your_username"
                                                  }
                                                  type='text'
                                                />
                                              </InputGroup>

                                              {meta.error && meta.touched && (
                                                <small className='text-danger'>
                                                  <FontAwesomeIcon icon='fa-triangle-exclamation' />{" "}
                                                  {meta.error}
                                                </small>
                                              )}
                                            </>
                                          )}
                                        </Field>
                                      </FormGroup>
                                    </div>

                                    <div className='mt-4 mb-4'>
                                      <FormGroup>
                                        <Field name='email'>
                                          {({ input, meta }) => (
                                            <>
                                              <Label for='email'>
                                                <div>
                                                  <strong>Support Email</strong>
                                                </div>
                                                <small className='text-muted'>
                                                  Public email for event support{" "}
                                                </small>
                                              </Label>
                                              <InputGroup>
                                                <InputGroupText className='input-group-left pr-2 text-muted'>
                                                  <FontAwesomeIcon icon='fa-envelope' />
                                                </InputGroupText>
                                                <Input
                                                  {...input}
                                                  id='email'
                                                  value={videoUrl}
                                                  className='input-group-right pl-2'
                                                  onChange={(e) => {
                                                    setEmail(e.target.value);
                                                    setFormChanged(true);
                                                  }}
                                                  placeholder={
                                                    email ||
                                                    "youremail@example.com"
                                                  }
                                                  type='text'
                                                />
                                              </InputGroup>

                                              {meta.error && meta.touched && (
                                                <small className='text-danger'>
                                                  <FontAwesomeIcon icon='fa-triangle-exclamation' />{" "}
                                                  {meta.error}
                                                </small>
                                              )}
                                            </>
                                          )}
                                        </Field>
                                      </FormGroup>
                                    </div> */}

                              <hr />

                              <div className="mt-4 mb-4">
                                <Row>
                                  <Col className="col-6">
                                    <Label for="nsfwCheck">
                                      <strong>Mark as NSFW</strong>
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label className="custom-toggle float-right">
                                      <Input
                                        id="nsfwCheck"
                                        checked={isNSFW}
                                        type="checkbox"
                                        onChange={() => {
                                          setIsNSFW(!isNSFW);
                                          setFormChanged(true);
                                        }}
                                      />
                                      <span className="custom-toggle-slider rounded-circle"></span>
                                    </Label>
                                  </Col>
                                </Row>

                                <Alert
                                  color="secondary"
                                  className="text-default"
                                >
                                  <span>
                                    <FontAwesomeIcon
                                      icon="fa-circle-info"
                                      className="mr-2"
                                    />
                                    {isNSFW
                                      ? "Content is marked as NSFW, and will be handled differently."
                                      : "If event contains age restricted content, it needs to be marked as NSFW."}
                                  </span>
                                </Alert>
                              </div>
                              <div className="mt-4 mb-4">
                                <Row>
                                  <Col className="col-6">
                                    <Label>
                                      <strong>Publish Event</strong>
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label className="custom-toggle float-right">
                                      <Input
                                        checked={isPublished}
                                        type="checkbox"
                                        onChange={() => {
                                          setIsPublished(!isPublished);
                                          setFormChanged(true);
                                        }}
                                      />
                                      <span className="custom-toggle-slider rounded-circle"></span>
                                    </Label>
                                  </Col>
                                </Row>

                                <Alert
                                  color="secondary"
                                  className="text-default"
                                >
                                  <span>
                                    <FontAwesomeIcon
                                      icon="fa-circle-info"
                                      className="mr-2"
                                    />
                                    {isPublished
                                      ? "Ready to publish the event. You may change it later."
                                      : "Save as draft. Event will be invisible. You may publish later."}
                                  </span>
                                </Alert>
                              </div>

                              <div className="mt-5 mb-3">
                                <Button
                                  className="btn btn-dark mt-4 btn-block"
                                  color="dark"
                                  type="button"
                                  onClick={saveEvent}
                                  disabled={
                                    !valid || dataSaving || !formChanged
                                  }
                                >
                                  {isPublished
                                    ? "Publish Event"
                                    : "Save as Draft"}

                                  {dataSaving && (
                                    <span className="ml-2">
                                      <ScaleLoader
                                        color="#eee"
                                        loading={dataSaving}
                                        height="10px"
                                        cssOverride={{
                                          display: "inline",
                                        }}
                                      />
                                    </span>
                                  )}
                                </Button>
                              </div>

                              {/* <Card className='shadow card-middle'>
                                  <CardHeader className='bg-white px-1 py-3'>
                                    <h5 className='mb-0'>
                                      <Button
                                        aria-expanded={
                                          openedCollapse === "collapse-2"
                                        }
                                        onClick={() =>
                                          setOpenedCollapse(
                                            openedCollapse === "collapse-2"
                                              ? ""
                                              : "collapse-2"
                                          )
                                        }
                                        className='w-100 text-left'
                                        color='link'
                                        type='button'
                                      >
                                        Step 2: Event Details{" "}
                                        {openedCollapse === "collapse-4" ? (
                                          <FontAwesomeIcon
                                            icon='fa-chevron-up'
                                            className='float-right pt-1'
                                          />
                                        ) : (
                                          <FontAwesomeIcon
                                            icon='fa-chevron-down'
                                            className='float-right pt-1'
                                          />
                                        )}
                                      </Button>
                                    </h5>
                                  </CardHeader> */}

                              {/* <Collapse
                                    isOpen={openedCollapse === "collapse-2"}
                                  >
                                    <CardBody>
                                      {eventCategoryOption.value ===
                                        "token" && (
                                        <Alert color='dark'>
                                          <span>
                                            Is this an NFT Drop? You can create{" "}
                                            <a
                                              href='/create/nft-launch'
                                              target='_blank'
                                              rel='external nofollow noopener'
                                              className='text-white'
                                            >
                                              <u>
                                                <strong>Launch Campaign</strong>
                                              </u>{" "}
                                              <FontAwesomeIcon icon='arrow-up-right-from-square' />
                                            </a>{" "}
                                            instead.
                                          </span>
                                        </Alert>
                                      )}
                                      <div className='mt-4 mb-4'>
                                        <FormGroup>
                                          <Field name='category'>
                                            {({ input, meta }) => (
                                              <>
                                                <Label for='category'>
                                                  <div>
                                                    <span className='required'>
                                                      <strong>Category</strong>
                                                    </span>
                                                  </div>
                                                </Label>

                                                <InputGroup>
                                                  <Select
                                                    {...input}
                                                    className='realpass-selector'
                                                    onChange={(e) => {
                                                      setEventCategoryOption(e);
                                                      setFormChanged(true);
                                                    }}
                                                    options={
                                                      EventCategorySelectorOptions
                                                    }
                                                    value={eventCategoryOption}
                                                    components={{
                                                      IndicatorSeparator: () =>
                                                        null,
                                                    }}
                                                  />
                                                </InputGroup>

                                                {meta.error && meta.touched && (
                                                  <small className='text-danger'>
                                                    <FontAwesomeIcon icon='fa-triangle-exclamation' />{" "}
                                                    {meta.error}
                                                  </small>
                                                )}
                                              </>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </div>

                                      <div className='mt-4 mb-4'>
                                        <FormGroup>
                                          <Field name='eventDuration'>
                                            {({ input, meta }) => (
                                              <>
                                                <Label>
                                                  <div>
                                                    <span className='required'>
                                                      <strong>
                                                        Event Duration
                                                      </strong>
                                                    </span>
                                                    <span className='ml-5'>
                                                      <Input
                                                        {...input}
                                                        className='custom-control-input'
                                                        id='tbaCheck'
                                                        type='checkbox'
                                                        checked={tba}
                                                        onChange={() => {
                                                          toggleTba();
                                                          setFormChanged(true);
                                                        }}
                                                      />
                                                      <Label
                                                        className='custom-control-label'
                                                        htmlFor='tbaCheck'
                                                      >
                                                        <span>TBA</span>
                                                      </Label>
                                                    </span>
                                                  </div>
                                                  <small className='text-muted'>
                                                    {tba ? (
                                                      <>
                                                        Event date to be
                                                        announced
                                                      </>
                                                    ) : (
                                                      <>
                                                        Set event start and end
                                                        date ({" "}
                                                        {moment
                                                          .tz(moment.tz.guess())
                                                          .format("z")}{" "}
                                                        )
                                                        <FontAwesomeIcon
                                                          icon='fa-circle-info'
                                                          className='text-muted ml-1'
                                                          id='timezoneTooltip'
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                        />
                                                        <UncontrolledTooltip
                                                          placement='top'
                                                          target='timezoneTooltip'
                                                          trigger='hover'
                                                        >
                                                          Your local timezone -{" "}
                                                          {moment.tz.guess()} (
                                                          {moment
                                                            .tz(
                                                              moment.tz.guess()
                                                            )
                                                            .format("z")}{" "}
                                                          )
                                                        </UncontrolledTooltip>
                                                      </>
                                                    )}
                                                  </small>
                                                </Label>
                                                <InputGroup className='date-range-picker'>
                                                  <InputGroupText className='date-picker-left text-muted'>
                                                    <FontAwesomeIcon icon='fa-calendar-days' />
                                                  </InputGroupText>

                                                  <ReactDatetime
                                                    {...input}
                                                    className='date-picker-middle'
                                                    value={startDateMoment}
                                                    inputProps={{
                                                      placeholder:
                                                        "Event Starts",
                                                      disabled: tba,
                                                    }}
                                                    onChange={(e) => {
                                                      handleReactDatetimeChange(
                                                        "startDate",
                                                        e
                                                      );
                                                      setFormChanged(true);
                                                    }}
                                                    renderDay={(
                                                      props,
                                                      currentDate,
                                                      selectedDate
                                                    ) => {
                                                      let classes =
                                                        props.className;
                                                      classes +=
                                                        getClassNameReactDatetimeDays(
                                                          currentDate
                                                        );
                                                      return (
                                                        <td
                                                          {...props}
                                                          className={classes}
                                                        >
                                                          {currentDate.date()}
                                                        </td>
                                                      );
                                                    }}
                                                  />
                                                  <ReactDatetime
                                                    {...input}
                                                    className='date-picker-right rdtPickerOnRight'
                                                    value={endDateMoment}
                                                    inputProps={{
                                                      placeholder: "Event Ends",
                                                      disabled: tba,
                                                    }}
                                                    onChange={(e) => {
                                                      handleReactDatetimeChange(
                                                        "endDate",
                                                        e
                                                      );
                                                      setFormChanged(true);
                                                    }}
                                                    renderDay={(
                                                      props,
                                                      currentDate,
                                                      selectedDate
                                                    ) => {
                                                      let classes =
                                                        props.className;
                                                      classes +=
                                                        getClassNameReactDatetimeDays(
                                                          currentDate
                                                        );
                                                      return (
                                                        <td
                                                          {...props}
                                                          className={classes}
                                                        >
                                                          {currentDate.date()}
                                                        </td>
                                                      );
                                                    }}
                                                  />
                                                </InputGroup>

                                                {meta.error && meta.touched && (
                                                  <small className='text-danger'>
                                                    <FontAwesomeIcon icon='fa-triangle-exclamation' />{" "}
                                                    {meta.error}
                                                  </small>
                                                )}
                                              </>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </div>

                                      <div className='mt-4 mb-4'>
                                        <FormGroup>
                                          <Field name='location'>
                                            {({ input, meta }) => (
                                              <>
                                                <Label for='location'>
                                                  <div>
                                                    <strong>Location</strong>

                                                    <span className='ml-5'>
                                                      <Input
                                                        {...input}
                                                        className='custom-control-input'
                                                        id='virtualCheck'
                                                        type='checkbox'
                                                        checked={isVirtual}
                                                        onChange={() => {
                                                          toggleLocation();
                                                          setFormChanged(true);
                                                        }}
                                                      /> */}
                              {/* <Label
                                                        className='custom-control-label'
                                                        htmlFor='virtualCheck'
                                                      >
                                                        <span>
                                                          Virtual Space
                                                        </span>{" "}
                                                        (
                                                        <a
                                                          id='whatsTokengate'
                                                          className='text-info'
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={(e) =>
                                                            e.preventDefault()
                                                          }
                                                        >
                                                          {" "}
                                                          <u>
                                                            Tokengated Access
                                                          </u>{" "}
                                                        </a>
                                                        )
                                                      </Label>
                                                    </span>
                                                  </div>
                                                  <small className='text-muted'>
                                                    {isVirtual
                                                      ? "URL for virtual event or digital assets "
                                                      : "Enter the location in real life"}
                                                  </small>
                                                </Label> */}
                              {/* <UncontrolledPopover
                                                  trigger='hover'
                                                  placement='bottom'
                                                  target='whatsTokengate'
                                                >
                                                  <PopoverHeader className='text-default'>
                                                    <small>
                                                      <strong>
                                                        What is Tokengated
                                                        Access?
                                                      </strong>
                                                    </small>
                                                  </PopoverHeader>
                                                  <PopoverBody className='d-flex p-3'>
                                                    <Row>
                                                      <Col>
                                                        <small>
                                                          Tokengated Access is
                                                          securely controlled
                                                          through dynamic
                                                          on-chain verification
                                                          of the NFT ownership.
                                                          This means that we
                                                          will check if members
                                                          have the required NFT
                                                          Passes to access your
                                                          protected digital
                                                          assets or entering the
                                                          physical venue of the
                                                          event.
                                                        </small>
                                                      </Col>
                                                    </Row>
                                                  </PopoverBody>
                                                </UncontrolledPopover> */}

                              {/* <InputGroup>
                                                  {isVirtual ? (
                                                    <>
                                                      <InputGroupText className='input-group-left pr-2 text-muted'>
                                                        <i className='ni ni-planet' />
                                                      </InputGroupText>
                                                      <Input
                                                        {...input}
                                                        id='location'
                                                        className='pl-2'
                                                        value={location?.name}
                                                        onChange={(e) => {
                                                          setLocation({
                                                            name: e.target
                                                              .value,
                                                          });
                                                          setFormChanged(true);
                                                        }}
                                                        placeholder={
                                                          "In virtual place"
                                                        }
                                                        type='text'
                                                      />{" "}
                                                    </>
                                                  ) : (
                                                    <>
                                                      <InputGroupText className='input-group-left pr-2 text-muted'>
                                                        <FontAwesomeIcon icon='fa-location-dot' />
                                                      </InputGroupText>
                                                      <GooglePlaceAutocomplete
                                                        className='form-control pl-2'
                                                        apiKey={
                                                          REACT_APP_FBASE_API_KEY
                                                        }
                                                        options={{
                                                          types: ["geocode"],
                                                          fields: [
                                                            "name",
                                                            "geometry",
                                                            "formatted_address",
                                                          ],
                                                        }}
                                                        onPlaceSelected={(
                                                          place
                                                        ) => {
                                                          if (
                                                            place
                                                              .formatted_address
                                                              .length > 120
                                                          ) {
                                                            setLocationError(
                                                              "Location must not exceed 120 characters."
                                                            );
                                                          } else {
                                                            setLocationError();
                                                            setLocation({
                                                              name: place.formatted_address,
                                                              lat: place.geometry.location.lat(),
                                                              lng: place.geometry.location.lng(),
                                                            });
                                                          }
                                                          setFormChanged(true);
                                                        }}
                                                      />{" "}
                                                    </>
                                                  )}
                                                </InputGroup>

                                                {meta.error &&
                                                  (meta.touched ||
                                                    locationError) && (
                                                    <small className='text-danger'>
                                                      <FontAwesomeIcon icon='fa-triangle-exclamation' />{" "}
                                                      {meta.error}
                                                    </small>
                                                  )}
                                              </>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </div>

                                      <div className='mt-4 mb-4'>
                                        <FormGroup>
                                          <Field name='advanced'>
                                            {({ input, meta }) => (
                                              <>
                                                <Label for='advanced'>
                                                  <div>
                                                    <strong>Advanced</strong>

                                                    <span className='ml-5'>
                                                      <Input
                                                        {...input}
                                                        className='custom-control-input'
                                                        id='secretCheck'
                                                        type='checkbox'
                                                        checked={secretEnabled}
                                                        onChange={() => {
                                                          toggleSecret();
                                                          setFormChanged(true);
                                                        }}
                                                      />
                                                      <Label
                                                        className='custom-control-label'
                                                        htmlFor='secretCheck'
                                                      >
                                                        <span>
                                                          Enable Secret
                                                        </span>{" "}
                                                        (
                                                        <a
                                                          id='whatsSecretCode'
                                                          className='text-info'
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={(e) =>
                                                            e.preventDefault()
                                                          }
                                                        >
                                                          {" "}
                                                          <u>
                                                            What is Secret?
                                                          </u>{" "}
                                                        </a>
                                                        )
                                                      </Label>
                                                    </span>
                                                  </div>
                                                  <small className='text-muted'>
                                                    Additional code for
                                                    protected content
                                                  </small>
                                                </Label>
                                                <UncontrolledPopover
                                                  trigger='hover'
                                                  placement='bottom'
                                                  target='whatsSecretCode'
                                                >
                                                  <PopoverHeader className='text-default'>
                                                    <small>
                                                      <strong>
                                                        What is Secret Code?
                                                      </strong>
                                                    </small>
                                                  </PopoverHeader>
                                                  <PopoverBody className='d-flex p-3'>
                                                    <Row>
                                                      <Col>
                                                        <small>
                                                          Secret Code is the
                                                          additional code you
                                                          provide to users to
                                                          access protected
                                                          venues, digital
                                                          assets, and exclusive
                                                          deals, eg. it can be a{" "}
                                                          <strong>
                                                            promo code
                                                          </strong>{" "}
                                                          for your{" "}
                                                          <strong>
                                                            Shopify
                                                          </strong>{" "}
                                                          online store,{" "}
                                                          <strong>
                                                            Meeting Code
                                                          </strong>{" "}
                                                          on{" "}
                                                          <strong>Zoom</strong>{" "}
                                                          , or a password for
                                                          people to enter the
                                                          private venue hosted
                                                          on{" "}
                                                          <strong>
                                                            Airbnb
                                                          </strong>{" "}
                                                          . Secret Code is
                                                          protected with
                                                          Tokengated Access and
                                                          will only be revealed
                                                          to eligible users
                                                          during the event.
                                                        </small>
                                                      </Col>
                                                    </Row>
                                                  </PopoverBody>
                                                </UncontrolledPopover>

                                                <Alert
                                                  color='secondary'
                                                  className='text-default'
                                                >
                                                  <span>
                                                    People need this code to
                                                    access the protected
                                                    content. You may change it
                                                    later.
                                                  </span>
                                                  {secretEnabled && (
                                                    <Alert
                                                      color='dark'
                                                      className='mt-1 p-3'
                                                    >
                                                      <div>Example:</div>
                                                      {isVirtual ? (
                                                        <>
                                                          <span
                                                            style={{
                                                              color: "gold",
                                                            }}
                                                          >
                                                            <strong>
                                                              <FontAwesomeIcon
                                                                icon='fa-shield'
                                                                className='mr-1'
                                                              />
                                                              Protect URL:
                                                            </strong>
                                                          </span>{" "}
                                                          <Badge
                                                            pill
                                                            color='white'
                                                            className='text-dark badge-no-transform px-2'
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "16px",
                                                              }}
                                                            >
                                                              {location?.name
                                                                ? `${location?.name}`
                                                                : "yoursite.com/"}
                                                              <strong>
                                                                {`?${
                                                                  secretName ||
                                                                  "promo"
                                                                }=${
                                                                  secretCode ||
                                                                  "freebie"
                                                                }`}
                                                              </strong>
                                                            </span>
                                                          </Badge>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <span
                                                            style={{
                                                              color: "gold",
                                                            }}
                                                          >
                                                            <strong>
                                                              <FontAwesomeIcon
                                                                icon='fa-shield'
                                                                className='mr-1'
                                                              />
                                                              Enter{" "}
                                                              {secretName ||
                                                                "Passcode"}
                                                              :
                                                            </strong>
                                                          </span>{" "}
                                                          <Badge
                                                            pill
                                                            color='white'
                                                            className='text-dark px-2'
                                                          >
                                                            <strong
                                                              style={{
                                                                fontSize:
                                                                  "16px",
                                                              }}
                                                            >
                                                              {secretCode ||
                                                                "123xyz"}
                                                            </strong>
                                                          </Badge>
                                                        </>
                                                      )}
                                                    </Alert>
                                                  )}
                                                </Alert>

                                                {secretEnabled && (
                                                  <InputGroup>
                                                    <>
                                                      <InputGroupText className='input-group-left pr-2 text-muted'>
                                                        <FontAwesomeIcon icon='fa-vault' />{" "}
                                                      </InputGroupText>
                                                      <Input
                                                        id='name'
                                                        className='input-group-mid pl-2'
                                                        onChange={(e) =>
                                                          setSecretName(
                                                            e.target.value
                                                          )
                                                        }
                                                        placeholder={
                                                          secretName ||
                                                          "Name eg. promo"
                                                        }
                                                        type='text'
                                                        required
                                                      />
                                                      <InputGroupText className='input-group-mid-no-border text-muted'>
                                                        <FontAwesomeIcon icon='fa-key' />{" "}
                                                      </InputGroupText>
                                                      <Input
                                                        id='secretCode'
                                                        className='input-group-right pl-2'
                                                        onChange={(e) =>
                                                          setSecretCode(
                                                            e.target.value
                                                          )
                                                        }
                                                        placeholder={
                                                          secretCode ||
                                                          "Secret eg. freebie"
                                                        }
                                                        type='text'
                                                        required
                                                      />
                                                    </>
                                                  </InputGroup>
                                                )}

                                                {meta.error && meta.touched && (
                                                  <small className='text-danger'>
                                                    <FontAwesomeIcon icon='fa-triangle-exclamation' />{" "}
                                                    {meta.error}
                                                  </small>
                                                )}
                                              </>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </div>
                                    </CardBody>
                                  </Collapse>
                                </Card> */}

                              {/* <Card className='shadow card-middle'>
                                  <CardHeader className='bg-white px-1 py-3'>
                                    <h5 className='mb-0'>
                                      <Button
                                        aria-expanded={
                                          openedCollapse === "collapse-3"
                                        }
                                        onClick={() =>
                                          setOpenedCollapse(
                                            openedCollapse === "collapse-3"
                                              ? ""
                                              : "collapse-3"
                                          )
                                        }
                                        className='w-100 text-left'
                                        color='link'
                                        type='button'
                                      >
                                        Step 3: Registration{" "}
                                        {openedCollapse === "collapse-3" ? (
                                          <FontAwesomeIcon
                                            icon='fa-chevron-up'
                                            className='float-right pt-1'
                                          />
                                        ) : (
                                          <FontAwesomeIcon
                                            icon='fa-chevron-down'
                                            className='float-right pt-1'
                                          />
                                        )}
                                      </Button>
                                    </h5>
                                  </CardHeader>
                                  <Collapse
                                    isOpen={openedCollapse === "collapse-3"}
                                  >
                                    <CardBody>
                                      <Alert color='dark'>
                                        <span>
                                          You may grant access to notable NFT
                                          community or create{" "}
                                          <a
                                            href='/create/pass'
                                            target='_blank'
                                            rel='external nofollow noopener'
                                            className='text-white'
                                          >
                                            <u>
                                              <strong>New NFT Pass</strong>
                                            </u>{" "}
                                            <FontAwesomeIcon icon='arrow-up-right-from-square' />
                                          </a>{" "}
                                        </span>
                                      </Alert>
                                      <div className='mt-4 mb-4'>
                                        <FormGroup>
                                          <Field name='blockchain'>
                                            {({ input, meta }) => (
                                              <>
                                                <Label
                                                  for='blockchain'
                                                  className='required'
                                                >
                                                  <strong>Blockchain</strong>
                                                </Label>
                                                <Select
                                                  {...input}
                                                  id='blockchain'
                                                  className='realpass-selector'
                                                  onChange={(e) => {
                                                    setBlockchainOption(e);
                                                    setFormChanged(true);
                                                  }}
                                                  options={ChainSelectorOptions}
                                                  value={blockchainOption}
                                                  components={{
                                                    IndicatorSeparator: () =>
                                                      null,
                                                  }}
                                                />

                                                {meta.error && meta.touched && (
                                                  <small className='text-danger'>
                                                    <FontAwesomeIcon icon='fa-triangle-exclamation' />{" "}
                                                    {meta.error}
                                                  </small>
                                                )}
                                              </>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </div>

                                      <div className='mt-4 mb-4'>
                                        <FormGroup>
                                          <Field name='passes'>
                                            {({ input, meta }) => (
                                              <>
                                                <Label for='passes'>
                                                  <div>
                                                    <span className='required'>
                                                      <strong>
                                                        Who can join?
                                                      </strong>
                                                    </span>
                                                    <span className='ml-5'>
                                                      <Input
                                                        {...input}
                                                        className='custom-control-input'
                                                        id='requirePassCheck'
                                                        type='checkbox'
                                                        checked={requirePass}
                                                        onChange={() => {
                                                          toggleRequirePass();
                                                          setFormChanged(true);
                                                        }}
                                                      />
                                                      <Label
                                                        className='custom-control-label'
                                                        htmlFor='requirePassCheck'
                                                      >
                                                        <span>
                                                          Pass Holders Only
                                                        </span>
                                                      </Label>
                                                    </span>
                                                  </div>

                                                  <div>
                                                    <small className='text-muted ml-2'>
                                                      You cannot change it after
                                                      event starts
                                                    </small>
                                                  </div>
                                                </Label>

                                                {requirePass ? (
                                                  <>
                                                    <Alert
                                                      color='secondary'
                                                      className='text-default'
                                                    >
                                                      <div className='mb-2'>
                                                        Find smart contract of{" "}
                                                        <a
                                                          id='whyERC721'
                                                          className='text-info'
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={(e) =>
                                                            e.preventDefault()
                                                          }
                                                        >
                                                          <u>ERC721 NFT</u>
                                                        </a>{" "}
                                                      </div>
                                                      <InputGroup>
                                                        <InputGroupText className='input-group-left pr-2 pass-search-prefix'>
                                                          {searchLoading ? (
                                                            <span className='loading-sm'></span>
                                                          ) : (
                                                            <FontAwesomeIcon
                                                              icon='fa-ticket'
                                                              className='mr-2'
                                                            />
                                                          )}
                                                        </InputGroupText>
                                                        <Autosuggest
                                                          suggestions={
                                                            passSuggestions
                                                          }
                                                          onSuggestionsFetchRequested={
                                                            onPassSuggestionsFetch
                                                          }
                                                          onSuggestionsClearRequested={
                                                            onPassSuggestionsClear
                                                          }
                                                          getSuggestionValue={
                                                            getPassSuggestionValue
                                                          }
                                                          renderSuggestion={
                                                            renderPassSuggestion
                                                          }
                                                          onSuggestionSelected={
                                                            onPassSelected
                                                          }
                                                          renderInputComponent={(
                                                            inputProps
                                                          ) =>
                                                            renderPassInput(
                                                              input,
                                                              inputProps
                                                            )
                                                          }
                                                          inputProps={
                                                            passInputProps
                                                          }
                                                        />
                                                      </InputGroup>

                                                      {meta.error &&
                                                        passInputChanged && (
                                                          <small className='text-danger'>
                                                            <FontAwesomeIcon icon='fa-triangle-exclamation' />{" "}
                                                            {meta.error}
                                                          </small>
                                                        )}

                                                      <div className='mt-3'>
                                                        <Row>{showPass}</Row>
                                                      </div>
                                                    </Alert>
                                                    <UncontrolledPopover
                                                      trigger='hover'
                                                      placement='bottom'
                                                      target='whyERC721'
                                                    >
                                                      <PopoverHeader className='text-default'>
                                                        <small>
                                                          <strong>
                                                            Why ERC721?
                                                          </strong>
                                                        </small>
                                                      </PopoverHeader>
                                                      <PopoverBody className='d-flex p-3'>
                                                        <Row>
                                                          <Col>
                                                            <small>
                                                              ERC721 is the
                                                              non-fungible token
                                                              standard that
                                                              supports unique
                                                              Token IDs where
                                                              each ID belongs to
                                                              one owner. ERC1155
                                                              is semi-fungible
                                                              where each ID may
                                                              have many owners.
                                                              ERC721 is much
                                                              better to track
                                                              ownerships and
                                                              provenance than
                                                              ERC1155. Most NFT
                                                              marketplaces
                                                              support ERC721.
                                                            </small>
                                                          </Col>
                                                        </Row>
                                                      </PopoverBody>
                                                    </UncontrolledPopover>
                                                  </>
                                                ) : (
                                                  <>
                                                    <Alert
                                                      color='secondary'
                                                      className='text-default'
                                                    >
                                                      <div className='mb-2'>
                                                        Anyone can join the
                                                        event. Are you sure?{" "}
                                                      </div>
                                                      <Toast className='bg-secondary'>
                                                        <ToastHeader>
                                                          <div>
                                                            <FontAwesomeIcon
                                                              icon='fa-ticket'
                                                              className='mr-2 text-primary'
                                                            />
                                                            <strong className='text-primary'>
                                                              No Pass Required
                                                            </strong>
                                                          </div>
                                                        </ToastHeader>
                                                        <ToastBody className='bg-secondary'>
                                                          <div className='my-2 mx-5'>
                                                            <Button
                                                              block
                                                              color='primary'
                                                              type='button'
                                                              onClick={(e) =>
                                                                e.preventDefault()
                                                              }
                                                            >
                                                              <h3 className='display-3 text-center text-white'>
                                                                FREE
                                                              </h3>
                                                            </Button>
                                                          </div>
                                                        </ToastBody>
                                                      </Toast>
                                                    </Alert>
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </div>

                                      <div className='mt-4 mb-4'>
                                        <FormGroup>
                                          <Field name='maxUsers'>
                                            {({ input, meta }) => (
                                              <>
                                                <Label for='maxUsers'>
                                                  <div>
                                                    <span className='required'>
                                                      <strong>Members</strong>
                                                    </span>
                                                    <span className='ml-5'>
                                                      <Input
                                                        {...input}
                                                        className='custom-control-input'
                                                        id='unlimitedUsersCheck'
                                                        type='checkbox'
                                                        checked={unlimitedUsers}
                                                        onChange={() => {
                                                          toggleUnlimitedUsers();
                                                          setFormChanged(true);
                                                        }}
                                                      />
                                                      <Label
                                                        className='custom-control-label'
                                                        htmlFor='unlimitedUsersCheck'
                                                      >
                                                        <span>
                                                          Unlimited Members
                                                        </span>
                                                      </Label>
                                                    </span>
                                                  </div>
                                                  <small className='text-muted'>
                                                    Number of members can join
                                                    the event
                                                  </small>
                                                </Label>
                                                <InputGroup>
                                                  <InputGroupText className='input-group-left pr-2'>
                                                    <FontAwesomeIcon
                                                      icon='fa-users'
                                                      className='mr-2'
                                                    />
                                                    Max
                                                  </InputGroupText>
                                                  <Input
                                                    {...input}
                                                    id='maxUsers'
                                                    value={maxUsers}
                                                    className='pl-2'
                                                    onChange={(e) => {
                                                      setMaxUsers(
                                                        e.target.value
                                                      );
                                                      setFormChanged(true);
                                                    }}
                                                    placeholder={
                                                      !unlimitedUsers
                                                        ? maxUsers
                                                        : "∞"
                                                    }
                                                    disabled={unlimitedUsers}
                                                    required
                                                  />
                                                </InputGroup>

                                                {meta.error && meta.touched && (
                                                  <small className='text-danger'>
                                                    <FontAwesomeIcon icon='fa-triangle-exclamation' />{" "}
                                                    {meta.error}
                                                  </small>
                                                )}
                                              </>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </div>

                                      <div className='mt-4 mb-4'>
                                        <Row>
                                          <Col
                                            className='mr--3'
                                            xl='3'
                                            lg='3'
                                            md='3'
                                            sm='4'
                                            xs='5'
                                          >
                                            <Label>
                                              <strong>
                                                Enable Registration
                                              </strong>
                                            </Label>
                                          </Col>
                                          <Col>
                                            <Label className='custom-toggle'>
                                              <Input
                                                checked={registerEnabled}
                                                type='checkbox'
                                                onChange={() => {
                                                  setRegisterEnabled(
                                                    !registerEnabled
                                                  );
                                                  setFormChanged(true);
                                                }}
                                              />
                                              <span
                                                className='custom-toggle-slider rounded-circle'
                                              ></span>
                                            </Label>
                                          </Col>
                                        </Row>

                                        <Alert
                                          color='secondary'
                                          className='text-default'
                                        >
                                          <span>
                                            {registerEnabled
                                              ? "People can register to join on the event page now."
                                              : "Must turn this on to allow people to join. You may change it later."}
                                          </span>
                                        </Alert>
                                      </div>

                                      <div className='mt-4 mb-4'>
                                        <div>
                                          <Label for='privateCheck'>
                                            <strong>Is it private?</strong>
                                          </Label>
                                          <span className='ml-5'>
                                            <Input
                                              checked={!isPublic}
                                              value={!isPublic}
                                              id='privateCheck'
                                              className='custom-control-input'
                                              type='checkbox'
                                              onChange={() =>
                                                setIsPublic(!isPublic)
                                              }
                                            />
                                            <Label
                                              className='custom-control-label'
                                              htmlFor='privateCheck'
                                            >
                                              <span>
                                                Invite or QR Access Only
                                              </span>
                                            </Label>
                                          </span>
                                        </div>
                                        <Alert
                                          color='secondary'
                                          className='text-default'
                                        >
                                          <span>
                                            {isPublic ? (
                                              <>
                                                This is a public event. People
                                                can join by registering on the
                                                event page.
                                              </>
                                            ) : (
                                              <>
                                                This is a private event. People
                                                need an{" "}
                                                <strong>Invite Code</strong> to
                                                register or{" "}
                                                <strong>Scan QR Code</strong> at
                                                the event.
                                              </>
                                            )}
                                          </span>
                                        </Alert>
                                      </div> */}

                              {/* <div className='mt-4 mb-4'>
                              <Label for='walletAddressButton'>
                                <div>
                                  <strong>Creator Wallet</strong>
                                  <small className='text-muted ml-2'>
                                    Wallet address to collect the payment
                                  </small>
                                </div>
                              </Label>

                              <InputGroup>
                                <Button
                                  id='walletAddressButton'
                                  outline
                                  type='button'
                                  style={{ textTransform: "none" }}
                                  onClick={() =>
                                    navigator.clipboard.writeText(
                                      currentUserInfo?.ethAddress
                                    )
                                  }
                                >
                                  <img
                                    className='icon-eth mr-2'
                                    alt='...'
                                    src={ethImg}
                                  />
                                  <span className='wallet-field-md'>
                                    {currentUserInfo?.ethAddress}
                                    <FontAwesomeIcon
                                      icon='fa-clone'
                                      className='ml-1 text-muted'
                                    />
                                  </span>
                                </Button>
                                <UncontrolledTooltip
                                  placement='top'
                                  target='walletAddressButton'
                                  trigger='click'
                                >
                                  Address Copied
                                </UncontrolledTooltip>
                              </InputGroup>
                            </div> */}
                              {/* </CardBody>
                                  </Collapse>
                                </Card> */}
                            </Col>
                          </Row>
                        </form>
                      </div>
                    )}
                  />
                  {/* </CardBody>
                  </Card> */}
                </Col>
              </Row>
            </Container>
          </section>
        </>
      )}
    </>
  );
}
