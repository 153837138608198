import React, { useState, useEffect, useContext } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";

import { AuthContext } from "Auth.js";

import QRCode from "react-qr-code";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// reactstrap components
import {
  Alert,
  Button,
  Badge,
  Card,
  Container,
  Label,
  Input,
  Row,
  Col,
  NavbarBrand,
  Toast,
  ToastHeader,
  ToastBody,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import TopNavbar from "components/Navbars/TopNavbar.js";

import Loader from "components/Loaders/Loader.js";
import LoaderModal from "components/Loaders/LoaderModal.js";

import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.compat.css";

import Select from "react-select";
import makeAnimated from "react-select/animated";

import Web3 from "web3";

// import { RandomHash } from "random-hash";

import ReactMarkdown from "react-markdown";

import {
  truncateAddressShort,
  truncateText,
  notify,
  checksumEthAddress,
} from "utils/Utils.js";

const defaultProfileImg = require("assets/img/default/profile.svg");
const defaultEventImg = require("assets/img/default/rpbox.svg");
const defaultBannerImg = require("assets/img/banners/gradient-banner.svg");
const defaultPassImg = require("assets/img/default/rpbox.svg");
const verifiedImg = require("assets/img/icons/verified-blue.svg");
const nsfwImg = require("assets/img/icons/nsfw.svg");
const logoWhiteImg = require("assets/img/logos/logo-white.svg");
const logoRoundImg = require("assets/img/logos/logo-round.svg");
const nfChipImg = require("assets/img/logos/realpass-chip-icon.svg");
const ethImg = require("assets/img/chains/eth-gray.svg");
const ethWhiteImg = require("assets/img/chains/eth-white.svg");
const polygonImg = require("assets/img/chains/polygon-gray.svg");
const polygonWhiteImg = require("assets/img/chains/polygon-white.svg");

const { REACT_APP_HOST } = process.env;

export default function MyAccessVerify() {
  const { currentUser, currentAuthToken, currentUserInfo, setCurrentUserInfo } =
    useContext(AuthContext);

  const navigate = useNavigate();

  const [ethAddress, setEthAddress] = useState(currentUserInfo?.ethAddress);

  const { eventId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const ehash = searchParams.get("eventHash");

  const [pageLoading, setPageLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const [image, setImage] = useState();
  const [name, setName] = useState("Unnamed");

  const [eventImage, setEventImage] = useState();
  const [eventBannerImage, setBannerImage] = useState();
  const [eventName, setEventName] = useState();

  const [registerEnabled, setRegisterEnabled] = useState();
  const [accessEnabled, setAccessEnabled] = useState();
  const [requireUniquePassId, setRequireUniquePassId] = useState();
  const [freePass, setFreePass] = useState(false);
  const [passContract, setPassContract] = useState();
  const [passName, setPassName] = useState();
  const [passSymbol, setPassSymbol] = useState();
  const [passTokenId, setPassTokenId] = useState();
  const [passType, setPassType] = useState();
  const [blockchain, setBlockchain] = useState();

  const [grantedByCreator, setGrantedByCreator] = useState(false);

  const [eventStartDate, setEventStartDate] = useState();
  const [eventEndDate, setEventEndDate] = useState();
  const [eventEnded, setEventEnded] = useState();

  const [totalReg, setTotalReg] = useState(0);
  const [maxParticipants, setMaxParticipants] = useState();
  const [spotsFull, setSpotsFull] = useState();

  const [isNSFW, setIsNSFW] = useState();

  const [tokenUrl, setTokenUrl] = useState();

  const [passVerified, setPassVerified] = useState();

  const [accessValid, setAccessValid] = useState();
  const [accessKey, setAccessKey] = useState();
  const [accessQRCode, setAccessQRCode] = useState(REACT_APP_HOST);
  const [accessHashCode, setAccessHashCode] = useState();

  const [qrModalOpen, setQRModalOpen] = useState(false);
  const [accessCreatedAt, setAccessCreatedAt] = useState();
  const [hasRegistered, setHasRegistered] = useState();
  const [qrRefreshCountdown, setQRRefreshCountdown] = useState(5);

  const [creatorEthAddress, setCreatorEthAddress] = useState();
  const [creatorName, setCreatorName] = useState();

  const [isNFVerified, setIsNFVerified] = useState(false);

  const [passError, setPassError] = useState();

  const animatedComponents = makeAnimated();
  const [userTotalPassHolding, setUserTotalPassHolding] = useState(0);
  const [userTokenOptions, setUserTokenOptions] = useState([]);
  const [userTokenOption, setUserTokenOption] = useState();
  const [eligibleToJoin, setEligibleToJoin] = useState(false);
  const [registerModalOpen, setRegisterModalOpen] = useState(false);

  const [confirmAge, setConfirmAge] = useState(false);

  const signingMsg = `Welcome to RealPass!
  \nBy signing this message, you accept RealPass Terms and Privacy Policy.
  \nYou need to sign the message to verify your wallet and continue.
  \nSigning is a safe and gas-less. It doesn't give RealPass permission to send transactions with your wallet.`;

  const fetchCurrentUserProfile = async () => {
    try {
      //   profileQuery.equalTo("user", currentUser);
      //   nfprofile = await profileQuery.first();
      //   if (nfprofile) {
      //     setImage(nfprofile?.get("image")?.url());
      //     setName(nfprofile?.get("name"));
      //   }
    } catch (err) {
      console.error(err.message);
      notify("Unable to fetch current user profile.", "error");
    }
  };

  const signMsg = async () => {
    if (!currentUser) {
      //   await authenticate({
      //     signingMessage: signingMsg,
      //   });
      //   currentUser = Moralis.User.current();
      //   if (!currentUser) {
      //     throw { message: "You must connect your wallet and verify the Pass." };
      //   }
      //   setEthAddress(currentUser?.attributes.ethAddress);
      //   await fetchCurrentUserProfile();
    }
  };

  const fecthAccess = async () => {
    try {
      setPageLoading(true);

      //   eventQuery.equalTo("objectId", eventId);
      //   nfevent = await eventQuery.first();

      //   if (!nfevent) {
      //     throw { message: "Event QR Code is invalid. Please try again." };
      //   }

      //   const name = nfevent.get("name");
      //   const image = nfevent.get("eventImage");
      //   const banner = nfevent.get("bannerImage");
      //   const contract = nfevent.get("passContract");
      //   const creator = nfevent.get("creator");
      //   const startTime = nfevent.get("startDateTime");
      //   const endTime = nfevent.get("endDateTime");
      //   const nsfw = nfevent.get("isNSFW");
      //   const maxParts = nfevent.get("maxParticipants");
      //   const accEnabled = nfevent.get("accessEnabled");
      //   const regEnabled = nfevent.get("registerEnabled");
      //   const uniqueId = nfevent.get("requireUniquePassId");

      //   //TODO: remove rinkeby
      //   const chain = nfevent.get("blockchain") === "eth" ? "rinkeby" : "mumbai";
      //   // const chain = nfevent.get("blockchain");

      //   setEventName(name);
      //   setEventImage(image?.url());
      //   setBannerImage(banner?.url());
      //   setBlockchain(chain);

      //   const startTimeShort = startTime?.toLocaleDateString(
      //     "en-US",
      //     US_DATE_OPTION
      //   );
      //   const endTimeShort = endTime?.toLocaleDateString("en-US", US_DATE_OPTION);

      //   setEventStartDate(startTimeShort);
      //   setEventEndDate(endTimeShort);

      //   setMaxParticipants(maxParts);

      //   setIsNSFW(nsfw);

      //   setAccessEnabled(accEnabled);
      //   setRegisterEnabled(regEnabled);

      //   const ended = endTime && endTime?.getTime() <= new Date().getTime();
      //   setEventEnded(ended);

      //   // Check if max participants have been reached
      //   const totalParts = await fetchTotalParticipants();

      //   let full = false;
      //   if (!isNaN(totalParts) && !isNaN(maxParts) && maxParts >= 0) {
      //     full = totalParts >= maxParts;
      //   }
      //   setSpotsFull(full);

      //   if (creator) {
      //     creatorProfileQuery.equalTo("objectId", creator.id);
      //     nfcreatorProfile = await creatorProfileQuery.first();

      //     if (nfcreatorProfile) {
      //       setCreatorEthAddress(nfcreatorProfile.get("ethAddress"));
      //       setCreatorName(nfcreatorProfile.get("name"));

      //       verifiedQuery.equalTo("profile", nfcreatorProfile);
      //       nfverified = await verifiedQuery.first();
      //       setIsNFVerified(nfverified?.get("verified"));
      //     }
      //   }

      //   // Disable access if ended
      //   if (ended) {
      //     throw { message: "Event has already ended." };
      //   }

      //   if (full) {
      //     throw {
      //       message: "No more spots available.",
      //     };
      //   }

      //   if ((!ehash || ehash?.length !== 6 || !accEnabled) && !regEnabled) {
      //     throw {
      //       message:
      //         "Event access registration is not available. Please try again later.",
      //     };
      //   }

      //   // Only sign message if not a current user
      //   if (!currentUser) {
      //     await signMsg();
      //   } else {
      //     await fetchCurrentUserProfile();
      //   }

      //   await checkEligibility(chain, contract, uniqueId);
    } catch (err) {
      console.error(err.message);
      setPassVerified(false);
      notify(err.message || "Unable to fetch access detail.", "error");
    } finally {
      setPageLoading(false);
    }
  };

  const fetchTotalParticipants = async () => {
    try {
      //   const res = await Moralis.Cloud.run("totalParticipants", {
      //     eventId: eventId,
      //     validOnly: true,
      //   });
      //   if (!isNaN(res)) {
      //     setTotalReg(res);
      //     return res;
      //   } else {
      //     setTotalReg(0);
      //     return 0;
      //   }
    } catch (err) {
      console.error(err.message);
    }
  };

  // 1. Register free event
  // 2. Register pass without unique Id
  // 3. Register pass with unique Id
  const register = async () => {
    // if (
    //   currentUser &&
    //   (registerEnabled || (ehash?.length === 6 && accessEnabled)) &&
    //   !eventEnded &&
    //   !spotsFull
    // ) {
    //   let shouldGenAccessKey = false;
    //   if (!nfaccessKey) {
    //     shouldGenAccessKey = true;
    //     nfaccessKey = new NFAccessKey();
    //     const generateHash = new RandomHash();
    //     const newkey = generateHash().toLowerCase();
    //     nfaccessKey.set("accessKey", newkey);
    //   }
    //   try {
    //     setModalLoading(true);
    //     let params = {
    //       eventId: eventId,
    //     };
    //     if (!freePass && requireUniquePassId) {
    //       params.tokenId = userTokenOption?.value;
    //     }
    //     if (ehash?.length === 6 && accessEnabled) {
    //       params.eventHash = ehash;
    //     } else {
    //       params.recaptcha = recaptchaToken;
    //     }
    //     const accessRes = await Moralis.Cloud.run(
    //       "userRegisterEventAccess",
    //       params
    //     );
    //     setModalLoading(false);
    //     if (!accessRes) {
    //       throw { message: "Unable to verify the access." };
    //     } else {
    //       setHasRegistered(true);
    //     }
    //     const createdAt = accessRes.get("createdAt");
    //     const valid = accessRes.get("isValid");
    //     const tokenId = accessRes.get("tokenId");
    //     setAccessValid(valid);
    //     setPassTokenId(tokenId);
    //     setAccessCreatedAt(
    //       createdAt?.toLocaleDateString("en-US", US_DATE_OPTION)
    //     );
    //     let key;
    //     if (shouldGenAccessKey) {
    //       nfaccessKey.set("access", accessRes);
    //       const accessKeyRes = await nfaccessKey.save();
    //       if (!accessKeyRes) {
    //         throw { message: "Unable to create access key." };
    //       }
    //       key = accessKeyRes.get("accessKey");
    //       if (valid) {
    //         setAccessKey(key);
    //         genQR(currentUser?.attributes.ethAddress, key);
    //       }
    //     } else {
    //       if (valid) {
    //         key = nfaccessKey?.get("accessKey");
    //         setAccessKey(key);
    //         genQR(currentUser?.attributes.ethAddress, key);
    //       }
    //     }
    //     if (valid) {
    //       setPassVerified(true);
    //       notify("You've successfully verified the access.", "success");
    //       setRegisterModalOpen(false);
    //       redirect(5);
    //     } else {
    //       notify("Error! Your access is invalid.", "error");
    //     }
    //   } catch (err) {
    //     console.error(err.message);
    //     setModalLoading(false);
    //     setPassVerified(false);
    //     notify(err.message || "Unable to register access.", "error");
    //   }
    // }
  };

  const deregister = async () => {
    // if (nfaccess) {
    //   const accessRes = await Moralis.Cloud.run("userDeregisterEventAccess", {
    //     eventId: eventId,
    //   });
    //   if (accessRes && !accessRes.get("isValid")) {
    //     setAccessValid(false);
    //   }
    // }
  };

  const checkEligibility = async (chain, contract, uniqueId) => {
    // try {
    //   nfaccess = await Moralis.Cloud.run("userGetOwnedEventAccess", {
    //     eventId: eventId,
    //   });
    //   const valid = nfaccess?.get("isValid");
    //   const createdAt = nfaccess?.get("createdAt");
    //   const tokenId = nfaccess?.get("tokenId");
    //   if (nfaccess) {
    //     myaccessKeyQuery.equalTo("access", nfaccess);
    //     nfaccessKey = await myaccessKeyQuery.first();
    //   }
    //   setAccessValid(valid);
    //   setPassTokenId(tokenId);
    //   setAccessCreatedAt(
    //     createdAt?.toLocaleDateString("en-US", US_DATE_OPTION)
    //   );
    //   const creatorGrantFlag = await Moralis.Cloud.run(
    //     "userCheckEventAccessGrantedByCreator",
    //     {
    //       eventId: eventId,
    //     }
    //   );
    //   setGrantedByCreator(creatorGrantFlag);
    //   if (creatorGrantFlag) {
    //     setPassVerified(true);
    //     if (!valid) {
    //       // Only be able to register if access is invalid or not registered
    //       setRegisterModalOpen(true);
    //       setEligibleToJoin(true);
    //     } else {
    //       redirect(0);
    //     }
    //   } else {
    // if (contract) {
    //   setPassContract(contract);
    //   setRequireUniquePassId(uniqueId);
    //   setFreePass(false);
    //   // TODO: check if pass contract is RealPass
    //   // TODO: remove rinkeby and mumbai
    //   if (chain === "eth") {
    //     setTokenUrl(`https://etherscan.io/address/${contract}`);
    //   }
    //   if (chain === "matic") {
    //     setTokenUrl(`https://polygonscan.com/address/${contract}`);
    //   }
    //   const options = {
    //     chain: chain,
    //     address: currentUser?.attributes?.ethAddress,
    //     token_address: contract,
    //   };
    //   const res = await Moralis.Web3API.account.getNFTsForContract(options);
    //   if (res && res.result && res.result.length > 0) {
    //     // Only set contract info, if user is eligible to join
    //     const nft = res.result[0];
    //     setPassName(nft?.name);
    //     setPassSymbol(nft?.symbol);
    //     setPassType(nft?.contract_type);
    //     if (uniqueId) {
    //       const hasTokenId = res.result.some(
    //         (token) => token.token_id === tokenId
    //       );
    //       if (!hasTokenId) {
    //         setPassVerified(false);
    //         // Deregister if inconsistent
    //         if (valid) {
    //           await deregister();
    //           valid = false;
    //         }
    //       } else {
    //         setPassVerified(true);
    //         if (valid) {
    //           redirect(0);
    //         }
    //       }
    //     } else {
    //       setPassVerified(true);
    //       if (valid) {
    //         redirect(0);
    //       }
    //     }
    //     if (!valid) {
    //       // Only be able to register if access is invalid or not registered
    //       setRegisterModalOpen(true);
    //       setModalLoading(true);
    //       setUserTotalPassHolding(res.total);
    //       if (uniqueId) {
    //         const tokenOptions = res.result.map((token, index) => {
    //           return {
    //             value: token.token_id,
    //             label: (
    //               <div>
    //                 <FontAwesomeIcon
    //                   icon='fa-ticket'
    //                   className='ml-2 mr-2'
    //                 />
    //                 <span>
    //                   {truncateText(token.symbol, 20)} #{token.token_id}
    //                 </span>
    //               </div>
    //             ),
    //           };
    //         });
    //         setUserTokenOptions(tokenOptions);
    //         if (res.total === 1) {
    //           setUserTokenOption(tokenOptions[0]);
    //           setEligibleToJoin(true);
    //         }
    //       } else {
    //         setEligibleToJoin(true);
    //       }
    //     }
    //   } else {
    //     setPassVerified(false);
    //     if (valid) {
    //       setEligibleToJoin(false);
    //       await deregister();
    //     }
    //     throw { message: "You don't have an event pass." };
    //   }
    // } else {
    //   setFreePass(true);
    //   setPassVerified(true);
    //   if (!valid) {
    //     // Only be able to register if access is invalid or not registered
    //     setRegisterModalOpen(true);
    //     setEligibleToJoin(true);
    //   } else {
    //     redirect(0);
    //   }
    // }
    //   }
    // } catch (err) {
    //   console.error(err.message);
    //   setPassVerified(false);
    //   notify(err.message || "Unable to check eligibility.", "error");
    // } finally {
    //   setModalLoading(false);
    // }
  };

  String.prototype.hashCode = function () {
    var hash = 0,
      i,
      chr;
    if (this.length === 0) return hash;
    for (i = 0; i < this.length; i++) {
      chr = this.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0;
    }
    hash = String(hash).replace("-", "");

    if (hash.length > 6) {
      hash = hash.substring(0, 6);
    }

    return hash;
  };

  const genQR = (wallet, key) => {
    if (wallet && key) {
      const salt = Math.round(new Date().getTime() / 500);
      const seed = salt + key;

      const hash = seed.hashCode();

      let url = window.location.href;

      if (url.indexOf("?") > 0) {
        url = url.substring(0, url.indexOf("?"));
      }

      const qrCode = `${REACT_APP_HOST}/event/checkin/${eventId}?wallet=${wallet}&ahash=${hash}`;

      setAccessQRCode(qrCode);
      setAccessHashCode(hash);
    }
  };

  const redirect = (countdown) => {
    if (countdown <= 0) {
      navigate(`/myaccess?event=${eventId}`);
    }

    const countdownInterval = setInterval(() => {
      setQRRefreshCountdown(countdown--);

      if (countdown <= 0) {
        clearInterval(countdownInterval);
        navigate(`/myaccess?event=${eventId}`);
      }
    }, 500);
  };

  useEffect(() => {
    fecthAccess();
  }, []);

  useEffect(() => {
    if (accessKey) {
      const qrTimer = setTimeout(() => {
        genQR(ethAddress, accessKey);
      }, 1000);

      return () => {
        clearTimeout(qrTimer);
      };
    }
  }, [accessHashCode]);

  return (
    <>
      <TopNavbar />
      <ReactNotifications />
      <div className="wrapper event-page">
        <section className="section-event-cover section-shaped my-0">
          <img
            alt="..."
            className="bg-image"
            src={eventBannerImage || defaultBannerImg}
            style={{ width: "100%" }}
          ></img>
        </section>
        <section className="section bg-secondary section-main">
          <Container>
            <Card className="card-event shadow mt--350">
              <div className="px-3">
                <Row className="justify-content-center mb-7">
                  <Col className="order-lg-2" lg="7">
                    <div className="card-event-image-md">
                      <img
                        alt="..."
                        className="event-image-md"
                        src={eventImage || defaultEventImg}
                      />
                    </div>
                  </Col>
                </Row>

                {eventName && (
                  <div className="text-center mt-6">
                    <h4 className="mt-3">
                      <a href={`/event/${eventId}`}>{eventName || "Unnamed"}</a>
                    </h4>

                    {creatorEthAddress && (
                      <div className="mt-3">
                        by{" "}
                        <a href={"/" + creatorEthAddress}>
                          <strong>{creatorName || "Unnamed"}</strong>
                          {isNFVerified && (
                            <img
                              className="realpass-verified-name-suffix ml-2 mb-1"
                              src={verifiedImg}
                            />
                          )}
                        </a>
                      </div>
                    )}
                  </div>
                )}

                <div>
                  {eventEnded ? (
                    <div className="ml-lg-8 mr-lg-8 mt-3 mb-3">
                      <Alert color="default" className="text-white">
                        <span>
                          This event has already ended on {eventEndDate}
                        </span>
                      </Alert>
                    </div>
                  ) : (
                    spotsFull && (
                      <div className="ml-lg-8 mr-lg-8 mt-3 mb-3">
                        <Alert color="default" className="text-white">
                          <span>This event has no more spots.</span>
                        </Alert>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="ml-lg-8 mr-lg-8 mt-4 mb-5">
                <Row className="ml-3 mr-2">
                  <Col>
                    <h3>
                      <strong>Verify Access</strong>
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col className="ml-2 mr-2" xl="3" lg="3">
                    <div className="text-center mt-3">
                      <a href="/account">
                        <img
                          alt="..."
                          className="rounded-circle profile-image-md"
                          src={image || defaultProfileImg}
                        />
                      </a>
                    </div>

                    <div className="text-center">
                      <h5 className="mt-3">
                        <a href="/account">{name || "Unnamed"}</a>
                      </h5>
                    </div>

                    <div className="text-center mt-2">
                      <Button
                        id="walletAddressButton"
                        color="dark"
                        outline
                        type="button"
                        size="sm"
                        style={{ textTransform: "none" }}
                        onClick={() =>
                          navigator.clipboard.writeText(ethAddress)
                        }
                      >
                        <img className="icon-eth mr-2" alt="..." src={ethImg} />
                        <span className="wallet-field-sm">
                          {truncateAddressShort(ethAddress)}
                          <FontAwesomeIcon
                            icon="fa-clone"
                            className="ml-1 text-muted"
                          />
                        </span>
                      </Button>
                      <UncontrolledTooltip
                        placement="top"
                        target="walletAddressButton"
                        trigger="click"
                      >
                        Address Copied
                      </UncontrolledTooltip>

                      <Button
                        className="btn-icon"
                        size="sm"
                        outline
                        color="primary"
                        type="button"
                        onClick={() => window.location.reload()}
                      >
                        <FontAwesomeIcon icon="fa-rotate-right" />
                      </Button>
                    </div>

                    {passVerified === true && accessValid === true ? (
                      <>
                        {" "}
                        <div className="text-center mt-2">
                          <Button
                            color="success"
                            size="sm"
                            href={`/myaccess?event=${eventId}`}
                          >
                            <span>
                              My Access ({qrRefreshCountdown}
                              <small>s</small>)
                            </span>
                          </Button>
                        </div>
                      </>
                    ) : hasRegistered ? (
                      <div className="text-center mt-2">
                        <Button
                          color={
                            passVerified === false && accessValid === false
                              ? "danger"
                              : "primary"
                          }
                          size="sm"
                          href={`/myaccess?event=${eventId}`}
                        >
                          <span className="mx-2">
                            My Access
                            <FontAwesomeIcon
                              icon="fa-circle-xmark"
                              className="ml-1"
                            />
                          </span>
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Col>
                  <Col className="mt-4">
                    <Loader loading={pageLoading} />
                    <div>
                      <div className="access_card_panel">
                        <a
                          className="p-0"
                          onClick={() => setQRModalOpen(true)}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="access_card access_card--front">
                            {!pageLoading && (
                              <>
                                <img
                                  className="access_card__chip"
                                  src={nfChipImg}
                                />
                                <div className="">
                                  <div className="access_card__event">
                                    {eventName}
                                  </div>
                                  <div className="access_card__checkin-date">
                                    {accessCreatedAt ? (
                                      <>Joined {accessCreatedAt}</>
                                    ) : (
                                      "Not Joined"
                                    )}
                                  </div>
                                  <div className="access_card__pass-info text-uppercase">
                                    {passName ? (
                                      passVerified === false ||
                                      accessValid === false ? (
                                        <s>
                                          {truncateText(passName, 20)}{" "}
                                          {requireUniquePassId &&
                                            passTokenId &&
                                            "#" + passTokenId}
                                        </s>
                                      ) : (
                                        <>
                                          {truncateText(passName, 20)}{" "}
                                          {requireUniquePassId &&
                                            passTokenId &&
                                            "#" + passTokenId}
                                        </>
                                      )
                                    ) : freePass ? (
                                      "Free Pass"
                                    ) : (
                                      "No Pass"
                                    )}
                                  </div>
                                </div>
                                <div className="access_card__verify">
                                  <div
                                    className={
                                      passVerified === true &&
                                      accessValid === true
                                        ? "text-success text-center"
                                        : passVerified === false ||
                                          accessValid === false
                                        ? "text-danger text-center"
                                        : "text-white text-center"
                                    }
                                  >
                                    <strong>
                                      {passVerified === true &&
                                      accessValid === true ? (
                                        <>
                                          VERIFIED
                                          <FontAwesomeIcon
                                            icon="fa-circle-check"
                                            className="ml-1"
                                          />
                                        </>
                                      ) : passVerified === false ||
                                        accessValid === false ? (
                                        <>
                                          INVALID
                                          <FontAwesomeIcon
                                            icon="fa-circle-xmark"
                                            className="ml-1"
                                          />
                                          <br />
                                        </>
                                      ) : (
                                        <>
                                          NOT VERIFIED
                                          <FontAwesomeIcon
                                            icon="fa-circle-arrow-down"
                                            className="ml-1"
                                          />
                                        </>
                                      )}
                                    </strong>
                                  </div>
                                  <div
                                    className={
                                      passVerified === true &&
                                      accessValid === true
                                        ? "m-1 access_card__qr"
                                        : "m-1"
                                    }
                                  >
                                    <QRCode
                                      className={
                                        passVerified === true &&
                                        accessValid === true
                                          ? ""
                                          : "qr-overlay"
                                      }
                                      fgColor="#222"
                                      bgColor="#fff"
                                      size={140}
                                      value={accessQRCode || REACT_APP_HOST}
                                    />
                                  </div>
                                  <Badge
                                    pill
                                    color="primary"
                                    className="access_card__code pl-2 pr-2 m-1"
                                  >
                                    <span className="text-white">
                                      {accessHashCode ? (
                                        <span className="text-lowercase">
                                          <FontAwesomeIcon
                                            icon="fa-lock-open"
                                            className="mr-1"
                                          />
                                          {accessHashCode}
                                        </span>
                                      ) : (
                                        <span>
                                          <FontAwesomeIcon
                                            icon="fa-lock"
                                            className="mr-1"
                                          />
                                          Invalid
                                        </span>
                                      )}
                                    </span>
                                  </Badge>
                                </div>
                                <div className="access_card__wallet_address">
                                  {checksumEthAddress(
                                    currentUserInfo?.ethAddress
                                  )}
                                </div>
                                <div className="access_card__owner">
                                  {currentUserInfo?.name || "Unnamed"}
                                </div>
                                <img
                                  className="access_card__logo"
                                  src={logoWhiteImg}
                                />
                              </>
                            )}

                            <img
                              alt="..."
                              src={eventImage}
                              className="access_card__bg"
                            />
                          </div>
                        </a>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>

            {((ehash?.length === 6 && accessEnabled) || registerEnabled) &&
              !eventEnded &&
              !spotsFull && (
                <Modal isOpen={registerModalOpen}>
                  <LoaderModal loading={modalLoading} />
                  <div className="modal-header">
                    <h5 className=" modal-title" id="verifyModalLabel">
                      Verify Access{" "}
                    </h5>
                    <button
                      aria-label="Close"
                      className=" close"
                      type="button"
                      onClick={() => {
                        setRegisterModalOpen(false);
                        setPassVerified(false);
                      }}
                    >
                      <span aria-hidden={true}>
                        <FontAwesomeIcon icon="fa-xmark" />
                      </span>
                    </button>
                  </div>

                  <ModalBody>
                    <div className="mb-4">
                      <div>
                        <h5>Check Eligibility</h5>
                        {grantedByCreator ? (
                          <div className="mt-2">
                            <FontAwesomeIcon
                              icon="fa-circle-check"
                              className="text-success fa-lg"
                            />
                            <span className="ml-2">
                              Found a special access granted by the creator.
                            </span>
                          </div>
                        ) : freePass ? (
                          <div className="mt-2">
                            <FontAwesomeIcon
                              icon="fa-circle-check"
                              className="text-success fa-lg"
                            />
                            <span className="ml-2">
                              This event requires no pass to join.
                            </span>
                          </div>
                        ) : (
                          passContract && (
                            <>
                              <div className="mt-2">
                                <FontAwesomeIcon
                                  icon="fa-circle-check"
                                  className="text-success fa-lg"
                                />
                                <span className="ml-2">
                                  This event requires the following pass.
                                </span>
                              </div>
                              <div className="mt-3 mb-4">
                                <Button
                                  className="btn-sm"
                                  type="button"
                                  href={tokenUrl}
                                  rel="external nofollow noopener"
                                  target="_blank"
                                >
                                  {blockchain === "eth" && (
                                    <img
                                      src={ethImg}
                                      className="icon-eth mr-2"
                                    />
                                  )}
                                  {blockchain === "matic" && (
                                    <img
                                      src={polygonImg}
                                      className="icon-polygon mr-2"
                                    />
                                  )}
                                  <>
                                    {truncateText(passName, 20)} {"( "}
                                    <small>{passType} </small>
                                    <span className="text-info">
                                      {truncateAddressShort(passContract)}
                                    </span>{" "}
                                    {")"}
                                    <FontAwesomeIcon
                                      icon="arrow-up-right-from-square"
                                      className="ml-1"
                                    />
                                  </>
                                </Button>
                              </div>

                              <div className="my-3">
                                {userTotalPassHolding > 0 ? (
                                  <FontAwesomeIcon
                                    icon="fa-circle-check"
                                    className="text-success fa-lg"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon="fa-circle-xmark"
                                    className="text-danger fa-lg"
                                  />
                                )}
                                <span className="ml-2">
                                  You are holding{" "}
                                  {userTotalPassHolding > 1 ? (
                                    <>{userTotalPassHolding} passes</>
                                  ) : (
                                    <>{userTotalPassHolding} pass</>
                                  )}
                                  .
                                </span>
                              </div>
                              {userTotalPassHolding > 0 &&
                                (requireUniquePassId ? (
                                  <div className="my-3">
                                    {userTokenOption?.value ? (
                                      <FontAwesomeIcon
                                        icon="fa-circle-check"
                                        className="text-success fa-lg"
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        icon="fa-circle-check"
                                        className="fa-lg"
                                      />
                                    )}
                                    <span className="ml-2">
                                      Choose your pass to register
                                    </span>
                                    <div className="ml-4">
                                      <small className="text-muted">
                                        Require unique wallet and Token ID to
                                        join.
                                      </small>
                                    </div>
                                    <div className="my-2 col-11">
                                      <Select
                                        className="realpass-selector"
                                        onChange={(e) => {
                                          setUserTokenOption(e);
                                          if (e) {
                                            setEligibleToJoin(true);
                                          } else {
                                            setEligibleToJoin(false);
                                          }
                                        }}
                                        isClearable={true}
                                        isSearchable={true}
                                        components={animatedComponents}
                                        options={userTokenOptions}
                                        value={userTokenOption}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <FontAwesomeIcon
                                      icon="fa-circle-check"
                                      className="text-success fa-lg"
                                    />
                                    <span className="ml-2">
                                      Each wallet and pass may have one entry.
                                    </span>
                                  </>
                                ))}
                            </>
                          )
                        )}
                      </div>
                    </div>
                    {isNSFW && (
                      <div className="mx-4 my-3">
                        <div>
                          <Label>
                            <Input
                              checked={confirmAge}
                              className="custom-control-input"
                              type="checkbox"
                              onChange={() => {
                                setConfirmAge(!confirmAge);
                              }}
                            />
                            <span className="custom-control-label">
                              <strong>Confirm Legal Age</strong>
                            </span>
                          </Label>
                        </div>
                        <small className="text-muted mt-2">
                          This event is marked NSFW and may contain sensitive
                          content. You need to confirm you're 18+ to continue.
                        </small>
                      </div>
                    )}
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="primary"
                      type="button"
                      disabled={
                        ehash?.length !== 6 ||
                        !accessEnabled ||
                        (isNSFW && !confirmAge) ||
                        !eligibleToJoin
                      }
                      onClick={() => {
                        register();
                      }}
                    >
                      Claim Access
                    </Button>
                    <Button
                      color="secondary"
                      type="button"
                      onClick={() => {
                        setRegisterModalOpen(false);
                        setPassVerified(false);
                      }}
                    >
                      Close
                    </Button>
                  </ModalFooter>
                </Modal>
              )}

            <Modal
              toggle={() => setQRModalOpen(!qrModalOpen)}
              isOpen={qrModalOpen}
            >
              <div className=" modal-header">
                <h5 className=" modal-title" id="qrModalLabel">
                  My Access
                </h5>
                <button
                  aria-label="Close"
                  className=" close"
                  type="button"
                  onClick={() => setQRModalOpen(!qrModalOpen)}
                >
                  <span aria-hidden={true}>
                    <FontAwesomeIcon icon="fa-xmark" />
                  </span>
                </button>
              </div>
              <ModalBody className="justify-content-center">
                <div className="text-center m-3">
                  <h1
                    className={
                      passVerified === true && accessValid === true
                        ? "text-success text-center"
                        : passVerified === false || accessValid === false
                        ? "text-danger text-center"
                        : "text-center"
                    }
                  >
                    <strong>
                      {passVerified === true && accessValid === true ? (
                        <>
                          VERIFIED
                          <FontAwesomeIcon
                            icon="fa-circle-check"
                            className="ml-1"
                          />
                        </>
                      ) : passVerified === false || accessValid === false ? (
                        <>
                          INVALID
                          <FontAwesomeIcon
                            icon="fa-circle-xmark"
                            className="ml-1"
                          />
                          <br />
                        </>
                      ) : (
                        <>
                          NOT VERIFIED
                          <FontAwesomeIcon
                            icon="fa-circle-arrow-down"
                            className="ml-1"
                          />
                        </>
                      )}
                    </strong>
                  </h1>
                </div>
                <div className="text-center my-3">
                  <QRCode
                    className={
                      passVerified === true && accessValid === true
                        ? ""
                        : "qr-overlay"
                    }
                    fgColor="#222"
                    bgColor="#fff"
                    size={280}
                    value={accessQRCode || REACT_APP_HOST}
                  />
                </div>
                <div className="text-center m-3">
                  <Badge pill color="primary" className="text-center m-2">
                    <h4 className="text-white text-center mx-3 my-0">
                      {accessHashCode ? (
                        <span className="text-lowercase">
                          <FontAwesomeIcon
                            icon="fa-lock-open"
                            className="mr-1"
                          />
                          {accessHashCode}
                        </span>
                      ) : (
                        <span>
                          <FontAwesomeIcon icon="fa-lock" className="mr-1" />
                          Invalid
                        </span>
                      )}
                    </h4>
                  </Badge>
                </div>
              </ModalBody>
            </Modal>
          </Container>
        </section>
      </div>
    </>
  );
}
