import axios from "axios";

import {
  useAccount,
  useConnect,
  useDisconnect,
  useEnsAvatar,
  useEnsName,
  useNetwork,
  useSignMessage,
} from "wagmi";

import { ethers } from "ethers";
import { SiweMessage, ErrorTypes } from "siwe";

import FirebaseInit from "utils/FirebaseInit.js";
import { relativeTimeRounding } from "moment";

const { REACT_APP_HOST, REACT_APP_API_HOST, REACT_APP_APIKEY } = process.env;

const {
  firebaseAuth,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  linkWithCredential,
  EmailAuthProvider,
  unlink,
  firebaseDb,
  doc,
  setDoc,
  firebaseStorage,
  ref,
  uploadBytes,
  getDownloadURL,
} = FirebaseInit();

export const apikeyHttpConfig = () => {
  return {
    headers: {
      "x-api-key": REACT_APP_APIKEY,
    },
  };
};

export const authHttpConfig = (idToken) => {
  return {
    headers: {
      "Authorization": `Bearer ${idToken}`,
      "x-api-key": REACT_APP_APIKEY,
    },
  };
};

export const validateAuthToken = async (idToken) => {
  let isValid = false;

  const res = await axios.get(
    `${REACT_APP_API_HOST}/auth/validate`,
    authHttpConfig(idToken)
  );

  isValid = res?.data?.isValid;

  return isValid;
};

export const resolveEns = async (name, address) => {
  if (!name && !address) {
    return;
  }

  const config = apikeyHttpConfig();
  let params;

  if (name) {
    params = {
      name,
    };
  } else if (address) {
    params = {
      address: address.toLowerCase(),
    };
  }

  config.params = params;
  const res = await axios.get(`${REACT_APP_API_HOST}/web3/ens/resolve`, config);

  return res?.data;
};

export const getNftMetadata = async (idToken, address, chain) => {
  if (!address || !chain) {
    return;
  }

  const config = authHttpConfig(idToken);

  const res = await axios.get(
    `${REACT_APP_API_HOST}/web3/${chain}/nft/${address}`,
    config
  );

  return res?.data;
};

export const ethWalletExists = async (idToken, address) => {
  if (!idToken || !address) {
    return;
  }

  let exists = false;

  const params = {
    address: address.toLowerCase(),
  };

  const config = authHttpConfig(idToken);
  config.params = params;

  const res = await axios.get(`${REACT_APP_API_HOST}/auth/exists`, config);

  exists = res?.data?.exists;

  return exists;
};

export const emailExists = async (idToken, email) => {
  if (!idToken || !email) {
    return;
  }

  let exists = false;
  const params = {
    email,
  };

  const config = authHttpConfig(idToken);
  config.params = params;

  const res = await axios.get(`${REACT_APP_API_HOST}/auth/exists`, config);

  exists = res?.data?.exists;

  return exists;
};

export const createSiweMessage = async (chainId, address, statement) => {
  const domain = "RealPass";
  const origin = REACT_APP_HOST;

  const res = await axios.get(
    `${REACT_APP_API_HOST}/auth/nonce`,
    apikeyHttpConfig()
  );

  const data = await res?.data;

  const message = new SiweMessage({
    domain,
    address,
    statement,
    uri: origin,
    version: "1",
    chainId,
    nonce: data?.nonce,
  });

  return { message: message.prepareMessage(), challenge: data?.challenge };
};

export const ethsigAuth = async (message, signature, challenge) => {
  const body = {
    message,
    signature,
    challenge,
  };

  const res = await axios.post(
    `${REACT_APP_API_HOST}/auth/authenticate/ethsig`,
    body,
    apikeyHttpConfig()
  );

  const data = res?.data;

  return data;
};

export const ethsigLinkAccount = async (
  idToken,
  message,
  signature,
  challenge
) => {
  const body = {
    message,
    signature,
    challenge,
  };

  const res = await axios.post(
    `${REACT_APP_API_HOST}/auth/link/ethsig`,
    body,
    authHttpConfig(idToken)
  );

  const data = res?.data;

  return data;
};

export const countFollows = async (userId) => {
  const res = await axios.get(
    `${REACT_APP_API_HOST}/user/${userId}/follows/count`,
    apikeyHttpConfig()
  );

  return res?.data;
};

export const countFollowsWeb3 = async (userId) => {
  const res = await axios.get(
    `${REACT_APP_API_HOST}/user/${userId}/follows3/count`,
    apikeyHttpConfig()
  );

  return res?.data;
};

export const followUser = async (idToken, followingId) => {
  const body = {
    followingId,
  };

  const res = await axios.post(
    `${REACT_APP_API_HOST}/user/follow`,
    body,
    authHttpConfig(idToken)
  );

  const isFollowing = res?.data?.isFollowing;
  return isFollowing;
};

export const unfollowUser = async (idToken, followingId) => {
  const body = {
    followingId,
  };

  const res = await axios.post(
    `${REACT_APP_API_HOST}/user/unfollow`,
    body,
    authHttpConfig(idToken)
  );

  const isFollowing = res?.data?.isFollowing;
  return isFollowing;
};

export const getProfile = async (idToken, userId) => {
  let url, config;

  if (idToken) {
    url = `${REACT_APP_API_HOST}/user/${userId}/profile`;
    config = authHttpConfig(idToken);
  } else {
    url = `${REACT_APP_API_HOST}/public/user/${userId}/profile`;
    config = apikeyHttpConfig();
  }

  const res = await axios.get(url, config);
  const profile = res?.data;
  return profile;
};

export const getProfileWeb3 = async (idToken, address) => {
  let url, config;

  if (idToken) {
    url = `${REACT_APP_API_HOST}/user/${address}/profile3`;
    config = authHttpConfig(idToken);
  } else {
    url = `${REACT_APP_API_HOST}/public/user/${address}/profile3`;
    config = apikeyHttpConfig();
  }

  const res = await axios.get(url, config);
  const profile = res?.data;
  return profile;
};

// Get current user info with idToken
export const getCurrentUserInfo = async (idToken) => {
  let userInfo;

  const res = await axios.get(
    `${REACT_APP_API_HOST}/user/info`,
    authHttpConfig(idToken)
  );
  userInfo = res?.data;

  return userInfo;
};

export const requestAccountDeletion = async (idToken) => {
  const res = await axios.post(
    `${REACT_APP_API_HOST}/user/delete`,
    null,
    authHttpConfig(idToken)
  );

  const userStatus = res?.data?.userStatus;

  return userStatus;
};

export const firebaseUpload = async (data, fullPath) => {
  let url;

  if (data) {
    const dataRef = ref(firebaseStorage, fullPath);

    const snapshot = await uploadBytes(dataRef, data);
    url = await getDownloadURL(snapshot.ref);
  }

  return url;
};

export const eventExists = async (idToken, eventId) => {
  let exists = false;

  const res = await axios.get(
    `${REACT_APP_API_HOST}/public/event/${eventId}/exists`,
    apikeyHttpConfig()
  );

  exists = res?.data?.exists;

  return exists;
};

export const listCreatedEvents = async (address, size, offset) => {
  let events;

  const res = await axios.get(
    `${REACT_APP_API_HOST}/public/events/created?address=${address}&size=${size}&offset=${offset}`,
    apikeyHttpConfig()
  );

  events = res?.data;

  return events;
};

export const listMyCreatedEvents = async (idToken, size, offset) => {
  let events;

  const res = await axios.get(
    `${REACT_APP_API_HOST}/events/created?size=${size}&offset=${offset}`,
    authHttpConfig(idToken)
  );

  events = res?.data;

  return events;
};

export const listMyJoinedEvents = async (idToken, size, offset) => {
  let events;

  const res = await axios.get(
    `${REACT_APP_API_HOST}/events/joined?size=${size}&offset=${offset}`,
    authHttpConfig(idToken)
  );

  events = res?.data;

  return events;
};

export const getEvent = async (idToken, eventId) => {
  let event;

  let config, apiUrl;

  if (idToken) {
    config = authHttpConfig(idToken);
    apiUrl = `event/${eventId}`;
  } else {
    config = apikeyHttpConfig();
    apiUrl = `public/event/${eventId}`;
  }

  const res = await axios.get(`${REACT_APP_API_HOST}/${apiUrl}`, config);

  event = res?.data;

  return event;
};

export const getEventBasics = async (idToken, eventId) => {
  let event;

  let config, apiUrl;

  if (idToken) {
    config = authHttpConfig(idToken);
    apiUrl = `event/${eventId}/basics`;
  } else {
    config = apikeyHttpConfig();
    apiUrl = `public/event/${eventId}/basics`;
  }

  const res = await axios.get(`${REACT_APP_API_HOST}/${apiUrl}`, config);

  event = res?.data;

  return event;
};

export const createEvent = async (idToken, event) => {
  const res = await axios.post(
    `${REACT_APP_API_HOST}/manage/event/create`,
    event,
    authHttpConfig(idToken)
  );

  const url = res?.data?.eventId;

  return url;
};

export const countEventLikes = async (eventId) => {
  const res = await axios.get(
    `${REACT_APP_API_HOST}/public/event/${eventId}/likes/count`,
    apikeyHttpConfig()
  );

  const count = res?.data?.totalLikes;

  return count;
};

export const likeEvent = async (idToken, eventId) => {
  const res = await axios.post(
    `${REACT_APP_API_HOST}/event/${eventId}/like`,
    null,
    authHttpConfig(idToken)
  );

  const isLiking = res?.data?.isLiking;

  return isLiking;
};

export const unlikeEvent = async (idToken, eventId) => {
  const res = await axios.post(
    `${REACT_APP_API_HOST}/event/${eventId}/unlike`,
    null,
    authHttpConfig(idToken)
  );

  const isLiking = res?.data?.isLiking;

  return isLiking;
};

export const getUserInvites = async (idToken, eventId) => {
  const res = await axios.get(
    `${REACT_APP_API_HOST}/event/${eventId}/invites`,
    authHttpConfig(idToken)
  );

  return res?.data;
};

export const retrieveInviteCodeUsed = async (idToken, eventId) => {
  const res = await axios.get(
    `${REACT_APP_API_HOST}/event/${eventId}/invite/retrieve`,
    authHttpConfig(idToken)
  );

  const appliedInviteCode = res?.data?.appliedInviteCode;

  return appliedInviteCode;
};

export const validateInviteCode = async (idToken, eventId, inviteCode) => {
  const res = await axios.get(
    `${REACT_APP_API_HOST}/event/${eventId}/invite/validate/${inviteCode}`,
    authHttpConfig(idToken)
  );

  const isValid = res?.data?.isInviteCodeValid;

  return isValid;
};

export const countEventUsers = async (eventId) => {
  const res = await axios.get(
    `${REACT_APP_API_HOST}/public/event/${eventId}/users/count`,
    apikeyHttpConfig()
  );

  const count = res?.data; // return total registered and checked in

  return count;
};

export const getMyParticipation = async (idToken, eventId) => {
  const res = await axios.get(
    `${REACT_APP_API_HOST}/event/${eventId}/user`,
    authHttpConfig(idToken)
  );

  const participation = res?.data;

  return participation;
};

export const userRegisterEvent = async (idToken, eventId, inviteCode) => {
  const body = {
    inviteCode,
  };

  const res = await axios.post(
    `${REACT_APP_API_HOST}/event/${eventId}/register`,
    body,
    authHttpConfig(idToken)
  );

  const participant = res?.data;

  return participant;
};

export const userReverifyEvent = async (idToken, eventId, inviteCode) => {
  const body = {
    inviteCode,
  };

  const res = await axios.post(
    `${REACT_APP_API_HOST}/event/${eventId}/reverify`,
    body,
    authHttpConfig(idToken)
  );

  const participant = res?.data;

  return participant;
};

export const userDeregisterEvent = async (idToken, eventId) => {
  const res = await axios.post(
    `${REACT_APP_API_HOST}/event/${eventId}/deregister`,
    null,
    authHttpConfig(idToken)
  );

  const participant = res?.data;

  return participant;
};

export const userUpdateEventAccessKey = async (idToken, eventId) => {
  const res = await axios.post(
    `${REACT_APP_API_HOST}/event/${eventId}/access/renew`,
    null,
    authHttpConfig(idToken)
  );

  const access = res?.data;

  return access;
};

export const getEventAccess = async (idToken, eventId) => {
  const res = await axios.get(
    `${REACT_APP_API_HOST}/event/${eventId}/access`,
    authHttpConfig(idToken)
  );

  const access = res?.data;

  return access;
};

export const getUserAccess = async (idToken) => {
  const res = await axios.get(
    `${REACT_APP_API_HOST}/user/access`,
    authHttpConfig(idToken)
  );

  const access = res?.data;

  return access;
};

/**
 * creator get event stats overview
 */
export const creatorGetStatsOverview = async (idToken, eventId) => {
  const res = await axios.get(
    `${REACT_APP_API_HOST}/manage/event/${eventId}/stats/overview`,
    authHttpConfig(idToken)
  );

  return res?.data;
};

/**
 * Get optin/out daily stats by days
 */
export const creatorGetOptStatsByDays = async (idToken, eventId, days) => {
  const params = {
    days,
  };

  const config = authHttpConfig(idToken);
  config.params = params;

  const res = await axios.get(
    `${REACT_APP_API_HOST}/manage/event/${eventId}/stats/opt`,
    config
  );

  return res?.data;
};

/**
 * List or count onboarding by days
 * - days: N days
 * - query: list or count
 */
export const creatorGetNewParticipantsByDays = async (
  idToken,
  eventId,
  days,
  size
) => {
  const params = {
    days,
    size,
  };

  const config = authHttpConfig(idToken);
  config.params = params;

  const res = await axios.get(
    `${REACT_APP_API_HOST}/manage/event/${eventId}/stats/new`,
    config
  );

  return res?.data;
};

/**
 * List or count optout users by days
 * - days: N days
 * - query: list or count
 */
export const creatorGetOptoutParticipantsByDays = async (
  idToken,
  eventId,
  days,
  q
) => {
  const params = {
    days,
    q,
  };

  const config = authHttpConfig(idToken);
  config.params = params;

  const res = await axios.get(
    `${REACT_APP_API_HOST}/manage/event/${eventId}/stats/optout`,
    config
  );

  return res?.data;
};

/**
 * List top event users by invites
 */
export const creatorGetTopParticipantsByInvites = async (idToken, eventId) => {
  const res = await axios.get(
    `${REACT_APP_API_HOST}/manage/event/${eventId}/stats/top/invites`,
    authHttpConfig(idToken)
  );

  return res?.data;
};

/**
 * List top event users by balance
 */
export const creatorGetTopParticipantsByBalance = async (
  idToken,
  eventId,
  chain
) => {
  const params = {
    chain,
  };

  const config = authHttpConfig(idToken);
  config.params = params;

  const res = await axios.get(
    `${REACT_APP_API_HOST}/manage/event/${eventId}/stats/top/balance`,
    config
  );

  return res?.data;
};

/**
 * Creator search event users by filter
 */
export const creatorGetEventUsers = async (
  idToken,
  eventId,
  filter,
  size,
  offset,
  regCount,
  ipCount,
  address,
  keywords,
  chain
) => {
  const params = {
    filter,
    size,
    offset,
    regCount,
    ipCount,
    address: address?.toLowerCase(),
    keywords: keywords?.toLowerCase(),
    chain,
  };

  const config = authHttpConfig(idToken);
  config.params = params;

  const res = await axios.get(
    `${REACT_APP_API_HOST}/manage/event/${eventId}/users`,
    config
  );

  return res?.data;
};

/**
 * Creator bulk add event members
 *
 * @param {*} idToken
 * @param {*} url
 * @param {*} addresses
 * @param {*} emails
 * @returns
 */
export const creatorBulkAddEventUsers = async (
  idToken,
  eventId,
  addresses,
  emails
) => {
  const body = {};

  if (addresses.length > 0) {
    body.addresses = addresses;
  } else if (emails.length > 0) {
    body.emails = emails;
  }

  const res = await axios.post(
    `${REACT_APP_API_HOST}/manage/event/${eventId}/users/add`,
    body,
    authHttpConfig(idToken)
  );

  return res?.data;
};

export const creatorUpdateEventUsers = async (
  idToken,
  eventId,
  action,
  userIds
) => {
  const body = {
    action,
    userIds,
  };

  const res = await axios.post(
    `${REACT_APP_API_HOST}/manage/event/${eventId}/users/action`,
    body,
    authHttpConfig(idToken)
  );

  return res?.data;
};

export const creatorCheckinUser = async (idToken, eventId, address, ahash) => {
  const body = {
    address: address.toLowerCase(),
    ahash,
  };

  const res = await axios.post(
    `${REACT_APP_API_HOST}/manage/event/${eventId}/checkin`,
    body,
    authHttpConfig(idToken)
  );

  const isValid = res?.data?.isAccessCodeValid;

  return isValid;
};

/**
 *
 * @param {*} idToken
 * @param {*} url
 * @param {*} event - 1. links 2. videoUrl 3. supportEmail
 * @returns
 */
export const creatorUpdateEventSocial = async (idToken, eventId, event) => {
  const res = await axios.post(
    `${REACT_APP_API_HOST}/manage/event/${eventId}/social`,
    event,
    authHttpConfig(idToken)
  );

  return res?.data;
};

export const creatorUpdateEventDetail = async (idToken, eventId, event) => {
  const res = await axios.post(
    `${REACT_APP_API_HOST}/manage/event/${eventId}/detail`,
    event,
    authHttpConfig(idToken)
  );

  return res?.data;
};

export const creatorUpdateAccessSettings = async (idToken, eventId, event) => {
  const res = await axios.post(
    `${REACT_APP_API_HOST}/manage/event/${eventId}/access/settings`,
    event,
    authHttpConfig(idToken)
  );

  return res?.data;
};

export const creatorUpdateInviteSettings = async (
  idToken,
  eventId,
  max,
  type
) => {
  const res = await axios.post(
    `${REACT_APP_API_HOST}/manage/event/${eventId}/invites/settings`,
    { max, type },
    authHttpConfig(idToken)
  );

  return res?.data;
};

export const creatorUpdateUserInviteStatus = async (
  idToken,
  eventId,
  userId,
  code,
  action
) => {
  const body = { userId, code, action };

  const res = await axios.post(
    `${REACT_APP_API_HOST}/manage/event/${eventId}/invites/users/status`,
    body,
    authHttpConfig(idToken)
  );

  return res?.data;
};

export const creatorGetUsersInvites = async (
  idToken,
  eventId,
  size,
  offset,
  address,
  chain
) => {
  const params = {
    size,
    offset,
    address: address?.toLowerCase(),
    chain,
  };

  const config = authHttpConfig(idToken);
  config.params = params;

  const res = await axios.get(
    `${REACT_APP_API_HOST}/manage/event/${eventId}/invites/users`,
    config
  );

  return res?.data;
};

export const creatorUpdateCreatorInviteSettings = async () => {};

export const creatorUpdateCreatorInviteStatus = async (
  idToken,
  eventId,
  userId,
  code,
  action
) => {
  const body = { userId, code, action };

  const res = await axios.post(
    `${REACT_APP_API_HOST}/manage/event/${eventId}/invites/creators/status`,
    body,
    authHttpConfig(idToken)
  );

  return res?.data;
};

export const creatorGetCreatorInvites = async (idToken, eventId) => {
  const config = authHttpConfig(idToken);

  const res = await axios.get(
    `${REACT_APP_API_HOST}/manage/event/${eventId}/invites/creators`,
    config
  );

  return res?.data;
};

export const creatorAddCreatorInvites = async (
  idToken,
  eventId,
  maxInvites,
  expireAfter
) => {
  const body = { maxInvites, expireAfter };

  const res = await axios.post(
    `${REACT_APP_API_HOST}/manage/event/${eventId}/invites/creators/add`,
    body,
    authHttpConfig(idToken)
  );

  return res?.data;
};

export const ownerAddTeamMember = async (
  idToken,
  eventId,
  role,
  address,
  email
) => {
  const body = { role, address, email };

  const res = await axios.post(
    `${REACT_APP_API_HOST}/manage/event/${eventId}/team/add`,
    body,
    authHttpConfig(idToken)
  );

  return res?.data;
};

export const ownerUpdateTeamMember = async (
  idToken,
  eventId,
  action,
  userId,
  role
) => {
  const body = { action, userId, role };

  const res = await axios.post(
    `${REACT_APP_API_HOST}/manage/event/${eventId}/team/admin`,
    body,
    authHttpConfig(idToken)
  );

  return res?.data;
};

export const creatorUpdateOwnTeamRole = async (
  idToken,
  eventId,
  action,
  userId,
  role
) => {
  const body = { action, userId, role };

  const res = await axios.post(
    `${REACT_APP_API_HOST}/manage/event/${eventId}/team/self`,
    body,
    authHttpConfig(idToken)
  );

  return res?.data;
};

export const creatorCancelEvent = async (idToken, eventId) => {
  const res = await axios.post(
    `${REACT_APP_API_HOST}/manage/event/${eventId}/cancel`,
    null,
    authHttpConfig(idToken)
  );

  return res?.data;
};

export const creatorCloneEvent = async (idToken, eventId) => {
  const res = await axios.post(
    `${REACT_APP_API_HOST}/manage/event/clone/${eventId}`,
    null,
    authHttpConfig(idToken)
  );

  return res?.data;
};

export const creatorAddPass = async (idToken, eventId, data) => {
  const apiUrl = `${REACT_APP_API_HOST}/manage/event/${eventId}/pass/add`;

  const res = await axios.post(apiUrl, data, authHttpConfig(idToken));

  return res?.data;
};

export const creatorUpdatePass = async (idToken, eventId, data, index) => {
  const apiUrl = `${REACT_APP_API_HOST}/manage/event/${eventId}/pass/${index}/update`;

  const res = await axios.post(apiUrl, data, authHttpConfig(idToken));

  return res?.data;
};

export const creatorDeletePass = async (idToken, eventId, index) => {
  const apiUrl = `${REACT_APP_API_HOST}/manage/event/${eventId}/pass/${index}/delete`;

  const res = await axios.post(apiUrl, null, authHttpConfig(idToken));

  return res?.data;
};
