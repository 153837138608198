import React, { useState, useContext, useEffect } from "react";

import { AuthContext } from "Auth.js";

// reactstrap components
import {
  Alert,
  ButtonGroup,
  Button,
  Collapse,
  Card,
  Container,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  CardImg,
  CardTitle,
  CardBody,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Table,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SimpleUsersList from "components/Lists/SimpleUsersList.js";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import GooglePlaceAutocomplete from "react-google-autocomplete";

import { Form, Field } from "react-final-form";
import Select from "react-select";

import pluralize from "pluralize";

import {
  truncateText,
  truncateAddressShort,
  truncateAddressLong,
  nativeCurrency,
  notify,
  genImageName,
  formatPrettyNumber,
  nDaysBeforeTZ,
  validEmail,
  removeProtocolStr,
  NoDataText,
} from "utils/Utils.js";

import {
  creatorGetStatsOverview,
  creatorGetTopParticipantsByInvites,
  creatorGetTopParticipantsByBalance,
  creatorGetOptStatsByDays,
  creatorGetNewParticipantsByDays,
} from "utils/Api.js";

import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.compat.css";

import Compressor from "compressorjs";

import Avatar from "boring-avatars";

import Loader from "components/Loaders/Loader.js";
import LoaderDots from "components/Loaders/LoaderDots.js";
import LoaderSection from "components/Loaders/LoaderSection.js";
import LoaderModal from "components/Loaders/LoaderModal.js";

import ScaleLoader from "react-spinners/ScaleLoader";
import BarLoader from "react-spinners/BarLoader";
import BeatLoader from "react-spinners/BeatLoader";

import ReactDatetime from "react-datetime";
import Moment from "react-moment";
import moment from "moment-timezone";

import ReactDataGrid from "@inovua/reactdatagrid-community";
import GridButton from "@inovua/reactdatagrid-community/packages/Button";
import "@inovua/reactdatagrid-community/index.css";

import Web3 from "web3";
import { getAddressesBalances } from "eth-balance-checker/lib/ethers";

import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { USDateTimeShortOption } from "utils/Variables";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const {
  defaultFormImg,
  defaultFormBanner,
  defaultProfileImg,
  bgGradient,
  defaultBoxGrayImg,
  verifiedImg,
  ethImg,
  nsfwImg,
  mmImg,
  wcImg,
  pwImg,
  ethWhiteImg,
  ethLogoColor,
  polygonImg,
  polygonWhiteImg,
  polygonLogoColor,
  USDateShortOption,
  USDateOption,
} = require("utils/Variables.js");

export default function EventOverview(props) {
  let { event, eventId, isEventActiveCreator } = props;

  const { currentUser, currentAuthToken, currentUserInfo, setCurrentUserInfo } =
    useContext(AuthContext);

  const hasAdminRole = currentUserInfo?.hasAdminRole;
  const hasCreatorRole = currentUserInfo?.hasCreatorRole;
  const isVerifiedUser = currentUserInfo?.isVerifiedUser;

  const [totalLikes, setTotalLikes] = useState(event?.totalLikes || 0);
  const [totalReg, setTotalReg] = useState(event?.totalRegistered || 0);
  const [totalCheckin, setTotalCheckin] = useState(event?.totalCheckin || 0);

  const [totalLikes24Hour, setTotalLikes24Hour] = useState(0);
  const [totalUsers24Hour, setTotalUsers24Hour] = useState(0);
  const [totalCheckin24Hour, setTotalCheckin24Hour] = useState(0);

  const [maxUsers, setMaxUsers] = useState(event?.maxParticipants || 0);
  const [spotsOccupyRate, setSpotsOccupyRate] = useState(
    isNaN(event?.totalRegistered) ||
      isNaN(event?.maxParticipants) ||
      event?.totalRegistered === 0 ||
      event?.maxParticipants <= 0 ||
      isNaN(event?.totalRegistered / event?.maxParticipants)
      ? 0
      : (event?.totalRegistered / event?.maxParticipants).toFixed(0)
  );

  const [lastNDaysFilter, setLastNDaysFilter] = useState(7);

  const [participantsChartOptions, setUsersChartOptions] = useState();

  const [participantsChartLabels, setUsersChartLabels] = useState();
  const [participantsChartData, setUsersChartData] = useState();

  const [newUsers, setNewUsers] = useState([]);
  const [newUsersCount, setNewUsersCount] = useState(0);

  const [chartLoading, setChartLoading] = useState(false);
  const [newUsersLoading, setNewUsersLoading] = useState(false);

  const [topUsersLoading, setTopUsersLoading] = useState(false);
  const [allUsersLoading, setAllUsersLoading] = useState(false);

  const [statsData1Day, setStatsData1Day] = useState();
  const [statsData7Day, setStatsData7Day] = useState();
  const [statsData30Day, setStatsData30Day] = useState();

  const [newUsers1Day, setNewUsers1Day] = useState();
  const [newUsers7Day, setNewUsers7Day] = useState();
  const [newUsers30Day, setNewUsers30Day] = useState();

  const [topUsersByInvites, setTopUsersByInvites] = useState([]);
  const [topUsersByBalance, setTopUsersByBalance] = useState([]);
  const [topUsersFilter, setTopUsersFilter] = useState("Invites");

  const [memberInviteMaxUsage, setMemberInviteMaxUsage] = useState(
    event?.memberInviteMaxUsage
  );

  const fetchOverview = async () => {
    try {
      const res = await creatorGetStatsOverview(currentAuthToken, eventId);

      if (res) {
        setTotalLikes(res.totalLikes || 0);
        setTotalReg(res.totalRegistered || 0);
        setTotalCheckin(res.totalCheckin || 0);

        setTotalLikes24Hour(res.totalLikes24H || 0);
        setTotalUsers24Hour(res.totalIncrease24H || 0);
        setTotalCheckin24Hour(res.totalCheckin24H || 0);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const fetchChartStats = async (days) => {
    try {
      setChartLoading(true);

      let stats;
      if (
        (days === 1 && !statsData1Day) ||
        (days === 7 && !statsData7Day) ||
        (days === 30 && !statsData30Day)
      ) {
        stats = await creatorGetOptStatsByDays(currentAuthToken, eventId, days);

        if (days === 1) {
          setStatsData1Day(stats);
        }

        if (days === 7) {
          setStatsData7Day(stats);
        }

        if (days === 30) {
          setStatsData30Day(stats);
        }
      } else {
        stats =
          days === 30
            ? statsData30Day
            : days === 1
            ? statsData1Day
            : statsData7Day;
      }

      let optinMax, optinMin, optinMaxLen;
      let optoutMax, optoutMin, optoutMaxLen;
      let optinData, optoutData;
      let labels;

      if (stats?.length > 0) {
        const resFillDays = [];

        // Fill the gapping data
        for (let d = days; d >= 0; d--) {
          const past = nDaysBeforeTZ(d);

          const found = stats.find((i) => {
            const date = new Date(i.date);
            date.setHours(0, 0, 0, 0);
            past.setHours(0, 0, 0, 0);
            return past.getTime() === date?.getTime();
          });

          if (found) {
            resFillDays.push(found);
          } else {
            resFillDays.push({ date: past, totalReg: 0, totalOptout: 0 });
          }
        }

        labels = resFillDays.map((item) =>
          new Date(item.date)?.toLocaleDateString("en-US", USDateShortOption)
        );
        optinData = resFillDays.map((item) => item.totalRegistered || 0);
        optinMax = Math.max(...stats.map((item) => item.totalRegistered || 0));
        optinMin = Math.min(...stats.map((item) => item.totalRegistered || 0));
        // Get number of digits, eg. 100 => 3
        optinMaxLen = (Math.log(optinMax) * Math.LOG10E + 1) | 0;

        optoutData = resFillDays.map((item) => item.totalOptout || 0);
        optoutMax = Math.max(...stats.map((item) => item.totalOptout || 0));
        optoutMin = Math.min(...stats.map((item) => item.totalOptout || 0));
        // Get number of digits, eg. 100 => 3
        optoutMaxLen = (Math.log(optoutMax) * Math.LOG10E + 1) | 0;
      } else {
        const resFillDays = [];

        for (let d = days; d >= 0; d--) {
          const past = new Date();
          past.setDate(past.getDate() - d);
          resFillDays.push({ date: past, count: 0 });
        }

        labels = resFillDays.map((item) =>
          item.date?.toLocaleDateString("en-US", USDateShortOption)
        );

        optinData = resFillDays.map((item) => item.totalRegistered);
        optinMax = 2;
        optinMin = 0;
        optinMaxLen = 1;

        optoutData = resFillDays.map((item) => item.totalOptout);
        optoutMax = 2;
        optoutMin = 0;
        optoutMaxLen = 1;
      }

      const chartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        interaction: {
          mode: "nearest",
          axis: "x",
          intersect: false,
        },
        scale: {
          ticks: {
            precision: 0,
            stepSize: 10 ** (Math.max(optinMaxLen, optoutMaxLen) - 1),
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            suggestedMax: Math.max(optinMax, optoutMax) * 1.2,
            suggestedMin: 0,
            grid: {
              display: true,
            },
          },
        },
        plugins: {
          legend: {
            labels: {
              boxWidth: 5,
              boxHeight: 5,
              usePointStyle: true,
            },
            position: "top",
            onClick: () => {},
          },
          title: {
            display: false,
          },
        },
      };

      setUsersChartOptions(chartOptions);

      const chartData = {
        labels,
        datasets: [
          {
            label: " On Board",
            data: optinData,
            borderColor: "rgb(45, 112, 245)",
            backgroundColor: "rgb(45, 112, 245)",
          },
          {
            label: " Opt Out",
            data: optoutData,
            borderColor: "rgba(245, 45, 112)",
            backgroundColor: "rgba(245, 45, 112)",
          },
        ],
      };

      setUsersChartData(chartData);
    } catch (err) {
      console.error(err.message);
    } finally {
      setChartLoading(false);
    }
  };

  const fetchNewUsers = async (days, fetchNew) => {
    try {
      setNewUsersLoading(true);
      let pageSize = 50;

      let res = await creatorGetNewParticipantsByDays(
        currentAuthToken,
        eventId,
        days,
        pageSize
      );

      let participantList = res?.participants;

      if (
        (days === 1 && !newUsers1Day) ||
        (days === 7 && !newUsers7Day) ||
        (days === 30 && !newUsers30Day)
      ) {
        if (days === 1) {
          setNewUsers1Day(participantList);
        }

        if (days === 7) {
          setNewUsers7Day(participantList);
        }

        if (days === 30) {
          setNewUsers30Day(participantList);
        }
      } else {
        participantList =
          days === 30
            ? newUsers30Day
            : days === 1
            ? newUsers1Day
            : newUsers7Day;
      }

      if (participantList?.length > 0) {
        setNewUsers(participantList);
        setNewUsersCount(participantList?.length);
      }
    } catch (err) {
      console.error(err.message);
      notify("Unable to fetch new users data.", "error");
    } finally {
      setNewUsersLoading(false);
    }
  };

  const fetchTopUsersByInviites = async () => {
    try {
      setTopUsersLoading(true);

      const res = await creatorGetTopParticipantsByInvites(
        currentAuthToken,
        eventId
      );

      if (res?.length > 0) {
        setTopUsersByInvites(res);
      }
    } catch (err) {
      console.error(err.message);
    } finally {
      setTopUsersLoading(false);
    }
  };

  // Only bulk balance support ETH balance at the moment
  const fetchTopUsersByBalance = async () => {
    try {
      setTopUsersLoading(true);

      const res = await creatorGetTopParticipantsByBalance(
        currentAuthToken,
        eventId
      );

      if (res?.length > 0) {
        setTopUsersByBalance(res);
      }
    } catch (err) {
      console.error(err.message);
    } finally {
      setTopUsersLoading(false);
    }
  };

  const listTopUsers = (topUsers) => {
    return topUsers?.length > 0 ? (
      topUsers.map((item, index) => {
        return (
          <div key={index} className="mt-2 mb-3 pt-3 px-3 border-top">
            <a href={"/" + item.ethAddress} target="_blank">
              <Row>
                <Col className="text-left" xl="1" lg="1" md="1" sm="2" xs="2">
                  <div>
                    <strong>{index + 1}</strong>
                    <sup>
                      {index <= 2 && (
                        <FontAwesomeIcon
                          icon="fa-crown"
                          className="fa-sm ml-2"
                          style={{
                            color:
                              index === 0
                                ? "gold"
                                : index === 1
                                ? "silver"
                                : index === 2
                                ? "brown"
                                : "",
                          }}
                        />
                      )}
                    </sup>
                  </div>
                </Col>
                <Col className="text-left" xl="7" lg="7" md="7" sm="10" xs="10">
                  <div className="author">
                    {item.imageUrl ? (
                      <img
                        alt="..."
                        className="avatar img-raised"
                        src={item.imageUrl}
                      />
                    ) : (
                      <span className="rounded-circle avatar img-raised">
                        <Avatar
                          size={40}
                          name={item.ethAddress}
                          square="false"
                          variant="ring"
                          colors={[
                            "#000000",
                            "#2F2F2F",
                            "#505050",
                            "#797979",
                            "#CECECE",
                          ]}
                          className="rounded-circle profile-image"
                        />
                      </span>
                    )}

                    <div className="text">
                      <span className="name">
                        {truncateText(item.name, 18) || "Unnamed"}

                        {item.isVerifiedUser && (
                          <img
                            className="realpass-verified-name-suffix ml-2 mb-1"
                            src={verifiedImg}
                          />
                        )}
                      </span>
                      <div className="meta">
                        <span>{truncateAddressLong(item.ethAddress)}</span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl="2" lg="2" md="2" sm="6" xs="6">
                  <div
                    className="text"
                    style={{ cursor: "pointer" }}
                    id={`topMemberInvitesCount-${index}`}
                  >
                    <div className="meta text-muted">
                      <small>
                        <strong>Invites</strong>
                      </small>
                    </div>
                    <span className="name">
                      <strong>{formatPrettyNumber(item?.totalInvites)}</strong>
                      <small>
                        <FontAwesomeIcon
                          icon="fa-circle-info"
                          className="text-muted ml-2 fa-sm"
                        />
                      </small>
                    </span>
                  </div>
                  <UncontrolledTooltip
                    placement="top"
                    target={`topMemberInvitesCount-${index}`}
                    trigger="hover"
                  >
                    {pluralize("active member", item?.totalInvites || 0, true)}{" "}
                    invited
                  </UncontrolledTooltip>
                </Col>
                <Col xl="2" lg="2" md="2" sm="6" xs="6">
                  <div
                    className="text"
                    style={{ cursor: "pointer" }}
                    id={`topMemberBalance-${index}`}
                  >
                    <div className="meta text-muted">
                      <small>
                        <strong>Balance</strong>
                      </small>
                    </div>
                    <span className="name">
                      <strong>
                        {formatPrettyNumber(
                          item?.balanceEth?.toLocaleString("en-US", {
                            maximumFractionDigits: 5,
                            minimumFractionDigits: 0,
                          })
                        )}
                      </strong>
                      <small>
                        {" "}
                        {nativeCurrency("eth")}
                        <FontAwesomeIcon
                          icon="fa-circle-info"
                          className="text-muted ml-2 fa-sm"
                        />
                      </small>
                    </span>
                  </div>

                  <UncontrolledTooltip
                    placement="top"
                    target={`topMemberBalance-${index}`}
                    trigger="hover"
                  >
                    Balance{" "}
                    {(item?.balanceEth?.toLocaleString("en-US", {
                      maximumFractionDigits: 9,
                      minimumFractionDigits: 2,
                    }) || 0) + " ETH"}
                    {item?.balanceCheckedAt &&
                      " checked on " +
                        new Date(item?.balanceCheckedAt)?.toLocaleDateString(
                          "en-US",
                          USDateOption
                        )}
                  </UncontrolledTooltip>
                </Col>
              </Row>
            </a>
          </div>
        );
      })
    ) : (
      <div>{topUsersLoading || <>{NoDataText("lg")}</>}</div>
    );
  };

  useEffect(() => {
    if (eventId) {
      fetchOverview();
    }
  }, []);

  useEffect(() => {
    if (eventId) {
      fetchChartStats(lastNDaysFilter);
      fetchNewUsers(lastNDaysFilter, true);
    }
  }, [lastNDaysFilter]);

  useEffect(() => {
    if (eventId) {
      if (topUsersFilter === "ETH") {
        fetchTopUsersByBalance();
      }

      if (topUsersFilter === "Invites") {
        fetchTopUsersByInviites();
      }
    }
  }, [topUsersFilter]);

  return (
    <>
      {(hasAdminRole || isEventActiveCreator) && (
        <>
          <Row className="mt-3 mb-5">
            <Col xl="3" lg="3" md="6" sm="6" xs="6" className="p-1">
              <Card className="card-stats shadow">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle className="text-muted mb-0">
                        <small>Likes</small>
                      </CardTitle>
                      <span className="h5 font-weight-bold mb-0">
                        {formatPrettyNumber(totalLikes)}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                        <FontAwesomeIcon icon="fa-heart-circle-plus" />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-sm">
                    <span
                      className={`mr-2 ${
                        totalLikes24Hour >= 0 ? "text-success" : "text-warning"
                      }`}
                    >
                      <FontAwesomeIcon
                        icon={
                          totalLikes24Hour >= 0
                            ? "fa-arrow-up"
                            : "fa-arrow-down"
                        }
                      />{" "}
                      {formatPrettyNumber(totalLikes24Hour)}
                    </span>{" "}
                    <small className="text-nowrap">24H</small>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col xl="3" lg="3" md="6" sm="6" xs="6" className="p-1">
              <Card className="card-stats shadow">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle className="text-muted mb-0">
                        <small>Joined</small>
                      </CardTitle>
                      <span className="h5 font-weight-bold mb-0">
                        {formatPrettyNumber(totalReg)}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                        <FontAwesomeIcon icon="fa-check-to-slot" />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-sm">
                    <span
                      className={`mr-2 ${
                        totalUsers24Hour >= 0 ? "text-success" : "text-warning"
                      }`}
                    >
                      <FontAwesomeIcon
                        icon={
                          totalUsers24Hour >= 0
                            ? "fa-arrow-up"
                            : "fa-arrow-down"
                        }
                      />{" "}
                      {formatPrettyNumber(totalUsers24Hour)}
                    </span>{" "}
                    <small className="text-nowrap">24H</small>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col xl="3" lg="3" md="6" sm="6" xs="6" className="p-1">
              <Card className="card-stats shadow">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle className="text-muted mb-0">
                        <small>Checkin</small>
                      </CardTitle>
                      <span className="h5 font-weight-bold mb-0">
                        {formatPrettyNumber(totalCheckin)}
                      </span>
                    </div>
                    <Col className="col-auto text-left">
                      <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                        <FontAwesomeIcon icon="fa-street-view" />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-sm">
                    <span
                      className={`mr-2 ${
                        totalCheckin24Hour >= 0
                          ? "text-success"
                          : "text-warning"
                      }`}
                    >
                      <FontAwesomeIcon
                        icon={
                          totalCheckin24Hour >= 0
                            ? "fa-arrow-up"
                            : "fa-arrow-down"
                        }
                      />{" "}
                      {formatPrettyNumber(totalCheckin24Hour)}
                    </span>{" "}
                    <small className="text-nowrap">24H</small>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col xl="3" lg="3" md="6" sm="6" xs="6" className="p-1">
              <Card className="card-stats shadow">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle className="text-muted mb-0">
                        <small>Spots</small>
                      </CardTitle>
                      <span className="h5 font-weight-bold mb-0">
                        {!isNaN(maxUsers) ? (
                          <div>
                            <span className="h5 font-weight-bold mb-0">
                              {maxUsers >= 0 ? (
                                formatPrettyNumber(maxUsers)
                              ) : (
                                <>
                                  <FontAwesomeIcon
                                    icon="fa-infinity"
                                    id="unlimitedSpots"
                                  />

                                  <UncontrolledTooltip
                                    placement="top"
                                    target="unlimitedSpots"
                                    trigger="hover"
                                  >
                                    Unlimited Spots{" "}
                                  </UncontrolledTooltip>
                                </>
                              )}
                            </span>
                          </div>
                        ) : (
                          "TBA"
                        )}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                        <i className="ni ni-chart-bar-32" />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-sm">
                    <span className="text-success mr-2">
                      <FontAwesomeIcon icon="fa-splotch" /> {spotsOccupyRate}%
                    </span>{" "}
                    <small className="text-nowrap">Spots filled</small>
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="text-left my-lg-3 col-7">
              <h5 className="text-default">
                <strong>On Boarding</strong>
              </h5>
              {participantsChartData && (
                <small className="text-muted">
                  Number of people have newly joined
                </small>
              )}
            </Col>

            <Col className="text-right my-lg-3 col-5">
              <UncontrolledDropdown>
                <DropdownToggle
                  caret
                  outline
                  size="md"
                  type="button"
                  className="filter-button"
                >
                  <span className="mx-2">
                    Last {pluralize("Day", lastNDaysFilter || 0, true)}
                  </span>
                </DropdownToggle>
                <DropdownMenu
                  className="full-dropdown-menu-right-md filter-button-menu"
                  style={{ margin: 0 }}
                >
                  <DropdownItem onClick={() => setLastNDaysFilter(1)}>
                    <span className="">Last 1 Day</span>
                  </DropdownItem>
                  <DropdownItem onClick={() => setLastNDaysFilter(7)}>
                    <span className="">Last 7 Days</span>
                  </DropdownItem>
                  <DropdownItem onClick={() => setLastNDaysFilter(30)}>
                    <span className="">Last 30 Days</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>
          {participantsChartData ? (
            <Row className="my-2">
              <Col lg="7">
                <div className="m-lg-2 mt-2">
                  <Line
                    className="p-2"
                    height={"200px"}
                    options={participantsChartOptions}
                    data={participantsChartData}
                  />
                </div>
              </Col>
              <Col lg="5">
                <div className="m-lg-2">
                  <span className="text-primary">
                    <strong>
                      <FontAwesomeIcon icon="fa-user-check" className="mr-2" />
                      Newly On Board
                    </strong>{" "}
                    <small className="text-muted">
                      {" "}
                      {`(showing ${pluralize(
                        "most recent result",
                        newUsersCount || 0,
                        true
                      )})`}
                    </small>
                  </span>
                </div>
                {newUsers?.length > 0 ? (
                  <div
                    style={{
                      maxHeight: 360,
                      overflow: "scroll",
                    }}
                  >
                    <SimpleUsersList users={newUsers} />
                  </div>
                ) : (
                  <>{NoDataText("sm", true)}</>
                )}
              </Col>
            </Row>
          ) : (
            <>
              {chartLoading || newUsersLoading ? (
                <div className="mt-6 text-center">
                  <BeatLoader
                    color="#111"
                    loading={chartLoading && newUsersLoading}
                    height="10px"
                    cssOverride={{
                      display: "inline",
                    }}
                  />
                </div>
              ) : (
                <>{NoDataText("lg", true)}</>
              )}
            </>
          )}

          <Row className="mt-4 mb-4">
            <Col className="text-left my-lg-3 col-7">
              <h5 className="text-default">
                <strong>Top Members</strong>
              </h5>
              {(topUsersByInvites?.length > 0 ||
                topUsersByBalance?.length > 0) && (
                <small className="text-muted">
                  Showing top 10 members{" "}
                  {topUsersFilter === "Invites"
                    ? " by invites"
                    : topUsersFilter === "ETH" && " by ETH balance"}
                </small>
              )}
            </Col>

            <Col className="text-right my-lg-3 col-5">
              <UncontrolledDropdown>
                <DropdownToggle
                  caret
                  outline
                  size="md"
                  type="button"
                  className="filter-button"
                >
                  <span className="mx-2">Top {topUsersFilter}</span>
                </DropdownToggle>
                <DropdownMenu
                  className="full-dropdown-menu-right-md filter-button-menu"
                  style={{ margin: 0 }}
                >
                  <DropdownItem onClick={() => setTopUsersFilter("Invites")}>
                    <span className="">By Invites</span>
                  </DropdownItem>
                  <DropdownItem onClick={() => setTopUsersFilter("ETH")}>
                    <span className="">By ETH Balance</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>
          {(topUsersFilter === "Invites" && topUsersByInvites?.length > 0) ||
          (topUsersFilter === "ETH" && topUsersByBalance?.length > 0) ? (
            <Row className="mt-2 mb-5 text-center">
              <Col>
                <div>
                  <>
                    {topUsersFilter === "ETH"
                      ? listTopUsers(topUsersByBalance)
                      : topUsersFilter === "Invites" &&
                        listTopUsers(topUsersByInvites)}
                  </>
                </div>
              </Col>
            </Row>
          ) : (
            <>
              {topUsersLoading ? (
                <div className="mt-6 mb-5 text-center">
                  <BeatLoader
                    color="#111"
                    loading={topUsersLoading}
                    height="10px"
                    cssOverride={{
                      display: "inline",
                    }}
                  />
                </div>
              ) : (
                <>{NoDataText("lg", true)}</>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
