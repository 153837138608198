import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";

import { AuthContext } from "Auth.js";

import QRCode from "react-qr-code";
// import { RandomHash } from "random-hash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Select from "react-select";
import makeAnimated from "react-select/animated";

// reactstrap components
import {
  Button,
  Badge,
  Alert,
  Card,
  CardHeader,
  CardBody,
  Container,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Col,
  NavbarBrand,
  Toast,
  ToastHeader,
  ToastBody,
  Spinner,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  Progress,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// core components
import TopNavbar from "components/Navbars/TopNavbar.js";

import Loader from "components/Loaders/Loader.js";
import LoaderModal from "components/Loaders/LoaderModal.js";

import ModalSuccess from "components/Modals/ModalSuccess.js";

import ScaleLoader from "react-spinners/ScaleLoader";
import BarLoader from "react-spinners/BarLoader";

import Avatar from "boring-avatars";

import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.compat.css";

import Web3 from "web3";

import ReactMarkdown from "react-markdown";

import {
  truncateAddressShort,
  truncateText,
  notify,
  hashCode,
  checksumEthAddress,
} from "utils/Utils.js";

import {
  getUserAccess,
  getEventAccess,
  userUpdateEventAccessKey,
  getEvent,
  countEventUsers,
  getEventBasics,
  userDeregisterEvent,
  validateInviteCode,
  retrieveInviteCodeUsed,
  userReverifyEvent,
} from "utils/Api.js";

const defaultProfileImg = require("assets/img/default/profile.svg");
const defaultEventImg = require("assets/img/default/rpbox.svg");
const defaultBannerImg = require("assets/img/banners/gradient-banner.svg");

const {
  bgAbstractLine01,
  bgAbstractLine02,
  bgAbstractLine03,
  bgAbstractLine04,
  bgAbstractLine05,
  logoWhiteImg,
  passChipImg,
  defaultBoxGrayImg,
  verifiedImg,
  nsfwImg,
  ethImg,
  ethWhiteImg,
  polygonImg,
  polygonWhiteImg,
  calImg,
  gCalImg,
  outlookImg,
  EventRegisterLimit,
  ChainLogos,
  ChainOptions,
  EventCategoryOptions,
  USDateOption,
  USDateTimeOption,
  USDateTimeShortOption,
  UserRoleTypes,
  CreatorRoleTypes,
  UserRoleStatus,
  VerifiedUserTypes,
  VerifiedUserStatus,
  SocialShareOptions,
  SocialShareMessage,
  SocialShareTitle,
  SocialShareSubject,
  SocialShareUsername,
  DisclaimerShort,
} = require("utils/Variables.js");

const { REACT_APP_HOST } = process.env;

function MyAccess() {
  const navigate = useNavigate();

  const { currentUser, currentAuthToken, currentUserInfo, setCurrentUserInfo } =
    useContext(AuthContext);

  const hasCreatorRole = currentUserInfo?.hasCreatorRole;
  const isVerifiedUser = currentUserInfo?.isVerifiedUser;

  const [ethAddress, setEthAddress] = useState(currentUserInfo?.ethAddress);

  const [searchParams, setSearchParams] = useSearchParams();
  const eventId = searchParams.get("event");

  const [pageLoading, setPageLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const [image, setImage] = useState();
  const [name, setName] = useState("Unnamed");

  const [passVerified, setPassVerified] = useState();
  const [eventImage, setEventImage] = useState();
  const [bannerImage, setBannerImage] = useState();
  const [eventName, setEventName] = useState();
  const [supportEmail, setSupportEmail] = useState();
  const [eventDescription, setEventDescription] = useState();

  const [blockchain, setBlockchain] = useState();
  const [passName, setPassName] = useState();
  const [passTokenId, setPassTokenId] = useState();
  const [accessActive, setAccessActive] = useState();
  const [accessBanned, setAccessBanned] = useState(false);
  const [passType, setPassType] = useState();
  const [passImage, setPassImage] = useState();
  const [passContract, setPassContract] = useState();
  const [requireUniquePassId, setRequireUniquePassId] = useState();
  const [isFree, setIsFree] = useState(false);
  const [grantedByCreator, setGrantedByCreator] = useState(false);

  const [totalReg, setTotalReg] = useState(0);
  const [maxParticipants, setMaxParticipants] = useState();
  const [maxUserRegAttempts, setMaxUserRegAttempts] = useState();
  const [spotsFull, setSpotsFull] = useState();
  const [maxAttemptsReached, setMaxAttemptsReached] = useState();

  const [eventStartDate, setEventStartDate] = useState();
  const [eventEndDate, setEventEndDate] = useState();
  const [eventEnded, setEventEnded] = useState();

  const [inviteCode, setInviteCode] = useState("");
  const [inviteError, setInviteError] = useState();
  const [inviteCodeChecking, setInviteCodeChecking] = useState(false);
  const [isInviteCodeValid, setIsInviteCodeValid] = useState();

  const [isPrivate, setIsPrivate] = useState();

  const [isCancelled, setIsCancelled] = useState(false);
  const [isPublished, setIsPublished] = useState(true);
  const [isRegEnabled, setIsRegEnabled] = useState();

  const [isVirtual, setIsVirtual] = useState(true);
  const [eventLocation, setEventLocation] = useState();

  const [isNSFW, setIsNSFW] = useState();

  const [tokenUrl, setTokenUrl] = useState();

  const [ownerEthAddress, setOwnerEthAddress] = useState();
  const [isOwner, setIsOwner] = useState(false);
  const [isCohost, setIsCohost] = useState(false);
  const [isPanelist, setIsPanelist] = useState(false);
  const [isEventCreator, setIsEventCreator] = useState(false);

  const [accessCipherCode, setAccessCipherCode] = useState();
  const [accessQRCode, setAccessQRCode] = useState(REACT_APP_HOST);
  const [accessHashCode, setAccessHashCode] = useState();

  const [registered, setRegistered] = useState();
  const [registeredAt, setRegisteredAt] = useState();
  const [registerCount, setRegisterCount] = useState();

  const [creatorEthAddress, setCreatorEthAddress] = useState();
  const [creatorName, setCreatorName] = useState();

  const [isNFVerified, setIsNFVerified] = useState(false);

  const [passError, setPassError] = useState();
  const [accessError, setAccessError] = useState();
  const [accessAlert, setAccessAlert] = useState(true);

  const [showMoreText, setShowMoreText] = useState(false);
  const [hasMoreText, setHasMoreText] = useState(false);

  const [qrActive, setQRActive] = useState(false);

  const [reverifySuccess, setReverifySuccess] = useState();
  const [deregisterModalOpen, setDeregisterModalOpen] = useState(false);
  const [regenKeySuccess, setRegenKeySuccess] = useState();
  const [regenKeyModalOpen, setRegenKeyModalOpen] = useState(false);
  const [qrModalOpen, setQRModalOpen] = useState(false);
  const [passModalOpen, setPassModalOpen] = useState(false);

  const animatedComponents = makeAnimated();
  const [userTotalPassHolding, setUserTotalPassHolding] = useState(0);
  const [userTokenOptions, setUserTokenOptions] = useState([]);
  const [userTokenOption, setUserTokenOption] = useState();
  const [eligibleToJoin, setEligibleToJoin] = useState(false);
  const [reverifyModalOpen, setReverifyModalOpen] = useState(false);

  const [confirmAge, setConfirmAge] = useState(false);

  const fetchEvent = async () => {
    try {
      setPageLoading(true);
      const event = await getEvent(currentAuthToken, eventId);

      if (event) {
        setEventImage(event.imageUrl);
        setBannerImage(event.bannerUrl);
        setEventName(event.name);

        setSupportEmail(event.supportEmail);

        setIsFree(event.isFree);

        if (event.isFree) {
          setPassVerified(true);
        }

        setIsCancelled(event.isCancelled);
        setIsPublished(event.isPublished);
        setIsRegEnabled(event.isRegisterEnabled);
        setIsPrivate(event.isPrivate);
        setMaxUserRegAttempts(event?.maxUserRegAttempts);

        const owner = event?.owner;
        setOwnerEthAddress(owner?.ethAddress);
        setIsOwner(event.isOwner);
        setIsCohost(event.isCohost);
        setIsPanelist(event.isPanelist);

        const isCreator =
          hasCreatorRole &&
          (event.isOwner || event.isCohost || event.isPanelist);
        setIsEventCreator(isCreator);

        const end = new Date(event.endDateTime);
        setEventEnded(end?.getTime() < new Date().getTime());

        const totalReg = event.totalRegistered;
        setTotalReg(totalReg || 0);

        setSpotsFull(
          event.maxParticipants >= 0 && totalReg >= event.maxParticipants
        );

        if (!event.isCancelled && event.isPublished) {
          await verifyAccess(event?.maxUserRegAttempts);
        }
      } else {
        navigate("/404");
      }
    } catch (err) {
      console.error(err.message);

      if (err.response?.status === 404) {
        navigate("/404");
      }

      notify("Event is not available.", "error");
    } finally {
      setPageLoading(false);
    }
  };

  const fetchUserAccess = async () => {
    try {
      setPageLoading(true);

      const access = await getUserAccess(currentAuthToken);

      if (access) {
        setPassVerified(true);

        setAccessCipherCode(access.cipherAccessCode);
        setAccessActive(true);
        setAccessError();

        genQR(ethAddress, access.cipherAccessCode);
      }
    } catch (err) {
      console.error(err.message);
    } finally {
      setPageLoading(false);
    }
  };

  const fetchTotalParticipants = async () => {
    if (isCancelled || !isPublished) {
      return;
    }

    try {
      let count = await countEventUsers(eventId);
      const totalReg = count?.totalRegistered;

      if (isNaN(totalReg)) {
        totalReg = 0;
      }

      setTotalReg(totalReg);

      setSpotsFull(maxParticipants >= 0 && totalReg >= maxParticipants);
    } catch (err) {
      console.error(err.message);
    }
  };

  const deregisterConfirm = async () => {
    try {
      setModalLoading(true);
      await deregister();

      notify("Okay! You no longer have an active access.", "success");

      setDeregisterModalOpen(!deregisterModalOpen);
    } catch (err) {
      console.error(err.message);
    } finally {
      setModalLoading(false);
    }
  };

  const deregister = async () => {
    if (isCancelled || !isPublished) {
      return;
    }

    if (accessActive) {
      const participant = await userDeregisterEvent(currentAuthToken, eventId);

      if (participant?.registerStatus === "inactive") {
        setAccessActive(false);
        setAccessCipherCode();
        setRegistered();
        setRegisteredAt();

        setAccessError(
          "Event access is not active. You may need to reverify access."
        );
        setAccessQRCode(REACT_APP_HOST);
        setAccessHashCode();

        await fetchTotalParticipants();
      }
    }
  };

  const checkInviteCode = async (privateEvent) => {
    if (isCancelled || !isPublished) {
      return;
    }

    if (!privateEvent) {
      setIsInviteCodeValid();
      setInviteError();
    }

    if (privateEvent && !inviteCode) {
      setIsInviteCodeValid(false);
      setInviteError("Invite code is not valid.");
    }

    if (currentUserInfo && !!inviteCode) {
      try {
        setInviteCodeChecking(true);

        const isValid = await validateInviteCode(
          currentAuthToken,
          eventId,
          inviteCode
        );

        setInviteError(isValid ? "" : "Invite code is not valid.");
        setIsInviteCodeValid(isValid);
      } catch (err) {
        console.error(err.message);
        setInviteError(
          err.response?.data?.message === "Self invite error."
            ? "Self invite is not allowed."
            : "Invite code is not valid."
        );
        setIsInviteCodeValid(false);
      } finally {
        setInviteCodeChecking(false);
      }
    }
  };

  const fetchInviteCode = async () => {
    if (isCancelled || !isPublished) {
      return;
    }

    try {
      setInviteCodeChecking(true);

      const appliedInviteCode = await retrieveInviteCodeUsed(
        currentAuthToken,
        eventId
      );

      if (appliedInviteCode) {
        setInviteCode(appliedInviteCode);
        setIsInviteCodeValid(true);
        setInviteError();
      } else {
        setInviteCode("");
        setIsInviteCodeValid(false);
        setInviteError("Invite code is not valid.");
      }
    } catch (err) {
      console.error(err.message);

      setInviteCode("");
      setIsInviteCodeValid(false);
      setInviteError("Invite code is not valid.");
    } finally {
      setInviteCodeChecking(false);
    }
  };

  const checkEligibility = async () => {
    if (isCancelled || !isPublished) {
      return;
    }

    try {
      setReverifySuccess();
      setReverifyModalOpen(true);
      setModalLoading(true);

      if (isPrivate) {
        await fetchInviteCode();
      }

      if (isFree || isEventCreator) {
        setEligibleToJoin(true);
      }
    } catch (err) {
      console.error(err.message);
    } finally {
      setModalLoading(false);
    }
  };

  const reverifyAccess = async () => {
    if (isCancelled || !isPublished) {
      return;
    }

    try {
      setModalLoading(true);

      const participant = await userReverifyEvent(
        currentAuthToken,
        eventId,
        inviteCode
      );

      if (participant && participant?.registerStatus === "active") {
        setReverifySuccess(true);
        setReverifyModalOpen(false);

        const access = await getEventAccess(currentAuthToken, eventId);

        await fetchTotalParticipants();

        if (access?.registered) {
          if (access.registerStatus === "active") {
            setAccessCipherCode(access.cipherAccessCode);
            setAccessActive(true);
            setRegistered(access.registered);

            const regTime = Date.parse(access.registeredAt);

            if (!isNaN(regTime)) {
              setRegisteredAt(
                new Date(regTime).toLocaleDateString("en-US", USDateOption)
              );
            }
            setRegisterCount(access.registerCount);
            genQR(ethAddress, access.cipherAccessCode);

            setAccessError();
          } else if (access.registerStatus === "inactive") {
            setAccessCipherCode();
            setAccessActive(false);
            setAccessError(
              "Event access is inactive. You may need to reverify access."
            );

            notify("Event access is not active.", "error");
          } else if (access.registerStatus === "banned") {
            setAccessCipherCode();
            setAccessActive(false);
            setAccessBanned(true);
            setAccessError(
              "Event access is banned. You may need to contact event creator."
            );

            notify("Event access is banned.", "error");
          }

          if (access.registerCount >= maxUserRegAttempts) {
            setMaxAttemptsReached(true);
            setAccessError("You've reached maximum registration attempts.");
          }
        }
      }
    } catch (err) {
      console.error(err.message);
    } finally {
      setModalLoading(false);
    }
  };

  const verifyAccess = async (maxAttempts) => {
    if (isCancelled || !isPublished) {
      return;
    }

    try {
      setPageLoading(true);

      const access = await getEventAccess(currentAuthToken, eventId);

      if (access?.registered) {
        if (access.registerType === "creator_granted") {
          setGrantedByCreator(true);
          setPassVerified(true);
        }

        if (access.registerStatus === "active") {
          setAccessCipherCode(access.cipherAccessCode);
          setAccessActive(true);
          setRegistered(access.registered);

          const regTime = Date.parse(access.registeredAt);

          if (!isNaN(regTime)) {
            setRegisteredAt(
              new Date(regTime).toLocaleDateString("en-US", USDateOption)
            );
          }
          setRegisterCount(access.registerCount);

          setAccessError();
        } else if (access.registerStatus === "inactive") {
          setAccessCipherCode();
          setAccessActive(false);
          setAccessError(
            "Event access is not active. You may need to reverify."
          );

          notify("Event access is not active.", "error");
        } else if (access.registerStatus === "banned") {
          setAccessCipherCode();
          setAccessActive(false);
          setAccessBanned(true);
          setAccessError(
            "Event access is banned. You may need to contact event organizer."
          );

          notify("Event access is banned.", "error");
        }

        if (access.registerCount >= maxAttempts) {
          setMaxAttemptsReached(true);
          setAccessError("You've reached maximum registration attempts.");
        }
      } else {
        navigate(`/event/${eventId}`);
      }
      //   eventQuery.equalTo("objectId", eventId);
      //   nfevent = await eventQuery.first();

      //   if (nfevent) {
      //     nfaccess = await Moralis.Cloud.run("userGetOwnedEventAccess", {
      //       eventId: eventId,
      //     });

      //     if (nfaccess) {
      //       const accCreatedAt = nfaccess.get("createdAt");
      //       const valid = nfaccess.get("isValid");
      //       const tokenId = nfaccess.get("tokenId");

      //       accessKeyQuery.equalTo("access", nfaccess);

      //       nfaccessKey = await accessKeyQuery.first();

      //       setRegisteredAt(
      //         accCreatedAt?.toLocaleDateString("en-US", USDateOption)
      //       );

      //       setAccessActive(valid);
      //       setPassTokenId(tokenId);

      //       const creatorGrantFlag = await Moralis.Cloud.run(
      //         "userCheckEventAccessGrantedByCreator",
      //         {
      //           eventId: eventId,
      //         }
      //       );

      //       setGrantedByCreator(creatorGrantFlag);

      //       const name = nfevent.get("name");
      //       const description = nfevent.get("description");
      //       const image = nfevent.get("eventImage");
      //       const banner = nfevent.get("bannerImage");
      //       const contract = nfevent.get("passContract");
      //       const creator = nfevent.get("creator");
      //       const startTime = nfevent.get("startDateTime");
      //       const endTime = nfevent.get("endDateTime");
      //       const regEnabled = nfevent.get("registerEnabled");
      //       const accEnabled = nfevent.get("accessEnabled");
      //       const virtual = nfevent.get("isVirtual");
      //       const location = nfevent.get("location");
      //       const nsfw = nfevent.get("isNSFW");
      //       const uniqueId = nfevent.get("requireUniquePassId");

      //       // TODO: remove rinkeby
      //       const chain =
      //         nfevent.get("blockchain") === "eth" ? "rinkeby" : "mumbai";
      //       // const chain = nfevent.get("blockchain");

      //       setEventName(name);
      //       setEventDescription(description);
      //       setHasMoreText(description.length > 300);
      //       setEventImage(image?.url());
      //       setBannerImage(banner?.url());
      //       setBlockchain(chain);

      //       const startTimeShort = startTime?.toLocaleDateString(
      //         "en-US",
      //         USDateOption
      //       );
      //       const endTimeShort = endTime?.toLocaleDateString(
      //         "en-US",
      //         USDateOption
      //       );

      //       setRegisterEnabled(regEnabled);
      //       setAccessEnabled(accEnabled);

      //       setEventStartDate(startTimeShort);
      //       setEventEndDate(endTimeShort);

      //       if (endTime?.getTime() <= new Date().getTime()) {
      //         setEventEnded(true);
      //       }

      //       setIsVirtual(virtual);
      //       setEventLocation(location);

      //       setIsNSFW(nsfw);

      //       if (creatorGrantFlag) {
      //         setPassVerified(true);

      //         if (nfaccessKey && valid) {
      //           const key = nfaccessKey?.get("accessKey");
      //           setAccessKey(key);
      //           genQR(ethAddress, key);
      //         }
      //       } else {
      //         if (contract) {
      //           setPassContract(contract);
      //           setRequireUniquePassId(uniqueId);
      //           setIsFree(false);

      //           // TODO: check if pass contract is RealPass
      //           // TODO: remove rinkeby and mumbai
      //           if (chain === "eth") {
      //             setTokenUrl(`https://etherscan.io/address/${contract}`);
      //           }

      //           if (chain === "matic") {
      //             setTokenUrl(`https://polygonscan.com/address/${contract}`);
      //           }

      //           let nftImage;

      //           const options01 = {
      //             chain: chain,
      //             address: contract,
      //             limit: 1,
      //           };

      //           try {
      //             const res = await Moralis.Web3API.token.getAllTokenIds(
      //               options01
      //             );
      //             if (res && res.result && res.result.length > 0) {
      //               const nft = res.result[0];
      //               setPassName(nft?.name);
      //               setPassType(nft?.contract_type);

      //               nftImage = JSON.parse(nft?.metadata)?.image?.replace(
      //                 "ipfs://",
      //                 "https://gateway.moralisipfs.com/ipfs/"
      //               );
      //             } else {
      //               setPassError("Cannot find the smart contract.");
      //             }
      //           } catch (err) {
      //             console.error(err.message);
      //             setPassError("Unable to get the smart contract data.");
      //           } finally {
      //             setPageLoading(false);
      //           }

      //           if (uniqueId) {
      //             if (tokenId) {
      //               const options02 = {
      //                 chain: chain,
      //                 address: contract,
      //                 token_id: tokenId,
      //               };

      //               const nft = await Moralis.Web3API.token.getTokenIdMetadata(
      //                 options02
      //               );

      //               if (nft?.owner_of === ethAddress && tokenId) {
      //                 nftImage = JSON.parse(nft?.metadata)?.image?.replace(
      //                   "ipfs://",
      //                   "https://gateway.moralisipfs.com/ipfs/"
      //                 );

      //                 setPassVerified(true);

      //                 if (nfaccessKey && valid) {
      //                   const key = nfaccessKey?.get("accessKey");
      //                   setAccessKey(key);
      //                   genQR(ethAddress, key);
      //                 }
      //               } else {
      //                 setPassVerified(false);
      //                 // Previously verified, but have no pass now
      //                 if (valid) {
      //                   await deregister();
      //                 }
      //               }
      //             } else {
      //               setPassVerified(false);

      //               // Previously verified, but have no pass now
      //               if (valid) {
      //                 await deregister();
      //               }
      //             }
      //           } else {
      //             const options02 = {
      //               chain: chain,
      //               address: currentUser?.attributes.ethAddress,
      //               token_address: contract,
      //             };

      //             // TODO: Verification on serverside
      //             const NFTs = await Moralis.Web3API.account.getNFTsForContract(
      //               options02
      //             );

      //             if (NFTs.total > 0) {
      //               setPassVerified(true);

      //               if (nfaccessKey && valid) {
      //                 const key = nfaccessKey?.get("accessKey");
      //                 setAccessKey(key);
      //                 genQR(ethAddress, key);
      //               }
      //             } else {
      //               setPassVerified(false);
      //               // Previously verified, but have no pass now
      //               if (valid) {
      //                 await deregister();
      //               }
      //             }
      //           }
      //           setPassImage(nftImage);
      //         } else {
      //           setIsFree(true);
      //           setPassVerified(true);

      //           if (nfaccessKey && valid) {
      //             const key = nfaccessKey?.get("accessKey");
      //             setAccessKey(key);
      //             genQR(ethAddress, key);
      //           }
      //         }
      //       }
      //     } else {
      //       navigate(`/event/${eventId}`);
      //     }
      //   } else {
      //     navigate(`/event/${eventId}`);
      //   }
    } catch (err) {
      console.error(err.message);
      if (err.response?.status === 404) {
        navigate(`/event/${eventId}`);
      }

      notify("Event access is not available.", "error");
    } finally {
      setPageLoading(false);
    }
  };

  const regenKey = async () => {
    if (isCancelled || !isPublished) {
      return;
    }

    if (accessCipherCode && accessActive) {
      try {
        setModalLoading(true);

        const access = await userUpdateEventAccessKey(
          currentAuthToken,
          eventId
        );

        if (access?.registerStatus === "active" && access?.cipherAccessCode) {
          setAccessCipherCode(access.cipherAccessCode);
          genQR(ethAddress, access.cipherAccessCode);

          setRegenKeyModalOpen(false);
          setRegenKeySuccess(true);
        }
      } catch (err) {
        console.error(err.message);
        notify("Unable to update access key.", "error");
      } finally {
        setModalLoading(false);
      }
    }
  };

  const genQR = (wallet, key) => {
    if (wallet && key) {
      const salt = Math.round(new Date().getTime() / 500);
      const seed = salt + key;

      const hash = hashCode(seed);

      const qrCode = `${REACT_APP_HOST}/event/checkin/${eventId}?wallet=${wallet}&ahash=${hash}`;

      setAccessQRCode(qrCode);
      setAccessHashCode(hash);
    }
  };

  useEffect(() => {
    if (currentUserInfo) {
      if (eventId) {
        fetchEvent();
      } else {
        fetchUserAccess();
      }
    } else {
      navigate(`/event/${eventId}`);
    }
  }, []);

  useEffect(() => {
    if (accessCipherCode && qrActive && accessActive && passVerified) {
      const qrTimer = setTimeout(() => {
        genQR(ethAddress, accessCipherCode);
      }, 500);

      return () => {
        clearTimeout(qrTimer);
      };
    }
  }, [accessHashCode, qrActive]);

  return (
    <>
      <BarLoader
        loading={pageLoading}
        cssOverride={{
          width: "100%",
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          zIndex: "1031",
          backgroundColor: "#fff",
          opacity: "1",
        }}
      />
      <TopNavbar />
      <ReactNotifications />
      {!pageLoading &&
        (isCancelled ? (
          <Alert
            color="dark"
            className="mb-0 mt-2 text-center rounded-0 bg-dark"
            style={{ zIndex: "1000" }}
          >
            <span className="h5 text-white">
              ☔️ This event has been cancelled by the{" "}
              <a
                href={`/${ownerEthAddress}`}
                target="_blank"
                className="text-info"
              >
                <u>creator</u>
              </a>
              . You no longer have the access.
            </span>
          </Alert>
        ) : (
          !isPublished && (
            <Alert
              color="dark"
              className="mb-0 mt-2 text-center rounded-0 bg-dark"
              style={{ zIndex: "1000" }}
            >
              <span className="h5 text-white">
                <FontAwesomeIcon icon="fa-bell" className="mr-2" />
                The event is not published. It's only visible to admin.
              </span>
            </Alert>
          )
        ))}
      <section className="section  section-main mb--10">
        <Container>
          <div className="card-event ">
            <section className="card-event-cover-top section-shaped my-0 w-100">
              <img
                alt="..."
                src={
                  eventId
                    ? bannerImage || bgAbstractLine05
                    : currentUserInfo?.bannerUrl
                    ? currentUserInfo?.bannerUrl || bgAbstractLine05
                    : bgAbstractLine05
                }
                className="event-cover-top w-100"
              />
            </section>
            <div className="ml-lg-8 mr-lg-8 mt-5 mb-3 px-2">
              {accessError && (
                <Alert
                  color={accessBanned ? "danger" : "primary"}
                  isOpen={accessAlert}
                >
                  {accessError}

                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                    onClick={() => {
                      setAccessAlert(false);
                    }}
                  >
                    <span aria-hidden="true" className="text-white">
                      <FontAwesomeIcon icon="fa-xmark" />
                    </span>
                  </button>
                </Alert>
              )}

              <h3 className="mb-3">
                <strong>
                  {eventId ? "My Event Access " : "RealPass Access "}
                </strong>
                {eventEnded && <small className="text-warning">Ended</small>}
              </h3>
              <Row className="mt-4">
                <Col className="ml-2 mr-2" xl="5" lg="5">
                  <div className="text-center mt-3">
                    <a href="/account">
                      {currentUserInfo?.imageUrl ? (
                        <img
                          alt="..."
                          className="rounded-circle profile-image-md"
                          src={currentUserInfo?.imageUrl}
                          style={{
                            position: "relative",
                            zIndex: "0",
                          }}
                        />
                      ) : (
                        <span
                          className="rounded-circle profile-image-md"
                          style={{
                            position: "relative",
                            zIndex: "0",
                          }}
                        >
                          <Avatar
                            size={100}
                            name={currentUserInfo?.ethAddress}
                            square="false"
                            variant="ring"
                            colors={[
                              "#000000",
                              "#2F2F2F",
                              "#505050",
                              "#797979",
                              "#CECECE",
                            ]}
                            className="rounded-circle profile-image"
                          />
                        </span>
                      )}
                    </a>
                  </div>

                  <div className="text-center">
                    <h5 className="mt-3">
                      <a href="/account">
                        {currentUserInfo?.name || "Unnamed"}

                        {isVerifiedUser && (
                          <img
                            className="realpass-verified-name-suffix ml-1 mb-1"
                            src={verifiedImg}
                          />
                        )}
                      </a>
                    </h5>
                  </div>

                  <div className="text-center mt-2">
                    <Button
                      id="walletAddressButton"
                      color="dark"
                      outline
                      type="button"
                      size="sm"
                      style={{ textTransform: "none" }}
                      onClick={() => navigator.clipboard.writeText(ethAddress)}
                    >
                      <img className="icon-eth mr-2" alt="..." src={ethImg} />
                      <span className="wallet-field-sm">
                        {truncateAddressShort(ethAddress)}
                        <FontAwesomeIcon
                          icon="fa-clone"
                          className="ml-1 text-muted"
                        />
                      </span>
                    </Button>
                    <UncontrolledTooltip
                      placement="top"
                      target="walletAddressButton"
                      trigger="click"
                    >
                      Address Copied
                    </UncontrolledTooltip>
                  </div>

                  <div className="text-center mt-2">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        id="myaccessButton"
                        caret
                        size="sm"
                        outline
                        color="primary"
                        type="button"
                      >
                        <span>Options</span>
                      </DropdownToggle>
                      <DropdownMenu
                        className="full-dropdown-menu-sm"
                        style={{ margin: 0 }}
                      >
                        {eventId ? (
                          <DropdownItem href={`/event/${eventId}`}>
                            <FontAwesomeIcon
                              icon="fa-compass"
                              className="mr-1"
                            />
                            <span className="ml-1">Go to Event</span>
                          </DropdownItem>
                        ) : (
                          <DropdownItem href={`/account`}>
                            <FontAwesomeIcon
                              icon="fa-circle-user"
                              className="mr-1"
                            />
                            <span className="ml-1">My Account</span>
                          </DropdownItem>
                        )}
                        {currentUserInfo && eventId && (
                          <DropdownItem
                            onClick={() => {
                              setPassModalOpen(true);
                            }}
                          >
                            <FontAwesomeIcon
                              icon="fa-ticket"
                              className="mr-1"
                            />
                            <span className="ml-1">My Pass</span>
                          </DropdownItem>
                        )}
                        {currentUserInfo && passVerified && accessActive && (
                          <>
                            <div id="myQRCodeButton">
                              <DropdownItem
                                onClick={() => {
                                  setQRModalOpen(true);
                                }}
                                disabled={eventEnded}
                              >
                                <FontAwesomeIcon
                                  icon="fa-qrcode"
                                  className="mr-1"
                                />
                                <span className="ml-1">My QR CODE</span>
                              </DropdownItem>
                            </div>

                            {eventEnded && (
                              <UncontrolledTooltip
                                placement="top"
                                target="myQRCodeButton"
                                trigger="hover"
                              >
                                Event has ended
                              </UncontrolledTooltip>
                            )}
                          </>
                        )}
                        {currentUserInfo &&
                          passVerified &&
                          accessActive &&
                          eventId && (
                            <>
                              <div id="updateAccessKeyButton">
                                <DropdownItem
                                  onClick={() => {
                                    setRegenKeySuccess();
                                    setRegenKeyModalOpen(true);
                                  }}
                                  disabled={
                                    isCancelled ||
                                    !isPublished ||
                                    !isRegEnabled ||
                                    eventEnded
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon="fa-rotate"
                                    className="mr-1"
                                  />
                                  <span className="ml-1">
                                    Update Access Key
                                  </span>
                                </DropdownItem>
                              </div>

                              {(isCancelled ||
                                !isPublished ||
                                !isRegEnabled ||
                                eventEnded) && (
                                <UncontrolledTooltip
                                  placement="top"
                                  target="updateAccessKeyButton"
                                  trigger="hover"
                                >
                                  {isCancelled
                                    ? "Event has been cancelled"
                                    : !isPublished
                                    ? "Event is not published"
                                    : !isRegEnabled
                                    ? "Access update is disabled"
                                    : eventEnded && "Event has ended"}
                                </UncontrolledTooltip>
                              )}
                            </>
                          )}
                        {currentUserInfo &&
                          eventId &&
                          (!passVerified || !accessActive) && (
                            <>
                              <DropdownItem divider></DropdownItem>

                              <div id="reverifyAccessButton">
                                <DropdownItem
                                  onClick={() => checkEligibility()}
                                  disabled={
                                    isCancelled ||
                                    !isPublished ||
                                    !isRegEnabled ||
                                    eventEnded ||
                                    spotsFull ||
                                    accessBanned ||
                                    maxAttemptsReached
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon="fa-rotate"
                                    className="mr-2"
                                  />
                                  Reverify
                                </DropdownItem>
                              </div>

                              {(isCancelled ||
                                !isPublished ||
                                !isRegEnabled ||
                                eventEnded ||
                                spotsFull ||
                                accessBanned ||
                                maxAttemptsReached) && (
                                <UncontrolledTooltip
                                  placement="top"
                                  target="reverifyAccessButton"
                                  trigger="hover"
                                >
                                  {isCancelled
                                    ? "Event has been cancelled"
                                    : !isPublished
                                    ? "Event is not published"
                                    : !isRegEnabled
                                    ? "Reverification is disabled"
                                    : eventEnded
                                    ? "Event has ended"
                                    : spotsFull
                                    ? "Event spots are full"
                                    : accessBanned
                                    ? "Event access is banned"
                                    : maxAttemptsReached &&
                                      "Max attempts reached"}
                                </UncontrolledTooltip>
                              )}
                            </>
                          )}

                        {currentUserInfo &&
                          eventId &&
                          passVerified &&
                          accessActive && (
                            <>
                              <DropdownItem divider></DropdownItem>
                              <div id="deregisterAccessButton">
                                <DropdownItem
                                  onClick={() => {
                                    setDeregisterModalOpen(true);
                                  }}
                                  disabled={
                                    isCancelled ||
                                    !isPublished ||
                                    !isRegEnabled ||
                                    eventEnded
                                  }
                                  className={
                                    isCancelled ||
                                    !isPublished ||
                                    !isRegEnabled ||
                                    eventEnded
                                      ? "text-muted"
                                      : "text-danger"
                                  }
                                >
                                  <span>
                                    <FontAwesomeIcon
                                      icon="fa-user-xmark"
                                      className="mr-2"
                                    />
                                    Deregister
                                  </span>
                                </DropdownItem>
                              </div>

                              {(isCancelled ||
                                !isPublished ||
                                !isRegEnabled ||
                                eventEnded) && (
                                <UncontrolledTooltip
                                  placement="top"
                                  target="deregisterAccessButton"
                                  trigger="hover"
                                >
                                  {isCancelled
                                    ? "Event has been cancelled"
                                    : !isPublished
                                    ? "Event is not published"
                                    : !isRegEnabled
                                    ? "Deregistration is disabled"
                                    : eventEnded && "Event has ended"}
                                </UncontrolledTooltip>
                              )}
                            </>
                          )}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <span id="activateQRButton">
                      <Button
                        className="btn-icon"
                        size="sm"
                        outline
                        color="primary"
                        type="button"
                        onClick={() => setQRActive(!qrActive)}
                        disabled={
                          !passVerified ||
                          !accessActive ||
                          !accessCipherCode ||
                          eventEnded
                        }
                      >
                        {qrActive ? (
                          <>
                            <FontAwesomeIcon icon="fa-eye-slash" />
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon icon="fa-eye" />
                          </>
                        )}
                      </Button>
                    </span>
                    {(!passVerified ||
                      !accessActive ||
                      !accessCipherCode ||
                      eventEnded) && (
                      <UncontrolledTooltip
                        placement="top"
                        target="activateQRButton"
                        trigger="hover"
                      >
                        {!passVerified || !accessActive || !accessCipherCode
                          ? "QR Code is not available"
                          : eventEnded && "Event has ended"}
                      </UncontrolledTooltip>
                    )}
                  </div>
                </Col>
                <Col className="mt-4">
                  <div>
                    <div className="access_card_panel">
                      <a
                        className="p-0"
                        onClick={() => setQRModalOpen(true)}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="access_card access_card--front">
                          {!pageLoading && (
                            <>
                              <img
                                className="access_card__chip"
                                src={passChipImg}
                              />
                              <div className="">
                                <div className="access_card__event">
                                  {eventId
                                    ? truncateText(eventName, 24)
                                    : "RealPass Membership"}
                                </div>
                                <div className="access_card__checkin-date">
                                  {eventId &&
                                    (registered && registeredAt ? (
                                      <>
                                        Joined on {registeredAt}
                                        {eventEnded && " (Ended)"}
                                      </>
                                    ) : (
                                      "Not Joined"
                                    ))}
                                </div>
                                <div className="access_card__pass-info text-uppercase">
                                  {eventId ? (
                                    isEventCreator ? (
                                      "Creator Pass"
                                    ) : grantedByCreator ? (
                                      "Granted Pass"
                                    ) : passName ? (
                                      !passVerified || !accessActive ? (
                                        <s>
                                          {truncateText(passName, 20)}{" "}
                                          {requireUniquePassId &&
                                            passTokenId &&
                                            "#" + passTokenId}
                                        </s>
                                      ) : (
                                        <>
                                          {truncateText(passName, 20)}{" "}
                                          {requireUniquePassId &&
                                            passTokenId &&
                                            "#" + passTokenId}
                                        </>
                                      )
                                    ) : isFree ? (
                                      "Free Pass"
                                    ) : (
                                      "No Pass"
                                    )
                                  ) : (
                                    "RealPass"
                                  )}
                                </div>
                              </div>
                              <div className="access_card__verify">
                                <div
                                  className={
                                    passVerified && accessActive
                                      ? "text-success text-center"
                                      : !passVerified || !accessActive
                                      ? "text-danger text-center"
                                      : "text-white text-center"
                                  }
                                >
                                  <strong>
                                    {passVerified &&
                                    accessActive &&
                                    accessCipherCode ? (
                                      <>
                                        VERIFIED
                                        <FontAwesomeIcon
                                          icon="fa-circle-check"
                                          className="ml-1"
                                        />
                                      </>
                                    ) : !passVerified || !accessActive ? (
                                      <>
                                        INVALID
                                        <FontAwesomeIcon
                                          icon="fa-circle-xmark"
                                          className="ml-1"
                                        />
                                        <br />
                                      </>
                                    ) : (
                                      <>
                                        NOT VERIFIED
                                        <FontAwesomeIcon
                                          icon="fa-circle-arrow-down"
                                          className="ml-1"
                                        />
                                      </>
                                    )}
                                  </strong>
                                </div>
                                <div
                                  className={
                                    passVerified &&
                                    accessActive &&
                                    accessHashCode &&
                                    qrActive
                                      ? "m-1 access_card__qr"
                                      : "m-1"
                                  }
                                >
                                  <QRCode
                                    className={
                                      passVerified &&
                                      accessActive &&
                                      accessHashCode &&
                                      qrActive
                                        ? ""
                                        : "qr-overlay"
                                    }
                                    fgColor="#222"
                                    bgColor="#fff"
                                    size={135}
                                    value={
                                      (qrActive && accessQRCode) ||
                                      REACT_APP_HOST
                                    }
                                  />
                                </div>
                                <Badge
                                  pill
                                  color="primary"
                                  className="access_card__code pl-2 pr-2 m-1"
                                >
                                  <span className="text-white">
                                    {passVerified &&
                                    accessActive &&
                                    accessCipherCode ? (
                                      <span className="text-lowercase">
                                        {qrActive && accessHashCode ? (
                                          <>
                                            <FontAwesomeIcon
                                              icon="fa-lock-open"
                                              className="ml-1 mr-1"
                                            />
                                            {accessHashCode}
                                          </>
                                        ) : (
                                          <>
                                            <FontAwesomeIcon
                                              icon="fa-lock"
                                              className="ml-1 mr-1"
                                            />
                                            <strong>{"*".repeat(7)}</strong>
                                          </>
                                        )}
                                      </span>
                                    ) : (
                                      <span>
                                        <FontAwesomeIcon
                                          icon="fa-lock"
                                          className="mr-1"
                                        />
                                        Invalid
                                      </span>
                                    )}
                                  </span>
                                </Badge>
                              </div>
                              <div className="access_card__wallet_address">
                                {checksumEthAddress(
                                  currentUserInfo?.ethAddress
                                )}
                              </div>
                              <div className="access_card__owner">
                                {currentUserInfo?.name || "Unnamed"}
                              </div>
                              <img
                                className="access_card__logo"
                                src={logoWhiteImg}
                              />
                            </>
                          )}
                          <img
                            alt="..."
                            src={
                              eventId ? eventImage : currentUserInfo?.imageUrl
                            }
                            className="access_card__bg"
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <section className="card-event-cover-bottom section-shaped my-0 w-100">
              <img
                alt="..."
                src={
                  eventId
                    ? bannerImage || bgAbstractLine05
                    : currentUserInfo?.bannerUrl || bgAbstractLine05
                }
                style={{ width: "100%" }}
                className="event-cover-bottom w-100"
              />
            </section>
          </div>

          {currentUserInfo &&
            passVerified &&
            accessActive &&
            !isCancelled &&
            isPublished &&
            isRegEnabled &&
            !eventEnded && (
              <Modal isOpen={deregisterModalOpen}>
                <BarLoader
                  loading={modalLoading}
                  cssOverride={{
                    marginLeft: "1.5%",
                    marginRight: "1.5%",
                    width: "97%",
                    backgroundColor: "#fff",
                    opacity: "1",
                  }}
                />
                <div className="modal-header">
                  <span className="modal-title" id="deregisterModalLabel">
                    <strong>Deregister My Access</strong>
                  </span>

                  <button
                    aria-label="Close"
                    className=" close"
                    type="button"
                    onClick={() => setDeregisterModalOpen(false)}
                    disabled={modalLoading}
                  >
                    <span aria-hidden={true}>
                      <FontAwesomeIcon icon="fa-xmark" />
                    </span>
                  </button>
                </div>
                <ModalBody>
                  <span className="text-default">
                    Are you sure you want to deregister your access? This action
                    is inreversible and will remove your access from the event.
                  </span>
                </ModalBody>
                <ModalFooter>
                  <Button
                    size="md"
                    color="danger"
                    type="button"
                    onClick={() => {
                      deregisterConfirm();
                    }}
                    disabled={modalLoading}
                  >
                    Yes, deregister
                    {modalLoading && (
                      <span className="ml-2">
                        <ScaleLoader
                          color="#eee"
                          loading={modalLoading}
                          height="10px"
                          cssOverride={{
                            display: "inline",
                          }}
                        />
                      </span>
                    )}
                  </Button>
                  <Button
                    size="md"
                    color="link"
                    type="button"
                    onClick={() => setDeregisterModalOpen(false)}
                    disabled={modalLoading}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            )}

          {regenKeySuccess ? (
            <ModalSuccess
              success={regenKeySuccess}
              message="You've successfully updated event access key."
            />
          ) : (
            currentUserInfo &&
            passVerified &&
            accessActive &&
            !isCancelled &&
            isPublished &&
            isRegEnabled &&
            !eventEnded && (
              <Modal isOpen={regenKeyModalOpen}>
                <BarLoader
                  loading={modalLoading}
                  cssOverride={{
                    marginLeft: "1.5%",
                    marginRight: "1.5%",
                    width: "97%",
                    backgroundColor: "#fff",
                    opacity: "1",
                  }}
                />
                <div className="modal-header">
                  <span className="modal-title" id="regenKeyModalLabel">
                    <strong>Update Access Key</strong>
                  </span>

                  <button
                    aria-label="Close"
                    className=" close"
                    type="button"
                    onClick={() => setRegenKeyModalOpen(false)}
                    disabled={modalLoading}
                  >
                    <span aria-hidden={true}>
                      <FontAwesomeIcon icon="fa-xmark" />
                    </span>
                  </button>
                </div>
                <ModalBody>
                  <span className="text-default">
                    <strong>QR Code</strong> is a dynamic hash code computed
                    from the <strong>Secret Access Key</strong>. Would you like
                    to regenerate the Access Key?
                  </span>
                </ModalBody>
                <ModalFooter>
                  <Button
                    size="md"
                    color="primary"
                    type="button"
                    onClick={() => {
                      regenKey();
                    }}
                    disabled={modalLoading}
                  >
                    Yes, update key
                    {modalLoading && (
                      <span className="ml-2">
                        <ScaleLoader
                          color="#eee"
                          loading={modalLoading}
                          height="10px"
                          cssOverride={{
                            display: "inline",
                          }}
                        />
                      </span>
                    )}
                  </Button>
                  <Button
                    size="md"
                    color="link"
                    type="button"
                    onClick={() => setRegenKeyModalOpen(false)}
                    disabled={modalLoading}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            )
          )}

          {reverifySuccess ? (
            <ModalSuccess
              success={reverifySuccess}
              message="You've successfully verified the access."
            />
          ) : (
            currentUserInfo &&
            !isCancelled &&
            isPublished &&
            isRegEnabled &&
            !eventEnded &&
            !spotsFull &&
            !accessBanned &&
            !maxAttemptsReached && (
              <Modal isOpen={reverifyModalOpen}>
                <BarLoader
                  loading={modalLoading}
                  cssOverride={{
                    marginLeft: "1.5%",
                    marginRight: "1.5%",
                    width: "97%",
                    backgroundColor: "#fff",
                    opacity: "1",
                  }}
                />
                <div className="modal-header">
                  <span className="modal-title" id="verifyModalLabel">
                    <strong>Verify Access</strong>
                  </span>

                  <button
                    aria-label="Close"
                    className=" close"
                    type="button"
                    onClick={() => setReverifyModalOpen(false)}
                    disabled={modalLoading}
                  >
                    <span aria-hidden={true}>
                      <FontAwesomeIcon icon="fa-xmark" />
                    </span>
                  </button>
                </div>

                <ModalBody>
                  <div className="mb-4">
                    <div>
                      <h5 className="mb-3">Check Eligibility</h5>
                      {isEventCreator ? (
                        <div className="mt-2">
                          <FontAwesomeIcon
                            icon="fa-circle-check"
                            className="text-success fa-lg"
                          />
                          <span className="ml-2">
                            Creator pass for event organizer.
                          </span>
                        </div>
                      ) : grantedByCreator ? (
                        <div className="mt-2">
                          <FontAwesomeIcon
                            icon="fa-circle-check"
                            className="text-success fa-lg"
                          />
                          <span className="ml-2">
                            Found a special access granted by the creator.
                          </span>
                        </div>
                      ) : isFree ? (
                        <div className="mt-2">
                          <FontAwesomeIcon
                            icon="fa-circle-check"
                            className="text-success fa-lg"
                          />
                          <span className="ml-2">
                            This event requires no pass to join.
                          </span>
                        </div>
                      ) : (
                        passContract && (
                          <>
                            <div className="mt-2">
                              <FontAwesomeIcon
                                icon="fa-circle-check"
                                className="text-success fa-lg"
                              />
                              <span className="ml-2">
                                This event requires the following pass.
                              </span>
                            </div>
                            <div className="mt-3 mb-4">
                              <Button
                                className="btn-sm"
                                type="button"
                                href={tokenUrl}
                                rel="external nofollow noopener"
                                target="_blank"
                              >
                                {blockchain === "eth" && (
                                  <img src={ethImg} className="icon-eth mr-2" />
                                )}
                                {blockchain === "matic" && (
                                  <img
                                    src={polygonImg}
                                    className="icon-polygon mr-2"
                                  />
                                )}
                                <>
                                  {truncateText(passName, 20)} {"( "}
                                  <small>{passType} </small>
                                  <span className="text-info">
                                    {truncateAddressShort(passContract)}
                                  </span>{" "}
                                  {")"}
                                  <FontAwesomeIcon
                                    icon="arrow-up-right-from-square"
                                    className="ml-1"
                                  />
                                </>
                              </Button>
                            </div>

                            <div className="my-3">
                              {userTotalPassHolding > 0 ? (
                                <FontAwesomeIcon
                                  icon="fa-circle-check"
                                  className="text-success fa-lg"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon="fa-circle-xmark"
                                  className="text-danger fa-lg"
                                />
                              )}
                              <span className="ml-2">
                                You are holding{" "}
                                {userTotalPassHolding > 1 ? (
                                  <>{userTotalPassHolding} passes</>
                                ) : (
                                  <>{userTotalPassHolding} pass</>
                                )}
                                .
                              </span>
                            </div>
                            {userTotalPassHolding > 0 &&
                              (requireUniquePassId ? (
                                <div className="my-3">
                                  {userTokenOption?.value ? (
                                    <FontAwesomeIcon
                                      icon="fa-circle-check"
                                      className="text-success fa-lg"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon="fa-circle-check"
                                      className="fa-lg"
                                    />
                                  )}
                                  <span className="ml-2">
                                    Choose your pass to register
                                  </span>
                                  <div className="ml-4">
                                    <small className="text-muted">
                                      Require unique wallet and Token ID to
                                      join.
                                    </small>
                                  </div>
                                  <div className="my-2 col-11">
                                    <Select
                                      className="realpass-selector"
                                      onChange={(e) => {
                                        setUserTokenOption(e);
                                        if (e) {
                                          setEligibleToJoin(true);
                                        } else {
                                          setEligibleToJoin(false);
                                        }
                                      }}
                                      isClearable={true}
                                      isSearchable={true}
                                      components={animatedComponents}
                                      options={userTokenOptions}
                                      value={userTokenOption}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <FontAwesomeIcon
                                    icon="fa-circle-check"
                                    className="text-success fa-lg"
                                  />
                                  <span className="ml-2">
                                    Each wallet and pass may have one entry.
                                  </span>
                                </>
                              ))}
                          </>
                        )
                      )}
                    </div>
                  </div>

                  {isPrivate && (
                    <div className="my-3">
                      {!inviteError ? (
                        <FontAwesomeIcon
                          icon="fa-circle-check"
                          className="text-success fa-lg"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon="fa-circle-xmark"
                          className="text-danger fa-lg"
                        />
                      )}
                      <span>
                        <span className="ml-2 required">
                          You need invite code for private event.
                        </span>
                      </span>
                      <div className="ml-3 my-2 col-11">
                        <InputGroup>
                          <InputGroupText className="input-group-left pr-2 text-muted">
                            <FontAwesomeIcon
                              icon="fa-handshake-angle"
                              className="mr-2"
                            />
                          </InputGroupText>

                          <Input
                            id="inviteCode"
                            className="input-group-mid-no-border pl-2"
                            value={inviteCode}
                            onChange={(e) => {
                              setInviteCode(e.target.value);
                            }}
                            onBlur={() => checkInviteCode(isPrivate)}
                            placeholder="Enter 6-digit invite code"
                            type="text"
                          />

                          <InputGroupText
                            className="input-group-right pr-2 text-muted"
                            style={{
                              borderLeft: "0px",
                            }}
                          >
                            {inviteCodeChecking ? (
                              <ScaleLoader
                                color="#eee"
                                loading={inviteCodeChecking}
                                height="10px"
                                cssOverride={{
                                  display: "inline",
                                }}
                              />
                            ) : (
                              isInviteCodeValid && (
                                <FontAwesomeIcon
                                  icon="fa-check"
                                  className="text-success"
                                />
                              )
                            )}
                          </InputGroupText>
                        </InputGroup>

                        {inviteError && (
                          <small className="text-danger">
                            <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                            {inviteError}
                          </small>
                        )}
                      </div>
                    </div>
                  )}

                  {isNSFW && (
                    <div className="mx-4 my-3">
                      <div>
                        <Label>
                          <Input
                            checked={confirmAge}
                            className="custom-control-input"
                            type="checkbox"
                            onChange={() => {
                              setConfirmAge(!confirmAge);
                            }}
                          />
                          <span className="custom-control-label">
                            <strong>Confirm Legal Age</strong>
                          </span>
                        </Label>
                      </div>
                      <small className="text-muted mt-2">
                        This event is NSFW. You need to confirm you're 18+ to
                        continue.
                      </small>
                    </div>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button
                    size="md"
                    color="primary"
                    type="button"
                    disabled={
                      (isNSFW && !confirmAge) ||
                      !eligibleToJoin ||
                      (isPrivate && !isInviteCodeValid) ||
                      inviteCodeChecking
                    }
                    onClick={() => {
                      reverifyAccess();
                    }}
                  >
                    Claim Access
                    {modalLoading && (
                      <span className="ml-2">
                        <ScaleLoader
                          color="#eee"
                          loading={modalLoading}
                          height="10px"
                          cssOverride={{
                            display: "inline",
                          }}
                        />
                      </span>
                    )}
                  </Button>
                  <Button
                    size="md"
                    color="link"
                    type="button"
                    onClick={() => {
                      setReverifyModalOpen(false);
                    }}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            )
          )}

          {currentUserInfo && passVerified && accessActive && !eventEnded && (
            <Modal
              toggle={() => setQRModalOpen(!qrModalOpen)}
              isOpen={qrModalOpen}
            >
              <BarLoader
                loading={modalLoading}
                cssOverride={{
                  marginLeft: "1.5%",
                  marginRight: "1.5%",
                  width: "97%",
                  backgroundColor: "#fff",
                  opacity: "1",
                }}
              />
              <div className="modal-header">
                <span className="modal-title" id="qrModalLabel">
                  <strong>My QR Code</strong>
                </span>

                <button
                  aria-label="Close"
                  className=" close"
                  type="button"
                  onClick={() => setQRModalOpen(false)}
                  disabled={modalLoading}
                >
                  <span aria-hidden={true}>
                    <FontAwesomeIcon icon="fa-xmark" />
                  </span>
                </button>
              </div>
              <ModalBody className="justify-content-center">
                <div className="text-center m-3">
                  <h1
                    className={
                      passVerified && accessActive
                        ? "text-success text-center"
                        : !passVerified || !accessActive
                        ? "text-danger text-center"
                        : "text-center"
                    }
                  >
                    <strong>
                      {passVerified && accessActive && accessCipherCode ? (
                        <>
                          VERIFIED
                          <FontAwesomeIcon
                            icon="fa-circle-check"
                            className="ml-1"
                          />
                        </>
                      ) : !passVerified || !accessActive ? (
                        <>
                          INVALID
                          <FontAwesomeIcon
                            icon="fa-circle-xmark"
                            className="ml-1"
                          />
                          <br />
                        </>
                      ) : (
                        <>
                          NOT VERIFIED
                          <FontAwesomeIcon
                            icon="fa-circle-arrow-down"
                            className="ml-1"
                          />
                        </>
                      )}
                    </strong>
                  </h1>
                </div>
                <div className="text-center my-3">
                  <QRCode
                    className={
                      passVerified && accessActive && accessHashCode && qrActive
                        ? ""
                        : "qr-overlay"
                    }
                    fgColor="#222"
                    bgColor="#fff"
                    size={280}
                    value={(qrActive && accessQRCode) || REACT_APP_HOST}
                  />
                </div>
                <div className="text-center m-3">
                  {passVerified && accessActive && (
                    <>
                      <span id="refreshQRButton">
                        <Button
                          className="btn-icon-only mr-2 mb-2 px-2"
                          outline
                          size="md"
                          color="primary"
                          type="button"
                          onClick={() => {
                            setRegenKeyModalOpen(true);
                            setQRModalOpen(false);
                          }}
                          disabled={
                            isCancelled ||
                            !isPublished ||
                            !isRegEnabled ||
                            eventEnded
                          }
                        >
                          <FontAwesomeIcon icon="fa-rotate" className="fa-lg" />
                        </Button>
                      </span>

                      {(isCancelled ||
                        !isPublished ||
                        !isRegEnabled ||
                        eventEnded) && (
                        <UncontrolledTooltip
                          placement="top"
                          target="refreshQRButton"
                          trigger="hover"
                        >
                          {isCancelled
                            ? "Event has been cancelled"
                            : !isPublished
                            ? "Event is not published"
                            : !isRegEnabled
                            ? "Access update is disabled"
                            : eventEnded && "Event has ended"}
                        </UncontrolledTooltip>
                      )}
                    </>
                  )}
                  <Badge pill color="primary" className="text-center m-2">
                    <h5 className="text-white text-center mx-3 my-0">
                      {passVerified && accessActive && accessCipherCode ? (
                        <span className="text-lowercase">
                          {qrActive && accessHashCode ? (
                            <>
                              <FontAwesomeIcon
                                icon="fa-lock-open"
                                className="mr-1"
                              />
                              {accessHashCode}
                            </>
                          ) : (
                            <>
                              <FontAwesomeIcon
                                icon="fa-lock"
                                className="mr-1"
                              />
                              <strong>{"*".repeat(7)}</strong>
                            </>
                          )}
                        </span>
                      ) : (
                        <span>
                          <FontAwesomeIcon icon="fa-lock" className="mr-1" />
                          Invalid
                        </span>
                      )}
                    </h5>
                  </Badge>
                  <Button
                    className="btn-icon-only ml-2 mb-2 px-2"
                    outline
                    size="md"
                    color="primary"
                    type="button"
                    onClick={() => setQRActive(!qrActive)}
                    disabled={
                      !passVerified ||
                      !accessActive ||
                      !accessCipherCode ||
                      eventEnded
                    }
                  >
                    {qrActive ? (
                      <>
                        <FontAwesomeIcon
                          icon="fa-eye-slash"
                          className="fa-lg"
                        />
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon="fa-eye" className="fa-lg" />
                      </>
                    )}
                  </Button>
                </div>
              </ModalBody>
            </Modal>
          )}

          {currentUserInfo && (
            <Modal
              toggle={() => setPassModalOpen(!passModalOpen)}
              isOpen={passModalOpen}
            >
              <BarLoader
                loading={modalLoading}
                cssOverride={{
                  marginLeft: "1.5%",
                  marginRight: "1.5%",
                  width: "97%",
                  backgroundColor: "#fff",
                  opacity: "1",
                }}
              />
              <div className="modal-header">
                <span className="modal-title" id="passModalLabel">
                  <strong>My Pass</strong>
                </span>

                <button
                  aria-label="Close"
                  className=" close"
                  type="button"
                  onClick={() => setPassModalOpen(false)}
                  disabled={modalLoading}
                >
                  <span aria-hidden={true}>
                    <FontAwesomeIcon icon="fa-xmark" />
                  </span>
                </button>
              </div>
              <ModalBody className="justify-content-center">
                <div className="m-3">
                  <Row>
                    <Col className="justify-content-center">
                      <Card
                        className="bg-primary justify-content-center"
                        style={{
                          border: "8px solid #2a2a2a",
                        }}
                      >
                        <div
                          className={
                            passVerified && accessActive
                              ? "bg-success"
                              : !passVerified || !accessActive
                              ? "bg-danger"
                              : "bg-primary"
                          }
                          style={{ borderRadius: "8px 8px 0 0" }}
                        >
                          <div className="text-white text-center mt-2 mx-2">
                            <h4 className="text-uppercase text-white">
                              <FontAwesomeIcon
                                icon="fa-ticket"
                                className="fa-lg mr-2"
                              />
                              <strong>
                                {passVerified &&
                                accessActive &&
                                accessCipherCode ? (
                                  <>
                                    VERIFIED
                                    <FontAwesomeIcon
                                      icon="fa-circle-check"
                                      className="ml-1"
                                    />
                                  </>
                                ) : !passVerified || !accessActive ? (
                                  <>
                                    INVALID
                                    <FontAwesomeIcon
                                      icon="fa-circle-xmark"
                                      className="ml-1"
                                    />
                                    <br />
                                  </>
                                ) : (
                                  <>
                                    VERIFIED
                                    <FontAwesomeIcon
                                      icon="fa-circle-arrow-down"
                                      className="ml-1"
                                    />
                                  </>
                                )}
                              </strong>
                            </h4>
                          </div>
                        </div>
                        <CardBody className="text-gradient">
                          <div className="m-1">
                            {isEventCreator ? (
                              <h3 className="display-3 text-center text-white">
                                CREATOR PASS
                              </h3>
                            ) : grantedByCreator ? (
                              <h3 className="display-3 text-center text-white">
                                GRANTED PASS
                              </h3>
                            ) : isFree ? (
                              <h3 className="display-3 text-center text-white">
                                FREE PASS
                              </h3>
                            ) : (
                              passContract && (
                                <>
                                  <img
                                    alt="..."
                                    style={{ width: "100%" }}
                                    className="mt-2 mb-2 rounded"
                                    src={passImage || defaultBoxGrayImg}
                                  ></img>
                                  <h5 className="text-white">
                                    <strong>
                                      {truncateText(passName, 15)}
                                    </strong>
                                    <strong className="float-right">
                                      {passTokenId ? (
                                        <>
                                          {" #"}
                                          {passTokenId}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </strong>
                                  </h5>
                                  <h4>
                                    <a
                                      href={tokenUrl}
                                      rel="external nofollow noopener"
                                      target="_blank"
                                    >
                                      {blockchain === "eth" && (
                                        <img
                                          src={ethWhiteImg}
                                          className="icon-eth mr-2"
                                        />
                                      )}
                                      {blockchain === "matic" && (
                                        <img
                                          src={polygonWhiteImg}
                                          className="icon-polygon mr-2"
                                        />
                                      )}
                                      <span className="text-white">
                                        <small>
                                          {passType && <>{passType}: </>}
                                        </small>
                                        <span className="text-info">
                                          {truncateAddressShort(passContract)}
                                        </span>
                                        <FontAwesomeIcon
                                          icon="arrow-up-right-from-square"
                                          className="ml-1 text-white"
                                        />
                                      </span>
                                    </a>
                                  </h4>

                                  {passContract && (
                                    <div>
                                      <small className="text-warning text-capitalize">
                                        * Must hold during the event
                                      </small>
                                    </div>
                                  )}
                                </>
                              )
                            )}

                            {passError && (
                              <div>
                                <span className="text-danger">
                                  <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                  {passError}
                                </span>
                              </div>
                            )}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </ModalBody>
            </Modal>
          )}
        </Container>
      </section>
      {isCancelled && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            color: "white",
            zIndex: "999",
          }}
        ></div>
      )}
    </>
  );
}

export default MyAccess;
