import React, { useState, useContext, useEffect } from "react";

import { AuthContext } from "Auth.js";

// reactstrap components
import {
  Alert,
  ButtonGroup,
  Button,
  Collapse,
  Card,
  Container,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  CardImg,
  CardBody,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Table,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import GooglePlaceAutocomplete from "react-google-autocomplete";

import { Form, Field } from "react-final-form";
import Select from "react-select";

import {
  notify,
  genImageName,
  nDaysAfterTZ,
  handleReactDatetimeChange,
  getClassNameReactDatetimeDays,
  hashCode,
  truncateSimpleUrl,
} from "utils/Utils.js";

import { firebaseUpload, creatorUpdateAccessSettings } from "utils/Api.js";

import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.compat.css";

import Compressor from "compressorjs";

import QRCode from "react-qr-code";

import Avatar from "boring-avatars";

import Loader from "components/Loaders/Loader.js";
import LoaderDots from "components/Loaders/LoaderDots.js";
import LoaderSection from "components/Loaders/LoaderSection.js";
import LoaderModal from "components/Loaders/LoaderModal.js";

import ScaleLoader from "react-spinners/ScaleLoader";
import BarLoader from "react-spinners/BarLoader";

import ModalSuccess from "components/Modals/ModalSuccess.js";

import MultiPassesCollapse from "components/Lists/MultiPassesCollapse.js";

import ReactDatetime from "react-datetime";
import Moment from "react-moment";
import moment from "moment-timezone";

import TimezoneSelect, { allTimezones } from "react-timezone-select";

import MultiSessionsEditable from "components/Lists/MultiSessionsEditable.js";

const {
  defaultFormImg,
  defaultFormBanner,
  DefaultLocation,
  ChainOptions,
  EventCategoryOptions,
  EventCategorySelectorOptions,
  CreatorRoleTypes,
  DisableMaxUsageOption,
  InviteMaxUsageOptions,
  InviteExpireOptions,
  ChainSelectorOptions,
  USDateOption,
  UserRoleTypes,
  UserRoleStatus,
  VerifiedUserTypes,
  VerifiedUserStatus,
  QuillEditorModules,
  QuillEditorFormats,
} = require("utils/Variables.js");

const { REACT_APP_HOST } = process.env;

export default function EventRegistration(props) {
  let {
    event,
    eventId,
    updateEvent,
    updateEventName,
    updateIsPublished,
    updateIsPrivate,
    updateIsVirtual,
    isEventActiveCreator,
  } = props;

  const {
    currentUser,
    currentUserId,
    currentAuthToken,
    currentUserInfo,
    setCurrentUserInfo,
  } = useContext(AuthContext);

  const hasAdminRole = currentUserInfo?.hasAdminRole;
  const hasCreatorRole = currentUserInfo?.hasCreatorRole;
  const isVerifiedUser = currentUserInfo?.isVerifiedUser;

  const [dataSaving, setDataSaving] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  const [isVirtual, setIsVirtual] = useState(!!event?.isVirtual);
  const [virtualLocation, setVirtualLocation] = useState(
    isVirtual && event?.location ? event?.location : DefaultLocation
  );
  const [irlLocation, setIrlLocation] = useState(
    !isVirtual && event?.location ? event?.location : DefaultLocation
  );
  const [isLocationRestricted, setIsLocationRestricted] = useState(
    !!event?.isLocationRestricted
  );

  const [maxUsers, setMaxUsers] = useState(
    event?.maxParticipants < 0 ? "∞" : event?.maxParticipants
  );
  const [unlimitedUsers, setUnlimitedUsers] = useState(
    event?.maxParticipants < 0
  );

  const [registerEnabled, setRegisterEnabled] = useState(
    event?.isRegisterEnabled
  );
  const [isPrivate, setIsPrivate] = useState(event?.isPrivate);

  const [checkinEnabled, setCheckinEnabled] = useState(event?.isCheckinEnabled);

  const [isSecretEnabled, setIsSecretEnabled] = useState(
    !!event?.isSecretEnabled
  );
  const [secretName, setSecretName] = useState(event?.secretName || "");
  const [secretCode, setSecretCode] = useState(event?.secretCode || "");
  const [secretModalOpen, setSecretModalOpen] = useState(false);

  // const [instantAccessEnabled, setInstantAccessEnabled] = useState(
  //   event?.isInstantAccessEnabled
  // );
  // const [qrActive, setQRActive] = useState();
  // const [eventCipherCode, setEventCipherCode] = useState();
  // const [eventQRCode, setEventQRCode] = useState("");
  // const [eventHashCode, setEventHashCode] = useState();
  // const [regenKeySuccess, setRegenKeySuccess] = useState(false);
  // const [regenKeyModalOpen, setRegenKeyModalOpen] = useState(false);
  // const [regenKeyLoading, setRegenKeyLoading] = useState(false);

  const saveEventAccess = async () => {
    try {
      setDataSaving(true);

      const eventData = {
        isRegisterEnabled: registerEnabled,
        isCheckinEnabled: checkinEnabled,
        // isInstantAccessEnabled: instantAccessEnabled,
        isPrivate,
        maxParticipants: maxUsers === "∞" ? -1 : Number(maxUsers),
        isSecretEnabled,
        secretName,
        secretCode,
      };

      const res = await creatorUpdateAccessSettings(
        currentAuthToken,
        eventId,
        eventData
      );

      if (res?.success) {
        updateEvent({
          ...event,
          isRegisterEnabled: eventData.isRegisterEnabled,
          isCheckinEnabled: eventData.isCheckinEnabled,
          isPrivate: eventData.isPrivate,
          maxParticipants: eventData.maxParticipants,
          isSecretEnabled: eventData.isSecretEnabled,
          secretName: eventData.secretName,
          secretCode: eventData.secretCode,
        });
        notify("Successfully updated event.", "success");
      } else {
        notify("Unable to update event.", "error");
      }
    } catch (err) {
      console.error(err.message);
      notify("Unable to update event.", "error");
    } finally {
      setDataSaving(false);
      setFormChanged(false);
    }
  };

  const toggleUnlimitedUsers = () => {
    const unlimited = !unlimitedUsers;
    setUnlimitedUsers(unlimited);
    unlimited ? setMaxUsers("∞") : setMaxUsers(0);
  };

  const toggleSecret = () => {
    const secretFlag = !isSecretEnabled;

    setIsSecretEnabled(secretFlag);
    setSecretName("");
    setSecretCode("");
  };

  // const regenKey = () => {
  //   try {
  //     setRegenKeyLoading(true);

  //     // const cipherCode = await creatorUpdateEventKey(currentAuthToken, eventId);;
  //     // setEventCipherCode(cipherCode);
  //     // genQR(cipherCode);

  //     //   setRegenKeyModalOpen(false);
  //     //   setRegenKeySuccess(true);
  //   } catch (err) {
  //     console.error(err.message);
  //     notify("Unable to regenerate key.", "error");
  //   } finally {
  //     //   setRegenKeyLoading(false);
  //   }
  // };

  // const genQR = (key) => {
  //   if (key) {
  //     const salt = Math.round(new Date().getTime() / 500);
  //     const seed = salt + key;

  //     const hash = hashCode(seed);

  //     const qrCode = `${REACT_APP_HOST}/myaccess/verify?ehash=${hash}`;

  //     setEventQRCode(qrCode);
  //     setEventHashCode(hash);
  //   }
  // };

  // useEffect(() => {
  //   if (eventCipherCode && qrActive && instantAccessEnabled) {
  //     const qrTimer = setTimeout(() => {
  //       genQR(eventCipherCode);
  //     }, 500);

  //     return () => {
  //       clearTimeout(qrTimer);
  //     };
  //   }
  // }, [eventHashCode, qrActive]);

  useEffect(() => {}, []);

  return (
    <>
      {(hasAdminRole || isEventActiveCreator) && (
        <>
          <Form
            onSubmit={() => {}}
            validate={(values) => {
              const errors = {};

              if (!maxUsers) {
                errors.maxUsers = "Max members is required.";
              }

              if (
                !unlimitedUsers &&
                (isNaN(Number(maxUsers)) || Number(maxUsers) < 0)
              ) {
                errors.maxUsers =
                  "Max members must be greater than or equal to 0.";
              }

              // No need to check this, we can validate capacity on event registration
              // if (
              //   !unlimitedUsers &&
              //   Number(maxUsers) < event?.totalRegistered
              // ) {
              //   errors.maxUsers = `Max members is less than ${event?.totalRegistered} of total members.`;
              // }

              if (secretName?.length > 40 || secretCode?.length > 40) {
                errors.secret = "Secret code must not exceed 40 characters.";
              }

              return errors;
            }}
            render={({
              handleSubmit,
              values,
              submitting,
              validating,
              valid,
            }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <h5 className="text-default">
                    <strong>Registration</strong>
                  </h5>
                  <div className="m-2">
                    <Row>
                      <Col>
                        <div className="mt-4 mb-4">
                          <Row>
                            <Col className="col-7">
                              <Label>
                                <div>
                                  <strong>Enable Registration</strong>
                                </div>
                                <small className="text-muted">
                                  Join on the event page
                                </small>
                              </Label>
                            </Col>
                            <Col>
                              <Label className="custom-toggle float-right">
                                <Input
                                  checked={registerEnabled}
                                  type="checkbox"
                                  onChange={() => {
                                    setRegisterEnabled(!registerEnabled);
                                    setFormChanged(true);
                                  }}
                                />
                                <span className="custom-toggle-slider rounded-circle"></span>
                              </Label>
                            </Col>
                          </Row>

                          <Alert color="secondary" className="text-default">
                            <FontAwesomeIcon
                              icon="fa-circle-info"
                              className="mr-2"
                            />
                            <span>
                              {registerEnabled
                                ? "Allow people to buy passes and register on the event page."
                                : "Registration is disabled. Turn it on to allow registration."}
                            </span>
                          </Alert>
                        </div>

                        <div className="mt-4 mb-4">
                          <FormGroup>
                            <Field name="maxUsers">
                              {({ input, meta }) => (
                                <>
                                  <Row>
                                    <Col className="col-6">
                                      <Label for="maxUsers">
                                        <div>
                                          <span className="required">
                                            <strong>Capacity</strong>
                                          </span>
                                        </div>
                                        <small className="text-muted">
                                          Number of members can join
                                        </small>
                                      </Label>
                                    </Col>
                                    <Col>
                                      <Label className="custom-toggle float-right">
                                        <Input
                                          {...input}
                                          checked={unlimitedUsers}
                                          type="checkbox"
                                          onChange={() => {
                                            toggleUnlimitedUsers();
                                            setFormChanged(true);
                                          }}
                                        />
                                        <span className="custom-toggle-slider rounded-circle"></span>
                                      </Label>
                                      <small className="text-default float-right mr-2 mt-1">
                                        No Limit
                                      </small>
                                    </Col>
                                  </Row>
                                  <InputGroup>
                                    <InputGroupText className="input-group-left pr-2">
                                      <FontAwesomeIcon
                                        icon="fa-users"
                                        className="mr-2"
                                      />
                                      Max
                                    </InputGroupText>
                                    <Input
                                      {...input}
                                      id="maxUsers"
                                      value={maxUsers}
                                      className="pl-2"
                                      onChange={(e) => {
                                        console.log(e.target.value);
                                        setMaxUsers(e.target.value);
                                        setFormChanged(true);
                                      }}
                                      placeholder={
                                        !unlimitedUsers ? maxUsers : "∞"
                                      }
                                      disabled={unlimitedUsers}
                                      type="number"
                                      required
                                    />
                                  </InputGroup>

                                  {meta.error && meta.touched && (
                                    <small className="text-danger">
                                      <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                      {meta.error}
                                    </small>
                                  )}
                                </>
                              )}
                            </Field>
                          </FormGroup>
                        </div>

                        <div className="mt-4 mb-4">
                          <Row>
                            <Col className="col-7">
                              <Label for="privateCheck">
                                <div>
                                  <strong>Private Access</strong>
                                </div>
                                <small className="text-muted">
                                  Exclusive access for private event
                                </small>
                              </Label>
                            </Col>
                            <Col>
                              <Label className="custom-toggle float-right">
                                <Input
                                  checked={isPrivate}
                                  type="checkbox"
                                  onChange={() => {
                                    setIsPrivate(!isPrivate);
                                    setFormChanged(true);
                                  }}
                                />
                                <span className="custom-toggle-slider rounded-circle"></span>
                              </Label>
                            </Col>
                          </Row>

                          <Alert color="secondary" className="text-default">
                            <FontAwesomeIcon
                              icon="fa-circle-info"
                              className="mr-2"
                            />
                            <span>
                              {isPrivate ? (
                                <>
                                  This is a private event. People need an{" "}
                                  <strong>Invite Code</strong> to register.
                                  {/* or{" "}
                                  <strong>Instant Access</strong>. */}
                                </>
                              ) : (
                                <>
                                  This is a public event. People can join by
                                  registering on the event page.
                                </>
                              )}
                            </span>
                          </Alert>
                        </div>

                        <div className="mt-4 mb-4">
                          <FormGroup>
                            <Field name="secret">
                              {({ input, meta }) => (
                                <>
                                  <Row>
                                    <Col className="col-7">
                                      <Label for="secret">
                                        <div>
                                          <strong>Event Secret</strong>{" "}
                                          <small>
                                            (
                                            <a
                                              id="whatsSecretCode"
                                              className="text-info"
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              onClick={(e) => {
                                                setSecretModalOpen(true);
                                              }}
                                            >
                                              <u>Learn more</u>
                                            </a>
                                            )
                                          </small>
                                        </div>
                                        <small className="text-muted">
                                          Secret code for protected content
                                        </small>
                                      </Label>
                                    </Col>
                                    <Col>
                                      <Label className="custom-toggle float-right">
                                        <Input
                                          {...input}
                                          checked={isSecretEnabled}
                                          type="checkbox"
                                          onChange={() => {
                                            toggleSecret();
                                            setFormChanged(true);
                                          }}
                                        />
                                        <span className="custom-toggle-slider rounded-circle"></span>
                                      </Label>
                                    </Col>
                                  </Row>

                                  <Alert
                                    color="secondary"
                                    className="text-default"
                                  >
                                    <FontAwesomeIcon
                                      icon="fa-circle-info"
                                      className="mr-2"
                                    />
                                    <span>
                                      Members can see this code after
                                      registration.
                                    </span>

                                    <Collapse
                                      isOpen={isSecretEnabled}
                                      className="my-2"
                                    >
                                      <Alert color="white" className="mt-1 p-3">
                                        {/* {isVirtual ? (
                                          <>
                                            <span className="text-success">
                                              <strong>
                                                <FontAwesomeIcon
                                                  icon="fa-shield"
                                                  className="mr-1"
                                                />
                                                Protected URL
                                              </strong>
                                            </span>{" "}
                                            <Badge
                                              pill
                                              color="dark"
                                              className="text-white px-2 ml-2"
                                            >
                                              <span
                                                style={{
                                                  fontSize: "12px",
                                                  textTransform: "none",
                                                }}
                                              >
                                                <strong>
                                                  {virtualLocation?.name
                                                    ?.length > 0
                                                    ? truncateSimpleUrl(
                                                        virtualLocation?.name,
                                                        16
                                                      )
                                                    : "yoursite.com/"}
                                                  {`?${secretName || "promo"}=${
                                                    secretCode || "freebie"
                                                  }`}
                                                </strong>
                                              </span>
                                            </Badge>
                                          </>
                                        ) : ( */}
                                        <>
                                          <span className="text-success">
                                            <strong>
                                              <FontAwesomeIcon
                                                icon="fa-shield"
                                                className="mr-1"
                                              />
                                              {secretName || "Passcode"}:
                                            </strong>
                                          </span>{" "}
                                          <Badge
                                            pill
                                            color="dark"
                                            className="text-white px-2 ml-2"
                                          >
                                            <strong
                                              style={{
                                                fontSize: "12px",
                                                textTransform: "none",
                                              }}
                                            >
                                              {secretCode || "******"}
                                            </strong>
                                          </Badge>
                                        </>
                                        {/* )} */}
                                      </Alert>

                                      <InputGroup>
                                        <>
                                          <InputGroupText className="input-group-left pr-2 text-muted">
                                            <FontAwesomeIcon icon="fa-vault" />{" "}
                                          </InputGroupText>
                                          <Input
                                            {...input}
                                            id="name"
                                            className="input-group-mid pl-2"
                                            value={secretName}
                                            onChange={(e) =>
                                              setSecretName(e.target.value)
                                            }
                                            placeholder={secretName || "Name"}
                                            type="text"
                                            required
                                          />
                                          <InputGroupText className="input-group-mid-no-border text-muted">
                                            <FontAwesomeIcon icon="fa-key" />{" "}
                                          </InputGroupText>
                                          <Input
                                            {...input}
                                            id="secretCode"
                                            className="input-group-right pl-2"
                                            value={secretCode}
                                            onChange={(e) =>
                                              setSecretCode(e.target.value)
                                            }
                                            placeholder={secretCode || "Secret"}
                                            type="text"
                                            required
                                          />
                                        </>
                                      </InputGroup>

                                      {meta.error && meta.touched && (
                                        <small className="text-danger">
                                          <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                          {meta.error}
                                        </small>
                                      )}
                                    </Collapse>
                                  </Alert>
                                </>
                              )}
                            </Field>
                          </FormGroup>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <hr />

                  <h5 className="text-default">
                    <strong>Checkin</strong>
                  </h5>
                  <div className="m-2">
                    <Row>
                      <Col>
                        <div className="mt-4 mb-4">
                          <Row>
                            <Col className="col-7">
                              <Label>
                                <div>
                                  <strong>Enable Checkin</strong>
                                </div>
                                <small className="text-muted">
                                  Existing members checkin at event
                                </small>
                              </Label>
                            </Col>
                            <Col>
                              <Label className="custom-toggle float-right">
                                <Input
                                  checked={checkinEnabled}
                                  type="checkbox"
                                  onChange={() => {
                                    setCheckinEnabled(!checkinEnabled);
                                    setFormChanged(true);
                                  }}
                                />
                                <span className="custom-toggle-slider rounded-circle"></span>
                              </Label>
                            </Col>
                          </Row>

                          <Alert color="secondary" className="text-default">
                            <Row>
                              <Col xl="8" lg="8" md="12" sm="12" xs="12">
                                <div className="mb-2">
                                  <FontAwesomeIcon
                                    icon="fa-circle-info"
                                    className="mr-2"
                                  />
                                  <span>
                                    {checkinEnabled ? (
                                      <>
                                        Check members in by scanning their
                                        active <strong>Access Code</strong>.
                                      </>
                                    ) : (
                                      <>
                                        Event checkin is disabled until you're
                                        ready.
                                      </>
                                    )}
                                  </span>
                                </div>
                              </Col>
                              <Col>
                                <Button
                                  size="sm"
                                  color="primary"
                                  href={`/event/checkin/${eventId}`}
                                  target="_blank"
                                  className="float-right"
                                >
                                  Start Checkin
                                </Button>
                              </Col>
                            </Row>
                          </Alert>
                        </div>

                        {/* <div className="mt-4 mb-4">
                          <Row>
                            <Col className="col-7">
                              <Label>
                                <div>
                                  <strong>Instant Access</strong>
                                </div>
                                <small className="text-muted">
                                  Quick QR scan with web3 wallet
                                </small>
                              </Label>
                            </Col>
                            <Col>
                              <Label className="custom-toggle float-right">
                                <Input
                                  checked={instantAccessEnabled}
                                  type="checkbox"
                                  onChange={() => {
                                    setInstantAccessEnabled(
                                      !instantAccessEnabled
                                    );
                                    setFormChanged(true);
                                  }}
                                />
                                <span className="custom-toggle-slider rounded-circle"></span>
                              </Label>
                            </Col>
                          </Row>

                          <Alert color="secondary" className="text-default">
                            <Row>
                              <Col xl="9" lg="9" md="12" sm="12" xs="12">
                                <div className="mb-2">
                                  <FontAwesomeIcon
                                    icon="fa-circle-info"
                                    className="mr-2"
                                  />
                                  <span>
                                    {instantAccessEnabled ? (
                                      <>
                                        People can scan{" "}
                                        <strong>Event QR Code</strong> get
                                        access without registering.
                                      </>
                                    ) : (
                                      <>
                                        Instant access is disabled. People must
                                        register before access.
                                      </>
                                    )}
                                  </span>
                                </div>
                              </Col>
                              <Col>
                                <Button
                                  size="sm"
                                  color="primary"
                                  href={`/event/instant/${eventId}`}
                                  target="_blank"
                                  className="float-right"
                                >
                                  Instant Access
                                </Button>
                              </Col>
                            </Row>

                            <Collapse
                              isOpen={instantAccessEnabled}
                              className="my-2"
                            >
                              <hr />
                              <Label>
                                <div>
                                  <strong>Event QR Code</strong>
                                </div>
                                <small className="text-default">
                                  QR Code is a dynamic hash code computed from
                                  the <strong>Secret Event Key</strong>
                                </small>
                              </Label>

                              <Row className="my-2">
                                <Col className="text-center">
                                  <div className="mb-2">
                                    <QRCode
                                      className={
                                        !instantAccessEnabled || !eventQRCode
                                          ? "qr-overlay"
                                          : ""
                                      }
                                      size={150}
                                      value={eventQRCode}
                                    />
                                  </div>
                                  <span className="mr-1">
                                    <Button
                                      className="btn-icon-only"
                                      outline
                                      color="dark"
                                      size="sm"
                                      type="button"
                                      onClick={() => {
                                        setRegenKeyModalOpen(true);
                                        setFormChanged(true);
                                      }}
                                    >
                                      <FontAwesomeIcon icon="fa-rotate" />
                                    </Button>
                                  </span>
                                  <Badge
                                    pill
                                    color="primary"
                                    className="text-white p-2 mx-2"
                                  >
                                    {qrActive && eventHashCode ? (
                                      <>
                                        <FontAwesomeIcon
                                          icon="fa-lock-open"
                                          className="mr-1"
                                        />
                                        {eventHashCode}
                                      </>
                                    ) : (
                                      <>
                                        <FontAwesomeIcon
                                          icon="fa-lock"
                                          className="mr-1"
                                        />
                                        <strong>{"*".repeat(7)}</strong>
                                      </>
                                    )}
                                  </Badge>
                                  <Button
                                    className="btn-icon-only ml-1"
                                    outline
                                    color="dark"
                                    size="sm"
                                    type="button"
                                    onClick={() => setQRActive(!qrActive)}
                                    disabled={!eventHashCode}
                                  >
                                    {qrActive ? (
                                      <>
                                        <FontAwesomeIcon
                                          icon="fa-eye-slash"
                                          className="fa-lg"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <FontAwesomeIcon
                                          icon="fa-eye"
                                          className="fa-lg"
                                        />
                                      </>
                                    )}
                                  </Button>
                                </Col>
                              </Row>
                            </Collapse>
                          </Alert>
                        </div> */}
                      </Col>
                    </Row>
                  </div>

                  <div className="mt-5 mb-3">
                    <Button
                      className="btn btn-dark mt-4 btn-block"
                      color="dark"
                      type="button"
                      onClick={saveEventAccess}
                      disabled={!valid || dataSaving || !formChanged}
                    >
                      Update Access
                      {dataSaving && (
                        <span className="ml-2">
                          <ScaleLoader
                            color="#eee"
                            loading={dataSaving}
                            height="10px"
                            cssOverride={{
                              display: "inline",
                            }}
                          />
                        </span>
                      )}
                    </Button>
                  </div>
                </form>
              </>
            )}
          />
          <Modal
            toggle={() => setSecretModalOpen(!secretModalOpen)}
            isOpen={secretModalOpen}
          >
            <div className="modal-header">
              <span className="modal-title" id="secretModalLabel">
                <strong>What is Secret Code?</strong>
              </span>

              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => setSecretModalOpen(false)}
              >
                <span aria-hidden={true}>
                  <FontAwesomeIcon icon="fa-xmark" />
                </span>
              </button>
            </div>
            <ModalBody>
              <p className="text-default">
                Secret Code is the additional code you provide to members to
                access protected content, digital assets, and exclusive offers,
                eg. a <strong>Promo Code</strong> for <strong>Shopify</strong>{" "}
                store, <strong>Meeting Code</strong> on <strong>Zoom</strong> ,
                or a passcode to enter the private venue. Secret Code is
                protected and only revealed to eligible members during the
                event.
              </p>
            </ModalBody>
          </Modal>

          {/* {regenKeySuccess ? (
            <ModalSuccess
              success={regenKeySuccess}
              message="You've successfully updated the secret event key."
            />
          ) : (
            <Modal isOpen={regenKeyModalOpen}>
              <BarLoader
                loading={regenKeyLoading}
                cssOverride={{
                  marginLeft: "1.5%",
                  marginRight: "1.5%",
                  width: "97%",
                  backgroundColor: "#fff",
                  opacity: "1",
                }}
              />
              <div className="modal-header">
                <span className="modal-title" id="regenKeyModalLabel">
                  <strong>Update Event Key</strong>
                </span>

                <button
                  aria-label="Close"
                  className=" close"
                  type="button"
                  onClick={() => setRegenKeyModalOpen(false)}
                  disabled={regenKeyLoading}
                >
                  <span aria-hidden={true}>
                    <FontAwesomeIcon icon="fa-xmark" />
                  </span>
                </button>
              </div>
              <ModalBody className="text-default">
                <div>
                  Would you like to regenerate the{" "}
                  <strong>Secret Event Key</strong> to prevent farming bots?
                </div>
                <div className=" mt-2"></div>
              </ModalBody>
              <ModalFooter>
                <Button
                  size="md"
                  color="primary"
                  onClick={regenKey}
                  disabled={regenKeyLoading}
                >
                  Yes, Regenerate
                  {regenKeyLoading && (
                    <span className="ml-1">
                      <ScaleLoader
                        color="#eee"
                        loading={regenKeyLoading}
                        height="10px"
                        cssOverride={{
                          display: "inline",
                        }}
                      />
                    </span>
                  )}
                </Button>
                <Button
                  size="md"
                  color="link"
                  type="button"
                  onClick={() => setRegenKeyModalOpen(false)}
                  disabled={regenKeyLoading}
                >
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          )} */}
        </>
      )}
    </>
  );
}
