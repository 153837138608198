import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Alert,
  ButtonGroup,
  Button,
  Collapse,
  Card,
  Container,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  CardImg,
  CardBody,
  CardTitle,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Table,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  removeProtocolStr,
  getTimeBetween,
  getFormattedTimeBetween,
} from "utils/Utils.js";

import { DefaultSocialLinkSize } from "utils/Variables.js";

import { SocialLinks } from "social-links";
const socialLinks = new SocialLinks();

export default function SocialLinksEditable(props) {
  let { links, updateLinks, updateFormChanged } = props;

  const [linksInput, setLinksInput] = useState([{ url: "", type: "web" }]);

  const addLinks = () => {
    setLinksInput([...linksInput, { url: "", type: "web" }]);
  };

  const removeLink = (index) => {
    const newLinks = [...linksInput];
    newLinks.splice(index, 1);
    setLinksInput(newLinks);
  };

  const changeLinks = (index, value) => {
    const newLinks = [...linksInput];
    const urlStr = value?.trim().toLowerCase();

    const type = urlStr.startsWith("discord")
      ? "discord"
      : socialLinks.detectProfile(value);

    newLinks[index] = { url: value?.trim(), type: type };

    setLinksInput(newLinks);

    if (value.length > 100) {
      newLinks[index].error = "Link must not exceed 100 characters.";
    }
  };

  useEffect(() => {
    const validLinks = linksInput.filter((item) => {
      return item.url?.trim().length > 0;
    });

    const linkArr = validLinks.map((item, index) => {
      return item.url;
    });

    updateLinks(linkArr);
  }, [linksInput]);

  useEffect(() => {
    const linkList = links?.map((item, index) => {
      const urlStr = item?.trim().toLowerCase();

      return {
        url: item,
        type: urlStr.startsWith("discord")
          ? "discord"
          : socialLinks.detectProfile(item),
      };
    });

    setLinksInput(linkList?.length > 0 ? linkList : [{ url: "", type: "web" }]);
  }, []);

  return (
    <>
      {linksInput?.map((item, index) => {
        return (
          <div key={index}>
            <Row className="my-1">
              <Col className="col-10">
                <InputGroup>
                  <InputGroupText className="pl-2 text-muted input-group-left">
                    {item.type === "twitter" ? (
                      <FontAwesomeIcon icon="fa-brands fa-twitter" />
                    ) : item.type === "youtube" ? (
                      <FontAwesomeIcon icon="fa-brands fa-youtube" />
                    ) : item.type === "github" ? (
                      <FontAwesomeIcon icon="fa-brands fa-github" />
                    ) : item.type === "linkedin" ? (
                      <FontAwesomeIcon icon="fa-brands fa-linkedin" />
                    ) : item.type === "medium" ? (
                      <FontAwesomeIcon icon="fa-brands fa-medium" />
                    ) : item.type === "instagram" ? (
                      <FontAwesomeIcon icon="fa-brands fa-instagram" />
                    ) : item.type === "telegram" ? (
                      <FontAwesomeIcon icon="fa-brands fa-telegram" />
                    ) : item.type === "discord" ? (
                      <FontAwesomeIcon icon="fa-brands fa-discord" />
                    ) : item.type === "spotify" ? (
                      <FontAwesomeIcon icon="fa-brands fa-spotify" />
                    ) : item.type === "soundcloud" ? (
                      <FontAwesomeIcon icon="fa-brands fa-soundcloud" />
                    ) : item.type === "pinterest" ? (
                      <FontAwesomeIcon icon="fa-brands fa-pinterest" />
                    ) : item.type === "tiktok" ? (
                      <FontAwesomeIcon icon="fa-brands fa-tiktok" />
                    ) : item.type === "twitch" ? (
                      <FontAwesomeIcon icon="fa-brands fa-twitch" />
                    ) : item.type === "dribbble" ? (
                      <FontAwesomeIcon icon="fa-brands fa-dribbble" />
                    ) : item.type === "facebook" ? (
                      <FontAwesomeIcon icon="fa-brands fa-facebook" />
                    ) : item.type === "patreon" ? (
                      <FontAwesomeIcon icon="fa-brands fa-patreon" />
                    ) : (
                      <FontAwesomeIcon icon={`fa-globe`} />
                    )}
                  </InputGroupText>

                  <Input
                    id="website"
                    className="pl-2 input-group-right"
                    value={item.url}
                    onChange={(e) => {
                      changeLinks(index, removeProtocolStr(e.target.value));
                      updateFormChanged(true);
                    }}
                    placeholder={item.url || "yoursite.xyz"}
                    type="text"
                  />
                </InputGroup>
              </Col>
              {index === 0 ? (
                <Button
                  className="text-info mt-2"
                  color="link"
                  size="sm"
                  disabled={linksInput.length >= DefaultSocialLinkSize}
                  onClick={() => {
                    addLinks();
                    updateFormChanged(true);
                  }}
                >
                  <FontAwesomeIcon icon="fa-square-plus" className="fa-lg" />
                </Button>
              ) : (
                <Button
                  color="link"
                  size="sm"
                  className="text-gray btn-icon-only mt-2 ml-0"
                  onClick={() => {
                    removeLink(index);
                    updateFormChanged(true);
                  }}
                >
                  <FontAwesomeIcon icon="fa-trash-can" className="fa-lg" />
                </Button>
              )}
            </Row>

            {item.error && (
              <small className="text-danger">
                <FontAwesomeIcon icon="fa-triangle-exclamation" /> {item.error}
              </small>
            )}
          </div>
        );
      })}
    </>
  );
}
