// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import {
  getAuth,
  updateProfile,
  signInWithCustomToken,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  onAuthStateChanged,
  linkWithCredential,
  EmailAuthProvider,
  reauthenticateWithPopup,
  linkWithPopup,
  unlink,
  updateEmail,
  sendEmailVerification,
  signOut,
} from "firebase/auth";

import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";

import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  list,
} from "firebase/storage";

import { getAnalytics, logEvent } from "firebase/analytics";

let app;

export default function FirebaseInit() {
  const {
    REACT_APP_FBASE_API_KEY,
    REACT_APP_FBASE_AUTH_DOMAIN,
    REACT_APP_FBASE_PROJECT_ID,
    REACT_APP_FBASE_STORAGE_BUCKET,
    REACT_APP_FBASE_MESSAGING_SENDER_ID,
    REACT_APP_FBASE_APP_ID,
    REACT_APP_FBASE_MEASUREMENT_ID,
  } = process.env;

  // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: REACT_APP_FBASE_API_KEY,
    authDomain: REACT_APP_FBASE_AUTH_DOMAIN,
    projectId: REACT_APP_FBASE_PROJECT_ID,
    storageBucket: REACT_APP_FBASE_STORAGE_BUCKET,
    messagingSenderId: REACT_APP_FBASE_MESSAGING_SENDER_ID,
    appId: REACT_APP_FBASE_APP_ID,
    measurementId: REACT_APP_FBASE_MEASUREMENT_ID,
  };

  // Initialize Firebase only once
  if (!app) {
    app = initializeApp(firebaseConfig);
  }
  const firebaseAuth = getAuth(app);
  const firebaseDb = getFirestore(app);
  const firebaseStorage = getStorage(app);
  // const firebaseAnalytics = getAnalytics(app);

  return {
    firebaseAuth,
    updateProfile,
    signInWithCustomToken,
    isSignInWithEmailLink,
    sendSignInLinkToEmail,
    signInWithEmailLink,
    onAuthStateChanged,
    linkWithCredential,
    EmailAuthProvider,
    reauthenticateWithPopup,
    linkWithPopup,
    unlink,
    updateEmail,
    sendEmailVerification,
    signOut,
    firebaseDb,
    doc,
    setDoc,
    getDoc,
    firebaseStorage,
    ref,
    uploadBytes,
    getDownloadURL,
    listAll,
    list,
    // firebaseAnalytics,
    // logEvent,
  };
}
