import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { AuthContext } from "Auth.js";

// reactstrap components
import {
  Alert,
  ButtonGroup,
  Button,
  Collapse,
  Card,
  Container,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  CardImg,
  CardBody,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Table,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";

import { Form, Field } from "react-final-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  truncateAddressShort,
  truncateAddressLong,
  truncateAddressXLong,
  truncateText,
  nativeCurrency,
  notify,
  validEmail,
  validEthAddress,
  removeProtocolStr,
  capitalize,
  NoDataText,
} from "utils/Utils.js";

import {
  getProfileWeb3,
  ownerAddTeamMember,
  ownerUpdateTeamMember,
  creatorUpdateOwnTeamRole,
} from "utils/Api.js";

import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.compat.css";

import Avatar from "boring-avatars";

import { SocialLinks } from "social-links";

import Select from "react-select";

import Loader from "components/Loaders/Loader.js";
import LoaderDots from "components/Loaders/LoaderDots.js";
import ModalSuccess from "components/Modals/ModalSuccess.js";
import LoaderSection from "components/Loaders/LoaderSection.js";
import LoaderModal from "components/Loaders/LoaderModal.js";

import ScaleLoader from "react-spinners/ScaleLoader";
import BarLoader from "react-spinners/BarLoader";
import { set } from "lodash";

const {
  defaultFormImg,
  defaultFormBanner,
  defaultProfileImg,
  bgGradient,
  defaultBoxGrayImg,
  verifiedImg,
  ethImg,
  nsfwImg,
  mmImg,
  wcImg,
  pwImg,
  ethWhiteImg,
  ethLogoColor,
  polygonImg,
  polygonWhiteImg,
  polygonLogoColor,
  bscLogoColor,
  ftmLogoColor,
  avaxLogoColor,
  cronosLogoColor,
  solLogoColor,
  catTokenImg,
  catArtImg,
  catMusicImg,
  catGameImg,
  catPartyImg,
  catSportsImg,
  catDaoImg,
  catMeetingImg,
  catStudyImg,
  catLeisureImg,
  catFundingImg,
  catMiscImg,
  TeamTypeSelectorOptions,
  ChainOptions,
  EventCategoryOptions,
  EventCategorySelectorOptions,
  CreatorRoleTypes,
  DisableMaxUsageOption,
  InviteMaxUsageOptions,
  InviteExpireOptions,
  ChainSelectorOptions,
  USDateOption,
  UserRoleTypes,
  UserRoleStatus,
  VerifiedUserTypes,
  VerifiedUserStatus,
  QuillEditorModules,
  QuillEditorFormats,
} = require("utils/Variables.js");

export default function EventTeam(props) {
  let { event, eventId, updateEvent, isEventActiveCreator } = props;

  const navigate = useNavigate();

  const {
    currentUser,
    currentUserId,
    currentUserEthAddress,
    currentAuthToken,
    currentUserInfo,
    setCurrentUserInfo,
  } = useContext(AuthContext);

  const hasAdminRole = currentUserInfo?.hasAdminRole;
  const hasCreatorRole = currentUserInfo?.hasCreatorRole;
  const isVerifiedUser = currentUserInfo?.isVerifiedUser;

  const [modalLoading, setModalLoading] = useState(false);
  const [teamUpdating, setTeamUpdating] = useState(false);
  const [teamSaving, setTeamSaving] = useState(false);
  const [teamLoading, setTeamLoading] = useState(false);

  const [useMemberWallet, setUseMemberWallet] = useState(true);
  const [newMemberWallet, setNewMemberWallet] = useState("");
  const [newMemberEmail, setNewMemberEmail] = useState("");
  // Place owner on the top
  const [teamList, setTeamList] = useState(
    event?.team?.sort((x, y) => {
      return x?.eventRole?.length - y?.eventRole?.length;
    }) || []
  );

  const [cohostList, setCohostList] = useState([
    ...new Set([
      ...(event?.cohostIds || []),
      ...(event?.cohostPendingIds || []),
    ]),
  ]);
  const [panelList, setPanelList] = useState([
    ...new Set([...(event?.panelIds || []), ...(event?.panelPendingIds || [])]),
  ]);

  const [teamTypeOption, setTeamTypeOption] = useState();
  const [userToAdd, setUserToAdd] = useState();
  const [teamError, setTeamError] = useState();

  const [teamFull, setTeamFull] = useState();
  const [teamOptionError, setTeamOptionError] = useState();
  const [newTeamSuccess, setNewTeamSuccess] = useState(false);
  const [addTeamModalOpen, setAddTeamModalOpen] = useState(false);

  const [teamActionError, setTeamActionError] = useState();
  const [teamAction, setTeamAction] = useState();
  const [selectedTeamMember, setSelectedTeamMember] = useState();
  const [teamActionModalOpen, setTeamActionModalOpen] = useState(false);

  const listTeam = () => {
    return teamList?.length > 0 ? (
      teamList?.map((item, index) => {
        return (
          item && (
            <tr key={index} className="w-100">
              <td className="text-left">
                <a
                  href={
                    currentUserId === item?.userId
                      ? "/account"
                      : "/" + item?.ethAddress
                  }
                  target="_blank"
                >
                  <div className="author">
                    {item?.imageUrl ? (
                      <img
                        alt="..."
                        className="avatar img-raised"
                        src={item?.imageUrl}
                      />
                    ) : (
                      <span className="rounded-circle avatar img-raised">
                        <Avatar
                          size={40}
                          name={item?.ethAddress}
                          square="false"
                          variant="ring"
                          colors={[
                            "#000000",
                            "#2F2F2F",
                            "#505050",
                            "#797979",
                            "#CECECE",
                          ]}
                          className="rounded-circle profile-image"
                        />
                      </span>
                    )}
                    <div className="text">
                      <span className="name">
                        {truncateText(item?.name, 13) || "Unnamed"}

                        {item?.isVerifiedUser && (
                          <img
                            className="realpass-verified-name-suffix ml-2 mb-1"
                            src={verifiedImg}
                          />
                        )}
                      </span>
                      <div className="meta">
                        <strong>{capitalize(item?.eventRole)}</strong>
                        {item?.eventRoleStatus === "pending" && (
                          <small className="ml-2">
                            ({item?.eventRoleStatus})
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                </a>
                {teamUpdating && selectedTeamMember?.userId === item?.userId ? (
                  <div className="float-right">
                    <ScaleLoader
                      color="#aaa"
                      loading={teamUpdating}
                      height="10px"
                      cssOverride={{
                        display: "inline",
                      }}
                    />
                  </div>
                ) : event?.isOwner && currentUserId !== item?.userId ? (
                  <UncontrolledDropdown className="text-right float-right">
                    <DropdownToggle id="teamOption" color="link" type="button">
                      <FontAwesomeIcon
                        icon="fa-ellipsis-vertical"
                        className="fa-lg"
                      />
                    </DropdownToggle>
                    <DropdownMenu
                      className="full-dropdown-menu-right-sm"
                      style={{ margin: 0 }}
                    >
                      <DropdownItem
                        onClick={() => confirmTeamAction("update", item)}
                      >
                        <FontAwesomeIcon icon="fa-user-gear" className="mr-1" />
                        <span className="ml-1">Update Role</span>
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => confirmTeamAction("revoke", item)}
                      >
                        <FontAwesomeIcon
                          icon="fa-user-xmark"
                          className="mr-1"
                        />
                        <span className="ml-1">Revoke Role</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                ) : (
                  (event?.isCohost || event?.isPanelist) &&
                  currentUserId === item?.userId && (
                    <UncontrolledDropdown className="text-right float-right">
                      <DropdownToggle
                        id="teamOption"
                        color="link"
                        type="button"
                      >
                        <FontAwesomeIcon
                          icon="fa-ellipsis-vertical"
                          className="fa-lg"
                        />
                      </DropdownToggle>
                      <DropdownMenu
                        className="full-dropdown-menu-right-sm"
                        style={{ margin: 0 }}
                      >
                        <DropdownItem
                          onClick={() => confirmTeamAction("leave", item)}
                        >
                          <FontAwesomeIcon
                            icon="fa-user-xmark"
                            className="mr-1"
                          />
                          <span className="ml-1">Leave Team</span>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )
                )}
              </td>
            </tr>
          )
        );
      })
    ) : (
      <tr>
        <td>{teamLoading || <>{NoDataText("sm", true)}</>}</td>
      </tr>
    );
  };

  const toggleUseMemberWallet = (flag) => {
    if (useMemberWallet !== flag) {
      setUseMemberWallet(flag);
      setTeamError();

      if (flag) {
        setNewMemberWallet("");
      } else {
        setNewMemberEmail("");
      }
    }
  };

  const confirmInviteWallet = async () => {
    if (!newMemberWallet || !validEthAddress(newMemberWallet)) {
      setTeamError("Invalid wallet address.");
      return;
    }

    if (
      currentUserEthAddress === newMemberWallet ||
      event?.team?.some((e) => e.ethAddress === newMemberWallet)
    ) {
      setTeamError("User is already in the team.");
      return;
    }
    setNewTeamSuccess();
    setTeamError();
    setAddTeamModalOpen(true);

    if (newMemberWallet === userToAdd?.ethAddress) {
      return;
    }

    try {
      setModalLoading(true);

      const profile = await getProfileWeb3(currentAuthToken, newMemberWallet);

      setUserToAdd(profile);
    } catch (err) {
      console.error(err.message);
      notify("Unable to find user.", "error");
      setUserToAdd();
    } finally {
      setModalLoading(false);
    }
  };

  // const confirmInviteEmail = async () => {
  //   if (!newMemberEmail || !validEmail(newMemberEmail)) {
  //     setTeamError("Invalid email address.");
  //     return;
  //   }

  //   if (
  //     currentUserInfo?.email === newMemberEmail ||
  //     event?.team?.some((e) => e.email === newMemberEmail)
  //   ) {
  //     setTeamError("User is already in the team.");
  //     return;
  //   }
  //   setNewTeamSuccess();
  //   setTeamError();
  //   setAddTeamModalOpen(true);

  //   if (newMemberEmail === userToAdd?.email) {
  //     return;
  //   }

  //   try {
  //     setModalLoading(true);

  //     const profile = await getProfileWeb3(currentAuthToken, newMemberWallet);

  //     setUserToAdd(profile);
  //   } catch (err) {
  //     console.error(err.message);
  //     notify("Unable to find user.", "error");
  //     setUserToAdd();
  //   } finally {
  //     setModalLoading(false);
  //   }
  // };

  const addTeamMember = async (teamType, address, email) => {
    if (!validEthAddress(address) && !validEmail(email)) {
      return;
    }

    if (!event?.isOwner) {
      return;
    }

    if (teamFull) {
      return;
    }

    try {
      setTeamSaving(true);
      const res = await ownerAddTeamMember(
        currentAuthToken,
        eventId,
        teamType,
        address,
        email
      );

      if (res?.team) {
        setNewMemberWallet("");
        setNewMemberEmail("");
        setTeamTypeOption();
        setNewTeamSuccess(true);
        updateEvent({ ...event, ...res });
        setTeamList(
          res?.team?.sort((x, y) => {
            return x?.eventRole?.length - y?.eventRole?.length;
          }) || []
        );
        setCohostList([
          ...new Set([
            ...(res?.cohostIds || []),
            ...(res?.cohostPendingIds || []),
          ]),
        ]);
        setPanelList([
          ...new Set([
            ...(res?.panelIds || []),
            ...(res?.panelPendingIds || []),
          ]),
        ]);
      }
    } catch (err) {
      console.error(err.message);
      notify(`Unable to add ${teamType}.`, "error");
    } finally {
      setTeamSaving(false);
    }
  };

  const confirmTeamAction = (action, teamMember) => {
    setTeamAction(action);
    setSelectedTeamMember(teamMember);
    setTeamActionModalOpen(true);
  };

  const performTeamAction = async (action, teamMember, role) => {
    if (!action || !teamMember?.userId) {
      return;
    }

    if (["update", "revoke"].includes(action) && !event?.isOwner) {
      return;
    }

    if (action === "update" && teamFull) {
      return;
    }

    try {
      setTeamUpdating(true);

      let newRole;
      if (action === "update") {
        if (teamMember?.eventRole === role) {
          setTeamTypeOption();
          setSelectedTeamMember();
          setTeamUpdating(false);
          notify(`Successfully updated the event role.`, "success");
          return;
        }
        newRole = role;
      }

      let res;
      if (event?.isOwner) {
        res = await ownerUpdateTeamMember(
          currentAuthToken,
          eventId,
          action,
          teamMember?.userId,
          newRole
        );
      } else if (event?.isCohost || event?.isPanelist) {
        res = await creatorUpdateOwnTeamRole(currentAuthToken, eventId, action);
      }

      if (res?.team) {
        setTeamTypeOption();
        setSelectedTeamMember();
        updateEvent({ ...event, ...res });

        if (!res?.team.some((e) => e.userId === currentUserId)) {
          navigate(`/event/${eventId}`);
        }

        setTeamList(
          res?.team?.sort((x, y) => {
            return x?.eventRole?.length - y?.eventRole?.length;
          }) || []
        );
        setCohostList([
          ...new Set([
            ...(res?.cohostIds || []),
            ...(res?.cohostPendingIds || []),
          ]),
        ]);
        setPanelList([
          ...new Set([
            ...(res?.panelIds || []),
            ...(res?.panelPendingIds || []),
          ]),
        ]);
        setTeamUpdating(false);
        notify(`Successfully updated the event role.`, "success");
      }
    } catch (err) {
      console.error(err.message);
      setTeamUpdating(false);
      notify(`Unable to ${action} ${teamMember?.eventRole}.`, "error");
    }
  };

  const checkTeamFull = (role) => {
    if (role === "cohost" && cohostList.length >= 3) {
      setTeamOptionError("Cohost group is already full.");
      setTeamFull(true);
    } else if (role === "panelist" && panelList?.length >= 3) {
      setTeamOptionError("Panel group is already full.");
      setTeamFull(true);
    } else {
      setTeamOptionError();
      setTeamFull(false);
    }
  };

  const inviteMemberEmail = async () => {};

  const ownerRemoveMember = async () => {};

  const memberSelfRemove = async () => {};

  const memberConfirm = async () => {};

  return (
    <>
      {(hasAdminRole || isEventActiveCreator) && (
        <>
          <h5 className="text-default">
            <strong>Team</strong>
          </h5>
          <Form
            onSubmit={() => {}}
            validate={async (values) => {
              const errors = {};

              // if (!email) {
              //   errors.email = "Email is required.";
              // } else if (!validEmail(email)) {
              //   errors.email = "Email must have valid format.";
              // } else if (email.length > 100) {
              //   errors.email = "Email must not exceed 100 characters.";
              // }

              return errors;
            }}
            render={({
              handleSubmit,
              values,
              submitting,
              validating,
              valid,
            }) => (
              <div className="m-2">
                <form onSubmit={handleSubmit}>
                  <Row className="mt-4 mb-4">
                    <Col xl="9" lg="10" md="11" sm="12" xs="12">
                      <div>
                        {(hasAdminRole || event?.isOwner) && (
                          <FormGroup>
                            <Field name="team">
                              {({ input, meta }) => (
                                <>
                                  <Row>
                                    <Col className="col-7">
                                      <Label for="team">
                                        <div>
                                          <strong>Invite Team Member</strong>
                                        </div>
                                        <small className="text-muted">
                                          Add team member by{" "}
                                          {useMemberWallet
                                            ? "web3 wallet"
                                            : "email"}
                                        </small>
                                      </Label>
                                    </Col>
                                    {/* <Col>
                                      <ButtonGroup
                                        role="tablist"
                                        className="float-right"
                                      >
                                        <Button
                                          aria-selected={!useMemberWallet}
                                          className={`btn-tab mb-sm-3 mb-md-0 ${
                                            useMemberWallet && "active"
                                          }`}
                                          outline
                                          color="dark"
                                          size="sm"
                                          onClick={(e) =>
                                            toggleUseMemberWallet(true)
                                          }
                                          type="button"
                                          role="tab"
                                          disabled={teamSaving}
                                        >
                                          Wallet
                                        </Button>
                                        <Button
                                          aria-selected={!useMemberWallet}
                                          className={`btn-tab mb-sm-3 mb-md-0 ${
                                            !useMemberWallet && "active"
                                          }`}
                                          outline
                                          color="dark"
                                          size="sm"
                                          onClick={(e) =>
                                            toggleUseMemberWallet(false)
                                          }
                                          type="button"
                                          role="tab"
                                          disabled={teamSaving}
                                        >
                                          Email
                                        </Button>
                                      </ButtonGroup>
                                    </Col> */}
                                  </Row>

                                  <Row>
                                    <Col>
                                      {
                                        <>
                                          <InputGroup>
                                            <InputGroupText className="input-group-left text-muted">
                                              <FontAwesomeIcon icon="fa-wallet" />
                                            </InputGroupText>
                                            <Input
                                              id="memberWalletSearch"
                                              value={newMemberWallet}
                                              onChange={(e) =>
                                                setNewMemberWallet(
                                                  e.target.value.toLowerCase()
                                                )
                                              }
                                              placeholder="0xabc123..."
                                              type="text"
                                              className="input-group-mid"
                                            />
                                            <Button
                                              color="primary"
                                              type="button"
                                              className="input-group-right"
                                              onClick={confirmInviteWallet}
                                              disabled={
                                                !newMemberWallet || teamSaving
                                              }
                                            >
                                              Add
                                              {teamSaving && (
                                                <span className="ml-2">
                                                  <ScaleLoader
                                                    color="#eee"
                                                    loading={teamSaving}
                                                    height="10px"
                                                    cssOverride={{
                                                      display: "inline",
                                                    }}
                                                  />
                                                </span>
                                              )}
                                            </Button>
                                          </InputGroup>

                                          {teamError && (
                                            <small className="text-danger">
                                              <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                              {teamError}
                                            </small>
                                          )}
                                        </>
                                      }
                                      {/* {useMemberWallet ? (
                                        <>
                                          <InputGroup>
                                            <InputGroupText className="input-group-left text-muted">
                                              <FontAwesomeIcon icon="fa-wallet" />
                                            </InputGroupText>
                                            <Input
                                              id="memberWalletSearch"
                                              value={newMemberWallet}
                                              onChange={(e) =>
                                                setNewMemberWallet(
                                                  e.target.value.toLowerCase()
                                                )
                                              }
                                              placeholder="0xabc123..."
                                              type="text"
                                              className="input-group-mid"
                                            />
                                            <Button
                                              color="primary"
                                              type="button"
                                              className="input-group-right"
                                              onClick={confirmInviteWallet}
                                              disabled={
                                                !newMemberWallet || teamSaving
                                              }
                                            >
                                              Add
                                              {teamSaving && (
                                                <span className="ml-2">
                                                  <ScaleLoader
                                                    color="#eee"
                                                    loading={teamSaving}
                                                    height="10px"
                                                    cssOverride={{
                                                      display: "inline",
                                                    }}
                                                  />
                                                </span>
                                              )}
                                            </Button>
                                          </InputGroup>

                                          {teamError && (
                                            <small className="text-danger">
                                              <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                              {teamError}
                                            </small>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <InputGroup>
                                            <InputGroupText className="input-group-left text-muted">
                                              <FontAwesomeIcon icon="fa-envelope" />
                                            </InputGroupText>
                                            <Input
                                              id="memberEmailSearch"
                                              value={newMemberEmail}
                                              onChange={(e) =>
                                                setNewMemberEmail(
                                                  e.target.value.toLowerCase()
                                                )
                                              }
                                              placeholder="youremail@example.com"
                                              type="text"
                                              className="input-group-mid"
                                            />
                                            <Button
                                              color="primary"
                                              type="button"
                                              className="input-group-right"
                                              onClick={confirmInviteEmail}
                                              disabled={
                                                !newMemberEmail || teamSaving
                                              }
                                            >
                                              Add
                                              {teamSaving && (
                                                <span className="ml-2">
                                                  <ScaleLoader
                                                    color="#eee"
                                                    loading={teamSaving}
                                                    height="10px"
                                                    cssOverride={{
                                                      display: "inline",
                                                    }}
                                                  />
                                                </span>
                                              )}
                                            </Button>
                                          </InputGroup>

                                          {teamError && newMemberEmail && (
                                            <small className="text-danger">
                                              <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                              {teamError}
                                            </small>
                                          )}
                                        </>
                                      )} */}
                                    </Col>
                                  </Row>
                                </>
                              )}
                            </Field>
                          </FormGroup>
                        )}

                        <Row className="my-2 text-center">
                          <Col className="mt-2">
                            <div className="mt-2">
                              <Table>
                                <tbody>
                                  <>
                                    {teamList && teamList?.length > 0 ? (
                                      listTeam()
                                    ) : (
                                      <>{NoDataText("sm", true)}</>
                                    )}
                                  </>
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </form>
              </div>
            )}
          />
          {(hasAdminRole || event?.isOwner) && (
            <>
              {newTeamSuccess ? (
                <ModalSuccess
                  success={newTeamSuccess}
                  message={`You've successfully added a new ${teamTypeOption?.value}.`}
                />
              ) : (
                <Modal
                  toggle={() => setAddTeamModalOpen(!addTeamModalOpen)}
                  isOpen={addTeamModalOpen}
                >
                  <BarLoader
                    loading={modalLoading}
                    cssOverride={{
                      marginLeft: "1.5%",
                      marginRight: "1.5%",
                      width: "97%",
                      backgroundColor: "#fff",
                      opacity: "1",
                    }}
                  />
                  <div className="modal-header">
                    <span className="modal-title" id="addTeamModalLabel">
                      <strong>
                        Add Team Member by{" "}
                        {useMemberWallet ? "Wallet" : "Email"}
                      </strong>
                    </span>
                    <button
                      aria-label="Close"
                      className=" close"
                      type="button"
                      onClick={() => {
                        setAddTeamModalOpen(false);
                        setTeamTypeOption();
                        setTeamOptionError();
                        setTeamFull(false);
                      }}
                      disabled={modalLoading}
                    >
                      <span aria-hidden={true}>
                        <FontAwesomeIcon icon="fa-xmark" />
                      </span>
                    </button>
                  </div>
                  <ModalBody>
                    <div>
                      <Row>
                        <Col>
                          {teamTypeOption?.value && (
                            <Alert color="secondary" className="text-default">
                              <span className="text-default">
                                <FontAwesomeIcon
                                  icon="fa-circle-info"
                                  className="text-muted mr-2"
                                />
                                {teamTypeOption?.value === "cohost"
                                  ? "Cohosts help manage event, campaigns, and community."
                                  : teamTypeOption?.value === "panelist" &&
                                    "Panelists help moderate and engage with the community."}
                              </span>
                            </Alert>
                          )}
                          <div>
                            {!teamTypeOption?.value ? (
                              <FontAwesomeIcon
                                icon="fa-circle-check"
                                className="fa-lg"
                              />
                            ) : teamOptionError || teamFull ? (
                              <FontAwesomeIcon
                                icon="fa-circle-xmark"
                                className="text-danger fa-lg"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon="fa-circle-check"
                                className="text-success fa-lg"
                              />
                            )}
                            <span className="ml-2">
                              <strong>Choose Team Type</strong>
                            </span>
                          </div>
                          <div className="ml-2">
                            <small className="text-muted">
                              You can add up to 3 cohosts and 3 panelists
                            </small>
                          </div>
                          <InputGroup className="mt-2">
                            <Select
                              id="teamTypeSelector"
                              className="realpass-selector-sm"
                              onChange={(e) => {
                                setTeamTypeOption(e);
                                checkTeamFull(e.value);
                              }}
                              options={TeamTypeSelectorOptions}
                              value={teamTypeOption}
                              styles={{
                                control: (base) => ({
                                  ...base,
                                  height: "38px",
                                  padding: "1.5px 2px !important",
                                }),
                              }}
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </InputGroup>

                          {teamOptionError && (
                            <small className="text-danger">
                              <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                              {teamOptionError}
                            </small>
                          )}
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col>
                          <div>
                            <FontAwesomeIcon icon="fa-user-check" />
                            <span className="ml-2">
                              <strong>
                                {userToAdd ? (
                                  <>
                                    Confirm to add{" "}
                                    <strong>
                                      {truncateText(userToAdd?.name, 13)}
                                    </strong>
                                    {teamTypeOption?.value && (
                                      <> as {teamTypeOption?.value}</>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    Confirm to add
                                    {teamTypeOption?.value}
                                  </>
                                )}
                              </strong>
                            </span>
                          </div>
                          {modalLoading ? (
                            <>
                              <div className="my-3 px-2 text-muted text-center">
                                Looking for user linked to{" "}
                                <strong>
                                  {truncateAddressLong(newMemberWallet)}
                                </strong>
                                ...
                              </div>
                            </>
                          ) : userToAdd ? (
                            <>
                              <div className="mt-2 mb-3 pt-3 px-4">
                                <a
                                  href={"/" + userToAdd?.ethAddress}
                                  target="_blank"
                                >
                                  <Row>
                                    <Col>
                                      <div className="author">
                                        {userToAdd?.imageUrl ? (
                                          <img
                                            alt="..."
                                            className="avatar img-raised"
                                            src={userToAdd?.imageUrl}
                                          />
                                        ) : (
                                          <span className="rounded-circle avatar img-raised">
                                            <Avatar
                                              size={40}
                                              name={userToAdd?.ethAddress}
                                              square="false"
                                              variant="ring"
                                              colors={[
                                                "#000000",
                                                "#2F2F2F",
                                                "#505050",
                                                "#797979",
                                                "#CECECE",
                                              ]}
                                              className="rounded-circle profile-image"
                                            />
                                          </span>
                                        )}
                                        <div className="text">
                                          <span className="name">
                                            {truncateText(
                                              userToAdd?.name,
                                              18
                                            ) || "Unnamed"}

                                            {userToAdd?.isVerifiedUser && (
                                              <img
                                                className="realpass-verified-name-suffix ml-2 mb-1"
                                                src={verifiedImg}
                                              />
                                            )}
                                          </span>
                                          <div className="meta">
                                            <span>
                                              {truncateAddressXLong(
                                                userToAdd?.ethAddress
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="float-right text-muted">
                                        <FontAwesomeIcon
                                          icon="arrow-up-right-from-square"
                                          className="ml-2 fa-sm"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </a>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="my-3 px-2 text-muted text-center">
                                Haven't found user linked to{" "}
                                <strong>
                                  {truncateAddressLong(newMemberWallet)}
                                </strong>
                                .
                                <br />
                                Would you like to add {
                                  teamTypeOption?.value
                                }{" "}
                                anyway?
                              </div>
                            </>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      size="md"
                      color="primary"
                      disabled={
                        modalLoading ||
                        teamSaving ||
                        !newMemberWallet ||
                        !teamTypeOption?.value ||
                        !!teamOptionError ||
                        teamFull ||
                        !!teamError
                      }
                      onClick={() => {
                        addTeamMember(
                          teamTypeOption?.value,
                          newMemberWallet,
                          newMemberEmail
                        );
                      }}
                    >
                      {teamSaving ? (
                        <ScaleLoader
                          color="#eee"
                          loading={teamSaving}
                          height="10px"
                          cssOverride={{
                            display: "inline",
                          }}
                        />
                      ) : (
                        <>Confirm</>
                      )}
                    </Button>
                    <Button
                      size="md"
                      color="link"
                      type="button"
                      disabled={modalLoading || teamSaving}
                      onClick={() => {
                        setAddTeamModalOpen(false);
                        setTeamTypeOption();
                        setTeamOptionError();
                        setTeamFull(false);
                      }}
                    >
                      Close
                    </Button>
                  </ModalFooter>
                </Modal>
              )}
            </>
          )}

          <Modal
            toggle={() => setTeamActionModalOpen(!teamActionModalOpen)}
            isOpen={teamActionModalOpen}
          >
            <div className="modal-header">
              <span className="modal-title" id="teamActionModalLabel">
                <strong>{capitalize(teamAction)} Team Role</strong>
              </span>

              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => {
                  setTeamActionModalOpen(false);
                  setTeamTypeOption();
                  setSelectedTeamMember();
                  setTeamOptionError();
                  setTeamFull(false);
                }}
              >
                <span aria-hidden={true}>
                  <FontAwesomeIcon icon="fa-xmark" />
                </span>
              </button>
            </div>
            <ModalBody>
              <div className="text-default">
                {teamAction === "revoke" && (
                  <>
                    Would you like to remove{" "}
                    <strong>
                      {truncateText(selectedTeamMember?.name, 18) ||
                        truncateAddressShort(selectedTeamMember?.ethAddress) ||
                        selectedTeamMember?.email}
                    </strong>{" "}
                    from {selectedTeamMember?.eventRole}?
                  </>
                )}
                {teamAction === "update" && (
                  <>
                    <div>
                      {!teamTypeOption?.value ? (
                        <FontAwesomeIcon
                          icon="fa-circle-check"
                          className="fa-lg"
                        />
                      ) : teamOptionError || teamFull ? (
                        <FontAwesomeIcon
                          icon="fa-circle-xmark"
                          className="text-danger fa-lg"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon="fa-circle-check"
                          className="text-success fa-lg"
                        />
                      )}
                      <span className="ml-2">
                        <strong>Update Team Type</strong>
                      </span>
                    </div>
                    <div className="ml-2">
                      <small className="text-muted">
                        Assign team member {teamTypeOption?.value || "a new"}{" "}
                        role
                      </small>
                    </div>
                    <InputGroup className="mt-2">
                      <Select
                        id="teamTypeSelector"
                        className="realpass-selector-sm"
                        onChange={(e) => {
                          setTeamTypeOption(e);
                          checkTeamFull(e.value);
                        }}
                        options={TeamTypeSelectorOptions}
                        value={teamTypeOption}
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: "38px",
                            padding: "1.5px 2px !important",
                          }),
                        }}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </InputGroup>
                    {teamOptionError && (
                      <small className="text-danger">
                        <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                        {teamOptionError}
                      </small>
                    )}
                  </>
                )}
                {teamAction === "accept" && (
                  <>
                    Would you like to join{" "}
                    <strong>{truncateText(event?.name, 24)}</strong> as a{" "}
                    {selectedTeamMember?.eventRole}?
                  </>
                )}
                {teamAction === "leave" && (
                  <>
                    Would you like to leave{" "}
                    <strong>{truncateText(event?.name, 24)}</strong> team?
                  </>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                size="md"
                color={
                  ["revoke", "leave"].includes(teamAction)
                    ? "danger"
                    : "primary"
                }
                type="button"
                onClick={() => {
                  performTeamAction(
                    teamAction,
                    selectedTeamMember,
                    teamTypeOption?.value
                  );
                  setTeamActionModalOpen(false);
                }}
                disabled={
                  !!teamActionError ||
                  !!teamOptionError ||
                  teamFull ||
                  (teamAction === "update" && !teamTypeOption?.value)
                }
              >
                {capitalize(teamAction)}
              </Button>
              <Button
                size="md"
                color="link"
                type="button"
                disabled={modalLoading}
                onClick={() => {
                  setTeamActionModalOpen(false);
                  setTeamTypeOption();
                  setSelectedTeamMember();
                  setTeamOptionError();
                  setTeamFull(false);
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </>
      )}
    </>
  );
}
