import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import { AuthContext } from "Auth.js";

// reactstrap components
import { Alert, Button, Card, CardBody, Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// core components
import TopNavbar from "components/Navbars/TopNavbar.js";
import Loader from "components/Loaders/Loader.js";

import ScaleLoader from "react-spinners/ScaleLoader";
import BarLoader from "react-spinners/BarLoader";

import { notify } from "utils/Utils.js";

import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.compat.css";

const {
  qrGifImg,
  eventGifImg,
  launchGifImg,
  passGifImg,
  bgAbstractLine03,
  ChainOptions,
  EventCategoryOptions,
  USDateOption,
  UserRoleTypes,
  CreatorRoleTypes,
  UserRoleStatus,
  VerifiedUserTypes,
  VerifiedUserStatus,
} = require("utils/Variables.js");

function Create() {
  const navigate = useNavigate();

  const { currentUser, currentUserInfo, setCurrentUserInfo } =
    useContext(AuthContext);

  const [pageLoading, setPageLoading] = useState(false);

  const hasCreatorRole = currentUserInfo?.hasCreatorRole;
  const isVerifiedUser = currentUserInfo?.isVerifiedUser;

  const fetchProfile = async () => {
    // try {
    //   profileQuery.equalTo("user", currentUser);
    //   nfprofile = await profileQuery.first();
    //   if (!nfprofile) {
    //     notify(
    //       "You don't have a creator profile. Create a profile first.",
    //       "error"
    //     );
    //     setTimeout(() => navigate(`/settings`), 3000);
    //   }
    // } catch (err) {
    //   console.error(err.message);
    //   notify("Unable to fetch profile.", "error");
    // }
  };

  useEffect(() => {
    // fetchProfile();
  }, []);

  return (
    <>
      <BarLoader
        loading={pageLoading}
        cssOverride={{
          width: "100%",
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          zIndex: "1031",
          backgroundColor: "#fff",
          opacity: "1",
        }}
      />
      <TopNavbar />
      <ReactNotifications />
      <div
        className="bg-transparent"
        style={{ height: "100%", width: "100%", marginBottom: "0px" }}
      >
        <img alt="..." className="bg-image" src={bgAbstractLine03} />
      </div>
      <section className="section section-shaped section-main">
        <Container className="">
          <Row className="justify-content-center">
            <Col lg="6" md="7" sm="8">
              <h3 className="display-4 mb-3">I would like to create</h3>
              <Card className="shadow border-0 pt-3 pb-3">
                <CardBody className="px-lg-5">
                  <div className="text-center my-3 text-dark">
                    <span>
                      <strong>Choose a Template</strong>
                    </span>
                  </div>
                  {!hasCreatorRole && (
                    <Alert className="bg-success my-3">
                      <span className="text-white">
                        You need to apply for a creator account first.
                      </span>
                      <Button
                        color="white"
                        size="sm"
                        href="/settings"
                        className="float-right"
                      >
                        Go to Settings
                      </Button>
                    </Alert>
                  )}
                  <div className="btn-wrapper text-center">
                    <Button
                      block
                      className="btn-icon my-3"
                      outline
                      color="white"
                      href="/create/event"
                      style={{ border: " 1px solid #000000" }}
                      disabled={!hasCreatorRole}
                    >
                      <div className="d-flex justify-content-center">
                        <img src={eventGifImg} className="icon-lg" />
                        <div className="ml-2 text-dark text-left">
                          <span className="display-4">Event Plan</span>
                          <br />
                          <small>Launch IRL & Virtual Event</small>
                        </div>
                      </div>
                    </Button>

                    <Button
                      block
                      className="btn-icon my-3"
                      outline
                      color="white"
                      href="/create/access"
                      style={{ border: " 1px solid #000000" }}
                      // disabled={!hasCreatorRole}
                      disabled
                    >
                      <div className="d-flex justify-content-center">
                        <img src={qrGifImg} className="icon-lg" />
                        <div className="ml-2 text-dark text-left">
                          <span className="display-4">QR Access</span>{" "}
                          <small>(soon)</small>
                          <br />
                          <small>Tokengated Access for NFTs</small>
                        </div>
                      </div>
                    </Button>

                    <Button
                      block
                      className="btn-icon my-3"
                      outline
                      color="white"
                      href="/create/event"
                      style={{ border: " 1px solid #000000" }}
                      // disabled={!hasCreatorRole}
                      disabled
                    >
                      <div className="d-flex justify-content-center">
                        <img src={launchGifImg} className="icon-lg" />
                        <div className="ml-2 text-dark text-left">
                          <span className="display-4">NFT Launch</span>{" "}
                          <small>(soon)</small>
                          <br />
                          <small>Campaign for NFT Launch</small>
                        </div>
                      </div>
                    </Button>

                    <Button
                      block
                      className="btn-icon my-3"
                      outline
                      color="white"
                      href="/create/pass"
                      style={{ border: " 1px solid #000000" }}
                      // disabled={!hasCreatorRole}
                      disabled
                    >
                      <div className="d-flex justify-content-center">
                        <img src={passGifImg} className="icon-lg" />
                        <div className="ml-2 text-dark text-left">
                          <span className="display-4">NFT Pass</span>{" "}
                          <small>(soon)</small>
                          <br />
                          <small>Sell Event & Club Pass as NFT</small>
                        </div>
                      </div>
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Create;
