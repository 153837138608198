import React from "react";

import { AuthContext } from "Auth.js";

// reactstrap components
import { Container, Row } from "reactstrap";

// core components
import TopNavbar from "components/Navbars/TopNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

// index page sections
import HeroIsoGrids from "components/Sections/HeroIsoGrids.js";
import TopEvents from "components/Sections/TopEvents.js";
import FAQGeneral from "components/Sections/FAQGeneral.js";

export default class Index extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  state = {};
  render() {
    return (
      <>
        <TopNavbar />
        <main ref="main">
          <HeroIsoGrids />
          {/* <TopEvents /> */}

          {/* <hr /> */}

          <FAQGeneral />
        </main>
        <DarkFooter />
      </>
    );
  }
}
