import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// reactstrap components
import {
  Collapse,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  Badge,
  Button,
  Container,
  UncontrolledCarousel,
  Row,
  Col,
} from "reactstrap";

export default function FAQGeneral() {
  const [openedCollapse, setOpenedCollapse] = useState("");

  return (
    <>
      <section className="section section-sm mt-5 mb-5">
        <Container>
          <Row className="justify-content-center text-center mb-4">
            <Col lg="8">
              <h2 className="display-3">FAQ</h2>
              <p>
                We've prepared some answers to frequently asked questions.{" "}
                <a
                  href="https://twitter.com/realpassxyz"
                  rel="external nofollow noopener"
                  target="_blank"
                  className="text-info"
                >
                  Get in touch on Twitter
                </a>{" "}
                if you would like to know more.
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="m-auto" md="10">
              <div className="accordion accordion-faq" id="accordion-faq">
                <Card className="shadow">
                  <CardHeader className="bg-white">
                    <h5 className="mb-0">
                      <Button
                        aria-expanded={openedCollapse === "collapse-1"}
                        onClick={() =>
                          setOpenedCollapse(
                            openedCollapse === "collapse-1" ? "" : "collapse-1"
                          )
                        }
                        className="w-100 text-left"
                        color="link"
                        type="button"
                      >
                        What is RealPass?{" "}
                        {openedCollapse === "collapse-1" ? (
                          <FontAwesomeIcon
                            icon="fa-chevron-up"
                            className="float-right pt-1"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon="fa-chevron-down"
                            className="float-right pt-1"
                          />
                        )}
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={openedCollapse === "collapse-1"}>
                    <CardBody className="opacity-8 px-5">
                      <small>
                        RealPass is a web3 platform that enables Location-Based
                        Tokens (LBTs), which are tied to locations in digital
                        and real-world.
                      </small>
                    </CardBody>
                  </Collapse>
                </Card>
                <Card className="shadow">
                  <CardHeader className="bg-white">
                    <h5 className="mb-0">
                      <Button
                        aria-expanded={openedCollapse === "collapse-2"}
                        onClick={() =>
                          setOpenedCollapse(
                            openedCollapse === "collapse-2" ? "" : "collapse-2"
                          )
                        }
                        className="w-100 text-left collapsed"
                        color="link"
                        type="button"
                      >
                        How do RealPass LBTs work?
                        {openedCollapse === "collapse-2" ? (
                          <FontAwesomeIcon
                            icon="fa-chevron-up"
                            className="float-right pt-1"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon="fa-chevron-down"
                            className="float-right pt-1"
                          />
                        )}
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={openedCollapse === "collapse-2"}>
                    <CardBody className="opacity-8 px-5">
                      <small>
                        LBTs on RealPass are digital assets that represent
                        ownership or a connection to a specific location. Users
                        can mint, collect, or interact with these tokens, which
                        are linked to both digital and real-world.
                      </small>
                    </CardBody>
                  </Collapse>
                </Card>
                <Card className="shadow">
                  <CardHeader className="bg-white">
                    <h5 className="mb-0">
                      <Button
                        aria-expanded={openedCollapse === "collapse-8"}
                        onClick={() =>
                          setOpenedCollapse(
                            openedCollapse === "collapse-8" ? "" : "collapse-8"
                          )
                        }
                        className="w-100 text-left collapsed"
                        color="link"
                        type="button"
                      >
                        Is RealPass safe?{" "}
                        {openedCollapse === "collapse-8" ? (
                          <FontAwesomeIcon
                            icon="fa-chevron-up"
                            className="float-right pt-1"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon="fa-chevron-down"
                            className="float-right pt-1"
                          />
                        )}
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={openedCollapse === "collapse-8"}>
                    <CardBody className="opacity-8 px-5">
                      <small>
                        RealPass prioritizes safety with blockchain technology,
                        ensuring secure transactions and ownership for all LBTs.
                      </small>
                    </CardBody>
                  </Collapse>
                </Card>
                <Card className="shadow">
                  <CardHeader className="bg-white">
                    <h5 className="mb-0">
                      <Button
                        aria-expanded={openedCollapse === "collapse-3"}
                        onClick={() =>
                          setOpenedCollapse(
                            openedCollapse === "collapse-3" ? "" : "collapse-3"
                          )
                        }
                        className="w-100 text-left collapsed"
                        color="link"
                        type="button"
                      >
                        Is RealPass free?{" "}
                        {openedCollapse === "collapse-3" ? (
                          <FontAwesomeIcon
                            icon="fa-chevron-up"
                            className="float-right pt-1"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon="fa-chevron-down"
                            className="float-right pt-1"
                          />
                        )}
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={openedCollapse === "collapse-3"}>
                    <CardBody className="opacity-8 px-5">
                      <small>
                        Creating an account on RealPass may be free, but minting
                        LBTs typically involves a cost, which varies based on
                        the blockchain used and market conditions.
                      </small>
                    </CardBody>
                  </Collapse>
                </Card>
                <Card className="shadow">
                  <CardHeader className="bg-white">
                    <h5 className="mb-0">
                      <Button
                        aria-expanded={openedCollapse === "collapse-6"}
                        onClick={() =>
                          setOpenedCollapse(
                            openedCollapse === "collapse-6" ? "" : "collapse-6"
                          )
                        }
                        className="w-100 text-left collapsed"
                        color="link"
                        type="button"
                      >
                        How can I mint my own LBTs?{" "}
                        {openedCollapse === "collapse-6" ? (
                          <FontAwesomeIcon
                            icon="fa-chevron-up"
                            className="float-right pt-1"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon="fa-chevron-down"
                            className="float-right pt-1"
                          />
                        )}
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={openedCollapse === "collapse-6"}>
                    <CardBody className="opacity-8 px-5">
                      <small>
                        To mint your own LBTs, simply register on RealPass, go
                        to the location that has LBTs available, and mint the
                        LBT.
                      </small>
                    </CardBody>
                  </Collapse>
                </Card>
                <Card className="shadow">
                  <CardHeader className="bg-white">
                    <h5 className="mb-0">
                      <Button
                        aria-expanded={openedCollapse === "collapse-7"}
                        onClick={() =>
                          setOpenedCollapse(
                            openedCollapse === "collapse-7" ? "" : "collapse-7"
                          )
                        }
                        className="w-100 text-left collapsed"
                        color="link"
                        type="button"
                      >
                        What blockchains does RealPass support?{" "}
                        {openedCollapse === "collapse-7" ? (
                          <FontAwesomeIcon
                            icon="fa-chevron-up"
                            className="float-right pt-1"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon="fa-chevron-down"
                            className="float-right pt-1"
                          />
                        )}
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={openedCollapse === "collapse-7"}>
                    <CardBody className="opacity-8 px-5">
                      <small>
                        RealPass supports Ethereum and Blast L2 blockchains.
                        Enjoy low gas minting (possible gas rebate) on Blast.
                      </small>
                    </CardBody>
                  </Collapse>
                </Card>

                <Card className="shadow">
                  <CardHeader className="bg-white">
                    <h5 className="mb-0">
                      <Button
                        aria-expanded={openedCollapse === "collapse-11"}
                        onClick={() =>
                          setOpenedCollapse(
                            openedCollapse === "collapse-11"
                              ? ""
                              : "collapse-11"
                          )
                        }
                        className="w-100 text-left"
                        color="link"
                        type="button"
                      >
                        Can LBTs be traded or sold like regular NFTs?{" "}
                        {openedCollapse === "collapse-11" ? (
                          <FontAwesomeIcon
                            icon="fa-chevron-up"
                            className="float-right pt-1"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon="fa-chevron-down"
                            className="float-right pt-1"
                          />
                        )}
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={openedCollapse === "collapse-11"}>
                    <CardBody className="opacity-8 px-5">
                      <small>
                        Yes, LBTs can be traded or sold on secondary markets
                        just like regular NFTs, allowing for potential
                        appreciation in value.
                      </small>
                    </CardBody>
                  </Collapse>
                </Card>
                <Card className="shadow">
                  <CardHeader className="bg-white">
                    <h5 className="mb-0">
                      <Button
                        aria-expanded={openedCollapse === "collapse-9"}
                        onClick={() =>
                          setOpenedCollapse(
                            openedCollapse === "collapse-9" ? "" : "collapse-9"
                          )
                        }
                        className="w-100 text-left collapsed"
                        color="link"
                        type="button"
                      >
                        What benefits do LBT holders get?{" "}
                        {openedCollapse === "collapse-9" ? (
                          <FontAwesomeIcon
                            icon="fa-chevron-up"
                            className="float-right pt-1"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon="fa-chevron-down"
                            className="float-right pt-1"
                          />
                        )}
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={openedCollapse === "collapse-9"}>
                    <CardBody className="opacity-8 px-5">
                      <small>
                        LBT holders can enjoy exclusive access to events,
                        promotions, and experiences tied to the location of
                        their token, along with the potential for asset
                        appreciation.
                      </small>
                    </CardBody>
                  </Collapse>
                </Card>
                <Card className="shadow">
                  <CardHeader className="bg-white">
                    <h5 className="mb-0">
                      <Button
                        aria-expanded={openedCollapse === "collapse-10"}
                        onClick={() =>
                          setOpenedCollapse(
                            openedCollapse === "collapse-10"
                              ? ""
                              : "collapse-10"
                          )
                        }
                        className="w-100 text-left"
                        color="link"
                        type="button"
                      >
                        How can RealPass benefit brands?{" "}
                        {openedCollapse === "collapse-10" ? (
                          <FontAwesomeIcon
                            icon="fa-chevron-up"
                            className="float-right pt-1"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon="fa-chevron-down"
                            className="float-right pt-1"
                          />
                        )}
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={openedCollapse === "collapse-10"}>
                    <CardBody className="opacity-8 px-5">
                      <small>
                        Brands can leverage RealPass to create unique marketing
                        campaigns, engage with NFT communities through exclusive
                        location-based offers, and provide innovative ways to
                        experience their products or services.
                      </small>
                    </CardBody>
                  </Collapse>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
