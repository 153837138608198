import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// reactstrap components
import { Button, Card, Container } from "reactstrap";

// core components
import TopNavbar from "components/Navbars/TopNavbar.js";
import LightFooter from "components/Footers/LightFooter.js";

const logoImage = require("assets/img/logos/logo-icon.svg");
const lostImage = require("assets/img/pages/lost.svg");
const planetImage = require("assets/img/animation/planet-spin.gif");

export default function NotFound() {
  return (
    <>
      <TopNavbar />
      <section className='section section-shaped section-main'>
        <Container>
          <div className='px-3'>
            <div className='text-center my-5'>
              <h4 className='display-4'>Lost in space, friend?</h4>
              <div className='my-5'>
                <img className='lost-in-space' alt='...' src={planetImage} />
              </div>
              <h1 className='display-1'>
                4 <img src={logoImage} /> 4
              </h1>
              <Button className='my-3' outline color='primary' href='/'>
                Beam Me Home
              </Button>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
