import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Alert,
  ButtonGroup,
  Button,
  Collapse,
  Card,
  Container,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  CardImg,
  CardBody,
  CardTitle,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Table,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  removeProtocolStr,
  getTimeBetween,
  getFormattedTimeBetween,
  validEthAddress,
} from "utils/Utils.js";

import { DefaultSocialLinkSize } from "utils/Variables.js";

export default function PaymentSplitsEditable(props) {
  let { wallets, updateWallets, updateFormChanged, updateWalletError } = props;

  const [payoutsError, setPayoutsError] = useState("");

  const [focused, setFocused] = React.useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const [walletsInput, setWalletsInput] = useState([
    { address: "", percent: 0 },
  ]);

  const [formChanged, setFormChanged] = useState(false);

  const addWallets = () => {
    setWalletsInput([...walletsInput, { address: "", percent: 0 }]);
  };

  const removeWallet = (index) => {
    const newWallets = [...walletsInput];
    newWallets.splice(index, 1);
    setWalletsInput(newWallets);
  };

  const changeWallets = (index, value) => {
    setFormChanged(true);

    const newWallets = [...walletsInput];
    const lowerAddress = value?.trim().toLowerCase();

    newWallets[index].address = lowerAddress;
    setWalletsInput(newWallets);
  };

  const changePercent = (index, value) => {
    setFormChanged(true);

    const newWallets = [...walletsInput];

    newWallets[index].percent = parseFloat(value);

    setWalletsInput(newWallets);
  };

  const checkDuplicateWallets = (wallets) => {
    const uniqueAddresses = new Set(
      wallets.map((wallet) => {
        return wallet.address;
      })
    );

    const hasDuplicates = uniqueAddresses.size < wallets.length;

    return hasDuplicates;
  };

  const checkValidWallets = (wallets) => {
    const invalidWallets = wallets.filter((wallet) => {
      return !validEthAddress(wallet.address);
    });

    return invalidWallets.length <= 0;
  };

  const sumPercent = (wallets) => {
    const sum = wallets.reduce((accumulator, object) => {
      return accumulator + object.percent;
    }, 0);

    return sum;
  };

  useEffect(() => {
    if (formChanged) {
      const sum = sumPercent(walletsInput);

      const validWallets = checkValidWallets(walletsInput);
      const hasDuplicates = checkDuplicateWallets(walletsInput);

      if (sum !== 100) {
        setPayoutsError("Total percent must equal 100.");
        updateWalletError(true);
      } else if (!validWallets) {
        setPayoutsError("Wallet address must be a valid ETH address.");
        updateWalletError(true);
      } else if (hasDuplicates) {
        setPayoutsError("Each wallet address must be unique.");
        updateWalletError(true);
      } else {
        setPayoutsError("");
        updateWalletError(false);
      }
    }
  }, [walletsInput]);

  return (
    <>
      {walletsInput?.map((item, index) => {
        return (
          <div key={index}>
            <Row className="my-2">
              <Col className="col-10">
                <InputGroup>
                  <InputGroupText className="input-group-left text-muted">
                    <FontAwesomeIcon icon="fa-wallet" />
                  </InputGroupText>

                  <Input
                    id="payoutWallet"
                    style={{ width: "30%" }}
                    value={item?.address}
                    onChange={(e) => {
                      changeWallets(index, e.target.value.toLowerCase());
                      updateFormChanged(true);
                    }}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    placeholder="0xabc123..."
                    type="text"
                    className="input-group-mid"
                  />

                  <InputGroupText className="input-group-mid-no-border">
                    <FontAwesomeIcon icon={"fa-percent"} />
                  </InputGroupText>

                  <Input
                    id="percent"
                    className="input-group-right pl-2"
                    value={item?.percent}
                    onChange={(e) => {
                      changePercent(index, e.target.value);
                      updateFormChanged(true);
                    }}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    placeholder={"100%"}
                    type="number"
                    required
                  />
                </InputGroup>
              </Col>
              {index === 0 ? (
                <Button
                  className="text-info mt-2"
                  color="link"
                  size="sm"
                  disabled={walletsInput.length >= DefaultSocialLinkSize}
                  onClick={() => {
                    addWallets();
                    updateFormChanged(true);
                  }}
                >
                  <FontAwesomeIcon icon="fa-square-plus" className="fa-lg" />
                </Button>
              ) : (
                <Button
                  color="link"
                  size="sm"
                  className="text-gray btn-icon-only mt-2 ml-0"
                  onClick={() => {
                    removeWallet(index);
                    updateFormChanged(true);
                  }}
                >
                  <FontAwesomeIcon icon="fa-trash-can" className="fa-lg" />
                </Button>
              )}
            </Row>
          </div>
        );
      })}

      {payoutsError && !focused && (
        <small className="text-danger">
          <FontAwesomeIcon icon="fa-triangle-exclamation" /> {payoutsError}
        </small>
      )}
    </>
  );
}
