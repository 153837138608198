import React from "react";

// reactstrap components
import {
  Alert,
  ButtonGroup,
  Button,
  Collapse,
  Card,
  Container,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  CardImg,
  CardBody,
  CardTitle,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Table,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Avatar from "boring-avatars";

import { truncateText, capitalize } from "utils/Utils.js";

const { verifiedImg } = require("utils/Variables.js");

export default function SimpleCreatorsList(props) {
  let { creators } = props;

  return creators?.map((item, index) => {
    return (
      <div key={index} className='mt-2 mb-3'>
        <a href={"/" + item.ethAddress} target='_blank'>
          <div className='author'>
            {item.imageUrl ? (
              <img
                alt='...'
                className='avatar img-raised'
                src={item.imageUrl}
              />
            ) : (
              <span className='rounded-circle avatar img-raised'>
                <Avatar
                  size={40}
                  name={item.ethAddress}
                  square='false'
                  variant='ring'
                  colors={[
                    "#000000",
                    "#2F2F2F",
                    "#505050",
                    "#797979",
                    "#CECECE",
                  ]}
                  className='rounded-circle profile-image'
                />
              </span>
            )}
            <div className='text'>
              <span className='name'>
                {truncateText(item.name, 13) || "Unnamed"}

                {item.isVerifiedUser && (
                  <img
                    className='realpass-verified-name-suffix ml-2 mb-1'
                    src={verifiedImg}
                  />
                )}
              </span>
              <div className='meta'>
                <strong>{capitalize(item.eventRole)}</strong>
                {item.eventRoleStatus === "pending" && (
                  <small> (Pending)</small>
                )}
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  });
}
