import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { AuthContext } from "Auth.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Autosuggest from "react-autosuggest";

// import { RandomHash } from "random-hash";
import _, { set } from "lodash";

// reactstrap components
import {
  Alert,
  Button,
  Badge,
  Card,
  Container,
  Row,
  Col,
  Input,
  InputGroupText,
  InputGroup,
  Label,
  FormGroup,
  UncontrolledTooltip,
  Table,
  Toast,
  ToastBody,
  Spinner,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";

// core components
import TopNavbar from "components/Navbars/TopNavbar.js";
import Loader from "components/Loaders/Loader.js";

import ScaleLoader from "react-spinners/ScaleLoader";
import BarLoader from "react-spinners/BarLoader";

import ManageEventButton from "components/Buttons/ManageEventButton.js";

import MarkdownIt from "markdown-it";
// import MdEditor from "react-markdown-editor-lite";
// import "react-markdown-editor-lite/lib/index.css";

import url from "url";

import Web3 from "web3";

import {
  truncateAddressShort,
  truncateText,
  validEthAddress,
  checksumEthAddress,
  notify,
  formatPrettyNumber,
} from "utils/Utils.js";

import {
  getEventAccess,
  userUpdateEventAccessKey,
  getEvent,
  countEventUsers,
  getEventBasics,
  userDeregisterEvent,
  validateInviteCode,
  retrieveInviteCodeUsed,
  userReverifyEvent,
  creatorCheckinUser,
} from "utils/Api.js";

import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.compat.css";

import ReactMarkdown from "react-markdown";

import { Form, Field } from "react-final-form";

import QrReader from "react-qr-reader";

import Moment from "react-moment";
import moment from "moment-timezone";

const {
  bgAbstractLine01,
  bgAbstractLine02,
  bgAbstractLine03,
  bgAbstractLine04,
  bgAbstractLine05,
  defaultProfileImg,
  logoWhiteImg,
  passChipImg,
  defaultBoxGrayImg,
  verifiedImg,
  nsfwImg,
  ethImg,
  ethWhiteImg,
  polygonImg,
  polygonWhiteImg,
  defaultImg,
  defaultEventImg,
  defaultPassImg,
  qrScanImg,
  qrScanPlainImg,
  qrScanLaserImg,
  qrScanAccessImg,
  qrScanSuccessImg,
  qrScanFailedImg,
  calImg,
  gCalImg,
  outlookImg,
  ChainLogos,
  ChainOptions,
  EventCategoryOptions,
  USDateOption,
  USDateTimeOption,
  USDateTimeShortOption,
  UserRoleTypes,
} = require("utils/Variables.js");

let nfevent, nfaccess, nfprofile;

export default function EventCheckin() {
  const navigate = useNavigate();

  const { currentUser, currentAuthToken, currentUserInfo, setCurrentUserInfo } =
    useContext(AuthContext);

  const currentUrl = new URL(window.location.href);

  const hasAdminRole = currentUserInfo?.hasAdminRole;
  const hasCreatorRole = currentUserInfo?.hasCreatorRole;

  const [creatorWallet, setCreatorWallet] = useState(
    currentUserInfo?.ethAddress
  );

  var context = new AudioContext();
  var volume = context.createGain();
  var successOscillator = context.createOscillator();
  successOscillator.type = "sine";
  successOscillator.frequency.value = 800;
  successOscillator.detune.value = 10;
  successOscillator.connect(volume);

  var failedOscillator = context.createOscillator();
  failedOscillator.type = "square";
  failedOscillator.frequency.value = 100;
  failedOscillator.detune.value = 10;
  failedOscillator.connect(volume);
  volume.gain.value = 0.1;
  volume.connect(context.destination);

  const { eventId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [walletAddress, setWalletAddress] = useState(
    searchParams.get("wallet")?.toLowerCase() || ""
  );
  const [accessHashCode, setAccessHashCode] = useState(
    searchParams.get("ahash") || ""
  );

  const [pageLoading, setPageLoading] = useState(false);

  const [isEventActiveCreator, setIsEventActiveCreator] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [isCohost, setIsCohost] = useState(false);
  const [isPanelist, setIsPanelist] = useState(false);

  const [spotsFull, setSpotsFull] = useState();
  const [maxParticipants, setMaxParticipants] = useState();
  const [totalReg, setTotalReg] = useState();
  const [totalCheckin, setTotalCheckin] = useState();

  const [accessFound, setAccessFound] = useState(false);
  const [accessCreatedAt, setAccessCreatedAt] = useState();
  const [accessValid, setAccessValid] = useState();
  const [grantedByCreator, setGrantedByCreator] = useState(false);

  const [passVerified, setPassVerified] = useState();

  const [userProfileName, setUserProfileName] = useState();
  const [userProfileImage, setUserProfileImage] = useState();

  const [isEventCreator, setIsEventCreator] = useState(false);
  const [ownerName, setOwnerName] = useState();
  const [ownerEthAddress, setOwnerEthAddress] = useState();
  const [isVerifiedUser, setIsVerifiedUser] = useState(false);

  const [passName, setPassName] = useState();
  const [passTokenId, setPassTokenId] = useState();
  const [passSymbol, setPassSymbol] = useState();
  const [passImage, setPassImage] = useState();
  const [passType, setPassType] = useState();
  const [passTitle, setPassTitle] = useState();
  const [freePass, setFreePass] = useState(false);
  const [passContract, setPassContract] = useState();
  const [requireUniquePassId, setRequireUniquePassId] = useState(true);

  const [blockchain, setBlockchain] = useState();
  const [tokenUrl, setTokenUrl] = useState();

  const [eventImage, setEventImage] = useState();
  const [bannerImage, setBannerImage] = useState();
  const [eventName, setEventName] = useState();
  const [eventDescription, setEventDescription] = useState();
  const [eventStartDate, setEventStartDate] = useState();
  const [eventEndDate, setEventEndDate] = useState();
  const [eventEnded, setEventEnded] = useState();

  const [isVirtual, setIsVirtual] = useState(true);
  const [eventLocation, setEventLocation] = useState();

  const [isPrivate, setIsPrivate] = useState();
  const [isNSFW, setIsNSFW] = useState();
  const [isCancelled, setIsCancelled] = useState(false);
  const [isPublished, setIsPublished] = useState(true);
  const [isCheckinEnabled, setIsCheckinEnabled] = useState();

  const [scanActive, setScanActive] = useState(false);
  const [scanStatus, setScanStatus] = useState("scanning");
  const [facingUser, setFacingUser] = useState(false);
  const [manualCheck, setManualCheck] = useState(false);

  const [passError, setPassError] = useState();
  const [eventError, setEventError] = useState();

  const fetchEvent = async () => {
    try {
      setPageLoading(true);
      const eventRes = await getEvent(currentAuthToken, eventId);

      if (
        !hasAdminRole &&
        !(
          hasCreatorRole &&
          (eventRes?.isOwner || eventRes?.isCohost || eventRes?.isPanelist)
        )
      ) {
        navigate(`/event/${eventId}`);
      }

      let contract, uniqueId, chain;

      const owner = eventRes?.owner;
      setOwnerEthAddress(owner?.ethAddress);
      setIsOwner(eventRes?.isOwner);
      setIsCohost(eventRes?.isCohost);
      setIsPanelist(eventRes?.isPanelist);
      setIsEventActiveCreator(
        hasCreatorRole &&
          (eventRes?.isOwner || eventRes?.isCohost || eventRes?.isPanelist)
      );

      setIsCancelled(eventRes?.isCancelled);
      setIsPublished(eventRes?.isPublished);

      setEventName(eventRes?.name);

      setEventImage(eventRes?.imageUrl);
      setBannerImage(eventRes?.bannerUrl);

      const start = new Date(eventRes?.startDateTime);
      const end = new Date(eventRes?.endDateTime);
      setEventEnded(end?.getTime() < new Date().getTime());

      const dateTZOption = JSON.parse(JSON.stringify(USDateOption));

      dateTZOption.timeZone = eventRes?.timezone;

      const startTimeShort = `${start?.toLocaleDateString(
        "en-US",
        dateTZOption
      )} (${moment().tz(eventRes?.timezone)?.zoneAbbr()})`;

      const endTimeShort = `${end?.toLocaleDateString(
        "en-US",
        dateTZOption
      )} (${moment().tz(eventRes?.timezone)?.zoneAbbr()})`;

      setEventStartDate(startTimeShort);
      setEventEndDate(endTimeShort);

      setIsPrivate(eventRes?.isPrivate);
      setIsNSFW(eventRes?.isNSFW);
      setIsCheckinEnabled(eventRes?.isCheckinEnabled);

      setIsVirtual(eventRes?.isVirtual);
      setEventLocation(eventRes?.location);

      setMaxParticipants(eventRes?.maxParticipants);
      setTotalReg(eventRes?.totalRegistered);
      setTotalCheckin(eventRes?.totalCheckin);
      setSpotsFull(
        eventRes?.maxParticipants >= 0 &&
          eventRes?.totalRegistered >= eventRes?.maxParticipants
      );

      setEventError();
      //   eventQuery.equalTo("objectId", eventId);
      //   nfevent = await eventQuery.first();
      //   setPageLoading(false);
      //   if (!nfevent) {
      //     navigate(`/account`);
      //   }
      //   if (nfevent.get("creatorWallet") !== creatorWallet) {
      //     navigate(`/event/${eventId}`);
      //   }
      //   const eName = nfevent.get("name");
      //   const eDesc = nfevent.get("description");
      //   const eImage = nfevent.get("eventImage")?.url();
      //   const contract = nfevent.get("passContract");
      //   const endTime = nfevent.get("endDateTime");
      //   const nsfw = nfevent.get("isNSFW");
      //   const creator = nfevent.get("creator");
      //   const uniqueId = nfevent.get("requireUniquePassId");
      //   //TODO: remove rinkeby or mumbai
      //   const chain = nfevent.get("blockchain") === "eth" ? "rinkeby" : "mumbai";
      //   // const chain = nfevent.get("blockchain");
      //   setEventName(eName);
      //   setEventDescription(
      //     `${eDesc?.substring(0, 50)}${eDesc?.length > 50 ? "..." : ""}`
      //   );
      //   setEventImage(eImage);
      //   setPassContract(contract);
      //   setBlockchain(chain);
      //   setRequireUniquePassId(uniqueId);
      //   setIsNSFW(nsfw);
      //   if (endTime?.getTime() <= new Date().getTime()) {
      //     setEventEnded(true);
      //   }
      //   if (creator) {
      //     creatorProfileQuery.equalTo("objectId", creator.id);
      //     const creatorRes = await creatorProfileQuery.first();
      //     if (creatorRes) {
      //       setIsEventCreator(
      //         currentUser &&
      //           currentUser.id &&
      //           currentUser.id === creatorRes.get("user")?.id
      //       );
      //       setCreatorName(creatorRes?.get("name"));
      //       setCreatorEthAddress(creatorRes?.get("ethAddress"));
      //       verifiedQuery.equalTo("profile", creatorRes);
      //       const verifiedRes = await verifiedQuery.first();
      //       setIsVerifiedUser(verifiedRes?.get("verified"));
      //     }
      //   }
      //   return { contract, uniqueId, chain };
    } catch (err) {
      console.error(err.message);
      if (err.response?.status === 404) {
        navigate("/404");
      } else {
        notify("Unable to fetch event data.", "error");
        setEventError(
          "This is event currently is not available. You can come back later."
        );
      }
    } finally {
      setPageLoading(false);
    }
  };

  const fetchTotalParticipants = async () => {
    try {
      let count = await countEventUsers(eventId);
      const totalReg = count?.totalRegistered;
      const totalCheckin = count?.totalCheckin;

      if (isNaN(totalReg)) {
        totalReg = 0;
      }

      if (isNaN(totalCheckin)) {
        totalReg = 0;
      }

      setTotalReg(totalReg);
      setTotalCheckin(totalCheckin);

      setSpotsFull(maxParticipants >= 0 && totalReg >= maxParticipants);
    } catch (err) {
      console.error(err.message);
    }
  };

  const checkAccess = async (wallet, hash) => {
    try {
      if (isPublished && !eventEnded && isCheckinEnabled) {
        setAccessFound(false);
        setScanStatus("loading");

        console.log("wallet: ", wallet);
        console.log("hash: ", hash);

        const approve = await creatorCheckinUser(
          currentAuthToken,
          eventId,
          wallet,
          hash
        );

        console.log("approve: ", JSON.stringify(approve));

        setAccessFound(approve);
        setScanStatus("scanning");

        return approve;
      }
      //   const { contract, uniqueId, chain } = await fetchEvent();
      //   setAccessFound(false);
      //   setScanStatus("loading");
      //   setPassVerified();
      //   setAccessValid();
      //   setAccessCreatedAt();
      //   setUserProfileName();
      //   setUserProfileImage();
      //   const res = await Moralis.Cloud.run("creatorCheckAccess", {
      //     eventId: eventId,
      //     walletAddress: wallet,
      //     accessHash: hash,
      //   });
      //   if (!res) {
      //     throw { message: "Unable to find the access." };
      //   }
      //   let checkAccessSuccess;
      //   setAccessFound(true);
      //   const createdAt = res.createdAt;
      //   const valid = res.isValid;
      //   const tokenId = res.tokenId;
      //   const creatorGrantFlag = res.grantedByCreator;
      //   setAccessCreatedAt(
      //     createdAt?.toLocaleDateString("en-US", USDateOption)
      //   );
      //   setAccessValid(valid);
      //   setPassTokenId(tokenId);
      //   setGrantedByCreator(creatorGrantFlag);
      //   userProfileQuery.equalTo("ethAddress", wallet);
      //   nfprofile = await userProfileQuery.first();
      //   if (nfprofile) {
      //     const pName = nfprofile.get("name");
      //     const pImage = nfprofile.get("image")?.url();
      //     setUserProfileName(pName);
      //     setUserProfileImage(pImage);
      //   }
      //   if (creatorGrantFlag) {
      //     setPassVerified(true);
      //     checkAccessSuccess = true;
      //   } else {
      //     if (contract) {
      //       setFreePass(false);
      //       if (chain === "eth") {
      //         setTokenUrl(`https://etherscan.io/address/${contract}`);
      //       }
      //       if (chain === "matic") {
      //         setTokenUrl(`https://polygonscan.com/address/${contract}`);
      //       }
      //       let nftImage;
      //       const options01 = {
      //         chain: chain,
      //         address: contract,
      //         limit: 1,
      //       };
      //       try {
      //         const nfts = await Moralis.Web3API.token.getAllTokenIds(options01);
      //         if (nfts?.result?.length > 0) {
      //           const nft = nfts.result[0];
      //           setPassName(nft?.name);
      //           setPassType(nft?.contract_type);
      //           nftImage = JSON.parse(nft?.metadata)?.image?.replace(
      //             "ipfs://",
      //             "https://gateway.moralisipfs.com/ipfs/"
      //           );
      //         } else {
      //           setPassError("Cannot find the smart contract.");
      //         }
      //       } catch (err) {
      //         console.error(err.message);
      //         setPassError("Unable to get the smart contract data.");
      //       }
      //       if (uniqueId) {
      //         const options02 = {
      //           chain: chain,
      //           address: contract,
      //           token_id: tokenId,
      //         };
      //         const nft = await Moralis.Web3API.token.getTokenIdMetadata(
      //           options02
      //         );
      //         if (nft?.owner_of === wallet && tokenId) {
      //           nftImage = JSON.parse(nft?.metadata)?.image?.replace(
      //             "ipfs://",
      //             "https://gateway.moralisipfs.com/ipfs/"
      //           );
      //           setPassVerified(true);
      //           checkAccessSuccess = true;
      //         } else {
      //           setPassVerified(false);
      //           checkAccessSuccess = false;
      //           // Invalidate Access
      //           const res = await Moralis.Cloud.run(
      //             "creatorInvalidateEventAccess",
      //             {
      //               eventId: eventId,
      //               walletAddress: walletAddress,
      //               accessHash: accessHashCode,
      //             }
      //           );
      //           setAccessValid(res?.isValid);
      //         }
      //       } else {
      //         const options02 = {
      //           chain: chain,
      //           address: wallet,
      //           token_address: contract,
      //         };
      //         const nfts = await Moralis.Web3API.account.getNFTsForContract(
      //           options02
      //         );
      //         if (nfts?.result?.length > 0) {
      //           const nft = nfts.result[0];
      //           nftImage = JSON.parse(nft?.metadata)?.image?.replace(
      //             "ipfs://",
      //             "https://gateway.moralisipfs.com/ipfs/"
      //           );
      //           setPassVerified(true);
      //           checkAccessSuccess = true;
      //         } else {
      //           setPassVerified(false);
      //           checkAccessSuccess = false;
      //           // Invalidate Access
      //           const res = await Moralis.Cloud.run(
      //             "creatorInvalidateEventAccess",
      //             {
      //               eventId: eventId,
      //               walletAddress: walletAddress,
      //               accessHash: accessHashCode,
      //             }
      //           );
      //           setAccessValid(res?.isValid);
      //         }
      //       }
      //       setPassImage(nftImage);
      //     } else {
      //       setFreePass(true);
      //       setPassVerified(true);
      //       checkAccessSuccess = true;
      //     }
      //   }
      //   setScanStatus("scanning");
      //   return checkAccessSuccess;
    } catch (err) {
      console.error(err.message);
      notify(err.message || "Unable to check access.", "error", 2000);

      setScanStatus("scanning");

      return false;
    }
  };

  const handleScan = async (data) => {
    try {
      if (data) {
        setScanStatus("loading");

        const url = new URL(data);

        if (url) {
          const urlPath = url.pathname;

          if (currentUrl.host !== url.host || currentUrl.pathname !== urlPath) {
            throw { message: `URL on the QR code is invalid.` };
          }

          const eventIdParam = /[^/]*$/.exec(urlPath)[0];

          if (eventIdParam !== eventId) {
            throw { message: `The event ID is invalid.` };
          }
          const params = url.searchParams;
          const wallet = params.get("wallet");
          const hash = params.get("ahash");
          const lowerAddress = wallet.toLowerCase();

          setWalletAddress(lowerAddress || "");
          setAccessHashCode(hash || "");

          if (!wallet) {
            throw { message: "The wallet address is missing." };
          }

          if (!hash || hash.length !== 7) {
            throw { message: "The QR code is invalid." };
          }

          const isAddress = validEthAddress(lowerAddress);

          if (!isAddress) {
            throw { message: "The wallet address is invalid." };
          }

          window.history.replaceState(null, null, url.search);

          const approved = await checkAccess(lowerAddress, hash);

          if (approved) {
            setScanStatus("success");
            successOscillator.start();
            // Beep for 500 milliseconds
            setTimeout(function () {
              successOscillator.stop();
            }, 100);
          } else {
            setScanStatus("failed");
            failedOscillator.start();
            // Beep for 500 milliseconds
            setTimeout(function () {
              failedOscillator.stop();
            }, 100);
          }
        }
      }
    } catch (err) {
      console.error(err.message);

      failedOscillator.start();
      // Beep for 500 milliseconds
      setTimeout(function () {
        failedOscillator.stop();
      }, 100);

      setScanStatus("failed");
      notify(err.message || "Unable to scan code.", "error", 2000);
    } finally {
      setTimeout(() => setScanStatus("scanning"), 1000);
    }
  };

  const handleScanError = (err) => {
    if (err.message === "Permission denied") {
      setScanStatus("access");
    }
  };

  useEffect(() => {
    if (!hasAdminRole && !hasCreatorRole) {
      navigate(`/event/${eventId}`);
    }

    fetchEvent();
  }, []);

  return (
    <>
      <BarLoader
        loading={pageLoading}
        cssOverride={{
          width: "100%",
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          zIndex: "1031",
          backgroundColor: "#fff",
          opacity: "1",
        }}
      />
      <TopNavbar />
      <ReactNotifications />
      <div className="wrapper event-page">
        {!pageLoading &&
          (isCancelled ? (
            <Alert
              color="dark"
              className="mb-0 mt-2 text-center rounded-0 bg-dark"
              style={{ zIndex: "1000" }}
            >
              <span className="h5 text-white">
                ☔️ This event has been cancelled by the{" "}
                <a
                  href={`/${ownerEthAddress}`}
                  target="_blank"
                  className="text-info"
                >
                  <u>creator</u>
                </a>
                . You no longer have the access.
              </span>
            </Alert>
          ) : eventError ? (
            <Alert
              color="dark"
              className="mb-0 mt-2 text-center rounded-0 bg-dark"
              style={{ zIndex: "1000" }}
            >
              <span className="h5 text-white">
                <FontAwesomeIcon
                  icon="fa-triangle-exclamation"
                  className="mr-2"
                />
                {eventError}
              </span>
            </Alert>
          ) : !isPublished ? (
            <Alert
              color="dark"
              className="mb-0 mt-2 text-center rounded-0 bg-dark"
              style={{ zIndex: "1000" }}
            >
              <span className="h5 text-white">
                <FontAwesomeIcon icon="fa-bell" className="mr-2" />
                The event is not published. It's only visible to admin.
              </span>
            </Alert>
          ) : eventEnded ? (
            <Alert
              color="dark"
              className="mb-0 mt-2 text-center rounded-0 bg-dark"
              style={{ zIndex: "1000" }}
            >
              <span className="h5 text-white">
                <FontAwesomeIcon icon="fa-bell" className="mr-2" />
                This event has already ended.
              </span>
            </Alert>
          ) : !isCheckinEnabled ? (
            <Alert
              color="dark"
              className="mb-0 mt-2 text-center rounded-0 bg-dark"
              style={{ zIndex: "1000" }}
            >
              <span className="h5 text-white">
                <FontAwesomeIcon icon="fa-bell" className="mr-2" />
                Event checkin is disabled.
              </span>
            </Alert>
          ) : (
            isNSFW && (
              <Alert
                color="dark"
                className="mb-0 mt-2 text-center rounded-0 bg-dark"
                style={{ zIndex: "1000" }}
              >
                <span className="h5 text-white">
                  <FontAwesomeIcon icon="fa-bell" className="mr-2" />
                  This event is NSFW and has age restriction.
                </span>
              </Alert>
            )
          ))}
        <section className="section-event-cover-half section-shaped my-0">
          <img
            alt="..."
            src={bannerImage || bgAbstractLine01}
            className="bg-image w-100"
          />
        </section>
        {(hasAdminRole || (hasCreatorRole && (isOwner || isCohost))) && (
          <section className="section section-main">
            <Container>
              <div className="card-event">
                <div className="pb-2">
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="7">
                      <div className="card-event-image-md">
                        <img
                          alt="..."
                          className="event-image-lg"
                          src={eventImage || defaultBoxGrayImg}
                        ></img>
                      </div>
                    </Col>
                    <Col
                      className="order-lg-3 text-lg-right align-self-lg-center"
                      lg="2"
                    >
                      <div className="card-event-actions justify-content-center py-lg-4 mt-lg-0 mt-md-2 mt-sm-1">
                        <ManageEventButton eventId={eventId} />
                      </div>
                    </Col>

                    <Col className="order-lg-1" lg="2">
                      <div className="card-event-stats d-flex justify-content-center">
                        <div>
                          <small className="heading">
                            {formatPrettyNumber(totalCheckin) || 0}
                          </small>
                          <small className="description">Checkin</small>
                        </div>
                        <div>
                          <small className="heading">
                            {formatPrettyNumber(totalReg) || 0}
                          </small>
                          <small className="description">Joined</small>
                        </div>
                        <div>
                          <small className="heading">
                            {!isNaN(maxParticipants) ? (
                              maxParticipants >= 0 ? (
                                formatPrettyNumber(maxParticipants)
                              ) : (
                                <>
                                  <FontAwesomeIcon
                                    icon="fa-infinity"
                                    id="unlimitedSpots"
                                    style={{ cursor: "pointer" }}
                                  />

                                  <UncontrolledTooltip
                                    placement="top"
                                    target="unlimitedSpots"
                                    trigger="hover"
                                  >
                                    Unlimited Spots{" "}
                                  </UncontrolledTooltip>
                                </>
                              )
                            ) : (
                              <>TBA</>
                            )}
                          </small>
                          <span className="description">
                            Spots{" "}
                            {spotsFull && (
                              <sup>
                                <small className="text-warning">FULL</small>
                              </sup>
                            )}
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center mt-lg-3">
                    <span className="display-4 text-dark">
                      {eventName || "Unnamed"}
                    </span>
                    {isPrivate && (
                      <span
                        id="privateEventTag"
                        className="text-white ml-2"
                        style={{ cursor: "pointer" }}
                      >
                        <sup>
                          <Badge color="secondary" className="text-default">
                            Private
                          </Badge>
                        </sup>
                      </span>
                    )}
                    {isPrivate && (
                      <UncontrolledTooltip
                        placement="top"
                        target="privateEventTag"
                        trigger="hover"
                      >
                        This is a private event.
                      </UncontrolledTooltip>
                    )}
                    <div className="ml-lg-9 mr-lg-9 mt-3 mb-4">
                      <span className="mx-2">
                        <FontAwesomeIcon
                          icon="fa-calendar-days"
                          className="mr-2"
                        />

                        <strong className="text-info">
                          {eventStartDate ? eventStartDate : "TBA"}
                        </strong>
                        {eventEnded && (
                          <small className="text-warning"> ENDED</small>
                        )}
                      </span>
                      <span className="mx-3">
                        {isVirtual ? (
                          <i className="ni ni-planet mr-2 align-middle" />
                        ) : (
                          <FontAwesomeIcon
                            icon="fa-location-dot"
                            className="mr-2"
                          />
                        )}
                        <strong className="text-info">
                          {eventLocation?.name ? (
                            isVirtual ? (
                              <>
                                <a
                                  href={
                                    eventLocation?.name.includes("https://") ||
                                    eventLocation?.name.includes("http://")
                                      ? eventLocation?.name
                                      : `https://${eventLocation?.name}`
                                  }
                                  target="_blank"
                                  rel="external nofollow noopener"
                                >
                                  Virtual
                                </a>
                              </>
                            ) : (
                              <>{eventLocation?.name}</>
                            )
                          ) : (
                            <>
                              {isVirtual ? "Virtual" : "IRL"}{" "}
                              <small className="text-primary">(TBA)</small>
                            </>
                          )}
                        </strong>
                      </span>
                    </div>
                  </div>

                  <hr />
                  {hasCreatorRole && (isOwner || isCohost) ? (
                    <div className="p-0">
                      <Row>
                        <Col xl="5" lg="5" md="6">
                          <div className="mt-1 text-center">
                            <h4 className="my-3 text-center">
                              Scan Access QR Code
                              <FontAwesomeIcon
                                icon="fa-qrcode"
                                className="ml-2"
                              />
                            </h4>
                            {scanActive ? (
                              scanStatus === "access" ? (
                                <img
                                  src={qrScanAccessImg}
                                  style={{
                                    width: "90%",
                                    border: "1px solid black",
                                  }}
                                />
                              ) : scanStatus === "success" ? (
                                <img
                                  src={qrScanSuccessImg}
                                  style={{
                                    width: "90%",
                                    border: "1px solid black",
                                  }}
                                />
                              ) : scanStatus === "failed" ? (
                                <img
                                  src={qrScanFailedImg}
                                  style={{
                                    width: "90%",
                                    border: "1px solid black",
                                  }}
                                />
                              ) : scanStatus === "loading" ? (
                                <>
                                  <img
                                    src={qrScanLaserImg}
                                    className="qr-scanning"
                                    style={{
                                      width: "70%",
                                      zIndex: 10,
                                    }}
                                  />
                                  <img
                                    src={qrScanPlainImg}
                                    style={{
                                      width: "90%",
                                      opacity: "0.6",
                                      border: "1px solid black",
                                    }}
                                  />
                                </>
                              ) : (
                                <QrReader
                                  className="mt-3"
                                  delay={1000}
                                  resolution={1200}
                                  onError={handleScanError}
                                  onScan={handleScan}
                                  style={{
                                    width: "90%",
                                    textAlign: "center",
                                    margin: "auto",
                                  }}
                                  facingMode={
                                    facingUser ? "user" : "environment"
                                  }
                                />
                              )
                            ) : (
                              <img
                                src={qrScanImg}
                                style={{
                                  width: "90%",
                                  opacity: "0.6",
                                  border: "1px solid black",
                                }}
                              />
                            )}
                            <div className="my-3">
                              <span id="manualButton">
                                <Button
                                  className="btn-icon-only"
                                  outline={!manualCheck}
                                  size="sm"
                                  color="primary"
                                  type="button"
                                  onClick={() => setManualCheck(!manualCheck)}
                                  disabled={
                                    !isPublished ||
                                    eventEnded ||
                                    !isCheckinEnabled
                                  }
                                >
                                  <FontAwesomeIcon icon="fa-keyboard" />
                                </Button>
                              </span>
                              {(!isPublished ||
                                eventEnded ||
                                !isCheckinEnabled) && (
                                <UncontrolledTooltip
                                  placement="top"
                                  target="manualButton"
                                  trigger="hover"
                                >
                                  Checkin is unavailable
                                </UncontrolledTooltip>
                              )}
                              <span id="scanButton" className="mx-2">
                                <Button
                                  outline={!scanActive}
                                  color="primary"
                                  type="button"
                                  size="md"
                                  onClick={() => setScanActive(!scanActive)}
                                  disabled={
                                    !isPublished ||
                                    eventEnded ||
                                    !isCheckinEnabled
                                  }
                                >
                                  {scanActive ? (
                                    <>
                                      <FontAwesomeIcon
                                        icon="fa-qrcode"
                                        className="mr-2"
                                      />
                                      Stop
                                    </>
                                  ) : (
                                    <>
                                      <FontAwesomeIcon
                                        icon="fa-camera"
                                        className="mr-2"
                                      />
                                      Scan
                                    </>
                                  )}
                                </Button>
                              </span>
                              {(!isPublished ||
                                eventEnded ||
                                !isCheckinEnabled) && (
                                <UncontrolledTooltip
                                  placement="top"
                                  target="scanButton"
                                  trigger="hover"
                                >
                                  Checkin is unavailable
                                </UncontrolledTooltip>
                              )}
                              <span id="camfaceButton">
                                <Button
                                  className="btn-icon-only"
                                  size="sm"
                                  outline={!facingUser}
                                  color="primary"
                                  type="button"
                                  onClick={() => setFacingUser(!facingUser)}
                                  disabled={
                                    !isPublished ||
                                    eventEnded ||
                                    !isCheckinEnabled
                                  }
                                >
                                  <FontAwesomeIcon icon="fa-camera-rotate" />
                                </Button>
                              </span>
                              {(!isPublished ||
                                eventEnded ||
                                !isCheckinEnabled) && (
                                <UncontrolledTooltip
                                  placement="top"
                                  target="camfaceButton"
                                  trigger="hover"
                                >
                                  Checkin is unavailable
                                </UncontrolledTooltip>
                              )}
                            </div>
                          </div>
                        </Col>
                        {manualCheck && (
                          <Col xl="7" lg="7" md="6">
                            <Form
                              onSubmit={() =>
                                checkAccess(walletAddress, accessHashCode)
                              }
                              validate={(values) => {
                                const errors = {};

                                if (!validEthAddress(walletAddress)) {
                                  errors.wallet =
                                    "Wallet address must be a valid address.";
                                }

                                if (!accessHashCode) {
                                  errors.code = "Access hash code is required.";
                                }

                                if (accessHashCode?.length !== 7) {
                                  errors.code =
                                    "Access hash code must be a 7-digit code.";
                                }

                                return errors;
                              }}
                              render={({
                                handleSubmit,
                                values,
                                submitting,
                                validating,
                                valid,
                              }) => (
                                <div className="px-2 m-2">
                                  <form onSubmit={handleSubmit}>
                                    <Row className="mt-4 mb-4">
                                      <Col className="mr-3">
                                        <div className="mt-4 mb-4">
                                          <h5>
                                            <strong>Enter Access Detail</strong>
                                          </h5>
                                        </div>

                                        <div className="mt-4 mb-4">
                                          <FormGroup>
                                            <Field name="wallet">
                                              {({ input, meta }) => (
                                                <>
                                                  <Label for="wallet">
                                                    <div className="required">
                                                      <strong>
                                                        Wallet Address
                                                      </strong>
                                                    </div>

                                                    <small className="text-muted ml-2">
                                                      Lowercase wallet address
                                                      begins with "0x..."
                                                    </small>
                                                  </Label>
                                                  <Input
                                                    {...input}
                                                    id="name"
                                                    value={walletAddress}
                                                    onChange={(e) => {
                                                      setWalletAddress(
                                                        e.target.value
                                                      );
                                                    }}
                                                    placeholder={
                                                      walletAddress ||
                                                      "Enter the wallet address 0x12345..."
                                                    }
                                                    type="text"
                                                  />

                                                  {meta.error &&
                                                    meta.touched && (
                                                      <small className="text-danger">
                                                        <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                                        {meta.error}
                                                      </small>
                                                    )}
                                                </>
                                              )}
                                            </Field>
                                          </FormGroup>
                                        </div>

                                        <div className="mt-4 mb-4">
                                          <FormGroup>
                                            <Field name="code">
                                              {({ input, meta }) => (
                                                <>
                                                  <Label for="code">
                                                    <div className="required">
                                                      <strong>
                                                        Access Hash Code
                                                      </strong>
                                                    </div>

                                                    <small className="text-muted ml-2">
                                                      7-digit numeric access
                                                      hash code
                                                    </small>
                                                  </Label>
                                                  <Input
                                                    {...input}
                                                    id="code"
                                                    value={accessHashCode}
                                                    onChange={(e) => {
                                                      setAccessHashCode(
                                                        e.target.value.toLowerCase()
                                                      );
                                                    }}
                                                    placeholder={
                                                      accessHashCode ||
                                                      "Enter the 7-digit access hash code"
                                                    }
                                                    type="text"
                                                  />

                                                  {meta.error &&
                                                    meta.touched && (
                                                      <small className="text-danger">
                                                        <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                                        {meta.error}
                                                      </small>
                                                    )}
                                                </>
                                              )}
                                            </Field>
                                          </FormGroup>
                                        </div>

                                        <div className="mt-4">
                                          <span id="checkinButton">
                                            <Button
                                              className="mt-3"
                                              outline
                                              color="primary"
                                              type="submit"
                                              size="md"
                                              disabled={
                                                !isPublished ||
                                                eventEnded ||
                                                !isCheckinEnabled ||
                                                !valid
                                              }
                                            >
                                              <FontAwesomeIcon
                                                icon="fa-user-check"
                                                className="mr-2"
                                              />
                                              Checkin
                                            </Button>
                                          </span>

                                          {(!isPublished ||
                                            eventEnded ||
                                            !isCheckinEnabled) && (
                                            <UncontrolledTooltip
                                              placement="top"
                                              target="checkinButton"
                                              trigger="hover"
                                            >
                                              Checkin is unavailable
                                            </UncontrolledTooltip>
                                          )}
                                        </div>
                                      </Col>
                                    </Row>
                                  </form>
                                </div>
                              )}
                            />
                          </Col>
                        )}
                        <Col>
                          <Row className="mt-5 mb-4">
                            <Col>
                              <Card
                                className="shadow"
                                style={{ minHeight: "450px" }}
                              >
                                <CardBody>
                                  <h5>
                                    <strong>🎩 Event Checkin</strong>
                                  </h5>
                                  <hr className="m-0" />
                                  <div className="mt-4 mb-4">
                                    {accessFound ? (
                                      <>
                                        <div className="ml-lg-9 mr-lg-9 px-lg-6 px-md-5 px-sm-4 mt-5 mb-5">
                                          <div className="container mt-3">
                                            <div className="card bg-primary justify-content-center mt-3 mb-6">
                                              <div className="row">
                                                <div className="col-lg-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                  <div className="card-block">
                                                    <CardBody className="text-white">
                                                      <Row className="m-1">
                                                        {grantedByCreator ? (
                                                          <div className="mt-3 ml-3">
                                                            <h4 className="display-4 text-center text-white">
                                                              <FontAwesomeIcon
                                                                icon="fa-ticket"
                                                                className="mr-2"
                                                              />
                                                              CREATOR GRANTED
                                                            </h4>
                                                          </div>
                                                        ) : freePass ? (
                                                          <div className="mt-3 ml-3">
                                                            <h3 className="display-3 text-center text-white">
                                                              <FontAwesomeIcon
                                                                icon="fa-ticket"
                                                                className="mr-2"
                                                              />
                                                              FREE PASS
                                                            </h3>
                                                          </div>
                                                        ) : (
                                                          passContract && (
                                                            <>
                                                              <Col className="col-3 mx-0">
                                                                <img
                                                                  alt="..."
                                                                  style={{
                                                                    width:
                                                                      "100%",
                                                                  }}
                                                                  className="mt-2 mb-2 rounded"
                                                                  src={
                                                                    passImage ||
                                                                    defaultBoxGrayImg
                                                                  }
                                                                ></img>
                                                              </Col>
                                                              <Col className="col-9">
                                                                <div className="mt-xl-3 mt-lg-3 mt-md-3 mt-sm-2 mt-xs-2">
                                                                  <h5 className="text-white">
                                                                    {truncateText(
                                                                      passName,
                                                                      20
                                                                    )}
                                                                    <span className="mr-2">
                                                                      {passTokenId ? (
                                                                        <>
                                                                          {" #"}
                                                                          {
                                                                            passTokenId
                                                                          }
                                                                        </>
                                                                      ) : (
                                                                        ""
                                                                      )}
                                                                    </span>
                                                                  </h5>
                                                                </div>
                                                                <span>
                                                                  <a
                                                                    href={
                                                                      tokenUrl
                                                                    }
                                                                    rel="external nofollow noopener"
                                                                    target="_blank"
                                                                  >
                                                                    <h6 className="text-white">
                                                                      {blockchain ===
                                                                        "eth" && (
                                                                        <img
                                                                          src={
                                                                            ethWhiteImg
                                                                          }
                                                                          className="icon-eth mr-1"
                                                                        />
                                                                      )}
                                                                      {blockchain ===
                                                                        "matic" && (
                                                                        <img
                                                                          src={
                                                                            polygonWhiteImg
                                                                          }
                                                                          className="icon-polygon mr-1"
                                                                        />
                                                                      )}
                                                                      <small>
                                                                        {passType && (
                                                                          <>
                                                                            {
                                                                              passType
                                                                            }
                                                                            :{" "}
                                                                          </>
                                                                        )}
                                                                      </small>
                                                                      <span className="text-info">
                                                                        {truncateAddressShort(
                                                                          passContract
                                                                        )}
                                                                      </span>
                                                                      <FontAwesomeIcon
                                                                        icon="arrow-up-right-from-square"
                                                                        className="ml-1 text-muted fa-sm"
                                                                      />
                                                                    </h6>
                                                                  </a>
                                                                </span>
                                                              </Col>
                                                            </>
                                                          )
                                                        )}

                                                        {passError && (
                                                          <div>
                                                            <span className="text-danger">
                                                              <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                                              {passError}
                                                            </span>
                                                          </div>
                                                        )}
                                                      </Row>
                                                    </CardBody>
                                                    <hr className="text-white border-white mx-3 mt-0 mb-1" />
                                                    <div className="p-2">
                                                      <span className="float-left ml-2 mb-4 text-white">
                                                        <a
                                                          href={`/${walletAddress}`}
                                                        >
                                                          <img
                                                            alt="..."
                                                            className="rounded-circle profile-image-xs ml-3 mr-1"
                                                            src={
                                                              userProfileImage ||
                                                              defaultProfileImg
                                                            }
                                                          />
                                                          <span className="text-info ml-2 mr-1">
                                                            {userProfileName ||
                                                              "Unnamed"}
                                                          </span>{" "}
                                                        </a>
                                                        <small>
                                                          registered on{" "}
                                                          {accessCreatedAt}
                                                        </small>{" "}
                                                      </span>
                                                      <span className="float-right mt--5 mr--4 mb--5">
                                                        {passVerified ===
                                                          true &&
                                                        accessValid === true ? (
                                                          <FontAwesomeIcon
                                                            icon="fa-circle-check"
                                                            className="fa-8x text-success"
                                                          />
                                                        ) : passVerified ===
                                                            false ||
                                                          accessValid ===
                                                            false ? (
                                                          <FontAwesomeIcon
                                                            icon="fa-circle-xmark"
                                                            className="fa-8x text-danger"
                                                          />
                                                        ) : (
                                                          ""
                                                        )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <div
                                        className="text-center my-6"
                                        style={{ minHeight: "100px" }}
                                      >
                                        <h4 className="mt-7 text-muted">
                                          No access found.
                                        </h4>
                                      </div>
                                    )}
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div
                      className="text-center mt-5 mb-5"
                      style={{ minHeight: "100px" }}
                    >
                      <h4 className="mt-6 text-muted">
                        {pageLoading
                          ? "Checking creator role ..."
                          : "Only event creator can check in event."}
                      </h4>
                    </div>
                  )}
                </div>
              </div>
            </Container>
          </section>
        )}
      </div>
      {isCancelled && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            color: "white",
            zIndex: "999",
          }}
        ></div>
      )}
    </>
  );
}
