import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { AuthContext } from "Auth.js";

// reactstrap components
import {
  Alert,
  ButtonGroup,
  Button,
  Collapse,
  Card,
  Container,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  CardImg,
  CardBody,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Table,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";

import { Form, Field } from "react-final-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { notify } from "utils/Utils.js";

import { creatorCancelEvent, creatorCloneEvent } from "utils/Api.js";

import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.compat.css";

import Avatar from "boring-avatars";

import Loader from "components/Loaders/Loader.js";
import LoaderDots from "components/Loaders/LoaderDots.js";
import LoaderSection from "components/Loaders/LoaderSection.js";
import LoaderModal from "components/Loaders/LoaderModal.js";

import ScaleLoader from "react-spinners/ScaleLoader";
import BarLoader from "react-spinners/BarLoader";

import { SocialLinks } from "social-links";
import { set } from "lodash";

const socialLinks = new SocialLinks();

const {
  defaultFormImg,
  defaultFormBanner,
  defaultProfileImg,
  bgGradient,
  defaultBoxGrayImg,
  verifiedImg,
  ethImg,
  nsfwImg,
  mmImg,
  wcImg,
  pwImg,
} = require("utils/Variables.js");

export default function EventMore(props) {
  let { event, eventId, updateEvent, isEventActiveCreator } = props;

  const navigate = useNavigate();

  const { currentAuthToken, currentUser, currentUserInfo, setCurrentUserInfo } =
    useContext(AuthContext);

  const hasAdminRole = currentUserInfo?.hasAdminRole;
  const hasCreatorRole = currentUserInfo?.hasCreatorRole;
  const isVerifiedUser = currentUserInfo?.isVerifiedUser;

  const [dataSaving, setDataSaving] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  const [cancelling, setCancelling] = useState(false);
  const [cancelConfirmation, setCancelConfirmation] = useState("");
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const [cloning, setCloning] = useState(false);
  const [cloneModalOpen, setCloneModalOpen] = useState(false);

  const cloneEvent = async () => {
    if (!event?.isOwner) {
      return;
    }

    try {
      setCloning(true);

      const res = await creatorCloneEvent(currentAuthToken, eventId);

      setCloning(false);

      if (res?.eventId) {
        updateEvent({ ...event, ...res });
        notify("You have successfully cloned the event.", "success");
        setCloneModalOpen(false);

        setTimeout(() => navigate(`/event/${res?.eventId}`), 3000);
      } else {
        notify("Event cancellation failed.", "error");
      }
    } catch (err) {
      console.error(err.message);
      notify("Event cancellation failed.", "error");
    } finally {
    }
  };

  const cancelEvent = async () => {
    if (!event?.isOwner) {
      return;
    }

    try {
      setCancelling(true);

      const res = await creatorCancelEvent(currentAuthToken, eventId);

      if (res?.isCancelled) {
        updateEvent({ ...event, ...res });

        notify("Event has been cancelled.", "");
      } else {
        notify("Event cancellation failed.", "error");
      }
    } catch (err) {
      console.error(err.message);
      notify("Event cancellation failed.", "error");
    } finally {
      setCancelling(false);
      setCancelModalOpen(false);
    }
  };

  return (
    <>
      {(hasAdminRole || isEventActiveCreator) && (
        <>
          <Row className='mt-4 mb-4'>
            <Col xl='9' lg='10' md='11' sm='12' xs='12'>
              <span className='h5 text-primary'>
                <strong>Clone Event Settings</strong>
              </span>{" "}
              {!event?.isOwner && (
                <span className='text-warning'>(Owner Only)</span>
              )}
              <Alert className='my-3 bg-secondary px-3'>
                <Row>
                  <Col xl='9' lg='9' md='12' sm='12' xs='12'>
                    <div className='text-default'>
                      <p className='mr-2'>
                        Create a new event from <strong>{event?.name}</strong>.
                      </p>
                      <p>
                        <small>
                          Event details and settings will be copied to the new
                          event. However, existings team and members are not
                          going to be copied.
                        </small>
                      </p>
                    </div>
                  </Col>
                  <Col xl='3' lg='3' md='12' sm='12' xs='12'>
                    <Button
                      color='primary'
                      size='md'
                      onClick={() => setCloneModalOpen(true)}
                      className='btn-icon float-right'
                      disabled={!event?.isOwner}
                    >
                      Create a Clone
                    </Button>
                  </Col>
                </Row>
              </Alert>
            </Col>
          </Row>
          <hr />
          <Row className='mt-4 mb-4'>
            <Col xl='9' lg='10' md='11' sm='12' xs='12'>
              <span className='h5 text-danger'>
                <strong>☔️ Raincheck</strong>
              </span>{" "}
              {!event?.isOwner && (
                <span className='text-warning'>(Owner Only)</span>
              )}
              <Alert className='my-3 bg-secondary px-3'>
                <Row>
                  <Col xl='9' lg='9' md='12' sm='12' xs='12'>
                    <div className='text-default'>
                      <p className='mr-2'>
                        There has been a change of plan. You want to cancel{" "}
                        <strong>{event?.name}</strong>.
                      </p>
                      <p>
                        <small>
                          You will no longer be able to manage the event. All
                          your existing members will lose access to it.
                        </small>
                      </p>
                    </div>
                  </Col>
                  <Col xl='3' lg='3' md='12' sm='12' xs='12'>
                    <Button
                      color='danger'
                      size='md'
                      onClick={() => setCancelModalOpen(true)}
                      className='btn-icon float-right'
                      disabled={!event?.isOwner}
                    >
                      Cancel Event
                    </Button>
                  </Col>
                </Row>
              </Alert>
            </Col>
          </Row>

          {event?.isOwner && (
            <Modal isOpen={cloneModalOpen}>
              <div className='modal-header'>
                <strong className='modal-title' id='cloneModalLabel'>
                  Clone Event
                </strong>

                <button
                  aria-label='Close'
                  className=' close'
                  type='button'
                  onClick={() => setCloneModalOpen(false)}
                  disabled={cancelling}
                >
                  <span aria-hidden={true}>
                    <FontAwesomeIcon icon='fa-xmark' />
                  </span>
                </button>
              </div>

              <ModalBody>
                <div className='my-1'>
                  <span className='text-default'>
                    Would you like to clone <strong>{event?.name}</strong>?
                  </span>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  size='md'
                  color='primary'
                  type='button'
                  onClick={cloneEvent}
                  disabled={cloning}
                >
                  {cloning ? (
                    <>
                      <span className='mr-2'>Cloning</span>
                      <ScaleLoader
                        color='#eee'
                        loading={cloning}
                        height='10px'
                        cssOverride={{
                          display: "inline",
                        }}
                      />
                    </>
                  ) : (
                    "Confirm"
                  )}
                </Button>
                <Button
                  size='md'
                  color='link'
                  type='button'
                  onClick={() => {
                    if (!cloning) {
                      setCloneModalOpen(!cloneModalOpen);
                    }
                  }}
                  disabled={cloning}
                >
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          )}

          {event?.isOwner && (
            <Modal isOpen={cancelModalOpen}>
              <div className='modal-header'>
                <strong
                  className='text-danger modal-title'
                  id='deleteModalLabel'
                >
                  Event Cancellation
                </strong>

                <button
                  aria-label='Close'
                  className=' close'
                  type='button'
                  onClick={() => setCancelModalOpen(false)}
                  disabled={cancelling}
                >
                  <span aria-hidden={true}>
                    <FontAwesomeIcon icon='fa-xmark' />
                  </span>
                </button>
              </div>

              <ModalBody>
                <div className='my-1 text-default'>
                  <Label for='confirmation' className='required'>
                    Please type <strong>cancel event</strong> to confirm the
                    cancellation.
                  </Label>
                  <Input
                    id='confirmation'
                    value={cancelConfirmation}
                    onChange={(e) => {
                      setCancelConfirmation(e.target.value);
                    }}
                    placeholder='cancel event'
                    type='text'
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  size='md'
                  color='danger'
                  type='button'
                  onClick={cancelEvent}
                  disabled={cancelConfirmation != "cancel event" || cancelling}
                >
                  {cancelling ? (
                    <>
                      <span className='mr-2'>Cancelling</span>
                      <ScaleLoader
                        color='#eee'
                        loading={cancelling}
                        height='10px'
                        cssOverride={{
                          display: "inline",
                        }}
                      />
                    </>
                  ) : (
                    "Confirm"
                  )}
                </Button>
                <Button
                  size='md'
                  color='link'
                  type='button'
                  onClick={() => {
                    if (!cancelling) {
                      setCancelModalOpen(!cancelModalOpen);
                      setCancelConfirmation("");
                    }
                  }}
                  disabled={cancelling}
                >
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </>
      )}
    </>
  );
}
