import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Navigate, useLocation } from "react-router-dom";

import { ReactSession } from "react-client-session";

import { AuthContext } from "Auth.js";

import FirebaseInit from "utils/FirebaseInit.js";

import { validateAuthToken } from "utils/Api.js";

const { REACT_APP_API_HOST } = process.env;

export default function Protected({ children }) {
  const {
    firebaseDb,
    firebaseAuth,
    signInWithCustomToken,
    onAuthStateChanged,
    signOut,
  } = FirebaseInit();

  const navigate = useNavigate();

  const location = useLocation();
  const path = location.pathname;

  const {
    currentUser,
    currentAuthToken,
    currentUserInfo,
    setCurrentUserInfo,
    currentUserStatus,
  } = useContext(AuthContext);

  useEffect(() => {
    // Validate the user's auth token, and if it's invalid, redirect to the signin page
    const validateAuth = async () => {
      const isValid = await validateAuthToken(currentAuthToken);
      if (!isValid) {
        await signOut(firebaseAuth);
        navigate("/signin", { state: { prevPath: path } });
      }
    };

    if (!currentUser || !currentUserInfo) {
      validateAuth();
    }
  }, [currentUser, currentUserInfo]);

  return currentUser ? (
    <>
      {children}

      {(currentUserStatus === "user_request_deletion" ||
        currentUserStatus === "suspended") && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            color: "white",
          }}
        ></div>
      )}
    </>
  ) : (
    <Navigate to='/signin' state={{ prevPath: location.pathname }} />
  );
}
