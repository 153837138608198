import React from "react";

// reactstrap components
import { Button, Card, CardBody, Container, Row, Col } from "reactstrap";

// core components
import TopNavbar from "components/Navbars/TopNavbar.js";
import LightFooter from "components/Footers/LightFooter.js";

export default function Terms() {
  return (
    <>
      <TopNavbar />
      <section className="section section-shaped bg-secondary section-main">
        <Container>
          <Card className="card-main shadow">
            <div className="px-2 pb-4">
              <div className="text-center mt-5 mb-3">
                <h4 className="display-2 mt-3">Terms of Service</h4>
                <small>Last updated: June 10, 2022</small>
              </div>
              <ol className="mt-3 mb-4">
                <li className="terms-list">
                  <h5 className="text-uppercase">Introduction</h5>
                  <p>
                    <small>
                      Welcome to PASS LABS ("RealPass", "we", "us", or "our").
                      These Terms of Service ("Terms") govern your access to
                      RealPass platform (realpass.xyz) including website(s),
                      APIs, apps, RealPass NFTs, intellectual properties, and
                      any other tools provided in connection with our Services;
                      including without limitation to view, create, buy, and
                      sell NFTs, passes, tickets, events, raffles, club
                      memberships and use our tools, intellectual properties, at
                      your discretion, to connect directly with others to buy,
                      sell, or transfer NFTs (collectively, the "Services").
                    </small>
                  </p>
                  <p>
                    <small>
                      For purposes of these Terms, "user", "you", and "your"
                      means you as the user of the Service. If you use the
                      Service on behalf of another entity then "you" includes
                      you and that entity, and you represent and warrant that
                      (a) you are an authorized representative of the entity
                      with the authority to bind the entity to these Terms, and
                      (b) you agree to these Terms on behalf of the entity.
                    </small>
                  </p>
                  <p>
                    <small className="text-uppercase">
                      Please read these Terms carefully as they contain
                      Important information regarding your legal rights,
                      Remedies, and obligations including a mandatory
                      Arbitration agreement and class action waiver which
                      Require any disputes between us to be resolved through
                      Individual arbitration rather than by a judge or jury in
                      Court.
                    </small>
                  </p>
                  <p>
                    <small>
                      By signing up for an Account on our platform, connecting
                      your digital wallets (e.g., MetaMask, Walletconnect, or
                      Phantom) to the Service, you acknowledge that you have
                      read and agree to be bound by these Terms. If you do not
                      agree to these Terms, you may not use the service.
                    </small>
                  </p>
                  <p>
                    <small>
                      RealPass reserves the right to change these Terms at any
                      time and in our sole discretion. If we make changes to
                      these Terms, we will make reasonable efforts to provide
                      the notice. By continuing to access the Service, you
                      confirm that you agree to the new Terms. You are
                      responsible to review the Terms from time to time to view
                      such changes and to ensure that you understand the terms
                      and conditions that apply when you access the Service.
                    </small>
                  </p>
                  <p>
                    <small>
                      RealPass reserves the right to change or discontinue,
                      temporarily or permanently, the Service (or any part
                      thereof) with or without notice. You agree that RealPass
                      will not be liable to you or to any entity for any
                      modification, suspension, or discontinuance of the
                      Services
                    </small>
                  </p>
                </li>
                <li className="terms-list">
                  <h5 className="text-uppercase">Service</h5>
                  <p>
                    <small>
                      RealPass accounts are based on blockchain wallet
                      addresses. You will need a blockchain address and a third
                      party wallet to access the Service. Your account
                      ("Account" or "Identity") on RealPass will be linked with
                      your blockchain address, and display activities,
                      transactions, and the NFTs for the linked address.
                    </small>
                  </p>
                  <p>
                    <small>
                      By connecting your wallet with the Service, you agree that
                      you are using that wallet under the terms and conditions
                      of the applicable provider of the wallet. You understand
                      and agree that you are solely responsible for the security
                      of your wallet and control over authentication
                      credentials, private keys, NFTs, or digital assets that
                      are stored in or are accessible through your wallet. You
                      should never share your wallet credentials, such as
                      private keys or seed phrase with anyone. Any unauthorized
                      access to your wallet could result in the loss of NFTs or
                      digital assets hold in your wallet and any linked wallets.
                    </small>
                  </p>
                  <p>
                    <small>
                      RealPass is not responsible for the security of your
                      wallets. RealPass has no responsibility or liability to
                      you for any unauthorized access to or use of your wallets,
                      or any loss of NFTs or digital assets held in your wallet
                      and any linked wallets. If you discover an issue related
                      to your wallet, please contact your wallet provider. You
                      agree to immediately notify us if you discover or
                      otherwise suspect any security issues related to the
                      Service or your Account.
                    </small>
                  </p>
                  <p>
                    <small>
                      You represent and warrant that you will comply with all
                      applicable laws when using the Service. Without limiting
                      the foregoing, by using the Service, you represent and
                      warrant that: (a) you are not located in a country that is
                      subject to a U.S. Government embargo; and (b) you have not
                      been identified as a Specially Designated National or
                      placed on any U.S. Government list of prohibited,
                      sanctioned, or restricted parties. If you access or use
                      the Service outside the United States, you are solely
                      responsible for ensuring that your access and use of the
                      Service in such country, territory or jurisdiction does
                      not violate any applicable laws.
                    </small>
                  </p>
                  <p>
                    <small>
                      Your access and use of the Service may be interrupted from
                      time to time for any of several reasons, including,
                      without limitation, the malfunction of equipment, periodic
                      updating, maintenance, or repair of the Service, or other
                      actions that RealPass, in its sole discretion, may elect
                      to take.
                    </small>
                  </p>
                  <p>
                    <small>
                      To use our service, you must be at least 18 years old. If
                      you are under 18 years old, you may only use RealPass
                      through a parent or guardian's Account and with their
                      oversight. That account holder is responsible for your
                      actions using the Account.
                    </small>
                  </p>
                </li>
                <li className="terms-list">
                  <h5 className="text-uppercase">Property</h5>
                  <p>
                    <small>
                      All content, visual elements, data, text, design, fonts,
                      icons, logos, layouts, look and feel, formatting,
                      graphics, images, photographs, videos, music, sounds,
                      scripts, software, files, metadata, product and service
                      names, hidden information, and other materials included in
                      the Service (e.g., websites, apps, NFTs, passes, events,
                      raffles, or club memberships) are the intellectual
                      property of RealPass or its affiliates, licensors, or
                      users. You agree not to take any action against these
                      property rights.
                    </small>
                  </p>
                  <p>
                    <small>
                      RealPass and its affiliates, licensors, and users, as
                      applicable, reserve all rights in connection with the
                      Service and its content, including, without limitation the
                      exclusive right to create derivative works. RealPass's
                      logo, trademarks, names, product and service name, and all
                      content created by RealPass are the intellectual property
                      of RealPass and may not be used, in whole or in part,
                      without our prior written permission in each instance.
                    </small>
                  </p>
                  <p>
                    <small>
                      All content relating to the Service (e.g., NFTs, passes,
                      events, raffles, and club memberships) created by third
                      party are the intellectual property of respective owners
                      and may not be used, in whole or in part, without the
                      permission of the applicable property owner.
                    </small>
                  </p>
                  <p>
                    <small>
                      All communication, comments, suggestions, and feature
                      requests, in any forms, to the Service ("Communication")
                      do not grant you any right or interest in the Service. You
                      agree that RealPass may use and disclose the Communication
                      at any time and for any or no reason without any notice or
                      compensation to you. You expressly assign to RealPass any
                      and all rights and interests, including any and all
                      intellectual property rights associated with the
                      Communication.
                    </small>
                  </p>
                  <p>
                    <small>
                      RealPass may use any public blockchain data or data
                      available in any public network, including without
                      limitation, transactions, ownership, blockchain addresses,
                      smart contracts, metadata, and content linked to NFTs, to
                      display on sites or use to improve the Service.
                    </small>
                  </p>
                  <p>
                    <small>
                      By using the Service, in conjunction with creating,
                      submitting, posting, or displaying content, or by
                      complying with RealPass metadata standards, you grant us a
                      worldwide, non-exclusive, sublicensable, royalty-free
                      license to use, copy, modify, and display any content,
                      including but not limited to text, materials, images,
                      files, communications, comments, feedback, suggestions,
                      ideas, concepts, questions, data, or otherwise, that you
                      submit or post on or through the Service for our current
                      and future business purposes, including to provide,
                      promote, and improve the Service.
                    </small>
                  </p>
                  <p>
                    <small>
                      You represent and warrant that you have, or have obtained,
                      all rights, licenses, consents, permissions, power, and/or
                      authority necessary to grant the rights granted herein for
                      any content that you create, submit, post, or display on
                      or through the Service. You represent and warrant that
                      such content does not contain material subject to
                      copyright, trademark, publicity rights, or other
                      intellectual property rights unless you have necessary
                      permission or are otherwise legally entitled to post the
                      material and to grant RealPass the license described
                      above, and that the content does not violate any laws.
                    </small>
                  </p>
                  <p>
                    <small>
                      We will take down works, and remove or disable access to
                      the allegedly infringing material, upon receiving a valid
                      and fully completed takedown notice that identifies an
                      infringement of intellectual property rights. If you
                      believe that your intellectual property rights have been
                      violated, please email us at{" "}
                      <a
                        href="mailto:gm@passlabs.xyz"
                        rel="external nofollow noopener"
                        target="_blank"
                        className="text-info"
                      >
                        gm@passlabs.xyz
                      </a>
                      .
                    </small>
                  </p>
                  <p>
                    <small>
                      Information we require in the take down notice:
                    </small>
                  </p>
                  <ul>
                    <li>
                      <small>
                        Identification of the intellectual property rights that
                        you claim is being infringed;
                      </small>
                    </li>
                    <li>
                      <small>
                        Identification of the allegedly infringing material that
                        is requested to be removed;
                      </small>
                    </li>
                    <li>
                      <small>
                        Your contact information, at a minimum, including your
                        email address and full legal name (not a pseudonym);
                      </small>
                    </li>
                    <li>
                      <small>
                        A declaration for the infringement claim includes the
                        following:
                      </small>
                      <ul>
                        <li>
                          <small>
                            A statement that you believe that the use of the
                            complained material is not authorized by the
                            intellectual property owner or its agent; and
                          </small>
                        </li>
                        <li>
                          <small>
                            A statement that you are an authorized
                            representative of the intellectual property owner if
                            you are acting on behalf of the owner; and
                          </small>
                        </li>
                        <li>
                          <small>
                            A statement that the information in the notice is
                            accurate;
                          </small>
                        </li>
                        <li>
                          <small>
                            You must sign the notice (with your full legal
                            name).
                          </small>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <small>
                        We will forward your notice of intellectual property
                        infringement, including your contact information, to the
                        other party. So that they understand why the alleged
                        content is removed and can contact you to resolve the
                        dispute if any.
                      </small>
                    </li>
                  </ul>
                </li>
                <li className="terms-list">
                  <h5 className="text-uppercase">License</h5>
                  <p>
                    <small>
                      By agreeing these Terms, you are granted a limited,
                      non-exclusive, non-transferable, non-sublicensable,
                      non-commercial, non-assignable right and license to access
                      and use the Service provided, however, that such license
                      is subject to your compliance with these Terms. Any
                      content or lincense linked to or associated with any NFTs
                      is solely as set forth by the applicable seller or creator
                      of such NFT.
                    </small>
                  </p>
                </li>
                <li className="terms-list">
                  <h5 className="text-uppercase">NFTs</h5>
                  <p>
                    <small>
                      RealPass provides the Service, smart contracts, and tools
                      to third parties for creating, buying and selling NFTs.
                      When an NFT is created by RealPass or a third party, the
                      metadata and content linked to the NFT are the
                      intellectual property of RealPass or the third party
                      creator (the "Creator").
                    </small>
                  </p>
                  <p>
                    <small>
                      When an NFT is sold for the first time, the agreement for
                      sale is between the initial buyer and the Creator. When
                      the Creator is not a party of subsequent sales, including
                      buying, selling, or transferring of the NFT, the agreement
                      for the sale is between the buyer and seller.
                    </small>
                  </p>
                  <p>
                    <small>
                      As a sellor or transferor of the NFT created with the
                      Services, you represent and warrant that you will notify
                      the new owner of these Terms and require the new owner to
                      comply with these Terms. The license and any other rights
                      will be automatically transferred to the subsequent owner.
                      The sellor or transferor will cease to have any further
                      rights to the transfered NFT.
                    </small>
                  </p>
                  <p>
                    <small>
                      By placing an order on RealPass platform or through the
                      Services, you agree that you are submitting a binding
                      offer of the NFT, you agree to pay all applicable fees,
                      including the price of the NFT and service fees, and you
                      authorize RealPass to automatically charge and collect
                      such fees from your wallet or blockchain address.
                    </small>
                  </p>
                </li>
                <li className="terms-list">
                  <h5 className="text-uppercase">User Conduct</h5>
                  <p>
                    <small>
                      We make the best efforts to ensure our community can enjoy
                      web3 and protect our users from potential harm or
                      malicious activities. If we believe you have violated
                      these Terms, we reserve the right to take action.
                    </small>
                  </p>
                  <p>
                    <small>
                      You agree that you are solely responsible for your conduct
                      and content, while accessing or using the Service, and
                      that you will not, and will not allow any parties to do or
                      attempt to do any of the following:
                    </small>
                  </p>
                  <ul>
                    <li>
                      <p>
                        <small>
                          Use or attempt to use another user's Account without
                          authorization from such user, or infringe or violate
                          the intellectual property rights or any other rights
                          of other user or entity;
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          Use the Service from a different blockchain address if
                          we've blocked any of your other blockchain addresses
                          from accessing the Service;
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          Use the Service to distribute advertising or spams,
                          including through sending NFTs to other users, or
                          creating duplicate entries or content;
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          Bypass control access to the Service, including
                          attempting to circumvent any rate limiting systems by
                          using multiple API keys, multiple IP addresses, or
                          obfuscating the source of traffic you send to
                          RealPass;
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          Use any data mining, spider, crawling, scraping,
                          script, robot, software, browser extension, or any
                          automated means to access the Service, extract data,
                          or otherwise interfere with or modify content or
                          functionality of the Service;
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          Reverse engineer, duplicate, decompile, disassemble,
                          or decode any aspect of the Service, or do anything
                          that may discover source code or bypass or circumvent
                          any part of the Service;
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          Create, distribute, buy, sell or resell, or use the
                          services or products that may circumvent any RealPass
                          Services;
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          Artificially alter search results, increase views,
                          favorites, sales data, or other metrics that RealPass
                          may use to display items and recommend products or
                          services;
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          Use the Service or data collected from our Services
                          for any advertising or direct marketing activity
                          (including without limitation, email marketing, SMS
                          marketing, and telemarketing);
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          Use the Service from a country sanctioned by the
                          government of the United States or to facilitate
                          transactions involving individuals sanctioned by the
                          government of the United States or located in
                          sanctioned countries;
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          Use the Service to carry out financial activities
                          subject to licensing or registration, including but
                          not limited to securities, options, commodities, real
                          estate, or debt instruments;
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          Use the Service to carry out any business activities
                          for any purpose that is not expressly authorized
                          herein;
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          Use the Service to engage in fraud, wash trade, price
                          manipulation, or any other deceptive, misleading, or
                          fraudulent activities;
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          Use the Service to buy, sell, or transfer stolen
                          items, fraudulent items, items taken without
                          authorization, or any other illegally obtained items;
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          Interfere the Service or any resources connected to
                          the Service that could negatively impact or inhibit
                          other users from fully enjoying the Service or that
                          could damage, disable, overburden or impair the
                          Services in any manner;
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          Obtain or attempt to access or otherwise obtain any
                          materials or information through any means not
                          intentionally made available through the Service;
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          Create or display illegal content, such as content
                          that may involve child sexual exploitation, or content
                          that promote suicide or self-harm, incites hate or
                          violence against others, or doxes another individual;
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          Use the Service for any illegal or unauthorized
                          purpose, or encouraging or promoting any activity that
                          violates the Terms of Service;
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          Violate any local, state, national or international
                          laws in applicable jurisdictions, or which would
                          involve proceeds of any unlawful activity;
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          Use the Service to further or promote any criminal
                          activities or provide instructional information about
                          illegal activities, including but not limited to money
                          laundering or terrorism aiding;
                        </small>
                      </p>
                    </li>
                  </ul>
                </li>
                <li className="terms-list">
                  <h5 className="text-uppercase">Communication</h5>
                  <p>
                    <small></small>
                    <small>
                      By creating an Account, you consent to receive electronic
                      communications from RealPass (e.g., emails, notifications,
                      SMS, or other types of messages). These communications may
                      include your Account updates, transactions, and
                      activities. We may also send you newsletters related to
                      products and services we consider relevant to you. You may
                      unsubscribe these communications through the Service or
                      the operating system of your device (with the exception of
                      important announcements) by following the instructions
                      provided.
                    </small>
                  </p>
                </li>
                <li className="terms-list">
                  <h5 className="text-uppercase">Indemnification</h5>
                  <p>
                    <small>
                      By accessing the Service, you agree, to the fullest extent
                      permitted by law, to indemnify, defend, and hold harmless
                      RealPass and its affiliates, including but not limited to,
                      our past, present, and future employees, officers,
                      directors, contractors, consultants, equity holders,
                      suppliers, vendors, service providers, parent companies,
                      subsidiaries, agents, representatives, predecessors, and
                      successors (individually and collectively, the "RealPass
                      Parties"), from and against all actual or alleged claims,
                      damages, awards, judgments, losses, liabilities,
                      obligations, penalties, interests, fees, costs, including,
                      without limitation, attorneys' fees, court costs, costs of
                      settlement, and costs of pursuing indemnification and
                      insurance, of every kind and nature whatsoever, whether
                      known or unknown, foreseen or unforeseen, matured or
                      unmatured, or suspected or unsuspected, in law or equity,
                      whether in tort, contract, or otherwise (collectively,
                      "Claims"), including but not limited to, damages to
                      property or personal injury, that are caused by, arise out
                      of or are related to (a) your use or misuse of the
                      Service, or content linked to or associated with the
                      Service (b) any communication between you and RealPass,
                      (c) your violation of any term of these Terms or
                      applicable law, and (d) your violation of the rights of or
                      obligations to a third party, and (e) your negligence or
                      wilful misconduct. You agree to promptly notify RealPass
                      of any Claims and cooperate with RealPass Parties in
                      defending such Claims. You further agree that RealPass
                      Parties shall have control of the defense or settlement of
                      any Claims. This indemnity is in addition to, and not in
                      lieu of, any other indemnities between you and RealPass.
                    </small>
                  </p>
                </li>
                <li className="terms-list">
                  <h5 className="text-uppercase">Disclaimers</h5>
                  <p>
                    <small className="text-uppercase">
                      Your access to and use of the Service of RealPass is at
                      your own risk. You understand and agree that the Service
                      are provided on an "as is" and "as available" basis,
                      without warranties or conditions of any kind, either
                      express or implied.
                    </small>
                  </p>
                  <p>
                    <small className="text-uppercase">
                      While RealPass makes the best efforts to meet the
                      satisfaction of its users, we do not represent or warrant
                      that the Service are always available, uninterrupted,
                      current, reliable, accurate, complete, legal, secure,
                      safe, or error-free.
                    </small>
                  </p>
                  <p>
                    <small className="text-uppercase">
                      RealPass and its representatives will not be liable for
                      any injury of any kind from any action taken or taken in
                      reliance on material or information, contained in the
                      Services, including but not limited to any losses,
                      damages, or claims arising from: (a) user errors or
                      transaction errors; (b) any unauthorized authentications
                      or transactions, loss of credentials, including without
                      limitation the use of viruses, phishing, bruteforcing or
                      other means of attack against the Service, NFTs, or
                      wallets. (c) server failure, data loss, or network
                      connection interruption.
                    </small>
                  </p>
                  <p>
                    <small className="text-uppercase">
                      RealPass does not guarantee the security of any data that
                      you disclose online. No advice or information, whether
                      oral or obtained from RealPass and its affiliates or
                      through the Service, will create any warranty or
                      representation not expressly made herein. You accept the
                      inherent security risks of providing information and
                      dealing online over the internet and will not hold
                      RealPass responsible for any breach of security.
                    </small>
                  </p>
                  <p>
                    <small className="text-uppercase">
                      No RealPass party is responsible for losses or injury due
                      to late reports or no reports by developers or
                      representatives or any negligence of any issues with the
                      blockchain supporting the NFTs, including forks, technical
                      node issues, or any other issues having losses or injury
                      as a result.
                    </small>
                  </p>
                </li>
                <li className="terms-list">
                  <h5 className="text-uppercase">
                    Dispute Resolution; Arbitration
                  </h5>
                  <ul>
                    <li>
                      <p>
                        <small className="text-uppercase">
                          <strong>Dispute Resolution. </strong>
                        </small>
                        <small>
                          Please read the following arbitration agreement in
                          this Section ("Arbitration Agreement") carefully. It
                          requires you to arbitrate disputes with RealPass and
                          limits how you can seek relief from us. This section
                          does not govern disputes between you and third
                          parties. RealPass does not provide dispute resolution
                          services for such disagreements and the parties must
                          resolve those disputes directly.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small className="text-uppercase">
                          <strong>Arbitration Agreement. </strong>
                        </small>
                        <small>
                          You agree that any and all disputes or claims that
                          have arisen or may arise between you and RealPass,
                          whether arising out of or relating to these Terms, or
                          relating to your accessing of the Service, to any
                          products sold or distributed through the Service, any
                          materials delivered through the Service, or any aspect
                          of the relationship with RealPass, will be resolved
                          exclusively by binding arbitration, rather than in
                          court, except that (a) You or RealPass may assert
                          claims in small claims court, but only if the claims
                          qualify, the claims remain only in such court; and (b)
                          You or RealPass may seek injunctive relief in a court
                          of if the claim relates to any misuse of intellectual
                          property rights.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small className="text-uppercase">
                          <strong>Dispute Resolution Process. </strong>
                        </small>
                        <small>
                          You and RealPass both agree to resolve disputes in
                          good faith before either party initiates an
                          arbitration. You must initiate this dispute resolution
                          process by emailing RealPass at{" "}
                          <a
                            href="mailto:gm@passlabs.xyz"
                            rel="external nofollow noopener"
                            target="_blank"
                            className="text-info"
                          >
                            gm@passlabs.xyz
                          </a>
                          , describing the nature of your claim and desired
                          resolution. If the parties do not reach an agreement
                          to resolve the dispute within sixty (60) days after
                          initiation of this dispute resolution process, either
                          party may commence an arbitration or other appropriate
                          proceedings provided in this Arbitration Agreement.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small className="text-uppercase">
                          <strong>Arbitration Rules. </strong>
                        </small>
                        <small>
                          You and RealPass may choose to have the arbitration
                          conducted at a mutually agreed upon location, or
                          remotely by telephone or video conference. Both
                          parties are responsible to pay for the costs they
                          incur in the arbitration, including without
                          limitation, arbitration fees, and costs for attorneys
                          or witnesses. Any judgment on the award rendered by
                          the arbitrator may be entered in any court of
                          competent jurisdiction.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small className="text-uppercase">
                          <strong>Waiver of Jury Trial. </strong>
                        </small>
                        <small>
                          You agree to waive any constitutional and statutory
                          rights to sue in court and have a trial in front of a
                          judge or a jury. All claims and disputes shall be
                          resolved by arbitration, except as provided in this
                          Arbitration Agreement.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small className="text-uppercase">
                          <strong>
                            Waiver of Non-Individualized Actions.{" "}
                          </strong>
                        </small>
                        <small>
                          All claims and disputes within the scope of this
                          Arbitration Agreement must be arbitrated only on an
                          individual (one person or entity) basis. Any claims of
                          more than an individual are prohibited. Accordingly,
                          an arbitrator shall not combine or consolidate more
                          than one person's claims without the written consent
                          of all affected parties.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small className="text-uppercase">
                          <strong>Severability </strong>
                        </small>
                        <small>
                          Except as provided in this Section, if any part or
                          parts of this Arbitration Agreement are found under
                          the law to be invalid or unenforceable, then such part
                          or parts shall be of no force and effect and the other
                          parts of the Arbitration Agreement shall continue in
                          full force and effect.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small className="text-uppercase">
                          <strong>Confidentiality </strong>
                        </small>
                        <small>
                          All aspects of the arbitration proceeding, and any
                          ruling, decision, or award by the arbitrator, will be
                          strictly confidential for the benefit of all parties.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small className="text-uppercase">
                          <strong>Survival. </strong>
                        </small>
                        <small>
                          This Arbitration Agreement will survive the
                          termination of your relationship with RealPass.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small className="text-uppercase">
                          <strong>Modification. </strong>
                        </small>
                        <small>
                          Notwithstanding any provision in these Terms to the
                          contrary, we agree that if RealPass makes any future
                          change to this Arbitration Agreement, you may reject
                          any such change with written notice within thirty (30)
                          days of such change becoming effective. By rejecting
                          any future change, you are agreeing that you will
                          arbitrate any dispute between us per this Arbitration
                          Agreement as of the date you first accepted these
                          Terms or accepted any subsequent changes.
                        </small>
                      </p>
                    </li>
                  </ul>
                </li>
                <li className="terms-list">
                  <h5 className="text-uppercase">Risks</h5>
                  <p>You acknowledge and accept:</p>
                  <ul>
                    <li>
                      <p>
                        <small>
                          Prices and liquidity of NFTs are highly subjective,
                          volatile, and subject to fluctuations. Fluctuations in
                          the prices of digital assets can also materially and
                          adversely affect NFT prices. You agree and understand
                          this subjectivity and volatility and that you may lose
                          money.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          The development of public blockchain ecosystems could
                          impact the development of related applications, the
                          Services, and potential utilities of NFTs. The laws
                          and regulations governing blockchain technologies,
                          digital assets, NFTs, and other blockchain items
                          change from time to time, and new laws or regulations
                          may materially adversely impact the development of the
                          Services.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          You are solely responsible for determining and paying
                          taxes, if any, apply to your transactions. RealPass is
                          not responsible for determining or paying the taxes
                          that apply to your transactions.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          There are risks associated with buying and selling
                          items associated with content created by third parties
                          through blockchain transactions, including but not
                          limited to, the risk of buying and selling counterfeit
                          items, mislabelled items, untransferable items, items
                          with metadata errors, and smart contract errors. You
                          represent and warrant that you have done sufficient
                          research before making any decisions to buy, sell,
                          obtain, transfer items, or otherwise interact with the
                          Service, sites, accounts, NFTs, and smart contracts.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          There are risks associated with using Internet,
                          blockchain, and smart contracts, including, but not
                          limited to, the risk associated with hardware,
                          software, and Internet connections, blockchain and
                          smart contracts failure, the risk of malicious
                          software introduction, and the risk of unauthorized
                          access to your wallet or Account. Blockchain
                          transactions are irreversible and RealPass is not
                          responsible to reverse any transactions or recover any
                          loss on the blockchain.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          The Services rely on third party providers. If we are
                          unable to maintain a good relationship with such
                          providers; if the terms and conditions or pricing of
                          such providers change; if we violate or cannot comply
                          with the terms and conditions of such providers; or if
                          any circumstances of such providers change, access to
                          and use of the Service will change without any notice
                          or compensation to you.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          RealPass reserves the right to hide, restrict, or
                          remove content, accounts, NFTs, and all items affected
                          by any issues. Items you purchase may become
                          unreachable on RealPass. Under no circumstances shall
                          the inability to access items or the Service on
                          RealPass in conjunction with the purchase, sale, or
                          transfer of items available on any blockchains serve
                          as grounds for a claim against RealPass.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          Transactions made or in connection with the Service
                          may be irreversible, and, accordingly, losses due to
                          fraudulent or accidental transactions may not be
                          recoverable. Blockchain transactions are recorded on a
                          public ledger, which may not be necessarily the date
                          or time that you initiated the transaction.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          You agree and understand that you are solely
                          responsible for determining the nature, potential
                          value, suitability, and appropriateness of these risks
                          for yourself, and that we do not give advice or
                          recommendations regarding the Service, RealPass NFTs,
                          or any and all products or services provided by third
                          party.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          You agree and understand that you access and use the
                          Services, RealPass NFTs, and intellectual properties
                          at your own risk; however, this brief statement does
                          not disclose all of the risks associated with RealPass
                          and its Services.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          You agree and understand that RealPass will not be
                          responsible or liable for any communication failures,
                          disruptions, errors, distortions, or delays you may
                          experience when using or not using the Service,
                          however caused.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          If you have a dispute with one or more users, you
                          release RealPass from claims, demands, and damages of
                          every kind, known and unknown, in any way connected
                          with such dispute. You expressly waive any rights or
                          protections that would otherwise limit the coverage of
                          this release.
                        </small>
                      </p>
                    </li>
                  </ul>
                </li>
                <li className="terms-list">
                  <h5 className="text-uppercase">Limitation of Liability</h5>
                  <p>
                    <small className="text-uppercase">
                      To the fullest extent permitted by law, you agree that in
                      No event will RealPass or its service providers be liable
                      to You or any third party for any lost profit or any
                      direct, Indirect, consequential, exemplary, incidental,
                      special, Or punitive damages arising from these Terms or
                      the Services, nfts, intellectual properties, sites,
                      products or Third party sites and products, or for any
                      damages related To loss of revenue, loss of profits, loss
                      of business or Anticipated savings, loss of use, loss of
                      goodwill, or Loss of data, and whether caused by strict
                      liability or Tort (including negligence), breach of
                      contract or Otherwise, even if foreseeable and even if
                      RealPass or its service providers have been advised of the
                      possibility of Such damages.
                    </small>
                  </p>
                  <p>
                    <small className="text-uppercase">
                      Access to, and use of, the Service, products, or third
                      Party sites, and products are at your discretion and Risk,
                      and you will be solely responsible for any damage to Your
                      computer system or mobile device or loss of data Resulting
                      therefrom.
                    </small>
                  </p>
                  <p>
                    <small className="text-uppercase">
                      Notwithstanding anything to the contrary contained herein,
                      In no event shall the maximum aggregate liability of
                      RealPass arising out of or in any way related to these
                      Terms, the access to and use of the site, the service,
                      Intellectual properties, content, nfts, or any RealPass
                      products or services exceed $100.
                    </small>
                  </p>
                  <p>
                    <small className="text-uppercase">
                      The foregoing limitations will apply even if the
                      above-stated remedy fails in its essential purpose. Some
                      Jurisdictions do not allow the disclaimer or exclusion of
                      Certain warranties or the limitation or exclusion of
                      Liability for incidental or consequential damages.
                      Accordingly, some of the above limitations set forth above
                      May not apply to you or be enforceable with respect to
                      You. If you are dissatisfied with any portion of the
                      Services or with these Terms, your sole and exclusive
                      Remedy is to discontinue the use of the Service.
                    </small>
                  </p>
                </li>
                <li className="terms-list">
                  <h5 className="text-uppercase">Privacy</h5>
                  <p>
                    <small>
                      By accessing the Service, you agree to our{" "}
                      <a
                        href="/privacy"
                        rel="external nofollow noopener"
                        target="_blank"
                        className="text-info"
                      >
                        Privacy Policy
                      </a>{" "}
                      and you expressly consent to the use of your personal data
                      in accordance with the{" "}
                      <a
                        href="/privacy"
                        rel="external nofollow noopener"
                        target="_blank"
                        className="text-info"
                      >
                        Privacy Policy
                      </a>
                      . You may review the{" "}
                      <a
                        href="/privacy"
                        rel="external nofollow noopener"
                        target="_blank"
                        className="text-info"
                      >
                        Privacy Policy
                      </a>{" "}
                      about how we use your personal data.
                    </small>
                  </p>
                </li>
                <li className="terms-list">
                  <h5 className="text-uppercase">Termination</h5>
                  <p>
                    <small>
                      You agree that a breach of these Terms will terminate
                      automatically all rights granted by RealPass.
                      Additionally, notwithstanding anything contained in these
                      Terms, we reserve the right, with or without notice and in
                      our sole discretion, to suspend, disable, or terminate
                      your Account or access to the Service at any time and for
                      any or no reason without any notice or compensation to
                      you. You acknowledge and agree that we shall have no
                      liability or obligation to you and you will not be
                      entitled to a refund or any compensation.
                    </small>
                  </p>
                  <p>
                    <small>
                      You may terminate your Account at any time by sending a
                      request to delete your account on{" "}
                      <a href="/settings/delete" className="text-danger">
                        Account Deletion
                      </a>{" "}
                      page at any time. You acknowledge and agree that we shall
                      have no liability or obligation to you and you will not be
                      entitled to a refund or any compensation. Account deletion
                      is irreversible. All your offchain data we've stored will
                      be approved for deletion. However we won't be able to
                      delete any data store in blockchain.
                    </small>
                  </p>
                </li>
                <li className="terms-list">
                  <h5 className="text-uppercase">Injunctive Relief</h5>
                  <p>
                    <small>
                      You agree that a breach of these Terms will cause
                      irreparable injury to RealPass for which monetary remedies
                      would not be adequate. RealPass shall be entitled to
                      injunctive relief in addition to any remedies it may have,
                      without a bond, other security, or proof of damages.
                    </small>
                  </p>
                </li>
                <li className="terms-list">
                  <h5 className="text-uppercase">Severability</h5>
                  <p>
                    <small>
                      If any provision of these Terms is found invalid or
                      unenforceable, then that provision will be severable from
                      these Terms and will not affect the validity or
                      enforceability of any remaining part of that provision, or
                      any other provision of these Terms, and the other
                      provisions of these Terms remain in full force and effect.
                    </small>
                  </p>
                </li>
                <li className="terms-list">
                  <h5 className="text-uppercase">Export Laws</h5>
                  <p>
                    <small>
                      By using the Service, you represent and warrant that you
                      are not located in any U.S. embargoed countries or any
                      country that has been designated by the U.S. Government as
                      a prohibited or restricted country. You represent and
                      warrant that you will not export the Service provided by
                      RealPass to any such country, or to anyone listed on any
                      U.S. Government list of prohibited or restricted parties.
                      You agree that you will not export the Service provided by
                      RealPass to any country for which the United States or any
                      other relevant jurisdiction requires any export license at
                      the time of export without first obtaining such license.
                      You agree that you are responsible to comply at your sole
                      expense with all applicable United States export laws and
                      regulations, or any other relevant jurisdiction.
                    </small>
                  </p>
                </li>
                <li className="terms-list">
                  <h5 className="text-uppercase">Miscellaneous</h5>
                  <p>
                    <small>
                      These Terms constitute the entire agreement between you
                      and RealPass and govern your access to the Service. Except
                      as otherwise provided herein, these Terms are intended
                      solely for the benefit of the parties and are not intended
                      to confer third party beneficiary rights upon any other
                      person or entity. You may not assign the Terms without the
                      prior written permission of RealPass, but RealPass may
                      assign or transfer any term of these Terms without any
                      restriction. RealPass's failure to assert or exercise any
                      right or provision of these Terms shall not constitute a
                      waiver of such right or provision. All sections should
                      survive the termination of these Terms shall continue in
                      full force and effect after and notwithstanding any
                      termination of these Terms. Termination will not limit any
                      rights or remedies of RealPass at law or in equity.
                    </small>
                  </p>
                </li>
              </ol>
            </div>
          </Card>
        </Container>
      </section>
      <LightFooter />
    </>
  );
}
