import React from "react";

// reactstrap components
import {
  Alert,
  ButtonGroup,
  Button,
  Collapse,
  Card,
  Container,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  CardImg,
  CardBody,
  CardTitle,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Table,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ManageEventButton(props) {
  let { eventId, currentPath } = props;

  return (
    <UncontrolledDropdown className="float-right">
      <DropdownToggle
        id="manageEventButton"
        caret
        size="sm"
        outline
        color="primary"
        type="button"
      >
        <span className="ml-2 mr-1">
          <FontAwesomeIcon icon="fa-sliders" className="mr-1" />
          <span className="d-none d-md-inline-block ml-1">Manage</span>
        </span>
      </DropdownToggle>
      <DropdownMenu
        className="full-dropdown-menu-right-sm"
        style={{ margin: 0 }}
      >
        {currentPath !== "event" && (
          <DropdownItem href={`/event/${eventId}`}>
            <FontAwesomeIcon icon="fa-compass" className="mr-1" />
            <span className="ml-1">Public Page</span>
          </DropdownItem>
        )}

        {currentPath !== "dashboard" && (
          <DropdownItem href={`/dashboard/event/${eventId}`}>
            <FontAwesomeIcon icon="fa-gauge-high" className="mr-1" />
            <span className="ml-1">Event Dashboard</span>
          </DropdownItem>
        )}

        {currentPath !== "checkin" && (
          <DropdownItem href={`/event/checkin/${eventId}`}>
            <FontAwesomeIcon icon="fa-camera" className="mr-1" />
            <span className="ml-1">Event Checkin</span>
          </DropdownItem>
        )}

        {/* {currentPath !== "instant" && (
          <DropdownItem href={`/event/instant/${eventId}`}>
            <FontAwesomeIcon icon='fa-qrcode' className='mr-1' />
            <span className='ml-1'>Instant Access</span>
          </DropdownItem>
        )} */}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}
