import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import {
  Link,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { AuthContext } from "Auth.js";

// reactstrap components
import {
  Alert,
  ButtonGroup,
  Button,
  Collapse,
  Card,
  Container,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  CardImg,
  CardBody,
  CardTitle,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Table,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";

import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

// core components
import TopNavbar from "components/Navbars/TopNavbar.js";

import EventOverview from "components/EventDashboard/EventOverview.js";
import EventMembers from "components/EventDashboard/EventMembers.js";
import EventDetail from "components/EventDashboard/EventDetail.js";
import EventSocial from "components/EventDashboard/EventSocial.js";
import EventTeam from "components/EventDashboard/EventTeam.js";
import EventMore from "components/EventDashboard/EventMore.js";
import EventRegistration from "components/EventDashboard/EventRegistration.js";
import EventPass from "components/EventDashboard/EventPass.js";
import EventPayment from "components/EventDashboard/EventPayment.js";

import Loader from "components/Loaders/Loader.js";
import LoaderDots from "components/Loaders/LoaderDots.js";
import LoaderSection from "components/Loaders/LoaderSection.js";
import LoaderModal from "components/Loaders/LoaderModal.js";

import ScaleLoader from "react-spinners/ScaleLoader";
import BarLoader from "react-spinners/BarLoader";
import BeatLoader from "react-spinners/BeatLoader";

import ManageEventButton from "components/Buttons/ManageEventButton.js";
import UrlButton from "components/Buttons/UrlButton.js";

import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.compat.css";

import Compressor from "compressorjs";

import ReactDataGrid from "@inovua/reactdatagrid-community";
import GridButton from "@inovua/reactdatagrid-community/packages/Button";
import "@inovua/reactdatagrid-community/index.css";

import ReactDatetime from "react-datetime";
import Moment from "react-moment";
import moment from "moment-timezone";

import Web3 from "web3";
import { getAddressesBalances } from "eth-balance-checker/lib/web3";

import {
  truncateAddressShort,
  nativeCurrency,
  notify,
  genImageName,
  handleReactDatetimeChange,
  getClassNameReactDatetimeDays,
  formatPrettyNumber,
  urlCopyButton,
} from "utils/Utils.js";

import {
  getEvent,
  likeEvent,
  unlikeEvent,
  countEventLikes,
  getUserInvites,
} from "utils/Api.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import InfiniteScroll from "react-infinite-scroll-component";

import { Form, Field } from "react-final-form";
import Select from "react-select";

// import { RandomHash } from "random-hash";

import { InlineShareButtons } from "sharethis-reactjs";

import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const {
  defaultFormImg,
  defaultFormBanner,
  defaultProfileImg,
  bgGradient,
  defaultBoxGrayImg,
  verifiedImg,
  ethImg,
  nsfwImg,
  mmImg,
  wcImg,
  pwImg,
  InviteMaxUsageOptions,
  InviteExpireOptions,
  ChainSelectorOptions,
} = require("utils/Variables.js");

export default function DashboardEvent() {
  const navigate = useNavigate();

  const { currentUser, currentAuthToken, currentUserInfo, setCurrentUserInfo } =
    useContext(AuthContext);

  const hasAdminRole = currentUserInfo?.hasAdminRole;
  const hasCreatorRole = currentUserInfo?.hasCreatorRole;
  const isVerifiedUser = currentUserInfo?.isVerifiedUser;

  const { eventId } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const view = searchParams.get("view");
  const tabIndexView = {
    overview: 0,
    members: 1,
    settings: 2,
    pass: 3,
  };

  const [mainTab, setMainTab] = useState(
    view && tabIndexView[view] ? tabIndexView[view] : 0
  );

  const tabRef = useRef({});

  const [eventDetailTab, setEventDetailTab] = useState(0);
  const [eventPassTab, setEventPassTab] = useState(0);

  const eventUrl = window.location.href.replace("/dashboard", "");
  const eventUrlClean =
    eventUrl.indexOf("?") > 0
      ? eventUrl.substring(0, eventUrl.indexOf("?"))
      : eventUrl;

  const [creatorWallet, setCreatorWallet] = useState(
    currentUserInfo?.ethAddress
  );

  const [pageLoading, setPageLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [eventError, setEventError] = useState();

  const [ownerEthAddress, setOwnerEthAddress] = useState();
  const [isEventActiveCreator, setIsEventActiveCreator] = useState(false);

  const [newParticipantsLoading, setNewParticipantsLoading] = useState(false);
  const [topParticipantsLoading, setTopParticipantsLoading] = useState(false);
  const [allParticipantsLoading, setAllParticipantsLoading] = useState(false);

  const [isCancelled, setIsCancelled] = useState(false);
  const [isPublished, setIsPublished] = useState(true);
  const [isVirtual, setIsVirtual] = useState();
  const [event, setEvent] = useState();
  const [eventName, setEventName] = useState();
  const [eventDescription, setEventDescription] = useState();
  const [eventImage, setEventImage] = useState();
  const [bannerImage, setBannerImage] = useState();
  const [eventWebsite, setEventWebsite] = useState();
  const [eventVideoUrl, setEventVideoUrl] = useState();
  const [eventTwitter, setEventTwitter] = useState();
  const [eventDiscord, setEventDiscord] = useState();
  const [eventInstagram, setEventInstagram] = useState();

  const [registerEnabled, setRegisterEnabled] = useState();
  const [accessEnabled, setAccessEnabled] = useState();

  const [eventStartDate, setEventStartDate] = useState();
  const [eventStartDateTime, setEventStartDateTime] = useState();
  const [eventEndDate, setEventEndDate] = useState();
  const [eventEndDateTime, setEventEndDateTime] = useState();
  const [eventEnded, setEventEnded] = useState();

  const [eventCategory, setEventCategory] = useState();
  // const [isVirtual, setIsVirtual] = useState(true);
  const [eventLocation, setEventLocation] = useState();
  const [isPrivate, setIsPrivate] = useState();
  const [isNSFW, setIsNSFW] = useState();
  const [memberInviteMaxUsage, setMemberInviteMaxUsage] = useState();

  const [likes, setLikes] = useState(0);
  const [followed, setFollowed] = useState(false);
  const [totalReg, setTotalReg] = useState(0);
  const [totalCheckin, setTotalCheckin] = useState(0);

  const [maxParticipants, setMaxParticipants] = useState();
  const [spotsFull, setSpotsFull] = useState();

  const [blockchain, setBlockchain] = useState();
  const [blockchainId, setBlockchainId] = useState();
  const [tokenUrl, setTokenUrl] = useState();

  const [creatorName, setCreatorName] = useState();
  const [creatorImage, setCreatorImage] = useState();
  const [creatorBio, setCreatorBio] = useState();
  const [creatorWebsite, setCreatorWebsite] = useState();
  const [creatorTwitter, setCreatorTwitter] = useState();
  const [creatorInstagram, setCreatorInstagram] = useState();
  const [creatorEmail, setCreatorEmail] = useState();

  const [passName, setPassName] = useState();
  const [passSymbol, setPassSymbol] = useState();
  const [passImage, setPassImage] = useState();
  const [passType, setPassType] = useState();
  const [passTitle, setPassTitle] = useState();
  const [freePass, setFreePass] = useState(false);
  const [passContract, setPassContract] = useState();
  const [requireUniquePassId, setRequireUniquePassId] = useState();

  const [ownedPasses, setOwnedPasses] = useState([]);
  const [createdPasses, setCreatedPasses] = useState([]);

  // const [membersTabs, setMembersTabs] = useState(0);

  const [joinedEvents, setJoinedEvents] = useState([]);
  const [moreJoinedEvents, setMoreJoinedEvents] = useState(true);
  const [joinedEventsPageIndex, setJoinedEventsPageIndex] = useState(0);

  // const [createdEvents, setCreatedEvents] = useState([]);
  // const [moreCreatedEvents, setMoreCreatedEvents] = useState(true);
  // const [createdEventsPageIndex, setCreatedEventsPageIndex] = useState(0);

  const [joinedRaffles, setJoinedRaffles] = useState([]);
  const [createdRaffles, setCreatedRaffles] = useState([]);

  const [participantsChartOptions, setParticipantsChartOptions] = useState();

  const [participantsChartLabels, setParticipantsChartLabels] = useState();
  const [participantsChartData, setParticipantsChartData] = useState();

  const [newParticipants, setNewParticipants] = useState([]);
  const [newParticipantsCount, setNewParticipantsCount] = useState(0);
  const [moreNewParticipants, setMoreNewParticipants] = useState(true);
  const [newParticipantsIndex, setNewParticipantsIndex] = useState(0);
  const [lastNDaysFilter, setLastNDaysFilter] = useState(7);

  // filter "all", "active", "inactive", "bots"
  const [membersTableFilter, setMembersTableFilter] = useState("active");
  const [botsConditionIPFilter, setBotsConditionIPFilter] = useState(2);
  const [botsConditionModificationFilter, setBotsConditionModificationFilter] =
    useState(5);

  const [topParticipants, setTopParticipants] = useState([]);
  const [topUsersFilter, setTopUsersFilter] = useState("Invites");

  const [membersSelected, setMembersSelected] = useState();
  const [filteredEventMembers, setFilteredEventMembers] = useState([]);
  const [filteredEventMembersCount, setFilteredEventMembersCount] = useState(0);

  const [membersGridRef, setMembersGridRef] = useState(null);
  const [creatorInvitesGridRef, setCreatorInvitesGridRef] = useState(null);
  const [membersInvitesGridRef, setMembersInvitesGridRef] = useState(null);

  const [allParticipants, setAllParticipants] = useState([]);

  const [memberWalletInput, setMemberWalletInput] = useState("");
  const [memberWalletToFind, setMemberWalletToFind] = useState();

  // const [membersFilterCollapseOpen, setMembersFilterCollapseOpen] =
  //   useState(false);

  // const [allMembersFilter, setAllMembersFilter] = useState(true);
  // const [activeMembersFilter, setActiveMembersFilter] = useState(false);
  // const [inactiveMembersFilter, setInactiveMembersFilter] = useState(false);
  // const [botMembersFilter, setBotMembersFilter] = useState(false);

  const [bulkMemberAddresses, setBulkMemberAddresses] = useState("");
  const [bulkMemberAddressList, setBulkMemberAddressList] = useState([]);

  const [allMembersTableRefresh, setAllMembersTableRefresh] = useState(false);
  const [bulkMemberAddressesValid, setBulkMemberAddressesValid] =
    useState(false);
  const [bulkAddMembersError, setBulkAddMembersError] = useState();
  const [validatingAddresses, setValidatingAddresses] = useState(false);
  const [confirmAddMembers, setConfirmAddMembers] = useState(false);

  const [creatorInviteCodesCount, setCreatorInviteCodesCount] = useState(0);
  const [membersInviteCodesCount, setMembersInviteCodesCount] = useState(0);

  const [creatorInviteMaxUsageOption, setCreatorInviteMaxUsageOption] =
    useState(InviteMaxUsageOptions[5]);

  const [creatorInviteExpireOption, setCreatorInviteExpireOption] = useState(
    InviteExpireOptions[9]
  );

  const [enableMemberInvite, setEnableMemberInvite] = useState(false);
  const [memberInviteMaxUsageOption, setMemberInviteMaxUsageOption] = useState(
    InviteMaxUsageOptions[5]
  );

  const [memberInviteWalletInput, setMemberInviteWalletInput] = useState("");
  const [memberInviteWalletToFind, setMemberInviteWalletToFind] = useState();
  const [memberInviteWalletError, setMemberInviteWalletError] = useState();

  const [memberInviteCodesRefresh, setMemberInviteCodesRefresh] =
    useState(false);
  const [memberInviteAction, setMemberInviteAction] = useState();
  const [memberInviteActionCode, setMemberInviteActionCode] = useState();
  const [memberInviteActionMemberName, setMemberInviteActionMemberName] =
    useState();
  const [memberInviteActionMemberWallet, setMemberInviteActionMemberWallet] =
    useState();

  const [creatorInviteCodesRefresh, setCreatorInviteCodesRefresh] =
    useState(false);

  const [creatorInviteAction, setCreatorInviteAction] = useState();
  const [creatorInviteActionCode, setCreatorInviteActionCode] = useState();
  const [creatorInviteLink, setCreatorInviteLink] = useState();

  const [creatorInviteSelected, setCreatorInviteSelected] = useState();

  const [inviteCodeModalOpen, setInviteCodeModalOpen] = useState(false);
  const [memberInvitesModalOpen, setMemberInvitesModalOpen] = useState(false);
  const [bulkAddMembersModalOpen, setBulkAddMembersModalOpen] = useState(false);

  const [memberInviteActionCollapseOpen, setMemberInviteActionCollapseOpen] =
    useState(false);

  const [creatorInviteActionCollapseOpen, setCreatorInviteActionCollapseOpen] =
    useState(false);

  const [maxUsers, setMaxUsers] = useState("∞");
  const [unlimitedUsers, setUnlimitedUsers] = useState(true);

  // const [isRaffle, setIsRaffle] = useState(false);
  // const [winners, setWinners] = useState(1);

  const [requirePass, setRequirePass] = useState(true);
  // const [registerEnabled, setRegisterEnabled] = useState(false);

  const [selectedPass, setSelectedPass] = useState();
  // const [requireUniquePassId, setRequireUniquePassId] = useState(true);

  const [passInput, setPassInput] = useState("");
  const [passInputChanged, setPassInputChanged] = useState(false);
  const [passSuggestions, setPassSuggestions] = useState([]);

  // const [isPublic, setIsPublic] = useState(true);
  // const [isNSFW, setIsNSFW] = useState(false);

  const [passBlockchainOption, setPassBlockchainOption] = useState(
    ChainSelectorOptions[0]
  );
  const [passError, setPassError] = useState();

  // const listCreatedRaffles = (items) => {
  //   return items.map((item, index) => {
  //     return <></>;
  //   });
  // };

  const handleSelectMainTab = async (index) => {
    setPageLoading(true);

    if (mainTab !== index) {
      setMainTab(index);

      if (index === 0) {
        setSearchParams({ ["view"]: "overview" });

        // await fetchParticipantStats(lastNDaysFilter);
        // await fetchTopParticipantsByBalance();
      }

      if (index === 1) {
        setSearchParams({ ["view"]: "members" });

        // setMembersTableFilter("active");
        // setAllMembersTableRefresh(!allMembersTableRefresh);
      }

      if (index === 2) {
        setEventDetailTab(0);
        setSearchParams({ ["view"]: "settings" });
      }

      if (index === 3) {
        setEventPassTab(0);
        setSearchParams({ ["view"]: "pass" });
      }
    }

    setPageLoading(false);
  };

  const handleSelectEventDetailTab = (index) => {
    if (eventDetailTab !== index) {
      setEventDetailTab(index);

      if (index === 0) {
      }

      if (index === 1) {
      }

      if (index === 2) {
      }

      if (index === 3) {
      }
    }
  };

  const handleSelectEventPassTab = (index) => {
    if (eventPassTab !== index) {
      setEventPassTab(index);

      if (index === 0) {
      }

      if (index === 1) {
      }

      if (index === 2) {
      }

      if (index === 3) {
      }
    }
  };

  // const toggleMembersNavs = async (index) => {
  //   setPageLoading(true);

  //   if (index === 0 && index !== membersTabs) {
  //     setMembersTabs(index);
  //   }

  //   if (index === 1 && index !== membersTabs) {
  //     setMembersTabs(index);
  //     setMembersTableFilter("active");
  //     setAllMembersTableRefresh(!allMembersTableRefresh);
  //   }

  //   setPageLoading(false);
  // };

  const fetchEvent = async () => {
    try {
      setPageLoading(true);

      const eventRes = await getEvent(currentAuthToken, eventId);

      if (
        !hasAdminRole &&
        !(
          hasCreatorRole &&
          (eventRes?.isOwner || eventRes?.isCohost || eventRes?.isPanelist)
        )
      ) {
        navigate(`/event/${eventId}`);
      }

      setIsCancelled(eventRes?.isCancelled);

      setIsEventActiveCreator(
        hasCreatorRole &&
          (eventRes?.isOwner || eventRes?.isCohost || eventRes?.isPanelist)
      );

      setEvent(eventRes);
      setEventName(eventRes?.name);
      setIsPublished(eventRes?.isPublished);
      setIsVirtual(eventRes?.isVirtual);
      setIsPrivate(eventRes?.isPrivate);

      const owner = eventRes?.owner;
      setOwnerEthAddress(owner?.ethAddress);

      const start = new Date(eventRes?.startDateTime);
      const end = new Date(eventRes?.endDateTime);
      setEventEnded(end?.getTime() < new Date().getTime());

      // eventQuery.equalTo("objectId", eventId);
      // eventQuery.equalTo("creatorWallet", creatorWallet);
      // nfevent = await eventQuery.first();

      // if (!nfevent) {
      //   navigate(`/event/${eventId}`);
      // }

      // const name = nfevent.get("name");
      // const description = nfevent.get("description");
      // const regEnabled = nfevent.get("registerEnabled");
      // const accEnabled = nfevent.get("accessEnabled");
      // const image = nfevent.get("eventImage");
      // const banner = nfevent.get("bannerImage");
      // const startTime = nfevent.get("startDateTime");
      // const endTime = nfevent.get("endDateTime");
      // const category = nfevent.get("category");
      // const virtual = nfevent.get("isVirtual");
      // const location = nfevent.get("location");
      // const maxParts = nfevent.get("maxParticipants");
      // const creator = nfevent.get("creator");
      // const contract = nfevent.get("passContract");
      // const uniqueId = nfevent.get("requireUniquePassId");

      // // TODO: remove rinkeby
      // const chain = nfevent.get("blockchain") === "eth" ? "rinkeby" : "mumbai";
      // // const chain = nfevent.get("blockchain");

      // // Set Chain ID by chain name
      // let chainId;

      // switch (chain) {
      //   case "matic":
      //     // chainId = "0x137";
      //     chainId = "0x13881"; //mumbai
      //     break;
      //   case "bsc":
      //     chainId = "0x56";
      //     break;
      //   case "avax":
      //     chainId = "0x43114";
      //     break;
      //   case "fantom":
      //     chainId = "0x250";
      //     break;
      //   case "cronos":
      //     chainId = "0x25";
      //     break;
      //   default:
      //     // chainId = "0x1";
      //     chainId = "0x4"; //TODO: remove rinkeby chain
      // }
      // // await Moralis.switchNetwork(chainId);

      // const website = nfevent.get("website");
      // const videoUrl = nfevent.get("videoUrl");
      // const twitter = nfevent.get("twitter");
      // const discord = nfevent.get("discord");
      // const instagram = nfevent.get("instagram");
      // const publicFlag = nfevent.get("isPublic");
      // const nsfw = nfevent.get("isNSFW");
      // const inviteLimit = nfevent.get("userInviteMaxUsage");

      // setEventName(name);
      // setEventDescription(description);
      // setRegisterEnabled(regEnabled);
      // setAccessEnabled(accEnabled);
      // setEventImage(image?.url());
      // setBannerImage(banner?.url());

      // const startTimeShort = startTime?.toLocaleDateString(
      //   "en-US",
      //   USDateOption
      // );
      // const endTimeShort = endTime?.toLocaleDateString("en-US", USDateOption);

      // const startTimeLong = startTime?.toLocaleString(
      //   "en-US",
      //   US_DATE_TIME_OPTION
      // );

      // const endTimeLong = endTime?.toLocaleString("en-US", US_DATE_TIME_OPTION);

      // setEventStartDate(startTimeShort);
      // setEventEndDate(endTimeShort);

      // setEventStartDateTime(startTimeLong);
      // setEventEndDateTime(endTimeLong);

      // if (endTime?.getTime() <= new Date().getTime()) {
      //   setEventEnded(true);
      // }

      // setEventCategory(category);
      // setIsVirtual(virtual);
      // setEventLocation(location);
      // setMaxParticipants(maxParts);
      // setBlockchain(chain);
      // setBlockchainId(chainId);
      // setEventWebsite(website);
      // setEventVideoUrl(videoUrl);
      // setEventTwitter(twitter);
      // setEventDiscord(discord);
      // setEventInstagram(instagram);
      // setIsPublic(publicFlag);
      // setIsNSFW(nsfw);
      // setMemberInviteMaxUsage(inviteLimit);

      // if (inviteLimit < 0 || inviteLimit > 0) {
      //   setEnableMemberInvite(true);
      // }

      // if (inviteLimit) {
      //   const inviteMaxUsageIndex = inviteMaxUsageOptions.findIndex(
      //     (item) => item.value === inviteLimit
      //   );
      //   setMemberInviteMaxUsageOption(
      //     inviteMaxUsageOptions[inviteMaxUsageIndex]
      //   );
      // } else {
      //   setMemberInviteMaxUsageOption(disableMaxUsageOption);
      // }

      // setRequireUniquePassId(uniqueId);

      // if (creatorWallet) {
      //   creatorProfileQuery.equalTo("ethAddress", creatorWallet);
      //   nfcreatorProfile = await creatorProfileQuery.first();

      //   if (nfcreatorProfile) {
      //     setIsEventCreator(
      //       currentUser &&
      //         currentUser.id &&
      //         currentUser.id === nfcreatorProfile.get("user")?.id
      //     );

      //     setCreatorImage(nfcreatorProfile.get("image")?.url());
      //     setCreatorName(nfcreatorProfile.get("name"));
      //     setCreatorBio(nfcreatorProfile.get("bio"));
      //     setCreatorWebsite(nfcreatorProfile.get("website"));
      //     setCreatorTwitter(nfcreatorProfile.get("twitter"));
      //     setCreatorInstagram(nfcreatorProfile.get("instagram"));
      //     setCreatorEmail(nfcreatorProfile.get("contactEmail"));

      //     verifiedQuery.equalTo("profile", nfcreatorProfile);
      //     nfverified = await verifiedQuery.first();
      //     setIsNFVerified(nfverified?.get("verified"));
      //   }
      // }

      // await fetchTotalParticipants(maxParts);

      // await fetchLikes();

      // if (contract) {
      //   setPassContract(contract);
      //   setFreePass(false);
      //   setPassTitle("Pass Holders Only");

      //   // TODO: check if pass contract is RealPass
      //   // TODO: remove rinkeby and mumbai
      //   if (chain === "eth") {
      //     setTokenUrl(`https://etherscan.io/address/${contract}`);
      //   }

      //   if (chain === "matic") {
      //     setTokenUrl(`https://polygonscan.com/address/${contract}`);
      //   }

      //   const options = {
      //     chain: chain,
      //     address: contract,
      //     limit: 1,
      //   };

      //   try {
      //     const res = await Moralis.Web3API.token.getAllTokenIds(options);
      //     if (res && res.result && res.result.length > 0) {
      //       const nft = res.result[0];
      //       setPassName(nft?.name);
      //       setPassSymbol(nft?.symbol);
      //       setPassType(nft?.contract_type);
      //       setPassImage(
      //         JSON.parse(nft?.metadata)?.image?.replace(
      //           "ipfs://",
      //           "https://gateway.moralisipfs.com/ipfs/"
      //         )
      //       );
      //     } else {
      //       setPassError("Cannot find the smart contract.");
      //     }
      //   } catch (err) {
      //     console.error(err.message);
      //     setPassError("Unable to get the smart contract data.");
      //   } finally {
      //     setPageLoading(false);
      //   }
      // } else {
      //   setPassTitle("Free Pass");
      //   setFreePass(true);
      // }
    } catch (err) {
      console.error(err.message);
      if (err.response?.status === 404) {
        navigate("/404");
      } else {
        notify("Unable to fetch event data.", "error");
        setEventError(
          "This is event currently is not available. You can come back later."
        );
      }
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (!hasAdminRole && !hasCreatorRole) {
      navigate(`/event/${eventId}`);
    }

    fetchEvent();
  }, []);

  return (
    <>
      <BarLoader
        loading={
          pageLoading || newParticipantsLoading || topParticipantsLoading
        }
        cssOverride={{
          width: "100%",
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          zIndex: "1031",
          backgroundColor: "#fff",
          opacity: "1",
        }}
      />
      <TopNavbar />
      <ReactNotifications />

      {!pageLoading &&
        (event?.isCancelled ? (
          <Alert
            color="dark"
            className="mb-0 mt-2 text-center rounded-0 bg-dark"
            style={{ zIndex: "1000" }}
          >
            <span className="h5 text-white">
              ☔️ This event has been cancelled by the{" "}
              <a
                href={`/${ownerEthAddress}`}
                target="_blank"
                className="text-info"
              >
                <u>creator</u>
              </a>
              . You no longer have the access.
            </span>
          </Alert>
        ) : eventError ? (
          <Alert
            color="dark"
            className="mb-0 mt-2 text-center rounded-0 bg-dark"
            style={{ zIndex: "1000" }}
          >
            <span className="h5 text-white">
              <FontAwesomeIcon
                icon="fa-triangle-exclamation"
                className="mr-2"
              />
              {eventError}
            </span>
          </Alert>
        ) : !isPublished ? (
          <Alert
            color="dark"
            className="mb-0 mt-2 text-center rounded-0 bg-dark"
            style={{ zIndex: "1000" }}
          >
            <span className="h5 text-white">
              <FontAwesomeIcon icon="fa-bell" className="mr-2" />
              The event is not published. It's only visible to admin.
            </span>
          </Alert>
        ) : (
          eventEnded && (
            <Alert
              color="dark"
              className="mb-0 mt-2 text-center rounded-0 bg-dark"
              style={{ zIndex: "1000" }}
            >
              <span className="h5 text-white">
                <FontAwesomeIcon icon="fa-bell" className="mr-2" />
                This event has already ended.
              </span>
            </Alert>
          )
        ))}
      {(hasAdminRole || isEventActiveCreator) && (
        <section className="section section-shaped section-main">
          <Container>
            <Row>
              <Col className="col-9">
                <h3>
                  <strong>🚦Dashboard</strong>{" "}
                  <sup>
                    {(hasAdminRole || isEventActiveCreator) && (
                      <Badge pill>
                        <small color="text-primary">
                          {hasAdminRole
                            ? "Admin Read-only"
                            : event?.isOwner
                            ? "Owner"
                            : event?.isCohost
                            ? "Cohost"
                            : event?.isPanelist && "Panelist"}
                        </small>
                      </Badge>
                    )}
                  </sup>
                </h3>
              </Col>
              <Col className="col-3 text-right mt-1">
                <ManageEventButton eventId={eventId} />
              </Col>
            </Row>
            <Row className="mt-1">
              <Col xl="6" lg="6" md="6" sm="12" xs="12">
                <h5>
                  <strong>{!pageLoading && (eventName || "Unnamed")}</strong>
                </h5>
              </Col>
              <Col className="text-right">
                <UrlButton url={`realpass.xyz/${eventId}`} size="sm" />
              </Col>
            </Row>

            <Tabs
              className="pt-5"
              selectedIndex={mainTab}
              onSelect={(index) => {}}
            >
              <TabList>
                <ScrollMenu apiRef={tabRef}>
                  <Tab onClick={() => handleSelectMainTab(0)}>
                    <FontAwesomeIcon icon="fa-chart-simple" className="mr-1" />
                    <span className="ml-1">Overview</span>
                  </Tab>
                  <Tab onClick={() => handleSelectMainTab(1)}>
                    <FontAwesomeIcon icon="fa-address-card" className="mr-1" />
                    <span className="ml-1">Members</span>
                  </Tab>
                  <Tab onClick={() => handleSelectMainTab(2)}>
                    <FontAwesomeIcon icon="fa-gear" className="mr-1" />
                    <span className="ml-1">Settings</span>
                  </Tab>

                  {(hasAdminRole || event?.isOwner || event?.isCohost) && (
                    <Tab onClick={() => handleSelectMainTab(3)}>
                      <FontAwesomeIcon icon="fa-passport" className="mr-1" />
                      <span className="ml-1">Passes</span>
                    </Tab>
                  )}
                </ScrollMenu>
              </TabList>

              <TabPanel>
                <div className="pt-2 pb-3 px-1">
                  {event ? (
                    !event?.isCancelled && (
                      <EventOverview
                        event={event}
                        eventId={eventId}
                        isEventActiveCreator={isEventActiveCreator}
                      />
                    )
                  ) : (
                    <div className="mt-6 text-center">
                      <BeatLoader
                        color="#111"
                        loading={pageLoading}
                        height="10px"
                        cssOverride={{
                          display: "inline",
                        }}
                      />
                    </div>
                  )}
                </div>
              </TabPanel>

              <TabPanel>
                <div className="pt-2 pb-3 px-1">
                  {event ? (
                    !event?.isCancelled && (
                      <EventMembers
                        event={event}
                        eventId={eventId}
                        eventName={eventName}
                        updateEvent={setEvent}
                        isEventActiveCreator={isEventActiveCreator}
                      />
                    )
                  ) : (
                    <div className="mt-6 text-center">
                      <BeatLoader
                        color="#111"
                        loading={pageLoading}
                        height="10px"
                        cssOverride={{
                          display: "inline",
                        }}
                      />
                    </div>
                  )}
                </div>
              </TabPanel>

              <TabPanel>
                <Tabs
                  selectedIndex={eventDetailTab}
                  onSelect={(index) => {}}
                  className="vertical-tabs mt-4"
                >
                  {event ? (
                    !event?.isCancelled && (
                      <Row>
                        <Col
                          xl="2"
                          lg="2"
                          md="2"
                          sm="0"
                          xs="0"
                          className="border-right ml-0 pr-1 d-none d-xl-block d-lg-block d-md-block"
                        >
                          <TabList>
                            {(hasAdminRole ||
                              event?.isOwner ||
                              event?.isCohost) && (
                              <Tab
                                onClick={() => handleSelectEventDetailTab(0)}
                              >
                                <FontAwesomeIcon
                                  icon="fa-pen-to-square"
                                  className="mr-2"
                                />
                                Detail
                              </Tab>
                            )}

                            {(hasAdminRole ||
                              event?.isOwner ||
                              event?.isCohost) && (
                              <Tab
                                onClick={() => handleSelectEventDetailTab(1)}
                              >
                                <FontAwesomeIcon
                                  icon="fa-bullhorn"
                                  className="mr-2"
                                />
                                Social
                              </Tab>
                            )}
                            <Tab onClick={() => handleSelectEventDetailTab(2)}>
                              <FontAwesomeIcon
                                icon="fa-user-group"
                                className="mr-2"
                              />
                              Team
                            </Tab>

                            {(hasAdminRole ||
                              event?.isOwner ||
                              event?.isCohost) && (
                              <Tab
                                onClick={() => handleSelectEventDetailTab(3)}
                              >
                                <FontAwesomeIcon
                                  icon="fa-toolbox"
                                  className="mr-2"
                                />
                                More
                              </Tab>
                            )}
                          </TabList>
                        </Col>
                        <Col xl="10" lg="10" md="10" sm="12" xs="12">
                          {(hasAdminRole ||
                            event?.isOwner ||
                            event?.isCohost) && (
                            <div className="justify-content-right text-right d-sm-block d-xs-block d-md-none d-lg-none d-xl-none mb-2">
                              <ButtonGroup role="tablist">
                                <Button
                                  aria-selected={eventDetailTab === 0}
                                  className={`btn-tab mb-sm-3 mb-md-0 ${
                                    eventDetailTab === 0 && "active"
                                  }`}
                                  outline
                                  color="dark"
                                  size="md"
                                  onClick={() => handleSelectEventDetailTab(0)}
                                  type="button"
                                  role="tab"
                                >
                                  <FontAwesomeIcon icon="fa-pen-to-square" />
                                </Button>
                                <Button
                                  aria-selected={eventDetailTab === 1}
                                  className={`btn-tab mb-sm-3 mb-md-0 ${
                                    eventDetailTab === 1 && "active"
                                  }`}
                                  outline
                                  color="dark"
                                  size="md"
                                  onClick={() => handleSelectEventDetailTab(1)}
                                  type="button"
                                  role="tab"
                                >
                                  <FontAwesomeIcon icon="fa-bullhorn" />
                                </Button>
                                <Button
                                  aria-selected={eventDetailTab === 2}
                                  className={`btn-tab mb-sm-3 mb-md-0 ${
                                    eventDetailTab === 2 && "active"
                                  }`}
                                  outline
                                  color="dark"
                                  size="md"
                                  onClick={() => handleSelectEventDetailTab(2)}
                                  type="button"
                                  role="tab"
                                >
                                  <FontAwesomeIcon icon="fa-user-group" />
                                </Button>
                                <Button
                                  aria-selected={eventDetailTab === 3}
                                  className={`btn-tab mb-sm-3 mb-md-0 ${
                                    eventDetailTab === 3 && "active"
                                  }`}
                                  outline
                                  color="dark"
                                  size="md"
                                  onClick={() => handleSelectEventDetailTab(3)}
                                  type="button"
                                  role="tab"
                                >
                                  <FontAwesomeIcon icon="fa-toolbox" />
                                </Button>
                              </ButtonGroup>
                            </div>
                          )}

                          {(hasAdminRole ||
                            event?.isOwner ||
                            event?.isCohost) && (
                            <TabPanel>
                              <div className="pt-2 pb-3 px-1">
                                <Row>
                                  <Col
                                    xl="9"
                                    lg="10"
                                    md="11"
                                    sm="12"
                                    xs="12"
                                    className="my-lg-3"
                                  >
                                    {event ? (
                                      <EventDetail
                                        event={event}
                                        updateEvent={setEvent}
                                        updateEventName={setEventName}
                                        updateIsPublished={setIsPublished}
                                        updateIsVirtual={setIsVirtual}
                                        updateIsEventEnded={setEventEnded}
                                        isEventActiveCreator={
                                          isEventActiveCreator
                                        }
                                      />
                                    ) : (
                                      <div className="mt-6 text-center">
                                        <BeatLoader
                                          color="#111"
                                          loading={pageLoading}
                                          height="10px"
                                          cssOverride={{
                                            display: "inline",
                                          }}
                                        />
                                      </div>
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            </TabPanel>
                          )}

                          {(hasAdminRole ||
                            event?.isOwner ||
                            event?.isCohost) && (
                            <TabPanel>
                              <div className="pt-2 pb-3 px-1">
                                <Row>
                                  <Col className="my-lg-3">
                                    {event ? (
                                      <EventSocial
                                        event={event}
                                        updateEvent={setEvent}
                                        isEventActiveCreator={
                                          isEventActiveCreator
                                        }
                                      />
                                    ) : (
                                      <div className="mt-6 text-center">
                                        <BeatLoader
                                          color="#111"
                                          loading={pageLoading}
                                          height="10px"
                                          cssOverride={{
                                            display: "inline",
                                          }}
                                        />
                                      </div>
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            </TabPanel>
                          )}

                          <TabPanel>
                            <div className="pt-2 pb-3 px-1">
                              <Row>
                                <Col className="my-lg-3">
                                  {event ? (
                                    <EventTeam
                                      event={event}
                                      eventId={eventId}
                                      updateEvent={setEvent}
                                      isEventActiveCreator={
                                        isEventActiveCreator
                                      }
                                    />
                                  ) : (
                                    <div className="mt-6 text-center">
                                      <BeatLoader
                                        color="#111"
                                        loading={pageLoading}
                                        height="10px"
                                        cssOverride={{
                                          display: "inline",
                                        }}
                                      />
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </TabPanel>

                          {(hasAdminRole ||
                            event?.isOwner ||
                            event?.isCohost) && (
                            <TabPanel>
                              <div className="pt-2 pb-3 px-1">
                                <Row>
                                  <Col className="my-lg-3">
                                    {event ? (
                                      <EventMore
                                        event={event}
                                        eventId={eventId}
                                        updateEvent={setEvent}
                                        isEventActiveCreator={
                                          isEventActiveCreator
                                        }
                                      />
                                    ) : (
                                      <div className="mt-6 text-center">
                                        <BeatLoader
                                          color="#111"
                                          loading={pageLoading}
                                          height="10px"
                                          cssOverride={{
                                            display: "inline",
                                          }}
                                        />
                                      </div>
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            </TabPanel>
                          )}
                        </Col>
                      </Row>
                    )
                  ) : (
                    <div className="mt-6 text-center">
                      <BeatLoader
                        color="#111"
                        loading={pageLoading}
                        height="10px"
                        cssOverride={{
                          display: "inline",
                        }}
                      />
                    </div>
                  )}
                </Tabs>
              </TabPanel>

              {(hasAdminRole || event?.isOwner || event?.isCohost) && (
                <TabPanel>
                  <Tabs
                    selectedIndex={eventPassTab}
                    onSelect={(index) => {}}
                    className="vertical-tabs mt-4"
                  >
                    {event ? (
                      !event?.isCancelled && (
                        <Row>
                          <Col
                            xl="2"
                            lg="2"
                            md="2"
                            sm="0"
                            xs="0"
                            className="border-right ml-0 pr-1 d-none d-xl-block d-lg-block d-md-block"
                          >
                            {(hasAdminRole ||
                              event?.isOwner ||
                              event?.isCohost) && (
                              <TabList>
                                <Tab
                                  onClick={() => handleSelectEventPassTab(0)}
                                >
                                  <FontAwesomeIcon
                                    icon="fa-ticket"
                                    className="mr-2"
                                  />
                                  Passes
                                </Tab>
                                <Tab
                                  onClick={() => handleSelectEventPassTab(1)}
                                >
                                  <FontAwesomeIcon
                                    icon="fa-right-to-bracket"
                                    className="mr-2"
                                  />
                                  Access
                                </Tab>
                                <Tab
                                  onClick={() => handleSelectEventPassTab(2)}
                                >
                                  <FontAwesomeIcon
                                    icon="fa-credit-card"
                                    className="mr-2"
                                  />
                                  Payment
                                </Tab>
                              </TabList>
                            )}
                          </Col>
                          <Col xl="10" lg="10" md="10" sm="12" xs="12">
                            {(hasAdminRole ||
                              event?.isOwner ||
                              event?.isCohost) && (
                              <div className="justify-content-right text-right d-sm-block d-xs-block d-md-none d-lg-none d-xl-none mb-2">
                                <ButtonGroup role="tablist">
                                  <Button
                                    aria-selected={eventPassTab === 0}
                                    className={`btn-tab mb-sm-3 mb-md-0 ${
                                      eventPassTab === 0 && "active"
                                    }`}
                                    outline
                                    color="dark"
                                    size="md"
                                    onClick={() => handleSelectEventPassTab(0)}
                                    type="button"
                                    role="tab"
                                  >
                                    <FontAwesomeIcon icon="fa-passport" />
                                  </Button>

                                  <Button
                                    aria-selected={eventPassTab === 1}
                                    className={`btn-tab mb-sm-3 mb-md-0 ${
                                      eventPassTab === 1 && "active"
                                    }`}
                                    outline
                                    color="dark"
                                    size="md"
                                    onClick={() => handleSelectEventPassTab(1)}
                                    type="button"
                                    role="tab"
                                  >
                                    <FontAwesomeIcon icon="fa-right-to-bracket" />
                                  </Button>

                                  <Button
                                    aria-selected={eventPassTab === 2}
                                    className={`btn-tab mb-sm-3 mb-md-0 ${
                                      eventPassTab === 2 && "active"
                                    }`}
                                    outline
                                    color="dark"
                                    size="md"
                                    onClick={() => handleSelectEventPassTab(2)}
                                    type="button"
                                    role="tab"
                                  >
                                    <FontAwesomeIcon icon="fa-credit-card" />
                                  </Button>
                                </ButtonGroup>
                              </div>
                            )}
                            {(hasAdminRole ||
                              event?.isOwner ||
                              event?.isCohost) && (
                              <TabPanel>
                                <div className="pt-2 pb-3 px-1">
                                  <Row>
                                    <Col
                                      xl="9"
                                      lg="10"
                                      md="11"
                                      sm="12"
                                      xs="12"
                                      className="my-lg-3"
                                    >
                                      {event ? (
                                        <EventPass
                                          event={event}
                                          eventId={eventId}
                                          updateEvent={setEvent}
                                          isEventActiveCreator={
                                            isEventActiveCreator
                                          }
                                        />
                                      ) : (
                                        <div className="mt-6 text-center">
                                          <BeatLoader
                                            color="#111"
                                            loading={pageLoading}
                                            height="10px"
                                            cssOverride={{
                                              display: "inline",
                                            }}
                                          />
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              </TabPanel>
                            )}
                            {(hasAdminRole ||
                              event?.isOwner ||
                              event?.isCohost) && (
                              <TabPanel>
                                <div className="pt-2 pb-3 px-1">
                                  <Row>
                                    <Col
                                      xl="9"
                                      lg="10"
                                      md="11"
                                      sm="12"
                                      xs="12"
                                      className="my-lg-3"
                                    >
                                      {event ? (
                                        <EventRegistration
                                          event={event}
                                          eventId={eventId}
                                          updateEvent={setEvent}
                                          updateEventName={setEventName}
                                          updateIsPublished={setIsPublished}
                                          updateIsPrivate={setIsPrivate}
                                          isEventActiveCreator={
                                            isEventActiveCreator
                                          }
                                        />
                                      ) : (
                                        <div className="mt-6 text-center">
                                          <BeatLoader
                                            color="#111"
                                            loading={pageLoading}
                                            height="10px"
                                            cssOverride={{
                                              display: "inline",
                                            }}
                                          />
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              </TabPanel>
                            )}
                            {(hasAdminRole ||
                              event?.isOwner ||
                              event?.isCohost) && (
                              <TabPanel>
                                <div className="pt-2 pb-3 px-1">
                                  <Row>
                                    <Col
                                      xl="9"
                                      lg="10"
                                      md="11"
                                      sm="12"
                                      xs="12"
                                      className="my-lg-3"
                                    >
                                      {event ? (
                                        <EventPayment
                                          event={event}
                                          eventId={eventId}
                                          updateEvent={setEvent}
                                          isEventActiveCreator={
                                            isEventActiveCreator
                                          }
                                        />
                                      ) : (
                                        <div className="mt-6 text-center">
                                          <BeatLoader
                                            color="#111"
                                            loading={pageLoading}
                                            height="10px"
                                            cssOverride={{
                                              display: "inline",
                                            }}
                                          />
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              </TabPanel>
                            )}
                          </Col>
                        </Row>
                      )
                    ) : (
                      <div className="mt-6 text-center">
                        <BeatLoader
                          color="#111"
                          loading={pageLoading}
                          height="10px"
                          cssOverride={{
                            display: "inline",
                          }}
                        />
                      </div>
                    )}
                  </Tabs>
                </TabPanel>
              )}
            </Tabs>
          </Container>
        </section>
      )}
      {event?.isCancelled && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            color: "white",
            zIndex: "999",
          }}
        ></div>
      )}
    </>
  );
}
