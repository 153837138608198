import React from "react";

// reactstrap components
import { Button, Card, CardBody, Container, Row, Col } from "reactstrap";

// core components
import TopNavbar from "components/Navbars/TopNavbar.js";
import LightFooter from "components/Footers/LightFooter.js";

export default function Privacy() {
  return (
    <>
      <TopNavbar />
      <section className="section section-shaped bg-secondary section-main">
        <Container>
          <Card className="card-main shadow">
            <div className="px-2 pb-4">
              <div className="text-center mt-5 mb-3">
                <h4 className="display-2 mt-3">Privacy Policy</h4>
                <small>Last updated: June 10, 2022</small>
              </div>
              <p>
                <small>
                  PASS LABS ("RealPass", "we", "us", or "our") is committed to
                  protecting your privacy. We have prepared this Privacy Policy
                  to describe our policies and procedures on the collection, use
                  and disclosure of your information when you use the Service
                  and tells you about your privacy rights and how the law
                  protects you. We use your Personal data to provide and improve
                  the Service. By using the Service, you agree to the collection
                  and use of information in accordance with this Privacy Policy.
                </small>
              </p>
              <ol className="mt-3 mb-4">
                <li className="terms-list">
                  <h5>Interpretation</h5>
                  <p>
                    <small>
                      The words of which the initial letter is capitalized have
                      meanings defined under the following conditions. The
                      following definitions shall have the same meaning
                      regardless of whether they appear in singular or in
                      plural.
                    </small>
                  </p>
                </li>
                <li className="terms-list">
                  <h5>Definitions</h5>
                  <p>
                    <small>For the purposes of this Privacy Policy:</small>
                  </p>
                  <ul>
                    <li>
                      <p>
                        <small>
                          <strong>Account</strong> means a unique account
                          created for you to access our Service or parts of our
                          Service.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          <strong>Company</strong> (referred to as either
                          &quot;the Company&quot;, &quot;We&quot;,
                          &quot;Us&quot; or &quot;Our&quot; in this Agreement)
                          refers to PASS LABS.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          <strong>Cookies</strong> are small files that are
                          placed on your computer, mobile device or any other
                          device by a website, containing the details of your
                          browsing history on that website among its many uses.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          <strong>Device</strong> means any device that can
                          access the Service such as a computer, a cellphone or
                          a digital tablet.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          <strong>Personal Data</strong> is any information that
                          relates to an identified or identifiable individual.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          <strong>Service</strong> refers to the website(s),
                          APIs, apps, RealPass NFTs, intellectual properties,
                          and any other tools.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          <strong>Service Provider</strong> means any natural or
                          legal person who processes the data on behalf of the
                          Company. It refers to third-party companies or
                          individuals employed by the Company to facilitate the
                          Service, to provide the Service on behalf of the
                          Company, to perform services related to the Service or
                          to assist the Company in analyzing how the Service is
                          used.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          <strong>Usage Data</strong> refers to data collected
                          automatically, either generated by the use of the
                          Service or from the Service infrastructure itself (for
                          example, the duration of a page visit).
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          <strong>Website</strong> refers to RealPass,
                          accessible from{" "}
                          <a
                            href="https://realpass.xyz"
                            rel="external nofollow noopener"
                            target="_blank"
                            className="text-info"
                          >
                            https://realpass.xyz
                          </a>
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          <strong>you</strong> means the individual accessing or
                          using the Service, or the company, or other legal
                          entity on behalf of which such individual is accessing
                          or using the Service, as applicable.
                        </small>
                      </p>
                    </li>
                  </ul>
                </li>
                <li className="terms-list">
                  <h5>Types of Data Collected</h5>
                  <ol className="mt-3 mb-4">
                    <li>
                      <h6>Personal Data</h6>
                      <p>
                        <small>
                          While using Our Service, We may ask you to provide Us
                          with certain personally identifiable information that
                          can be used to contact or identify you. Personally
                          identifiable information may include, but is not
                          limited to:
                        </small>
                      </p>
                      <ul>
                        <li>
                          <p>
                            <small>
                              Email address, Name, Phone number, Location,
                              Address, Usage Data, Wallet Data, Public
                              Blockchain Data
                            </small>
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <h6>Usage Data</h6>
                      <p>
                        <small>
                          Usage Data is collected automatically when using the
                          Service.
                        </small>
                      </p>
                      <p>
                        <small>
                          Usage Data may include information such as your
                          Device's Internet Protocol address (e.g. IP address),
                          browser type, browser version, the pages of our
                          Service that you visit, the time and date of your
                          visit, the time spent on those pages, unique device
                          identifiers and other diagnostic data.
                        </small>
                      </p>
                      <p>
                        <small>
                          When you access the Service by or through a mobile
                          device, We may collect certain information
                          automatically, including, but not limited to, the type
                          of mobile device you use, your mobile device unique
                          ID, the IP address of your mobile device, your mobile
                          operating system, the type of mobile Internet browser
                          you use, unique device identifiers and other
                          diagnostic data.
                        </small>
                      </p>
                      <p>
                        <small>
                          We may also collect information that your browser
                          sends whenever you visit our Service or when you
                          access the Service by or through a mobile device.
                        </small>
                      </p>
                    </li>
                    <li>
                      <h6>Tracking Technologies and Cookies</h6>
                      <p>
                        <small>
                          We use Cookies and similar tracking technologies to
                          track the activity on Our Service and store certain
                          information. Tracking technologies used are beacons,
                          tags, and scripts to collect and track information and
                          to improve and analyze Our Service. The technologies
                          We use may include:
                        </small>
                      </p>
                      <ul>
                        <li>
                          <small>
                            <strong>Cookies or Browser Cookies.</strong> A
                            cookie is a small file placed on your Device. you
                            can instruct your browser to refuse all Cookies or
                            to indicate when a Cookie is being sent. However, if
                            you do not accept Cookies, you may not be able to
                            use some parts of our Service. Unless you have
                            adjusted your browser setting so that it will refuse
                            Cookies, our Service may use Cookies.
                          </small>
                        </li>
                        <li>
                          <p>
                            <small>
                              <strong>Web Beacons.</strong> Certain sections of
                              our Service and our emails may contain small
                              electronic files known as web beacons (also
                              referred to as clear gifs, pixel tags, and
                              single-pixel gifs) that permit the Company, for
                              example, to count users who have visited those
                              pages or opened an email and for other related
                              website statistics (for example, recording the
                              popularity of a certain section and verifying
                              system and server integrity).
                            </small>
                          </p>
                        </li>
                      </ul>
                      <p>
                        <small>
                          Cookies can be &quot;Persistent&quot; or
                          &quot;Session&quot; Cookies. Persistent Cookies remain
                          on your personal computer or mobile device when you go
                          offline, while Session Cookies are deleted as soon as
                          you close your web browser.
                        </small>
                      </p>
                      <p>
                        <small>
                          We use both Session and Persistent Cookies for the
                          purposes set out below:
                        </small>
                      </p>
                      <ul>
                        <li>
                          <p>
                            <small>
                              <strong>Necessary / Essential Cookies</strong>
                            </small>
                          </p>
                          <p>
                            <small>Type: Session Cookies</small>
                          </p>
                          <p>
                            <small>Administered by: Us</small>
                          </p>
                          <p>
                            <small>
                              Purpose: These Cookies are essential to provide
                              you with services available through the Website
                              and to enable you to use some of its features.
                              They help to authenticate users and prevent
                              fraudulent use of user accounts. Without these
                              Cookies, the services that you have asked for
                              cannot be provided, and We only use these Cookies
                              to provide you with those services.
                            </small>
                          </p>
                        </li>
                        <li>
                          <p>
                            <small>
                              <strong>
                                Cookies Policy / Notice Acceptance Cookies
                              </strong>
                            </small>
                          </p>
                          <p>
                            <small>Type: Persistent Cookies</small>
                          </p>
                          <p>
                            <small>Administered by: Us</small>
                          </p>
                          <p>
                            <small>
                              Purpose: These Cookies identify if users have
                              accepted the use of cookies on the Website.
                            </small>
                          </p>
                        </li>
                        <li>
                          <p>
                            <small>
                              <strong>Functionality Cookies</strong>
                            </small>
                          </p>
                          <p>
                            <small>Type: Persistent Cookies</small>
                          </p>
                          <p>
                            <small>Administered by: Us</small>
                          </p>
                          <p>
                            <small>
                              Purpose: These Cookies allow us to remember
                              choices you make when you use the Website, such as
                              remembering your sign in details or language
                              preference. The purpose of these Cookies is to
                              provide you with a more personal experience and to
                              avoid you having to re-enter your preferences
                              every time you use the Website.
                            </small>
                          </p>
                        </li>
                      </ul>
                      <p>
                        <small>
                          For more information about the cookies we use and your
                          choices regarding cookies, please visit our Cookies
                          Policy or the Cookies section of our Privacy Policy.
                        </small>
                      </p>
                    </li>
                  </ol>
                </li>

                <li className="terms-list">
                  <h5>Use of your Personal Data</h5>
                  <p>
                    <small>
                      The Company may use Personal Data for the following
                      purposes:
                    </small>
                  </p>
                  <ul>
                    <li>
                      <p>
                        <small>
                          <strong>To provide and maintain our Service</strong>,
                          including to monitor the usage of our Service.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          <strong>To manage your Account:</strong> to manage
                          your registration as a user of the Service. The
                          Personal Data you provide can give you access to
                          different functionalities of the Service that are
                          available to you as a registered user.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          <strong>For the performance of a contract:</strong>{" "}
                          the development, compliance and undertaking of the
                          purchase contract for the products, items or services
                          you have purchased or of any other contract with Us
                          through the Service.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          <strong>To contact you:</strong> To contact you by
                          email, telephone calls, SMS, or other equivalent forms
                          of electronic communication, such as a mobile
                          application's push notifications regarding updates or
                          informative communications related to the
                          functionalities, products or contracted services,
                          including the security updates, when necessary or
                          reasonable for their implementation.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          <strong>To provide you</strong> with news, special
                          offers and general information about other goods,
                          services and events which we offer that are similar to
                          those that you have already purchased or enquired
                          about unless you have opted not to receive such
                          information.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          <strong>To manage your requests:</strong> To attend
                          and manage your requests to Us.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          <strong>For business transfers:</strong> We may use
                          your information to evaluate or conduct a merger,
                          divestiture, restructuring, reorganization,
                          dissolution, or other sale or transfer of some or all
                          of Our assets, whether as a going concern or as part
                          of bankruptcy, liquidation, or similar proceeding, in
                          which Personal Data held by Us about our Service users
                          is among the assets transferred.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          <strong>For other purposes</strong>: We may use your
                          information for other purposes, such as data analysis,
                          identifying usage trends, determining the
                          effectiveness of our promotional campaigns and to
                          evaluate and improve our Service, products, services,
                          marketing and your experience.
                        </small>
                      </p>
                    </li>
                  </ul>
                  <p>
                    <small>
                      We may share your personal information in the following
                      situations:
                    </small>
                  </p>
                  <ul>
                    <li>
                      <p>
                        <small>
                          <strong>With Service Providers:</strong> We may share
                          your personal information with Service Providers to
                          monitor and analyze the use of our Service, to contact
                          you.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          <strong>For business transfers:</strong> We may share
                          or transfer your personal information in connection
                          with, or during negotiations of, any merger, sale of
                          Company assets, financing, or acquisition of all or a
                          portion of Our business to another company.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          <strong>With Affiliates:</strong> We may share your
                          information with Our affiliates, in which case we will
                          require those affiliates to honor this Privacy Policy.
                          Affiliates include Our parent company and any other
                          subsidiaries, joint venture partners or other
                          companies that We control or that are under common
                          control with Us.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          <strong>With business partners:</strong> We may share
                          your information with Our business partners to offer
                          you certain products, services or promotions.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          <strong>With other users:</strong> when you share
                          personal information or otherwise interact in the
                          public areas with other users, such information may be
                          viewed by all users and may be publicly distributed
                          outside. If you interact with other users or register
                          through a Third-Party Social Media Service, your
                          contacts on the Third-Party Social Media Service may
                          see your name, profile, pictures and description of
                          your activity. Similarly, other users will be able to
                          view descriptions of your activity, communicate with
                          you and view your profile.
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          <strong>With your consent</strong>: We may disclose
                          your personal information for any other purpose with
                          your consent.
                        </small>
                      </p>
                    </li>
                  </ul>
                </li>
                <li className="terms-list">
                  <h5>Retention of your Personal Data</h5>
                  <p>
                    <small>
                      The Company will retain your Personal Data only for as
                      long as is necessary for the purposes set out in this
                      Privacy Policy. We will retain and use your Personal Data
                      to the extent necessary to comply with our legal
                      obligations (for example, if we are required to retain
                      your data to comply with applicable laws), resolve
                      disputes, and enforce our legal agreements and policies.
                    </small>
                  </p>
                  <p>
                    <small>
                      The Company will also retain Usage Data for internal
                      analysis purposes. Usage Data is generally retained for a
                      shorter period of time, except when this data is used to
                      strengthen the security or to improve the functionality of
                      Our Service, or We are legally obligated to retain this
                      data for longer time periods.
                    </small>
                  </p>
                </li>
                <li className="terms-list">
                  <h5>Transfer of your Personal Data</h5>
                  <p>
                    <small>
                      Your information, including Personal Data, is processed at
                      the Company's operating offices and in any other places
                      where the parties involved in the processing are located.
                      It means that this information may be transferred to — and
                      maintained on — computers located outside of your state,
                      province, country or other governmental jurisdiction where
                      the data protection laws may differ than those from your
                      jurisdiction.
                    </small>
                  </p>
                  <p>
                    <small>
                      Your consent to this Privacy Policy followed by your
                      submission of such information represents your agreement
                      to that transfer.
                    </small>
                  </p>
                  <p>
                    <small>
                      The Company will take all steps reasonably necessary to
                      ensure that your data is treated securely and in
                      accordance with this Privacy Policy and no transfer of
                      your Personal Data will take place to an organization or a
                      country unless there are adequate controls in place
                      including the security of your data and other personal
                      information.
                    </small>
                  </p>
                </li>
                <li className="terms-list">
                  <h5>Disclosure of your Personal Data</h5>
                  <h6>Business Transactions</h6>
                  <p>
                    <small>
                      If the Company is involved in a merger, acquisition or
                      asset sale, your Personal Data may be transferred. We will
                      provide notice before your Personal Data is transferred
                      and becomes subject to a different Privacy Policy.
                    </small>
                  </p>
                  <h6>Law enforcement</h6>
                  <p>
                    <small>
                      Under certain circumstances, the Company may be required
                      to disclose your Personal Data if required to do so by law
                      or in response to valid requests by public authorities
                      (e.g. a court or a government agency).
                    </small>
                  </p>
                  <h6>Other legal requirements</h6>
                  <p>
                    <small>
                      The Company may disclose your Personal Data in the good
                      faith belief that such action is necessary to:
                    </small>
                  </p>
                  <ul>
                    <li>
                      <p>
                        <small>Comply with a legal obligation</small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          Protect and defend the rights or property of the
                          Company
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          Prevent or investigate possible wrongdoing in
                          connection with the Service
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          Protect the personal safety of Users of the Service or
                          the public
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>Protect against legal liability</small>
                      </p>
                    </li>
                  </ul>
                </li>
                <li className="terms-list">
                  <h5>Security of your Personal Data</h5>
                  <p>
                    <small>
                      The security of your Personal Data is important to Us, but
                      remember that no method of transmission over the Internet,
                      or method of electronic storage is 100% secure. While We
                      strive to use commercially acceptable means to protect
                      your Personal Data, We cannot guarantee its absolute
                      security.
                    </small>
                  </p>
                </li>

                <li className="terms-list">
                  <h5>Children's Privacy</h5>
                  <p>
                    <small>
                      Our Service does not address anyone under the age of 13.
                      We do not knowingly collect personally identifiable
                      information from anyone under the age of 13. If you are a
                      parent or guardian and you are aware that your child has
                      provided Us with Personal Data, please contact Us. If We
                      become aware that We have collected Personal Data from
                      anyone under the age of 13 without verification of
                      parental consent, We take steps to remove that information
                      from Our servers.
                    </small>
                  </p>
                  <p>
                    <small>
                      If We need to rely on consent as a legal basis for
                      processing your information and your country requires
                      consent from a parent, We may require your parent's
                      consent before We collect and use that information.
                    </small>
                  </p>
                </li>
                <li className="terms-list">
                  <h5>Links to Other Websites</h5>
                  <p>
                    <small>
                      Our Service may contain links to other websites that are
                      not operated by Us. If you click on a third party link,
                      you will be directed to that third party's site. We
                      strongly advise you to review the Privacy Policy of every
                      site you visit.
                    </small>
                  </p>
                  <p>
                    <small>
                      We have no control over and assume no responsibility for
                      the content, privacy policies or practices of any third
                      party sites or services.
                    </small>
                  </p>
                </li>
                <li className="terms-list">
                  <h5>Changes to this Privacy Policy</h5>
                  <p>
                    <small>
                      We may update Our Privacy Policy from time to time. We
                      will notify you of any changes by posting the new Privacy
                      Policy on this page.
                    </small>
                  </p>
                  <p>
                    <small>
                      We will let you know via email and/or a prominent notice
                      on Our Service, prior to the change becoming effective and
                      update the &quot;Last updated&quot; date at the top of
                      this Privacy Policy.
                    </small>
                  </p>
                  <p>
                    <small>
                      you are advised to review this Privacy Policy periodically
                      for any changes. Changes to this Privacy Policy are
                      effective when they are posted on this page.
                    </small>
                  </p>
                </li>
                <li className="terms-list">
                  <h5>Contact Us</h5>
                  <p>
                    <small>
                      If you have any questions about this Privacy Policy, you
                      can contact us:
                    </small>
                  </p>
                  <ul>
                    <li>
                      <p>
                        <small>
                          By email:{" "}
                          <a
                            href="mailto:gm@passlabs.xyz"
                            rel="external nofollow noopener"
                            target="_blank"
                            className="text-info"
                          >
                            gm@passlabs.xyz
                          </a>
                        </small>
                      </p>
                    </li>
                    <li>
                      <p>
                        <small>
                          By visiting this page on our website:{" "}
                          <a
                            href="https://realpass.xyz/privacy"
                            rel="external nofollow noopener"
                            target="_blank"
                            className="text-info"
                          >
                            https://realpass.xyz/privacy
                          </a>
                        </small>
                      </p>
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
          </Card>
        </Container>
      </section>
      <LightFooter />
    </>
  );
}
