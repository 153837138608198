import React, { useState, useContext, useEffect } from "react";

import { AuthContext } from "Auth.js";

// reactstrap components
import {
  Alert,
  ButtonGroup,
  Button,
  Collapse,
  Container,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Card,
  CardTitle,
  CardImg,
  CardBody,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Table,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import GooglePlaceAutocomplete from "react-google-autocomplete";

import { Form, Field } from "react-final-form";
import Select from "react-select";

import {
  notify,
  genImageName,
  nDaysAfterTZ,
  handleReactDatetimeChange,
  getClassNameReactDatetimeDays,
  hashCode,
  formatPrettyNumber,
  formatCurrency,
} from "utils/Utils.js";

import { firebaseUpload, creatorUpdateEventDetail } from "utils/Api.js";

import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.compat.css";

import ScaleLoader from "react-spinners/ScaleLoader";
import BarLoader from "react-spinners/BarLoader";

import ReactDatetime from "react-datetime";
import Moment from "react-moment";
import moment from "moment-timezone";

import TimezoneSelect, { allTimezones } from "react-timezone-select";

import PaymentSplitsEditable from "components/Lists/PaymentSplitsEditable.js";

const {
  defaultFormImg,
  defaultFormBanner,
  DefaultLocation,
  ChainOptions,
  EventCategoryOptions,
  EventCategorySelectorOptions,
  CreatorRoleTypes,
  DisableMaxUsageOption,
  InviteMaxUsageOptions,
  InviteExpireOptions,
  ChainSelectorOptions,
  CurrencySelectorOptions,
  USDateOption,
  UserRoleTypes,
  UserRoleStatus,
  VerifiedUserTypes,
  VerifiedUserStatus,
  QuillEditorModules,
  QuillEditorFormats,
} = require("utils/Variables.js");

const { REACT_APP_HOST } = process.env;

export default function EventPayment(props) {
  let {
    event,
    eventId,
    updateEvent,
    updateEventName,
    updateIsPublished,
    isEventActiveCreator,
  } = props;

  const {
    currentUser,
    currentUserId,
    currentAuthToken,
    currentUserInfo,
    setCurrentUserInfo,
  } = useContext(AuthContext);

  const hasAdminRole = currentUserInfo?.hasAdminRole;
  const hasCreatorRole = currentUserInfo?.hasCreatorRole;
  const isVerifiedUser = currentUserInfo?.isVerifiedUser;

  const [dataSaving, setDataSaving] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  const [eventImageUrl, setEventImageUrl] = useState(event?.imageUrl || "");
  const [eventImageName, setEventImageName] = useState("");
  const [eventImageData, setEventImageData] = useState();

  const [bannerImageUrl, setBannerImageUrl] = useState(event?.bannerUrl || "");
  const [bannerImageName, setBannerImageName] = useState("");
  const [bannerImageData, setBannerImageData] = useState();

  const [name, setName] = useState(event?.name || "");
  const [description, setDescription] = useState(event?.description || "");

  const [isVirtual, setIsVirtual] = useState(!!event?.isVirtual);
  const [virtualLocation, setVirtualLocation] = useState(
    isVirtual && event?.location ? event?.location : DefaultLocation
  );
  const [irlLocation, setIrlLocation] = useState(
    !isVirtual && event?.location ? event?.location : DefaultLocation
  );
  const [isLocationRestricted, setIsLocationRestricted] = useState(
    !!event?.isLocationRestricted
  );

  const [categoryOption, setCategoryOption] = useState(
    EventCategorySelectorOptions[0]
  );

  const [selectedTimezoneValue, setSelectedTimezoneValue] = useState(
    event?.timezone || moment.tz.guess()
  );
  const [selectedTimezoneLabel, setSelectedTimezoneLabel] = useState(
    `${event?.timezone || moment.tz.guess()} (${moment
      .tz(event?.timezone || moment.tz.guess())
      .format("z")})`?.replace("_", " ")
  );
  const [selectedTimezone, setSelectedTimezone] = useState(
    event?.timezone || moment.tz.guess()
  );

  const [startDateMoment, setStartDateMoment] = useState(
    nDaysAfterTZ(1, event?.timezone || moment.tz.guess())
  );
  const [endDateMoment, setEndDateMoment] = useState(
    nDaysAfterTZ(2, event?.timezone || moment.tz.guess())
  );

  const [isMultiSession, setIsMultiSession] = useState(!!event?.isMultiSession);

  const [sessions, setSessions] = useState([]);

  const [isNSFW, setIsNSFW] = useState(!!event?.isNSFW);
  const [isPublished, setIsPublished] = useState(!!event?.isPublished);

  const [durationError, setDurationError] = useState();
  const [locationError, setLocationError] = useState();

  const [maxUsers, setMaxUsers] = useState("∞");
  const [unlimitedUsers, setUnlimitedUsers] = useState(true);

  // const [isSplitPayment, setIsSplitPayment] = useState(false);

  const [paymentBlockchainOption, setPaymentBlockchainOption] = useState(
    ChainSelectorOptions[0]
  );

  const CurrencyOption = CurrencySelectorOptions.find(
    (option) => option.value === event?.paymentCurrency
  );

  const [paymentCurrencyOption, setPaymentCurrencyOption] = useState(
    CurrencyOption || CurrencySelectorOptions[0]
  );

  const [payoutWallets, setPayoutWallets] = useState(
    event?.payoutWallets || []
  );
  const [walletError, setWalletError] = useState();

  const saveEventPayment = async () => {
    try {
      setDataSaving(true);

      let imageUrl = eventImageUrl;
      let bannerUrl = bannerImageUrl;

      if (eventImageData) {
        imageUrl = await firebaseUpload(
          eventImageData,
          genImageName(currentUserId, "event", eventImageName, true)
        );

        setEventImageUrl(imageUrl);
      }

      if (bannerImageData) {
        bannerUrl = await firebaseUpload(
          bannerImageData,
          genImageName(currentUserId, "event", bannerImageName, true)
        );

        setBannerImageUrl(bannerUrl);
      }

      const eventData = {
        imageUrl,
        bannerUrl,
        name,
        description,
        category: categoryOption.value,
        isMultiSession,
        isVirtual,
        isLocationRestricted,
        isNSFW,
        isPublished,
        timezone: selectedTimezoneValue,
      };

      if (!isMultiSession) {
        eventData.startDateTime = new Date(startDateMoment);
        eventData.endDateTime = new Date(endDateMoment);
      } else {
        eventData.sessions = sessions;
      }

      if (isVirtual) {
        eventData.location = {
          name: virtualLocation.name,
        };
      } else {
        eventData.location = {
          name: irlLocation.name,
          lat: irlLocation.lat,
          lng: irlLocation.lng,
        };
      }

      const res = await creatorUpdateEventDetail(
        currentAuthToken,
        eventId,
        eventData
      );

      if (res?.success) {
        setEventImageName("");
        setEventImageData();

        setBannerImageName("");
        setBannerImageData();

        updateEvent({ ...event, ...res });
        updateEventName(name);
        updateIsPublished(isPublished);

        notify("Successfully updated event.", "success");
      } else {
        notify("Unable to update event.", "error");
      }
    } catch (err) {
      console.error(err.message);
      notify("Unable to update event.", "error");
    } finally {
      setDataSaving(false);
      setFormChanged(false);
    }
  };

  // const toggleSplitPayment = (splitFlag) => {
  //   if (splitFlag !== isSplitPayment) {
  //     setIsSplitPayment(splitFlag);
  //   }
  // };

  return (
    <>
      {(hasAdminRole || isEventActiveCreator) && (
        <>
          <Row className="mb-5">
            <Col xl="6" lg="6" md="6" sm="6" xs="6" className="p-2">
              <Card className="card-stats shadow">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle className="text-muted mb-0">
                        <small>Sales</small>
                      </CardTitle>
                      <span className="h5 font-weight-bold mb-0">
                        {formatPrettyNumber(event?.totalSales)}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                        <FontAwesomeIcon icon="fa-cash-register" />
                      </div>
                    </Col>
                  </Row>
                  {/* <p className="mt-3 mb-0 text-sm">
                    <span
                      className={`mr-2 ${
                        totalLikes24Hour >= 0 ? "text-success" : "text-warning"
                      }`}
                    >
                      <FontAwesomeIcon
                        icon={
                          totalLikes24Hour >= 0
                            ? "fa-arrow-up"
                            : "fa-arrow-down"
                        }
                      />{" "}
                      {formatPrettyNumber(totalLikes24Hour)}
                    </span>{" "}
                    <small className="text-nowrap">24H</small>
                  </p> */}
                </CardBody>
              </Card>
            </Col>
            <Col xl="6" lg="6" md="6" sm="6" xs="6" className="p-2">
              <Card className="card-stats shadow">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle className="text-muted mb-0">
                        <small>Payouts</small>
                      </CardTitle>
                      <span className="h5 font-weight-bold mb-0">
                        {formatPrettyNumber(event?.totalPayouts)}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                        <FontAwesomeIcon icon="fa-file-invoice-dollar" />
                      </div>
                    </Col>
                  </Row>
                  {/* <p className="mt-3 mb-0 text-sm">
                    <span
                      className={`mr-2 ${
                        totalUsers24Hour >= 0 ? "text-success" : "text-warning"
                      }`}
                    >
                      <FontAwesomeIcon
                        icon={
                          totalUsers24Hour >= 0
                            ? "fa-arrow-up"
                            : "fa-arrow-down"
                        }
                      />{" "}
                      {formatPrettyNumber(totalUsers24Hour)}
                    </span>{" "}
                    <small className="text-nowrap">24H</small>
                  </p> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Form
            onSubmit={() => {}}
            validate={(values) => {
              const errors = {};

              if (!maxUsers) {
                errors.maxUsers = "Max members is required.";
              }

              if (
                !unlimitedUsers &&
                (isNaN(Number(maxUsers)) || Number(maxUsers) < 0)
              ) {
                errors.maxUsers =
                  "Max members must be greater than or equal to 0.";
              }

              if (
                !unlimitedUsers &&
                Number(maxUsers) < event?.totalRegistered
              ) {
                errors.maxUsers = `Max members is less than ${event?.totalRegistered} of total members.`;
              }

              if (!paymentCurrencyOption.value) {
                errors.coin = "Currency is required.";
              }

              return errors;
            }}
            render={({
              handleSubmit,
              values,
              submitting,
              validating,
              valid,
            }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <h5 className="text-default">
                    <strong>Payment</strong>
                  </h5>
                  <div className="m-2">
                    <Row>
                      <Col>
                        <FormGroup>
                          <Field name="blockchain">
                            {({ input, meta }) => (
                              <>
                                <Row className="mt-2 mb-1">
                                  <Col>
                                    <div
                                      htmlFor="blockchainInput"
                                      className="mb-1"
                                    >
                                      <small>
                                        <strong>Blockchain</strong>
                                      </small>
                                    </div>
                                    <InputGroup id="blockchainInput">
                                      <Select
                                        {...input}
                                        id="blockchain"
                                        className="realpass-selector"
                                        onChange={(e) => {
                                          setPaymentBlockchainOption(e);
                                        }}
                                        options={ChainSelectorOptions}
                                        value={paymentBlockchainOption}
                                        components={{
                                          IndicatorSeparator: () => null,
                                        }}
                                      />
                                    </InputGroup>

                                    {meta.error && meta.touched && (
                                      <small className="text-danger">
                                        <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                        {meta.error}
                                      </small>
                                    )}
                                  </Col>
                                </Row>
                              </>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <FormGroup>
                          <Field name="coin">
                            {({ input, meta }) => (
                              <>
                                <Row className="mt-2 mb-1">
                                  <Col>
                                    <div htmlFor="coinInput" className="mb-1">
                                      <small>
                                        <strong>Currency</strong>
                                      </small>
                                    </div>
                                    <InputGroup id="coinInput">
                                      <Select
                                        {...input}
                                        id="coin"
                                        className="realpass-selector"
                                        onChange={(e) => {
                                          setPaymentCurrencyOption(e);
                                        }}
                                        options={CurrencySelectorOptions}
                                        value={paymentCurrencyOption}
                                        components={{
                                          IndicatorSeparator: () => null,
                                        }}
                                      />
                                    </InputGroup>

                                    {meta.error && meta.touched && (
                                      <small className="text-danger">
                                        <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                        {meta.error}
                                      </small>
                                    )}
                                  </Col>
                                </Row>
                              </>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mt-4 mb-2">
                          <Row>
                            <Col className="col-7">
                              <Label>
                                <div>
                                  <strong>Payout Wallet</strong>
                                </div>
                                <small className="text-muted">
                                  ETH wallet address for payouts
                                </small>
                              </Label>
                            </Col>
                            <Col>
                              {/* <ButtonGroup
                                role="tablist"
                                className="float-right"
                              >
                                <Button
                                  aria-selected={!isSplitPayment}
                                  className={`btn-tab mb-sm-3 mb-md-0 ${
                                    !isSplitPayment && "active"
                                  }`}
                                  outline
                                  color="dark"
                                  size="sm"
                                  onClick={(e) => {
                                    toggleSplitPayment(false);
                                    setFormChanged(true);
                                  }}
                                  type="button"
                                  role="tab"
                                >
                                  Simple
                                </Button>
                                <Button
                                  aria-selected={isSplitPayment}
                                  className={`btn-tab mb-sm-3 mb-md-0 ${
                                    isSplitPayment && "active"
                                  }`}
                                  outline
                                  color="dark"
                                  size="sm"
                                  onClick={(e) => {
                                    toggleSplitPayment(true);
                                    setFormChanged(true);
                                  }}
                                  type="button"
                                  role="tab"
                                >
                                  Split
                                </Button>
                              </ButtonGroup> */}
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <PaymentSplitsEditable
                                wallets={payoutWallets}
                                updateWallets={setPayoutWallets}
                                updateFormChanged={setFormChanged}
                                updateWalletError={setWalletError}
                              />
                            </Col>
                          </Row>
                          {/* <FormGroup>
                            <Field name="team">
                              {({ input, meta }) => (
                                <>
                                  <Row>
                                    <Col>
                                      {
                                        <>
                                          <InputGroup>
                                            <InputGroupText className="input-group-left text-muted">
                                              <FontAwesomeIcon icon="fa-wallet" />
                                            </InputGroupText>
                                            <Input
                                              id="payoutWallet"
                                              value={payoutWallets[0]}
                                              onChange={(e) =>
                                                setPayoutWallets([
                                                  {
                                                    "address":
                                                      e.target.value.toLowerCase(),
                                                    "percent": 100,
                                                  },
                                                ])
                                              }
                                              placeholder="0xabc123..."
                                              type="text"
                                              className="input-group-mid"
                                            />
                                          </InputGroup>

                                          {walletError && (
                                            <small className="text-danger">
                                              <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                              {walletError}
                                            </small>
                                          )}
                                        </>
                                      }
                                    </Col>
                                  </Row>
                                </>
                              )}
                            </Field>
                          </FormGroup> */}
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="mt-5 mb-3">
                    <Button
                      className="btn btn-dark mt-4 btn-block"
                      color="dark"
                      type="button"
                      onClick={saveEventPayment}
                      disabled={
                        !valid || dataSaving || !formChanged || walletError
                      }
                    >
                      Update Payment
                      {dataSaving && (
                        <span className="ml-2">
                          <ScaleLoader
                            color="#eee"
                            loading={dataSaving}
                            height="10px"
                            cssOverride={{
                              display: "inline",
                            }}
                          />
                        </span>
                      )}
                    </Button>
                  </div>
                </form>
              </>
            )}
          />
        </>
      )}
    </>
  );
}
