import React from "react";

// reactstrap components
import {
  Alert,
  ButtonGroup,
  Button,
  Collapse,
  Card,
  Container,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  CardImg,
  CardBody,
  CardTitle,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Table,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { SocialLinks } from "social-links";
const socialLinks = new SocialLinks();

export default function SocialLinksList(props) {
  let { links, size } = props;

  const linkList = links.map((item, index) => {
    const urlStr = item?.trim().toLowerCase();

    return {
      url: item,
      type: urlStr.startsWith("discord")
        ? "discord"
        : socialLinks.detectProfile(item),
    };
  });

  return linkList.map((item, index) => {
    return (
      <span key={index}>
        <a
          href={`https://${item.url}`}
          target='_blank'
          rel='external nofollow noopener'
        >
          {item.type === "twitter" ? (
            <FontAwesomeIcon
              icon='fa-brands fa-twitter'
              className={`text-muted mx-2 mt-1 fa-${size}`}
            />
          ) : item.type === "youtube" ? (
            <FontAwesomeIcon
              icon='fa-brands fa-youtube'
              className={`text-muted mx-2 mt-1 fa-${size}`}
            />
          ) : item.type === "github" ? (
            <FontAwesomeIcon
              icon='fa-brands fa-github'
              className={`text-muted mx-2 mt-1 fa-${size}`}
            />
          ) : item.type === "linkedin" ? (
            <FontAwesomeIcon
              icon='fa-brands fa-linkedin'
              className={`text-muted mx-2 mt-1 fa-${size}`}
            />
          ) : item.type === "medium" ? (
            <FontAwesomeIcon
              icon='fa-brands fa-medium'
              className={`text-muted mx-2 mt-1 fa-${size}`}
            />
          ) : item.type === "instagram" ? (
            <FontAwesomeIcon
              icon='fa-brands fa-instagram'
              className={`text-muted mx-2 mt-1 fa-${size}`}
            />
          ) : item.type === "telegram" ? (
            <FontAwesomeIcon
              icon='fa-brands fa-telegram'
              className={`text-muted mx-2 mt-1 fa-${size}`}
            />
          ) : item.type === "discord" ? (
            <FontAwesomeIcon
              icon='fa-brands fa-discord'
              className={`text-muted mx-2 mt-1 fa-${size}`}
            />
          ) : item.type === "spotify" ? (
            <FontAwesomeIcon
              icon='fa-brands fa-spotify'
              className={`text-muted mx-2 mt-1 fa-${size}`}
            />
          ) : item.type === "soundcloud" ? (
            <FontAwesomeIcon
              icon='fa-brands fa-soundcloud'
              className={`text-muted mx-2 mt-1 fa-${size}`}
            />
          ) : item.type === "pinterest" ? (
            <FontAwesomeIcon
              icon='fa-brands fa-pinterest'
              className={`text-muted mx-2 mt-1 fa-${size}`}
            />
          ) : item.type === "tiktok" ? (
            <FontAwesomeIcon
              icon='fa-brands fa-tiktok'
              className={`text-muted mx-2 mt-1 fa-${size}`}
            />
          ) : item.type === "twitch" ? (
            <FontAwesomeIcon
              icon='fa-brands fa-twitch'
              className={`text-muted mx-2 mt-1 fa-${size}`}
            />
          ) : item.type === "dribbble" ? (
            <FontAwesomeIcon
              icon='fa-brands fa-dribbble'
              className={`text-muted mx-2 mt-1 fa-${size}`}
            />
          ) : item.type === "facebook" ? (
            <FontAwesomeIcon
              icon='fa-brands fa-facebook'
              className={`text-muted mx-2 mt-1 fa-${size}`}
            />
          ) : item.type === "patreon" ? (
            <FontAwesomeIcon
              icon='fa-brands fa-patreon'
              className={`text-muted mx-2 mt-1 fa-${size}`}
            />
          ) : (
            <FontAwesomeIcon
              icon={`fa-globe`}
              className={`text-muted mx-2 mt-1 fa-${size}`}
            />
          )}
        </a>
      </span>
    );
  });
}
