import React, { useState, useEffect, useContext } from "react";
import {
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";

import Select from "react-select";

// reactstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Label,
  UncontrolledTooltip,
} from "reactstrap";

import ReactDatetime from "react-datetime";

import { AuthContext } from "Auth.js";

// core components
import TopNavbar from "components/Navbars/TopNavbar.js";
import Loader from "components/Loaders/Loader.js";

import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.compat.css";

const {
  defaultFormImg,
  defaultFormBanner,
  defaultProfileImg,
  bgGradient,
  defaultBoxGrayImg,
  verifiedImg,
  ethImg,
  nsfwImg,
  mmImg,
  wcImg,
  pwImg,
  ethWhiteImg,
  ethLogoColor,
  polygonImg,
  polygonWhiteImg,
  polygonLogoColor,
  USDateShortOption,
  USDateOption,
  ChainOptions,
} = require("utils/Variables.js");

export default function PassCreate() {
  const navigate = useNavigate();

  const {
    currentUser,
    currentAuthToken,
    currentUserInfo,
    setCurrentUserInfo,
    currentUserId,
    currentUserEthAddress,
  } = useContext(AuthContext);

  const hasCreatorRole = currentUserInfo?.hasCreatorRole;
  const isVerifiedUser = currentUserInfo?.isVerifiedUser;

  const [pageLoading, setPageLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [dataSaving, setDataSaving] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [name, setName] = useState();
  const [symbol, setSymbol] = useState();

  const [description, setDescription] = useState();
  const [blockchainOption, setBlockchainOption] = useState(ChainOptions[0]);
  const [price, setPrice] = useState();
  const [maxSupply, setMaxSupply] = useState(1);
  const [unlimitedSupply, setUnlimitedSupply] = useState(false);
  const [maxMints, setMaxMints] = useState(1);

  const [isRefundable, setIsRefundable] = useState(true);
  const [refundEndDate, setRefundEndDate] = useState();

  const [passImage, setPassImage] = useState();

  const [website, setWebsite] = useState();
  const [twitter, setTwitter] = useState();
  const [discord, setDiscord] = useState();
  const [instagram, setInstagram] = useState();

  const uploadImage = async (e) => {
    const data = e.target.files[0];
    setPassImage(URL.createObjectURL(data));
  };

  const savePass = async () => {
    // try {
    //   setLoading(true);
    //   const NFPass = Moralis.Object.extend("NFPass");
    //   const nfpass = new NFPass();
    //   nfpass.set("name", name);
    //   nfpass.set("description", description);
    //   nfpass.set("blockchain", blockchainOption.value);
    //   nfpass.set("price", price);
    //   nfpass.set("website", website);
    //   nfpass.set("twitter", twitter);
    //   nfpass.set("discord", discord);
    //   nfpass.set("instagram", instagram);
    //   await nfpass.save();
    // } catch (err) {
    //   console.error(err.message);
    // } finally {
    //   setLoading(false);
    // }
  };

  const toggleUnlimitedSupply = () => {
    const unlimited = !unlimitedSupply;
    setUnlimitedSupply(unlimited);
    unlimited ? setMaxSupply(2 ** 256) : setMaxSupply(1);
  };

  const toggleRefundable = () => {
    const refundableFlag = !isRefundable;
    setIsRefundable(refundableFlag);
    setRefundEndDate();
  };

  return (
    <>
      <TopNavbar />
      <ReactNotifications />
      <section className="section section-shaped bg-secondary section-main">
        <Loader loading={loading} />
        <Container>
          <h3 className="mb-3">
            <strong>Create Pass</strong>
          </h3>
          <Card className="card-main shadow">
            <Form>
              <div className="px-2 m-2">
                <Row className="mt-4 mb-4">
                  <Col lg="8" className="mr-3">
                    <div className="mt-4 mb-4">
                      <Label>
                        <div className="required">
                          <strong>Pass Image</strong>
                        </div>
                        <small className="text-muted">
                          Used for the Pass NFT
                          <br />
                          Recommended 1200 x 1200 1MB
                        </small>
                      </Label>
                      <Label
                        className="ml-3 image-upload-label"
                        for="passUpload"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          alt="..."
                          className="pass-image-edit"
                          src={passImage || defaultFormImg}
                        />
                        <div className="image-overlay">
                          <FontAwesomeIcon
                            icon="fa-pencil"
                            className="text-muted hidden-icon"
                          />
                        </div>
                      </Label>
                      <small>
                        <FontAwesomeIcon
                          icon="fa-circle-info"
                          className="text-muted ml-1"
                          id="profileImageTooltip"
                          style={{ cursor: "pointer" }}
                        />
                      </small>
                      <UncontrolledTooltip
                        placement="top"
                        target="profileImageTooltip"
                        trigger="hover"
                      >
                        Pass image to be stored in IPFS
                      </UncontrolledTooltip>
                      <Input
                        id="passUpload"
                        name="file"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={uploadImage}
                      />
                    </div>

                    <div className="mt-4 mb-4">
                      <Label for="name">
                        <div className="required">
                          <strong>Name &amp; Symbol</strong>
                        </div>
                        <small className="text-muted">
                          How would you like to identify your token?
                          <FontAwesomeIcon
                            icon="fa-circle-info"
                            className="text-muted ml-1"
                            id="nameSymbolTooltip"
                            style={{ cursor: "pointer" }}
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target="nameSymbolTooltip"
                            trigger="hover"
                          >
                            Name &amp; Symbol for ERC721 NFT
                          </UncontrolledTooltip>
                        </small>
                      </Label>

                      <InputGroup>
                        <InputGroupText className="input-group-left pr-2">
                          <FontAwesomeIcon icon={"fa-signature"} />
                        </InputGroupText>

                        <Input
                          id="name"
                          style={{ width: "28%" }}
                          className="input-group-mid pl-2"
                          onChange={(e) => setName(e.target.value)}
                          placeholder={name || "Token Name"}
                          type="text"
                          required
                        />

                        <InputGroupText className="input-group-mid">
                          <FontAwesomeIcon icon={"fa-dollar-sign"} />
                        </InputGroupText>

                        <Input
                          id="symbol"
                          className="input-group-right pl-2"
                          onChange={(e) => setSymbol(e.target.value)}
                          placeholder={symbol || "SYMBOL"}
                          type="text"
                          required
                        />
                      </InputGroup>
                    </div>
                    <div className="mt-4 mb-4">
                      <Label for="description">
                        <strong>Description</strong>
                      </Label>
                      <Input
                        id="description"
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder={
                          description || "What is this pass about ..."
                        }
                        rows="4"
                        maxLength="1000"
                        type="textarea"
                      />
                    </div>

                    <div className="mt-4 mb-4">
                      <Label for="blockchain">
                        <strong>Blockchain</strong>
                      </Label>
                      <InputGroup>
                        <Select
                          className="realpass-selector"
                          value={blockchainOption}
                          onChange={(e) => setBlockchainOption(e)}
                          options={ChainOptions}
                          defaultValue={ChainOptions[0]}
                        />
                      </InputGroup>
                    </div>

                    <div className="mt-4 mb-4">
                      <Label for="price" className="required">
                        <strong>Price</strong>
                      </Label>
                      <InputGroup>
                        <InputGroupText className="input-group-left pr-2">
                          {blockchainOption?.value === "eth" && (
                            <>
                              <img src={ethImg} className="icon-eth mr-2" />
                              ETH
                            </>
                          )}
                          {blockchainOption?.value === "matic" && (
                            <>
                              <img
                                src={polygonImg}
                                className="icon-polygon mr-2"
                              />
                              MATIC
                            </>
                          )}
                        </InputGroupText>

                        <Input
                          id="price"
                          className="pl-2"
                          onChange={(e) => setPrice(e.target.value)}
                          placeholder={price || "Amount"}
                          required
                        />
                      </InputGroup>
                    </div>

                    <div className="mt-4 mb-4">
                      <Label for="maxSupply">
                        <div>
                          <span className="required">
                            <strong>Supply</strong>
                          </span>
                          <span className="ml-5">
                            <Input
                              className="custom-control-input"
                              id="unlimitedSupplyCheck"
                              type="checkbox"
                              checked={unlimitedSupply}
                              onChange={toggleUnlimitedSupply}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="unlimitedSupplyCheck"
                            >
                              <span>Unlimited Supply</span>
                            </label>
                          </span>
                        </div>
                        <small className="text-muted">
                          Number of NFT Passes available to mint
                        </small>
                      </Label>
                      <InputGroup>
                        <InputGroupText className="input-group-left pr-2">
                          <FontAwesomeIcon icon="fa-coins" className="mr-2" />
                          Max
                        </InputGroupText>

                        <Input
                          id="maxSupply"
                          className="pl-2"
                          onChange={(e) => setMaxSupply(e.target.value)}
                          placeholder={!unlimitedSupply ? maxSupply : "∞"}
                          type="number"
                          disabled={unlimitedSupply}
                          required
                        />
                      </InputGroup>
                    </div>

                    <div className="mt-4 mb-4">
                      <Label for="maxMints">
                        <div>
                          <span>
                            <strong>Mints Per Wallet</strong>
                          </span>
                        </div>
                        <small className="text-muted">
                          Maximum NFT Passes each wallet can mint
                        </small>
                      </Label>
                      <InputGroup>
                        <InputGroupText className="input-group-left pr-2">
                          <FontAwesomeIcon icon="fa-wallet" className="mr-2" />
                          Max
                        </InputGroupText>

                        <Input
                          id="maxMints"
                          className="pl-2"
                          onChange={(e) => setMaxMints(e.target.value)}
                          placeholder={maxMints || "Amount"}
                          type="number"
                        />
                      </InputGroup>
                    </div>

                    <div className="mt-4 mb-4">
                      <Label>
                        <div>
                          <span>
                            <strong>Enable refund?</strong>
                          </span>
                          <span className="ml-5">
                            <Input
                              className="custom-control-input"
                              id="refundableCheck"
                              type="checkbox"
                              checked={isRefundable}
                              onChange={toggleRefundable}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="refundableCheck"
                            >
                              <span>This is Refundable</span>{" "}
                              <a
                                href="https://erc721ra.org"
                                target="_blank"
                                rel="external nofollow noopener"
                              >
                                <small>( by ERC721RA )</small>
                              </a>
                            </label>
                          </span>
                        </div>
                        <small className="text-muted">
                          {isRefundable ? (
                            <>
                              Fund locked in smart contract during refund period
                              ( {moment.tz(moment.tz.guess()).format("z")} )
                              <FontAwesomeIcon
                                icon="fa-circle-info"
                                className="text-muted ml-1"
                                id="timezoneTooltip"
                                style={{ cursor: "pointer" }}
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target="timezoneTooltip"
                                trigger="hover"
                              >
                                Your local timezone - {moment.tz.guess()} (
                                {moment.tz(moment.tz.guess()).format("z")} )
                              </UncontrolledTooltip>
                            </>
                          ) : (
                            <>Owners cannot refund the pass after minting</>
                          )}
                        </small>
                      </Label>
                      <InputGroup className="date-picker">
                        <InputGroupText className="date-picker-left">
                          <FontAwesomeIcon icon="fa-calendar-days" />
                        </InputGroupText>

                        <ReactDatetime
                          className="date-picker-right"
                          inputProps={{
                            placeholder: "Refundable Before",
                            disabled: !isRefundable,
                          }}
                          onChange={(e) => setRefundEndDate(e)}
                        />
                      </InputGroup>
                    </div>

                    <div className="mt-4 mb-4">
                      <Label for="walletAddressButton">
                        <div>
                          <strong>Creator Wallet</strong>
                          <small className="text-muted ml-2">
                            Wallet address (not ENS) to collect the payment
                          </small>
                        </div>
                      </Label>

                      <InputGroup>
                        <Button
                          id="walletAddressButton"
                          outline
                          type="button"
                          style={{ textTransform: "none" }}
                          onClick={() =>
                            navigator.clipboard.writeText(currentUserEthAddress)
                          }
                        >
                          <img
                            className="icon-eth mr-2"
                            alt="..."
                            src={ethImg}
                          />
                          <span className="wallet-field-md">
                            {currentUserEthAddress}
                            <FontAwesomeIcon
                              icon="fa-clone"
                              className="ml-1 text-muted"
                            />
                          </span>
                        </Button>
                        <UncontrolledTooltip
                          placement="top"
                          target="walletAddressButton"
                          trigger="click"
                        >
                          Address Copied
                        </UncontrolledTooltip>
                      </InputGroup>
                    </div>

                    <div className="mt-4 mb-4">
                      <Label for="website">
                        <strong>Links</strong>
                      </Label>
                      <InputGroup>
                        <InputGroupText className="input-group-top-left pr-2 text-muted">
                          <FontAwesomeIcon icon={"fa-globe"} className="mr-2" />{" "}
                          https://
                        </InputGroupText>

                        <Input
                          id="website"
                          className="input-group-top-right pl-2"
                          onChange={(e) => setWebsite(e.target.value)}
                          placeholder={website || " yoursite.xyz"}
                          type="url"
                        />
                      </InputGroup>
                      <InputGroup>
                        <InputGroupText className="input-group-mid-left pr-2 text-muted">
                          <FontAwesomeIcon
                            icon={"fa-brands fa-twitter"}
                            className="mr-2"
                          />{" "}
                          https://twitter.com/
                        </InputGroupText>

                        <Input
                          id="twitter"
                          className="input-group-mid-right pl-2"
                          onChange={(e) => setTwitter(e.target.value)}
                          placeholder={twitter || " your_twitter_handle"}
                          type="text"
                        />
                      </InputGroup>
                      <InputGroup>
                        <InputGroupText className="input-group-mid-left pr-2 text-muted">
                          <FontAwesomeIcon
                            icon={"fa-brands fa-discord"}
                            className="mr-2"
                          />{" "}
                          https://discord.gg/
                        </InputGroupText>

                        <Input
                          id="twitter"
                          className="input-group-mid-right pl-2"
                          onChange={(e) => setDiscord(e.target.value)}
                          placeholder={discord || " your_discord_server"}
                          type="text"
                        />
                      </InputGroup>
                      <InputGroup>
                        <InputGroupText className="input-group-bottom-left pr-2 text-muted">
                          <FontAwesomeIcon
                            icon={"fa-brands fa-instagram"}
                            className="mr-2"
                          />
                          https://instagram.com/
                        </InputGroupText>

                        <Input
                          id="instagram"
                          className="input-group-bottom-right pl-2"
                          onChange={(e) => setInstagram(e.target.value)}
                          placeholder={instagram || " your_instagram_handle"}
                          type="text"
                        />
                      </InputGroup>
                    </div>
                    <div className="mt-5">
                      <Button
                        className="btn btn-dark"
                        type="button"
                        onClick={savePass}
                        disabled={loading || hasError || !formChanged}
                      >
                        Create Pass (soon)
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Form>
          </Card>
        </Container>
      </section>
    </>
  );
}
