import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { AuthContext } from "Auth.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Moment from "react-moment";
import moment from "moment-timezone";
import Select from "react-select";

import QRCode from "react-qr-code";
import Autosuggest from "react-autosuggest";

// import { RandomHash } from "random-hash";
import _ from "lodash";

import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.compat.css";

import { Form, Field } from "react-final-form";

// reactstrap components
import {
  Alert,
  Button,
  Badge,
  Card,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  InputGroupText,
  InputGroup,
  Label,
  Toast,
  ToastHeader,
  ToastBody,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// core components
import TopNavbar from "components/Navbars/TopNavbar.js";
import Loader from "components/Loaders/Loader.js";

import ScaleLoader from "react-spinners/ScaleLoader";
import BarLoader from "react-spinners/BarLoader";

import MarkdownIt from "markdown-it";
// import MdEditor from "react-markdown-editor-lite";
// import "react-markdown-editor-lite/lib/index.css";

import Web3 from "web3";

import { truncateAddressShort, truncateText, notify } from "utils/Utils.js";

import ReCAPTCHA from "react-google-recaptcha";

const { REACT_APP_HOST } = process.env;

const defaultImg = require("assets/img/forms/image.svg");
const defaultPassImg = require("assets/img/default/rpbox.svg");
const ethImg = require("assets/img/chains/eth-gray.svg");
const ethWhiteImg = require("assets/img/chains/eth-white.svg");
const polygonImg = require("assets/img/chains/polygon-gray.svg");
const polygonWhiteImg = require("assets/img/chains/polygon-white.svg");

const {
  ChainOptions,
  ChainSelectorOptions,
  EventCategoryOptions,
  USDateOption,
  UserRoleTypes,
  CreatorRoleTypes,
  UserRoleStatus,
  VerifiedUserTypes,
  VerifiedUserStatus,
  DisclaimerShort,
} = require("utils/Variables.js");

// const blockchainOptions = [
//   {
//     value: "eth",
//     label: (
//       <div>
//         <img src={ethImg} className='icon-eth mr-2' />
//         <span>Ethereum</span>
//       </div>
//     ),
//   },
//   {
//     value: "matic",
//     label: (
//       <div>
//         <img src={polygonImg} className='icon-polygon mr-2' />
//         <span>Polygon</span>
//       </div>
//     ),
//   },
// ];

let nfevent, nfekey, nfprofile;

export default function AccessUpdate() {
  const navigate = useNavigate();

  const { currentUser, currentAuthToken, currentUserInfo, setCurrentUserInfo } =
    useContext(AuthContext);

  const hasCreatorRole = currentUserInfo?.hasCreatorRole;
  const isVerifiedUser = currentUserInfo?.isVerifiedUser;

  const { eventId } = useParams();

  const [creatorWallet, setCreatorWallet] = useState(
    currentUserInfo?.ethAddress
  );

  const [pageLoading, setPageLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  const [eventImage, setEventImage] = useState("");
  const [eventImageData, setEventImageData] = useState();

  const [eventName, setEventName] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [blockchain, setBlockchain] = useState("");
  const [blockchainOption, setBlockchainOption] = useState(
    ChainSelectorOptions[0]
  );
  const [requirePass, setRequirePass] = useState(true);
  const [accessEnabled, setAccessEnabled] = useState(false);

  const [totalReg, setTotalReg] = useState(0);
  const [eventKey, setEventKey] = useState();
  const [eventQRCode, setEventQRCode] = useState("");
  const [eventHashCode, setEventHashCode] = useState();

  const [selectedPass, setSelectedPass] = useState();
  const [requireUniquePassId, setRequireUniquePassId] = useState(true);

  const [passInput, setPassInput] = useState("");
  const [passInputChanged, setPassInputChanged] = useState(false);
  const [passSuggestions, setPassSuggestions] = useState([]);

  const [isPublic, setIsPublic] = useState(false);
  const [isNSFW, setIsNSFW] = useState(false);

  const [acceptTerms, setAcceptTerms] = useState(false);

  const [recaptcha, setRecaptcha] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const [passError, setPassError] = useState();

  const mdParser = new MarkdownIt();

  /************** Pass Selector Below **************/
  // Disable Input when Max Contracts are selected
  const renderPassInput = (input, inputProps) => (
    <input
      {...input}
      {...inputProps}
      disabled={selectedPass || totalReg > 0}
      placeholder={
        selectedPass
          ? "You can only set one Pass Contract."
          : "Smart contract 0x12345..."
      }
    />
  );

  // Pass thumbnail to render
  const showPass = selectedPass && (
    <Col className="m-3">
      <Toast className="bg-secondary">
        <Row className="toast-header">
          <Col>
            <FontAwesomeIcon icon="fa-ticket" className="mr-2 text-primary" />
            <strong className="text-primary">
              Require {selectedPass?.contract_type} Pass
            </strong>
          </Col>
          <Col className="justify-content-right text-right col-1">
            {totalReg <= 0 && (
              <button
                type="button"
                className="ml-2 close text-white"
                data-dismiss="toast"
                aria-label="Close"
                color="white"
                onClick={() => {
                  setSelectedPass();
                  setFormChanged(true);
                }}
              >
                <span aria-hidden="true" className="">
                  <FontAwesomeIcon icon="fa-xmark" />
                </span>
              </button>
            )}
          </Col>
        </Row>
        <ToastBody className="bg-secondary">
          <div className="ml-5 mt-2">
            <div>
              <Input
                className="custom-control-input"
                id="requireUniqueIdCheck"
                type="checkbox"
                checked={requireUniquePassId}
                onChange={() => {
                  setRequireUniquePassId(!requireUniquePassId);
                  setFormChanged(true);
                }}
                disabled={totalReg > 0}
              />
              <Label
                className="custom-control-label"
                htmlFor="requireUniqueIdCheck"
              >
                <strong>Require Unique Token ID</strong>
              </Label>
            </div>
          </div>

          <div className="mx-4 mt-4 mb-3">
            <Button
              color="primary"
              href={
                (blockchainOption.value === "eth" &&
                  `https://etherscan.io/address/${selectedPass?.token_address}`) ||
                (blockchainOption.value === "matic" &&
                  `https://polygonscan.com/address/${selectedPass?.token_address}`)
              }
              rel="external nofollow noopener"
              target="_blank"
            >
              <div className="text-left">
                {truncateText(selectedPass?.name, 20)}
              </div>
              <div className="text-left">
                <img
                  alt="..."
                  style={{ width: "100%" }}
                  className="mt-2 mb-2 rounded"
                  src={
                    JSON.parse(selectedPass?.metadata)?.image?.replace(
                      "ipfs://",
                      "https://gateway.moralisipfs.com/ipfs/"
                    ) || defaultPassImg
                  }
                />
              </div>
              <div className="text-left">
                {blockchainOption.value === "eth" && (
                  <img src={ethWhiteImg} className="icon-eth mr-2" />
                )}
                {blockchainOption.value === "matic" && (
                  <img src={polygonWhiteImg} className="icon-polygon mr-2" />
                )}
                <>
                  <small>{selectedPass?.contract_type} </small>
                  <span className="text-info">
                    {truncateAddressShort(selectedPass?.token_address)}
                  </span>{" "}
                  <FontAwesomeIcon
                    icon="arrow-up-right-from-square"
                    className="ml-1"
                  />
                </>
              </div>
              <div className="text-left">
                <small className="text-warning text-capitalize">
                  *{" "}
                  {requireUniquePassId
                    ? "Require unique Token ID to join"
                    : "Token ID can be reused"}
                </small>
              </div>
            </Button>
          </div>
        </ToastBody>
      </Toast>
    </Col>
  );

  // Fetch Metadata by Contract Address
  const getPassSuggestions = async (input) => {
    // const isAddress = web3.utils.isAddress(input.toLowerCase());
    // if (isAddress) {
    //   const address = web3.utils
    //     .toChecksumAddress(input.toLowerCase())
    //     .toLowerCase();
    //   //TODO: remove rinkeby
    //   const options = {
    //     address: address,
    //     // chain: blockchainOption.value,
    //     chain: blockchainOption.value === "eth" ? "rinkeby" : "mumbai",
    //     limit: 1,
    //   };
    //   let nfts = [];
    //   try {
    //     setSearchLoading(true);
    //     const res = await Moralis.Web3API.token.getAllTokenIds(options);
    //     if (res && res.result && res.result.length > 0) {
    //       if (res.result[0].contract_type === "ERC721") {
    //         nfts = res.result;
    //         setPassError();
    //       } else {
    //         setPassError("Smart contract is not ERC721.");
    //       }
    //     } else {
    //       setPassError("Cannot find the smart contract.");
    //     }
    //   } catch (err) {
    //     console.error(err.message);
    //     setPassError("Unable to get the smart contract data.");
    //   } finally {
    //     setSearchLoading(false);
    //   }
    //   return nfts;
    // } else {
    //   setPassError("Pass Contract must be a valid address.");
    //   return [];
    // }
  };

  // Get suggest value and set on the input
  const getPassSuggestionValue = (suggestion) => suggestion.name;

  // Render pass suggestion result dropdown
  const renderPassSuggestion = (suggestion) => (
    <div>
      <span style={{ display: "block", textAlign: "left" }}>
        {suggestion?.name}
      </span>
      <small style={{ display: "block", textAlign: "left" }}>
        {suggestion?.token_address}
      </small>
    </div>
  );

  // When Contract Input Change
  const onPassInputChange = (event, { newValue }) => {
    setPassInput(newValue);
    setPassInputChanged(true);
  };

  // Set the Pass Suggestion after getting the metadata
  const onPassSuggestionsFetch = async ({ value }) => {
    const data = await getPassSuggestions(value);
    if (data) {
      setPassSuggestions(data);
    } else {
      setPassSuggestions([]);
    }
  };

  // Set the pass thumbnail after selecting the suggestion
  const onPassSelected = (event, { suggestion }) => {
    setSelectedPass(suggestion);
    setRequireUniquePassId(true);
    setFormChanged(true);
  };

  // Reset the input
  const onPassSuggestionsClear = () => {
    setPassInput("");
    setPassSuggestions([]);
  };

  // Input Props
  const passInputProps = {
    placeholder: "Smart contract 0x12345...",
    value: passInput,
    onChange: onPassInputChange,
  };
  /************** Pass Selector Above **************/

  // Upload to IPFS during event updating
  const uploadEventImage = (e) => {
    const data = e.target.files[0];
    setEventImage(URL.createObjectURL(data));
    setEventImageData(data);
  };

  const saveAccess = async () => {
    // try {
    //   setPageLoading(true);
    //   if (eventImageData) {
    //     const file = new Moralis.File(
    //       eventName.replace(/[^a-zA-Z0-9 ]/g, ""),
    //       eventImageData
    //     );
    //     await file.saveIPFS();
    //     nfevent.set("eventImage", file);
    //   }
    //   nfevent.set("name", eventName);
    //   nfevent.set("description", eventDescription);
    //   nfevent.set("blockchain", blockchainOption.value);
    //   if (selectedPass?.token_address) {
    //     nfevent.set("passContract", selectedPass?.token_address);
    //     nfevent.set("requireUniquePassId", requireUniquePassId);
    //   } else {
    //     nfevent.unset("passContract");
    //     nfevent.unset("requireUniquePassId");
    //   }
    //   nfevent.set("accessEnabled", accessEnabled);
    //   nfevent.set("isNSFW", isNSFW);
    //   // Serverside recaptcha validation via context
    //   const context = { recaptcha: recaptchaToken };
    //   await nfevent.save(null, { context: context });
    //   nfekey.set("eventId", eventId);
    //   nfekey.set("eventKey", eventKey);
    //   await nfekey.save();
    //   // Finish loading before redirect
    //   setPageLoading(false);
    //   setFormChanged(false);
    //   notify("Access has been updated.", "success", 2000);
    //   setTimeout(() => navigate(`/access/${eventId}`), 3000);
    // } catch (err) {
    //   console.error(err.message);
    //   setPageLoading(false);
    //   setFormChanged(false);
    //   notify("Access update failed.", "error");
    // }
  };

  const fetchEvent = async () => {
    // try {
    //   setPageLoading(true);
    //   eventQuery.equalTo("objectId", eventId);
    //   eventQuery.equalTo("creatorWallet", creatorWallet);
    //   nfevent = await eventQuery.first();
    //   if (!nfevent) {
    //     navigate(`/event/${eventId}`);
    //   }
    //   ekeyQuery.equalTo("eventId", eventId);
    //   nfekey = await ekeyQuery.first();
    //   if (!nfekey) {
    //     nfekey = new NFEventKey();
    //   }
    //   const name = nfevent.get("name");
    //   const description = nfevent.get("description");
    //   let chain = nfevent.get("blockchain");
    //   const contract = nfevent.get("passContract");
    //   const uniqueId = nfevent.get("requireUniquePassId");
    //   const image = nfevent.get("eventImage");
    //   const enabled = nfevent.get("accessEnabled");
    //   const publicFlag = nfevent.get("isPublic");
    //   const nsfw = nfevent.get("isNSFW");
    //   setEventName(name);
    //   setEventDescription(description || "");
    //   setBlockchain(chain);
    //   setBlockchainOption(
    //     ChainSelectorOptions.find((option) => option.value === chain)
    //   );
    //   setEventImage(image?.url());
    //   setAccessEnabled(!!enabled);
    //   setIsPublic(!!publicFlag);
    //   setIsNSFW(!!nsfw);
    //   await fetchTotalParticipants();
    //   if (nfekey) {
    //     setEventKey(nfekey.get("eventKey"));
    //     genQR(nfekey.get("eventKey"));
    //   }
    //   if (contract) {
    //     setRequirePass(true);
    //     // TODO: Remove Rinkeby  on prod
    //     chain = chain === "eth" ? "rinkeby" : "mumbai";
    //     const options = {
    //       chain: chain,
    //       address: contract,
    //       limit: 1,
    //     };
    //     try {
    //       setSearchLoading(true);
    //       const res = await Moralis.Web3API.token.getAllTokenIds(options);
    //       setSearchLoading(false);
    //       if (res && res.result && res.result.length > 0) {
    //         if (res.result[0].contract_type === "ERC721") {
    //           const nft = res.result[0];
    //           setSelectedPass(nft);
    //           setRequireUniquePassId(uniqueId);
    //           setPassError();
    //         } else {
    //           setPassError("Smart contract is not ERC721.");
    //         }
    //       } else {
    //         setPassError("Cannot find the smart contract.");
    //       }
    //     } catch (err) {
    //       console.error(err.message);
    //       setSearchLoading(false);
    //       setPassError("Unable to get the smart contract data.");
    //     }
    //   } else {
    //     setRequirePass(false);
    //   }
    // } catch (err) {
    //   setPageLoading(false);
    //   console.error(err.message);
    //   notify("Unable to fetch event data.", "error");
    // } finally {
    //   setPageLoading(false);
    // }
  };

  const fetchTotalParticipants = async () => {
    // try {
    //   const res = await Moralis.Cloud.run("totalParticipants", {
    //     eventId: eventId,
    //     validOnly: true,
    //   });
    //   if (!isNaN(res)) {
    //     setTotalParticipants(res);
    //     return res;
    //   } else {
    //     setTotalParticipants(0);
    //     return 0;
    //   }
    // } catch (err) {
    //   console.error(err.message);
    // }
  };

  const toggleRequirePass = () => {
    const requirePassFlag = !requirePass;
    setRequirePass(requirePassFlag);
    setSelectedPass();
    setPassError();
  };

  const regenKey = () => {
    // const generateHash = new RandomHash();
    // const newkey = generateHash();
    // setEventKey(newkey);
    // genQR(newkey);
  };

  String.prototype.hashCode = function () {
    var hash = 0,
      i,
      chr;
    if (this.length === 0) return hash;
    for (i = 0; i < this.length; i++) {
      chr = this.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0;
    }
    hash = String(hash).replace("-", "");

    if (hash.length > 6) {
      hash = hash.substring(0, 6);
    }

    return hash;
  };

  const genQR = (key) => {
    if (key) {
      const salt = Math.round(new Date().getTime() / 500);
      const seed = salt + key;

      const hashCode = seed.hashCode();

      let url = window.location.href;

      if (url.indexOf("?") > 0) {
        url = url.substring(0, url.indexOf("?"));
      }

      const qrCode = `${REACT_APP_HOST}/myaccess/verify?ehash=${hashCode}`;

      setEventQRCode(qrCode);
      setEventHashCode(hashCode);
    }
  };

  const recaptchaChangeHandler = (value) => {
    setRecaptcha(true);
    setRecaptchaToken(value);
  };

  useEffect(() => {
    fetchEvent();
  }, []);

  useEffect(() => {
    if (eventKey && accessEnabled) {
      const qrTimer = setTimeout(() => {
        genQR(eventKey);
      }, 500);

      return () => {
        clearTimeout(qrTimer);
      };
    }
  }, [eventHashCode, accessEnabled]);

  return (
    <>
      <BarLoader
        loading={pageLoading}
        cssOverride={{
          width: "100%",
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          zIndex: "1031",
          backgroundColor: "#fff",
          opacity: "1",
        }}
      />
      <TopNavbar />
      <ReactNotifications />
      {hasCreatorRole && (
        <>
          <section className="section section-shaped bg-secondary section-main">
            <Loader loading={pageLoading} />
            <Container>
              <Row className="justify-content-center">
                <Col xl="9" lg="10" md="11" sm="12" xs="12">
                  <Row>
                    <Col className="col-7">
                      <h3 className="mb-3">
                        <strong>Update QR Access</strong>
                      </h3>
                    </Col>
                    <Col className="col-5 text-right">
                      <UncontrolledDropdown className="mt-1">
                        <DropdownToggle
                          id="manageEventButton"
                          caret
                          size="sm"
                          outline
                          color="primary"
                          type="button"
                          className="pr-2"
                        >
                          <span className="ml-2 mr-1">
                            <FontAwesomeIcon
                              icon="fa-sliders"
                              className="mr-1"
                            />
                            <span className="d-none d-md-inline-block ml-1">
                              Manage
                            </span>
                          </span>
                        </DropdownToggle>
                        <DropdownMenu
                          className="full-dropdown-menu-right-sm"
                          style={{ margin: 0 }}
                        >
                          <DropdownItem href={`/event/${eventId}`}>
                            <FontAwesomeIcon
                              icon="fa-compass"
                              className="mr-1"
                            />
                            <span className="ml-1">Go to Event</span>
                          </DropdownItem>

                          <DropdownItem href={`/dashboard/event/${eventId}`}>
                            <FontAwesomeIcon
                              icon="fa-gauge-high"
                              className="mr-1"
                            />
                            <span className="ml-1">Event Dashboard</span>
                          </DropdownItem>

                          <DropdownItem href={`/event/checkin/${eventId}`}>
                            <FontAwesomeIcon
                              icon="fa-camera"
                              className="mr-1"
                            />
                            <span className="ml-1">Event Checkin</span>
                          </DropdownItem>

                          <DropdownItem href={`/access/${eventId}`}>
                            <FontAwesomeIcon
                              icon="fa-qrcode"
                              className="mr-1"
                            />
                            <span className="ml-1">Instant Access</span>
                          </DropdownItem>

                          <DropdownItem href={`/access/update/${eventId}`}>
                            <FontAwesomeIcon
                              icon="fa-wrench"
                              className="mr-1"
                            />
                            <span className="ml-1">Update Access</span>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Col>
                  </Row>
                  <Card className="card-main shadow">
                    <Form
                      onSubmit={saveAccess}
                      validate={(values) => {
                        const errors = {};
                        const validateAddress = () => {
                          try {
                            if (!selectedPass) {
                              return false;
                            }

                            // const lowerAddress =
                            //   selectedPass.token_address.toLowerCase();
                            // const isAddress = web3.utils.isAddress(lowerAddress);

                            // return isAddress;
                          } catch (err) {
                            console.error("Invalid address", err.message);
                            return false;
                          }
                        };

                        if (!eventImage) {
                          errors.eventImage = "Event image is required.";
                        }

                        if (!eventName) {
                          errors.name = "Name is required.";
                        }

                        if (eventName.length > 60) {
                          errors.name = "Name must not exceed 60 characters.";
                        }

                        if (eventDescription.length > 1200) {
                          errors.description =
                            "Description must not exceed 1200 characters.";
                        }

                        if (!blockchainOption.value) {
                          errors.blockchain = "Blockchain is required.";
                        }

                        if (requirePass && !validateAddress()) {
                          errors.passes =
                            "Pass Contract must be a valid address.";
                        }

                        if (passError) {
                          errors.passes = passError;
                        }

                        if (!acceptTerms) {
                          errors.acceptTerms =
                            "You must accept Terms and Privacy Policy to save events.";
                        }

                        return errors;
                      }}
                      render={({
                        handleSubmit,
                        values,
                        submitting,
                        validating,
                        valid,
                      }) => (
                        <div className="m-2">
                          <form onSubmit={handleSubmit}>
                            <Row className="mt-2 mb-4">
                              <Col>
                                <div className="mt-4 mb-4">
                                  <FormGroup>
                                    <Field name="eventImage">
                                      {({ input, meta }) => (
                                        <>
                                          <Label>
                                            <div className="required">
                                              <strong>Event Image</strong>
                                            </div>
                                            <small className="text-muted">
                                              Used for event navigation
                                              <br />
                                              Recommended 360 x 360 1MB
                                            </small>
                                          </Label>
                                          <Label
                                            className="ml-3 image-upload-label"
                                            for="eventUpload"
                                            style={{ cursor: "pointer" }}
                                          >
                                            <img
                                              alt="..."
                                              className="event-image-edit"
                                              src={eventImage || defaultImg}
                                            />
                                            <div className="image-overlay">
                                              <FontAwesomeIcon
                                                icon="fa-pencil"
                                                className="text-muted hidden-icon"
                                              />
                                            </div>
                                          </Label>
                                          <Input
                                            {...input}
                                            id="eventUpload"
                                            name="file"
                                            type="file"
                                            accept="image/*"
                                            style={{ display: "none" }}
                                            onChange={(e) => {
                                              uploadEventImage(e);
                                              setFormChanged(true);
                                            }}
                                          />

                                          {meta.error && meta.touched && (
                                            <small className="text-danger">
                                              <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                              {meta.error}
                                            </small>
                                          )}
                                        </>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </div>

                                <div className="mt-4 mb-4">
                                  <FormGroup>
                                    <Field name="name">
                                      {({ input, meta }) => (
                                        <>
                                          <Label
                                            for="name"
                                            className="required"
                                          >
                                            <strong>Name</strong>
                                          </Label>

                                          <Input
                                            {...input}
                                            id="name"
                                            value={eventName}
                                            onChange={(e) => {
                                              setEventName(e.target.value);
                                              setFormChanged(true);
                                            }}
                                            placeholder={
                                              eventName ||
                                              "Event name, eg. Non-fungible Party"
                                            }
                                            type="text"
                                          />

                                          {meta.error && meta.touched && (
                                            <small className="text-danger">
                                              <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                              {meta.error}
                                            </small>
                                          )}
                                        </>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </div>

                                <div className="mt-4 mb-4">
                                  <FormGroup>
                                    <Field name="description">
                                      {({ input, meta }) => (
                                        <>
                                          <Label for="description">
                                            <div>
                                              <strong>Description</strong>
                                            </div>
                                            <small className="text-muted ml-2">
                                              Supports basic markdown syntax{" "}
                                              <a
                                                href="https://www.markdownguide.org/basic-syntax/"
                                                target="_blank"
                                                rel="external nofollow noopener"
                                                className="text-muted"
                                              >
                                                ( Learn more{" "}
                                                <FontAwesomeIcon icon="arrow-up-right-from-square" />{" "}
                                                )
                                              </a>
                                            </small>
                                          </Label>
                                          {/* <MdEditor
                                            {...input}
                                            id='description'
                                            style={{ height: "300px" }}
                                            plugins={[
                                              "font-bold",
                                              "font-italic",
                                              "list-unordered",
                                              "list-ordered",
                                              "link",
                                              "mode-toggle",
                                              "logger",
                                            ]}
                                            view={{
                                              menu: true,
                                              md: true,
                                              html: false,
                                              fullScreen: false,
                                            }}
                                            canView={{
                                              menu: true,
                                              md: true,
                                              html: true,
                                              fullScreen: false,
                                              hideMenu: true,
                                            }}
                                            renderHTML={(text) =>
                                              mdParser.render(text)
                                            }
                                            onChange={(data) => {
                                              setEventDescription(data.text);
                                              setFormChanged(true);
                                            }}
                                            value={eventDescription}
                                          /> */}

                                          {meta.error && meta.touched && (
                                            <small className="text-danger">
                                              <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                              {meta.error}
                                            </small>
                                          )}
                                        </>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </div>

                                <div className="mt-4 mb-4">
                                  <FormGroup>
                                    <Field name="blockchain">
                                      {({ input, meta }) => (
                                        <>
                                          <Label
                                            for="blockchain"
                                            className="required"
                                          >
                                            <strong>Blockchain</strong>
                                          </Label>
                                          <Select
                                            {...input}
                                            id="blockchain"
                                            className="realpass-selector"
                                            onChange={(e) => {
                                              setBlockchainOption(e);
                                              setFormChanged(true);
                                            }}
                                            options={ChainSelectorOptions}
                                            value={blockchainOption}
                                            components={{
                                              IndicatorSeparator: () => null,
                                            }}
                                          />

                                          {meta.error && meta.touched && (
                                            <small className="text-danger">
                                              <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                              {meta.error}
                                            </small>
                                          )}
                                        </>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </div>

                                <div className="mt-4 mb-4">
                                  <FormGroup>
                                    <Field name="passes">
                                      {({ input, meta }) => (
                                        <>
                                          <Label for="passes">
                                            <div>
                                              <span className="required">
                                                <strong>Who can join?</strong>
                                              </span>
                                              <span className="ml-5">
                                                <Input
                                                  {...input}
                                                  className="custom-control-input"
                                                  id="requirePassCheck"
                                                  type="checkbox"
                                                  checked={requirePass}
                                                  onChange={() => {
                                                    toggleRequirePass();
                                                    setFormChanged(true);
                                                  }}
                                                  disabled={totalReg > 0}
                                                />
                                                <Label
                                                  className="custom-control-label"
                                                  htmlFor="requirePassCheck"
                                                >
                                                  <span>Pass Holders Only</span>
                                                </Label>
                                                <a
                                                  href="/pass/create"
                                                  target="_blank"
                                                  rel="external nofollow noopener"
                                                  className="ml-2"
                                                >
                                                  <small>
                                                    (Create Pass{" "}
                                                    <FontAwesomeIcon icon="arrow-up-right-from-square" />
                                                    )
                                                  </small>
                                                </a>
                                              </span>
                                            </div>

                                            <div>
                                              <small className="text-muted ml-2">
                                                Update is not allowed after
                                                registration starts
                                              </small>
                                            </div>
                                          </Label>

                                          {requirePass ? (
                                            <>
                                              <Alert
                                                color="secondary"
                                                className="text-default"
                                              >
                                                <div className="mb-2">
                                                  Find the smart contract
                                                  address of{" "}
                                                  <a
                                                    id="whyERC721"
                                                    className="text-info"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={(e) =>
                                                      e.preventDefault()
                                                    }
                                                  >
                                                    <u>ERC721</u>
                                                  </a>{" "}
                                                </div>
                                                <InputGroup>
                                                  <InputGroupText className="input-group-left pr-2 pass-search-prefix">
                                                    {searchLoading ? (
                                                      <span className="loading-sm"></span>
                                                    ) : (
                                                      <FontAwesomeIcon
                                                        icon="fa-ticket"
                                                        className="mr-2"
                                                      />
                                                    )}
                                                  </InputGroupText>
                                                  <Autosuggest
                                                    suggestions={
                                                      passSuggestions
                                                    }
                                                    onSuggestionsFetchRequested={
                                                      onPassSuggestionsFetch
                                                    }
                                                    onSuggestionsClearRequested={
                                                      onPassSuggestionsClear
                                                    }
                                                    getSuggestionValue={
                                                      getPassSuggestionValue
                                                    }
                                                    renderSuggestion={
                                                      renderPassSuggestion
                                                    }
                                                    onSuggestionSelected={
                                                      onPassSelected
                                                    }
                                                    renderInputComponent={(
                                                      inputProps
                                                    ) =>
                                                      renderPassInput(
                                                        input,
                                                        inputProps
                                                      )
                                                    }
                                                    inputProps={passInputProps}
                                                  />
                                                </InputGroup>

                                                {meta.error &&
                                                  passInputChanged && (
                                                    <small className="text-danger">
                                                      <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                                      {meta.error}
                                                    </small>
                                                  )}

                                                <div className="mt-3">
                                                  <Row>{showPass}</Row>
                                                </div>
                                              </Alert>
                                              <UncontrolledPopover
                                                trigger="hover"
                                                placement="bottom"
                                                target="whyERC721"
                                              >
                                                <PopoverHeader className="text-default">
                                                  <small>
                                                    <strong>Why ERC721?</strong>
                                                  </small>
                                                </PopoverHeader>
                                                <PopoverBody className="d-flex p-3">
                                                  <Row>
                                                    <Col>
                                                      <small>
                                                        ERC721 is the
                                                        non-fungible token
                                                        standard that supports
                                                        unique Token IDs where
                                                        each ID belongs to one
                                                        owner. ERC1155 is
                                                        semi-fungible where each
                                                        ID may have many owners.
                                                        ERC721 is much better to
                                                        track ownerships and
                                                        provenance than ERC1155.
                                                        Most NFT marketplaces
                                                        support ERC721.
                                                      </small>
                                                    </Col>
                                                  </Row>
                                                </PopoverBody>
                                              </UncontrolledPopover>
                                            </>
                                          ) : (
                                            <>
                                              <Alert
                                                color="secondary"
                                                className="text-default"
                                              >
                                                <div className="mb-2">
                                                  Anyone can join the event. Are
                                                  you sure?{" "}
                                                </div>
                                                <Toast className="bg-secondary">
                                                  <ToastHeader>
                                                    <div>
                                                      <FontAwesomeIcon
                                                        icon="fa-ticket"
                                                        className="mr-2 text-primary"
                                                      />
                                                      <strong className="text-primary">
                                                        No Pass Required
                                                      </strong>
                                                    </div>
                                                  </ToastHeader>
                                                  <ToastBody className="bg-secondary">
                                                    <div className="my-2 mx-5">
                                                      <Button
                                                        block
                                                        color="primary"
                                                        type="button"
                                                        onClick={(e) =>
                                                          e.preventDefault()
                                                        }
                                                      >
                                                        <h3 className="display-3 text-center text-white">
                                                          FREE
                                                        </h3>
                                                      </Button>
                                                    </div>
                                                  </ToastBody>
                                                </Toast>
                                              </Alert>
                                            </>
                                          )}
                                        </>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </div>

                                <div className="mt-4 mb-4">
                                  <Row>
                                    <Col
                                      className="mr--3"
                                      xl="2"
                                      lg="3"
                                      md="3"
                                      sm="4"
                                      xs="5"
                                    >
                                      <Label>
                                        <strong>Enable Access</strong>
                                      </Label>
                                    </Col>
                                    <Col>
                                      <Label className="custom-toggle">
                                        <Input
                                          checked={accessEnabled}
                                          type="checkbox"
                                          onChange={() => {
                                            setAccessEnabled(!accessEnabled);
                                            setFormChanged(true);
                                          }}
                                        />
                                        <span className="custom-toggle-slider rounded-circle" />
                                      </Label>
                                    </Col>
                                  </Row>

                                  <Alert
                                    color="secondary"
                                    className="text-default"
                                  >
                                    <span>
                                      {accessEnabled
                                        ? "People can scan QR Code get instant access now"
                                        : "You must turn this on to enable QR Code Access. You can change it later."}
                                    </span>
                                  </Alert>
                                </div>
                                {accessEnabled && (
                                  <>
                                    <div className="mt-4 mb-4">
                                      <Label>
                                        <div>
                                          <strong>Refresh Key</strong>
                                          <Badge
                                            color="primary"
                                            className="text-white p-2 mx-2"
                                          >
                                            <FontAwesomeIcon
                                              icon="fa-key"
                                              className="ml-1 mr-1"
                                            />
                                            {eventKey || "******"}
                                          </Badge>
                                          <span className="ml-3">
                                            <Button
                                              className="btn-icon-only"
                                              outline
                                              color="dark"
                                              size="sm"
                                              type="button"
                                              onClick={() => {
                                                regenKey();
                                                setFormChanged(true);
                                              }}
                                            >
                                              <FontAwesomeIcon icon="fa-rotate" />
                                            </Button>
                                          </span>
                                        </div>
                                        <small className="text-muted ml-2">
                                          Regenerate Event Key and Dynamic QR
                                          Code to prevent bots actions
                                        </small>
                                      </Label>

                                      <Row className="m-3">
                                        <Col>
                                          <QRCode
                                            className={
                                              !accessEnabled || !eventQRCode
                                                ? "qr-overlay"
                                                : ""
                                            }
                                            size={150}
                                            value={eventQRCode}
                                          />
                                        </Col>
                                      </Row>
                                    </div>
                                  </>
                                )}

                                <div className="mt-4 mb-4">
                                  <div>
                                    <Label for="privateCheck">
                                      <strong>Is it private?</strong>
                                    </Label>
                                    <span className="ml-5">
                                      <Input
                                        checked={!isPublic}
                                        id="privateCheck"
                                        className="custom-control-input"
                                        type="checkbox"
                                        disabled
                                      />
                                      <Label
                                        className="custom-control-label"
                                        htmlFor="privateCheck"
                                      >
                                        <span>Invite or QR Access Only</span>
                                      </Label>
                                    </span>
                                  </div>
                                  <Alert
                                    color="secondary"
                                    className="text-default"
                                  >
                                    <span>
                                      {isPublic ? (
                                        <>
                                          This is a public event. People can
                                          join by registering on the event page.
                                        </>
                                      ) : (
                                        <>
                                          This is a private event. People need
                                          to have an{" "}
                                          <strong>Invite Code</strong> or{" "}
                                          <strong>Scan QR Code</strong> at the
                                          event.
                                        </>
                                      )}{" "}
                                      You can change it in the event editor.
                                    </span>
                                  </Alert>
                                </div>

                                <div className="mt-4 mb-4">
                                  <div>
                                    <Label for="nsfwCheck">
                                      <strong>Is this NSFW?</strong>
                                    </Label>
                                    <span className="ml-5">
                                      <Input
                                        checked={isNSFW}
                                        id="nsfwCheck"
                                        className="custom-control-input"
                                        type="checkbox"
                                        onChange={() => {
                                          {
                                            setIsNSFW(!isNSFW);
                                            setFormChanged(true);
                                          }
                                        }}
                                      />

                                      <Label
                                        className="custom-control-label"
                                        htmlFor="nsfwCheck"
                                      >
                                        <span>Mark as NSFW</span>
                                      </Label>
                                    </span>
                                  </div>

                                  <small className="text-muted mt-2 ml-3">
                                    RealPass allows NSFW content, but the
                                    content is required to be marked NSFW and
                                    handled differently from normal content.{" "}
                                  </small>
                                </div>

                                <div className="mt-5">
                                  <FormGroup>
                                    <Field name="acceptTerms">
                                      {({ input, meta }) => (
                                        <div className="ml-4">
                                          <div>
                                            <Label>
                                              <Input
                                                {...input}
                                                checked={acceptTerms}
                                                className="custom-control-input"
                                                type="checkbox"
                                                onChange={() => {
                                                  setAcceptTerms(!acceptTerms);
                                                }}
                                              />
                                              <span className="custom-control-label">
                                                <strong>
                                                  Accept Terms and Privacy
                                                  Policy
                                                </strong>
                                              </span>
                                            </Label>
                                          </div>
                                          <small className="text-muted mt-2">
                                            All events, passes, and raffles must
                                            be in compliance with laws and may
                                            not be used for securities, ICO, or
                                            gambling. Creator is solely
                                            responsible for meeting all
                                            compliance requirements and
                                            providing full disclosure to users.
                                            Your use of service is subject to
                                            RealPass{" "}
                                            <a
                                              href="/terms"
                                              rel="external nofollow noopener"
                                              target="_blank"
                                              className="text-info"
                                            >
                                              Terms of Service
                                            </a>{" "}
                                            and{" "}
                                            <a
                                              href="/privacy"
                                              rel="external nofollow noopener"
                                              target="_blank"
                                              className="text-info"
                                            >
                                              Privacy Policy
                                            </a>
                                            .
                                          </small>
                                        </div>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </div>

                                <div className="mt-4">
                                  <Button
                                    className="btn btn-dark mt-4 btn-block"
                                    color="dark"
                                    type="submit"
                                    disabled={
                                      !valid || !recaptcha || !formChanged
                                    }
                                  >
                                    Save Access
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      )}
                    />
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </>
      )}
    </>
  );
}
