import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Alert,
  ButtonGroup,
  Button,
  Collapse,
  Card,
  Container,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  CardImg,
  CardBody,
  CardTitle,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Table,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Toast,
  ToastHeader,
  ToastBody,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  notify,
  genImageName,
  nDaysAfterTZ,
  handleReactDatetimeChange,
  getClassNameReactDatetimeDays,
  truncateText,
  truncateAddressShort,
  truncateAddressLong,
  NoDataText,
  formatCurrency,
  formatPrettyNumber,
} from "utils/Utils.js";

import ReactDatetime from "react-datetime";
import Moment from "react-moment";
import moment from "moment-timezone";

const {
  ethWhiteImg,
  polygonWhiteImg,
  USDateTimeOption,
  defaultBoxGrayImg,
  ethImg,
  polygonImg,
  ethLogoColor,
  polygonLogoColor,
  usdtLogoColor,
  btcLogoColor,
  apeLogoColor,
  CurrencyImageOptions,
} = require("utils/Variables.js");

export default function MultiPassesEditable(props) {
  let { passes, updatePass, deletePass, action, index } = props;

  const [showPasses, setShowPasses] = useState(true);

  const listPasses = () => {
    return passes?.map((pass, i) => {
      return (
        <Toast
          key={`pass-${i}`}
          className="bg-white mt-2 p-2"
          style={{ width: "100%", maxWidth: "100%" }}
        >
          <Row className="toast-header text-primary bg-white">
            <Col className="col-10">
              <FontAwesomeIcon icon="fa-ticket" className="mr-2" />
              <strong className="text-uppercase">
                {pass?.passName
                  ? pass?.passName
                  : pass?.passPrice === 0 && !pass?.address
                  ? "Free to join"
                  : pass?.passPrice === 0 && pass?.address
                  ? `Free for ${truncateText(pass?.symbol, 12) + " "} Holders`
                  : !pass?.passPrice === 0 && !pass?.address && "TBA"}
              </strong>
            </Col>
            <Col className="col-2">
              <UncontrolledDropdown className="float-right">
                <DropdownToggle
                  id="manageEventButton"
                  caret
                  size="sm"
                  color="link"
                  type="button"
                >
                  <span className="ml-2 mr-1 text-right">
                    <FontAwesomeIcon icon="fa-wrench" className="mr-1" />
                  </span>
                </DropdownToggle>
                <DropdownMenu
                  className="full-dropdown-menu-right-sm"
                  style={{ margin: 0 }}
                >
                  {pass?.isRegistrEnabled ? (
                    <DropdownItem
                      onClick={() => {
                        updatePass(false);
                        deletePass(false);
                        action("Deactivate");
                        index(i);
                      }}
                    >
                      <FontAwesomeIcon icon="fa-pause" className="mr-1" />
                      <span className="ml-1">Deactivate</span>
                    </DropdownItem>
                  ) : (
                    <DropdownItem
                      onClick={() => {
                        updatePass(false);
                        deletePass(false);
                        action("Activate");
                        index(i);
                      }}
                      disabled={pass?.totalRegistered > 0}
                    >
                      <FontAwesomeIcon icon="fa-play" className="mr-1" />
                      <span className="ml-1">Activate</span>
                    </DropdownItem>
                  )}
                  <DropdownItem
                    onClick={() => {
                      updatePass(true);
                      deletePass(false);
                      action("Update");
                      index(i);
                    }}
                  >
                    <FontAwesomeIcon icon="fa-pen" className="mr-1" />
                    <span className="ml-1">Update</span>
                  </DropdownItem>

                  <DropdownItem
                    onClick={() => {
                      deletePass(true);
                      updatePass(false);
                      action("Delete");
                      index(i);
                    }}
                    disabled={pass?.totalRegistered > 0}
                  >
                    <FontAwesomeIcon
                      icon="fa-trash-can"
                      className="mr-1 text-danger"
                    />
                    <span className="ml-1 text-danger">Delete</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>
          <ToastBody className="text-primary bg-white text-left">
            {pass?.passPrice === 0 && !pass?.isTokengated && (
              <Alert className="mt-1 bg-secondary">
                <h1 className="display-1 text-center text-gradient-dark">
                  FREE
                </h1>
              </Alert>
            )}
            {pass?.isTokengated && pass?.address && (
              <>
                <Row>
                  <Col className="col-12">
                    <span className="h5 text-primary">⛩</span>
                    <span className="ml-2">
                      <strong>Tokengate</strong>
                    </span>
                  </Col>
                </Row>

                <Alert className="mt-1 bg-secondary text-primary">
                  <Row>
                    <Col className="col-3">
                      <div className="text-left">
                        <img
                          alt="..."
                          style={{ width: "100%" }}
                          className="mt-2 mb-2 rounded"
                          src={pass?.imageUrl || defaultBoxGrayImg}
                        />
                      </div>
                    </Col>

                    <Col className="col-9">
                      <Row className="mt-2">
                        <Col className="col-12">
                          <strong className="text-left">
                            {truncateText(pass?.collectionName, 20)}
                          </strong>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col className="col-12">
                          <div className="ml-2 text-left">
                            {pass?.chain === "eth" && (
                              <img src={ethImg} className="icon-eth mr-2" />
                            )}
                            {pass?.chain === "matic" && (
                              <img
                                src={polygonImg}
                                className="icon-polygon mr-2"
                              />
                            )}
                            <>
                              <span>{pass?.tokenType} </span>

                              <a
                                href={`https://opensea.io/assets/${
                                  pass?.chain === "matic" ? "matic" : "ethereum"
                                }/${pass?.address}`}
                                rel="external nofollow noopener"
                                target="_blank"
                              >
                                <span className="text-info">
                                  {truncateAddressShort(pass?.address)}
                                </span>{" "}
                                <FontAwesomeIcon
                                  icon="arrow-up-right-from-square"
                                  className="ml-1 text-gray"
                                />
                              </a>
                            </>
                          </div>

                          <div className="ml-2 mt-2 text-left">
                            <small>
                              {pass?.tokenIdUnique
                                ? "Token ID can be used once"
                                : "Token ID can be reused"}
                            </small>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Alert>
              </>
            )}

            {pass?.passPrice !== 0 && (
              <>
                <Row>
                  <Col className="col-12">
                    <FontAwesomeIcon icon="fa-money-check-dollar" />
                    <span className="ml-2">
                      <strong>Pricing</strong>
                    </span>
                  </Col>
                </Row>

                <Alert className="mt-1 bg-secondary text-primary">
                  <Row>
                    <Col className="col-2">
                      <div className="ml-2">
                        <small>
                          <strong>Price:</strong>
                        </small>
                      </div>
                    </Col>
                    <Col className="col-10">
                      <span className="ml-2 text-default">
                        {pass?.passPrice === 0 ? (
                          "Free"
                        ) : (
                          <>
                            {pass?.passCurrency && (
                              <img
                                src={CurrencyImageOptions[pass?.passCurrency]}
                                className="icon-coin-sm mr-2"
                              />
                            )}
                            {formatCurrency(
                              pass?.passPrice,
                              pass?.passCurrency
                            )}
                          </>
                        )}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-2">
                      <div className="ml-2">
                        <small>
                          <strong>Qty:</strong>
                        </small>
                      </div>
                    </Col>
                    <Col className="col-10">
                      <span className="ml-2 text-default">
                        {pass?.passQty < 0
                          ? "Unlimited"
                          : formatPrettyNumber(pass?.passQty)}
                      </span>
                    </Col>
                  </Row>
                </Alert>
              </>
            )}

            <>
              <Row>
                <Col className="col-12">
                  <FontAwesomeIcon icon="fa-check-to-slot" />
                  <span className="ml-2">
                    <strong>Registration</strong>
                  </span>
                </Col>
              </Row>

              <Alert className="mt-1 bg-secondary text-primary">
                <Row>
                  <Col className="col-2">
                    <div className="ml-2">
                      <small>
                        <strong>Sold:</strong>
                      </small>
                    </div>
                  </Col>
                  <Col className="col-10">
                    <span className="ml-2 text-default">
                      {pass?.passCurrency && (
                        <img
                          src={CurrencyImageOptions[pass?.passCurrency]}
                          className="icon-coin-sm mr-2"
                        />
                      )}
                      {pass?.totalSales
                        ? formatCurrency(pass?.totalSales, pass?.passCurrency)
                        : formatCurrency(0, pass?.passCurrency)}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-2">
                    <div className="ml-2">
                      <small>
                        <strong>Joined:</strong>
                      </small>
                    </div>
                  </Col>
                  <Col className="col-10">
                    <span className="ml-2 text-default">
                      {pass?.totalRegistered
                        ? formatPrettyNumber(pass?.totalRegistered)
                        : formatPrettyNumber(0)}{" "}
                      <small>Members</small>
                    </span>
                  </Col>
                </Row>
              </Alert>
            </>
          </ToastBody>
        </Toast>
      );
    });
  };

  return (
    <Alert color="white" className="mt-2" style={{ border: "1px solid #ccc" }}>
      <Button
        className="text-primary"
        color="link"
        size="sm"
        block
        onClick={() => {
          setShowPasses(!showPasses);
        }}
      >
        All Pass Tiers
        <FontAwesomeIcon icon="fa-list-ul" className="ml-2" />
      </Button>

      <Collapse isOpen={showPasses}>
        <div className="border-top mt-2">
          {passes?.length > 0
            ? listPasses()
            : NoDataText(
                "sm",
                true,
                null,
                "No pass found",
                "Start creating passes and sell them to NFT holders"
              )}
        </div>
      </Collapse>
    </Alert>
  );
}
