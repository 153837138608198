import React, { useState, useEffect, useContext } from "react";
import {
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import { AuthContext } from "Auth.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from "react-moment";
import moment from "moment-timezone";
import Select from "react-select";
import GooglePlaceAutocomplete from "react-google-autocomplete";

// reactstrap components
import {
  Alert,
  Button,
  Card,
  Container,
  Row,
  Col,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Label,
  UncontrolledTooltip,
} from "reactstrap";

import ReactDatetime from "react-datetime";

// core components
import TopNavbar from "components/Navbars/TopNavbar.js";
import Loader from "components/Loaders/Loader.js";

import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.compat.css";

const {
  defaultFormImg,
  defaultFormBanner,
  defaultProfileImg,
  bgGradient,
  defaultBoxGrayImg,
  verifiedImg,
  ethImg,
  nsfwImg,
  mmImg,
  wcImg,
  pwImg,
  ethWhiteImg,
  ethLogoColor,
  polygonImg,
  polygonWhiteImg,
  polygonLogoColor,
  USDateShortOption,
  USDateOption,
  ChainOptions,
  EventCategoryOptions,
} = require("utils/Variables.js");

export default function RaffleCreate() {
  const navigate = useNavigate();

  const {
    currentUser,
    currentAuthToken,
    currentUserInfo,
    setCurrentUserInfo,
    currentUserId,
    currentUserEthAddress,
  } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  const [raffleImage, setRaffleImage] = useState();
  const [bannerImage, setBannerImage] = useState();

  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [blockchainOption, setBlockchainOption] = useState(ChainOptions[0]);

  const [isVirtual, setIsVirtual] = useState(true);
  const [location, setLocation] = useState();

  const [categoryOption, setCategoryOption] = useState(EventCategoryOptions[0]);

  // const [url, setUrl] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [tba, setTba] = useState(false);

  const [maxParticipants, setMaxParticipants] = useState(2 ** 256);
  const [unlimitedParticipants, setUnlimitedParticipants] = useState(true);

  const [isRaffle, setIsRaffle] = useState(false);
  const [winners, setWinners] = useState(1);

  const [requirePass, setRequirePass] = useState(true);
  const [passContract, setPassContract] = useState();

  const [website, setWebsite] = useState();
  const [twitter, setTwitter] = useState();
  const [discord, setDiscord] = useState();
  const [instagram, setInstagram] = useState();
  // const [video, setVideo] = useState();
  // const [telegram, setTelegram] = useState();
  // const [blog, setBlog] = useState();

  const uploadRaffleImage = async (e) => {
    const data = e.target.files[0];
    setRaffleImage(URL.createObjectURL(data));
  };

  const uploadBannerImage = async (e) => {
    const data = e.target.files[0];
    setBannerImage(URL.createObjectURL(data));
  };

  const saveRaffle = async () => {
    //   try {
    //     setLoading(true);
    //     const NFRaffle = Moralis.Object.extend("NFRaffle");
    //     const nfraffle = new NFRaffle();
    //     nfraffle.set("name", name);
    //     nfraffle.set("description", description);
    //     nfraffle.set("startDate", startDate);
    //     nfraffle.set("endDate", endDate);
    //     nfraffle.set("website", website);
    //     nfraffle.set("twitter", twitter);
    //     nfraffle.set("discord", discord);
    //     nfraffle.set("instagram", instagram);
    //     await nfraffle.save();
    //   } catch (err) {
    //     console.error(err.message);
    //   } finally {
    //     setLoading(false);
    //   }
  };

  const handleReactDatetimeChange = (which, date) => {
    if (
      startDate &&
      which === "endDate" &&
      new Date(startDate._d + "") > new Date(date._d + "")
    ) {
      setStartDate(date);
      setEndDate(date);
    } else if (
      endDate &&
      which === "startDate" &&
      new Date(endDate._d + "") < new Date(date._d + "")
    ) {
      setStartDate(date);
      setEndDate(date);
    } else {
      if (which === "startDate") setStartDate(date);
      if (which === "endDate") setEndDate(date);
    }
  };

  const getClassNameReactDatetimeDays = (date) => {
    if (startDate && endDate) {
    }
    if (startDate && endDate && startDate._d + "" !== endDate._d + "") {
      if (
        new Date(endDate._d + "") > new Date(date._d + "") &&
        new Date(startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };

  const toggleTba = () => {
    setTba(!tba);
    setStartDate();
    setEndDate();
    handleReactDatetimeChange("startDate", "");
    handleReactDatetimeChange("endDate", "");
  };

  const toggleUnlimitedParticipants = () => {
    const unlimited = !unlimitedParticipants;
    setUnlimitedParticipants(unlimited);
    unlimited ? setMaxParticipants(2 ** 256) : setMaxParticipants(1);
  };

  const toggleRaffle = () => {
    const raffleFlag = !isRaffle;
    setIsRaffle(raffleFlag);
    setWinners();
  };

  const toggleLocation = () => {
    const virtualFlag = !isVirtual;
    setIsVirtual(virtualFlag);
    setLocation();
  };

  const toggleRequirePass = () => {
    const requirePassFlag = !requirePass;
    setRequirePass(requirePassFlag);
    setPassContract();
  };

  return (
    <>
      <TopNavbar />
      <ReactNotifications />
      <section className="section section-shaped bg-secondary section-main">
        <Loader loading={loading} />
        <Container>
          <h3 className="mb-3">
            <strong>Create Raffle</strong>
          </h3>
          <Card className="card-main shadow">
            <Form>
              <div className="px-2 m-2">
                <Row className="mt-4 mb-4">
                  <Col lg="8" className="mr-3">
                    <div className="mt-4 mb-4">
                      <Label>
                        <div className="required">
                          <strong>Raffle Image</strong>
                        </div>
                        <small className="text-muted">
                          Used for raffle navigation
                          <br />
                          Recommended 360 x 360 1MB
                        </small>
                      </Label>
                      <Label
                        className="ml-3 image-upload-label"
                        for="raffleUpload"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          alt="..."
                          className="raffle-image-edit"
                          src={raffleImage || defaultFormImg}
                        />
                        <div className="image-overlay">
                          <FontAwesomeIcon
                            icon="fa-pencil"
                            className="text-muted hidden-icon"
                          />
                        </div>
                      </Label>
                      <small>
                        <FontAwesomeIcon
                          icon="fa-circle-info"
                          className="text-muted ml-1"
                          id="rafffleImageTooltip"
                          style={{ cursor: "pointer" }}
                        />
                      </small>
                      <UncontrolledTooltip
                        placement="top"
                        target="rafffleImageTooltip"
                        trigger="hover"
                      >
                        Raffle image to be stored in IPFS
                      </UncontrolledTooltip>
                      <Input
                        id="raffleUpload"
                        name="file"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={uploadRaffleImage}
                      />
                    </div>
                    <div className="mt-4 mb-4">
                      <Label>
                        <div>
                          <strong>Banner Image</strong>
                        </div>
                        <small className="text-muted">
                          Show on top of your raffle page
                          <br />
                          Recommended 1400 x 400 1MB
                        </small>
                      </Label>
                      <Label
                        className="ml-3 image-upload-label"
                        for="bannerUpload"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          alt="..."
                          className="banner-image-edit"
                          src={bannerImage || defaultFormBanner}
                        />
                        <div className="image-overlay">
                          <FontAwesomeIcon
                            icon="fa-pencil"
                            className="text-muted hidden-icon"
                          />
                        </div>
                      </Label>
                      <small>
                        <FontAwesomeIcon
                          icon="fa-circle-info"
                          className="text-muted ml-1"
                          id="bannerImageTooltip"
                          style={{ cursor: "pointer" }}
                        />
                      </small>
                      <UncontrolledTooltip
                        placement="top"
                        target="bannerImageTooltip"
                        trigger="hover"
                      >
                        Banner image to be stored in IPFS
                      </UncontrolledTooltip>
                      <Input
                        id="bannerUpload"
                        name="file"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={uploadBannerImage}
                      />
                    </div>

                    <div className="mt-4 mb-4">
                      <Label for="name" className="required">
                        <strong>Name</strong>
                      </Label>
                      <Input
                        id="name"
                        onChange={(e) => setName(e.target.value)}
                        placeholder={
                          name || "Raffle name, eg. Non-fungible Party"
                        }
                        type="text"
                      />
                    </div>
                    <div className="mt-4 mb-4">
                      <Label for="description">
                        <strong>Description</strong>
                      </Label>
                      <Input
                        id="description"
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder={
                          description || "What is this raffle about ..."
                        }
                        rows="4"
                        maxLength="1000"
                        type="textarea"
                      />
                    </div>

                    <div className="mt-4 mb-4">
                      <Label for="blockchain">
                        <strong>Blockchain</strong>
                      </Label>
                      <InputGroup>
                        <Select
                          className="realpass-selector"
                          onChange={(e) => setBlockchainOption(e)}
                          options={ChainOptions}
                          defaultValue={ChainOptions[0]}
                        />
                      </InputGroup>
                    </div>

                    <div className="mt-4 mb-4">
                      <Label>
                        <div>
                          <span>
                            <strong>Raffle Duration</strong>
                          </span>
                          <span className="ml-5">
                            <Input
                              className="custom-control-input"
                              id="tbaCheck"
                              type="checkbox"
                              checked={tba}
                              onChange={toggleTba}
                            />
                            <Label
                              className="custom-control-label"
                              htmlFor="tbaCheck"
                            >
                              <span>TBA</span>
                            </Label>
                          </span>
                        </div>
                        <small className="text-muted">
                          {tba ? (
                            <>Raffle date to be announced</>
                          ) : (
                            <>
                              Set raffle start and end date ({" "}
                              {moment.tz(moment.tz.guess()).format("z")} )
                              <FontAwesomeIcon
                                icon="fa-circle-info"
                                className="text-muted ml-1"
                                id="timezoneTooltip"
                                style={{ cursor: "pointer" }}
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target="timezoneTooltip"
                                trigger="hover"
                              >
                                Your local timezone - {moment.tz.guess()} (
                                {moment.tz(moment.tz.guess()).format("z")} )
                              </UncontrolledTooltip>
                            </>
                          )}
                        </small>
                      </Label>
                      <InputGroup className="date-range-picker">
                        <InputGroupText className="date-picker-left text-muted">
                          <FontAwesomeIcon icon="fa-calendar-days" />
                        </InputGroupText>

                        <ReactDatetime
                          className="date-picker-middle"
                          inputProps={{
                            placeholder: "Raffle Starts",
                            disabled: tba,
                          }}
                          onChange={(e) =>
                            handleReactDatetimeChange("startDate", e)
                          }
                          renderDay={(props, currentDate, selectedDate) => {
                            let classes = props.className;
                            classes +=
                              getClassNameReactDatetimeDays(currentDate);
                            return (
                              <td {...props} className={classes}>
                                {currentDate.date()}
                              </td>
                            );
                          }}
                        />
                        <ReactDatetime
                          className="date-picker-right rdtPickerOnRight"
                          inputProps={{
                            placeholder: "Raffle Ends",
                            disabled: tba,
                          }}
                          onChange={(e) =>
                            handleReactDatetimeChange("endDate", e)
                          }
                          renderDay={(props, currentDate, selectedDate) => {
                            let classes = props.className;
                            classes +=
                              getClassNameReactDatetimeDays(currentDate);
                            return (
                              <td {...props} className={classes}>
                                {currentDate.date()}
                              </td>
                            );
                          }}
                        />
                      </InputGroup>
                    </div>

                    <div className="mt-4 mb-4">
                      <Label for="maxParticipants">
                        <div>
                          <span>
                            <strong>Participants</strong>
                          </span>
                          <span className="ml-5">
                            <Input
                              className="custom-control-input"
                              id="unlimitedParticipantsCheck"
                              type="checkbox"
                              checked={unlimitedParticipants}
                              onChange={toggleUnlimitedParticipants}
                            />
                            <Label
                              className="custom-control-label"
                              htmlFor="unlimitedParticipantsCheck"
                            >
                              <span>Unlimited Participants</span>
                            </Label>
                          </span>
                        </div>
                        <small className="text-muted">
                          Number of participants can join the raffle
                        </small>
                      </Label>
                      <InputGroup>
                        <InputGroupText className="input-group-left pr-2">
                          <FontAwesomeIcon icon="fa-users" className="mr-2" />
                          Max
                        </InputGroupText>

                        <Input
                          id="maxParticipants"
                          className="pl-2"
                          onChange={(e) => setMaxParticipants(e.target.value)}
                          placeholder={
                            !unlimitedParticipants ? maxParticipants : "∞"
                          }
                          disabled={unlimitedParticipants}
                          required
                        />
                      </InputGroup>
                    </div>

                    <div className="mt-4">
                      <Label for="winners">
                        <div>
                          <span className="required">
                            <strong>Raffle Winners</strong>
                          </span>
                        </div>
                        <small className="text-muted">
                          Number of people can win the raffle
                        </small>
                      </Label>
                      <InputGroup>
                        <InputGroupText className="input-group-left pr-2">
                          <FontAwesomeIcon icon="fa-crown" className="mr-2" />
                          Max
                        </InputGroupText>

                        <Input
                          id="winners"
                          className="input-group-middle pl-2"
                          onChange={(e) => setWinners(e.target.value)}
                          placeholder={winners || "Number"}
                          type="number"
                          required
                        />
                        <InputGroupText className="input-group-right">
                          of Winners
                        </InputGroupText>
                      </InputGroup>
                    </div>

                    <div className="mt-4 mb-4">
                      <Label for="location">
                        <div>
                          <strong>Who can join?</strong>

                          <span className="ml-5">
                            <Input
                              className="custom-control-input"
                              id="requirePassCheck"
                              type="checkbox"
                              checked={requirePass}
                              onChange={toggleRequirePass}
                            />
                            <Label
                              className="custom-control-label"
                              htmlFor="requirePassCheck"
                            >
                              <span>Pass Holders Only</span>
                            </Label>
                            <a
                              href="/create/pass"
                              target="_blank"
                              rel="external nofollow noopener"
                              className="ml-2"
                            >
                              <small>
                                (Create Pass{" "}
                                <FontAwesomeIcon icon="arrow-up-right-from-square" />
                                )
                              </small>
                            </a>
                          </span>
                        </div>

                        {requirePass && (
                          <small className="text-muted">
                            Token ID is allowed to join once
                          </small>
                        )}
                      </Label>
                      <InputGroup>
                        {requirePass ? (
                          <>
                            <InputGroupText className="input-group-left pr-2">
                              <FontAwesomeIcon
                                icon="fa-ticket"
                                className="mr-2"
                              />
                              Pass
                            </InputGroupText>
                            <Input
                              id="holders"
                              className="pl-2"
                              onChange={(e) => setPassContract(e.target.value)}
                              placeholder={"Smart contract 0x12345..."}
                              type="text"
                            />{" "}
                          </>
                        ) : (
                          <>
                            <Alert className="alert-default">
                              <span>
                                Anyone can join this raffle. Are you sure?{" "}
                              </span>
                            </Alert>
                          </>
                        )}
                      </InputGroup>
                    </div>

                    <div className="mt-4 mb-4">
                      <Label for="walletAddressButton">
                        <div>
                          <strong>Creator Wallet</strong>
                          <small className="text-muted ml-2">
                            Wallet address (not ENS) to collect the payment
                          </small>
                        </div>
                      </Label>

                      <InputGroup>
                        <Button
                          id="walletAddressButton"
                          outline
                          type="button"
                          style={{ textTransform: "none" }}
                          onClick={() =>
                            navigator.clipboard.writeText(currentUserEthAddress)
                          }
                        >
                          <img
                            className="icon-eth mr-2"
                            alt="..."
                            src={ethImg}
                          />
                          <span className="wallet-field-md">
                            {currentUserEthAddress}
                            <FontAwesomeIcon
                              icon="fa-clone"
                              className="ml-1 text-muted"
                            />
                          </span>
                        </Button>
                        <UncontrolledTooltip
                          placement="top"
                          target="walletAddressButton"
                          trigger="click"
                        >
                          Address Copied
                        </UncontrolledTooltip>
                      </InputGroup>
                    </div>

                    <div className="mt-4 mb-4">
                      <Label for="website">
                        <strong>Links</strong>
                      </Label>
                      <InputGroup>
                        <InputGroupText className="input-group-top-left pr-2 text-muted">
                          <FontAwesomeIcon icon={"fa-globe"} className="mr-2" />{" "}
                          https://
                        </InputGroupText>

                        <Input
                          id="website"
                          className="input-group-top-right pl-2"
                          onChange={(e) => setWebsite(e.target.value)}
                          placeholder={website || " yoursite.xyz"}
                          type="url"
                        />
                      </InputGroup>
                      <InputGroup>
                        <InputGroupText className="input-group-mid-left pr-2 text-muted">
                          <FontAwesomeIcon
                            icon={"fa-brands fa-twitter"}
                            className="mr-2"
                          />{" "}
                          https://twitter.com/
                        </InputGroupText>

                        <Input
                          id="twitter"
                          className="input-group-mid-right pl-2"
                          onChange={(e) => setTwitter(e.target.value)}
                          placeholder={twitter || " your_twitter_handle"}
                          type="text"
                        />
                      </InputGroup>
                      <InputGroup>
                        <InputGroupText className="input-group-mid-left pr-2 text-muted">
                          <FontAwesomeIcon
                            icon={"fa-brands fa-discord"}
                            className="mr-2"
                          />{" "}
                          https://discord.gg/
                        </InputGroupText>

                        <Input
                          id="twitter"
                          className="input-group-mid-right pl-2"
                          onChange={(e) => setDiscord(e.target.value)}
                          placeholder={discord || " your_discord_server"}
                          type="text"
                        />
                      </InputGroup>
                      <InputGroup>
                        <InputGroupText className="input-group-bottom-left pr-2 text-muted">
                          <FontAwesomeIcon
                            icon={"fa-brands fa-instagram"}
                            className="mr-2"
                          />
                          https://instagram.com/
                        </InputGroupText>

                        <Input
                          id="instagram"
                          className="input-group-bottom-right pl-2"
                          onChange={(e) => setInstagram(e.target.value)}
                          placeholder={instagram || " your_instagram_handle"}
                          type="text"
                        />
                      </InputGroup>
                    </div>
                    <div className="mt-5">
                      <Button
                        className="btn btn-dark"
                        type="button"
                        onClick={saveRaffle}
                        disabled={loading || hasError || !formChanged}
                      >
                        Create (soon)
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Form>
          </Card>
        </Container>
      </section>
    </>
  );
}
