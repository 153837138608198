import React, { useState, useContext, useEffect } from "react";

import { AuthContext } from "Auth.js";

// reactstrap components
import {
  Alert,
  ButtonGroup,
  Button,
  Collapse,
  Card,
  Container,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  CardImg,
  CardBody,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Table,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import GooglePlaceAutocomplete from "react-google-autocomplete";

import { Form, Field } from "react-final-form";
import Select from "react-select";

import {
  notify,
  genImageName,
  nDaysAfterTZ,
  handleReactDatetimeChange,
  getClassNameReactDatetimeDays,
} from "utils/Utils.js";

import { firebaseUpload, creatorUpdateEventDetail } from "utils/Api.js";

import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.compat.css";

import Compressor from "compressorjs";

import Avatar from "boring-avatars";

import Loader from "components/Loaders/Loader.js";
import LoaderDots from "components/Loaders/LoaderDots.js";
import LoaderSection from "components/Loaders/LoaderSection.js";
import LoaderModal from "components/Loaders/LoaderModal.js";

import ScaleLoader from "react-spinners/ScaleLoader";
import BarLoader from "react-spinners/BarLoader";

import ReactDatetime from "react-datetime";
import Moment from "react-moment";
import moment from "moment-timezone";

import TimezoneSelect, { allTimezones } from "react-timezone-select";

import MultiSessionsEditable from "components/Lists/MultiSessionsEditable.js";

const {
  defaultFormImg,
  defaultFormBanner,
  DefaultLocation,
  ChainOptions,
  EventCategoryOptions,
  EventCategorySelectorOptions,
  CreatorRoleTypes,
  DisableMaxUsageOption,
  InviteMaxUsageOptions,
  InviteExpireOptions,
  ChainSelectorOptions,
  USDateOption,
  UserRoleTypes,
  UserRoleStatus,
  VerifiedUserTypes,
  VerifiedUserStatus,
  QuillEditorModules,
  QuillEditorFormats,
} = require("utils/Variables.js");

const { REACT_APP_FBASE_API_KEY } = process.env;

export default function EventDetail(props) {
  let {
    event,
    updateEvent,
    updateEventName,
    updateIsPublished,
    updateIsPrivate,
    updateIsVirtual,
    updateIsEventEnded,
    isEventActiveCreator,
  } = props;

  const {
    currentUser,
    currentUserId,
    currentAuthToken,
    currentUserInfo,
    setCurrentUserInfo,
  } = useContext(AuthContext);

  const hasAdminRole = currentUserInfo?.hasAdminRole;
  const hasCreatorRole = currentUserInfo?.hasCreatorRole;
  const isVerifiedUser = currentUserInfo?.isVerifiedUser;

  const [dataSaving, setDataSaving] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  const [eventImageUrl, setEventImageUrl] = useState(event?.imageUrl || "");
  const [eventImageName, setEventImageName] = useState("");
  const [eventImageData, setEventImageData] = useState();

  const [bannerImageUrl, setBannerImageUrl] = useState(event?.bannerUrl || "");
  const [bannerImageName, setBannerImageName] = useState("");
  const [bannerImageData, setBannerImageData] = useState();

  const [name, setName] = useState(event?.name || "");
  const [description, setDescription] = useState(event?.description || "");

  const [venue, setVenue] = useState(event?.venue);
  const [isVirtual, setIsVirtual] = useState(!!event?.isVirtual);
  const [virtualLocation, setVirtualLocation] = useState(
    isVirtual && event?.location ? event?.location : DefaultLocation
  );
  const [irlLocation, setIrlLocation] = useState(
    !isVirtual && event?.location ? event?.location : DefaultLocation
  );

  const [isLocationRestricted, setIsLocationRestricted] = useState(
    !!event?.isLocationRestricted
  );

  const [categoryOption, setCategoryOption] = useState(
    EventCategorySelectorOptions[0]
  );

  const [selectedTimezoneValue, setSelectedTimezoneValue] = useState(
    event?.timezone || moment.tz.guess()
  );
  const [selectedTimezoneLabel, setSelectedTimezoneLabel] = useState(
    `${event?.timezone || moment.tz.guess()} (${moment
      .tz(event?.timezone || moment.tz.guess())
      .format("z")})`?.replace("_", " ")
  );
  const [selectedTimezone, setSelectedTimezone] = useState(
    event?.timezone || moment.tz.guess()
  );

  const [startDateMoment, setStartDateMoment] = useState(
    new Date(event?.startDateTime) ||
      nDaysAfterTZ(1, event?.timezone || moment.tz.guess())
  );
  const [endDateMoment, setEndDateMoment] = useState(
    new Date(event?.endDateTime) ||
      nDaysAfterTZ(2, event?.timezone || moment.tz.guess())
  );

  const [isMultiSession, setIsMultiSession] = useState(!!event?.isMultiSession);

  const [sessions, setSessions] = useState([]);

  const [isNSFW, setIsNSFW] = useState(!!event?.isNSFW);
  const [isPublished, setIsPublished] = useState(!!event?.isPublished);

  const [durationError, setDurationError] = useState();
  const [locationError, setLocationError] = useState();

  const selectEventImage = (e) => {
    const data = e.target.files[0];

    //Compress Images
    new Compressor(data, {
      quality: 0.8,
      maxWidth: 600,
      maxHeight: 600,
      mimeType: "image/webp",

      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      success(result) {
        setEventImageUrl(URL.createObjectURL(result));
        setEventImageData(result);
        setEventImageName(`image.${result.name.split(".").at(-1)}`);
      },
      error(err) {
        console.error(err.message);
      },
    });
  };

  const selectEventBanner = (e) => {
    const data = e.target.files[0];

    //Compress Images
    new Compressor(data, {
      quality: 0.8,
      maxWidth: 1400,
      maxHeight: 400,
      mimeType: "image/webp",

      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      success(result) {
        setBannerImageUrl(URL.createObjectURL(result));
        setBannerImageData(result);
        setBannerImageName(`banner.${result.name.split(".").at(-1)}`);
      },
      error(err) {
        console.error(err.message);
      },
    });
  };

  const saveEventDetail = async () => {
    try {
      setDataSaving(true);

      let imageUrl = eventImageUrl;
      let bannerUrl = bannerImageUrl;

      if (eventImageData) {
        imageUrl = await firebaseUpload(
          eventImageData,
          genImageName(currentUserId, "event", eventImageName, true)
        );

        setEventImageUrl(imageUrl);
      }

      if (bannerImageData) {
        bannerUrl = await firebaseUpload(
          bannerImageData,
          genImageName(currentUserId, "event", bannerImageName, true)
        );

        setBannerImageUrl(bannerUrl);
      }

      const eventData = {
        imageUrl,
        bannerUrl,
        name,
        description,
        category: categoryOption.value,
        isMultiSession,
        isVirtual,
        isLocationRestricted,
        isNSFW,
        isPublished,
        timezone: selectedTimezoneValue,
        venue,
      };

      eventData.startDateTime = new Date(startDateMoment);
      eventData.endDateTime = new Date(endDateMoment);

      if (isMultiSession) {
        eventData.sessions = sessions;
      } else {
        eventData.sessions = null;
      }

      if (isVirtual) {
        eventData.location = {
          name: virtualLocation.name,
        };
      } else {
        eventData.location = {
          name: irlLocation.name,
          lat: irlLocation.lat,
          lng: irlLocation.lng,
        };
      }

      const res = await creatorUpdateEventDetail(
        currentAuthToken,
        event?.eventId,
        eventData
      );

      if (res?.success) {
        setEventImageName("");
        setEventImageData();

        setBannerImageName("");
        setBannerImageData();

        updateEventName(name);
        updateIsPublished(isPublished);
        updateIsVirtual(isVirtual);

        const start = new Date(eventData.startDateTime);
        const end = new Date(eventData.endDateTime);
        updateIsEventEnded(end?.getTime() < new Date().getTime());

        notify("Successfully updated event.", "success");
      } else {
        notify("Unable to update event.", "error");
      }
    } catch (err) {
      console.error(err.message);
      notify("Unable to update event.", "error");
    } finally {
      setDataSaving(false);
      setFormChanged(false);
    }
  };

  const handleTimezoneChange = (tz) => {
    setSelectedTimezoneValue(tz.value);
    setSelectedTimezoneLabel(`${tz.value} (${tz.abbrev})`?.replace("_", " "));
    setSelectedTimezone(tz);
    setFormChanged(true);
  };

  const toggleMultiSession = (multiFlag) => {
    if (multiFlag !== isMultiSession) {
      setIsMultiSession(multiFlag);
    }
  };

  const toggleVirtual = (virtualFlag) => {
    if (virtualFlag !== isVirtual) {
      setIsVirtual(virtualFlag);
      setLocationError();
    }
  };

  useEffect(() => {
    const catOption = EventCategorySelectorOptions.find(
      (option) => option.value === event?.category
    );

    setCategoryOption(catOption || EventCategorySelectorOptions[0]);
  }, []);

  return (
    <>
      {(hasAdminRole || isEventActiveCreator) && (
        <>
          <h5 className="text-default">
            <strong>Event Detail</strong>
          </h5>

          <Form
            onSubmit={() => {}}
            validate={(values) => {
              const errors = {};

              if (!eventImageUrl) {
                errors.eventImage = "Event image is required.";
              }

              if (!name) {
                errors.name = "Name is required.";
              }

              if (name?.length > 40) {
                errors.name = "Name must not exceed 40 characters.";
              }

              if (description?.length > 3000) {
                errors.description =
                  "Description must not exceed 3000 characters.";
              }

              if (venue?.length > 40) {
                errors.venue = "Venue must not exceed 40 characters.";
              }

              if (
                (isVirtual && virtualLocation?.name?.length > 120) ||
                (!isVirtual && irlLocation?.name?.length > 120)
              ) {
                errors.location = "Location must not exceed 120 characters.";
              }

              if (locationError) {
                errors.location = locationError;
              }

              return errors;
            }}
            render={({
              handleSubmit,
              values,
              submitting,
              validating,
              valid,
            }) => (
              <div className="m-2">
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <div className="mt-4 mb-4">
                        <FormGroup>
                          <Field name="eventImage">
                            {({ input, meta }) => (
                              <>
                                <Label>
                                  <div className="required">
                                    <strong>Event Image</strong>
                                    <small>
                                      <FontAwesomeIcon
                                        icon="fa-circle-info"
                                        className="text-muted ml-1"
                                        id="eventImageTooltip"
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      />
                                    </small>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="eventImageTooltip"
                                      trigger="hover"
                                    >
                                      Used for event navigation
                                    </UncontrolledTooltip>
                                  </div>
                                  <small className="text-muted">
                                    Recommended 600 x 600 1MB
                                  </small>
                                </Label>
                                <Label
                                  className="ml-3 image-upload-label"
                                  for="eventUpload"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    alt="..."
                                    className="event-image-edit"
                                    src={eventImageUrl || defaultFormImg}
                                  />
                                  <div className="image-overlay">
                                    <FontAwesomeIcon
                                      icon="fa-pencil"
                                      className="text-muted hidden-icon"
                                    />
                                  </div>
                                </Label>
                                <Input
                                  {...input}
                                  id="eventUpload"
                                  name="file"
                                  type="file"
                                  accept="image/*"
                                  style={{
                                    display: "none",
                                  }}
                                  onChange={(e) => {
                                    selectEventImage(e);
                                    setFormChanged(true);
                                  }}
                                />

                                {meta.error && meta.touched && (
                                  <small className="text-danger">
                                    <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                    {meta.error}
                                  </small>
                                )}
                              </>
                            )}
                          </Field>
                        </FormGroup>
                      </div>

                      <div className="mt-4 mb-4">
                        <FormGroup>
                          <Field name="bannerImage">
                            {({ input, meta }) => (
                              <>
                                <Label>
                                  <div>
                                    <strong>Banner Image</strong>
                                    <small>
                                      <FontAwesomeIcon
                                        icon="fa-circle-info"
                                        className="text-muted ml-1"
                                        id="bannerImageTooltip"
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      />
                                    </small>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="bannerImageTooltip"
                                      trigger="hover"
                                    >
                                      Show on top of your event page
                                    </UncontrolledTooltip>
                                  </div>
                                  <small className="text-muted">
                                    Recommended 1400x400 1MB
                                  </small>
                                </Label>
                                <Label
                                  className="ml-3 image-upload-label"
                                  for="bannerUpload"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    alt="..."
                                    className="banner-image-edit"
                                    src={bannerImageUrl || defaultFormBanner}
                                  />
                                  <div className="image-overlay">
                                    <FontAwesomeIcon
                                      icon="fa-pencil"
                                      className="text-muted hidden-icon"
                                    />
                                  </div>
                                </Label>
                                <Input
                                  {...input}
                                  id="bannerUpload"
                                  name="file"
                                  type="file"
                                  accept="image/*"
                                  style={{
                                    display: "none",
                                  }}
                                  onChange={(e) => {
                                    selectEventBanner(e);
                                    setFormChanged(true);
                                  }}
                                />

                                {meta.error && meta.touched && (
                                  <small className="text-danger">
                                    <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                    {meta.error}
                                  </small>
                                )}
                              </>
                            )}
                          </Field>
                        </FormGroup>
                      </div>

                      <div className="mt-4 mb-4">
                        <FormGroup>
                          <Field name="name">
                            {({ input, meta }) => (
                              <>
                                <Label for="name" className="required">
                                  <strong>Name</strong>
                                </Label>
                                <Input
                                  {...input}
                                  id="name"
                                  value={name}
                                  onChange={(e) => {
                                    setName(e.target.value);
                                    setFormChanged(true);
                                  }}
                                  placeholder={
                                    name || "Event name, eg. Non-fungible Party"
                                  }
                                  type="text"
                                />

                                {meta.error && meta.touched && (
                                  <small className="text-danger">
                                    <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                    {meta.error}
                                  </small>
                                )}
                              </>
                            )}
                          </Field>
                        </FormGroup>
                      </div>

                      <div className="mt-4 mb-4">
                        <FormGroup>
                          <Field name="category">
                            {({ input, meta }) => (
                              <>
                                <Label for="category">
                                  <div>
                                    <span>
                                      <strong>Category</strong>
                                    </span>
                                  </div>
                                </Label>
                                <InputGroup>
                                  <Select
                                    {...input}
                                    className="realpass-selector"
                                    onChange={(e) => {
                                      setCategoryOption(e);
                                      setFormChanged(true);
                                    }}
                                    options={EventCategorySelectorOptions}
                                    value={categoryOption}
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                  />
                                </InputGroup>

                                {meta.error && meta.touched && (
                                  <small className="text-danger">
                                    <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                    {meta.error}
                                  </small>
                                )}
                              </>
                            )}
                          </Field>
                        </FormGroup>
                      </div>

                      <div className="mt-4 mb-4">
                        <FormGroup>
                          <Field name="description">
                            {({ input, meta }) => (
                              <>
                                <Label for="description">
                                  <div>
                                    <strong>Description</strong>
                                  </div>
                                </Label>
                                <ReactQuill
                                  theme="snow"
                                  value={description}
                                  modules={QuillEditorModules}
                                  formats={QuillEditorFormats}
                                  onChange={(e) => {
                                    setDescription(e);
                                    setFormChanged(true);
                                  }}
                                  className="mb-2"
                                />

                                {meta.error && meta.touched && (
                                  <small className="text-danger">
                                    <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                    {meta.error}
                                  </small>
                                )}
                              </>
                            )}
                          </Field>
                        </FormGroup>
                      </div>
                      <hr />
                      <div className="my-4">
                        <FormGroup>
                          <Field name="eventSessions">
                            {({ input, meta }) => (
                              <>
                                <Row>
                                  <Col>
                                    <Label>
                                      <div>
                                        <strong>Event Duration</strong>
                                      </div>

                                      <small className="text-muted">
                                        {isMultiSession
                                          ? "A series of events"
                                          : "A single event"}
                                      </small>
                                    </Label>
                                  </Col>
                                  <Col>
                                    <ButtonGroup
                                      role="tablist"
                                      className="float-right"
                                    >
                                      <Button
                                        aria-selected={!isMultiSession}
                                        className={`btn-tab mb-sm-3 mb-md-0 ${
                                          !isMultiSession && "active"
                                        }`}
                                        outline
                                        color="dark"
                                        size="sm"
                                        onClick={(e) => {
                                          toggleMultiSession(false);
                                          setFormChanged(true);
                                        }}
                                        type="button"
                                        role="tab"
                                      >
                                        Single
                                      </Button>
                                      <Button
                                        aria-selected={isMultiSession}
                                        className={`btn-tab mb-sm-3 mb-md-0 ${
                                          isMultiSession && "active"
                                        }`}
                                        outline
                                        color="dark"
                                        size="sm"
                                        onClick={(e) => {
                                          toggleMultiSession(true);
                                          setFormChanged(true);
                                        }}
                                        type="button"
                                        role="tab"
                                      >
                                        Multi
                                      </Button>
                                    </ButtonGroup>
                                  </Col>
                                </Row>
                                <div className="mt-1">
                                  <InputGroup className="date-range-picker">
                                    <InputGroupText className="date-picker-left text-muted">
                                      <FontAwesomeIcon icon="fa-calendar-days" />
                                    </InputGroupText>

                                    <ReactDatetime
                                      {...input}
                                      className="date-picker-middle"
                                      value={startDateMoment}
                                      inputProps={{
                                        placeholder: "Event Starts",
                                      }}
                                      isValidDate={(now) => {
                                        return now.isAfter(
                                          moment().subtract(1, "day")
                                        );
                                      }}
                                      onChange={(e) => {
                                        const duration =
                                          handleReactDatetimeChange(
                                            "startDate",
                                            e,
                                            startDateMoment,
                                            endDateMoment
                                          );
                                        setStartDateMoment(duration.start);

                                        if (duration.end) {
                                          setEndDateMoment(duration.end);
                                        }

                                        setFormChanged(true);
                                      }}
                                      renderDay={(
                                        props,
                                        currentDate,
                                        selectedDate
                                      ) => {
                                        let classes = props.className;
                                        classes +=
                                          getClassNameReactDatetimeDays(
                                            currentDate,
                                            startDateMoment,
                                            endDateMoment
                                          );
                                        return (
                                          <td {...props} className={classes}>
                                            {currentDate.date()}
                                          </td>
                                        );
                                      }}
                                      displayTimeZone={selectedTimezoneValue}
                                    />
                                    <ReactDatetime
                                      {...input}
                                      className="date-picker-right rdtPickerOnRight"
                                      value={endDateMoment}
                                      inputProps={{
                                        placeholder: "Event Ends",
                                      }}
                                      isValidDate={(now) => {
                                        return now.isAfter(
                                          moment().subtract(1, "day")
                                        );
                                      }}
                                      onChange={(e) => {
                                        const duration =
                                          handleReactDatetimeChange(
                                            "endDate",
                                            e,
                                            startDateMoment,
                                            endDateMoment
                                          );
                                        if (duration.start) {
                                          setStartDateMoment(duration.start);
                                        }
                                        setEndDateMoment(duration.end);

                                        setFormChanged(true);
                                      }}
                                      renderDay={(
                                        props,
                                        currentDate,
                                        selectedDate
                                      ) => {
                                        let classes = props.className;
                                        classes +=
                                          getClassNameReactDatetimeDays(
                                            currentDate,
                                            startDateMoment,
                                            endDateMoment
                                          );
                                        return (
                                          <td {...props} className={classes}>
                                            {currentDate.date()}
                                          </td>
                                        );
                                      }}
                                      displayTimeZone={selectedTimezoneValue}
                                    />
                                  </InputGroup>
                                </div>

                                {durationError && (
                                  <small className="text-danger">
                                    <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                    {durationError}
                                  </small>
                                )}

                                {isMultiSession && (
                                  <>
                                    <div className="mt-3">
                                      <Label>
                                        <div>
                                          <strong>Sessions</strong>
                                        </div>

                                        <small className="text-muted">
                                          Add up to 10 sessions
                                        </small>
                                      </Label>
                                      <MultiSessionsEditable
                                        sessions={event?.sessions}
                                        updateSessions={setSessions}
                                        updateFormChanged={setFormChanged}
                                        timezone={selectedTimezoneValue}
                                      />
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </Field>
                        </FormGroup>
                      </div>

                      <div className="my-4">
                        <FormGroup>
                          <Field name="timezone">
                            {({ input, meta }) => (
                              <>
                                <Row>
                                  <Col>
                                    <Label for="timezone">
                                      <div>
                                        <strong>Timezone</strong>
                                        <small>
                                          <FontAwesomeIcon
                                            icon="fa-circle-info"
                                            className="text-muted ml-1"
                                            id="timezoneTooltip"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          />
                                        </small>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="timezoneTooltip"
                                          trigger="hover"
                                        >
                                          Your local timezone -{" "}
                                          {moment.tz.guess()} (
                                          {moment
                                            .tz(moment.tz.guess())
                                            .format("z")}{" "}
                                          )
                                        </UncontrolledTooltip>
                                      </div>
                                      <span>
                                        <small className="text-muted">
                                          Selected timezone -{" "}
                                        </small>
                                        <small className="text-info">
                                          {selectedTimezoneLabel}
                                        </small>
                                      </span>
                                    </Label>
                                  </Col>
                                </Row>
                                <div className="select-wrapper">
                                  <TimezoneSelect
                                    className="realpass-selector"
                                    value={selectedTimezone}
                                    onChange={handleTimezoneChange}
                                    timezones={{
                                      ...allTimezones,
                                    }}
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                  />
                                </div>
                              </>
                            )}
                          </Field>
                        </FormGroup>
                      </div>

                      <hr />
                      <div className="my-4">
                        <FormGroup>
                          <Field name="location">
                            {({ input, meta }) => (
                              <>
                                <Row>
                                  <Col className="col-6">
                                    <Label for="location">
                                      <div>
                                        <strong>Location</strong>
                                      </div>
                                      <small className="text-muted">
                                        {isVirtual
                                          ? "URL for virtual space"
                                          : "In real fife address"}
                                      </small>
                                    </Label>
                                  </Col>
                                  <Col>
                                    <ButtonGroup
                                      role="tablist"
                                      className="float-right"
                                    >
                                      <Button
                                        aria-selected={!isVirtual}
                                        className={`btn-tab mb-sm-3 mb-md-0 ${
                                          !isVirtual && "active"
                                        }`}
                                        outline
                                        color="dark"
                                        size="sm"
                                        onClick={(e) => {
                                          toggleVirtual(false);
                                          setFormChanged(true);
                                        }}
                                        type="button"
                                        role="tab"
                                      >
                                        IRL
                                      </Button>
                                      <Button
                                        aria-selected={isVirtual}
                                        className={`btn-tab mb-sm-3 mb-md-0 ${
                                          isVirtual && "active"
                                        }`}
                                        outline
                                        color="dark"
                                        size="sm"
                                        onClick={(e) => {
                                          toggleVirtual(true);
                                          setFormChanged(true);
                                        }}
                                        type="button"
                                        role="tab"
                                      >
                                        Virtual
                                      </Button>
                                    </ButtonGroup>
                                  </Col>
                                </Row>
                                <InputGroup>
                                  {isVirtual ? (
                                    <>
                                      <InputGroupText className="input-group-left pr-2 text-muted">
                                        <i className="ni ni-planet" />
                                      </InputGroupText>
                                      <Input
                                        {...input}
                                        id="location"
                                        className="pl-2"
                                        value={virtualLocation?.name}
                                        onChange={(e) => {
                                          if (e.target.value.length > 120) {
                                            setLocationError(
                                              "Location must not exceed 120 characters."
                                            );
                                          } else {
                                            setLocationError();
                                          }

                                          setVirtualLocation({
                                            name: e.target.value,
                                          });

                                          setFormChanged(true);
                                        }}
                                        placeholder={"In virtual place"}
                                        type="text"
                                      />{" "}
                                    </>
                                  ) : (
                                    <>
                                      <InputGroupText className="input-group-left pr-2 text-muted">
                                        <i className="ni ni-square-pin" />
                                      </InputGroupText>
                                      <GooglePlaceAutocomplete
                                        className="form-control pl-2"
                                        apiKey={`${REACT_APP_FBASE_API_KEY}&callback=Function.prototype`}
                                        defaultValue={irlLocation?.name || ""}
                                        placeholder="Find Address"
                                        options={{
                                          types: ["geocode"],
                                          fields: [
                                            "name",
                                            "geometry",
                                            "formatted_address",
                                          ],
                                        }}
                                        onChange={(e) => {
                                          if (e.target.value.length > 120) {
                                            setLocationError(
                                              "Location must not exceed 120 characters."
                                            );
                                          } else {
                                            setLocationError();
                                          }
                                          setFormChanged(true);
                                        }}
                                        onPlaceSelected={(place) => {
                                          if (
                                            place.formatted_address.length > 120
                                          ) {
                                            setLocationError(
                                              "Location must not exceed 120 characters."
                                            );
                                          } else {
                                            setLocationError();

                                            setIrlLocation({
                                              name: place.formatted_address,
                                              lat: place.geometry.location.lat(),
                                              lng: place.geometry.location.lng(),
                                            });
                                          }
                                          setFormChanged(true);
                                        }}
                                      />{" "}
                                    </>
                                  )}
                                </InputGroup>

                                {((meta.error && meta.touched) ||
                                  locationError) && (
                                  <small className="text-danger">
                                    <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                    {meta.error || locationError}
                                  </small>
                                )}
                              </>
                            )}
                          </Field>
                        </FormGroup>

                        <FormGroup>
                          <Field name="venue">
                            {({ input, meta }) => (
                              <>
                                <Label for="venue">
                                  <strong>Venue</strong>
                                </Label>
                                <Input
                                  {...input}
                                  id="venue"
                                  value={venue}
                                  onChange={(e) => {
                                    setVenue(e.target.value);
                                    setFormChanged(true);
                                  }}
                                  placeholder={venue || "Venue Name"}
                                  type="text"
                                />

                                {meta.error && meta.touched && (
                                  <small className="text-danger">
                                    <FontAwesomeIcon icon="fa-triangle-exclamation" />{" "}
                                    {meta.error}
                                  </small>
                                )}
                              </>
                            )}
                          </Field>
                        </FormGroup>
                      </div>

                      <div className="my-4">
                        <Row>
                          <Col className="col-9">
                            <Label>
                              <div>
                                <strong>Restrict Location</strong>
                              </div>

                              <small className="text-muted">
                                Location only visible after registration
                              </small>
                            </Label>
                          </Col>
                          <Col>
                            <Label className="custom-toggle float-right">
                              <Input
                                checked={isLocationRestricted}
                                type="checkbox"
                                onChange={() => {
                                  setIsLocationRestricted(
                                    !isLocationRestricted
                                  );
                                  setFormChanged(true);
                                }}
                              />
                              <span className="custom-toggle-slider rounded-circle"></span>
                            </Label>
                          </Col>
                        </Row>
                      </div>
                      <hr />

                      <div className="mt-4 mb-4">
                        <Row>
                          <Col className="col-6">
                            <Label for="nsfwCheck">
                              <strong>Mark as NSFW</strong>
                            </Label>
                          </Col>
                          <Col>
                            <Label className="custom-toggle float-right">
                              <Input
                                id="nsfwCheck"
                                checked={isNSFW}
                                type="checkbox"
                                onChange={() => {
                                  setIsNSFW(!isNSFW);
                                  setFormChanged(true);
                                }}
                              />
                              <span className="custom-toggle-slider rounded-circle"></span>
                            </Label>
                          </Col>
                        </Row>

                        <Alert color="secondary" className="text-default">
                          <span>
                            <FontAwesomeIcon
                              icon="fa-circle-info"
                              className="mr-2"
                            />
                            {isNSFW
                              ? "Content is marked as NSFW, and will be handled differently."
                              : "If event contains age restricted content, it needs to be marked as NSFW."}
                          </span>
                        </Alert>
                      </div>
                      <div className="mt-4 mb-4">
                        <Row>
                          <Col className="col-6">
                            <Label>
                              <strong>Publish Event</strong>
                            </Label>
                          </Col>
                          <Col>
                            <Label className="custom-toggle float-right">
                              <Input
                                checked={isPublished}
                                type="checkbox"
                                onChange={() => {
                                  setIsPublished(!isPublished);
                                  setFormChanged(true);
                                }}
                              />
                              <span className="custom-toggle-slider rounded-circle"></span>
                            </Label>
                          </Col>
                        </Row>

                        <Alert color="secondary" className="text-default">
                          <span>
                            <FontAwesomeIcon
                              icon="fa-circle-info"
                              className="mr-2"
                            />
                            {isPublished
                              ? "Ready to publish the event now."
                              : "Save as draft. Event will be invisible."}
                          </span>
                        </Alert>
                      </div>

                      <div className="mt-5 mb-3">
                        <Button
                          className="btn btn-dark mt-4 btn-block"
                          color="dark"
                          type="button"
                          onClick={saveEventDetail}
                          disabled={!valid || dataSaving || !formChanged}
                        >
                          Update Event
                          {dataSaving && (
                            <span className="ml-2">
                              <ScaleLoader
                                color="#eee"
                                loading={dataSaving}
                                height="10px"
                                cssOverride={{
                                  display: "inline",
                                }}
                              />
                            </span>
                          )}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </form>
              </div>
            )}
          />
        </>
      )}
    </>
  );
}
